// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Users/roles/roles.proto

import { Caller } from '@altipla/grpc-browser';


export default class RolesClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/roles`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/roles/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/roles:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/roles`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/roles/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/roles/${req.name}`, req, false, ['name']);
  }
};

