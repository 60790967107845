
import React from 'react'

import Icon from 'components/Icon'


export default function EntityBlockedAlert({ blocked }) {
  return (
    <>
      {blocked &&
        <div className="rounded-md bg-red-50 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0 mt-1">
              <Icon solid name="exclamation-triangle" className="text-red-400 text-3xl"/>
            </div>
            <div className="ml-4">
              <h3 className="font-medium text-red-800 mb-3">
                Entidad bloqueada por cambios pendientes
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>La entidad está pendiente de validar cambios por parte de un administrador.</p>
                <p>Los datos mostrados a continuación pueden estar obsoletos. Se actualizarán una vez que se resuelva la validación.</p>
                <p>No se pueden realizar acciones sobre esta entidad hasta que se revise el último cambio solicitado.</p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
