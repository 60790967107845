
export function formatDecimal(value) {
  if (value !== 0 && !value) {
    return ''
  }
  
  let precision = 2
  if (value % 100 === 0) {
    precision = 0
  }
  return (value / 100).toFixed(precision).toString().replace(/\./g, ',')
}

export function parseDecimal(value) {
  return parseInt(value.replace(/,/, '.') * 100)
}
