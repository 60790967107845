
import React from 'react'
import cx from 'classnames'

import { CustomFieldEditor } from 'components/CustomFieldEditor'


export function CustomFieldsEditor({ form, fields, onChange }) {
  return <>
    {fields.map((field, index) => (
      <fieldset className={cx({ 'mb-6': index !== fields.length - 1 })} key={index}>
        <CustomFieldEditor
          form={form}
          field={field}
          index={index}
          onChange={values => {
            field.values = values
            onChange([...fields])
          }}
          key={field.name}
        />
      </fieldset>
    ))}
  </>
}
