
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { listEntities } from 'models/docs/entities'


export default function InboxesGroupsList() {
  let [groups, setGroups] = useState([])

  useNavigate(async () => {
    setGroups(await listEntities('inboxes-groups'))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/inboxes-groups">Grupos de gestión</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Grupos de gestión"
        subtitle="Definen para cada propietario y tipo de documento quién tiene acceso durante el proceso de firma y clasificación."
        empty="No hay ningún grupo de gestión"
        actions={
          <ButtonLinkPrimary to="/manage/inboxes-groups/create">
            <Icon solid name="plus" className="mr-2" />
            Crear grupo
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {groups.map(group => (
          <TableRow key={group.name}>
            <Cell>
              <Link to={`/manage/inboxes-groups/${group.name.split('/')[1]}`} className="text-gray-700">{group.displayName}</Link>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/inboxes-groups/${group.name.split('/')[1]}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
