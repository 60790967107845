
import { uniq, flatten } from 'lodash-es'


export const ROLES = [
  { label: 'Gestión', value: 'gestion', states: ['STATE_UPLOADED', 'STATE_MANAGEMENT_PENDING'] },
  { label: 'Control', value: 'control', states: ['STATE_UPLOADED', 'STATE_CONTROL_PENDING'] },
  { label: 'Firma', value: 'firma', states: ['STATE_PHYSICAL_SIGNATURES'] },
  { label: 'Clasificación', value: 'clasificacion', states: ['STATE_CLASSIFICATION'] },
  { label: 'Validación', value: 'validacion', states: ['STATE_VALIDATION'] },
]

export function filterActiveRoles(states) {
  if (!states) {
    return []
  }

  let roles = []
  ROLES.forEach(state => {
    if (state.states.every(s => states.includes(s))) {
      roles.push(state)
    }
  })

  return roles
}

export function extractStatesFromRoles(codes) {
  if (!codes) {
    return []
  }

  let states = codes
    .map(code => ROLES.find(r => r.value === code))
    .map(role => role.states)

  return uniq(flatten(states))
}

export function getRole(code) {
  return ROLES.find(role => role.value === code)
}
