
import { keyBy, uniq } from 'lodash-es'
import { price } from 'platform/filters'
import { webinterna } from 'services/discovery'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'

const sociedadesClient = new SociedadesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())


export let REF_SOCIEDAD = {
  loader: async names => {
    let reply = await sociedadesClient.BatchGet({ names })
    let sociedades = reply.sociedades || []

    let municipiosNames = Object.values(sociedades)
      .filter(p => p.municipio)
      .map(p => p.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(municipiosNames) })
    let provinciasNames = municipios?.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(provinciasNames) })
    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')

    sociedades = sociedades.map(sociedad => {
      return {
        ...sociedad,
        label: sociedad.denominacion,
        municipio: municipios[sociedad.municipio],
        provincia: provincias[municipios[sociedad.municipio].provincia],
        descriptor: 'Sociedad',
      }
    })
    return sociedades
  },
  link: name => `/webinterna/sociedades/${name}`,
  label: 'Sociedad',
  fields: {
    acronimo: {
      label: 'Acrónimo',
      type: 'text',
      wide: false,
      extractor: entity => entity.acronimo,
    },
    denominacion: {
      label: 'Título sociedad',
      type: 'text',
      wide: false,
      extractor: entity => entity.denominacion,
    },
    tipo: {
      label: 'Tipo',
      type: 'text',
      wide: false,
      extractor: entity => entity.tipo,
    },
    email: {
      label: 'Email',
      type: 'text',
      wide: false,
      extractor: entity => entity.email,
    },
    telefono: {
      label: 'Teléfono',
      type: 'text',
      wide: false,
      extractor: entity => entity.telefono,
    },
    cif: {
      label: 'CIF',
      type: 'text',
      wide: false,
      extractor: entity => entity.cif,
    },
    grupo: {
      label: 'Grupo',
      type: 'text',
      wide: false,
      extractor: entity => entity.grupo,
    },
    subgrupo: {
      label: 'Subgrupo',
      type: 'text',
      wide: false,
      extractor: entity => entity.subgrupo,
    },
    capitalSocial: {
      label: 'Capital social',
      type: 'text',
      wide: false,
      extractor: entity => price(entity.capitalSocial),
    },
    direccion: {
      label: 'Dirección',
      type: 'text',
      wide: false,
      extractor: entity => entity.direccion,
    },
    codigoPostal: {
      label: 'Código Postal',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigoPostal,
    },
    provincia: {
      label: 'Provincia',
      type: 'text',
      wide: false,
      extractor: entity => entity.provincia.nombre,
    },
  },
}
