
import React from 'react'
import cx from 'classnames'
import { isArray } from 'lodash-es'


export default function PeopleGrid({ className, children }) {
  if (children && !isArray(children)) {
    children = [children]
  }
  
  return (
    <div className={cx('grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2', className)}>
      {children?.map((item, index) => (
        <div key={index}>
          {item}
        </div>
      ))}
    </div>
  )
}
