
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import FilesClient from 'services/Docs/files/files'
import { docs } from 'services/discovery'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import PageLayout from 'layout/docs/PageLayout'
import Card from 'components/Card'
import Icon from 'components/Icon'
import PdfPreview from 'components/PdfPreview'
import { Datepicker } from 'components/simple-forms/Datepicker'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import { useNavigate } from 'hooks/navigate'
import { getEntity, putEntity } from 'models/docs/entities'
import { parseTimestamp, serializeDate, serializeTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import { accountInfo } from 'auth/active-directory'
import { InfoPane } from 'views/docs/upload/internal/InfoPane'
import { Sidebar, UPLOAD } from 'views/docs/upload/internal/Sidebar'


const client = new FilesClient(docs())


export function UploadPresentation() {
  let { code } = useParams()

  let [doc, setDoc] = useState(null)
  let [date, setDate] = useState(null)
  let [file, setFile] = useState(null)
  let [status, setStatus] = useState(UPLOAD.PENDING)

  useNavigate(async () => {
    let doc = await getEntity(`documents/${code}`)
    doc.createTime = parseTimestamp(doc.createTime)
    let file = await client.Get({ name: doc.file })
    doc.fileUrl = file.downloadUrl

    setDoc(doc)
  })

  async function submit() {
    let doc = await getEntity(`documents/${code}`)
    doc.presentationDate = serializeDate(date)

    await putEntity('documents', { document: doc })

    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      presentation: {
        date: doc.presentationDate,
        receipt: file.name,
      },
    }
    await putEntity('revisions', { revision })
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url={`/docs/upload/presentation/${code}`}>Presentación: {doc?.title || doc?.suggestedTitle}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="flex space-x-16">
        <div className="flex-grow">
          {doc &&
            <>
              <div className="mx-auto md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex items-center space-x-5">
                  <div>
                    <h1 className="text-3xl font-bold text-gray-900">{doc.title || doc.suggestedTitle}</h1>
                  </div>
                </div>
              </div>

              <Card
                title="Información general"
                subtitle="Información general del documento"
                className="mt-8"
              >
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                  <InfoPane title="Formato">
                    {doc.format === 'FORMAT_DIGITAL' && 'Digital'}
                    {doc.format === 'FORMAT_PHYSICAL' && 'Físico'}
                  </InfoPane>

                  <InfoPane title="Fecha de creación">
                    {datetime(doc.createTime, 'DATETIME')}
                  </InfoPane>
                </div>
              </Card>

              <Card
                title="Presentación"
                subtitle="Datos sobre la presentación del documento"
                className="mt-8"
                footer={
                  <ButtonAsyncPrimary
                    disabled={!file || !date}
                    onClick={() => submit()}
                  >
                    <Icon solid name="file-upload" className="mr-2" />
                    Presentar documento
                  </ButtonAsyncPrimary>
                }
              >
                <Datepicker
                  name="date"
                  label="Fecha de presentación"
                  onChange={setDate}
                ></Datepicker>
              </Card>
            </>
          }
        </div>
        <div className="w-128 flex-shrink-0">
          <Sidebar status={status} setStatus={setStatus} onUploadFile={setFile}>
            <Card title="Previsualización del documento" >
              <div className="w-96 m-auto">
                <PdfPreview url={doc?.fileUrl} />
              </div>
            </Card>
          </Sidebar>
        </div>
      </div>
    </PageLayout>
  )
}
