
import React from 'react'

import ButtonPrimary from 'components/buttons/ButtonPrimary'
import Icon from 'components/Icon'


export function Submit({ form, children }) {
  return (
    <div>
      {form.formState.isSubmitting ?
        <button type="button" disabled className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-300 cursor-default">
          <Icon solid spin name="spinner" className="mr-2" />
          Enviando datos...
        </button>
        :
        <ButtonPrimary type="submit">
          {children}
        </ButtonPrimary>
      }
    </div>
  )
}
