
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'

import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { Datatable, Cell, HeaderCell, RowMobile, useDatatable } from 'components/Datatable'
import PlanesCuentasClient from 'services/WebInterna/planes_cuentas/planes_cuentas'
import { webinterna } from 'services/discovery'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { parseTimestamp } from 'platform/datetime'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeRed from 'components/badges/BadgeRed'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { keyBy, uniq } from 'lodash'
import ExternalLink from 'components/ExternalLink'
import { datetime } from 'platform/filters'


const planesClient = new PlanesCuentasClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())


export default function PlanesCuentasList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Fecha de creación',
        sort: firstBy('creacionTime', 'desc'),
      },
    ])

    let { planesCuentas: planes } = await planesClient.List()
    if (!planes) {
      planes = []
    }

    let sociedadesNames = planes.filter(c => c.sociedad).map(c => c.sociedad)
    let sociedades = await sociedadesClient.BatchGet({ names: uniq(sociedadesNames) }).then(sociedades => sociedades.sociedades || [])
    sociedades = keyBy(sociedades, 'name')

    planes.forEach(plan => {
      plan.creacionTime = parseTimestamp(plan.creacionTime)
      plan.sociedad = sociedades[plan.sociedad]
      plan.$search = [
        plan.denominacion,
      ]
    })
    setRows(planes)
  })

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.planes_cuentas.propose-edit">
            <ButtonLinkPrimary to="/webinterna/planes-cuentas/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Plan de cuentas
            </ButtonLinkPrimary>
          </Acl>
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/planes-cuentas">Contabilidad Analítica</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell>Nombre Plan de Cuentas</HeaderCell>
            <HeaderCell>Sociedad</HeaderCell>
            <HeaderCell>Partidas Analíticas</HeaderCell>
            <HeaderCell>Fecha de creación</HeaderCell>
            <HeaderCell>Inhabilitado</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/planes-cuentas/${row.name}`}>
            <div className="font-medium text-gray-900">{row.denominacion}</div>
            <div>{row.partidasAnaliticas?.length || 0} partidas analíticas</div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell wrap>
              <Link
                to={`/webinterna/planes-cuentas/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.denominacion}
              </Link>
            </Cell>
            <Cell>
              {row.sociedad && 
                <ExternalLink to={`/webinterna/sociedades/${row.sociedad.name}`}>
                  {row.sociedad.denominacion}
                </ExternalLink>
              }
            </Cell>
            <Cell>
              {row.partidasAnaliticas?.length || 0} partidas analíticas
            </Cell>
            <Cell>
              {datetime(row.creacionTime, 'DATETIME')}
            </Cell>
            <Cell>
              {row.inhabilitado ? <BadgeRed>Inhabilitado</BadgeRed> : <BadgeGreen>Vigente</BadgeGreen>}
            </Cell>
            <Cell className="text-right">
              {!row.inhabilitado && <Acl perm="webinterna.planes_cuentas.propose-edit">
                <Link to={`/webinterna/planes-cuentas/edit/${row.name}`}>Editar</Link>
              </Acl>}
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
