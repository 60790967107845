
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { Transition } from '@headlessui/react'

import { HeaderMenu } from 'layout/HeaderMenu'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import NavigateThrobber from 'components/NavigateThrobber'


export const MENU = [
  {
    label: 'Procesos',
    url: '/webinterna/procesos',
    icon: 'tasks',
    perm: 'webinterna.procesos.menu',
  },
  {
    label: 'Personas',
    url: '/webinterna/personas',
    icon: 'user-friends',
    perm: 'webinterna.personas.menu',
  },
  {
    label: 'Contribuidores',
    url: '/webinterna/contribuidores',
    icon: 'user-tie',
    perm: 'webinterna.personas.menu',
  },
  {
    label: 'Proyectos',
    url: '/webinterna/proyectos',
    icon: 'construction',
    perm: 'webinterna.proyectos.menu',
  },
  {
    label: 'Sociedades',
    url: '/webinterna/sociedades',
    icon: 'handshake-alt',
    perm: 'webinterna.sociedades.menu',
  },
  {
    label: 'Unidades inmobiliarias',
    url: '/webinterna/inmuebles',
    icon: 'building',
    perm: 'webinterna.inmuebles.menu',
  },
  {
    label: 'Fincas registrales',
    url: '/webinterna/fincas-registrales',
    icon: 'landmark',
    perm: 'webinterna.fincas_registrales.menu',
    indented: true,
  },
  {
    label: 'Elementos patrimoniales',
    url: '/webinterna/elementos-patrimoniales',
    icon: 'balance-scale',
    perm: 'webinterna.elementos_patrimoniales.menu',
    indented: true,
  },
  {
    label: 'Fincas catastrales',
    url: '/webinterna/fincas-catastrales',
    icon: 'file-contract',
    perm: 'webinterna.fincas_catastrales.menu',
    indented: true,
  },
  {
    label: 'Contabilidad analítica',
    url: '/webinterna/planes-cuentas',
    icon: 'euro-sign',
    perm: 'webinterna.planes_cuentas.menu',
  },
  {
    label: 'Contratos',
    url: '/webinterna/contratos',
    icon: 'file-contract',
    perm: 'webinterna.contratos.menu',
  },
  {
    label: 'Validaciones',
    url: '/webinterna/validaciones',
    icon: 'check',
    perm: 'webinterna.validaciones.menu',
  },
]


export default function PageLayout({ children, wide = false }) {
  let [showMenu, setShowMenu] = useState(false)

  let location = useLocation()
  MENU.forEach(item => item.active = location.pathname.startsWith(item.url))

  return (
    <HeaderMenu>
      <div className={cx({
        'hidden': !showMenu,
        'block': showMenu,
      })}>
        <div className="fixed inset-0 flex z-40">
          <Transition
            show={true}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0"
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </Transition>

          <Transition
            show={true}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
          >
            <div className="absolute top-0 right-0 -mr-14 p-1">
              <button
                className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                onClick={() => setShowMenu(!showMenu)}
              >
                <Icon
                  light
                  name="times"
                  className={cx(
                    'h-6 w-6 fa-2x text-white',
                    {
                      'hidden': !showMenu,
                      'block': showMenu,
                    },
                  )}
                ></Icon>
              </button>
            </div>
            <div className="flex-shrink-0 flex items-center px-4 text-white font-bold text-center text-xl">
              WebInterna
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {MENU.map(item => (
                  <Acl key={item.label} perm={item.perm}>
                    <Link
                      to={item.url}
                      onClick={() => setShowMenu(false)}
                      className={cx(
                        'group flex items-center py-2 text-base leading-6 font-medium rounded-md hover:text-white focus:outline-none transition ease-in-out duration-150',
                        {
                          'text-white bg-gray-900': item.active,
                          'text-gray-300 hover:bg-gray-700 focus:text-white focus:bg-gray-700': !item.active,
                          'px-2': !item.indented,
                          'pl-6 pr-2': item.indented,
                        },
                      )}
                    >
                      <Icon regular name={item.icon} className="mr-3 text-lg text-gray-400" fixedWidth />
                      {item.label}
                    </Link>
                  </Acl>
                ))}
              </nav>
            </div>
          </Transition>

          <div className="flex-shrink-0 w-14">
            {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
          </div>
        </div>
      </div>

      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900 text-white font-bold text-center text-xl">
              WebInterna
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                {MENU.map(item => (
                  <Acl perm={item.perm} key={item.label}>
                    <Link
                      to={item.url}
                      className={cx(
                        'group flex items-center py-2 text-sm leading-5 font-medium rounded-md hover:text-white focus:outline-none transition ease-in-out duration-150',
                        {
                          'text-white bg-gray-900': item.active,
                          'text-gray-300 hover:bg-gray-700 focus:text-white focus:bg-gray-700': !item.active,
                          'px-2': !item.indented,
                          'pl-6 pr-2': item.indented,
                        },
                      )}
                    >
                      <Icon regular name={item.icon} className="mr-3 text-lg text-gray-400" fixedWidth />
                      {item.label}
                    </Link>
                  </Acl>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 overflow-y-scroll">
        <div className="lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            onClick={() => setShowMenu(!showMenu)}
          >
            <Icon
              regular
              name="bars"
              className={cx(
                'h-5 w-5 fa-2x',
                {
                  'hidden': showMenu,
                  'block': !showMenu,
                },
              )}
            ></Icon>
          </button>
        </div>

        <div className="flex-1 relative">
          <NavigateThrobber></NavigateThrobber>
          <div
            className={cx(
              {
                'w-full': wide,
                'max-w-7xl': !wide,
              },
              'mx-auto px-4 sm:px-6 lg:px-8 mb-12',
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </HeaderMenu>
  )
}
