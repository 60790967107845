
import React from 'react'
import cx from 'classnames'


export default function CardHeader({ children, className, header, subheader, actions, fit, footer }) {
  return (
    <div className={cx(
      'bg-white sm:rounded-lg sm:shadow',
      className,
    )}>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{header}</h3>
            {subheader && <p className="mt-1 text-sm leading-5 text-gray-500">{subheader}</p>}
          </div>

          <div className="ml-4 mt-2 flex-shrink-0">{actions}</div>
        </div>
      </div>

      <div className={cx({ 'px-4 py-5 sm:p-6': !fit })}>
        {children}
      </div>
      
      {footer && <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">{footer}</div>}
    </div>
  )
}
