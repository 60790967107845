import { watch } from '@vue/runtime-core';
import { getUso, serializePercentage, serializeInteger } from './storage.js';
import { createPresupuesto, addSuperficie, removeSuperficie, } from './internal.js';
export { printInteger, printPercentage, parseInteger, parsePercentage, printMoney, printArea, printRatio } from './operations.js';
export default class Presupuesto {
    constructor(val, setVersion) {
        if (!setVersion) {
            setVersion = () => { };
        }
        let presupuesto = createPresupuesto(val);
        this.resumen = new Resumen(setVersion, presupuesto.resumen);
        this.superficies = new Superficies(setVersion, presupuesto.superficies);
        this.costes = new Costes(setVersion, presupuesto.costes);
        this.ingresos = new Ingresos(setVersion, presupuesto.ingresos);
        this.otrosGastos = new OtrosGastos(setVersion, presupuesto.otrosGastos);
    }
    toJSON() {
        return {
            resumen: this.resumen.toJSON(),
            superficies: this.superficies.toJSON(),
            costes: this.costes.toJSON(),
            ingresos: this.ingresos.toJSON(),
            otrosGastos: this.otrosGastos.toJSON(),
        };
    }
}
class Resumen {
    constructor($setVersion, $resumen) {
        this.$setVersion = $setVersion;
        this.$resumen = $resumen;
    }
    toJSON() {
        return {
            costesFinanciacion: serializePercentage(this.costesFinanciacion),
            ltvBancario: serializePercentage(this.$resumen.ratioLtvBancario.value),
            ltcBancario: serializePercentage(this.$resumen.ratioLtcBancario.value),
        };
    }
    get financiacionObjetivo() {
        return this.$resumen.financiacionObjetivo.value;
    }
    get costesFinanciacion() {
        return this.$resumen.costesFinanciacion.value;
    }
    set costesFinanciacion(val) {
        this.$resumen.costesFinanciacion.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ltvBancario() {
        return this.$resumen.ltvBancario.value;
    }
    set ltvBancario(val) {
        this.$resumen.ratioLtvBancario.value = val;
        this.$resumen.ratioLtcBancario.value = undefined;
        this.$setVersion((v) => v + 1);
    }
    get ltcBancario() {
        return this.$resumen.ltcBancario.value;
    }
    set ltcBancario(val) {
        this.$resumen.ratioLtcBancario.value = val;
        this.$resumen.ratioLtvBancario.value = undefined;
        this.$setVersion((v) => v + 1);
    }
    get vppOverviews() {
        return [
            serializeVPPOverview(this.$resumen.ingresosTotales),
            serializeVPPOverview(this.$resumen.costesTotales),
            serializeVPPOverview(this.$resumen.suelo),
            serializeVPPOverview(this.$resumen.urbanizacion),
            serializeVPPOverview(this.$resumen.edificacion),
            serializeVPPOverview(this.$resumen.legales),
            serializeVPPOverview(this.$resumen.proyectosSSTT),
            serializeVPPOverview(this.$resumen.margenBrutoDesarrollo),
            serializeVPPOverview(this.$resumen.margenBrutoDesarrolloPercentage),
            serializeVPPOverview(this.$resumen.otrosGastosOverview),
            serializeVPPOverview(this.$resumen.costesFinanciacionPercentage),
            serializeVPPOverview(this.$resumen.costesFinanciacionOverview),
            serializeVPPOverview(this.$resumen.margenBrutoTotal),
            serializeVPPOverview(this.$resumen.margenBrutoPercentage),
        ];
    }
    get comercialOverviews() {
        return [
            serializeComercialOverview(this.$resumen.noi),
            serializeComercialOverview(this.$resumen.rbe),
            serializeComercialOverview(this.$resumen.yield),
        ];
    }
}
function serializeVPPOverview(val) {
    return {
        nombre: val.nombre,
        subOverview: val.subOverview,
        total: val.total.value,
        totalMetros: val.totalMetros.value,
        viviendaLibre: val.viviendaLibre.value,
        vpp: val.vpp.value,
    };
}
function serializeComercialOverview(val) {
    return {
        nombre: val.nombre,
        total: val.total.value,
        totalMetros: val.totalMetros.value,
        viviendaLibre: val.viviendaLibre.value,
        comercial: val.comercial.value,
    };
}
class Superficies {
    constructor($setVersion, $superficies) {
        this.$setVersion = $setVersion;
        this.$superficies = $superficies;
        this.superficies = [];
        this.loadSuperficies();
    }
    loadSuperficies() {
        this.superficies = this.$superficies.superficies.map((s, index) => new Superficie(this.$setVersion, this.$superficies, index));
    }
    toJSON() {
        return {
            suelo: serializeInteger(this.suelo),
            edificabilidadMax: serializeInteger(this.edificabilidadMax),
            superficies: this.superficies.map(superficie => superficie.toJSON()),
        };
    }
    get total() {
        return {
            superficieConstruida: this.$superficies.superficieConstruida.value,
            srSuperficie: this.$superficies.srSuperficie.value,
            brSuperficie: this.$superficies.brSuperficie.value,
            unidades: this.$superficies.totalUnidades.value,
        };
    }
    get suelo() {
        return this.$superficies.suelo.value;
    }
    set suelo(val) {
        this.$superficies.suelo.value = val;
        this.$setVersion((v) => v + 1);
    }
    get edificabilidadMax() {
        return this.$superficies.edificabilidadMax.value;
    }
    set edificabilidadMax(val) {
        this.$superficies.edificabilidadMax.value = val;
        this.$setVersion((v) => v + 1);
    }
    add(uso) {
        addSuperficie(this.$superficies, { uso });
        let superficie = new Superficie(this.$setVersion, this.$superficies, this.$superficies.superficies.length - 1);
        this.superficies.push(superficie);
        this.$setVersion((v) => v + 1);
        return superficie;
    }
    remove(index) {
        removeSuperficie(this.$superficies, index);
        this.loadSuperficies();
        this.$setVersion((v) => v + 1);
    }
}
class Superficie {
    constructor($setVersion, $superficies, $index) {
        this.$setVersion = $setVersion;
        this.$superficies = $superficies;
        this.$index = $index;
    }
    toJSON() {
        return {
            superficieConstruida: serializeInteger(this.superficieConstruida),
            sr: serializePercentage(this.sr),
            unidades: serializeInteger(this.unidades),
            s1d: serializeInteger(this.s1d),
            s2d: serializeInteger(this.s2d),
            s3d: serializeInteger(this.s3d),
            s4d: serializeInteger(this.s4d),
            nombre: this.uso === 'USO_LIBRE' ? this.nombre : undefined,
            uso: this.uso,
        };
    }
    get superficieConstruida() {
        return this.$superficies.superficies[this.$index].superficieConstruida.value;
    }
    set superficieConstruida(val) {
        this.$superficies.superficies[this.$index].superficieConstruida.value = val;
        this.$setVersion((v) => v + 1);
    }
    get sr() {
        return this.$superficies.superficies[this.$index].sr.value;
    }
    set sr(val) {
        this.$superficies.superficies[this.$index].sr.value = val;
        this.$setVersion((v) => v + 1);
    }
    get unidades() {
        return this.$superficies.superficies[this.$index].unidades.value;
    }
    set unidades(val) {
        this.$superficies.superficies[this.$index].unidades.value = val;
        this.$setVersion((v) => v + 1);
    }
    get s1d() {
        return this.$superficies.superficies[this.$index].s1d.value;
    }
    set s1d(val) {
        this.$superficies.superficies[this.$index].s1d.value = val;
        this.$setVersion((v) => v + 1);
    }
    get s2d() {
        return this.$superficies.superficies[this.$index].s2d.value;
    }
    set s2d(val) {
        this.$superficies.superficies[this.$index].s2d.value = val;
        this.$setVersion((v) => v + 1);
    }
    get s3d() {
        return this.$superficies.superficies[this.$index].s3d.value;
    }
    set s3d(val) {
        this.$superficies.superficies[this.$index].s3d.value = val;
        this.$setVersion((v) => v + 1);
    }
    get s4d() {
        return this.$superficies.superficies[this.$index].s4d.value;
    }
    set s4d(val) {
        this.$superficies.superficies[this.$index].s4d.value = val;
        this.$setVersion((v) => v + 1);
    }
    get nombre() {
        return this.$superficies.superficies[this.$index].nombre.value;
    }
    set nombre(nombre) {
        var _a;
        if (!((_a = getUso(this.$superficies.superficies[this.$index].uso)) === null || _a === void 0 ? void 0 : _a.freeNombre)) {
            throw new Error(`can only change the name of USO_LIBRE superficies`);
        }
        this.$superficies.superficies[this.$index].nombre.value = nombre;
        this.$setVersion((v) => v + 1);
    }
    get uso() {
        return this.$superficies.superficies[this.$index].uso;
    }
    get br() {
        return this.$superficies.superficies[this.$index].br.value;
    }
    get srSuperficie() {
        return this.$superficies.superficies[this.$index].srSuperficie.value;
    }
    get brSuperficie() {
        return this.$superficies.superficies[this.$index].brSuperficie.value;
    }
}
class Costes {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
        this.suelo = new CostesSuelo(this.$setVersion, this.$costes);
        this.urbanizacion = new CostesUrbanizacion(this.$setVersion, this.$costes);
        this.edificacion = new CostesEdificacion(this.$setVersion, this.$costes);
        this.mobiliarioEquipamiento = new CostesMobiliarioEquipamiento(this.$setVersion, this.$costes);
        this.fitOuts = new CostesFitOuts(this.$setVersion, this.$costes);
        this.legales = new CostesLegales(this.$setVersion, this.$costes);
        this.proyectoSSTT = new CostesProyectoSSTT(this.$setVersion, this.$costes);
    }
    toJSON() {
        return {
            suelo: this.suelo.toJSON(),
            urbanizacion: this.urbanizacion.toJSON(),
            edificacion: this.edificacion.toJSON(),
            mobiliarioEquipamiento: this.mobiliarioEquipamiento.toJSON(),
            fitOuts: this.fitOuts.toJSON(),
            legales: this.legales.toJSON(),
            proyectoSSTT: this.proyectoSSTT.toJSON(),
        };
    }
    get total() {
        return {
            nombre: this.$costes.total.nombre,
            base: this.$costes.total.base.value,
            ratio: this.$costes.total.ratio.value,
            importe: this.$costes.total.importe.value,
        };
    }
}
class CostesSuelo {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
    }
    toJSON() {
        return serializeInteger(this.importe);
    }
    get nombre() {
        return this.$costes.suelo.nombre;
    }
    get base() {
        return this.$costes.suelo.base.value;
    }
    get ratio() {
        return this.$costes.suelo.ratio.value;
    }
    get importe() {
        return this.$costes.suelo.importe.value;
    }
    set importe(val) {
        this.$costes.suelo.importe.value = val;
        this.$setVersion((v) => v + 1);
    }
}
class CostesUrbanizacion {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
    }
    toJSON() {
        return serializeInteger(this.importe);
    }
    get nombre() {
        return this.$costes.urbanizacion.nombre;
    }
    get base() {
        return this.$costes.urbanizacion.base.value;
    }
    get ratio() {
        return this.$costes.urbanizacion.ratio.value;
    }
    get importe() {
        return this.$costes.urbanizacion.importe.value;
    }
    set importe(val) {
        this.$costes.urbanizacion.importe.value = val;
        this.$setVersion((v) => v + 1);
    }
}
class CostesEdificacion {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
        this.costes = [];
        watch(this.$costes.edificacion.costes, () => {
            this.costes = this.$costes.edificacion.costes.value.map((coste, index) => new CosteRef(this.$setVersion, this.$costes.edificacion.costes.value, index));
        }, { immediate: true, flush: 'sync' });
    }
    toJSON() {
        return this.costes.map(coste => coste.toJSON());
    }
    get nombre() {
        return this.$costes.edificacion.nombre;
    }
    get base() {
        return this.$costes.edificacion.base.value;
    }
    get ratio() {
        return this.$costes.edificacion.ratio.value;
    }
    get importe() {
        return this.$costes.edificacion.importe.value;
    }
}
class CostesMobiliarioEquipamiento {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
        this.costes = [];
        watch(this.$costes.mobiliarioEquipamiento.costes, () => {
            this.costes = this.$costes.mobiliarioEquipamiento.costes.value.map((coste, index) => new CosteRef(this.$setVersion, this.$costes.mobiliarioEquipamiento.costes.value, index));
        }, { immediate: true, flush: 'sync' });
    }
    toJSON() {
        return this.costes.map(coste => coste.toJSON());
    }
    get nombre() {
        return this.$costes.mobiliarioEquipamiento.nombre;
    }
    get importe() {
        return this.$costes.mobiliarioEquipamiento.importe.value;
    }
}
class CostesFitOuts {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
        this.costes = [];
        watch(this.$costes.fitOuts.costes, () => {
            this.costes = this.$costes.fitOuts.costes.value.map((coste, index) => new CosteRef(this.$setVersion, this.$costes.fitOuts.costes.value, index));
        }, { immediate: true, flush: 'sync' });
    }
    toJSON() {
        return this.costes.map(coste => coste.toJSON());
    }
    get nombre() {
        return this.$costes.fitOuts.nombre;
    }
    get importe() {
        return this.$costes.fitOuts.importe.value;
    }
}
class CosteRef {
    constructor($setVersion, $costes, $index) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
        this.$index = $index;
    }
    toJSON() {
        return {
            ratio: serializeInteger(this.$costes[this.$index].ratioValue.value),
            importe: serializeInteger(this.$costes[this.$index].importeValue.value),
        };
    }
    get nombre() {
        return this.$costes[this.$index].nombre;
    }
    get base() {
        return this.$costes[this.$index].base.value;
    }
    get ratio() {
        return this.$costes[this.$index].ratio.value;
    }
    set ratio(val) {
        this.$costes[this.$index].ratioValue.value = val;
        this.$costes[this.$index].importeValue.value = undefined;
        this.$setVersion((v) => v + 1);
    }
    get importe() {
        return this.$costes[this.$index].importe.value;
    }
    set importe(val) {
        this.$costes[this.$index].importeValue.value = val;
        this.$costes[this.$index].ratioValue.value = undefined;
        this.$setVersion((v) => v + 1);
    }
}
class CostesLegales {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
    }
    toJSON() {
        return {
            tasasCIO: serializePercentage(this.ratioTasasCIO),
            obraNueva: serializePercentage(this.ratioObraNueva),
            divisionHorizontal: serializePercentage(this.ratioDivisionHorizontal),
            otrosGastos: serializePercentage(this.ratioOtrosGastos),
        };
    }
    get ratioTasasCIO() {
        return this.$costes.legales.tasasCIO.ratio.value;
    }
    set ratioTasasCIO(val) {
        this.$costes.legales.tasasCIO.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioObraNueva() {
        return this.$costes.legales.obraNueva.ratio.value;
    }
    set ratioObraNueva(val) {
        this.$costes.legales.obraNueva.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioDivisionHorizontal() {
        return this.$costes.legales.divisionHorizontal.ratio.value;
    }
    set ratioDivisionHorizontal(val) {
        this.$costes.legales.divisionHorizontal.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioOtrosGastos() {
        return this.$costes.legales.otrosGastos.ratio.value;
    }
    set ratioOtrosGastos(val) {
        this.$costes.legales.otrosGastos.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get nombre() {
        return this.$costes.legales.nombre;
    }
    get importe() {
        return this.$costes.legales.importe.value;
    }
    get tasasCIO() {
        return serializeCoste(this.$costes.legales.tasasCIO);
    }
    get obraNueva() {
        return serializeCoste(this.$costes.legales.obraNueva);
    }
    get divisionHorizontal() {
        return serializeCoste(this.$costes.legales.divisionHorizontal);
    }
    get otrosGastos() {
        return serializeCoste(this.$costes.legales.otrosGastos);
    }
}
function serializeCoste(coste) {
    return {
        nombre: coste.nombre,
        base: coste.base.value,
        ratio: coste.ratio.value,
        importe: coste.importe.value,
    };
}
class CostesProyectoSSTT {
    constructor($setVersion, $costes) {
        this.$setVersion = $setVersion;
        this.$costes = $costes;
    }
    toJSON() {
        return serializePercentage(this.$costes.proyectoSSTT.ratio.value);
    }
    get ratio() {
        return this.$costes.proyectoSSTT.ratio.value;
    }
    set ratio(val) {
        this.$costes.proyectoSSTT.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get nombre() {
        return this.$costes.proyectoSSTT.nombre;
    }
    get base() {
        return this.$costes.proyectoSSTT.base.value;
    }
    get importe() {
        return this.$costes.proyectoSSTT.importe.value;
    }
}
class Ingresos {
    constructor($setVersion, $ingresos) {
        this.$setVersion = $setVersion;
        this.$ingresos = $ingresos;
        this.ventas = new Ventas(this.$setVersion, this.$ingresos.ventas);
        this.arrendamientos = new Arrendamientos(this.$setVersion, this.$ingresos.arrendamientos);
    }
    toJSON() {
        return {
            venta: this.ventas.toJSON(),
            arrendamientos: this.arrendamientos.toJSON(),
        };
    }
}
class Ventas {
    constructor($setVersion, $ventas) {
        this.$setVersion = $setVersion;
        this.$ventas = $ventas;
        this.items = [];
        watch(this.$ventas.length, (length, oldLength) => {
            if (length === oldLength) {
                return;
            }
            this.items = [];
            if (!this.$ventas.edit) {
                return;
            }
            this.items = this.$ventas.items.value.map((venta, index) => new VentaStatic(this.$ventas.items.value, index));
            this.items.unshift(new VentaEditable(this.$setVersion, this.$ventas.edit));
        }, { immediate: true, flush: 'sync' });
    }
    toJSON() {
        if (this.items.length) {
            return this.items[0].toJSON();
        }
    }
    get totalValorVenta() {
        return this.$ventas.totalValorVenta.value;
    }
    get total() {
        return {
            nombre: this.$ventas.nombre,
            valorVenta: this.$ventas.totalValorVenta.value,
            ventasPercentage: this.$ventas.ventasPercentage,
            ventasUnidades: this.$ventas.ventasUnidades.value,
            ventasMetros: this.$ventas.ventasMetros.value,
            costeEjecucion: this.$ventas.costeEjecucion.value,
            margenBrutoExplotacion: this.$ventas.margenBrutoExplotacion.value,
            mbce: this.$ventas.mbce.value,
        };
    }
}
class VentaEditable {
    constructor($setVersion, $venta) {
        this.$setVersion = $setVersion;
        this.$venta = $venta;
    }
    get isEditable() {
        return true;
    }
    toJSON() {
        return serializeInteger(this.$venta.valorVenta.value);
    }
    get nombre() {
        return this.$venta.nombre;
    }
    get valorVenta() {
        return this.$venta.valorVenta.value;
    }
    set valorVenta(val) {
        this.$venta.valorVenta.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ventasPercentage() {
        return this.$venta.ventasPercentage.value;
    }
    get ventasUnidades() {
        return this.$venta.ventasUnidades.value;
    }
    get ventasMetros() {
        return this.$venta.ventasMetros.value;
    }
    get costeEjecucion() {
        return this.$venta.costeEjecucion.value;
    }
    get margenBrutoExplotacion() {
        return this.$venta.margenBrutoExplotacion.value;
    }
    get mbece() {
        return this.$venta.mbece.value;
    }
}
class VentaStatic {
    constructor($ventas, $index) {
        this.$ventas = $ventas;
        this.$index = $index;
    }
    get isEditable() {
        return false;
    }
    toJSON() {
        return undefined;
    }
    get nombre() {
        return this.$ventas[this.$index].nombre;
    }
    get valorVenta() {
        return this.$ventas[this.$index].valorVenta.value;
    }
    set valorVenta(val) {
        throw new Error(`cannot modify valorVenta in a static venta`);
    }
    get ventasPercentage() {
        return this.$ventas[this.$index].ventasPercentage.value;
    }
    get ventasUnidades() {
        return this.$ventas[this.$index].ventasUnidades.value;
    }
    get ventasMetros() {
        return this.$ventas[this.$index].ventasMetros.value;
    }
    get costeEjecucion() {
        return this.$ventas[this.$index].costeEjecucion.value;
    }
    get margenBrutoExplotacion() {
        return this.$ventas[this.$index].margenBrutoExplotacion.value;
    }
    get mbece() {
        return this.$ventas[this.$index].mbece.value;
    }
}
class Arrendamientos {
    constructor($setVersion, $arrendamientos) {
        this.$setVersion = $setVersion;
        this.$arrendamientos = $arrendamientos;
        this.items = [];
        watch(this.$arrendamientos.length, (length, oldLength) => {
            if (length === oldLength) {
                return;
            }
            this.items = [];
            if (!length) {
                return;
            }
            this.items = this.$arrendamientos.items.value.map((arrendamiento, index) => new ArrendamientoEditable(this.$setVersion, this.$arrendamientos.items.value, index));
            this.items.unshift(new ArrendamientoStatic(this.$arrendamientos.static.value));
        }, { immediate: true, flush: 'sync' });
    }
    toJSON() {
        return this.items.map(arrendamiento => arrendamiento.toJSON());
    }
    get total() {
        return {
            isEditable: false,
            toJSON() { return {}; },
            nombre: this.$arrendamientos.nombre,
            renta: this.$arrendamientos.renta.value,
            desoc: this.$arrendamientos.desoc.value,
            desocImporte: this.$arrendamientos.desocImporte.value,
            gExpl: this.$arrendamientos.gExpl.value,
            noi: this.$arrendamientos.noi.value,
            ree: this.$arrendamientos.ree.value,
            yield: this.$arrendamientos.yield.value,
        };
    }
}
class ArrendamientoStatic {
    constructor($arrendamiento) {
        this.$arrendamiento = $arrendamiento;
        this.isEditable = false;
    }
    toJSON() {
        return {};
    }
    get nombre() {
        return this.$arrendamiento.nombre;
    }
}
class ArrendamientoEditable {
    constructor($setVersion, $arrendamientos, $index) {
        this.$setVersion = $setVersion;
        this.$arrendamientos = $arrendamientos;
        this.$index = $index;
        this.isEditable = true;
    }
    toJSON() {
        return {
            renta: serializeInteger(this.$arrendamientos[this.$index].renta.value),
            desoc: serializePercentage(this.$arrendamientos[this.$index].desoc.value),
            gExpl: serializeInteger(this.$arrendamientos[this.$index].gExpl.value),
            yield: serializePercentage(this.$arrendamientos[this.$index].yield.value),
        };
    }
    get nombre() {
        return this.$arrendamientos[this.$index].nombre;
    }
    get renta() {
        return this.$arrendamientos[this.$index].renta.value;
    }
    set renta(val) {
        this.$arrendamientos[this.$index].renta.value = val;
        this.$setVersion((v) => v + 1);
    }
    get desoc() {
        return this.$arrendamientos[this.$index].desoc.value;
    }
    set desoc(val) {
        this.$arrendamientos[this.$index].desoc.value = val;
        this.$setVersion((v) => v + 1);
    }
    get gExpl() {
        return this.$arrendamientos[this.$index].gExpl.value;
    }
    set gExpl(val) {
        this.$arrendamientos[this.$index].gExpl.value = val;
        this.$setVersion((v) => v + 1);
    }
    get yield() {
        return this.$arrendamientos[this.$index].yield.value;
    }
    set yield(val) {
        this.$arrendamientos[this.$index].yield.value = val;
        this.$setVersion((v) => v + 1);
    }
    get desocImporte() {
        return this.$arrendamientos[this.$index].desocImporte.value;
    }
    get noi() {
        return this.$arrendamientos[this.$index].noi.value;
    }
    get ree() {
        return this.$arrendamientos[this.$index].ree.value;
    }
}
class OtrosGastos {
    constructor($setVersion, $otrosGastos) {
        this.$setVersion = $setVersion;
        this.$otrosGastos = $otrosGastos;
    }
    toJSON() {
        return {
            ratioDireccion: serializePercentage(this.$otrosGastos.direccion.ratio.value),
            ratioActivacion: serializePercentage(this.$otrosGastos.activacion.ratio.value),
            ratioComercializacion: serializePercentage(this.$otrosGastos.comercializacion.ratio.value),
            ratioGestionIntegral: serializePercentage(this.$otrosGastos.gestionIntegral.ratio.value),
            ratioVentaViviendas: serializePercentage(this.$otrosGastos.ventaViviendas.ratio.value),
            ratioVentaOtros: serializePercentage(this.$otrosGastos.ventaOtros.ratio.value),
            ratioGestionInmuebles: serializePercentage(this.$otrosGastos.gestionInmuebles.ratio.value),
            ratioMarketing: serializePercentage(this.$otrosGastos.marketing.ratio.value),
            ratioContabilidad: serializePercentage(this.$otrosGastos.contabilidad.ratio.value),
            ratioOtros: serializePercentage(this.$otrosGastos.otros.ratio.value),
        };
    }
    get ratioDireccion() {
        return this.$otrosGastos.direccion.ratio.value;
    }
    set ratioDireccion(val) {
        this.$otrosGastos.direccion.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioActivacion() {
        return this.$otrosGastos.activacion.ratio.value;
    }
    set ratioActivacion(val) {
        this.$otrosGastos.activacion.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioComercializacion() {
        return this.$otrosGastos.comercializacion.ratio.value;
    }
    set ratioComercializacion(val) {
        this.$otrosGastos.comercializacion.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioGestionIntegral() {
        return this.$otrosGastos.gestionIntegral.ratio.value;
    }
    set ratioGestionIntegral(val) {
        this.$otrosGastos.gestionIntegral.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioVentaViviendas() {
        return this.$otrosGastos.ventaViviendas.ratio.value;
    }
    set ratioVentaViviendas(val) {
        this.$otrosGastos.ventaViviendas.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioVentaOtros() {
        return this.$otrosGastos.ventaOtros.ratio.value;
    }
    set ratioVentaOtros(val) {
        this.$otrosGastos.ventaOtros.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioGestionInmuebles() {
        return this.$otrosGastos.gestionInmuebles.ratio.value;
    }
    set ratioGestionInmuebles(val) {
        this.$otrosGastos.gestionInmuebles.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioMarketing() {
        return this.$otrosGastos.marketing.ratio.value;
    }
    set ratioMarketing(val) {
        this.$otrosGastos.marketing.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioContabilidad() {
        return this.$otrosGastos.contabilidad.ratio.value;
    }
    set ratioContabilidad(val) {
        this.$otrosGastos.contabilidad.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get ratioOtros() {
        return this.$otrosGastos.otros.ratio.value;
    }
    set ratioOtros(val) {
        this.$otrosGastos.otros.ratio.value = val;
        this.$setVersion((v) => v + 1);
    }
    get direccion() {
        return {
            nombre: this.$otrosGastos.direccion.nombre,
            base: this.$otrosGastos.direccion.base.value,
            ratio: this.$otrosGastos.direccion.ratio.value,
            importe: this.$otrosGastos.direccion.importe.value,
        };
    }
    get activacion() {
        return {
            nombre: this.$otrosGastos.activacion.nombre,
            base: this.$otrosGastos.activacion.base.value,
            ratio: this.$otrosGastos.activacion.ratio.value,
            importe: this.$otrosGastos.activacion.importe.value,
        };
    }
    get comercializacion() {
        return {
            nombre: this.$otrosGastos.comercializacion.nombre,
            base: this.$otrosGastos.comercializacion.base.value,
            ratio: this.$otrosGastos.comercializacion.ratio.value,
            importe: this.$otrosGastos.comercializacion.importe.value,
        };
    }
    get gestionIntegral() {
        return {
            nombre: this.$otrosGastos.gestionIntegral.nombre,
            base: this.$otrosGastos.gestionIntegral.base.value,
            ratio: this.$otrosGastos.gestionIntegral.ratio.value,
            importe: this.$otrosGastos.gestionIntegral.importe.value,
        };
    }
    get ventaViviendas() {
        return {
            nombre: this.$otrosGastos.ventaViviendas.nombre,
            base: this.$otrosGastos.ventaViviendas.base.value,
            ratio: this.$otrosGastos.ventaViviendas.ratio.value,
            importe: this.$otrosGastos.ventaViviendas.importe.value,
        };
    }
    get ventaOtros() {
        return {
            nombre: this.$otrosGastos.ventaOtros.nombre,
            base: this.$otrosGastos.ventaOtros.base.value,
            ratio: this.$otrosGastos.ventaOtros.ratio.value,
            importe: this.$otrosGastos.ventaOtros.importe.value,
        };
    }
    get gestionInmuebles() {
        return {
            nombre: this.$otrosGastos.gestionInmuebles.nombre,
            base: this.$otrosGastos.gestionInmuebles.base.value,
            ratio: this.$otrosGastos.gestionInmuebles.ratio.value,
            importe: this.$otrosGastos.gestionInmuebles.importe.value,
        };
    }
    get marketing() {
        return {
            nombre: this.$otrosGastos.marketing.nombre,
            base: this.$otrosGastos.marketing.base.value,
            ratio: this.$otrosGastos.marketing.ratio.value,
            importe: this.$otrosGastos.marketing.importe.value,
        };
    }
    get contabilidad() {
        return {
            nombre: this.$otrosGastos.contabilidad.nombre,
            base: this.$otrosGastos.contabilidad.base.value,
            ratio: this.$otrosGastos.contabilidad.ratio.value,
            importe: this.$otrosGastos.contabilidad.importe.value,
        };
    }
    get otros() {
        return {
            nombre: this.$otrosGastos.otros.nombre,
            base: this.$otrosGastos.otros.base.value,
            ratio: this.$otrosGastos.otros.ratio.value,
            importe: this.$otrosGastos.otros.importe.value,
        };
    }
    get total() {
        return {
            nombre: this.$otrosGastos.total.nombre,
            base: this.$otrosGastos.total.base.value,
            ratio: this.$otrosGastos.total.ratio.value,
            importe: this.$otrosGastos.total.importe.value,
        };
    }
}
