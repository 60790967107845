
import React, { useState } from 'react'

import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import { webinterna } from 'services/discovery'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { Cell, HeaderCell } from 'components/Table'
import Selector from 'components/simple-forms/Selector'
import Throbber from 'components/Throbber'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import { useNavigate } from 'hooks/navigate'


const provinciasClient = new ProvinciasClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())


export default function MunicipiosList() {
  let { table, setRows } = usePaginatedTable()
  
  let [provincias, setProvincias] = useState([])
  let [provincia, setProvincia] = useState(null)

  useNavigate(async () => {
    let { provincias } = await provinciasClient.List()

    provincias = provincias.map(provincia => {
      return { label: provincia.nombre, value: provincia.name }
    })
    setProvincias(provincias)
  })

  async function loadProvincia(provincia) {
    let { municipios } = await municipiosClient.Search({ provincia })
    setRows(municipios.map(row => {
      row.$search = [
        row.nombre,
      ]
      return row
    }))
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/municipios">Municipios</Breadcrumb>
        </Breadcrumbs>
      }
    >
      {provincias.length ?
        <CardPaginatedTable
          title="Municipios"
          subtitle="Buscar municipios por provincia."
          empty="No hay municipios que cumplan con el criterio de búsqueda"
          table={table}
          actions={
            <Selector
              className="w-52"
              name="provincia"
              value={provincia}
              options={provincias}
              onChange={value => {
                setRows([])
                setProvincia(value)
                loadProvincia(value)
              }}
            />
          }
          header={
            <HeaderCell>Nombre</HeaderCell>
          }
          row={row => (
            <Cell>
              {row.nombre}
            </Cell>
          )}
        >
        </CardPaginatedTable>
        :
        <Throbber/>
      }
    </PageLayout>
  )
}
