
import React from 'react'
import cx from 'classnames'

export default function SectionTitle({ children, fit }) {
  return (
    <div className={cx(
      'text-base leading-6 font-medium text-gray-700 mb-4',
      { 'mt-16': !fit },
    )}>
      {children}
    </div>
  )
}
