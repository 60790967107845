
import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { some } from 'lodash-es'
import firstBy from 'thenby'

import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import CardTable from 'components/CardTable'
import { putEntity, listEntities, getEntity } from 'models/docs/entities'
import { useNavigate } from 'hooks/navigate'


export default function ArchiveRoomsEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [room, setRoom] = useState({})
  let [form, setForm] = useState({})
  let [lockers, setLockers] = useState([])

  useNavigate(async () => {
    if (name) {
      let reply = await getEntity(`archive-rooms/${name}`)
      setRoom(reply)
      setForm(reply)
      let lockersReply = await listEntities('archive-rooms-lockers', reply.name)
      lockersReply = lockersReply.sort(firstBy('displayName'))
      setLockers(lockersReply)
    }
  })

  async function save() {
    let reply = await putEntity('archive-rooms', { archiveRoom: form })
    if (!name) {
      history.push(`/manage/${reply.name}`)
      return
    }
    setRoom(reply)
    setForm(reply)
  }

  function editField(locker) {
    history.push(`/manage/${locker}`)
  }

  let changed = some([
    room.displayName !== form.displayName,
  ]) && form.displayName

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/archive-rooms">Habitaciones</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/archive-rooms/${name}`}>{room.displayName}</Breadcrumb>
            :
            <Breadcrumb url="/manage/archive-rooms/create">Crear Habitación</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos generales"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <Input
          label="Nombre"
          value={form.displayName}
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        ></Input>
      </CardHeader>
      {name &&
        <CardTable
          title="Armarios"
          subtitle="Armarios que pertenecen a la habitación."
          empty="No hay armarios en esta habitación"
          header={
            <>
              <HeaderCell>Nombre</HeaderCell>
              <HeaderCell></HeaderCell>
            </>
          }
          actions={
            <ButtonLinkPrimary to={`/manage/archive-rooms/${name}/archive-rooms-lockers/add`}>
              <Icon solid name="plus" className="mr-2" />
              Añadir armario
            </ButtonLinkPrimary>
          }
        >
          {lockers?.map(locker => (
            <TableRow key={locker.name}>
              <Cell className="w-full">
                <Link to="#" onClick={() => editField(locker.name)} className="text-gray-900">{locker.displayName}</Link>
              </Cell>
              <Cell>
                <ButtonSecondary onClick={() => editField(locker.name)}>
                  <Icon solid name="pencil" />
                </ButtonSecondary>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
      }
    </PageLayout>
  )
}
