
import React from 'react'
import { Link } from 'react-router-dom'

import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { users, webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeBlue from 'components/badges/BadgeBlue'
import BadgeOrange from 'components/badges/BadgeOrange'
import { parseDate, parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import { validationType, validationDescriptor } from 'models/webinterna/validations'
import PageLayout from 'layout/webinterna/PageLayout'
import UsersClient from 'services/Users/users/users'
import { keyBy } from 'lodash-es'


const client = new ValidacionesClient(webinterna())
const usersClient = new UsersClient(users())

const ACCIONES = {
  ACCION_CREAR: 'Nueva entidad',
  ACCION_EDITAR: 'Cambio pendiente',
  ACCION_ELIMINAR: 'Entidad eliminada',
}


export default function ValidacionesList() {
  let {
    table,
    setRows,
    useAsyncLoad,
  } = useDatatable()

  useAsyncLoad(async () => {
    let { validaciones } = await client.List()
    if (!validaciones) {
      validaciones = []
    }

    let { users } = await usersClient.List()
    users = users || []
    users = keyBy(users, 'name')

    validaciones.forEach(validacion => {
      validacion.creacionTime = parseTimestamp(validacion.creacionTime)

      let desc = validationDescriptor(validacion)
      validacion.tipoEntidad = desc.label
      validacion.url = `/webinterna/validaciones/${desc.slug}/${validacion.name}`

      let key = validationType(validacion)
      switch (key) {
      case 'persona':
        if (validacion.entidad[key].fisica?.fisica) {
          validacion.nombre = validacion.entidad[key].fisica.fisica.nombre
        } else if (validacion.entidad[key].juridica) {
          validacion.nombre = validacion.entidad[key].juridica.denominacionSocial
        } else {
          validacion.nombre = users[validacion.entidad[key].fisica.contribuidor.user].displayName
        }
        break
      case 'inmueble':
        validacion.nombre = validacion.entidad[key].nombre
        break
      case 'fincaRegistral':
        validacion.nombre = validacion.entidad[key].numeroFinca
        break
      case 'elementoPatrimonial':
        validacion.nombre = validacion.entidad[key].nombre
        break
      case 'fincaCatastral':
        validacion.nombre = validacion.entidad[key].nombre
        break
      case 'presupuesto':
        validacion.nombre = 'Presupuesto ' + (validacion.entidad[key].elaboracionDate ?  'de ' + datetime(parseDate(validacion.entidad[key].elaboracionDate), 'DATE') : 'sin fecha')
        break
      default:
        validacion.nombre = validacion.entidad[key].denominacion
        break
      }

      validacion.textoAccion = ACCIONES[validacion.accion]
      validacion.$search = [
        validacion.nombre,
        validacion.tipoEntidad,
        validacion.textoAccion,
      ]
    })
    setRows(validaciones)
  })

  return (
    <PageLayout wide>
      <PageHeader>
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/validaciones">Validaciones</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell>Tipo Entidad</HeaderCell>
            <HeaderCell>Acción</HeaderCell>
            <HeaderCell>Último cambio</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={row.url}>
            <div>
              <span className="font-medium text-gray-900">{row.nombre}</span>
              &nbsp;&mdash;&nbsp;
              {row.tipoEntidad}
            </div>
            <div className="mt-1">
              <span className="mr-2">
                {row.accion === 'ACCION_CREAR' && <BadgeGreen>{row.textoAccion}</BadgeGreen>}
                {row.accion === 'ACCION_EDITAR' && <BadgeBlue>{row.textoAccion}</BadgeBlue>}
                {row.accion === 'ACCION_ELIMINAR' && <BadgeOrange>{row.textoAccion}</BadgeOrange>}
              </span>
              {datetime(row.creacionTime, 'DATETIME')}
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell wrap>
              <Link
                to={row.url}
                className="font-medium text-gray-900"
              >
                {row.nombre}
              </Link>
            </Cell>
            <Cell>
              {row.tipoEntidad}
            </Cell>
            <Cell>
              {row.accion === 'ACCION_CREAR' && <BadgeGreen>{row.textoAccion}</BadgeGreen>}
              {row.accion === 'ACCION_EDITAR' && <BadgeBlue>{row.textoAccion}</BadgeBlue>}
              {row.accion === 'ACCION_ELIMINAR' && <BadgeOrange>{row.textoAccion}</BadgeOrange>}
            </Cell>
            <Cell>
              {datetime(row.creacionTime, 'DATETIME')}
            </Cell>
            <Cell className="text-right">
              <Link to={row.url}>Validar</Link>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
