
import React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Icon({ name, solid, regular, light, brands, duotone, variant, className, spin, fixedWidth, $stack }) {
  if (!solid && !regular && !light && !brands && !variant && !duotone) {
    throw new Error(`at least one of (solid, regular, light, brands, duotone, variant) props is required in <Icon>`)
  }

  let icon = [variant, name]
  if (solid) {
    icon[0] = 'fas'
  }
  if (regular) {
    icon[0] = 'far'
  }
  if (light) {
    icon[0] = 'fal'
  }
  if (brands) {
    icon[0] = 'fab'
  }
  if (duotone) {
    icon[0] = 'fad'
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      className={cx(
        className,
        {
          [`fa-stack-${$stack}x`]: $stack,
        },
      )}
      spin={spin}
      fixedWidth={fixedWidth}
    />
  )
}


Icon.Stack = function ({ children, className }) {
  return (
    <div className={cx('fa-stack', className)}>
      {React.cloneElement(children[0], { $stack: 1 })}
      {React.cloneElement(children[1], { $stack: 2 })}
    </div>
  )
}
