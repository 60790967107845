
import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import {
  parseShortValidations,
  validationMessage,
  hasError,
} from 'platform/forms'

const DURATION_UNITS = [
  { value: 'UNIT_HOURS', label: 'Horas' },
  { value: 'UNIT_DAYS', label: 'Días' },
  { value: 'UNIT_WEEKS', label: 'Semanas' },
  { value: 'UNIT_MONTHS', label: 'Meses' },
]

export default function Duration({ name, label, form, value, help, validations, className }) {
  let [number, setNumber] = useState('')
  let [unit, setUnit] = useState('UNIT_DAYS')
  let checks = parseShortValidations(validations)

  useEffect(() => {
    form.register({ name }, checks)

    return () => form.unregister(name)
  }, [name, validations]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value) {
      setNumber(value.value)
      setUnit(value.unit)
      form.setValue(name, value)
    } else {
      setNumber('')
      setUnit('UNIT_DAYS')
      form.setValue(name, null)
    }
  }, [value, setNumber, setUnit, name]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleNumberChange(event) {
    let number = event.target.value
    setNumber(number)

    if (number) {
      form.setValue(name, { value: number, unit })
    } else {
      form.setValue(name, null)
    }
  }

  function handleUnitChange(event) {
    let unit = event.target.value
    setUnit(unit)

    if (number) {
      form.setValue(name, { value: number, unit })
    } else {
      form.setValue(name, null)
    }
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium leading-5 text-gray-700 text-sm w-full',
          { 'text-red-700': hasError(form, name) },
        )}
      >
        {label}
        {checks && checks.required && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 flex">
        <div className="w-19 mb-1 mr-2">
          <div className="relative rounded-md shadow-sm">
            <input
              type="number"
              name={name}
              id={name}
              min="0"
              className={cx(
                'form-input block shadow-sm focus:ring-teal-500 focus:border-teal-500 w-20 sm:text-sm border-gray-300 rounded-md',
                {
                  'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red': hasError(form, name),
                },
              )}
              value={number}
              onChange={handleNumberChange}
            />
          </div>
        </div>
        <div className="w-36 mb-1">
          <div className="relative rounded-md shadow-sm">
            <select
              name={`${name}Select`}
              id={`${name}Select`}
              className={cx(
                'form-select block w-full focus:ring-teal-500 focus:border-teal-500 shadow-sm sm:text-sm border-gray-300 rounded-md',
                {
                  'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red': hasError(form, name),
                },
              )}
              value={unit}
              onChange={handleUnitChange}
            >
              {DURATION_UNITS.map(option => {
                return (
                  <option key={option.value.trim()} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {help && <p className="text-gray-500">{help}</p>}
        {hasError(form, name) &&
          <p className="text-red-700">
            {validationMessage(checks, form.errors[name])}
          </p>
        }
      </div>
    </div>
  )
}
