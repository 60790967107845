
import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { webinterna } from 'services/discovery'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Datepicker from 'components/forms/Datepicker'
import Percent from 'components/forms/Percent'
import Checkbox from 'components/forms/Checkbox'
import CardHeader from 'components/CardHeader'
import { WideList, WideListInteractiveItem, WideListEmpty } from 'components/WideList'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import { parseDate, serializeDate } from 'platform/datetime'
import resolver from 'platform/resolver'
import { listPersonasSociedades } from 'models/webinterna/personas-sociedades'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import SingleEntity from 'components/forms/SingleEntity'
import RegistrosMercantilesClient from 'services/WebInterna/registros_mercantiles/registros_mercantiles'
import ImpuestosActividadClient from 'services/WebInterna/impuestos_actividad/impuestos_actividad'
import CardTable from 'components/CardTable'
import { Cell, HeaderCell, Table, TableRow } from 'components/Table'
import { keyBy } from 'lodash'
import EntityLink from 'components/EntityLink'
import { datetime, percentage, price } from 'platform/filters'
import Price from 'components/forms/Price'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import Locality from 'components/forms/Locality'
import People from 'components/forms/People'
import { useNavigate } from 'hooks/navigate'


const sociedadesClient = new SociedadesClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const registrosClient = new RegistrosMercantilesClient(webinterna())
const impuestosClient = new ImpuestosActividadClient(webinterna())


function useSociedad({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [sociedad, setSociedad] = useState({})
  let [validation, setValidation] = useState(null)

  async function parse(sociedad) {
    sociedad.constitucionFecha = parseDate(sociedad.constitucionFecha)
    sociedad.extincionFecha = parseDate(sociedad.extincionFecha)
    sociedad.finEjercicioDate = parseDate(sociedad.finEjercicioDate)
    sociedad.participacionesTotales = sociedad.socios
      ?.map(socio => socio.rangos?.reduce((prev, rango) => prev + rango.endParticipaciones - rango.startParticipaciones, 0))
      .reduce((prev, rango) => prev + rango, 0)
    sociedad.socios = sociedad.socios?.map(socio => {
      return {
        ...socio,
        ghostKey: nanoid(),
        rangos: socio.rangos.map(rango => {
          return {
            ...rango,
            ghostKey: nanoid(),
          }
        }),
      }
    })
    if (!sociedad.participacionesTotales) {
      sociedad.participacionesTotales = 0
    }
  }

  async function load() {
    if (isEdit) {
      let reply = await sociedadesClient.Get({ name: name })
      parse(reply)
      setSociedad(reply)
    }
  }
  
  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    parse(reply.entidad.sociedad)
    setSociedad(reply.entidad.sociedad)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        sociedad: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de esta sociedad para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/sociedades/${name}`)
    } else {
      history.push(`/webinterna/sociedades`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await sociedadesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await sociedadesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await sociedadesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        sociedad: {
          ...sociedad,
          constitucionFecha: serializeDate(sociedad.constitucionFecha),
          extincionFecha: serializeDate(sociedad.extincionFecha),
          finEjercicioDate: serializeDate(sociedad.finEjercicioDate),
          cargas: sociedad.cargas.map(carga => {
            carga.expedicionFecha = serializeDate(carga.expedicionFecha)
            carga.caducidadFecha = serializeDate(carga.caducidadFecha)
            return carga
          }),
          socios: sociedad.socios?.map(socio => {
            return {
              ...socio,
              rangos: socio.rangos && socio.rangos.map(rango => {
                rango.fecha = serializeDate(rango.fecha)
                rango.altaFecha = serializeDate(rango.altaFecha)
                rango.bajaFecha = serializeDate(rango.bajaFecha)
                return rango
              }),
            }
          }),
          apoderados: sociedad.apoderados?.map(apoderado => {
            apoderado.fecha = serializeDate(apoderado.fecha)
            apoderado.caducidadFecha = serializeDate(apoderado.caducidadFecha)
            return apoderado
          }),
          administradores: sociedad.administradores?.map(administrador => {
            administrador.fecha = serializeDate(administrador.fecha)
            administrador.caducidadFecha = serializeDate(administrador.caducidadFecha)
            return administrador
          }),
          titulares: sociedad.titulares?.map(titular => {
            titular.fecha = serializeDate(titular.fecha)
            return titular
          }),
        },
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de esta sociedad para que lo apruebe un administrador.')
    history.push(`/webinterna/sociedades`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    sociedad,
    validation,
    remove,
  }
}


function useCargas({ sociedad }) {
  let [cargas, setCargas] = useState([])

  function add() {
    setCargas([
      ...cargas,
      {
        ghostKey: nanoid(),
      },
    ])
  }

  function remove(carga) {
    setCargas(cargas.filter(c => c !== carga))
  }

  function setStartParticipaciones(start, index) {
    cargas[index].startParticipaciones = start
    setCargas(cargas)
  }

  function setEndParticipaciones(end, index) {
    cargas[index].endParticipaciones = end
    setCargas(cargas)
  }

  function setTipo(tipo, index) {
    cargas[index].tipo = tipo
    setCargas(cargas)
  }

  function setBeneficiario(beneficiario, index) {
    cargas[index].beneficiario = beneficiario
    setCargas(cargas)
  }
  
  function setTitulo(titulo, index) {
    cargas[index].titulo = titulo
    setCargas(cargas)
  }

  function setNotario(notario, index) {
    cargas[index].notario = notario
    setCargas(cargas)
  }

  function setProtocolo(protocolo, index) {
    cargas[index].protocolo = protocolo
    setCargas(cargas)
  }

  function setFechaExpedicion(fecha, index) {
    cargas[index].expedicionFecha = fecha
    setCargas(cargas)
  }

  function setFechaCaducidad(fecha, index) {
    cargas[index].caducidadFecha = fecha
    setCargas(cargas)
  }

  function setObligacionGarantizada(obl, index) {
    cargas[index].obligacionGarantizada = obl
    setCargas(cargas)
  }

  function setImporteGarantizado(imp, index) {
    cargas[index].importeGarantizado = imp
    setCargas(cargas)
  }

  function setEstado(estado, index) {
    cargas[index].estado = estado
    setCargas(cargas)
  }

  useEffect(() => {
    if (sociedad?.cargas?.length) {
      setCargas(sociedad.cargas.map(carga => {
        carga.expedicionFecha = parseDate(carga.expedicionFecha)
        carga.caducidadFecha = parseDate(carga.caducidadFecha)
        return {
          ...carga,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setCargas, sociedad])

  return {
    cargas,
    setStartParticipaciones,
    setEndParticipaciones,
    setTipo,
    setBeneficiario,
    setTitulo,
    setNotario,
    setProtocolo,
    setFechaCaducidad,
    setFechaExpedicion,
    setObligacionGarantizada,
    setImporteGarantizado,
    setEstado,
    add,
    remove,
  }
}


function useSocios({ sociedad, personasSociedades }) {
  let [socios, setSocios] = useState([])

  function add() {
    setSocios([
      ...socios,
      {
        ghostKey: nanoid(),
        rangos: [],
      },
    ])
  }

  function remove(socio) {
    setSocios(socios.filter(s => s !== socio))
  }

  function setPropietario(propietario, index) {
    if (propietario) {
      let personasSociedadesKey = keyBy(personasSociedades, 'value')
      socios[index].propietario = propietario
      socios[index].propietarioDetails = personasSociedadesKey[Object.values(propietario)[0]]
      setSocios([...socios])
    } else {
      socios[index].propietario = null
    }
  }

  function addRango(socioIndex) {
    if (!socios[socioIndex].rangos) {
      socios[socioIndex].rangos = [
        {
          ghostKey: nanoid(),
        },
      ]
    } else {
      socios[socioIndex].rangos = [
        ...socios[socioIndex].rangos,
        {
          ghostKey: nanoid(),
        },
      ]
    }
    setSocios([...socios])
  }

  function removeRango(socioIndex, rango) {
    socios[socioIndex].rangos = socios[socioIndex].rangos.filter(r => r !== rango)
    setSocios([...socios])
  }

  function setStartParticipaciones(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].startParticipaciones = value
    setSocios([...socios])
  }

  function setEndParticipaciones(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].endParticipaciones = value
    setSocios([...socios])
  }

  function setTitulo(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].titulo = value
    setSocios([...socios])
  }

  function setNotario(value, currentSocio, currentRango) {
    let personasSociedadesKey = keyBy(personasSociedades, 'value')
    socios[currentSocio].rangos[currentRango].notario = value
    socios[currentSocio].rangos[currentRango].notarioDetails = personasSociedadesKey[value]
    setSocios([...socios])
  }

  function setProtocolo(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].protocolo = value
    setSocios([...socios])
  }

  function setFecha(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].fecha = value
    setSocios([...socios])
  }

  function setRelacionPropiedad(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].relacionPropiedad = value
    setSocios([...socios])
  }

  function setAltaFecha(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].altaFecha = value
    setSocios([...socios])
  }

  function setBajaFecha(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].bajaFecha = value
    setSocios([...socios])
  }

  function setPrima(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].prima = value
    setSocios([...socios])
  }

  function setValorUnitario(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].valorUnitario = value
    setSocios([...socios])
  }

  function setClase(value, currentSocio, currentRango) {
    socios[currentSocio].rangos[currentRango].clase = value
    setSocios([...socios])
  }

  useEffect(() => {
    if (sociedad?.socios?.length) {
      let personasSociedadesKey = keyBy(personasSociedades, 'value')
      setSocios(sociedad.socios.map(socio => {
        return {
          ...socio,
          propietarioDetails: personasSociedadesKey[Object.values(socio.propietario)[0]],
          rangos: socio.rangos?.map(rango => {
            return {
              ...rango,
              notarioDetails: personasSociedadesKey[rango.notario],
              altaFecha: parseDate(rango.altaFecha),
              bajaFecha: parseDate(rango.bajaFecha),
              fecha: parseDate(rango.fecha),
            }
          }),
          ghostKey: nanoid(),
        }
      }))
    }
  }, [personasSociedades, setSocios, sociedad])

  return {
    socios,
    add,
    remove,
    setPropietario,
    addRango,
    removeRango,
    setStartParticipaciones,
    setEndParticipaciones,
    setTitulo,
    setNotario,
    setProtocolo,
    setFecha,
    setAltaFecha,
    setBajaFecha,
    setRelacionPropiedad,
    setPrima,
    setClase,
    setValorUnitario,
  }
}


function useApoderados({ sociedad }) {
  let [apoderados, setApoderados] = useState([])

  function add() {
    setApoderados([
      ...apoderados,
      {
        ghostKey: nanoid(),
      },
    ])
  }

  function remove(apoderado) {
    setApoderados(apoderados.filter(a => a !== apoderado))
  }

  useEffect(() => {
    if (sociedad?.apoderados?.length) {
      setApoderados(sociedad.apoderados.map(apoderado => {
        apoderado.fecha = parseDate(apoderado.fecha)
        apoderado.nombramientoFecha = parseDate(apoderado.nombramientoFecha)
        apoderado.caducidadFecha = parseDate(apoderado.caducidadFecha)
        return {
          ...apoderado,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setApoderados, sociedad])

  return {
    apoderados,
    add,
    remove,
  }
}


function useAdministradores({ sociedad }) {
  let [administradores, setAdministradores] = useState([])

  function add() {
    setAdministradores([
      ...administradores,
      {
        ghostKey: nanoid(),
      },
    ])
  }

  function remove(administrador) {
    setAdministradores(administradores.filter(a => a !== administrador))
  }

  useEffect(() => {
    if (sociedad?.administradores?.length) {
      setAdministradores(sociedad.administradores.map(administrador => {
        administrador.fecha = parseDate(administrador.fecha)
        administrador.caducidadFecha = parseDate(administrador.caducidadFecha)
        return {
          ...administrador,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setAdministradores, sociedad])

  return {
    administradores,
    add,
    remove,
  }
}


function useTitulares({ sociedad }) {
  let [titulares, setTitulares] = useState([])

  function add() {
    setTitulares([
      ...titulares,
      {
        ghostKey: nanoid(),
      },
    ])
  }

  function remove(titular) {
    setTitulares(titulares.filter(t => t !== titular))
  }

  useEffect(() => {
    if (sociedad?.titulares?.length) {
      setTitulares(sociedad.titulares.map(titular => {
        titular.fecha = parseDate(titular.fecha)
        return {
          ...titular,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setTitulares, sociedad])

  return {
    titulares,
    add,
    remove,
  }
}


export default function SociedadesEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')

  let [personasSociedades, setPersonasSociedades] = useState([])
  let [registros, setRegistros] = useState([])
  let [impuestos, setImpuestos] = useState([])
  let [currentSocio, setCurrentSocio] = useState(null)
  let [currentRango, setCurrentRango] = useState(null)

  let [currentCarga, setCurrentCarga] = useState(null)

  let {
    save: saveSociedad,
    load: loadSociedad,
    loadValidation,
    confirmValidation,
    sociedad,
    validation,
    remove: removeSociedad,
  } = useSociedad({ name })

  useNavigate(async () => {
    let { personasSociedades, registros, impuestos } = await resolver.all({
      personasSociedades: listPersonasSociedades(),
      registros: registrosClient.List().then(reply => reply.registros || []),
      impuestos: impuestosClient.List().then(reply => reply.impuestos || []),
    })
    personasSociedades = personasSociedades.map(cs => {
      return {
        value: cs.name,
        isPeople: cs.isPeople,
        isCorp: cs.isCorp,
        displayName: cs.nombre,
        email: cs.email,
        pictureUrl: cs.pictureUrl,
        url: cs.isCorp ? `/webinterna/sociedades/${cs.name}` : `/webinterna/personas/${cs.name}`,
      }
    })
    setPersonasSociedades(personasSociedades)
    setRegistros(registros.map(r => {
      return {
        value: r.name,
        label: r.nombre,
      }
    }))
    setImpuestos(impuestos.map(i => {
      return {
        value: i.name,
        label: i.nombre,
      }
    }))

    if (isValidation) {
      await loadValidation()
    } else {
      await loadSociedad()
    }
  })

  let {
    cargas,
    add: addCarga,
    remove: removeCarga,
    setStartParticipaciones,
    setEndParticipaciones,
    setTipo,
    setBeneficiario,
    setTitulo,
    setObligacionGarantizada,
    setImporteGarantizado,
    setEstado,
    setFechaCaducidad,
    setFechaExpedicion,
    setNotario,
    setProtocolo,
  } = useCargas({ sociedad })

  let {
    socios,
    add: addSocio,
    remove: removeSocio,
    setPropietario,
    addRango,
    removeRango,
    setNotario: setNotarioRango,
    setProtocolo: setProtocoloRango,
    setStartParticipaciones: setStartParticipacionesRango,
    setEndParticipaciones: setEndParticipacionesRango,
    setTitulo: setTituloRango,
    setFecha: setFechaRango,
    setAltaFecha: setAltaFechaRango,
    setBajaFecha: setBajaFechaRango,
    setPrima,
    setValorUnitario,
    setClase,
    setRelacionPropiedad,
  } = useSocios({ sociedad, personasSociedades })

  let {
    apoderados,
    add: addApoderado,
    remove: removeApoderado,
  } = useApoderados({ sociedad })

  let {
    administradores,
    add: addAdministrador,
    remove: removeAdministrador,
  } = useAdministradores({ sociedad })

  let {
    titulares,
    add: addTitular,
    remove: removeTitular,
  } = useTitulares({ sociedad })

  let form = useForm(async (data) => {
    cargas.forEach(carga => delete carga.ghostKey)
    socios.forEach(socio => delete socio.ghostKey)
    let req = {
      ...sociedad,
      ...data,
      gestion: sociedad.gestion || data.gestion || 'GESTION_UNKNOWN',
      constitucionFecha: serializeDate(data.constitucionFecha),
      extincionFecha: serializeDate(data.extincionFecha),
      finEjercicioDate: serializeDate(data.finEjercicioDate),
      cargas: cargas.map(carga => {
        carga.expedicionFecha = serializeDate(carga.expedicionFecha)
        carga.caducidadFecha = serializeDate(carga.caducidadFecha)
        return carga
      }),
      socios: socios.map(socio => {
        delete socio.propietarioDetails
        return {
          ...socio,
          rangos: socio.rangos && socio.rangos.map(rango => {
            delete rango.notarioDetails
            delete rango.ghostKey
            rango.fecha = serializeDate(rango.fecha)
            rango.altaFecha = serializeDate(rango.altaFecha)
            rango.bajaFecha = serializeDate(rango.bajaFecha)
            return rango
          }),
        }
      }),
      apoderados: data.apoderados?.map(apoderado => {
        apoderado.fecha = serializeDate(apoderado.fecha)
        apoderado.caducidadFecha = serializeDate(apoderado.caducidadFecha)
        return apoderado
      }),
      administradores: data.administradores?.map(administrador => {
        administrador.fecha = serializeDate(administrador.fecha)
        administrador.caducidadFecha = serializeDate(administrador.caducidadFecha)
        return administrador
      }),
      titulares: data.titulares?.map(titular => {
        titular.fecha = serializeDate(titular.fecha)
        return titular
      }),
    }

    delete req.participacionesTotales

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveSociedad(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !sociedad.blocked &&
            <ButtonConfirmDanger onClick={removeSociedad}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Sociedad
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/sociedades">Sociedades</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/sociedades/${name}`}>Consultar Sociedad</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/sociedades/edit/${name}`}>Editar Sociedad</Breadcrumb>
          : <Breadcrumb url="/webinterna/sociedades/edit/new">Crear Nueva Sociedad</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={sociedad.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:space-x-4">
              <Input form={form} name="cif" label="CIF" validations="cif" value={sociedad.cif} className="w-56 mb-6" />
              <Input form={form} name="acronimo" label="Acrónimo" value={sociedad.acronimo} className="w-32 mb-6" />
              <Input form={form} name="denominacion" label="Título Sociedad" validations="required|min:3" value={sociedad.denominacion} className="flex-1 mb-6" />
            </div>

            <div className="md:flex md:space-x-4">
              <Input form={form} name="tipo" label="Tipo" value={sociedad.tipo} className="w-56 mb-6" />
              <Input form={form} name="duracion" label="Duración" value={sociedad.duracion} className="w-48 mb-6" />
              <Select
                form={form}
                className="w-48 mb-6"
                name="gestion"
                label="Gestión"
                options={[
                  { value: 'GESTION_DIRECTA', label: 'Gestión directa' },
                  { value: 'GESTION_CONTROL', label: 'Control de gestión' },
                  { value: 'GESTION_AJENA', label: 'Gestión ajena' },
                ]}
                value={sociedad.gestion ? sociedad.gestion : ''}
              />
              <Select
                form={form}
                className="w-48 mb-6"
                name="grupo"
                label="Grupo"
                options={[
                  { value: 'ALVORES', label: 'ALVORES' },
                  { value: 'GESTIÓN INTEGRAL ALAMEDA', label: 'GESTIÓN INTEGRAL ALAMEDA' },
                  { value: 'ALBERTI 23 SMARKETING', label: 'ALBERTI 23 SMARKETING' },
                  { value: 'LASCONY', label: 'LASCONY' },
                ]}
                value={sociedad.grupo ? sociedad.grupo : ''}
              />
              <Select
                form={form}
                className="w-48 mb-6"
                name="subgrupo"
                label="Subgrupo"
                options={[
                  { value: 'ZERTUM', label: 'ZERTUM' },
                  { value: 'JAÉN PLAZA', label: 'JAÉN PLAZA' },
                ]}
                value={sociedad.subgrupo ? sociedad.subgrupo : ''}
              />
            </div>

            <div className="md:flex md:space-x-4">
              <Input form={form} name="email" label="Email" value={sociedad.email} className="mb-6 w-72" />
              <Input form={form} name="telefono" label="Teléfono" value={sociedad.telefono} className="w-52 mb-6" />
              <SingleEntity
                form={form}
                name="iae"
                label="Impuesto a la actividad"
                header="Seleccionar impuesto a la actividad"
                options={impuestos}
                value={sociedad.iae}
              />
              <Price form={form} name="capitalSocial" label="Capital social" value={sociedad.capitalSocial} className="w-52 mb-6" />
            </div>

            <div className="md:flex md:space-x-4">
              <Input form={form} name="direccion" label="Dirección" value={sociedad.direccion} className="flex-grow mb-6" />
              <Input form={form} name="codigoPostal" label="Código postal" value={sociedad.codigoPostal} className="w-56" />
              <Locality form={form} name="municipio" label="Municipio" value={sociedad.municipio} />
            </div>

            <div className="md:flex md:space-x-4">
              <Datepicker
                form={form}
                name="finEjercicioDate"
                label="Fin ejercicio"
                value={sociedad.finEjercicioDate}
              />
            </div>
          </fieldset>

          <fieldset className="mt-6">
            <legend className="text-base leading-6 font-medium text-gray-900">Registro Mercantil</legend>
            <div className="md:flex md:space-x-4 mt-4 mb-6">
              <Input form={form} type="number" name="registro.inscripcion" label="Inscripción" value={sociedad.registro?.inscripcion} className="w-32" />
              <SingleEntity
                form={form}
                name="registro.registro"
                label="Registro"
                header="Seleccionar registro"
                options={registros}
                value={sociedad.registro?.registro}
                className="flex-grow"
              />
              <Input form={form} name="registro.tomo" label="Tomo" value={sociedad.registro?.tomo} className="w-32" />
              <Input form={form} name="registro.libro" label="Libro" value={sociedad.registro?.libro} />
            </div>
            <div className="md:flex md:space-x-4">
              <Input form={form} name="registro.folio" label="Folio" value={sociedad.registro?.folio} className="w-32 mb-6" />
              <Input form={form} name="registro.hoja" label="Hoja" value={sociedad.registro?.hoja} className="w-32 mb-6" />
              <Input form={form} name="registro.seccion" label="Sección" value={sociedad.registro?.seccion} className="w-32 mb-6" />
            </div>
          </fieldset>

          <fieldset className="mt-6">
            <legend className="text-base leading-6 font-medium text-gray-900">Escritura</legend>
            <div className="md:flex md:space-x-4 mt-4">
              <Input form={form} name="escritura.titulo" label="Título" value={sociedad.escritura?.titulo} className="mb-6 w-96 flex-1" />
              <div className="w-1/2">
                <People
                  form={form}
                  name={`escritura.notario`}
                  label="Notario"
                  restrict="NOTARIO"
                  value={sociedad.escritura?.notario}
                />
              </div>
            </div>
            <div className="md:flex md:space-x-4">
              <Input form={form} type="number" name="escritura.protocolo" label="Protocolo" value={sociedad.escritura?.protocolo} className="mb-6 w-48" />
              <Datepicker form={form} name="constitucionFecha" label="Fecha Constitución" value={sociedad.constitucionFecha} className="mb-6" />
              <Datepicker form={form} name="extincionFecha" label="Fecha Extinción" value={sociedad.extincionFecha} />
            </div>
          </fieldset>
        </Card>

        {currentRango === null ?
          (currentSocio === null ?
            <CardHeader
              className="mt-8"
              header="Socios"
              actions={
                <ButtonSecondary onClick={() => {
                  setCurrentSocio(socios.length)
                  addSocio()
                }}>
                  <Icon solid name="plus" className="mr-2" />
                  Añadir socio
                </ButtonSecondary>
              }
              fit
            >
              <Table
                header={
                  <>
                    <HeaderCell>Socio</HeaderCell>
                    <HeaderCell>Email</HeaderCell>
                    <HeaderCell>Nº participaciones</HeaderCell>
                    <HeaderCell>Valor nominal</HeaderCell>
                    <HeaderCell>% participación</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </>
                }
              >
                {socios.map((socio, socioIndex) => (
                  <TableRow key={socio.ghostKey}>
                    <Cell>
                      {socio.propietarioDetails && <EntityLink to={`/webinterna/${socio.propietarioDetails.isPeople ? 'personas' : 'sociedades'}/${socio.propietarioDetails.value}`}>{socio.propietarioDetails.displayName}</EntityLink>}
                    </Cell>
                    <Cell>
                      {socio.propietarioDetails?.email}
                    </Cell>
                    <Cell>{socio.rangos?.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0)}</Cell>
                    <Cell>
                      {socio.rangos?.reduce(
                        (total, rango) => total + (rango.endParticipaciones - rango.startParticipaciones) * (rango.valorUnitario ?? 0) / 100, 0,
                      ) ?? 0}
                    </Cell>
                    <Cell>
                      {percentage(sociedad.participacionesTotales
                        ? (socio.rangos?.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0) * 100 / sociedad.participacionesTotales) * 100 ?? 0
                        : 0)
                      }
                    </Cell>
                    <Cell className="text-right">
                      <Link onClick={() => setCurrentSocio(socioIndex)}>Editar</Link>
                    </Cell>
                  </TableRow>
                ))}
              </Table>
            </CardHeader>
            :
            <CardHeader
              className="mt-8"
              header={`Datos del socio ${socios[currentSocio].propietarioDetails ? socios[currentSocio].propietarioDetails.displayName : 'sin nombre'}`}
              fit
              actions={
                <div className="space-x-4">
                  <ButtonSecondary onClick={() => {
                    if (!socios[currentSocio].propietario) {
                      removeSocio(socios[currentSocio])
                    }
                    setCurrentSocio(null)
                  }}>
                    <Icon regular name="chevron-left" className="mr-2" />
                    Volver
                  </ButtonSecondary>
                  <ButtonHoverDanger onClick={() => {
                    removeSocio(socios[currentSocio])
                    setCurrentSocio(null)
                  }}>
                    <Icon solid name="trash" className="mr-2" />
                    Eliminar socio
                  </ButtonHoverDanger>
                  <ButtonSecondary onClick={() => {
                    addRango(currentSocio)
                    setCurrentRango(socios[currentSocio].rangos.length - 1)
                  }}>
                    <Icon solid name="plus" className="mr-2" />
                    Añadir rango
                  </ButtonSecondary>
                </div>
              }
              empty="No hay socios"
            >
              <div className="w-1/2 mt-4 mb-6 ml-8">
                <People
                  form={form}
                  name={`socios[${currentSocio}].propietario`}
                  label="Socio"
                  value={socios[currentSocio].propietario}
                  onChange={value => setPropietario(value, currentSocio)}
                ></People>
              </div>

              <Table
                header={
                  <>
                    <HeaderCell>Rango</HeaderCell>
                    <HeaderCell>Título</HeaderCell>
                    <HeaderCell>Notario</HeaderCell>
                    <HeaderCell>Protocolo</HeaderCell>
                    <HeaderCell>Fecha</HeaderCell>
                    <HeaderCell>Relación de propiedad</HeaderCell>
                    <HeaderCell>Alta participación</HeaderCell>
                    <HeaderCell>Baja participación</HeaderCell>
                    <HeaderCell>Prima</HeaderCell>
                    <HeaderCell>Clase</HeaderCell>
                    <HeaderCell>Valor unitario</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </>
                }
              >
                {socios[currentSocio].rangos && socios[currentSocio].rangos.map((rango, rangoIndex) => (
                  <TableRow key={rango.ghostKey}>
                    <Cell>
                      {rango.startParticipaciones} - {rango.endParticipaciones}
                    </Cell>
                    <Cell>
                      {rango.titulo}
                    </Cell>
                    <Cell>
                      {rango.notarioDetails && <EntityLink to={`/webinterna/personas/${rango.notarioDetails.value}`}>{rango.notarioDetails.displayName}</EntityLink>}
                    </Cell>
                    <Cell>
                      {rango.protocolo}
                    </Cell>
                    <Cell>
                      {datetime(rango.fecha, 'DATE')}
                    </Cell>
                    <Cell>
                      {rango.relacionPropiedad ? 'Sí' : 'No'}
                    </Cell>
                    <Cell>
                      {datetime(rango.altaFecha, 'DATE')}
                    </Cell>
                    <Cell>
                      {datetime(rango.bajaFecha, 'DATE')}
                    </Cell>
                    <Cell>
                      {price(rango.prima)}
                    </Cell>
                    <Cell>
                      {rango.clase}
                    </Cell>
                    <Cell>
                      {price(rango.valorUnitario)}
                    </Cell>
                    <Cell className="text-right">
                      <Link onClick={() => setCurrentRango(rangoIndex)}>Editar</Link>
                    </Cell>
                  </TableRow>
                ))}
              </Table>
              <div className="flex justify-end p-4">
                <ButtonPrimary onClick={() => {
                  if (!socios[currentSocio].propietario) {
                    removeSocio(socios[currentSocio])
                  }
                  setCurrentSocio(null)
                }}>
                  <Icon solid name="check" className="mr-2"/>
                  Aceptar
                </ButtonPrimary>
              </div>
            </CardHeader>
          )
          :
          <CardHeader
            className="mt-8"
            header={`Rango ${socios[currentSocio].rangos[currentRango] ? socios[currentSocio].rangos[currentRango].titulo : 'sin título'}
              del socio ${socios[currentSocio].propietarioDetails ? socios[currentSocio].propietarioDetails.displayName : 'sin nombre'}`}
            actions={
              <div className="space-x-4">
                <ButtonSecondary onClick={() => {
                  if (!socios[currentSocio].rangos[currentRango].startParticipaciones || !socios[currentSocio].rangos[currentRango].endParticipaciones) {
                    removeRango(currentRango)
                  }
                  setCurrentRango(null)
                }}>
                  <Icon regular name="chevron-left" className="mr-2" />
                  Volver
                </ButtonSecondary>
                <ButtonHoverDanger onClick={() => removeRango(currentRango)}>
                  <Icon solid name="trash" className="mr-2" />
                  Eliminar rango
                </ButtonHoverDanger>
              </div>
            }
          >
            <div className="md:flex gap-x-4 mb-6">
              <Input
                form={form}
                type="number"
                name={`socio[${currentSocio}].rangos[${currentRango}].startParticipaciones`}
                label="Inicio participaciones"
                value={socios[currentSocio].rangos[currentRango]?.startParticipaciones}
                onChange={value => setStartParticipacionesRango(value, currentSocio, currentRango)}
              />
              <Input
                form={form}
                type="number"
                name={`socio[${currentSocio}].rangos[${currentRango}].endParticipaciones`}
                label="Fin participaciones"
                value={socios[currentSocio].rangos[currentRango]?.endParticipaciones}
                onChange={value => setEndParticipacionesRango(value, currentSocio, currentRango)}
              />
              <Input
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}].titulo`}
                label="Título"
                value={socios[currentSocio].rangos[currentRango]?.titulo}
                onChange={value => setTituloRango(value, currentSocio, currentRango)}
                className="flex-grow"
              />
            </div>
            <div className="md:flex gap-x-4 mb-6 items-center">
              <div className="w-1/2">
                <People
                  form={form}
                  name={`socio[${currentSocio}].rangos[${currentRango}].notario`}
                  label="Notario"
                  restrict="NOTARIO"
                  value={socios[currentSocio].rangos[currentRango]?.notario}
                  onChange={value => setNotarioRango(value, currentSocio, currentRango)}
                />
              </div>
              
              <Input
                form={form}
                type="number"
                name={`socio[${currentSocio}].rangos[${currentRango}].protocolo`}
                label="Protocolo"
                value={socios[currentSocio].rangos[currentRango]?.protocolo}
                className="w-32"
                onChange={value => setProtocoloRango(value, currentSocio, currentRango)}
              />
              <Datepicker
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.fecha`}
                label="Fecha"
                value={socios[currentSocio].rangos[currentRango]?.fecha}
                onChange={value => setFechaRango(value, currentSocio, currentRango)}
              />
              <Checkbox
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.relacionPropiedad`}
                label="Relación propiedad"
                value={socios[currentSocio].rangos[currentRango]?.relacionPropiedad}
                onChange={value => setRelacionPropiedad(value, currentSocio, currentRango)}
              />
            </div>
            <div className="md:flex gap-x-4 mb-6">
              <Datepicker
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.altaFecha`}
                label="Fecha de alta"
                value={socios[currentSocio].rangos[currentRango]?.altaFecha}
                onChange={value => setAltaFechaRango(value, currentSocio, currentRango)}
              />
              <Datepicker
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.bajaFecha`}
                label="Fecha de baja"
                value={socios[currentSocio].rangos[currentRango]?.bajaFecha}
                onChange={value => setBajaFechaRango(value, currentSocio, currentRango)}
              />
              <Price
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.prima`}
                label="Prima"
                value={socios[currentSocio].rangos[currentRango]?.prima}
                onChange={(value) => setPrima(value, currentSocio, currentRango)}
              />
              <Price
                form={form}
                name={`socio[${currentSocio}].rangos[${currentRango}.valorUnitario`}
                label="Valor Unitario"
                value={socios[currentSocio].rangos[currentRango]?.valorUnitario}
                onChange={(value) => setValorUnitario(value, currentSocio, currentRango)}
              />
              <Input
                form={form}
                type="number"
                name={`socio[${currentSocio}].rangos[${currentRango}].clase`}
                label="Clase"
                value={socios[currentSocio].rangos[currentRango]?.clase}
                onChange={value => setClase(value, currentSocio, currentRango)}
              />
            </div>
            <div className="flex justify-end p-4">
              <ButtonPrimary onClick={() => {
                if (!socios[currentSocio].rangos[currentRango].startParticipaciones || !socios[currentSocio].rangos[currentRango].endParticipaciones) {
                  removeRango(currentRango)
                }
                setCurrentRango(null)
              }}>
                <Icon solid name="check" className="mr-2"/>
                Aceptar
              </ButtonPrimary>
            </div>
          </CardHeader>
        }

        <CardHeader
          fit
          className="mt-8"
          header="Apoderados"
          subheader="Apoderados de la sociedad."
        >
          <WideList divider="divide-y-4 divide-teal-400">
            {!apoderados.length && <WideListEmpty>No hay apoderados actualmente</WideListEmpty>}
            {apoderados.map((apoderado, index) => (
              <WideListInteractiveItem
                key={apoderado.ghostKey}
                actions={
                  <div className="self-start">
                    <ButtonHoverDanger onClick={() => removeApoderado(apoderado)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                  </div>
                }
              >
                <fieldset className="mb-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Apoderado {index + 1}</legend>
                </fieldset>

                <div className="md:flex gap-x-4 mb-6">
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`apoderados[${index}].apoderado`}
                      label="Apoderado"
                      value={apoderado.apoderado}
                    />
                  </div>
                  
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`apoderados[${index}].notario`}
                      label="Notario"
                      restrict="NOTARIO"
                      value={apoderado.notario}
                    />
                  </div>
                </div>

                <div className="md:flex gap-x-4 gap-y-6">
                  <Input form={form} name={`apoderados[${index}].titulo`} label="Título Apoderado" value={apoderado.titulo} className="mb-6 flex-grow" />
                  <Input form={form} name={`apoderados[${index}].tipoRelacion`} label="Tipo Relación" value={apoderado.tipoRelacion} className="mb-6 w-72" />
                  <Input form={form} type="number" name={`apoderados[${index}].protocolo`} label="Protocolo" value={apoderado.protocolo} className="mb-6 w-72" />
                </div>

                <div className="md:flex md:space-x-4 items-center">
                  <Datepicker form={form} name={`apoderados[${index}].fecha`} label="Fecha" value={apoderado.fecha} />
                  <Datepicker form={form} name={`apoderados[${index}].caducidadFecha`} label="Fecha Caducidad" value={apoderado.caducidadFecha} />
                  <Checkbox form={form} name={`apoderados[${index}].vigente`} label="Vigente" value={apoderado.vigente} />
                </div>

                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Registro Mercantil</legend>
                  <div className="md:flex md:space-x-4 mt-4">
                    <Input form={form} type="number" name={`apoderados[${index}].registroMercantil.inscripcion`} label="Inscripción" value={apoderado.registroMercantil?.inscripcion} className="w-32 mb-6" />
                    <SingleEntity
                      form={form}
                      name={`apoderados[${index}].registroMercantil.registro`}
                      label="Registro"
                      header="Seleccionar registro"
                      options={registros}
                      value={apoderado.registroMercantil?.registro}
                    />
                    <Input form={form} name={`apoderados[${index}].registroMercantil.tomo`} label="Tomo" value={apoderado.registroMercantil?.tomo} className="w-32 mb-6" />
                    <Input form={form} name={`apoderados[${index}].registroMercantil.libro`} label="Libro" value={apoderado.registroMercantil?.libro} className="mb-6" />
                    <Input form={form} name={`apoderados[${index}].registroMercantil.folio`} label="Folio" value={apoderado.registroMercantil?.folio} className="w-32 mb-6" />
                    <Input form={form} name={`apoderados[${index}].registroMercantil.hoja`} label="Hoja" value={apoderado.registroMercantil?.hoja} className="w-32 mb-6" />
                    <Input form={form} name={`apoderados[${index}].registroMercantil.seccion`} label="Sección" value={apoderado.registroMercantil?.seccion} className="w-32 mb-6" />
                  </div>
                </fieldset>
                
              </WideListInteractiveItem>
            ))}
            <WideListInteractiveItem>
              <ButtonSecondary onClick={addApoderado}>
                <Icon solid name="plus" className="mr-2" />
                Añadir Apoderado
              </ButtonSecondary>
            </WideListInteractiveItem>
          </WideList>
        </CardHeader>

        <CardHeader
          fit
          className="mt-8"
          header="Administradores"
          subheader="Administradores de la sociedad."
        >
          <WideList divider="divide-y-4 divide-teal-400">
            {!administradores.length && <WideListEmpty>No hay administradores actualmente</WideListEmpty>}
            {administradores.map((administrador, index) => (
              <WideListInteractiveItem
                key={administrador.ghostKey}
                actions={
                  <div className="self-start">
                    <ButtonHoverDanger onClick={() => removeAdministrador(administrador)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                  </div>
                }
              >
                <fieldset className="mb-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Administrador {index + 1}</legend>
                </fieldset>

                <div className="md:flex gap-x-4 mb-6">
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`administradores[${index}].apoderado`}
                      label="Administrador"
                      value={administrador.apoderado}
                    />
                  </div>
                  
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`administradores[${index}].notario`}
                      label="Notario"
                      restrict="NOTARIO"
                      value={administrador.notario}
                    />
                  </div>
                </div>

                <div className="md:flex gap-x-4 gap-y-6">
                  <Input form={form} name={`administradores[${index}].titulo`} label="Título Apoderado" value={administrador.titulo} className="mb-6 flex-grow" />
                  <Input form={form} name={`administradores[${index}].tipoRelacion`} label="Tipo Relación" value={administrador.tipoRelacion} className="mb-6 w-72" />
                  <Input form={form} type="number" name={`apoderados[${index}].protocolo`} label="Protocolo" value={administrador.protocolo} className="mb-6 w-72" />
                </div>

                <div className="md:flex md:space-x-4 items-center">
                  <Datepicker form={form} name={`administradores[${index}].fecha`} label="Fecha" value={administrador.fecha} />
                  <Datepicker form={form} name={`administradores[${index}].caducidadFecha`} label="Fecha Caducidad" value={administrador.caducidadFecha} />
                  <Checkbox form={form} name={`administradores[${index}].vigente`} label="Vigente" value={administrador.vigente} />
                </div>

                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Registro Mercantil</legend>
                  <div className="md:flex md:space-x-4 mt-4">
                    <Input form={form} type="number" name={`administradores[${index}].registroMercantil.inscripcion`} label="Inscripción" value={administrador.registroMercantil?.inscripcion} className="w-32 mb-6" />
                    <SingleEntity
                      form={form}
                      name={`administradores[${index}].registroMercantil.registro`}
                      label="Registro"
                      header="Seleccionar registro"
                      options={registros}
                      value={administrador.registroMercantil?.registro}
                    />
                    <Input form={form} name={`administradores[${index}].registroMercantil.tomo`} label="Tomo" value={administrador.registroMercantil?.tomo} className="w-32 mb-6" />
                    <Input form={form} name={`administradores[${index}].registroMercantil.libro`} label="Libro" value={administrador.registroMercantil?.libro} className="mb-6" />
                    <Input form={form} name={`administradores[${index}].registroMercantil.folio`} label="Folio" value={administrador.registroMercantil?.folio} className="w-32 mb-6" />
                    <Input form={form} name={`administradores[${index}].registroMercantil.hoja`} label="Hoja" value={administrador.registroMercantil?.hoja} className="w-32 mb-6" />
                    <Input form={form} name={`administradores[${index}].registroMercantil.seccion`} label="Sección" value={administrador.registroMercantil?.seccion} className="w-32 mb-6" />
                  </div>
                </fieldset>
                
              </WideListInteractiveItem>
            ))}
            <WideListInteractiveItem>
              <ButtonSecondary onClick={addAdministrador}>
                <Icon solid name="plus" className="mr-2" />
                Añadir Administrador
              </ButtonSecondary>
            </WideListInteractiveItem>
          </WideList>
        </CardHeader>

        {currentCarga === null ?
          <CardTable
            title="Cargas"
            empty="No hay cargas"
            className="mt-8"
            header={
              <>
                <HeaderCell>Participaciones</HeaderCell>
                <HeaderCell>Beneficiario</HeaderCell>
                <HeaderCell>Tipo</HeaderCell>
                <HeaderCell>Título</HeaderCell>
                <HeaderCell></HeaderCell>
              </>
            }
            actions={
              <ButtonSecondary onClick={() => {
                addCarga()
                setCurrentCarga(cargas.length)
              }}>
                <Icon solid name="plus" className="mr-2" />
                Añadir carga
              </ButtonSecondary>
            }
          >
            {cargas && cargas.map((carga, index) => (
              <TableRow key={carga.ghostKey}>
                <Cell>{carga.startParticipaciones && carga.endParticipaciones && `${carga.startParticipaciones} - ${carga.endParticipaciones} (${carga.endParticipaciones - carga.startParticipaciones})`}</Cell>
                <Cell>{carga.beneficiario}</Cell>
                <Cell>{carga.tipo}</Cell>
                <Cell>{carga.titulo}</Cell>
                <Cell className="text-right">
                  <Link onClick={() => setCurrentCarga(index)}>Editar</Link>
                </Cell>
              </TableRow>
            ))}
          </CardTable>
          :
          <CardHeader
            className="mt-8"
            header={`Carga ${cargas[currentCarga].titulo}`}
            actions={
              <div className="flex space-x-4">
                <ButtonSecondary onClick={() => {
                  if (!cargas[currentCarga].startParticipaciones || !cargas[currentCarga].endParticipaciones) {
                    removeCarga(cargas[currentCarga])
                  }
                  setCurrentCarga(null)
                }}>
                  <Icon solid name="chevron-left" className="mr-2"/>
                  Volver
                </ButtonSecondary>
                <ButtonHoverDanger onClick={() => {
                  removeCarga(cargas[currentCarga])
                  setCurrentCarga(null)
                }}>
                  <Icon solid name="trash" className="mr-2" />
                  Eliminar carga
                </ButtonHoverDanger>
              </div>
            }
          >
            <div className="md:flex md:space-x-4 mb-6">
              <Input
                form={form}
                type="number"
                name={`cargas[${currentCarga}].startParticipaciones`}
                label="Inicio participaciones"
                value={cargas[currentCarga].startParticipaciones}
                onChange={(value) => setStartParticipaciones(value, currentCarga)}
              />
              <Input
                form={form}
                type="number"
                name={`cargas[${currentCarga}].endParticipaciones`}
                label="Fin participaciones"
                value={cargas[currentCarga].endParticipaciones}
                onChange={(value) => setEndParticipaciones(value, currentCarga)}
              />
            </div>
            <div className="md:flex md:space-x-4 mb-6">
              <Input
                form={form}
                name={`cargas[${currentCarga}].beneficiario`}
                label="Beneficiario"
                className="flex-grow"
                value={cargas[currentCarga].beneficiario}
                onChange={(value) => setBeneficiario(value, currentCarga)}
              />
              <Input
                form={form}
                name={`cargas[${currentCarga}].tipo`}
                label="Tipo"
                value={cargas[currentCarga].tipo}
                onChange={(value) => setTipo(value, currentCarga)}
              />
              <Input
                form={form}
                name={`cargas[${currentCarga}].titulo`}
                label="Título"
                className="flex-grow"
                value={cargas[currentCarga].titulo}
                onChange={(value) => setTitulo(value, currentCarga)}
              />
            </div>
            <div className="md:flex md:space-x-4 mb-6">
              <div className="w-1/2">
                <People
                  form={form}
                  name={`cargas[${currentCarga}].notario`}
                  label="Notario"
                  restrict="NOTARIO"
                  value={cargas[currentCarga].notario}
                  onChange={(value) => setNotario(value, currentCarga)}
                />
              </div>
              <Input
                form={form}
                type="number"
                name={`cargas[${currentCarga}].protocolo`}
                label="Protocolo"
                value={cargas[currentCarga].protocolo}
                onChange={(value) => setProtocolo(value, currentCarga)}
              />
              <Input
                form={form}
                name={`cargas[${currentCarga}].estado`}
                label="Estado"
                value={cargas[currentCarga].estado}
                onChange={(value) => setEstado(value, currentCarga)}
              />
            </div>
            <div className="md:flex md:space-x-4 mb-6">
              <Datepicker
                form={form}
                name={`cargas[${currentCarga}].expedicionFecha`}
                label="Fecha expedición"
                value={cargas[currentCarga].expedicionFecha}
                onChange={(value) => setFechaExpedicion(value, currentCarga)}
              />
              <Datepicker
                form={form}
                name={`cargas[${currentCarga}].caducidadFecha`}
                label="Fecha caducidad"
                value={cargas[currentCarga].caducidadFecha}
                onChange={(value) => setFechaCaducidad(value, currentCarga)}
              />
              <Input
                form={form}
                name={`cargas[${currentCarga}].obligacionGarantizada`}
                label="Obligación garantizada"
                value={cargas[currentCarga].obligacionGarantizada}
                onChange={(value) => setObligacionGarantizada(value, currentCarga)}
              />
              <Price
                form={form}
                name={`cargas[${currentCarga}].importeGarantizado`}
                label="Importe garantizado"
                value={cargas[currentCarga].importeGarantizado}
                onChange={(value) => setImporteGarantizado(value, currentCarga)}
              />
            </div>
            <div className="flex justify-end w-full">
              <ButtonPrimary onClick={() => {
                if (!cargas[currentCarga].startParticipaciones || !cargas[currentCarga].endParticipaciones) {
                  removeCarga(cargas[currentCarga])
                }
                setCurrentCarga(null)
              }}
              >
                <Icon solid name="check" className="mr-2"/>
                Aceptar
              </ButtonPrimary>
            </div>
          </CardHeader>
        }

        <CardHeader
          fit
          className="mt-8"
          header="Titulares"
          subheader="Titulares de la sociedad."
        >
          <WideList divider="divide-y-4 divide-teal-400">
            {!titulares.length && <WideListEmpty>No hay titulares actualmente</WideListEmpty>}
            {titulares.map((titular, index) => (
              <WideListInteractiveItem
                key={titular.ghostKey}
                actions={
                  <div className="self-start">
                    <ButtonHoverDanger onClick={() => removeTitular(titular)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                  </div>
                }
              >
                <fieldset className="mb-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Titular {index + 1}</legend>
                </fieldset>

                <div className="md:flex gap-x-4 mb-6">
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`titulares[${index}].titular`}
                      label="Titular"
                      value={titular.titular}
                      restrict="FISICA, CONTRIBUIDOR"
                    />
                  </div>
                  
                  <div className="md:w-1/2">
                    <People
                      form={form}
                      name={`titulares[${index}].notario`}
                      label="Notario"
                      restrict="NOTARIO"
                      value={titular.notario}
                    />
                  </div>
                </div>

                <div className="md:flex md:space-x-4 mt-4">
                  <Percent form={form} name={`titulares[${index}].porcentaje`} label="Porcentaje" value={titular.porcentaje} className="mb-6 w-48" />
                  <Datepicker form={form} name={`titulares[${index}].fecha`} label="Fecha" value={titular.fecha} className="mb-6" />
                  <Input form={form} type="number" name={`titulares[${index}].protocolo`} label="Protocolo" value={titular.protocolo} className="mb-6 w-56" />
                </div>
              </WideListInteractiveItem>
            ))}
            <WideListInteractiveItem>
              <ButtonSecondary onClick={addTitular}>
                <Icon solid name="plus" className="mr-2" />
                Añadir Titular
              </ButtonSecondary>
            </WideListInteractiveItem>
          </WideList>
        </CardHeader>

        {!sociedad.blocked && currentSocio === null && currentRango === null && currentCarga === null &&
          <Submit form={form} validation={validation}>Guardar sociedad</Submit>
        }
      </Form>
    </PageLayout>
  )
}
