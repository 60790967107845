import React from 'react'
import firstBy from 'thenby'
import { Link, useLocation } from 'react-router-dom'
import { uniq, keyBy } from 'lodash-es'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import ExportCSV from 'components/ExportCSV'
import { parseDate } from 'platform/datetime'
import { datetime, decimal, percentage } from 'platform/filters'
import { prepareSearchPersonasSociedades, searchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())

const TIPO = {
  TIPO_VIVIENDA: 'Vivienda',
  TIPO_VIVIENDA_PP: 'Vivienda PP',
  TIPO_LOCAL: 'Local',
  TIPO_NAVE: 'Nave',
  TIPO_HOTEL: 'Hotel',
  TIPO_OFICINA: 'Oficina',
  TIPO_TRASTERO: 'Trastero',
  TIPO_ALMACEN: 'Almacén',
  TIPO_GARAJE: 'Garaje',
  TIPO_TERRENO: 'Terreno',
  TIPO_INSTALACION_AGRICOLA: 'Instalación Agrícola',
  TIPO_VALLA: 'Valla',
  TIPO_KIOSKO: 'Kiosko',
  TIPO_STAND: 'Stand',
  TIPO_OTROS: 'Otros',
}

const DESTINO = {
  DESTINO_ARRENDAMIENTO: 'Arrendamiento',
  DESTINO_VENTA: 'Venta',
  DESTINO_USO_PROPIO: 'Uso propio',
}


export default function ElementosPatrimonialesList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  let location = useLocation()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('nombre'),
      },
      {
        name: 'Por código',
        sort: firstBy('codigo').thenBy('nombre'),
      },
    ])

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    if (!elementosPatrimoniales) {
      setRows([])
      return
    }

    let names = elementosPatrimoniales
      .filter(ep => ep.fincaRegistral)
      .map(ep => ep.fincaRegistral)
    let { fincas } = await fincasRegistralesClient.BatchGet({ names: uniq(names) })

    names = fincas
      .filter(finca => finca.inmueble)
      .map(finca => finca.inmueble)
    let { inmuebles } = await inmueblesClient.BatchGet({ names: uniq(names) })

    names = inmuebles
      .filter(inmueble => inmueble.municipio)
      .map(inmueble => inmueble.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(names) })

    names = municipios.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(names) })

    names = inmuebles.filter(inmueble => inmueble.grupo).map(inmueble => inmueble.grupo)
    let { grupos } = await gruposInmobiliariosClient.BatchGet({ names: uniq(names) })

    names = inmuebles.map(i => i.titular)
    names = names.filter(name => name)
    let titularesSearch = await prepareSearchPersonasSociedades(names)

    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    grupos = keyBy(grupos, 'name')

    inmuebles.forEach(inmueble => {
      inmueble.municipio = municipios[inmueble.municipio]
      inmueble.provincia = provincias[inmueble.municipio?.provincia]
      inmueble.grupo = grupos[inmueble.grupo]
    })
    inmuebles = keyBy(inmuebles, 'name')

    fincas.forEach(finca => {
      finca.inmueble = inmuebles[finca.inmueble]
    })
    fincas = keyBy(fincas, 'name')

    elementosPatrimoniales.forEach(ep => ep.fincaRegistral = fincas[ep.fincaRegistral])

    if (location.search) {
      let params = new URLSearchParams(location.search)
      if (params.get('inmueble')) {
        elementosPatrimoniales = elementosPatrimoniales.filter(ep => {
          return ep.fincaRegistral?.inmueble?.name === params.get('inmueble')
        })
      }
      if (params.get('finca-registral')) {
        elementosPatrimoniales = elementosPatrimoniales.filter(ep => ep.fincaRegistral?.name === params.get('finca-registral'))
      }
      if (params.get('finca-catastral')) {
        let fincaCatastral = await fincasCatastralesClient.Get({ name: params.get('finca-catastral') })
        elementosPatrimoniales = elementosPatrimoniales.filter(ep => ep.fincaRegistral?.name === fincaCatastral.fincaRegistral)
      }
    }

    elementosPatrimoniales.forEach(ep => {
      ep.$search = [
        ep.codigo,
        ep.nombre,
        ep.fincaRegistral?.inmueble?.grupo?.nombre,
        ...searchPersonasSociedades(titularesSearch, ep.fincaRegistral?.inmueble?.titular),
        ep.fincaRegistral?.numeroFinca,
      ]
    })
    setRows(elementosPatrimoniales)
  })

  function getElementosPatrimonialesCSV(rows) {
    return rows.map(p => {
      return {
        'E. patrimonial': p.nombre,
        'Código': p.codigo,
        'Código inmueble': p.fincaRegistral.inmueble.codigo,
        'Inmueble': p.fincaRegistral.inmueble.nombre,
        'Grupo inmobiliario': p.fincaRegistral.inmueble?.grupo?.nombre,
        'Nº Freg': p.fincaRegistral.numeroFinca,
        'Titular': p.fincaRegistral.inmueble.titular?.nombre,
        'Identidad titular': p.fincaRegistral.inmueble.titular?.identidad,
        'Tipo': TIPO[p.tipo],
        'Destino': `Destino: ${DESTINO[p.destino]}`,
        'Estado': p.estadoComercial === 'ESTADO_COMERCIAL_DISPONIBLE' ? 'Disponible' : 'No disponible',
        'Metros fachada': `${decimal(p.metrosFachada)} m²`,
        'Metros construidos sin terraza': `${decimal(p.construidoSinTerrazas)} m²`,
        'Metros construidos terrazas': `${decimal(p.construidoTerrazas)} m²`,
        'Metros construidos totales': `${decimal(p.construidoTotal)} m²`,
        'Metros construidos pp.zz.cc': `${decimal(p.construidoPpzzcc)} m²`,
        'Metros útiles interior': `${decimal(p.utilesInterior)} m²`,
        'Metros útiles terrazas': `${decimal(p.utilesTerrazas)} m²`,
        'Metros útiles totales': `${decimal(p.utilesTotal)} m²`,
        'Superficie suelo ocupado': `${decimal(p.sueloOcupado)} m²`,
        'Superficie suelo computable': `${decimal(p.superficieComputable)} m²`,
        'Cuadro superficies': p.cuadroSuperficies,
        'Planimetría': p.fincaRegistral.planimetria,
        'Alta jurídica': datetime(parseDate(p.fincaRegistral.altaJuridicaDate), 'DATE'),
        'Baja jurídica': datetime(parseDate(p.fincaRegistral.bajaJuridicaDate), 'DATE'),
        'Fecha de alta': datetime(parseDate(p.fincaRegistral.altaDate), 'DATE'),
        'Fecha de baja': datetime(parseDate(p.fincaRegistral.bajaDate), 'DATE'),
        'Cód postal': p.codigoPostal,
        'Bloque': p.bloque,
        'Portal': p.portal,
        'Escalera': p.escalera,
        'Planta': p.planta,
        'Puerta': p.puerta,
        'Nº dormitorios': p.dormitorios,
        'Nº baños': p.banos,
        'Participación gastos comunes': percentage(p.gastosComunesPercentage),
        'Observaciones': p.observaciones,
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.elementos_patrimoniales.propose-edit">
            <ButtonLinkPrimary to="/webinterna/elementos-patrimoniales/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Elemento Patrimonial
            </ButtonLinkPrimary>
          </Acl>
          <ExportCSV
            className="ml-3"
            data={getElementosPatrimonialesCSV(table.filteredRows)}
            filename="Elementos-patrimoniales"
          />
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/elementos-patrimoniales">Elementos Patrimoniales</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell>E. Patrimonial</HeaderCell>
            <HeaderCell>Unidad Inmobiliaria</HeaderCell>
            <HeaderCell>Nº FREG</HeaderCell>
            <HeaderCell>Titular</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Estado Com.</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/elementos-patrimoniales/${row.name}`}>
            <div>
              {row.codigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.nombre}</span>
              <span className="ml-2">
                {row.fincaRegistral?.inmueble?.grupo?.nombre}
              </span>
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell>
              <Link
                to={`/webinterna/elementos-patrimoniales/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.nombre}
              </Link>
              <div className="font-medium">
                {row.codigo}
              </div>
            </Cell>
            <Cell>
              <Link
                to={`/webinterna/inmuebles/${row.fincaRegistral?.inmueble?.name}`}
                className="font-medium text-gray-900"
              >
                {row.fincaRegistral?.inmueble?.codigo}
                &nbsp;&mdash;&nbsp;
                {row.fincaRegistral?.inmueble?.nombre}
              </Link>
              <div>
                {row.fincaRegistral?.inmueble?.grupo?.nombre}
              </div>
            </Cell>
            <Cell>
              {row.fincaRegistral?.numeroFinca}
            </Cell>
            <Cell>
              {row.fincaRegistral?.inmueble?.titular && <PeopleViewer value={row.fincaRegistral.inmueble.titular} className="w-56"/>}
            </Cell>
            <Cell>
              <div>
                {row.tipo === 'TIPO_VIVIENDA' && 'Vivienda'}
                {row.tipo === 'TIPO_VIVIENDA_PP' && 'Vivienda PP'}
                {row.tipo === 'TIPO_LOCAL' && 'Local'}
                {row.tipo === 'TIPO_NAVE' && 'Nave'}
                {row.tipo === 'TIPO_HOTEL' && 'Hotel'}
                {row.tipo === 'TIPO_OFICINA' && 'Oficina'}
                {row.tipo === 'TIPO_TRASTERO' && 'Trastero'}
                {row.tipo === 'TIPO_ALMACEN' && 'Almacén'}
                {row.tipo === 'TIPO_GARAJE' && 'Garaje'}
                {row.tipo === 'TIPO_TERRENO' && 'Terreno'}
                {row.tipo === 'TIPO_INSTALACION_AGRICOLA' && 'Instalación Agrícola'}
                {row.tipo === 'TIPO_VALLA' && 'Valla'}
                {row.tipo === 'TIPO_KIOSKO' && 'Kiosko'}
                {row.tipo === 'TIPO_STAND' && 'Stand'}
                {row.tipo === 'TIPO_OTROS' && 'Otros'}
              </div>
              <div>
                Destino:&nbsp;
                {row.destino === 'DESTINO_ARRENDAMIENTO' && 'Arrendamiento'}
                {row.destino === 'DESTINO_VENTA' && 'Venta'}
                {row.destino === 'DESTINO_USO_PROPIO' && 'Uso propio'}
              </div>
            </Cell>
            <Cell>
              {row.estadoComercial === 'ESTADO_COMERCIAL_DISPONIBLE' && <BadgeGreen>Disponible</BadgeGreen>}
              {row.estadoComercial === 'ESTADO_COMERCIAL_NO_DISPONIBLE' && <BadgeGray>No disponible</BadgeGray>}
            </Cell>
            <Cell>
              <Acl perm="webinterna.elementos_patrimoniales.propose-edit">
                <Link to={`/webinterna/elementos-patrimoniales/edit/${row.name}`}>Editar</Link>
              </Acl>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
