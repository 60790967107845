
import React, { useState } from 'react'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { webinterna } from 'services/discovery'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { Cell, HeaderCell, TableRow } from 'components/Table'
import { useNavigate } from 'hooks/navigate'
import CardTable from 'components/CardTable'
import { firstBy } from 'thenby'


const client = new ProvinciasClient(webinterna())


export default function ProvinciasList() {
  let [provincias, setProvincias] = useState([])

  useNavigate(async () => {
    let { provincias } = await client.List()
    if (!provincias) {
      provincias = []
    }
    provincias.sort(firstBy('nombre'))
    setProvincias(provincias)
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/provincias">Provincias</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Provincias"
        subtitle="Listado de provincias."
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
          </>
        }
      >
        {provincias.map(provincia => (
          <TableRow key={provincia.nombre}>
            <Cell>
              {provincia.nombre}
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
