
import React, { useRef, useEffect } from 'react'
import flatpickr from 'flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import startOfDay from 'date-fns/startOfDay'
import isAfter from 'date-fns/isAfter'
import cx from 'classnames'
import { isDate } from 'lodash-es'

import { useField } from 'platform/forms'
import Icon from 'components/Icon'
import { useMounted } from 'hooks/mounted'


export default function Datepicker({ form, className, help, name, label, validations, value, onChange, min, disabled }) {
  let field = useField({ form, name, validations, value, onChange })

  if (value && !isDate(value)) {
    throw new Error(`Datepicker should receive a Date object. Use parseDate to read it: ${value}`)
  }
  if (min) {
    min = startOfDay(min)
  }
  if (value) {
    value = startOfDay(value)
  }

  let inputElem = useRef(null)
  let picker = useRef(null)

  useMounted(() => {
    picker.current = flatpickr(inputElem.current, {
      dateFormat: 'd M Y',
      minDate: min ? startOfDay(min) : undefined,
      locale: Spanish,
      onChange: dates => {
        field.setValue(dates[0])
      },
      defaultDate: value,
    })

    return () => {
      picker.current.destroy()
    }
  })

  useEffect(() => {
    if (picker.current && value) {
      picker.current.setDate(value)
    }
  }, [picker, value])

  useEffect(() => {
    if (picker.current && min) {
      if (isAfter(min, picker.current.selectedDates[0])) {
        picker.current.setDate(min)
      }
      picker.current.set('minDate', min)
    }
  }, [picker, min])

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium leading-5 text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1">
        <div
          className={cx(
            'mt-1 relative rounded-md shadow-sm sm:w-56',
            {
              'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red': field.hasError,
            },
          )}
        >
          <input
            className="form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md pr-10"
            type="text"
            disabled={disabled}
            readOnly
            ref={inputElem}
            name={name}
            id={name}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <Icon regular name="calendar-alt" className="text-gray-500" />
          </div>
        </div>
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
