
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { keyBy } from 'lodash'

import { useNavigate } from 'hooks/navigate'
import { webinterna } from 'services/discovery'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import resolver from 'platform/resolver'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import ProcesosClient from 'services/WebInterna/procesos/procesos'


const gruposProcesosClient = new GruposProcesosClient(webinterna())
const procesosClient = new ProcesosClient(webinterna())


export default function GruposProcesosList() {
  let [grupos, setGrupos] = useState([])

  useNavigate(async () => {
    let { gruposProcesos, procesos } = await resolver.all({
      gruposProcesos: gruposProcesosClient.List().then(reply => reply.grupos || []),
      procesos: procesosClient.List().then(reply => reply.procesos || []),
    })

    procesos = keyBy(procesos, 'name')

    gruposProcesos.forEach(grupoProceso => {
      grupoProceso.procesos = grupoProceso.procesos || []
      grupoProceso.procesos = grupoProceso.procesos.map(p => procesos[p])
    })
    setGrupos(gruposProcesos)
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/grupos-procesos">Grupos de procesos</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Grupos de procesos"
        subtitle="Organización de procesos."
        empty="No hay grupos de procesos configurados."
        actions={
          <ButtonLinkPrimary to="/manage/grupos-procesos/create">
            <Icon solid name="plus" className="mr-2" />
            Crear grupo
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell>Código</HeaderCell>
            <HeaderCell>Procesos asignados</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {grupos.map(grupo => (
          <TableRow key={grupo.name}>
            <Cell>
              <Link to={`/manage/grupos-procesos/${grupo.name}`} className="text-gray-700">{grupo.nombre}</Link>
            </Cell>
            <Cell className="font-mono">
              {grupo.codigo}
            </Cell>
            <Cell>
              <span title={grupo.procesos.map((proceso) => proceso.denominacion).join(', ')}>
                {grupo.procesos.length === 1 ? '1 proceso' : `${grupo.procesos.length} procesos`}
              </span>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/grupos-procesos/${grupo.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
