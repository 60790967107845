
import React, { useEffect, useState } from 'react'

import { parseShortValidations, validationMessage, hasError } from 'platform/forms'


export default function Checkbox({ form, className, help, name, label, validations, value, onChange }) {
  let [selected, setSelected] = useState(value)
  let checks = parseShortValidations(validations)

  useEffect(() => {
    form.register({ name }, checks)

    return () => form.unregister(name)
  }, [name, validations]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setValue(name, value)
    setSelected(value)
  }, [name, value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setValue(name, selected)
  }, [name, selected]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="p-2 flex"
      >
        <input
          id={name}
          name={name}
          type="checkbox"
          className="form-checkbox focus:ring-teal-400 h-4 w-4 text-teal-600 border-gray-300 rounded"
          defaultChecked={selected}
          onChange={e => {
            setSelected(e.target.checked)
            if (onChange) {
              onChange(e.target.checked)
            }
          }}
        />
        <div className="ml-3 text-sm">
          <div className="font-medium text-gray-700 -mt-0.5">
            {label}
          </div>
          {help && <p className="mt-0.5 text-gray-500 font-normal">{help}</p>}
        </div>
      </label>
      {hasError(form, name) && <p className="mt-0.5 text-red-700">{validationMessage(checks, form.errors[name])}</p>}
    </div>
  )
}
