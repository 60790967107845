
import { set, erase } from '@altipla/browser-cookies-module'
import { isString } from 'lodash-es'


export function setCrossSite(name, value, opts) {
  if (!isString(value)) {
    return
  }
  if (process.env.NODE_ENV === 'production') {
    set(name, value, {
      expires: opts.expires || 0,
      secure: true,
      samesite: 'none',
    })
  } else {
    set(name, value, {
      expires: opts.expires || 0,
    })
  }
}


export function eraseCrossSite(name) {
  if (process.env.NODE_ENV === 'production') {
    erase(name, {
      secure: true,
      samesite: 'none',
    })
  } else {
    erase(name)
  }
}
