
import React, { useState, useEffect, useRef } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import cx from 'classnames'
import diacritic from 'diacritic'
import { Link } from 'react-router-dom'

import Icon from 'components/Icon'
import { Dropdown, DropdownMenuItem } from 'components/Dropdown'


const DEFAULT_PAGE_SIZE = 30


export function useDatatable({
  pageSize = DEFAULT_PAGE_SIZE,
  primaryField = 'name',
  advancedFilters = false,
} = {}) {
  let root = useRef(null)

  let [allRows, setAllRows] = useState([])
  let [filteredRows, setFilteredRows] = useState([])
  let [currentPageRows, setCurrentPageRows] = useState([])
  let [page, setPage] = useState(0)
  let [loading, setLoading] = useState(true)
  let [range, setRange] = useState({
    start: 0,
    end: 0,
    total: 0,
  })
  let [filter, setFilter] = useState('')
  let [sortOrders, setSortOrders] = useState([])
  let [currentSortOrder, setCurrentSortOrder] = useState(null)

  function goTo(newPage) {
    setPage(newPage)
    let start = newPage * pageSize
    let end = Math.min((newPage + 1) * pageSize, filteredRows.length)
    setCurrentPageRows(filteredRows.slice(start, end))

    let totalPages = parseInt(filteredRows.length / pageSize)
    if (filteredRows.length % pageSize !== 0) {
      totalPages++
    }
    setRange({
      start,
      end,
      total: filteredRows.length,
      totalPages,
    })

    let scrollable = root.current?.closest('.overflow-y-scroll')
    if (scrollable) {
      if (scrollable.scrollTop > 0) {
        scrollable.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    } else {
      if (window.scrollY > 0) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    let search = diacritic.clean(filter).toUpperCase()

    let rows = allRows.filter(row => row.__search.includes(search))
    if (currentSortOrder && currentSortOrder.sort) {
      rows.sort(currentSortOrder.sort)
    }
    setFilteredRows(rows)
  }, [allRows, filter, currentSortOrder])

  // Go to the first page after loading the rows or changing
  // the filters.
  useEffect(() => goTo(0), [filteredRows]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    // Global object with everything grouped to pass it together to the component.
    // Everything inside this object is internal and intended only for use inside
    // the datatable component below.
    table: {
      root,
      currentPageRows,
      page,
      setPage,
      primaryField,
      loading,
      range,
      filter,
      setFilter,
      sortOrders,
      currentSortOrder,
      setCurrentSortOrder,
      advancedFilters,
      filteredRows,

      nextPage() {
        if (page < range.totalPages - 1) {
          goTo(page + 1)
        }
      },

      prevPage() {
        if (page > 0) {
          goTo(page - 1)
        }
      },
    },

    /**
     * Set the rows from the server.
     * @param {Array} rows List of items.
     */
    setRows(rows) {
      setAllRows(rows.map(row => {
        row.__search = diacritic.clean(row.$search.join(' ')).toUpperCase()

        return row
      }))

      setLoading(false)
    },

    setSortOrders(orders) {
      setSortOrders(orders)
      if (orders.length) {
        setCurrentSortOrder(orders[0])
      } else {
        setCurrentSortOrder({
          name: 'Orden por defecto',
        })
      }
    },

    /**
     * Show a spinner while the table data loads.
     * @param {Function} fn Function to call and wait to.
     */
    useAsyncLoad(fn) {
      useEffect(() => {
        async function load() {
          await fn()
        }
        load()
      }, []) // eslint-disable-line react-hooks/exhaustive-deps
    },
  }
}


export function Datatable({ table, header, row, rowMobile, filters, fit }) {
  function collapseSpaces(filter) {
    return filter.replace(/\s+/g, ' ')
  }

  return (
    <>
      <div className="lg:hidden bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {table.currentPageRows.map(rowData => (
            <li key={rowData[table.primaryField]}>
              {rowMobile(rowData)}
            </li>
          ))}
        </ul>
      </div>

      <div className="hidden lg:block">
        <div
          className={cx(
            'flex mb-4 items-end',
            {
              'mx-4': fit,
            },
          )}
          ref={table.root}
        >
          <div className="mt-1 flex rounded-md shadow-sm w-full md:w-1/2">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon solid name="search" className="text-gray-400" />
              </div>
              <input
                type="text"
                id="search"
                className={cx(
                  'form-input block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 rounded-none rounded-l-md py-2 px-10 border-gray-300',
                  {
                    'rounded-r-md': !table.advancedFilters,
                  },
                )}
                placeholder="Filtrar..."
                value={table.filter}
                onChange={event => table.setFilter(collapseSpaces(event.target.value))}
              />
            </div>

            {table.advancedFilters &&
              <button
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 whitespace-nowrap"
              >
                <Icon regular name="filter" className="mr-3" />
                Filtros avanzados
                <Icon regular name="chevron-down" className="ml-2" />
              </button>
            }
          </div>

          {filters}

          <div className="flex-1"></div>
          <div className="ml-4">
            {table.sortOrders.length ?
              <Dropdown
                trigger={
                  <button
                    type="button"
                    className="inline-block rounded-md px-4 py-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-500 -mb-1 whitespace-nowrap"
                  >
                    <Icon regular name="sort-alpha-down" className="mr-2 text-gray-500" />
                    <span className="hidden md:inline">
                      {table.currentSortOrder.name}
                    </span>
                    <Icon solid name="chevron-down" className="md:ml-2 -mr-1" />
                  </button>
                }
              >
                {table.sortOrders.map(sortOrder =>(
                  <DropdownMenuItem
                    key={sortOrder.name}
                    onClick={() => table.setCurrentSortOrder(sortOrder)}
                    className={cx(
                      {
                        'font-bold': sortOrder === table.currentSortOrder,
                      },
                    )}
                  >
                    {sortOrder.name}
                  </DropdownMenuItem>
                ))}
              </Dropdown>
              : ''}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 py-2 overflow-x-auto">
            <div
              className={cx(
                'align-middle inline-block min-w-full shadow overflow-hidden border-b border-gray-200',
                {
                  'sm:rounded-lg': !fit,
                },
              )}
            >
              <table className="min-w-full">
                <thead>
                  <tr>
                    {header}
                  </tr>
                </thead>
                <tbody>
                  {table.loading &&
                    <tr className="bg-gray-50">
                      <td colSpan={header.props.children.length} className="h-64 p-0 align-top">
                        <BarLoader color="#047481" width="100%" />
                      </td>
                    </tr>
                  }
                  {table.currentPageRows.map((rowData, index) =>(
                    <tr
                      className={cx({
                        'bg-white': !(index % 2),
                        'bg-gray-50': index % 2,
                      })}
                      key={rowData[table.primaryField]}
                    >
                      {row(rowData)}
                    </tr>
                  ))}
                  {!table.loading && !table.filteredRows.length &&
                    <tr>
                      <td colSpan={header.props.children.length} className="bg-white text-center text-xl italic text-gray-400 py-20">
                        No hay resultados
                      </td>
                    </tr>
                  }
                </tbody>
              </table>

              <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div className="flex-1 flex justify-between sm:hidden">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                    title="Página anterior"
                    onClick={table.prevPage}
                  >
                    <Icon regular name="chevron-left" className="mx-2" />
                  </button>
                  <button
                    type="button"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                    title="Página siguiente"
                    onClick={table.nextPage}
                  >
                    <Icon regular name="chevron-right" className="mx-2" />
                  </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm leading-5 text-gray-700">
                      <span className="font-medium pr-1">{Math.min(table.range.start + 1, table.range.total)}</span>
                      -
                      <span className="font-medium px-1">{table.range.end}</span>
                      de
                      <span className="font-medium px-1">{table.range.total}</span>
                      resultados
                    </p>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex shadow-sm -space-x-px">
                      <button
                        type="button"
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                        title="Página anterior"
                        onClick={table.prevPage}
                      >
                        <Icon regular name="chevron-left" className="mx-2" />
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                        title="Siguiente página"
                        onClick={table.nextPage}
                      >
                        <Icon regular name="chevron-right" className="mx-2" />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export function Cell({ children, className, colspan = 1, wrap, title, expand, fit = 'none' }) {
  let spacing = {
    right: 'pl-6',
    left: 'pr-6',
    both: '',
    none: 'px-6',
  }

  return (
    <td
      title={title}
      className={cx(
        'py-4 text-sm leading-5 text-gray-500',
        spacing[fit],
        className,
        {
          'whitespace-nowrap': !wrap,
          'w-full': expand,
        },
      )}
      colSpan={colspan}
    >
      {children}
    </td>
  )
}


export function ButtonIconCell({ children, onClick }) {
  return (
    <td>
      <button
        className="w-full h-10 text-sm text-gray-500 hover:text-green-400 px-6 whitespace-nowrap focus:outline-none"
        onClick={() => onClick()}
      >
        {children}
      </button>
    </td>
  )
}


export function HeaderCell({ children, className, expand, fit = 'none' }) {
  let spacing = {
    right: 'pl-6',
    left: 'pr-6',
    both: '',
    none: 'px-6',
  }

  return (
    <th
      className={cx(
        'py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap',
        spacing[fit],
        className,
        {
          'w-full': expand,
        },
      )}
    >
      {children}
    </th>
  )
}


export function RowMobile({ children, to, className }) {
  return (
    <Link
      to={to}
      className={cx(
        'block hover:bg-gray-50',
        className,
      )}
    >
      <div className="px-4 py-4 flex items-center sm:px-6">
        <div className="min-w-0 flex-1 text-gray-500 font-normal">
          {children}
        </div>
        
        <div className="ml-5 flex-shrink-0">
          <Icon regular name="chevron-right" className="text-gray-400" />
        </div>
      </div>
    </Link>
  )
}
