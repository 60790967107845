
import React, { useRef, useState } from 'react'
import cx from 'classnames'

import { ReactComponent as DiagonalBars } from 'images/DiagonalBars.svg'
import { CSVReader } from 'react-papaparse'
import AlertDanger from './alerts/AlertDanger'


export default function ParseCSV({ onFileLoad, onError, error }) {
  let [hover, setHover] = useState(false)

  let fileRef = useRef()

  return (
    <>
      <CSVReader
        ref={fileRef}
        onFileLoad={onFileLoad}
        onError={onError}
        config={{}}
        noProgressBar
      >
        <div
          className={cx(
            'h-72 w-full relative px-4 sm:px-0 cursor-pointer border-8 border-dashed rounded-lg border-gray-300 hover:border-gray-400 text-gray-300 hover:text-gray-400',
            {
              'border-gray-400 text-gray-400': hover,
            },
          )}
        >
          <div className="stroke-current stroke-8">
            <DiagonalBars />
          </div>
          <div className={cx('w-full h-full flex justify-center items-center bg-white', { 'bg-gray-200': hover })}>
            <div className={cx('absolute bg-white bg-opacity-75 w-80 text-center py-4', { 'bg-gray-200': hover })}>
              <span className="text-2xl text-gray-400 font-medium">
                Arrastra aquí tu documento o haz click para subirlo
              </span>
            </div>
          </div>
          <div
            className="w-full h-full absolute top-0"
            onDragOver={e => {
              e.preventDefault()
            }}
            onDragEnter={e => {
              e.preventDefault()
              setHover(true)
            }}
            onDragLeave={e => {
              e.preventDefault()
              setHover(false)
            }}
          ></div>
        </div>
      </CSVReader>
      {error && <AlertDanger className="mt-6">{error}</AlertDanger>}
    </>
  )
}
