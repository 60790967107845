
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'

import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { putEntity, getEntityOrDefault } from 'models/docs/entities'
import User from 'components/simple-forms/User'
import { every } from 'lodash-es'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import { LIBRARY_MANAGERS_NAME } from 'models/docs/library-managers'


const usersClient = new UsersClient(users())


export default function LibraryManagersEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [form, setForm] = useState('')
  let [managers, setManagers] = useState({})
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { users } = await usersClient.List()
    setUsers(users)
    let reply = await getEntityOrDefault(LIBRARY_MANAGERS_NAME)
    reply.users = reply.users || []

    if (name) {
      setForm(reply.users.find(user => user === name))
    }
    setManagers(reply)
  })

  async function save() {
    await putEntity('library-managers', {
      libraryManagers: {
        ...managers,
        users: [
          ...managers.users.filter(u => u !== name),
          form,
        ],
      },
    })
    history.push(`/manage/library-managers`)
  }

  async function deleteLibraryManager() {
    await putEntity('library-managers', {
      libraryManagers: {
        ...managers,
        users: managers.users.filter(u => u !== name),
      },
    })
    history.push(`/manage/library-managers`)
  }

  let changed = every([
    name !== form,
    form,
  ])

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/library-managers">Gestores de biblioteca</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/library-managers/${name}`}>Editar gestor de biblioteca</Breadcrumb>
            :
            <Breadcrumb url="/manage/library-managers/create">Crear gestor de biblioteca</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos generales"
        footer={
          <div
            className={cx(
              {
                'flex justify-between w-full': name,
              },
            )}
          >
            {name &&
              <ButtonAsyncDanger onClick={deleteLibraryManager}>
                <Icon solid name="trash" className="mr-2" />
                Eliminar gestor de biblioteca
              </ButtonAsyncDanger>
            }
            <ButtonAsyncPrimary onClick={save} disabled={!changed}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <User
          label="Usuario"
          users={users}
          value={form}
          onChange={value => {
            setForm(value)
          }}
        ></User>
      </CardHeader>
    </PageLayout>
  )
}
