// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/registros_propiedad/registros_propiedad.proto

import { Caller } from '@altipla/grpc-browser';


export default class RegistrosPropiedadClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/registros-propiedad`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/registros-propiedad/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/registros-propiedad:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/registros-propiedad`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/registros-propiedad/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/registros-propiedad/${req.name}`, req, false, ['name']);
  }
};

