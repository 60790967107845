
import React, { useEffect, useState } from 'react'

import ShowPane from 'components/ShowPane'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeOrange from 'components/badges/BadgeOrange'
import ExternalLink from 'components/ExternalLink'
import PeopleViewer from 'components/people/PeopleViewer'
import EntityLink from 'components/EntityLink'
import { datetime } from 'platform/filters'
import { REF_PROYECTO } from 'components/CustomFieldViewer-config/proyecto'
import { REF_PERSONA } from 'components/CustomFieldViewer-config/persona'
import { REF_SOCIEDAD } from 'components/CustomFieldViewer-config/sociedad'
import { REF_PROCESO } from 'components/CustomFieldViewer-config/proceso'
import { REF_CONTRIBUIDOR } from 'components/CustomFieldViewer-config/contribuidor'
import { REF_INMUEBLE } from 'components/CustomFieldViewer-config/inmueble'
import { REF_FINCA_REGISTRAL } from 'components/CustomFieldViewer-config/fincaRegistral'
import { REF_FINCA_CATASTRAL } from 'components/CustomFieldViewer-config/fincaCatastral'
import { REF_ELEMENTO_PATRIMONIAL } from 'components/CustomFieldViewer-config/elementoPatrimonial'
import { REF_PLAN_CUENTAS } from 'components/CustomFieldViewer-config/planCuentas'


const CONFIG = {
  REF_PROYECTO,
  REF_PERSONA,
  REF_SOCIEDAD,
  REF_PROCESO,
  REF_CONTRIBUIDOR,
  REF_INMUEBLE,
  REF_FINCA_REGISTRAL,
  REF_FINCA_CATASTRAL,
  REF_ELEMENTO_PATRIMONIAL,
  REF_PLAN_CUENTAS,
}

function hasPersonViewer(ref) {
  return ref === 'REF_PERSONA' || ref === 'REF_SOCIEDAD' || ref === 'REF_CONTRIBUIDOR'
}

function ComputedMultiViewer({ spec, value }) {
  return spec.extractor(value)?.map((item, index) => (
    <ComputedViewer
      spec={{
        ...spec,
        label: spec.label + ' ' + (index + 1),
        extractor: () => item,
      }}
    />
  ))
}

function ComputedViewer({ spec, value }) {
  return (
    <ShowPane title={spec.label} className={spec.wide ? 'col-span-2' : ''}>
      {spec.type === 'text' && spec.extractor(value)}
      {spec.type === 'badge' &&
        <>
          {spec.extractor(value).active && <BadgeGreen>{spec.extractor(value).text}</BadgeGreen>}
          {!spec.extractor(value).active && <BadgeOrange>{spec.extractor(value).text}</BadgeOrange>}
        </>
      }
      {spec.type === 'persona' && spec.extractor(value) &&
        <PeopleViewer value={ spec.extractor(value) }/>
      }
      {spec.type === 'date' && datetime(spec.extractor(value), 'DATE')}
    </ShowPane>
  )
}

export function CustomFieldViewer({ field }) {
  let [values, setValues] = useState([])

  let ref = field.info.webinternaRef ? field.info.webinternaRef.ref : field.info.webinternaRefDescription?.ref

  useEffect(() => {
    async function getData() {
      let names
      if (field.info.webinternaRef) {
        names = field.values
          .map(value => value.webinternaRef)
          .filter(value => value)
      } else {
        names = field.values
          .map(value => value.webinternaRefDescription.text)
          .filter(value => value)
      }

      if (!names.length) {
        return
      }

      let loader = CONFIG[ref]?.loader
      if (loader) {
        setValues(await loader(names))
      }
    }
    
    let isCustomField = field.info.webinternaRef || field.info.webinternaRefDescription
    if (isCustomField) {
      getData()
    }
  }, [field, ref])

  return (
    <React.Fragment key={field.name}>
      {!field.info.webinternaRef && !field.info.webinternaRefDescription &&
        <ShowPane title={field.info.displayName}>
          <div className="space-y-2">
            {field.values.map((value, idx) => (
              <span key={`${field.name}-${idx}`}>
                {field.info.text && value.text}
                {field.info.numeric && value.numeric}
                {field.info.email && value.email}
                {field.info.url && <ExternalLink to={value.url}>{value.url}</ExternalLink>}
                {field.info.checkbox &&
                  <>
                    {value.checkbox ? <BadgeGreen>Marcado</BadgeGreen> : <BadgeOrange>No marcado</BadgeOrange>}
                  </>
                }
                {field.info.select && value.select &&
                  <>
                    {field.info.options[value.select].displayName}
                  </>
                }
              </span>
            ))}
          </div>
        </ShowPane>
      }
      {(field.info.webinternaRef || field.info.webinternaRefDescription) &&
        <div>
          {values.map((value, index) => (
            <div>
              <div className="text-base leading-6 font-medium text-gray-700 mb-4 mt-8">
                {CONFIG[ref].label + ' ' + value.label}
              </div>
              <div className="grid grid-cols-2 space-y-2">
                {hasPersonViewer(ref) ?
                  <ShowPane title={CONFIG[ref].label} className="col-span-2">
                    <PeopleViewer
                      value={(ref === 'REF_PERSONA' || ref === 'REF_CONTRIBUIDOR') ?
                        { persona: value.name }
                        :
                        { sociedad: value.name }
                      }
                    />
                  </ShowPane>
                  :
                  <ShowPane title={CONFIG[ref].label} className="col-span-2">
                    <EntityLink to={CONFIG[ref].link(value.name)}>
                      {value.label}
                    </EntityLink>
                  </ShowPane>
                }
                {field.info.webinternaRefDescription &&
                  <ShowPane title="Descripción" className="col-span-2">
                    {field.values[index].webinternaRefDescription.description}
                  </ShowPane>
                }
                {(field.info.webinternaRef?.fields || field.info.webinternaRefDescription?.fields)
                  .filter(field => CONFIG[ref]?.fields[field])
                  .map(customField => (
                    <>
                      {!CONFIG[ref]?.fields[customField]?.multi &&
                        <ComputedViewer spec={CONFIG[ref].fields[customField]} value={value} />
                      }
                      {CONFIG[ref]?.fields[customField]?.multi &&
                        <ComputedMultiViewer spec={CONFIG[ref].fields[customField]} value={value} />
                      }
                    </>
                  ))
                }
              </div>
            </div>
          ))}
        </div>
      }
    </React.Fragment>
  )
}
