
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { logout, accountInfo } from 'auth/active-directory'
import { ProfileInvert } from 'components/Profile'
import { NotificationsInvert } from 'components/Notifications'
import Icon from 'components/Icon'
import Face from 'components/people/Face'
import logo from 'images/logo-negativo.png'
import { MENU_ITEMS } from 'layout/HeaderMenu'
import Acl from 'components/Acl'


export default function Dashboard() {
  let [showMenu, setShowMenu] = useState(false)

  function isDashboard(item) {
    return item.url === '/'
  }

  return (
    <div>
      <div className="bg-gray-800 pb-32">
        <nav className="bg-gray-800">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="border-b-2 border-gray-700">
              <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img width="96" height="32" src={process.env.REACT_APP_HOST + logo} alt="Alvores"></img>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {MENU_ITEMS.map(item => (
                        <React.Fragment key={item.label}>
                          {item.perm &&
                            <Acl perm={item.perm}>
                              <Link
                                to={item.url}
                                className={cx(
                                  'px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700 hover:text-white',
                                  {
                                    'text-white bg-gray-900': isDashboard(item),
                                    'text-gray-300 hover:bg-gray-700': !isDashboard(item),
                                  },
                                )}
                              >{item.label}</Link>
                            </Acl>
                          }
                          {!item.perm &&
                            <Link
                              to={item.url}
                              className={cx(
                                'px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700 hover:text-white',
                                {
                                  'text-white bg-gray-900': isDashboard(item),
                                  'text-gray-300 hover:bg-gray-700': !isDashboard(item),
                                },
                              )}
                            >{item.label}</Link>}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <NotificationsInvert />
                    <ProfileInvert />
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <Icon
                      regular
                      name="bars"
                      className={cx(
                        'h-5 w-5 fa-2x',
                        {
                          'hidden': showMenu,
                          'block': !showMenu,
                        },
                      )}
                    ></Icon>
                    <Icon
                      light
                      name="times"
                      className={cx(
                        'h-6 w-6 fa-2x',
                        {
                          'hidden': !showMenu,
                          'block': showMenu,
                        },
                      )}
                    ></Icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={cx(
              'sm:hidden border-b border-gray-700',
              {
                'block': showMenu,
                'hidden': !showMenu,
              },
            )}
          >
            <div className="px-2 py-3 space-y-1 sm:px-3">
              {MENU_ITEMS.map(item => (
                <Link
                  to={item.url}
                  key={item.url}
                  className={cx(
                    'block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white focus:bg-gray-700',
                    {
                      'text-white bg-gray-900': isDashboard(item),
                      'text-gray-300 hover:text-white hover:bg-gray-700': !isDashboard(item),
                    },
                  )}
                >{item.label}</Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5 space-x-3">
                <div className="flex-shrink-0">
                  <Face displayName={accountInfo().displayName} pictureUrl={accountInfo().pictureUrl}></Face>
                </div>
                <div className="space-y-1">
                  <div className="text-base font-medium leading-none text-white">{accountInfo().displayName}</div>
                  <div className="text-sm font-medium leading-none text-gray-400">{accountInfo().email}</div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <button
                  onClick={() => logout()}
                  className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >Cerrar sesión</button>
              </div>
            </div>
          </div>
        </nav>
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl leading-9 font-bold text-white">
              Dashboard
            </h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">

          {/* <!-- Replace with your content --> */}
          <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"></div>
          </div>
          {/* <!-- /End replace --> */}

        </div>
      </main>
    </div>
  )
}
