
import React from 'react'
import { CSVDownloader } from 'react-papaparse'

import ButtonSecondary from 'components/buttons/ButtonSecondary'
import Icon from 'components/Icon'


export default function ExportCSV({ data, filename, className, label = 'Exportar' }) {
  return (
    <CSVDownloader
      className={className}
      data={data}
      filename={filename}
    >
      <ButtonSecondary>
        <Icon solid name="download" className="mr-2"/>
        {label}
      </ButtonSecondary>
    </CSVDownloader>
  )
}
