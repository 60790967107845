
export function build(...filters) {
  let q = {
    bool: {
      filter: filters,
    },
  }
  return JSON.stringify(q)
}


export function and(key, value) {
  return { match: { [key]: value } }
}


export function text(key, value) {
  return { match_phrase_prefix: { [key]: value } }
}


export function range(key, min, max) {
  return {
    range: {
      [key]: {
        gte: min,
        lte: max,
      },
    },
  }
}


export function andIn(key, value) {
  return { terms: { [key]: value } }
}


export function or(...filters) {
  return { bool: { should: filters } }
}


export function nested(path, ...children) {
  return {
    nested: {
      path,
      query: {
        bool: {
          filter: children,
        },
      },
    },
  }
}


export function sort(key, direction) {
  let s = [
    { [key]: { order: direction } },
  ]
  return JSON.stringify(s)
}
