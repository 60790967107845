
import React from 'react'
import cx from 'classnames'

import Icon from 'components/Icon'


export default function AlertDanger({ children, className }) {
  return (
    <>
      <div className={cx('rounded-md bg-red-50 p-4', className)}>
        <div className="flex">
          <div className="flex-shrink-0">
            <Icon solid name="exclamation-triangle" className="text-red-400" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between items-center">
            <div className="text-sm text-red-700">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
