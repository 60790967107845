
import React, { useState, useEffect, useRef } from 'react'

import cx from 'classnames'
import ButtonAsyncWhite from 'components/buttons/ButtonAsyncWhite'
import Icon from 'components/Icon'
import Face from 'components/people/Face'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { webinterna } from 'services/discovery'
import resolver from 'platform/resolver'
import { listPersonas } from 'models/webinterna/personas'
import SideSheet from 'components/SideSheet'
import Throbber from 'components/Throbber'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import diacritic from 'diacritic'
import firstBy from 'thenby'
import { isObject } from 'lodash-es'


const PAGE_SIZE = 50
const TYPE_NOTARIO = 'NOTARIO'
const TYPE_CONTRIBUIDOR = 'CONTRIBUIDOR'
const TYPE_FISICA = 'FISICA'
const TYPE_JURIDICA = 'JURIDICA'
const TYPE_SOCIEDAD = 'SOCIEDAD'
const ALL_TYPES = [TYPE_NOTARIO, TYPE_CONTRIBUIDOR, TYPE_FISICA, TYPE_JURIDICA, TYPE_SOCIEDAD].join(', ')
const TABS = [
  {
    label: 'Todos',
    filter: () => true,
    restrict: () => true,
  },
  {
    label: 'Notarios',
    filter: item => item.fisica?.notario,
    restrict: (restrictions) => restrictions.includes(TYPE_NOTARIO) && restrictions.length < 5,
  },
  {
    label: 'Personas físicas',
    filter: item => item.fisica,
    restrict: (restrictions) => restrictions.includes(TYPE_FISICA),
  },
  {
    label: 'Personas jurídicas',
    filter: item => item.juridica,
    restrict: (restrictions) => restrictions.includes(TYPE_JURIDICA),
  },
  {
    label: 'Contribuidores',
    filter: item => item.fisica?.contribuidor,
    restrict: (restrictions) => restrictions.includes(TYPE_CONTRIBUIDOR),
  },
  {
    label: 'Sociedades',
    filter: item => item.cif,
    restrict: (restrictions) => restrictions.includes(TYPE_SOCIEDAD),
  },
]

const sociedadesClient = new SociedadesClient(webinterna())


export default function PeopleSelector({ label, header, value, onChange, restrict = ALL_TYPES, className, required }) {
  let [open, setOpen] = useState(false)

  let [options, setOptions] = useState([])
  let [loading, setLoading] = useState(true)
  let [selected, setSelected] = useState(false)
  let [selectedInsideSelector, setSelectedInsideSelector] = useState({})
  let [currentTab, setCurrentTab] = useState(TABS[0])

  let filterElement = useRef()
  let [filter, setFilter] = useState('')
  let [filtered, setFiltered] = useState([])

  let restrictions = restrict
    .split(',')
    .map(element => element.trim())

  function isSelected(item) {
    return item.value === selectedInsideSelector.value
  }

  useEffect(() => {
    async function getData() {
      if (!options.length) {
        let options = []
        
        let hasSociedades = restrictions.includes(TYPE_SOCIEDAD)
        let hasPersonas = restrictions.includes(TYPE_NOTARIO) || restrictions.includes(TYPE_CONTRIBUIDOR) || restrictions.includes(TYPE_FISICA) || restrictions.includes(TYPE_JURIDICA)
        
        let { personas, sociedades } = await resolver.all({
          personas: hasPersonas ? listPersonas() : null,
          sociedades: hasSociedades ? sociedadesClient.List().then(reply => reply.sociedades || []) : null,
        })

        if (hasSociedades) {
          options.push(...sociedades.map(sociedad => {
            return {
              ...sociedad,
              displayName: sociedad.denominacion,
              identidad: sociedad.cif,
              value: sociedad.name,
              type: 'SOCIEDAD',
            }
          }))
        }
        if (hasPersonas) {
          personas.forEach(persona => {
            if ((persona.fisica && restrictions.includes(TYPE_FISICA)) || (persona.juridica && restrictions.includes(TYPE_JURIDICA)) ||
              (persona.fisica?.contribuidor && restrictions.includes(TYPE_CONTRIBUIDOR)) || (persona.fisica?.notario && restrictions.includes(TYPE_NOTARIO))) {
              let displayName
              if (persona.fisica?.fisica) {
                displayName = persona.fisica.fisica.nombre + ' ' + persona.fisica.fisica.apellidos
              } else {
                displayName = persona.nombre
              }
              options.push({
                ...persona,
                pictureUrl: persona.fotoUrl,
                displayName: displayName,
                value: persona.name,
                type: 'PERSONA',
              })
            }
          })
        }
        setOptions(options)
        setLoading(false)
      }
    }

    getData()
    
  }, [restrict, options.length, restrictions])

  useEffect(() => {
    if (value && options.length) {
      let selectedValue = value
      if (isObject(value)) {
        selectedValue = Object.values(value)[0]
      }
      setSelected(options.filter(o => selectedValue === o.name)[0])
    }
  }, [value, options.length, options])

  useEffect(() => {
    let filtered = options
    filtered.sort(firstBy(item => item.displayName).thenBy('email'))
    if (filter) {
      let search = diacritic.clean(filter).toUpperCase()

      filtered = options.filter(item => {
        let $search = diacritic.clean(`${item.displayName} ${item.email}`).toUpperCase()
        return $search.includes(search)
      })
        
      let end = Math.min(PAGE_SIZE, filtered.length)
      filtered = filtered.slice(0, end)
    }
    filtered = filtered.filter(item => currentTab.filter(item))
    setFiltered(filtered)
  }, [currentTab, filter, options])

  useEffect(() => {
    if (open) {
      setTimeout(() => filterElement.current.focus())
    }
  }, [open])

  return (
    <div className={className}>
      <label className="font-medium leading-5 text-gray-700 text-sm">
        {label}
        {required && label && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="bg-white mb-2 mt-1 shadow md:rounded-lg">
        <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:items-center">
            {selected && selected.email && <Face displayName={selected.displayName || selected.email} pictureUrl={selected.pictureUrl}></Face>}
            {(!selected || !selected.email) &&
              <span className="fa-stack fa-lg text-gray-200">
                <Icon solid name="camera" className="fa-stack-1x" />
                <Icon solid name="ban" className="fa-stack-2x" />
              </span>
            }
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <div className="text-sm font-medium text-gray-900">
                {selected?.displayName}
              </div>
              <div className="text-sm text-gray-600">
                {selected?.identidad}
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 md:flex-shrink-0">
            <ButtonAsyncWhite onClick={() => setOpen(true)}>
              <Icon solid name="user" className="mr-2" />
              Editar
            </ButtonAsyncWhite>
          </div>
        </div>
      </div>

      <SideSheet
        wide
        fit
        header={header ? header : label}
        open={open}
        onClose={() => setOpen(false)}
        footer={
          <div className="flex w-full justify-between">
            <ButtonHoverDanger onClick={() => {
              setSelected({})
              if (onChange) {
                onChange(null)
              }
              setOpen(false)
            }}>
              <Icon solid name="unlink" className="mr-2" />
              Desvincular existente
            </ButtonHoverDanger>
            <ButtonPrimary className="text-right" onClick={() => {
              setSelected(selectedInsideSelector || {})
              if (onChange) {
                if (restrictions.includes(TYPE_SOCIEDAD) && restrictions.length > 1) {
                  if (selectedInsideSelector.type === 'SOCIEDAD') {
                    onChange({
                      sociedad: selectedInsideSelector?.value,
                    })
                  } else {
                    onChange({
                      persona: selectedInsideSelector?.value,
                    })
                  }
                } else {
                  onChange(selectedInsideSelector?.value)
                }
              }
              setOpen(false)
            }}>
              <Icon solid name="link" className="mr-2" />
              Seleccionar
            </ButtonPrimary>
          </div>
        }
      >
        <div className="mt-5 border-b border-gray-200">
          <div className="px-6">
            <nav className="-mb-px flex space-x-4">
              {TABS
                .filter(tab => tab.restrict(restrictions))
                .map((tab) => (
                  <div
                    key={tab.label}
                    className={cx(
                      'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
                      {
                        'border-indigo-500 text-indigo-600': tab === currentTab,
                        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': tab !== currentTab,
                      },
                    )}
                    onClick={() => setCurrentTab(tab)}
                  >
                    {tab.label}
                  </div>
                ))
              }
            </nav>
          </div>
        </div>
        <div className="flex rounded-md w-full">
          <div className="relative flex-grow focus-within:z-10 border-b-3 border-gray-300 pl-6">
            <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
              <Icon solid name="search" className="text-gray-400" />
            </div>
            <input
              ref={filterElement}
              type="text"
              id="search"
              autoComplete="off"
              className="form-input block w-full focus:ring-0 py-2 pl-8 border-0"
              placeholder="Filtrar..."
              value={filter}
              onChange={event => setFilter(event.target.value.trim())}
            />
          </div>
        </div>
        <div className="relative flex-1 overflow-y-auto">
          <ul>
            {loading &&
              <div className="mt-40">
                <Throbber/>
              </div>
            }
            {!loading && filtered.map((item) => (
              <li key={item.value} className={cx(
                'px-6 py-3 relative border-t border-b -mt-px',
                {
                  'z-10 bg-indigo-100 border-indigo-700': isSelected(item),
                  'border-gray-300': !isSelected(item),
                },
              )}>
                <div className="group flex justify-between items-center">
                  <div onClick={() => setSelectedInsideSelector(item)} className="-m-1 p-1 block cursor-pointer">
                    <div className={cx(
                      'absolute inset-0',
                      {
                        'group-hover:bg-indigo-100': isSelected(item),
                        'group-hover:bg-gray-200': !isSelected(item),
                      },
                    )} />
                    <div className="flex-1 flex items-center min-w-0 relative">
                      <Face displayName={item.displayName || item.email} pictureUrl={item.pictureUrl} />
                      <div className="truncate cursor-pointer ml-4">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {item.displayName
                            ? item.displayName
                            : <span className="italic">(sin nombre)</span>
                          }
                        </p>
                        {item.identidad &&
                        <p className="text-sm text-gray-500 truncate">{item.identidad}</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 relative inline-block text-left">
                    <a
                      href={item.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={cx(
                        'group relative w-10 h-10 text-gray-500 rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
                        {
                          'bg-indigo-100': isSelected(item),
                          'bg-white': !isSelected(item),
                        },
                      )}
                    >
                      <span className="flex items-center justify-center h-full w-full rounded-full">
                        <Icon regular name="external-link" className="fa-lg" />
                      </span>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </SideSheet>
    </div>
  )
}
