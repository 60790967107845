
import React, { useState } from 'react'
import cx from 'classnames'

import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import ClickOutside from 'components/ClickOutside'
import { useIfMounted } from 'hooks/if-mounted'


export default function ButtonConfirmDanger({ onClick, children, className, sendingLabel = 'Eliminando...', confirmation, type = 'button' }) {
  let [open, setOpen] = useState(false)
  let [sending, setSending] = useState(false)
  let ifMounted = useIfMounted()

  async function onClickInternal() {
    setSending(true)
    await onClick()
    ifMounted(() => {
      setSending(false)
    })
  }

  return (
    <div className="relative inline-block">
      <span className="shadow-sm rounded-md">
        <button
          type={type}
          className={cx(
            'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:text-white hover:bg-red-500 active:bg-red-700 bg-white focus:outline-none',
            {
              'bg-red-600 text-white': open,
              'focus:ring-2 focus:ring-offset-2 text-red-600': !open,
            },
            className,
          )}
          onClick={() => setOpen(!open)}
        >
          {children}
        </button>
      </span>

      {open &&
        <ClickOutside onClick={() => setOpen(false)}>
          <div className="absolute left-0 md:left-auto md:right-0 top-0 w-88 rounded-md shadow-lg z-40">
            <div className="rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <div className="p-7 text-center">
                {!sending && <div className="font-medium uppercase mb-6">¿Está seguro?</div>}
                <div className="flex">
                  <div className={cx('text-center', { 'flex-grow': sending })}>
                    <ButtonAsyncDanger onClick={onClickInternal} sendingLabel={sendingLabel}>
                      {confirmation || children}
                    </ButtonAsyncDanger>
                  </div>
                  {!sending &&
                    <>
                      <div className="flex-grow"></div>
                      <div>
                        <span className="inline-flex rounded-md shadow-sm">
                          <button
                            type={type}
                            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                            onClick={() => setOpen(false)}
                          >
                            Cancelar
                          </button>
                        </span>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </ClickOutside>
      }
    </div>
  )
}
