
import React from 'react'
import { accountInfo } from 'auth/active-directory'


export default function Acl({ perm, children }) {
  return (
    <>
      {perm && accountInfo().permissions.includes(perm) &&
        <>
          {children}
        </>
      }
    </>
  )
}
