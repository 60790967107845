
import React from 'react'
import cx from 'classnames'

import { useField } from 'platform/forms'
import Icon from 'components/Icon'


export default function Input({ form, className, help, name, type = 'text', label, validations, value, onChange }) {
  let field = useField({ form, name, validations, value, onChange })

  function parseValue(val) {
    if (type === 'text') {
      return val ? val.trim() : ''
    } else if (type === 'number') {
      return val ? parseInt(val, 10) : undefined
    }

    return val
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && label && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={name}
          className={cx(
            'form-input block w-full sm:text-sm rounded-md',
            {
              'focus:ring-teal-500 focus:border-teal-500 border-gray-300': !field.hasError,
              'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': field.hasError,
            },
          )}
          defaultValue={parseValue(value)}
          onChange={e => {
            field.setValue(parseValue(e.target.value))
          }}
        />
        {field.hasError &&
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
            <Icon solid name="exclamation-circle" />
          </div>
        }
      </div>
      {help && <p className="mt-2 text-gray-500">{help}</p>}
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
    </div>
  )
}
