
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'

import { webinterna } from 'services/discovery'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import SingleEntity from 'components/forms/SingleEntity'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import Datepicker from 'components/forms/Datepicker'
import { parseDate, serializeDate } from 'platform/datetime'
import CardHeader from 'components/CardHeader'
import { WideList, WideListEmpty, WideListInteractiveItem } from 'components/WideList'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import { nanoid } from 'nanoid'
import Textarea from 'components/forms/Textarea'
import SectionTitle from 'components/SectionTitle'
import ButtonSecondarySm from 'components/buttons/ButtonSecondarySm'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import AlertDanger from 'components/alerts/AlertDanger'
import Percent from 'components/forms/Percent'
import { Cell, HeaderCell, TableRow } from 'components/Table'
import CardTable from 'components/CardTable'
import { useNavigate } from 'hooks/navigate'


const proyectosClient = new ProyectosClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())


function serializePlanTemporal(planTemporal) {
  planTemporal.inicioDate = serializeDate(planTemporal.inicioDate)
  planTemporal.finDate = serializeDate(planTemporal.finDate)

  return planTemporal
}

function parsePlanTemporal(planTemporal) {
  planTemporal.inicioDate = parseDate(planTemporal.inicioDate)
  planTemporal.finDate = parseDate(planTemporal.finDate)

  return planTemporal
}

function useProyecto({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [proyecto, setProyecto] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (isEdit) {
      let reply = await proyectosClient.Get({ name })
      reply.garantia1yDate = parseDate(reply.garantia1yDate)
      reply.garantia3yDate = parseDate(reply.garantia3yDate)
      reply.garantia10yDate = parseDate(reply.garantia10yDate)
      reply.cfoDate = parseDate(reply.cfoDate)
      if (reply.planeamiento) {
        reply.planeamiento.planTemporal = parsePlanTemporal(reply.planeamiento.planTemporal)
      }
      if (reply.urbanizacion) {
        reply.urbanizacion.planTemporal = parsePlanTemporal(reply.urbanizacion.planTemporal)
      }
      if (reply.proyectoBasico) {
        reply.proyectoBasico.planTemporal = parsePlanTemporal(reply.proyectoBasico.planTemporal)
      }
      if (reply.licencia) {
        reply.licencia.planTemporal = parsePlanTemporal(reply.licencia.planTemporal)
      }
      if (reply.proyectoEdificacion) {
        reply.proyectoEdificacion.planTemporal = parsePlanTemporal(reply.proyectoEdificacion.planTemporal)
      }
      if (reply.legalizacionEntrega) {
        reply.legalizacionEntrega.planTemporal = parsePlanTemporal(reply.legalizacionEntrega.planTemporal)
      }
      if (reply.licitacion) {
        reply.licitacion.planTemporal = parsePlanTemporal(reply.licitacion.planTemporal)
      }
      if (reply.obras) {
        reply.obras.planTemporal = parsePlanTemporal(reply.obras.planTemporal)
      }
      setProyecto(reply)
    }
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    reply.entidad.proyecto.garantia1yDate = parseDate(reply.entidad.proyecto.garantia1yDate)
    reply.entidad.proyecto.garantia3yDate = parseDate(reply.entidad.proyecto.garantia3yDate)
    reply.entidad.proyecto.garantia10yDate = parseDate(reply.entidad.proyecto.garantia10yDate)
    reply.entidad.proyecto.cfoDate = parseDate(reply.entidad.proyecto.cfoDate)
    if (reply.entidad.proyecto.planeamiento) {
      reply.entidad.proyecto.planeamiento.planTemporal = parsePlanTemporal(reply.entidad.proyecto.planeamiento.planTemporal)
    }
    if (reply.entidad.proyecto.urbanizacion) {
      reply.entidad.proyecto.urbanizacion.planTemporal = parsePlanTemporal(reply.entidad.proyecto.urbanizacion.planTemporal)
    }
    if (reply.entidad.proyecto.proyectoBasico) {
      reply.entidad.proyecto.proyectoBasico.planTemporal = parsePlanTemporal(reply.entidad.proyecto.proyectoBasico.planTemporal)
    }
    if (reply.entidad.proyecto.licencia) {
      reply.entidad.proyecto.licencia.planTemporal = parsePlanTemporal(reply.entidad.proyecto.licencia.planTemporal)
    }
    if (reply.entidad.proyecto.proyectoEdificacion) {
      reply.entidad.proyecto.proyectoEdificacion.planTemporal = parsePlanTemporal(reply.entidad.proyecto.proyectoEdificacion.planTemporal)
    }
    if (reply.entidad.proyecto.legalizacionEntrega) {
      reply.entidad.proyecto.legalizacionEntrega.planTemporal = parsePlanTemporal(reply.entidad.proyecto.legalizacionEntrega.planTemporal)
    }
    if (reply.entidad.proyecto.licitacion) {
      reply.entidad.proyecto.licitacion.planTemporal = parsePlanTemporal(reply.entidad.proyecto.licitacion.planTemporal)
    }
    if (reply.entidad.proyecto.obras) {
      reply.entidad.proyecto.obras.planTemporal = parsePlanTemporal(reply.entidad.proyecto.obras.planTemporal)
    }
    setProyecto(reply.entidad.proyecto)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        proyecto: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este proyecto para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/proyectos/${name}`)
    } else {
      history.push(`/webinterna/proyectos`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await proyectosClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await proyectosClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await proyectosClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    proyecto.garantia1yDate = serializeDate(proyecto.garantia1yDate)
    proyecto.garantia3yDate = serializeDate(proyecto.garantia3yDate)
    proyecto.garantia10yDate = serializeDate(proyecto.garantia10yDate)
    proyecto.cfoDate = serializeDate(proyecto.cfoDate)
    proyecto.planeamiento.planTemporal = serializePlanTemporal(proyecto.planeamiento.planTemporal)
    proyecto.urbanizacion.planTemporal = serializePlanTemporal(proyecto.urbanizacion.planTemporal)
    proyecto.proyectoBasico.planTemporal = serializePlanTemporal(proyecto.proyectoBasico.planTemporal)
    proyecto.licencia.planTemporal = serializePlanTemporal(proyecto.licencia.planTemporal)
    proyecto.proyectoEdificacion.planTemporal = serializePlanTemporal(proyecto.proyectoEdificacion.planTemporal)
    proyecto.legalizacionEntrega.planTemporal = serializePlanTemporal(proyecto.legalizacionEntrega.planTemporal)
    proyecto.licitacion.planTemporal = serializePlanTemporal(proyecto.licitacion.planTemporal)
    proyecto.obras.planTemporal = serializePlanTemporal(proyecto.obras.planTemporal)

    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        proyecto,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este proyecto para que lo apruebe un administrador.')
    history.push(`/webinterna/proyectos`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    proyecto,
    validation,
    remove,
  }
}

function useOrigen({ proyecto }) {
  let [origen, setOrigen] = useState([])

  function add() {
    setOrigen([
      ...origen,
      {
        ghostKey: nanoid(),
        inmueble: '',
        fincasRegistrales: [],
        elementosPatrimoniales: [],
      },
    ])
  }

  function remove(unidadInmobiliaria) {
    setOrigen(origen.filter(uin => uin !== unidadInmobiliaria))
  }

  function addFincaRegistral(index) {
    if (!origen[index].fincasRegistrales) {
      origen[index].fincasRegistrales = []
    }
    origen[index].fincasRegistrales.push({
      ghostKey: nanoid(),
      fincaRegistral: '',
      costePorcentaje: 0,
    })
    setOrigen([
      ...origen,
    ])
  }

  function removeFincaRegistral(index, fincaRegistral) {
    origen[index].fincasRegistrales = origen[index].fincasRegistrales.filter(f => f !== fincaRegistral)
    setOrigen([
      ...origen,
    ])
  }

  function addElementoPatrimonial(index) {
    if (!origen[index].elementosPatrimoniales) {
      origen[index].elementosPatrimoniales = []
    }
    origen[index].elementosPatrimoniales.push({
      ghostKey: nanoid(),
      elementoPatrimonial: '',
    })
    setOrigen([
      ...origen,
    ])
  }

  function removeElementoPatrimonial(index, elementoPatrimonial) {
    origen[index].elementosPatrimoniales = origen[index].elementosPatrimoniales.filter(e => e !== elementoPatrimonial)
    setOrigen([
      ...origen,
    ])
  }

  function setCostePorcentaje(index, frIndex, percentage) {
    origen[index].fincasRegistrales[frIndex].costePorcentaje = percentage
    setOrigen([
      ...origen,
    ])
  }

  useEffect(() => {
    if (proyecto?.origen?.length) {
      setOrigen(proyecto.origen.map(uin => {
        return {
          ...uin,
          fincasRegistrales: uin.fincasRegistrales?.map(f => { return { ...f, ghostKey: nanoid() }}),
          elementosPatrimoniales: uin.elementosPatrimoniales?.map(e => { return { elementoPatrimonial: e, ghostKey: nanoid() }}),
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setOrigen, proyecto])

  return {
    origen,
    add,
    remove,
    addFincaRegistral,
    removeFincaRegistral,
    addElementoPatrimonial,
    removeElementoPatrimonial,
    setCostePorcentaje,
  }
}

function useResultado({ proyecto }) {
  let [resultado, setResultado] = useState([])

  function add() {
    setResultado([
      ...resultado,
      {
        ghostKey: nanoid(),
        inmueble: '',
        fincasRegistrales: [],
        elementosPatrimoniales: [],
      },
    ])
  }

  function remove(unidadInmobiliaria) {
    setResultado(resultado.filter(uin => uin !== unidadInmobiliaria))
  }

  function addFincaRegistral(index) {
    if (!resultado[index].fincasRegistrales) {
      resultado[index].fincasRegistrales = []
    }
    resultado[index].fincasRegistrales.push({
      ghostKey: nanoid(),
      fincaRegistral: '',
      costePorcentaje: 0,
    })
    setResultado([
      ...resultado,
    ])
  }

  function removeFincaRegistral(index, fincaRegistral) {
    resultado[index].fincasRegistrales = resultado[index].fincasRegistrales.filter(f => f !== fincaRegistral)
    setResultado([
      ...resultado,
    ])
  }

  function addElementoPatrimonial(index) {
    if (!resultado[index].elementosPatrimoniales) {
      resultado[index].elementosPatrimoniales = []
    }
    resultado[index].elementosPatrimoniales.push({
      ghostKey: nanoid(),
      elementoPatrimonial: '',
    })
    setResultado([
      ...resultado,
    ])
  }

  function removeElementoPatrimonial(index, elementoPatrimonial) {
    resultado[index].elementosPatrimoniales = resultado[index].elementosPatrimoniales.filter(e => e !== elementoPatrimonial)
    setResultado([
      ...resultado,
    ])
  }

  function setCostePorcentaje(index, frIndex, percentage) {
    resultado[index].fincasRegistrales[frIndex].costePorcentaje = percentage
    setResultado([
      ...resultado,
    ])
  }

  useEffect(() => {
    if (proyecto?.resultado?.length) {
      setResultado(proyecto.resultado.map(uin => {
        return {
          ...uin,
          fincasRegistrales: uin.fincasRegistrales?.map(f => { return { ...f, ghostKey: nanoid() }}),
          elementosPatrimoniales: uin.elementosPatrimoniales?.map(e => { return { elementoPatrimonial: e, ghostKey: nanoid() }}),
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setResultado, proyecto])

  return {
    resultado,
    add,
    remove,
    addFincaRegistral,
    removeFincaRegistral,
    addElementoPatrimonial,
    removeElementoPatrimonial,
    setCostePorcentaje,
  }
}


export default function ProyectosEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [sociedades, setSociedades] = useState(null)
  let [inmuebles, setInmuebles] = useState(null)
  let [fincasRegistrales, setFincasRegistrales] = useState(null)
  let [elementosPatrimoniales, setElementosPatrimoniales] = useState(null)

  let {
    save: saveProyecto,
    load: loadProyecto,
    loadValidation,
    confirmValidation,
    proyecto,
    validation,
    remove: removeProyecto,
  } = useProyecto({ name })

  let {
    origen,
    add: addOrigen,
    remove: removeOrigen,
    addElementoPatrimonial: addElementoPatrimonialOrigen,
    removeElementoPatrimonial: removeElementoPatrimonialOrigen,
    addFincaRegistral: addFincaRegistralOrigen,
    removeFincaRegistral: removeFincaRegistralOrigen,
    setCostePorcentaje: setCostePorcentajeOrigen,
  } = useOrigen({ proyecto })

  let {
    resultado,
    add: addResultado,
    remove: removeResultado,
    addElementoPatrimonial: addElementoPatrimonialResultado,
    removeElementoPatrimonial: removeElementoPatrimonialResultado,
    addFincaRegistral: addFincaRegistralResultado,
    removeFincaRegistral: removeFincaRegistralResultado,
    setCostePorcentaje: setCostePorcentajeResultado,
  } = useResultado({ proyecto })

  let validationErrorOrigen = origen.some(uin => {
    if (!uin.fincasRegistrales || !uin.fincasRegistrales.length) {
      return false
    }
    return uin.fincasRegistrales.map(fr => fr.costePorcentaje).reduce((acc, curr) => acc + parseInt(curr, 10) / 100, 0) !== 100
  })
  let validationErrorResultado = resultado.some(uin => {
    if (!uin.fincasRegistrales || !uin.fincasRegistrales.length) {
      return false
    }
    return uin.fincasRegistrales.map(fr => fr.costePorcentaje).reduce((acc, curr) => acc + parseInt(curr, 10) / 100, 0) !== 100
  })

  useNavigate(async () => {
    let { sociedades } = await sociedadesClient.List()
    sociedades = sociedades || []
    sociedades = sociedades.map(sociedad => {
      return {
        value: sociedad.name,
        url: `/webinterna/sociedades/${sociedad.name}`,
        label: sociedad.denominacion,
        sublabel: sociedad.acronimo,
      }
    })
    setSociedades(sociedades)

    let { inmuebles } = await inmueblesClient.List()
    inmuebles = inmuebles || []
    inmuebles = inmuebles.map(inmueble => {
      return {
        value: inmueble.name,
        url: `/webinterna/inmuebles/${inmueble.name}`,
        label: inmueble.nombre,
        sublabel: inmueble.codigo,
      }
    })
    setInmuebles(inmuebles)

    let { fincas } = await fincasRegistralesClient.List()
    fincas = fincas || []
    let fincasRegistrales = fincas.map(fr => {
      return {
        value: fr.name,
        url: `/webinterna/fincas-registrales/${fr.name}`,
        label: fr.numeroFinca,
      }
    })
    setFincasRegistrales(fincasRegistrales)

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales || []
    elementosPatrimoniales = elementosPatrimoniales.map(ep => {
      return {
        value: ep.name,
        url: `/webinterna/fincas-registrales/${ep.name}`,
        label: ep.nombre,
      }
    })
    setElementosPatrimoniales(elementosPatrimoniales)

    if (isValidation) {
      await loadValidation()
    } else {
      await loadProyecto()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...proyecto,
      ...data,
    }

    if (validationErrorOrigen || validationErrorResultado) {
      return
    }

    req.planeamiento.gestion = req.planeamiento.gestion ?? 'GESTION_UNKNOWN'
    req.urbanizacion.gestion = req.urbanizacion.gestion ?? 'GESTION_UNKNOWN'
    req.proyectoBasico.gestion = req.proyectoBasico.gestion ?? 'GESTION_UNKNOWN'
    req.licencia.gestion = req.licencia.gestion ?? 'GESTION_UNKNOWN'
    req.proyectoEdificacion.gestion = req.proyectoEdificacion.gestion ?? 'GESTION_UNKNOWN'
    req.legalizacionEntrega.gestion = req.legalizacionEntrega.gestion ?? 'GESTION_UNKNOWN'
    req.licitacion.gestion = req.licitacion.gestion ?? 'GESTION_UNKNOWN'
    req.obras.gestion = req.obras.gestion ?? 'GESTION_UNKNOWN'

    req.garantia1yDate = serializeDate(req.garantia1yDate)
    req.garantia3yDate = serializeDate(req.garantia3yDate)
    req.garantia10yDate = serializeDate(req.garantia10yDate)
    req.cfoDate = serializeDate(req.cfoDate)
    req.planeamiento.planTemporal = serializePlanTemporal(req.planeamiento.planTemporal)
    req.urbanizacion.planTemporal = serializePlanTemporal(req.urbanizacion.planTemporal)
    req.proyectoBasico.planTemporal = serializePlanTemporal(req.proyectoBasico.planTemporal)
    req.licencia.planTemporal = serializePlanTemporal(req.licencia.planTemporal)
    req.proyectoEdificacion.planTemporal = serializePlanTemporal(req.proyectoEdificacion.planTemporal)
    req.legalizacionEntrega.planTemporal = serializePlanTemporal(req.legalizacionEntrega.planTemporal)
    req.licitacion.planTemporal = serializePlanTemporal(req.licitacion.planTemporal)
    req.obras.planTemporal = serializePlanTemporal(req.obras.planTemporal)

    if (req.origen) {
      req.origen = req.origen.map(uin => {
        return {
          ...uin,
          elementosPatrimoniales: uin.elementosPatrimoniales?.map(e => e.elementoPatrimonial),
          fincasRegistrales: uin.fincasRegistrales?.map(f => { return { ...f, costePorcentaje: f.costePorcentaje } }),
        }
      })
    }

    if (req.resultado) {
      req.resultado = req.resultado.map(uin => {
        return {
          ...uin,
          elementosPatrimoniales: uin.elementosPatrimoniales?.map(e => e.elementoPatrimonial),
          fincasRegistrales: uin.fincasRegistrales?.map(f => { return { ...f, costePorcentaje: f.costePorcentaje } }),
        }
      })
    }

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveProyecto(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !proyecto.blocked &&
            <ButtonConfirmDanger onClick={removeProyecto}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Proyecto
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/proyectos">Proyectos</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/proyectos/${name}`}>Consultar Proyecto</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/proyectos/edit/${name}`}>Editar Proyecto</Breadcrumb>
          : <Breadcrumb url="/webinterna/proyectos/edit/new">Crear Nuevo Proyecto</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={proyecto.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card className="mb-4">
          <div className="md:flex md:space-x-4 mb-6">
            <Input form={form} name="codigo" label="Código" validations="required" value={proyecto.codigo} className="w-24" />
            <Input form={form} name="denominacion" label="Nombre Proyecto" validations="required|min:3" value={proyecto.denominacion} className="flex-1 md:ml-2" />
          </div>

          <div className="md:flex md:space-x-4 mb-6">
            <Select
              form={form}
              name="tipo"
              label="Tipo"
              options={[
                { value: 'TIPO_A', label: 'Tipo A' },
                { value: 'TIPO_B', label: 'Tipo B' },
                { value: 'TIPO_C', label: 'Tipo C' },
                { value: 'TIPO_D', label: 'Tipo D' },
              ]}
              value={proyecto.tipo}
            />
            <Select
              form={form}
              name="estado"
              label="Estado"
              options={[
                { value: 'ESTADO_EN_CURSO', label: 'En curso' },
                { value: 'ESTADO_FINALIZADO', label: 'Finalizado' },
              ]}
              value={proyecto.estado}
            />
            <div className="flex-grow">
              <SingleEntity
                form={form}
                name="promotor"
                label="Promotor"
                header="Seleccionar promotor"
                options={sociedades}
                value={proyecto.promotor}
                fit
              />
            </div>
          </div>

          <div className="md:flex md:space-x-4 mb-6">
            <Textarea form={form} name="ordenanzaGeneral" label="Ordenanza General" value={proyecto.ordenanzaGeneral} className="flex-grow" />
            <Textarea form={form} name="ambitoDesarrollo" label="Ámbito Desarrollo" value={proyecto.ambitoDesarrollo} className="flex-grow" />
          </div>

          <div className="md:flex md:space-x-4 mb-6">
            <Select
              form={form}
              name="tipoContable"
              label="Tipo Contable"
              options={[
                { value: 'TIPO_CONTABLE_EXISTENCIAS_EN_CURSO', label: 'Existencias en curso' },
                { value: 'TIPO_CONTABLE_INMOBILIZADO_EN_CURSO', label: 'Inmobilizado en curso' },
                { value: 'TIPO_CONTABLE_EXISTENCIAS_FINALIZADO', label: 'Existencias finalizado' },
                { value: 'TIPO_CONTABLE_INMOBILIZADO_FINALIZADO', label: 'Inmobilizado finalizado' },
              ]}
              value={proyecto.tipoContable}
            />
            <Input form={form} name="cuentaContable" label="Cuenta Contable" value={proyecto.cuentaContable} className="flex-1" />
          </div>
          
          <div className="md:flex md:space-x-4">
            <Datepicker
              form={form}
              name="garantia1yDate"
              label="Periodo garantía 1 año"
              value={proyecto.garantia1yDate}
            />
            <Datepicker
              form={form}
              name="garantia3yDate"
              label="Periodo garantía 3 años"
              value={proyecto.garantia3yDate}
            />
            <Datepicker
              form={form}
              name="garantia10yDate"
              label="Periodo garantía 10 años"
              value={proyecto.garantia10yDate}
            />
            <Datepicker
              form={form}
              name="cfoDate"
              label="Fecha CFO"
              value={proyecto.cfoDate}
            />
          </div>
        </Card>

        <CardTable
          title="Etapas"
          header={
            <>
              <HeaderCell>Nombre</HeaderCell>
              <HeaderCell>Estado</HeaderCell>
              <HeaderCell>Fecha inicio</HeaderCell>
              <HeaderCell>Fecha fin</HeaderCell>
              <HeaderCell>% progreso</HeaderCell>
            </>
          }
        >
          <TableRow>
            <Cell>Planeamiento</Cell>
            <Cell>
              <Select
                form={form}
                name="planeamiento.gestion"
                options={[
                  { value: 'GESTION_REDACCION', label: 'Redacción' },
                  { value: 'GESTION_APROBACION_INICIAL', label: 'Aprobación inicial' },
                  { value: 'GESTION_APROBACION_DEFINITIVA', label: 'Aprobación definitiva' },
                ]}
                value={proyecto.planeamiento?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="planeamiento.planTemporal.inicioDate"
                value={proyecto.planeamiento?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="planeamiento.planTemporal.finDate"
                value={proyecto.planeamiento?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="planeamiento.planTemporal.progresoPercentage" value={proyecto.planeamiento?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Urbanización</Cell>
            <Cell>
              <Select
                form={form}
                name="urbanizacion.gestion"
                options={[
                  { value: 'GESTION_PLAN_PARCIAL', label: 'Plan parcial' },
                  { value: 'GESTION_INNOVACION_PLANEAMIENTO', label: 'Planeamiento innovación' },
                  { value: 'GESTION_PERI', label: 'Peri' },
                  { value: 'GESTION_PROYECTO_URBANIZACION', label: 'Proyecto urbanización' },
                  { value: 'GESTION_PROYECTO_REPARCELIZACION', label: 'Proyecto reparcelización' },
                  { value: 'GESTION_ESTUDIO_DETALLE', label: 'Estudio detalle' },
                ]}
                value={proyecto.urbanizacion?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="urbanizacion.planTemporal.inicioDate"
                value={proyecto.urbanizacion?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="urbanizacion.planTemporal.finDate"
                value={proyecto.urbanizacion?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="urbanizacion.planTemporal.progresoPercentage" value={proyecto.urbanizacion?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Proyecto básico</Cell>
            <Cell>
              <Select
                form={form}
                name="proyectoBasico.gestion"
                options={[
                  { value: 'GESTION_NO_INICIADA', label: 'No iniciado' },
                  { value: 'GESTION_EN_CURSO', label: 'En curso' },
                  { value: 'GESTION_FINALIZADO', label: 'Finalizado' },
                ]}
                value={proyecto.proyectoBasico?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="proyectoBasico.planTemporal.inicioDate"
                value={proyecto.proyectoBasico?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="proyectoBasico.planTemporal.finDate"
                value={proyecto.proyectoBasico?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="proyectoBasico.planTemporal.progresoPercentage" value={proyecto.proyectoBasico?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Licencia</Cell>
            <Cell>
              <Select
                form={form}
                name="licencia.gestion"
                options={[
                  { value: 'GESTION_NO_PRESENTADO', label: 'No presentada' },
                  { value: 'GESTION_EN_TRAMITACION', label: 'En tramitación' },
                  { value: 'GESTION_CONCEDIDA', label: 'Concedida' },
                ]}
                value={proyecto.licencia?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="licencia.planTemporal.inicioDate"
                value={proyecto.licencia?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="licencia.planTemporal.finDate"
                value={proyecto.licencia?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="licencia.planTemporal.progresoPercentage" value={proyecto.licencia?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Proyecto Edificación</Cell>
            <Cell>
              <Select
                form={form}
                name="proyectoEdificacion.gestion"
                options={[
                  { value: 'GESTION_NO_INICIADA', label: 'No iniciada' },
                  { value: 'GESTION_EN_CURSO', label: 'En curso' },
                  { value: 'GESTION_FINALIZADO', label: 'Finalizado' },
                ]}
                value={proyecto.proyectoEdificacion?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="proyectoEdificacion.planTemporal.inicioDate"
                value={proyecto.proyectoEdificacion?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="proyectoEdificacion.planTemporal.finDate"
                value={proyecto.proyectoEdificacion?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="proyectoEdificacion.planTemporal.progresoPercentage" value={proyecto.proyectoEdificacion?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Licitación</Cell>
            <Cell>
              <Select
                form={form}
                name="licitacion.gestion"
                options={[
                  { value: 'GESTION_NO_INICIADA', label: 'No iniciada' },
                  { value: 'GESTION_EN_CURSO', label: 'En curso' },
                  { value: 'GESTION_FINALIZADO', label: 'Finalizado' },
                ]}
                value={proyecto.licitacion?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="licitacion.planTemporal.inicioDate"
                value={proyecto.licitacion?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="licitacion.planTemporal.finDate"
                value={proyecto.licitacion?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="licitacion.planTemporal.progresoPercentage" value={proyecto.licitacion?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Obras</Cell>
            <Cell>
              <Select
                form={form}
                name="obras.gestion"
                options={[
                  { value: 'GESTION_NO_INICIADA', label: 'No iniciada' },
                  { value: 'GESTION_EN_CURSO', label: 'En curso' },
                  { value: 'GESTION_TERMINADA_EN_GARANTIA', label: 'Terminada en garantía' },
                  { value: 'GESTION_CERRADA', label: 'Cerrada' },
                ]}
                value={proyecto.obras?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="obras.planTemporal.inicioDate"
                value={proyecto.obras?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="obras.planTemporal.finDate"
                value={proyecto.obras?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="obras.planTemporal.progresoPercentage" value={proyecto.obras?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>Legalización entrega</Cell>
            <Cell>
              <Select
                form={form}
                name="legalizacionEntrega.gestion"
                options={[
                  { value: 'GESTION_NO_INICIADA', label: 'No iniciada' },
                  { value: 'GESTION_EN_CURSO', label: 'En curso' },
                  { value: 'GESTION_FINALIZADO', label: 'Finalizado' },
                ]}
                value={proyecto.legalizacionEntrega?.gestion}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="legalizacionEntrega.planTemporal.inicioDate"
                value={proyecto.legalizacionEntrega?.planTemporal.inicioDate}
              />
            </Cell>
            <Cell>
              <Datepicker
                form={form}
                name="legalizacionEntrega.planTemporal.finDate"
                value={proyecto.legalizacionEntrega?.planTemporal.finDate}
              />
            </Cell>
            <Cell>
              <Percent form={form} name="legalizacionEntrega.planTemporal.progresoPercentage" value={proyecto.legalizacionEntrega?.planTemporal.progresoPercentage} />
            </Cell>
          </TableRow>
        </CardTable>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
          <div>
            <CardHeader
              fit
              header="UINs origen"
              className={cx('mt-4', { 'border-red-300 border-2': validationErrorOrigen })}
            >
              {validationErrorOrigen && <AlertDanger>La suma de los costes de cada finca registral debe ser 100</AlertDanger>}
              <WideList divider="divide-y-4 divide-teal-400">
                {!origen?.length && <WideListEmpty>No hay UINs</WideListEmpty>}
                {origen.map((unidadInmobiliaria, uinIndex) => (
                  <WideListInteractiveItem
                    key={unidadInmobiliaria.ghostKey}
                    actions={
                      <div className="self-start">
                        <ButtonHoverDanger onClick={() => removeOrigen(unidadInmobiliaria)}>
                          <Icon className="h-5" solid name="trash" />
                        </ButtonHoverDanger>
                      </div>
                    }
                  >
                    <legend className="text-base leading-6 font-medium text-gray-900 mb-6">UIN {uinIndex + 1}</legend>

                    <SingleEntity
                      form={form}
                      name={`origen[${uinIndex}].inmueble`}
                      label="Unidad Inmobiliaria"
                      header="Seleccionar unidad inmobiliaria"
                      options={inmuebles}
                      validations="required"
                      value={unidadInmobiliaria.inmueble}
                      fit
                    />
                    
                    <div className="mt-6 border-t-2 border-gray-200 pt-4">
                      <SectionTitle fit>Fincas registrales</SectionTitle>
                    </div>
                    {unidadInmobiliaria.fincasRegistrales && unidadInmobiliaria.fincasRegistrales.map((fincaRegistral, frIndex) => (
                      <div key={fincaRegistral.ghostKey}>
                        <div className="flex items-end gap-x-4 mb-4">
                          <SingleEntity
                            form={form}
                            name={`origen[${uinIndex}].fincasRegistrales[${frIndex}].fincaRegistral`}
                            label={`Finca Registral ${frIndex + 1}`}
                            header="Seleccionar finca registral"
                            options={fincasRegistrales}
                            value={fincaRegistral.fincaRegistral}
                            validations="required"
                            fit
                            className="flex-grow"
                          />
                          <Percent
                            form={form}
                            name={`origen[${uinIndex}].fincasRegistrales[${frIndex}].costePorcentaje`}
                            label="% coste"
                            validations="required|minNumber:1|maxNumber:10000"
                            value={parseInt(fincaRegistral.costePorcentaje, 10)}
                            onChange={value => setCostePorcentajeOrigen(uinIndex, frIndex, value)}
                            className="w-16"
                          />
                          <ButtonHoverDanger onClick={() => removeFincaRegistralOrigen(uinIndex, fincaRegistral)}>
                            <Icon className="h-5" solid name="trash" />
                          </ButtonHoverDanger>
                        </div>
                      </div>
                    ))}

                    <ButtonSecondarySm onClick={() => addFincaRegistralOrigen(uinIndex)}>
                      <Icon solid name="plus" className="mr-2" />
                      Añadir Finca Registral
                    </ButtonSecondarySm>

                    <div className="mt-6 border-t-2 border-gray-200 pt-4">
                      <SectionTitle fit>Elementos patrimoniales</SectionTitle>
                    </div>
                    {unidadInmobiliaria.elementosPatrimoniales && unidadInmobiliaria.elementosPatrimoniales.map((elementoPatrimonial, epIndex) => (
                      <div key={elementoPatrimonial.ghostKey}>
                        <div className="flex gap-x-4 items-end mb-4">
                          <SingleEntity
                            form={form}
                            name={`origen[${uinIndex}].elementosPatrimoniales[${epIndex}].elementoPatrimonial`}
                            label={`Elemento Patrimonial ${epIndex}`}
                            header="Seleccionar elemento patrimonial"
                            options={elementosPatrimoniales}
                            value={elementoPatrimonial.elementoPatrimonial}
                            validations="required"
                            fit
                            className="flex-grow"
                          />
                          <ButtonHoverDanger onClick={() => removeElementoPatrimonialOrigen(uinIndex, elementoPatrimonial)}>
                            <Icon className="h-5" solid name="trash" />
                          </ButtonHoverDanger>
                        </div>
                      </div>
                    ))}
                    <ButtonSecondarySm onClick={() => addElementoPatrimonialOrigen(uinIndex)}>
                      <Icon solid name="plus" className="mr-2" />
                    Añadir Elemento Patrimonial
                    </ButtonSecondarySm>
                  </WideListInteractiveItem>
                ))}
                <WideListInteractiveItem>
                  <ButtonSecondary onClick={addOrigen}>
                    <Icon solid name="plus" className="mr-2" />
                  Añadir UIN
                  </ButtonSecondary>
                </WideListInteractiveItem>
              </WideList>
            </CardHeader>
          </div>

          <div>
            <CardHeader
              fit
              header="UINs destino"
              className={cx('mt-4', { 'border-red-300 border-2': validationErrorResultado })}
            >
              {validationErrorResultado && <AlertDanger>La suma de los costes de cada finca registral debe ser 100</AlertDanger>}
              <WideList divider="divide-y-4 divide-teal-400">
                {!resultado?.length && <WideListEmpty>No hay UINs</WideListEmpty>}
                {resultado.map((unidadInmobiliaria, uinIndex) => (
                  <WideListInteractiveItem
                    key={unidadInmobiliaria.ghostKey}
                    actions={
                      <div className="self-start">
                        <ButtonHoverDanger onClick={() => removeResultado(unidadInmobiliaria)}>
                          <Icon className="h-5" solid name="trash" />
                        </ButtonHoverDanger>
                      </div>
                    }
                  >
                    <legend className="text-base leading-6 font-medium text-gray-900 mb-6">UIN {uinIndex + 1}</legend>

                    <SingleEntity
                      form={form}
                      name={`resultado[${uinIndex}].inmueble`}
                      label="Unidad Inmobiliaria"
                      header="Seleccionar unidad inmobiliaria"
                      options={inmuebles}
                      validations="required"
                      value={unidadInmobiliaria.inmueble}
                      fit
                    />

                    <div className="mt-6 border-t-2 border-gray-200 pt-4">
                      <SectionTitle fit>Fincas registrales</SectionTitle>
                    </div>
                    {unidadInmobiliaria.fincasRegistrales && unidadInmobiliaria.fincasRegistrales.map((fincaRegistral, frIndex) => (
                      <div key={fincaRegistral.ghostKey}>
                        <div className="flex gap-x-4 items-end mb-4">
                          <SingleEntity
                            form={form}
                            name={`resultado[${uinIndex}].fincasRegistrales[${frIndex}].fincaRegistral`}
                            label={`Finca Registral ${frIndex + 1}`}
                            header="Seleccionar finca registral"
                            options={fincasRegistrales}
                            value={fincaRegistral.fincaRegistral}
                            validations="required"
                            fit
                            className="flex-grow"
                          />
                          <Percent
                            form={form}
                            name={`resultado[${uinIndex}].fincasRegistrales[${frIndex}].costePorcentaje`}
                            label="% coste"
                            validations="required|minNumber:1|maxNumber:10000"
                            value={parseInt(fincaRegistral.costePorcentaje, 10)}
                            onChange={value => setCostePorcentajeResultado(uinIndex, frIndex, value)}
                            className="w-16"
                          />
                          <ButtonHoverDanger onClick={() => removeFincaRegistralResultado(uinIndex, fincaRegistral)}>
                            <Icon className="h-5" solid name="trash" />
                          </ButtonHoverDanger>
                        </div>
                      </div>
                    ))}

                    <ButtonSecondarySm onClick={() => addFincaRegistralResultado(uinIndex)}>
                      <Icon solid name="plus" className="mr-2" />
                      Añadir Finca Registral
                    </ButtonSecondarySm>

                    <div className="mt-6 border-t-2 border-gray-200 pt-4">
                      <SectionTitle fit>Elementos patrimoniales</SectionTitle>
                    </div>
                    {unidadInmobiliaria.elementosPatrimoniales && unidadInmobiliaria.elementosPatrimoniales.map((elementoPatrimonial, epIndex) => (
                      <div key={elementoPatrimonial.ghostKey}>
                        <div className="flex gap-x-4 items-end mb-4">
                          <SingleEntity
                            form={form}
                            name={`resultado[${uinIndex}].elementosPatrimoniales[${epIndex}].elementoPatrimonial`}
                            label={`Elemento Patrimonial ${epIndex}`}
                            header="Seleccionar elemento patrimonial"
                            options={elementosPatrimoniales}
                            validations="required"
                            value={elementoPatrimonial.elementoPatrimonial}
                            fit
                            className="flex-grow"
                          />
                          <ButtonHoverDanger onClick={() => removeElementoPatrimonialResultado(uinIndex, elementoPatrimonial)}>
                            <Icon solid name="trash" />
                          </ButtonHoverDanger>
                        </div>
                      </div>
                    ))}
                    <ButtonSecondarySm onClick={() => addElementoPatrimonialResultado(uinIndex)}>
                      <Icon solid name="plus" className="mr-2" />
                    Añadir Elemento Patrimonial
                    </ButtonSecondarySm>
                  </WideListInteractiveItem>
                ))}
                <WideListInteractiveItem>
                  <ButtonSecondary onClick={addResultado}>
                    <Icon solid name="plus" className="mr-2" />
                  Añadir UIN
                  </ButtonSecondary>
                </WideListInteractiveItem>
              </WideList>
            </CardHeader>
          </div>
        </div>

        {!proyecto.blocked &&
          <Submit validation={validation} form={form}>Guardar proyecto</Submit>
        }
      </Form>
    </PageLayout>
  )
}
