
import React, { useState } from 'react'
import { some } from 'lodash-es'

import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import User from 'components/simple-forms/User'
import { runQuery, putEntity, getEntityOrDefault } from 'models/docs/entities'
import * as query from 'models/docs/query'
import { serializeTimestamp } from 'platform/datetime'
import { LIBRARY_MANAGERS_NAME } from 'models/docs/library-managers'


const client = new UsersClient(users())


export default function RolesTransfer() {
  let [form, setForm] = useState({})
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { users } = await client.List()
    setUsers(users)
  })

  async function save() {
    let q = query.build(
      query.and('payload.owner.privacyManager', form.from),
    )
    let owners = await runQuery('owners', q)
    owners.forEach(async owner => {
      owner.privacyManager = form.to
      await putEntity('owners', { owner })
    })
    
    q = query.build(
      query.and('payload.inboxesGroup.users', form.from),
    )
    let inboxesGroups = await runQuery('inboxes-groups', q)
    inboxesGroups.forEach(async inboxGroup => {
      inboxGroup.users = inboxGroup.users.filter(u => u !== form.from)
      inboxGroup.users.push(form.to)
      await putEntity('inboxes-groups', { inboxGroup })
    })

    q = query.build(query.or(
      query.and('payload.accessGroup.users', form.from),
      query.and('payload.accessGroup.pendingUsers', form.from),
    ))
    let accessGroups = await runQuery('access-groups', q)
    accessGroups.forEach(async accessGroup => {
      accessGroups.users = accessGroups.users.filter(u => u !== form.from)
      accessGroups.pendingUsers = accessGroups.pendingUsers.filter(u => u !== form.from)

      if (!accessGroups.users.include(form.to)) {
        accessGroup.pendingUsers.push(form.to)

        accessGroup.owners.forEach(async owner => {
          let accessInvitation = {
            createTime: serializeTimestamp(new Date()),
            authorization: 'AUTHORIZATION_PENDING',
            accessGroup: accessGroup.name,
            user: form.to,
            owner,
          }
          await putEntity('access-invitations', { accessInvitation })
        })
      }

      await putEntity('access-groups', { accessGroup })
    })


    let libraryManagers = await getEntityOrDefault(LIBRARY_MANAGERS_NAME)
    libraryManagers.users = libraryManagers.users || []
    let idx = libraryManagers.users.indexOf(form.from)
    if (idx > -1) {
      libraryManagers.users[idx] = form.to
      await putEntity('library-managers', {
        libraryManagers,
      })
    }
  }

  let changed = some([
    form.from,
    form.to,
  ])

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/roles-transfer">Transferencia de roles</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Usuarios"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="exchange-alt" className="mr-2" />
            Transferir roles
          </ButtonAsyncPrimary>
        }
      >
        <User
          label="Usuario que perderá los roles"
          users={users}
          value={form.from}
          onChange={from => {
            setForm({
              ...form,
              from,
            })
          }}
        ></User>

        <User
          label="Usuario que hereda los roles"
          className="mt-8"
          users={users}
          value={form.to}
          onChange={to => {
            setForm({
              ...form,
              to,
            })
          }}
        ></User>
      </CardHeader>
    </PageLayout>
  )
}
