
import React, { useState } from 'react'
import cx from 'classnames'

import Icon from 'components/Icon'
import { useIfMounted } from 'hooks/if-mounted'


export default function ButtonAsyncDanger({ onClick, className, disabled, sendingLabel = 'Enviando datos...', children, type = 'button' }) {
  let [sending, setSending] = useState(false)
  let ifMounted = useIfMounted()

  async function onClickInternal() {
    setSending(true)
    await onClick()
    ifMounted(() => {
      setSending(false)
    })
  }

  return (
    <div className="inline-block">
      {sending
        ?
        <button
          type={type}
          disabled
          className={cx(
            'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-300 cursor-default',
            className,
          )}
        >
          <Icon solid spin name="spinner" className="mr-2" />
          {sendingLabel}
        </button>
        :
        <button
          type={type}
          disabled={disabled}
          className={cx(
            'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white',
            {
              'bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:bg-red-700': !disabled,
              'bg-red-300 cursor-default': disabled,
            },
            className,
          )}
          onClick={onClickInternal}
        >
          {children}
        </button>
      }
    </div>
  )
}
