
import React, { useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { login, accountInfo } from 'auth/active-directory'
import routes from 'routes'
import ScrollToTop from 'components/ScrollToTop'
import Notifier from 'components/Notifier'
import PageThrobber from 'components/PageThrobber'
import NotFound from 'views/NotFound'
import { useMounted } from 'hooks/mounted'
import { GlobalContext } from 'hooks/navigate'
import { LogoutAlert } from 'components/LogoutAlert'


export default function AppLayout() {
  let [pending, setPending] = useState(0)
  let [notifications, setNotifications] = useState([])
  let [account, setAccount] = useState(null)

  let ctx = {
    pending,
    setPending,
    account,

    isLoading() {
      return pending > 0
    },

    addNotification(notification) {
      setNotifications(prev => [notification, ...prev])
    },
    removeNotification(notification) {
      setNotifications(prev => prev.filter(n => n.id !== notification.id))
    },
  }

  useMounted(async () => {
    await login()
    setAccount(accountInfo())
  })

  if (!account) {
    return (
      <PageThrobber>Conectando con Microsoft...</PageThrobber>
    )
  }
  return (
    <BrowserRouter>
      <ScrollToTop></ScrollToTop>
      <GlobalContext.Provider value={ctx}>
        <Switch>
          <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
          
          {routes
            .filter(route => !route.redirect)
            .map(route =>
              <Route key={route.path} exact strict path={route.path}>{route.component}</Route>,
            )}
          {routes
            .filter(route => route.redirect)
            .map(route =>
              <Redirect key={route.path} exact strict from={route.path} to={route.redirect} />,
            )}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Notifier notifications={notifications} />
        <LogoutAlert />
      </GlobalContext.Provider>
    </BrowserRouter>
  )
}
