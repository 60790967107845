
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import CardHeader from 'components/CardHeader'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import Icon from 'components/Icon'
import { useNavigate } from 'hooks/navigate'
import { getEntity, putEntity } from 'models/docs/entities'


export default function DocumentSubtypesAdd () {
  let history = useHistory()
  let { name } = useParams()
  let [subtype, setSubtype] = useState('')
  let [docType, setDocType] = useState({})

  useNavigate(async () => {
    let docType = await getEntity(`document-types/${name}`)
    docType.subtypes = docType.subtypes || []
    setDocType(docType)
  })

  async function save() {
    docType.subtypes.push(subtype)

    await putEntity('document-types', { documentType: docType })
    history.push(`/manage/document-types/${name}`)
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/document-types">Tipos de documento</Breadcrumb>
          <Breadcrumb url={`/manage/document-types/${name}`}>{docType.displayName}</Breadcrumb>
          <Breadcrumb url={`/manage/document-types/${name}/subtypes/add`}>Añadir subtipo</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Añadir subtipo"
        subheader="Registra un nuevo subtipo de documento."
        footer={
          <div className="flex justify-end w-full">
            <ButtonAsyncPrimary onClick={save} disabled={false}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Input
          label="Nombre"
          type="text"
          value={subtype}
          onChange={setSubtype}
        ></Input>
      </CardHeader>
    </PageLayout>
  )
}
