
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import ContratosClient from 'services/WebInterna/contratos/contratos'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { useNavigate } from 'hooks/navigate'
import { datetime, duracion, price } from 'platform/filters'
import { parseDate } from 'platform/datetime'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import HtmlViewer from 'components/HtmlViewer'
import EntityLink from 'components/EntityLink'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { PeopleCorpViewer, PeopleGrid } from 'components/people'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'


const contratosClient = new ContratosClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())


export default function ContratosShow() {
  let { name } = useParams()
  let [contrato, setContrato] = useState({})

  useNavigate(async () => {
    let contrato = await contratosClient.Get({ name })
    contrato.objetivoFinalizacionDate = parseDate(contrato.objetivoFinalizacionDate)

    if (contrato.proveedor) {
      contrato.proveedor = await sociedadesClient.Get({ name: contrato.proveedor })
    }
    if (contrato.inmueble) {
      contrato.inmueble = await inmueblesClient.Get({ name: contrato.inmueble })
    }
    if (contrato.proyecto) {
      contrato.proyecto = await proyectosClient.Get({ name: contrato.proyecto })
    }
    if (contrato.cliente) {
      let clientes = await fetchPersonasSociedades([contrato.cliente])
      contrato.cliente = clientes.resolve(contrato.cliente)
    }

    setContrato(contrato)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!contrato.blocked &&
            <Acl perm="webinterna.validaciones.edit">
              <ButtonLinkPrimary to={`/webinterna/contratos/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Contrato
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/contratos">Planes Cuentas</Breadcrumb>
        <Breadcrumb url={`/webinterna/contratos/${name}`}>Consultar Contrato</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={contrato.blocked}></EntityBlockedAlert>

      <Card>
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ShowPane title="Código" className="sm:col-span-2">{contrato.codigo}</ShowPane>
          
          <ShowPane title="Cliente" className="sm:col-span-2">
            <PeopleGrid>
              <PeopleCorpViewer value={contrato.cliente}></PeopleCorpViewer>
            </PeopleGrid>
          </ShowPane>

          <ShowPane title="Proveedor">
            {contrato.proveedor &&
              <EntityLink to={`/webinterna/sociedades/${contrato.proveedor.name}`}>{contrato.proveedor.denominacion}</EntityLink>
            }
          </ShowPane>

          <ShowPane title="Proyecto">
            {contrato.proyecto &&
              <EntityLink to={`/webinterna/proyectos/${contrato.proyecto.name}`}>{contrato.proyecto.denominacion}</EntityLink>
            }
          </ShowPane>

          <ShowPane title="Unidad Inmobiliaria">
            {contrato.inmueble &&
              <EntityLink to={`/webinterna/inmuebles/${contrato.inmueble.name}`}>{contrato.inmueble.denominacion}</EntityLink>
            }
          </ShowPane>

          <ShowPane title="Descripción" className="sm:col-span-2">
            <HtmlViewer html={contrato.descripcion} />
          </ShowPane>

          <ShowPane title="Fecha Objetivo Finalización">
            {datetime(contrato.objetivoFinalizacionDate, 'DATE')}
          </ShowPane>

          <ShowPane title="Duración">
            {duracion(contrato.duracion)}
          </ShowPane>

          <ShowPane title="Programación">
            {contrato.programacion === 'PROGRAMACION_PUNTUAL' && 'Puntual'}
            {contrato.programacion === 'PROGRAMACION_PRORROGABLE' && 'Prorrogable'}
            {contrato.programacion === 'PROGRAMACION_TERMINO_CIERTO' && 'Término Cierto'}
          </ShowPane>
        </div>
      </Card>

      <CardHeader header="Facturación" className="mt-8">
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ShowPane title="Precio">
            {price(contrato.precio)}
          </ShowPane>

          <ShowPane title="Tipo Importe">
            {contrato.tipoImporte === 'TIPO_IMPORTE_FIJO' && 'Fijo'}
            {contrato.tipoImporte === 'TIPO_IMPORTE_FIJO_VARIABLE' && 'Fijo + Variable'}
            {contrato.tipoImporte === 'TIPO_IMPORTE_VARIABLE' && 'Variable'}
            {contrato.tipoImporte === 'TIPO_IMPORTE_FIJO_REVISABLE' && 'Fijo Revisable'}
          </ShowPane>

          <ShowPane title="Tipo Pago">
            {contrato.tipoPago === 'TIPO_PAGO_ANTICIPADO' && 'Anticipado'}
            {contrato.tipoPago === 'TIPO_PAGO_A_TERMINO' && 'Pago A Término'}
            {contrato.tipoPago === 'TIPO_PAGO_CUOTA_REGULAR' && 'Cuota Regular'}
            {contrato.tipoPago === 'TIPO_PAGO_CALENDARIO_ESPECIFICO' && 'Calendario Específico'}
            {contrato.tipoPago === 'TIPO_PAGO_CERTIFICACION' && 'Certificación'}
          </ShowPane>

          {contrato.tipoPago === 'TIPO_PAGO_CUOTA_REGULAR' &&
            <ShowPane title="Periodo Pago">
              {contrato.periodoPago === 'PERIODO_PAGO_MENSUAL' && 'Mensual'}
              {contrato.periodoPago === 'PERIODO_PAGO_TRIMESTRAL' && 'Trimestral'}
              {contrato.periodoPago === 'PERIODO_PAGO_SEMESTRAL' && 'Semestral'}
              {contrato.periodoPago === 'PERIODO_PAGO_ANUAL' && 'Anual'}
            </ShowPane>
          }
        </div>
      </CardHeader>
    </PageLayout>
  )
}
