
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'
import cx from 'classnames'

import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import Icon from 'components/Icon'
import FullscreenLayout from 'layout/docs/FullscreenLayout'
import { getEntity, batchGetEntities, runQuery, putEntity } from 'models/docs/entities'
import * as query from 'models/docs/query'
import UsersClient from 'services/Users/users/users'
import { users, docs } from 'services/discovery'
import { datetime } from 'platform/filters'
import { parseTimestamp, serializeTimestamp } from 'platform/datetime'
import Throbber from 'components/Throbber'
import Card from 'components/Card'
import CardTable from 'components/CardTable'
import { HeaderCell, TableRow, Cell } from 'components/Table'
import ShowPane from 'components/ShowPane'
import { accountInfo } from 'auth/active-directory'
import { getRole } from 'models/docs/inboxes-groups'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import ButtonExternalLinkSecondary from 'components/buttons/ButtonExternalLinkSecondary'
import ButtonDanger from 'components/buttons/ButtonDanger'
import Modal from 'components/Modal'
import Textarea from 'components/simple-forms/Textarea'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import FilesClient from 'services/Docs/files/files'
import { PDFViewer } from 'react-view-pdf'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import ButtonFile from 'components/buttons/ButtonFile'
import AlertDanger from 'components/alerts/AlertDanger'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { batchGetPersonas } from 'models/webinterna/personas'
import Face from 'components/people/Face'
import { waitDocumentChanged } from 'models/docs/documents'
import { useNavigate } from 'hooks/navigate'
import { CustomFieldsViewer } from 'components/CustomFieldsViewer'


const client = new UsersClient(users())
const filesClient = new FilesClient(docs())


function ControlAction({ doc, onAction }) {
  let [open, setOpen] = useState(false)
  let [comment, setComment] = useState('')

  async function approve() {
    await sendReview('VERIFICATION_APPROVED')
    await onAction()
  }

  async function deny() {
    await sendReview('VERIFICATION_DENIED', comment)
    await onAction()
  }

  async function sendReview(verification, comment) {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      controlReview: {
        verification,
        comment,
      },
    }
    await putEntity('revisions', { revision })
  }

  return (
    <>
      <ButtonAsyncPrimary onClick={approve}>
        <Icon solid name="check" className="mr-2" />
        Aprobar
      </ButtonAsyncPrimary>

      <ButtonDanger onClick={() => setOpen(true)}>
        <Icon solid name="ban" className="mr-2" />
        Pedir cambios
      </ButtonDanger>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Rechazar documento"
        footer={
          <div className="w-full flex justify-between">
            <ButtonSecondary onClick={() => setOpen(false)}>
              Cancelar
            </ButtonSecondary>

            <ButtonAsyncDanger onClick={deny}>
              Pedir cambios
            </ButtonAsyncDanger>
          </div>
        }
      >
        <Textarea
          label="Comentario"
          name="comment"
          help="Descripción sobre los cambios requeridos en el documento"
          rows="5"
          value={comment}
          onChange={setComment}
        />
      </Modal>
    </>
  )
}

function ManagementAction({ doc, onAction }) {
  let [open, setOpen] = useState(false)
  let [comment, setComment] = useState('')

  async function approve() {
    await sendReview('VERIFICATION_APPROVED')
    await onAction()
  }

  async function deny() {
    await sendReview('VERIFICATION_DENIED', comment)
    await onAction()
  }

  async function sendReview(verification, comment) {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      managementReview: {
        verification,
        comment,
      },
    }
    await putEntity('revisions', { revision })
  }

  return (
    <>
      <ButtonAsyncPrimary onClick={approve}>
        <Icon solid name="check" className="mr-2" />
        Aprobar
      </ButtonAsyncPrimary>

      <ButtonDanger onClick={() => setOpen(true)}>
        <Icon solid name="ban" className="mr-2" />
        Pedir cambios
      </ButtonDanger>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Rechazar documento"
        footer={
          <div className="w-full flex justify-between">
            <ButtonSecondary onClick={() => setOpen(false)}>
              Cancelar
            </ButtonSecondary>

            <ButtonAsyncDanger onClick={deny}>
              Pedir cambios
            </ButtonAsyncDanger>
          </div>
        }
      >
        <Textarea
          label="Comentario"
          name="comment"
          help="Descripción sobre los cambios requeridos en el documento"
          rows="5"
          value={comment}
          onChange={setComment}
        />
      </Modal>
    </>
  )
}

function ValidationAction({ doc, onAction }) {
  let [open, setOpen] = useState(false)
  let [comment, setComment] = useState('')

  async function approve() {
    await sendReview('VERIFICATION_APPROVED')
    await onAction()
  }

  async function deny() {
    await sendReview('VERIFICATION_DENIED', comment)
    await onAction()
  }

  async function sendReview(verification, comment) {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      validationReview: {
        verification,
        comment,
      },
    }
    await putEntity('revisions', { revision })
  }
  
  return (
    <>
      <ButtonAsyncPrimary onClick={approve}>
        <Icon solid name="check" className="mr-2" />
        Aprobar
      </ButtonAsyncPrimary>

      <ButtonDanger onClick={() => setOpen(true)}>
        <Icon solid name="ban" className="mr-2" />
        Pedir cambios
      </ButtonDanger>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Rechazar documento"
        footer={
          <div className="w-full flex justify-between">
            <ButtonSecondary onClick={() => setOpen(false)}>
              Cancelar
            </ButtonSecondary>

            <ButtonAsyncDanger onClick={deny}>
              Pedir cambios
            </ButtonAsyncDanger>
          </div>
        }
      >
        <Textarea
          label="Comentario"
          name="comment"
          help="Descripción sobre los cambios requeridos en el documento"
          rows="5"
          value={comment}
          onChange={setComment}
        />
      </Modal>
    </>
  )
}

function SignatureAction({ doc, onAction, tab }) {
  let [open, setOpen] = useState(false)
  let [comment, setComment] = useState('')
  let [errorMessage, setErrorMessage] = useState('')
  let [loading, setLoading] = useState(false)

  async function deny() {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      rejectedSignature: {
        comment,
      },
    }
    await putEntity('revisions', { revision })
    await onAction()
  }

  async function uploadFile(file) {
    if (file.type !== 'application/pdf') {
      setErrorMessage('Seleccione por favor un fichero en formato PDF')
      return
    }
    setLoading(true)
    let upload = await filesClient.Create()

    try {
      let reply = await fetch(upload.uploadUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        body: file,
      })
      if (reply.status !== 201) {
        setErrorMessage(`Bad HTTP status code: ${reply.status}`)
        setLoading(false)
        return
      }

      let finishReply = await filesClient.FinishUpload({
        name: upload.name,
        filename: file.name,
        size: file.size,
      })

      let revision = {
        parent: doc.name,
        user: accountInfo().name,
        createTime: serializeTimestamp(new Date()),
        internalSignature: {
          file: finishReply.name,
        },
      }
      await putEntity('revisions', { revision })
      await onAction()
    } catch (err) {
      setErrorMessage(`${err}`)
      setLoading(false)
      return
    }
  }

  return (
    <>
      {(tab === 'DOCUMENT' || tab === 'VOLANTE') &&
        <ButtonExternalLinkSecondary to={tab === 'DOCUMENT' ? doc.fileUrl : doc.metadataUrl}>
          <Icon solid name="download" className="mr-2" />
          Descargar
        </ButtonExternalLinkSecondary>
      }
      {
        loading ?
          <ButtonPrimary disabled={true}>
            <Icon duotone name="spinner-third" spin className="mr-2" />
            Subiendo documento
          </ButtonPrimary>
          :
          <ButtonFile
            accept="application/pdf"
            onSelect={uploadFile}
          >
            <Icon solid name="file-upload" className="mr-2" />
            Subir documento
          </ButtonFile>
      }

      <ButtonDanger onClick={() => setOpen(true)}>
        <Icon solid name="ban" className="mr-2" />
        Rechazar firma
      </ButtonDanger>

      <Modal
        open={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
        title="Error"
        footer={
          <div className="w-full flex justify-end">
            <ButtonDanger onClick={() => setErrorMessage('')}>
              Cerrar
            </ButtonDanger>
          </div>
        }
      >
        <AlertDanger>{errorMessage}</AlertDanger>
      </Modal>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Rechazar firma"
        footer={
          <div className="w-full flex justify-between">
            <ButtonSecondary onClick={() => setOpen(false)}>
              Cancelar
            </ButtonSecondary>

            <ButtonAsyncDanger onClick={() => deny()}>
              Rechazar firma
            </ButtonAsyncDanger>
          </div>
        }
      >
        <Textarea
          label="Comentario"
          name="comment"
          help="Descripción sobre los motivos para rechazar la firma del documento"
          rows="5"
          value={comment}
          onChange={setComment}
        />
      </Modal>
    </>
  )
}

function ClasificationAction({ doc, parent }) {
  let queryString = new URL(window.location.href)

  return (
    <ButtonLinkPrimary
      to={`/docs/${parent}/classification/${doc.name}${queryString.search}`}
    >
      <Icon solid name="folders" className="mr-2" />
      Clasificar documento
    </ButtonLinkPrimary>
  )
}

function ValidationInfo({ doc, docType }) {
  let fieldsInfo = keyBy(docType.fields, 'name')
  let fields = doc.fields?.map(field => {
    field.values = field.values || []
    field.info = fieldsInfo[field.name]
    field.info.options = keyBy(field.info.select?.options, 'name')
    return field
  })

  return (
    <div className="pt-10 pb-5 px-16">
      <Card
        title="Información general"
        subtitle="Información para este tipo de documento."
      >
        <div className="space-y-2">
          <ShowPane title="Título">{doc.title}</ShowPane>
          <ShowPane title="Subtipo">{doc.owners[0].subtype}</ShowPane>
        </div>
      </Card>

      {fields &&
        <Card
          className="mt-8"
          title="Campos personalizados"
          subtitle="Datos acordes a lo que requiere el tipo de documento."
        >
          <CustomFieldsViewer fields={fields} />
        </Card>
      }
    </div>
  )
}

function SignatureInfo({ doc }) {
  return (
    <div className="pt-10 pb-5 px-16">
      <CardTable
        title="Firmantes"
        subtitle="Información de los firmantes del documento."
        header={
          <>
            <HeaderCell>Firmante</HeaderCell>
            <HeaderCell>Propietario</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
          </>
        }
      >
        {doc.owners.map(owner => (
          <TableRow key={owner.name}>
            <Cell className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <Face displayName={owner.signer.nombre} pictureUrl={owner.signer.fotoUrl}></Face>
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">{owner.signer.nombre}</p>
                <p className="text-sm font-normal text-gray-500 truncate">{owner.signer.identidad}</p>
              </div>
            </Cell>
            <Cell>{owner.owner.nombre}</Cell>
            <Cell>{owner.documentType.displayName}</Cell>
          </TableRow>
        ))}
      </CardTable>
    </div>
  )
}


const TABS = [
  {
    value: 'DOCUMENT',
    label: 'Documento',
    visible: () => true,
  },
  {
    value: 'VOLANTE',
    label: 'Volante',
    visible: (inbox, doc) => inbox.role.states.includes('STATE_PHYSICAL_SIGNATURES') && doc.metadataUrl,
  },
  {
    value: 'SIGNERS',
    label: 'Firmantes',
    visible: inbox => inbox.role.states.includes('STATE_PHYSICAL_SIGNATURES'),
  },
  {
    value: 'FIELDS',
    label: 'Campos',
    visible: inbox => inbox.role.states.includes('STATE_VALIDATION'),
  },
]


export default function Inbox() {
  let history = useHistory()
  let { code } = useParams()
  let [loading, setLoading] = useState(true)
  let [inbox, setInbox] = useState({})
  let [docs, setDocs] = useState([])
  let [selected, setSelected] = useState(null)
  let [selectedIndex, setSelectedIndex] = useState(-1)
  let [tab, setTab] = useState('DOCUMENT')

  let hasTabs = selected && TABS
    .filter(tab => tab.visible(inbox, selected))
    .length > 1

  useNavigate(async () => {
    let group = await getEntity(`inboxes-groups/${code}`)

    let u = new URL(window.location.href)

    let role = u.searchParams.get('role')
    let owner = u.searchParams.get('owner')
    let docType = u.searchParams.get('docType')
    if (!role || !owner || !docType) {
      history.push('/docs')
    }
    if (!group.users.includes(accountInfo().name) || !group.owners.includes(owner) || !group.documentTypes.includes(docType)) {
      history.push('/docs')
    }

    let entities = await batchGetEntities([owner, docType])
    entities = keyBy(entities, 'name')
    role = getRole(role)

    let reply = await fetchPersonasSociedades([entities[owner].owner])

    let inbox = {
      group,
      displayName: `${role.label}: ${reply.resolve(entities[owner].owner).nombre}`,
      role,
      owner: {
        ...reply.resolve(entities[owner].owner),
        name: entities[owner].name,
      },
      documentType: entities[docType],
    }
    setInbox(inbox)

    await loadDocuments(inbox)
    setLoading(false)
  })

  async function loadDocuments(inbox) {
    if (selected) {
      await waitDocumentChanged(selected)
    }

    setSelected(null)
    setDocs([])

    let q = query.build(
      query.andIn('payload.document.state', inbox.role.states),
      query.nested(
        'payload.document.owners',
        query.and('payload.document.owners.name', inbox.owner.name),
        query.and('payload.document.owners.documentType', inbox.documentType.name),
      ),
    )
    let docs = await runQuery('documents', q, query.sort('lastUpdateTime', 'asc'))

    let names = docs.map(doc => doc.uploader)
    let { users } = await client.BatchGet({ names: uniq(names) })
    users = keyBy(users, 'name')

    if (inbox.role.states.includes('STATE_PHYSICAL_SIGNATURES')) {
      let names = []
      docs.forEach(doc => {
        doc.owners.forEach(owner => {
          names.push(owner.name)
          names.push(owner.documentType)
        })
      })

      let entities = {}
      names = uniq(names)
      if (names.length) {
        let entitiesList = await batchGetEntities(names)
        entities = keyBy(entitiesList, 'name')
      }

      let ownerNames = []
      let signerNames = []
      docs.forEach(doc => {
        doc.owners.forEach(owner => {
          ownerNames.push(entities[owner.name].owner)
          signerNames.push(owner.signer)
        })
      })
      let owners = await fetchPersonasSociedades(ownerNames)
      let signers = await batchGetPersonas(signerNames)

      docs = docs.map(doc => {
        doc.owners = doc.owners.map(owner => {
          owner.owner = owners.resolve(entities[owner.name].owner)
          owner.documentType = entities[owner.documentType]
          owner.signer = signers[owner.signer]
          return owner
        })
        return doc
      })
    }

    if (docs.length) {
      let reply = await filesClient.Get({ name: docs[0].file })
      docs[0].fileUrl = reply.previewUrl
      if (docs[0].metadataFile) {
        let metadataReply = await filesClient.Get({  name: docs[0].metadataFile })
        docs[0].metadataUrl = metadataReply.previewUrl
      }
      
      setSelected(docs[0])
      setSelectedIndex(0)
    }

    setDocs(docs.map(doc => {
      doc.uploader = users[doc.uploader]
      doc.createTime = parseTimestamp(doc.createTime)
      return doc
    }))
  }

  async function selectDoc(idx) {
    if (idx < 0 || idx > docs.length - 1) {
      return
    }
    let docReply = docs[idx]
    if (!docReply.fileUrl) {
      let reply = await filesClient.Get({ name: docReply.file })
      docReply.fileUrl = reply.previewUrl
      if (docReply.metadataFile) {
        let metadataReply = await filesClient.Get({  name: docReply.metadataFile })
        docReply.metadataFile = metadataReply.previewUrl
      }
      setDocs(docs.map((doc, index) => {
        return index === idx ? docReply : doc
      }))
    }
    setSelected(docReply)
    setSelectedIndex(idx)
  }

  return (
    <FullscreenLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url={`/docs/${inbox.group?.name}`}>{inbox.displayName}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{inbox.displayName}</h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-1 flex items-center text-sm text-gray-500">
              <Icon regular name="user-crown" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {inbox.owner?.nombre}
            </div>
            <div className="mt-1 flex items-center text-sm text-gray-500">
              <Icon regular name="file-contract" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {inbox.documentType?.displayName}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 border-t border-gray-200 xl:flex mt-2 shadow sm:rounded-md">
        <section className="flex-1 flex flex-col xl:order-last">
          <div className="flex-shrink-0 bg-white border-b border-gray-200">
            <div className="h-16 flex flex-col justify-center">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="py-3 flex justify-between items-center">
                  <div>
                    {selected &&
                      <div className="space-x-4">
                        {inbox.role.states.includes('STATE_CONTROL_PENDING') && <ControlAction doc={selected} onAction={() => loadDocuments(inbox)}></ControlAction>}
                        {inbox.role.states.includes('STATE_MANAGEMENT_PENDING') && <ManagementAction doc={selected} onAction={() => loadDocuments(inbox)}></ManagementAction>}
                        {inbox.role.states.includes('STATE_PHYSICAL_SIGNATURES') && <SignatureAction doc={selected} onAction={() => loadDocuments(inbox)} tab={tab}></SignatureAction>}
                        {inbox.role.states.includes('STATE_CLASSIFICATION') && <ClasificationAction doc={selected} parent={inbox.group.name}></ClasificationAction>}
                        {inbox.role.states.includes('STATE_VALIDATION') && <ValidationAction doc={selected} onAction={() => loadDocuments(inbox)} ></ValidationAction>}
                      </div>
                    }
                  </div>
                  <nav>
                    <span className="relative z-0 inline-flex shadow-sm rounded-md">
                      <button
                        onClick={() => selectDoc(selectedIndex - 1)}
                        className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-teal-600 focus:border-teal-600"
                      >
                        <Icon solid name="chevron-up" />
                      </button>
                      <button
                        onClick={() => selectDoc(selectedIndex + 1)}
                        className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-teal-600 focus:border-teal-600"
                      >
                        <Icon solid name="chevron-down" />
                      </button>
                    </span>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          {!selected &&
            <div className="px-6 text-center text-lg italic text-gray-400 py-10">
              Seleccione un documento<br />para abrir el detalle.
            </div>
          }
          {selected &&
            <div className="flex flex-col flex-1 bg-gray-100 pdf-container">
              <div className="bg-white pt-2 z-10 shadow">
                <div
                  className={cx(
                    'bg-white',
                    {
                      'pb-6': !hasTabs,
                      'pb-4': hasTabs,
                    },
                  )}
                >
                  <div className="px-4 sm:flex sm:justify-between sm:items-baseline sm:px-6 lg:px-8">
                    <div className="sm:w-0 sm:flex-1">
                      <h1 id="message-heading" className="text-lg font-medium text-gray-900">
                        {selected.title || selected.suggestedTitle}
                      </h1>
                      <p className="mt-1 text-sm text-gray-500 truncate">{selected?.uploader.displayName}</p>
                    </div>
                  </div>
                </div>
                {hasTabs &&
                  <div className="bg-white z-20 px-6">
                    <nav className="-mb-px flex space-x-4">
                      {TABS
                        .filter(tab => tab.visible(inbox, selected))
                        .map(t => (
                          <button
                            key={t.value}
                            onClick={() => setTab(t.value) }
                            className={cx(
                              'whitespace-nowrap pt-1 pb-2 px-1 border-b-2 font-medium text-sm focus:outline-none',
                              {
                                'border-teal-500 text-teal-600': t.value === tab,
                                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': t.value !== tab,
                              },
                            )}
                          >
                            {t.label}
                          </button>
                        ))}
                    </nav>
                  </div>
                }
              </div>

              {tab === 'DOCUMENT' && <PDFViewer url={selected.fileUrl} />}
              {tab === 'FIELDS' && <ValidationInfo doc={selected} docType={inbox.documentType} />}
              {tab === 'SIGNERS' && <SignatureInfo doc={selected} />}
              {tab === 'VOLANTE' && <PDFViewer url={selected.metadataUrl} />}
            </div>
          }
        </section>
        <aside className="hidden xl:block xl:flex-shrink-0 xl:order-first bg-white">
          <div className="h-full relative flex flex-col w-96 border-r border-gray-200">
            <div className="flex-shrink-0">
              <div className="h-16 bg-white px-6 flex flex-col justify-center">
                <div className="flex items-baseline space-x-3">
                  <h2 className="text-lg font-medium text-gray-900">Documentos</h2>
                  {!loading &&
                    <p className="text-sm font-medium text-gray-500">{docs.length === 1 ? '1 documento' : `${docs.length} documentos`}</p>
                  }
                </div>
              </div>
            </div>
            <nav className="min-h-0 flex-1 overflow-y-auto border-t border-gray-200">
              <ul className="border-b border-gray-200 divide-y divide-gray-200">
                {loading &&
                  <li className="px-6 text-center text-lg italic text-gray-400 py-10">
                    <Throbber></Throbber>
                  </li>
                }
                {docs.map((doc, idx) => (
                  <li
                    key={doc.name}
                    className={cx(
                      'relative py-5 px-6 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600 cursor-pointer',
                      {
                        'ring-2 ring-inset ring-teal-600 bg-teal-50': idx === selectedIndex,
                        'bg-white': idx !== selectedIndex,
                      },
                    )}
                    onClick={() => selectDoc(idx)}
                  >
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <div className="block focus:outline-none">
                          <p className="text-sm font-medium text-gray-900 truncate">{doc.title || doc.suggestedTitle}</p>
                        </div>
                      </div>
                      <span className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                        {datetime(doc.createTime, 'DATE')}
                      </span>
                    </div>
                    <div className="mt-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{doc.uploader.displayName}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    </FullscreenLayout>
  )
}
