
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { Cell, HeaderCell, TableRow } from 'components/Table'
import CardTable from 'components/CardTable'
import Icon from 'components/Icon'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { useNavigate } from 'hooks/navigate'
import { webinterna } from 'services/discovery'
import RegistrosMercantilesClient from 'services/WebInterna/registros_mercantiles/registros_mercantiles'


const client = new RegistrosMercantilesClient(webinterna())


export default function RegistrosMercantilesList() {
  let [registros, setRegistros] = useState([])

  useNavigate(async () => {
    let reply = await client.List()
    setRegistros(reply.registros || [])
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/registros-mercantiles">Registros mercantiles</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Registros Mercantiles"
        subtitle="Administración de los registros mercantiles."
        empty="No hay registros mercantiles configurados."
        actions={
          <ButtonLinkPrimary to="/manage/registros-mercantiles/create">
            <Icon solid name="plus" className="mr-2" />
            Crear Registro
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {registros.map(registro => (
          <TableRow key={registro.name}>
            <Cell>
              <Link to={`/manage/registros-mercantiles/${registro.name}`} className="text-gray-700">{registro.nombre}</Link>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/registros-mercantiles/${registro.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
