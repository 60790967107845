
import React from 'react'
import cx from 'classnames'


export default function ButtonDanger({ onClick, className, children, type = 'button' }) {
  return (
    <button
      type={type}
      className={cx(
        'inline-flex items-center w-full md:w-auto px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
