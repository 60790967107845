import React from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { listEntities } from 'models/docs/entities'


export default function RecordFamiliesList() {
  let { table, setRows } = usePaginatedTable()

  useNavigate(async () => {
    let rows = await listEntities('record-families')
    setRows(rows.map(row => {
      row.$search = [
        row.displayName,
      ]
      return row
    }))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/record-families">Familias de expedientes</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardPaginatedTable
        title="Familias de expedientes"
        subtitle="Clasificación de los expedientes en la plataforma."
        empty="No hay familias de expedientes que mostrar"
        table={table}
        actions={
          <ButtonLinkPrimary to="/manage/record-families/create">
            <Icon solid name="plus" className="mr-2" />
            Crear familia de expedientes
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        row={row => (
          <>
            <Cell>
              <Link to={`/manage/record-families/${row.name.split('/')[1]}`} className="text-gray-700">{row.displayName}</Link>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/record-families/${row.name.split('/')[1]}`}>Editar</Link>
            </Cell>
          </>
        )}
      >
      </CardPaginatedTable>
    </PageLayout>
  )
}
