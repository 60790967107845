
import { library } from '@fortawesome/fontawesome-svg-core'


library.add(
  require('@fortawesome/pro-solid-svg-icons/faArchive').definition,
  require('@fortawesome/pro-solid-svg-icons/faArrowLeft').definition,
  require('@fortawesome/pro-solid-svg-icons/faAtlas').definition,
  require('@fortawesome/pro-solid-svg-icons/faBan').definition,
  require('@fortawesome/pro-solid-svg-icons/faBars').definition,
  require('@fortawesome/pro-solid-svg-icons/faCamera').definition,
  require('@fortawesome/pro-solid-svg-icons/faCheck').definition,
  require('@fortawesome/pro-solid-svg-icons/faCheckCircle').definition,
  require('@fortawesome/pro-solid-svg-icons/faCheckSquare').definition,
  require('@fortawesome/pro-solid-svg-icons/faChevronDown').definition,
  require('@fortawesome/pro-solid-svg-icons/faChevronUp').definition,
  require('@fortawesome/pro-solid-svg-icons/faChevronLeft').definition,
  require('@fortawesome/pro-solid-svg-icons/faChevronRight').definition,
  require('@fortawesome/pro-solid-svg-icons/faCogs').definition,
  require('@fortawesome/pro-solid-svg-icons/faCommentAltDots').definition,
  require('@fortawesome/pro-solid-svg-icons/faEnvelope').definition,
  require('@fortawesome/pro-solid-svg-icons/faExclamationCircle').definition,
  require('@fortawesome/pro-solid-svg-icons/faExclamationTriangle').definition,
  require('@fortawesome/pro-solid-svg-icons/faEye').definition,
  require('@fortawesome/pro-solid-svg-icons/faFileUpload').definition,
  require('@fortawesome/pro-solid-svg-icons/faFolders').definition,
  require('@fortawesome/pro-solid-svg-icons/faHandshakeAlt').definition,
  require('@fortawesome/pro-solid-svg-icons/faHome').definition,
  require('@fortawesome/pro-solid-svg-icons/faIdCard').definition,
  require('@fortawesome/pro-solid-svg-icons/faInfoCircle').definition,
  require('@fortawesome/pro-solid-svg-icons/faLink').definition,
  require('@fortawesome/pro-solid-svg-icons/faLongArrowLeft').definition,
  require('@fortawesome/pro-solid-svg-icons/faLongArrowRight').definition,
  require('@fortawesome/pro-solid-svg-icons/faPencil').definition,
  require('@fortawesome/pro-solid-svg-icons/faPercentage').definition,
  require('@fortawesome/pro-solid-svg-icons/faPhone').definition,
  require('@fortawesome/pro-solid-svg-icons/faPhoneOffice').definition,
  require('@fortawesome/pro-solid-svg-icons/faPhoneRotary').definition,
  require('@fortawesome/pro-solid-svg-icons/faPlus').definition,
  require('@fortawesome/pro-solid-svg-icons/faSave').definition,
  require('@fortawesome/pro-solid-svg-icons/faSearch').definition,
  require('@fortawesome/pro-solid-svg-icons/faSignature').definition,
  require('@fortawesome/pro-solid-svg-icons/faSpinner').definition,
  require('@fortawesome/pro-solid-svg-icons/faStar').definition,
  require('@fortawesome/pro-solid-svg-icons/faTimes').definition,
  require('@fortawesome/pro-solid-svg-icons/faTimesCircle').definition,
  require('@fortawesome/pro-solid-svg-icons/faTimesSquare').definition,
  require('@fortawesome/pro-solid-svg-icons/faTrash').definition,
  require('@fortawesome/pro-solid-svg-icons/faUnlink').definition,
  require('@fortawesome/pro-solid-svg-icons/faUpload').definition,
  require('@fortawesome/pro-solid-svg-icons/faUser').definition,
  require('@fortawesome/pro-solid-svg-icons/faUserHardHat').definition,
  require('@fortawesome/pro-solid-svg-icons/faUserTie').definition,
  require('@fortawesome/pro-solid-svg-icons/faDownload').definition,
  require('@fortawesome/pro-solid-svg-icons/faShare').definition,
  require('@fortawesome/pro-solid-svg-icons/faExchangeAlt').definition,
  require('@fortawesome/pro-solid-svg-icons/faBriefcase').definition,
  require('@fortawesome/pro-solid-svg-icons/faBooks').definition,

  require('@fortawesome/pro-regular-svg-icons/faBan').definition,
  require('@fortawesome/pro-regular-svg-icons/faBars').definition,
  require('@fortawesome/pro-regular-svg-icons/faBell').definition,
  require('@fortawesome/pro-regular-svg-icons/faBuilding').definition,
  require('@fortawesome/pro-regular-svg-icons/faBusinessTime').definition,
  require('@fortawesome/pro-regular-svg-icons/faCalendarAlt').definition,
  require('@fortawesome/pro-regular-svg-icons/faCheck').definition,
  require('@fortawesome/pro-regular-svg-icons/faCheckCircle').definition,
  require('@fortawesome/pro-regular-svg-icons/faChevronDown').definition,
  require('@fortawesome/pro-regular-svg-icons/faChevronLeft').definition,
  require('@fortawesome/pro-regular-svg-icons/faChevronRight').definition,
  require('@fortawesome/pro-regular-svg-icons/faCloudUpload').definition,
  require('@fortawesome/pro-regular-svg-icons/faCode').definition,
  require('@fortawesome/pro-regular-svg-icons/faCog').definition,
  require('@fortawesome/pro-regular-svg-icons/faConstruction').definition,
  require('@fortawesome/pro-regular-svg-icons/faEuroSign').definition,
  require('@fortawesome/pro-regular-svg-icons/faExternalLink').definition,
  require('@fortawesome/pro-regular-svg-icons/faEyeSlash').definition,
  require('@fortawesome/pro-regular-svg-icons/faFileContract').definition,
  require('@fortawesome/pro-regular-svg-icons/faFilePdf').definition,
  require('@fortawesome/pro-regular-svg-icons/faFileSearch').definition,
  require('@fortawesome/pro-regular-svg-icons/faFileSignature').definition,
  require('@fortawesome/pro-regular-svg-icons/faFileWord').definition,
  require('@fortawesome/pro-regular-svg-icons/faFilter').definition,
  require('@fortawesome/pro-regular-svg-icons/faGavel').definition,
  require('@fortawesome/pro-regular-svg-icons/faHandshakeAlt').definition,
  require('@fortawesome/pro-regular-svg-icons/faHome').definition,
  require('@fortawesome/pro-regular-svg-icons/faHourglassEnd').definition,
  require('@fortawesome/pro-regular-svg-icons/faHourglassStart').definition,
  require('@fortawesome/pro-regular-svg-icons/faInboxIn').definition,
  require('@fortawesome/pro-regular-svg-icons/faPrint').definition,
  require('@fortawesome/pro-regular-svg-icons/faProjectDiagram').definition,
  require('@fortawesome/pro-regular-svg-icons/faQuestionSquare').definition,
  require('@fortawesome/pro-regular-svg-icons/faSave').definition,
  require('@fortawesome/pro-regular-svg-icons/faSortAlphaDown').definition,
  require('@fortawesome/pro-regular-svg-icons/faSortNumericDownAlt').definition,
  require('@fortawesome/pro-regular-svg-icons/faTasks').definition,
  require('@fortawesome/pro-regular-svg-icons/faTimes').definition,
  require('@fortawesome/pro-regular-svg-icons/faTrash').definition,
  require('@fortawesome/pro-regular-svg-icons/faUserCrown').definition,
  require('@fortawesome/pro-regular-svg-icons/faUserFriends').definition,
  require('@fortawesome/pro-regular-svg-icons/faUserTie').definition,
  require('@fortawesome/pro-regular-svg-icons/faLandmark').definition,
  require('@fortawesome/pro-regular-svg-icons/faFileContract').definition,
  require('@fortawesome/pro-regular-svg-icons/faChartArea').definition,
  require('@fortawesome/pro-regular-svg-icons/faBalanceScale').definition,
  require('@fortawesome/pro-regular-svg-icons/faProjectDiagram').definition,
  require('@fortawesome/pro-regular-svg-icons/faUserUnlock').definition,

  require('@fortawesome/pro-light-svg-icons/faBan').definition,
  require('@fortawesome/pro-light-svg-icons/faChevronLeft').definition,
  require('@fortawesome/pro-light-svg-icons/faEnvelope').definition,
  require('@fortawesome/pro-light-svg-icons/faHome').definition,
  require('@fortawesome/pro-light-svg-icons/faPhone').definition,
  require('@fortawesome/pro-light-svg-icons/faTimes').definition,

  require('@fortawesome/pro-duotone-svg-icons/faSpinnerThird').definition,
)
