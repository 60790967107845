
import React from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { webinterna } from 'services/discovery'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonDanger from 'components/buttons/ButtonDanger'
import Icon from 'components/Icon'
import Acl from 'components/Acl'


const validacionesClient = new ValidacionesClient(webinterna())


function Content({ form, children, validation }) {
  let history = useHistory()

  async function discardValidation() {
    await validacionesClient.Delete({ name: validation.name })
    history.push(`/webinterna/validaciones`)
  }

  return (
    <div
      className={cx(
        'sticky bottom-0 right-0 left-0 bg-gray-100 py-5 px-6 -mx-6 mt-14 border-t md:pr-12',
        {
          'text-right': !validation,
          'flex justify-between': validation,
        },
      )}
    >
      {validation &&
        <ButtonHoverDanger onClick={() => discardValidation()}>
          <Icon solid name="times" className="mr-2" />
          Descartar validación
        </ButtonHoverDanger>
      }

      {form.formState.isSubmitting ?
        <button type="button" disabled className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-300 cursor-default">
          <Icon solid spin name="spinner" className="mr-2" />
          Enviando datos...
        </button>
        :
        <>
          {validation &&
            <>
              {validation.accion === 'ACCION_ELIMINAR' ?
                <ButtonDanger type="submit">
                  <Icon solid name="trash" className="mr-2" />
                  Eliminar definitivamente
                </ButtonDanger>
                :
                <ButtonPrimary type="submit">
                  <Icon solid name="check" className="mr-2" />
                  Aceptar cambio
                </ButtonPrimary>
              }
            </>
          }
          {!validation &&
            <ButtonPrimary type="submit">
              <Icon solid name="save" className="mr-2" />
              {children}
            </ButtonPrimary>
          }
        </>
      }
    </div>
  )
}


export default function Submit({ form, children, validation }) {
  return (
    <>
      {validation
        ?
        <Acl perm="webinterna.validaciones.delete">
          <Content form={form} validation={validation}>{children}</Content>
        </Acl>
        : <Content form={form} validation={validation}>{children}</Content>
      }
    </>
  )
}
