
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { keyBy, debounce } from 'lodash-es'
import BarLoader from 'react-spinners/BarLoader'

import PageLayout from 'layout/archive/PageLayout'
import { listEntities, runQueryPagination } from 'models/docs/entities'
import { Table, HeaderCell, TableRow, Cell } from 'components/Table'
import Datepicker from 'components/Datepicker'
import Icon from 'components/Icon'
import SelectorMultiple from 'components/SelectorMultiple'
import { Dropdown, DropdownMenuItem } from 'components/Dropdown'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import resolver from 'platform/resolver'
import { docs } from 'services/discovery'
import AccessClient from 'services/Docs/access/access'
import * as query from 'models/docs/query'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import { useInfinitePagination } from 'hooks/infinite-pagination'
import { useNavigate } from 'hooks/navigate'
import Throbber from 'components/Throbber'


const accessClient = new AccessClient(docs())
const SORT_OPTIONS = [
  { label: 'Título', name: 'title.raw', direction: 'asc' },
  { label: 'Fecha', name: 'createTime', direction: 'desc' },
]


function useDocRelations() {
  let [owners, setOwners] = useState([])
  let [docTypes, setDocTypes] = useState([])

  async function fetchRelations() {
    let { owners, docTypes } = await resolver.all({
      owners: listEntities('owners'),
      docTypes: listEntities('document-types'),
    })

    setDocTypes(docTypes)

    let refs = await fetchPersonasSociedades(owners.map(o => o.owner))
    setOwners(owners.map(owner => {
      return {
        ...owner,
        ref: refs.resolve(owner.owner),
      }
    }))
  }

  return {
    fetchRelations,
    owners,
    docTypes,
  }
}


export default function ArchiveHome() {
  let {
    fetchRelations,
    owners,
    docTypes,
  } = useDocRelations()
  
  let [ownersOptions, setOwnersOptions] = useState([])
  useEffect(() => {
    setOwnersOptions(owners.map(owner => {
      return {
        value: owner.name,
        label: owner.ref.nombre,
      }
    }))
  }, [owners])
  
  let [docTypesOptions, setDocTypesOptions] = useState([])
  useEffect(() => {
    setDocTypesOptions(docTypes.map(docType => {
      return {
        value: docType.name,
        label: docType.displayName,
      }
    }))
  }, [docTypes])

  let [filterFree, setFilterFree] = useState('')
  let [filterOwners, setFilterOwners] = useState([])
  let [filterDocTypes, setFilterDocTypes] = useState([])
  let [filterDate, setFilterDate] = useState(null)

  let [sort, setSort] = useState(SORT_OPTIONS[0])

  let rootElem = useRef(null)
  let {
    prevPage,
    nextPage,
    loading,
    rows,
    initialLoad,
    reset,
    hasPrevPage,
    hasNextPage,
  } = useInfinitePagination(async function ({ pageToken, pageSize }) {
    let access = await accessClient.ListDocuments()

    if (!access.documents) {
      return {
        rows: [],
      }
    }
    
    let filters = [
      query.andIn('name', access.documents),
      query.and('payload.document.state', 'STATE_ARCHIVED'),
    ]
    if (filterOwners.length) {
      filters.push(query.nested(
        'payload.document.owners',
        query.andIn('payload.document.owners.name', filterOwners),
      ))
    }
    if (filterDocTypes.length) {
      filters.push(query.nested(
        'payload.document.owners',
        query.andIn('payload.document.owners.documentType', filterDocTypes),
      ))
    }
    if (filterFree) {
      filters.push(query.text('payload.document.title', filterFree))
    }
    if (filterDate) {
      let max = new Date(filterDate)
      max.setDate(filterDate.getDate() + 1)
      filters.push(query.range('payload.document.createTime', filterDate, max))
    }
    let reply = await runQueryPagination('documents', {
      pageToken,
      pageSize,
      query: query.build(...filters),
      sort: query.sort(`payload.document.${sort.name}`, sort.direction),
    })
  
    let indexOwners = keyBy(owners, 'name')
    let indexDocTypes = keyBy(docTypes, 'name')
    return {
      ...reply,
      rows: reply.entities.map(doc => {
        return {
          ...doc,
          createTime: parseTimestamp(doc.createTime),
          owner: indexOwners[doc.owners[0].name],
          docType: indexDocTypes[doc.owners[0].documentType],
          subtype: doc.owners[0].subtype,
        }
      }),
    }
  }, {
    rootElem,
  })

  useNavigate(async () => {
    await fetchRelations()
    initialLoad()
  })

  let debouncedReset = useCallback(debounce(reset, 300), [])

  return (
    <PageLayout>
      <div className="mt-6 flex" ref={rootElem}>
        <div className="w-full">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Icon solid name="search" className="text-gray-400" />
            </div>
            <input
              placeholder="Filtrar documentos..."
              className="pl-10 form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={e => {
                setFilterFree(e.target.value.trim())
                debouncedReset()
              }}
            />
          </div>
        </div>
        <SelectorMultiple
          className="w-140 ml-4"
          options={ownersOptions}
          value={filterOwners}
          onChange={value => {
            setFilterOwners(value)
            debouncedReset()
          }}
          empty="Filtrar propietarios"
          selectedPrefix="Propietarios"
        />
        <SelectorMultiple
          className="w-120 ml-4"
          options={docTypesOptions}
          value={filterDocTypes}
          onChange={value => {
            setFilterDocTypes(value)
            debouncedReset()
          }}
          empty="Filtrar tipos"
          selectedPrefix="Tipos"
        />
        <Datepicker
          className="w-120 ml-4"
          placeholder="Filtrar por fecha"
          value={filterDate}
          onChange={value => {
            setFilterDate(value)
            debouncedReset()
          }}
        ></Datepicker>

        <Dropdown
          className="w-80 ml-32 text-right flex flex-col justify-end"
          trigger={
            <button
              type="button"
              className="inline-block rounded-md px-4 py-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-500 -mb-1 whitespace-nowrap"
            >
              <Icon regular name="sort-alpha-down" className="mr-2 text-gray-500" />
              <span className="hidden md:inline">
                {sort.label}
              </span>
              <Icon solid name="chevron-down" className="md:ml-2 -mr-1" />
            </button>
          }
        >
          {SORT_OPTIONS.map(option => (
            <DropdownMenuItem
              key={option.name}
              onClick={() => {
                setSort(option)
                debouncedReset()
              }}
              className={cx(
                {
                  'font-bold': option.name === sort.name,
                },
              )}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </Dropdown>
      </div>
      <Table
        className="mt-4"
        empty="No hay documentos que cumplan con los filtros."
        header={
          <>
            <HeaderCell className="w-full">Título</HeaderCell>
            <HeaderCell>Formato</HeaderCell>
            <HeaderCell>Propietario</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Subtipo</HeaderCell>
            <HeaderCell>Expediente</HeaderCell>
            <HeaderCell>Fecha de creación</HeaderCell>
          </>
        }
      >
        {loading &&
          <tr className="bg-gray-50">
            <td
              colSpan="7"
              className={cx(
                'p-0 align-top relative',
                { 'h-64': !rows.length },
              )}
            >
              <div className="absolute t-0 w-full">
                <BarLoader color="#047481" width="100%" />
              </div>
            </td>
          </tr>
        }
        {rows.map(doc => (
          <TableRow key={doc.name}>
            <Cell>
              <Link to={`/archive/${doc.name}`} className="font-medium text-gray-900">{doc.title}</Link>
            </Cell>
            <Cell>
              {doc.format === 'FORMAT_PHYSICAL' && 'Físico'}
              {doc.format === 'FORMAT_DIGITAL' && 'Digital'}
            </Cell>
            <Cell>{doc.owner?.ref.nombre}</Cell>
            <Cell>{doc.docType?.displayName}</Cell>
            <Cell>{doc.subtype}</Cell>
            <Cell>
              {/* TODO(alberto): Indicar el expediente */}
            </Cell>
            <Cell>{datetime(doc.createTime, 'DATETIME')}</Cell>
          </TableRow>
        ))}
      </Table>
      <div className="mt-3 flex justify-end">
        <nav className="relative z-0 inline-flex shadow-sm -space-x-px">
          <button
            type="button"
            className={cx(
              'relative inline-flex items-center px-2 py-2 rounded-l-md border-l border-t border-b border-gray-300 text-sm font-medium',
              {
                'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasPrevPage,
                'bg-gray-100 cursor-default text-gray-300': !hasPrevPage,
              },
            )}
            title="Página anterior"
            onClick={prevPage}
            disabled={hasPrevPage}
          >
            <Icon regular name="chevron-left" className="mx-2" />
          </button>
          {loading &&
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-xs font-medium bg-gray-100 cursor-default"
              disabled
            >
              <Throbber />
            </button>
          }
          {!loading &&
            <button
              type="button"
              className={cx(
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium',
                {
                  'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasNextPage,
                  'bg-gray-100 cursor-default text-gray-300': !hasNextPage,
                },
              )}
              title="Siguiente página"
              onClick={nextPage}
              disabled={hasNextPage}
            >
              <Icon regular name="chevron-right" className="mx-2" />
            </button>
          }
        </nav>
      </div>
    </PageLayout>
  )
}
