
import React, { useEffect, useState } from 'react'
import { concat } from 'lodash-es'

import Icon from 'components/Icon'
import { accountInfo } from 'auth/active-directory'
import * as query from 'models/docs/query'
import { getEntityOrDefault, runQuery } from 'models/docs/entities'
import ButtonSecondary from './buttons/ButtonSecondary'
import Modal from './Modal'
import Textarea from './simple-forms/Textarea'
import ButtonAsyncPrimary from './buttons/ButtonAsyncPrimary'
import { sendNotificationButton } from 'hooks/notifications'


export default function WithdrawDocument({ doc }) {
  let [groups, setGroups] = useState([])
  let [open, setOpen] = useState(false)
  let [withdrawReason, setWithdrawReason] = useState('')

  useEffect(() => {
    async function getData() {
      let currentUser = accountInfo().name
      let q = query.build(
        query.and('payload.accessGroup.owners', doc.owners[0].name),
        query.and('payload.accessGroup.documentTypes', doc.owners[0].documentType),
        query.and('payload.accessGroup.users', currentUser),
      )
      setGroups(await runQuery('access-groups', q))
    }
    if (doc && doc.owners) {
      getData()
    }
  }, [doc, doc.owners])

  let withdrawPermission = groups.some(document => document.level === 'LEVEL_PHYSICAL_ACCESS')

  async function withdrawDocument() {
    if (withdrawPermission) {
      let reply = await getEntityOrDefault('library-managers/82eb277d-d11a-41d7-b311-1ed4fb7712fa')
      reply.users = reply.users || []
      sendNotificationButton(
        reply.users,
        `Un usuario solicita permiso para retirar un documento. Motivo: ${withdrawReason}.`,
        {
          text: 'Revisar solicitud',
          link: `/archive/withdraw/${doc.name.substr(10)}/${accountInfo().name}`,
        },
      )
    } else {
      let q = query.build(
        query.and('payload.accessGroup.owners', doc.owners[0].name),
        query.and('payload.accessGroup.documentTypes', doc.owners[0].documentType),
        query.and('payload.accessGroup.level', 'LEVEL_PHYSICAL_ACCESS'),
      )
      let groupsUsers = await runQuery('access-groups', q)
      let users = groupsUsers
        .map(g => g.users)
        .reduce((total, curr) => concat(total, curr), [])

      sendNotificationButton(
        users,
        `Un usuario solicita permiso para retirar un documento. Motivo: ${withdrawReason}.`,
        {
          text: 'Revisar solicitud',
          link: `/archive/withdraw/request/${doc.name.substr(10)}/${accountInfo().name}`,
        },
      )
    }
    setOpen(false)
  }

  return (
    <>
      {doc.format === 'FORMAT_PHYSICAL' && doc.state === 'STATE_ARCHIVED' &&
        <ButtonSecondary disabled={doc.loan} onClick={() => setOpen(true)}>
          <Icon solid name="books" className="mr-2" />
          Retirar documento
        </ButtonSecondary>
      }
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Retirar documento"
        footer={
          <div className="w-full flex justify-between">
            <ButtonSecondary onClick={() => setOpen(false)}>
              Cancelar
            </ButtonSecondary>

            <ButtonAsyncPrimary onClick={withdrawDocument}>
              {withdrawPermission ? 'Retirar documento' : 'Solicitar retirada'}
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Textarea
          label="Motivo de retirada"
          name="withdrawReason"
          help="Motivo por el cual se retira el documento."
          rows="5"
          value={withdrawReason}
          onChange={setWithdrawReason}
        />
      </Modal>
    </>
  )
}
