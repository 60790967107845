
import { bearerToken } from 'auth/active-directory'


export function webinterna() {
  return {
    authorization: () => `Bearer ${bearerToken()}`,
    server: process.env.NODE_ENV === 'development' ? 'https://localhost:8002' : 'https://webinterna.api.dlege.altipla.consulting',
  }
}


export function docs() {
  return {
    authorization: () => `Bearer ${bearerToken()}`,
    server: process.env.NODE_ENV === 'development' ? 'https://localhost:8004' : 'https://docs.api.dlege.altipla.consulting',
  }
}


export function users() {
  return {
    authorization: () => `Bearer ${bearerToken()}`,
    server: process.env.NODE_ENV === 'development' ? 'https://localhost:8003' : 'https://users.api.dlege.altipla.consulting',
  }
}


export function notifications() {
  return {
    authorization: () => `Bearer ${bearerToken()}`,
    server: process.env.NODE_ENV === 'development' ? 'https://localhost:8001' : 'https://notifications.api.dlege.altipla.consulting',
  }
}
