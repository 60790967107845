
import { GrpcError, StatusCode } from '@altipla/grpc-browser'
import { omit } from 'lodash-es'

import { docs } from 'services/discovery'
import EntitiesClient  from 'services/Docs/entities/entities'


const entitiesClient = new EntitiesClient(docs())


function firstKey(obj) {
  for (let key in obj) {
    return {
      key,
      payload: obj[key],
    }
  }
}


function parseEntity(entity) {
  let { payload } = firstKey(entity.payload)
  return {
    name: entity.name,
    version: entity.version,
    lastUpdateTime: entity.lastUpdateTime,
    ...payload,
  }
}


function serializeEntity(entity) {
  let { key, payload } = firstKey(entity)
  return {
    name: payload.name,
    version: payload.version,
    parent: payload.parent,
    lastUpdateTime: payload.lastUpdateTime,
    payload: {
      [key]: omit(payload, ['name', 'version', 'parent', 'lastUpdateTime']),
    },
  }
}


export async function listEntities(collection, parent = '') {
  let { entities } = await entitiesClient.List({ collection, parent })
  entities = entities || []
  return entities.map(entity => parseEntity(entity))
}


export async function batchGetEntities(names) {
  let { entities } = await entitiesClient.BatchGet({ names })
  entities = entities || []
  return entities.map(entity => parseEntity(entity))
}


export async function getEntity(name) {
  let entity = await entitiesClient.Get({ name })
  return parseEntity(entity)
}


export async function getEntityOrDefault(name) {
  try {
    let entity = await entitiesClient.Get({ name })
    return parseEntity(entity)
  } catch (err) {
    if (err instanceof GrpcError && err.code === StatusCode.NotFound) {
      return { name }
    }
    throw err
  }
}


export async function putEntity(collection, entity) {
  let { name, version, parent, payload } = serializeEntity(entity)
  
  let result
  if (version) {
    result = await entitiesClient.Update({
      name,
      version,
      payload,
    })
  } else {
    result = await entitiesClient.Create({
      parent,
      collection,
      entity: {
        name,
        version,
        payload,
      },
    })
  }
  return parseEntity(result)
}


export async function runQuery(collection, query, sort) {
  let { entities } = await entitiesClient.RunQuery({ collection, query, sort })
  entities = entities || []
  return entities.map(entity => parseEntity(entity))
}


export async function runQueryPagination(collection, { pageToken, pageSize, query, sort } = {}) {
  let reply = await entitiesClient.RunQuery({
    collection,
    pageToken,
    pageSize,
    query,
    sort,
  })
  return {
    ...reply,
    entities: reply.entities ? reply.entities.map(entity => parseEntity(entity)) : [],
  }
}
