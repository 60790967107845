
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep, some } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { webinterna } from 'services/discovery'
import CentrosTrabajoClient from 'services/WebInterna/centros_trabajo/centros_trabajo'


const client = new CentrosTrabajoClient(webinterna())


export default function CentrosTrabajoEdit() {
  let history = useHistory()
  let { name } = useParams()
  
  let [centro, setCentro] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await client.Get({ name })
      setCentro(cloneDeep(reply))
      setForm(reply)
    }
  })

  async function create() {
    let req = cloneDeep(form)
    if (name) {
      client.Update(req)
      setCentro(req)
    } else {
      let reply = await client.Create(req)
      history.push('/manage/centros-trabajo/' + reply.name)
    }
  }

  async function deleteCentroTrabajo() {
    await client.Delete(centro)
    history.push('/manage/centros-trabajo')
  }
  
  let disableSubmit = some([
    centro.nombre === form.nombre,
    !form.nombre,
  ])

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/centros-trabajo">Centros de trabajo</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/centros-trabajo/${name}`}>{centro.denominacion}</Breadcrumb>
            :
            <Breadcrumb url="/manage/centros-trabajo/create">Crear centro de trabajo</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header={name ? 'Editar centro de trabajo' : 'Crear centro de trabajo'}
        subheader={name ? 'Edita un centro de trabajo.' : 'Crea un centro de trabajo.'}
        footer={
          <div className={`flex ${name ? 'justify-between' : 'justify-end'} w-full`}>
            {name &&
              <ButtonAsyncDanger
                onClick={deleteCentroTrabajo}
                disabled={centro.personas?.length}
              >
                <Icon solid name="trash" className="mr-2" />
                <span title={centro.personas?.length ? 'No se pueden eliminar grupos de procesos con procesos asignados' : ''}>
                  Eliminar centro
                </span>
              </ButtonAsyncDanger>
            }
            <ButtonAsyncPrimary onClick={create} disabled={disableSubmit}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Input
          className="mb-6"
          label="Nombre"
          type="text"
          value={form.nombre}
          onChange={value => {
            setForm({
              ...form,
              nombre: value,
            })
          }}
        ></Input>

      </CardHeader>
    </PageLayout>
  )
}
