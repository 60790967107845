
import React from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { listEntities } from 'models/docs/entities'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'


export default function OwnersList() {
  let { table, setRows } = usePaginatedTable()

  useNavigate(async () => {
    let rows = await listEntities('owners')
    let owners = await fetchPersonasSociedades(rows.map(o => o.owner))
    setRows(rows.map(row => {
      row.owner = {
        nombre: owners.resolve(row.owner).nombre,
        name: row.name,
      }
      row.$search = [ row.owner.nombre ]

      return row
    }))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/owners">Propietarios</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardPaginatedTable
        title="Propietarios"
        subtitle="Personas o sociedades poseedores de documentos en el sistema."
        empty="No hay propietarios que mostrar."
        table={table}
        actions={
          <ButtonLinkPrimary to="/manage/owners/create">
            <Icon solid name="plus" className="mr-2" />
            Crear propietario
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        row={row => (
          <>
            <Cell>
              <Link to={`/manage/${row.name}`} className="text-gray-700">{row.owner.nombre}</Link>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/${row.name}`}>Editar</Link>
            </Cell>
          </>
        )}
      >
      </CardPaginatedTable>
    </PageLayout>
  )
}
