
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import HtmlViewer from 'components/HtmlViewer'
import Card from 'components/Card'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import BadgeGray from 'components/badges/BadgeGray'
import BadgePurple from 'components/badges/BadgePurple'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeOrange from 'components/badges/BadgeOrange'
import { datetime, decimal, percentage } from 'platform/filters'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import EntityLink from 'components/EntityLink'
import ExternalLink from 'components/ExternalLink'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import CardHeader from 'components/CardHeader'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'
import { parseDate } from 'platform/datetime'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())


export default function InmueblesShow() {
  let { name } = useParams()
  let [inmueble, setInmueble] = useState({})

  useNavigate(async () => {
    let inmueble = await inmueblesClient.Get({ name })
    inmueble.altaDate = parseDate(inmueble.altaDate)
    inmueble.altaJuridicaDate = parseDate(inmueble.altaJuridicaDate)
    inmueble.bajaJuridicaDate = parseDate(inmueble.bajaJuridicaDate)
    inmueble.bajaDate = parseDate(inmueble.bajaDate)

    if (inmueble.registroPropiedad) {
      inmueble.registroPropiedad = await registrosPropiedadClient.Get({ name: inmueble.registroPropiedad })
    }

    if (inmueble.municipio) {
      inmueble.municipio = await municipiosClient.Get({ name: inmueble.municipio })
    }

    if (inmueble.provincia) {
      inmueble.provincia = await provinciasClient.Get({ name: inmueble.provincia })
    }

    if (inmueble.grupo) {
      inmueble.grupo = await gruposInmobiliariosClient.Get({ name: inmueble.grupo })
    }

    if (inmueble.proyecto) {
      inmueble.proyecto = await proyectosClient.Get({ name: inmueble.proyecto })
    }

    if (inmueble.origen) {
      inmueble.origen = await inmueblesClient.Get({ name: inmueble.origen })
    }

    let { fincas } = await fincasRegistralesClient.List()
    fincas = fincas || []
    let fincasRegistrales = fincas.filter(fincaRegistral => fincaRegistral.inmueble === inmueble.name)
    inmueble.numeroFincasRegistrales = fincasRegistrales.length
    inmueble.sumaSupRegistrales = fincasRegistrales?.reduce((acc, curr) => acc + curr.superficieRegistralSuelo, 0) ?? 0

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales || []
    elementosPatrimoniales = elementosPatrimoniales.filter(
      elementoPatrimonial => fincasRegistrales.filter(fincaRegistral => elementoPatrimonial.fincaRegistral === fincaRegistral.name),
    )
    inmueble.numeroElementosPatrimoniales = elementosPatrimoniales.length
    inmueble.sumaSupPatrimoniales = elementosPatrimoniales?.reduce((acc, curr) => acc + curr.superficieComputable, 0) ?? 0

    let fincasCatastrales = await fincasCatastralesClient.List()
    fincasCatastrales.fincas = fincasCatastrales.fincas || []
    fincasCatastrales = fincasCatastrales.fincas.filter(
      fincaCatastral => fincasRegistrales.filter(fincaRegistral => fincaCatastral.fincaRegistral === fincaRegistral.name),
    )
    inmueble.numeroFincasCatastrales = fincasCatastrales.length
    inmueble.sumaSupCatastrales = fincasCatastrales?.reduce((acc, curr) => acc + curr.superficieTotal, 0) ?? 0

    setInmueble(inmueble)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!inmueble.blocked &&
            <Acl perm="webinterna.inmuebles.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/inmuebles/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Unidad Inmobiliaria
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/inmuebles">Unidades Inmobiliarias</Breadcrumb>
        <Breadcrumb url={`/webinterna/inmuebles/${name}`}>Consultar Unidad Inmobiliaria</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={inmueble.blocked}></EntityBlockedAlert>

      <div className="flex mb-6">
        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Denominación GIU">{inmueble.grupo?.nombre}</ShowPane>
              <ShowPane title="Código UINM">{inmueble.codigo}</ShowPane>
              <ShowPane title="Denominación UINM">{inmueble.nombre}</ShowPane>
              <ShowPane title="Titular">
                {inmueble.titular && <PeopleViewer value={inmueble.titular} />}
              </ShowPane>
              <ShowPane title="Clase">
                {inmueble.clase === 'CLASE_RUSTICO' && <span>Rústico</span>}
                {inmueble.clase === 'CLASE_URBANIZABLE' && <span>Urbanizable</span>}
                {inmueble.clase === 'CLASE_URBANO' && <span>Urbano</span>}
                {inmueble.clase === 'CLASE_EDIFICACION' && <span>Edificación</span>}
              </ShowPane>
              <ShowPane title="Uso Urbanístico">
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_RESIDENCIAL' && 'Residencial'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_COMERCIAL' && 'Comercial'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_RESIDENCIAL_COMERCIAL' && 'Residencial y Comercial'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_INDUSTRIAL' && 'Industrial'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_LOGISTICO' && 'Logístico'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_HOTELERO' && 'Hotelero'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_OFICINAS' && 'Oficinas'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_INSTALACIONES_AGRICOLAS' && 'Instalaciones agrícolas'}
                {inmueble.usoUrbanistico === 'USO_URBANISTICO_OTROS_USOS' && 'Otros usos'}
              </ShowPane>
              <ShowPane title="Estado">
                {inmueble.estado === 'ESTADO_PROYECTO' && <BadgePurple>Proyecto</BadgePurple>}
                {inmueble.estado === 'ESTADO_ACTIVO' && <BadgeGreen>Activo</BadgeGreen>}
                {inmueble.estado === 'ESTADO_TERMINADO' && <BadgeGray>Terminado</BadgeGray>}
                {inmueble.estado === 'ESTADO_ENAJENADO' && <BadgeOrange>Enajenado</BadgeOrange>}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Estado obras">
                {inmueble.estadoObra === 'ESTADO_OBRA_NO_INICIADA' && 'No iniciada'}
                {inmueble.estadoObra === 'ESTADO_OBRA_EN_CURSO' && 'En curso'}
                {inmueble.estadoObra === 'ESTADO_OBRA_COMPLETADA' && 'Completada'}
              </ShowPane>
              <ShowPane title="Proyecto">
                {inmueble.proyecto && 
                  <EntityLink to={`/webinterna/proyectos/${inmueble.proyecto?.name}`}>
                    {inmueble.proyecto?.denominacion}
                  </EntityLink>
                }
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Junta de Compensación">
                {inmueble.juntaCompensacion && <PeopleViewer value={{ persona: inmueble.juntaCompensacion }} />}
              </ShowPane>
              <ShowPane title="Estado Junta de Compensación">
                {inmueble.estadoJuntaCompensacion === 'ESTADO_JUNTA_COMPENSACION_NO_CONSTITUIDA' && 'No constituida'}
                {inmueble.estadoJuntaCompensacion === 'ESTADO_JUNTA_COMPENSACION_CONSTITUIDA_LIQUIDADA' && 'Constituida y liquidada'}
              </ShowPane>
              {inmueble.estadoJuntaCompensacion === 'ESTADO_JUNTA_COMPENSACION_CONSTITUIDA_LIQUIDADA' &&
                <>
                  <ShowPane title="% derechos de voto en asamblea">{percentage(inmueble.derechoVotoPercentage)}</ShowPane>
                  <ShowPane title="% participación en costes urb.">{percentage(inmueble.participacionCostesPercentage)}</ShowPane>
                </>
              }
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Comunidad">
                {inmueble.comunidad && <PeopleViewer value={{ persona: inmueble.comunidad }} />}
              </ShowPane>
              {inmueble.estadoJuntaCompensacion === 'ESTADO_JUNTA_COMPENSACION_CONSTITUIDA_LIQUIDADA' &&
                <ShowPane title="% participación">{percentage(inmueble.participacionPercentage)}</ShowPane>
              }
            </div>
          </Card>
        </div>
        <div className="space-y-4 w-1/2">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Fecha Alta Jurídica">{datetime(inmueble.altaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja Jurídica">{datetime(inmueble.bajaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Alta">{datetime(inmueble.altaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja">{datetime(inmueble.bajaDate, 'DATE')}</ShowPane>
              <ShowPane title="Origen">
                {inmueble.origen && 
                  <EntityLink to={`/webinterna/proyectos/${inmueble.origen?.name}`}>
                    {inmueble.origen?.nombre}
                  </EntityLink>
                }
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Registro de la Propiedad">{inmueble.registroPropiedad?.nombre}</ShowPane>
              <ShowPane title="Municipio">{inmueble.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">
                {inmueble.provincia?.nombre}
              </ShowPane>
              <ShowPane title="Coordenadas UTM">{inmueble.coordenadas}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Nº Fincas Registrales">{inmueble.numeroFincasRegistrales}</ShowPane>
              <ShowPane title="Nº Elementos Patrimoniales">{inmueble.numeroElementosPatrimoniales}</ShowPane>
              <ShowPane title="Nº Fincas catastrales">{inmueble.numeroFincasCatastrales}</ShowPane>
              <ShowPane title="Suma sup. Registrales">{decimal(inmueble.sumaSupRegistrales)}</ShowPane>
              <ShowPane title="Suma sup. Elementos Patrimoniales">{decimal(inmueble.sumaSupPatrimoniales)}</ShowPane>
              <ShowPane title="Suma sup. catastrales">{decimal(inmueble.sumaSupCatastrales)}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Observaciones">
                <HtmlViewer html={inmueble.observaciones}/>
              </ShowPane>
            </div>
          </Card>
        </div>
      </div>

      <Card>
        <div className="flex flex-col gap-4">
          <ShowPane title="Cuadro superficies">
            <ExternalLink to={inmueble.cuadroSuperficies}>{inmueble.cuadroSuperficies}</ExternalLink>
          </ShowPane>
          <ShowPane title="Planimetría referencia">
            <ExternalLink to={inmueble.planimetria}>{inmueble.planimetria}</ExternalLink>
          </ShowPane>
        </div>
      </Card>

      <CardHeader header="Navegación" className="mt-4">
        <div className="flex items center w-full gap-4">
          <ButtonLinkSecondary to={`/webinterna/fincas-registrales?inmueble=${inmueble.name}`}>Ver fincas registrales asociadas</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/elementos-patrimoniales?inmueble=${inmueble.name}`}>Ver elementos patrimoniales asociados</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/fincas-catastrales?inmueble=${inmueble.name}`}>Ver fincas catastrales asociadas</ButtonLinkSecondary>
        </div>
      </CardHeader>
    </PageLayout>
  )
}
