
import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import { useField } from 'platform/forms'
import Icon from 'components/Icon'


export default function Select({ form, className, help, name, label, validations, value, onChange, options, fit }) {
  let field = useField({ form, name, validations, value, onChange })
  let [selected, setSelected] = useState(value)

  useEffect(() => setSelected(value), [value])

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && label && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <select
          name={name}
          id={name}
          className={cx(
            'form-select block w-full focus:ring-teal-500 focus:border-teal-500 shadow-sm sm:text-sm border-gray-300 rounded-md',
            {
              'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red': field.hasError,
              'w-full': !fit,
              'max-w-lg sm:max-w-xs': fit,
            },
          )}
          value={selected}
          onChange={e => {
            field.setValue(e.target.value)
            setSelected(e.target.value)
          }}
        >
          <option value="" hidden></option>
          {options.map(option => {
            return <option key={option.value.trim()} value={option.value}>{option.label}</option>
          })}
        </select>
        {field.hasError &&
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
            <Icon solid name="exclamation-circle" />
          </div>
        }
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
