
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import GroupsClient from 'services/Users/groups/groups'
import { useNavigate } from 'hooks/navigate'
import { users } from 'services/discovery'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'

const client = new GroupsClient(users())

export default function GroupsList() {
  let [groups, setGroups] = useState([])

  useNavigate(async () => {
    let reply = await client.List().then(reply => reply.groups || [])
    setGroups(reply)
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/groups">Grupos</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Grupos"
        subtitle="Agrupa usuarios para asignarles roles y permisos de forma conjunta y darles acceso a diferentes partes de la plataforma."
        empty="No hay grupos configurados."
        actions={
          <ButtonLinkPrimary to="/manage/groups/create">
            <Icon solid name="plus" className="mr-2" />
            Crear grupo
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {groups.map(group => (
          <TableRow key={group.name}>
            <Cell>
              <Link to={`/manage/groups/${group.name}`} className="text-gray-700">{group.displayName}</Link>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/groups/${group.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
