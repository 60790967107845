
import React from 'react'
import cx from 'classnames'

export default function ShowPane({ className, children, title }) {
  return (
    <dl className={cx('md:flex', className)}>
      <dt className="text-sm font-medium text-gray-400 text-right md:mr-6 w-40">{title}</dt>
      <dd className="mt-1 md:mt-0 text-sm text-gray-900 flex-1 overflow-ellipsis overflow-hidden">{children}</dd>
    </dl>
  )
}
