
import React, { useState } from 'react'
import cx from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'
import { nanoid } from 'nanoid'
import { eachOfLimit, asyncify } from 'async'

import Icon from 'components/Icon'
import PageLayout from 'layout/docs/PageLayout'
import FileUpload from 'components/FileUpload'
import { useNavigate } from 'hooks/navigate'
import { Table, HeaderCell, Cell, TableRow } from 'components/Table'
import { setPendingUpload } from 'views/docs/upload/UploadMain'
import * as query from 'models/docs/query'
import { runQuery, runQueryPagination, batchGetEntities } from 'models/docs/entities'
import { accountInfo } from 'auth/active-directory'
import Throbber from 'components/Throbber'
import { filterActiveRoles } from 'models/docs/inboxes-groups'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const STATES = [
  'STATE_UPLOADED',
  'STATE_REVIEWED',
  'STATE_MANAGEMENT_PENDING',
  'STATE_CONTROL_PENDING',
  'STATE_CLASSIFICATION',
  'STATE_DIGITAL_SIGNATURES',
  'STATE_PHYSICAL_SIGNATURES',
  'STATE_SIGNATURE_COUNTERPART',
  'STATE_VALIDATION',
  'STATE_PHYSICAL_ARCHIVE_PENDING',
]


export default function Home() {
  let [inboxesLoading, setInboxesLoading] = useState(true)
  let [inboxes, setInboxes] = useState([])
  let [lastDocs, setLastDocs] = useState([])
  let [docsLoading, setDocsLoading] = useState(true)
  let [myDocs, setMyDocs] = useState([])

  let history = useHistory()

  useNavigate(async () => {
    setDocsLoading(true)
    setInboxesLoading(true)

    let q = query.build(
      query.and('payload.inboxesGroup.users', accountInfo().name),
    )
    let inboxesGroups = await runQuery('inboxes-groups', q)

    let names = []
    inboxesGroups.forEach(group => {
      names = names.concat(group.owners, group.documentTypes)
    })
    let entities = await batchGetEntities(uniq(names))
    entities = keyBy(entities, 'name')

    let ownersNames = []
    inboxesGroups.forEach(group => {
      group.owners.forEach(owner => 
        ownersNames.push(entities[owner].owner),
      )
    })
    let owners = await fetchPersonasSociedades(ownersNames)

    let inboxes = []
    let lastDocs = []
    inboxesGroups.forEach(group => {
      group.owners.forEach(owner => {
        group.documentTypes.forEach(docType => {
          filterActiveRoles(group.states).forEach(role => {
            lastDocs.push({
              loading: true,
            })

            let query = new URLSearchParams({
              role: role.value,
              owner,
              docType,
            })
            inboxes.push({
              ghostKey: nanoid(),
              url: `/docs/${group.name}?${query.toString()}`,
              role,
              ownerName: entities[owner].name,
              owner: owners.resolve(entities[owner].owner),
              docType: entities[docType],
            })
          })
        })
      })
    })
    setLastDocs(lastDocs)
    setInboxes(inboxes)
    setInboxesLoading(false)

    let qDocs = query.build(
      query.and('payload.document.uploader', accountInfo().name),
      query.andIn('payload.document.state', STATES),
    )
    let reply = await runQueryPagination('documents', {
      query: qDocs,
      sort: query.sort('lastUpdateTime', 'desc'),
      pageSize: 5,
    })
    setMyDocs(reply.entities.map(doc => {
      doc.createTime = parseTimestamp(doc.createTime)
      return doc
    }))
    setDocsLoading(false)

    eachOfLimit(inboxes, 4, asyncify(async (inbox, idx) => {
      let q = query.build(
        query.andIn('payload.document.state', inbox.role.states),
        query.nested(
          'payload.document.owners',
          query.and('payload.document.owners.name', inbox.ownerName),
          query.and('payload.document.owners.documentType', inbox.docType.name),
        ),
      )
      let reply = await runQueryPagination('documents', {
        query: q,
        sort: query.sort('lastUpdateTime', 'desc'),
        pageSize: 1,
      })
      if (!reply.entities.length) {
        lastDocs[idx].loading = false
        setLastDocs([...lastDocs])
        return
      }

      let doc = reply.entities[0]
      doc.lastUpdateTime = parseTimestamp(doc.lastUpdateTime)
      lastDocs[idx].doc = doc
      lastDocs[idx].loading = false
      setLastDocs([...lastDocs])
    }))
  })

  function fileSelected(file) {
    setPendingUpload(file)
    history.push('/docs/upload/main')
  }

  return (
    <PageLayout>
      <div className="flex space-x-16">
        <div className="flex-grow">
          <h3 className="text-2xl font-semibold font-display text-gray-900 sm:text-3xl">Mis Bandejas</h3>
          <div className="shadow sm:rounded-md mt-6">
            <div className="bg-white">
              <ul className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                {inboxesLoading &&
                  <li className="px-6 text-center text-lg italic text-gray-400 py-10">
                    <Throbber></Throbber>
                  </li>
                }
                {!inboxesLoading && !inboxes.length &&
                  <li className="px-6 text-center text-lg italic text-gray-400 py-20">
                    No hay ninguna bandeja de documentos accesible
                  </li>
                }
                {inboxes.map((inbox, idx) => (
                  <li className="relative px-4 py-3 hover:bg-gray-50" key={inbox.ghostKey}>
                    <div className="flex items-start justify-between space-x-4">
                      <div className="min-w-0 space-y-3">
                        <div className="flex items-start space-x-3">
                          {lastDocs[idx].loading
                            ?
                            <Icon duotone spin name="spinner-third" className="text-teal-300 h-4 w-4 mt-0.5"></Icon>
                            :
                            <span
                              className={cx(
                                'h-4 w-4 rounded-full flex items-center justify-center mt-0.5',
                                { 'bg-gray-100': !lastDocs[idx].doc, 'bg-green-100': lastDocs[idx].doc },
                              )}
                            >
                              <span
                                className={cx(
                                  'h-2 w-2 rounded-full',
                                  { 'bg-gray-400': !lastDocs[idx].doc, 'bg-green-400': lastDocs[idx].doc },
                                )}
                              ></span>
                            </span>
                          }

                          <span className="block">
                            <h2 className="text-sm font-medium">
                              <Link to={inbox.url} className="text-gray-900 hover:text-gray-900">
                                <span className="absolute inset-0"></span>
                                {inbox.role.label}: {inbox.owner.nombre}
                              </Link>
                            </h2>
                            <span className="text-sm text-gray-400 font-medium truncate">
                              {inbox.docType.displayName}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="sm:hidden">
                        <Icon solid name="chevron-right" className="text-gray-400" />
                      </div>

                      <div className="hidden sm:flex flex-col flex-shrink-0 items-end">
                        <p className="flex items-center space-x-4">
                          <Link to={inbox.url} className="relative text-sm text-indigo-500 hover:text-indigo-900 font-medium">
                            Ver bandeja
                            <Icon regular name="inbox-in" className="ml-2" />
                          </Link>
                        </p>
                        <div className="flex">
                          {!lastDocs[idx].loading && lastDocs[idx].doc &&
                            <div className="flex text-sm text-gray-500 justify-end w-88 mt-1">
                              <div className="text-gray-400 font-medium truncate mr-2">
                                {lastDocs[idx].doc.title ? lastDocs[idx].doc.title : lastDocs[idx].doc.suggestedTitle}
                              </div>
                              &mdash;
                              <div className="flex-none ml-2">{datetime(lastDocs[idx].doc.lastUpdateTime, 'DAY_MONTH_TIME')}</div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-128 flex-shrink-0">
          <FileUpload onSelect={fileSelected} />

          <h3 className="mt-12 mb-4 text-xl font-semibold font-display text-gray-900">Mis Documentos</h3>
          {docsLoading &&
            <div className="px-6 text-center text-lg italic text-gray-400 py-10 bg-gray-50 rounded-md">
              <Throbber></Throbber>
            </div>
          }
          {!docsLoading &&
            <Table
              header={
                <>
                  <HeaderCell>Nombre</HeaderCell>
                </>
              }
              empty="No has subido documentos todavía"
            >
              {myDocs.map(doc => (
                <TableRow key={doc.name}>
                  <Cell className="max-w-0 w-full">
                    <div className="text-gray-900 font-medium truncate">
                      {doc.title || doc.suggestedTitle}
                    </div>
                    <div className="mt-1 space-x-6">
                      <span>
                        <Icon regular name="calendar-alt" className="text-gray-500 mr-2" />
                        {datetime(doc.createTime, 'DATETIME')}
                      </span>
                      <span>
                        <Icon regular name="tasks" className="text-gray-500 mr-2" />
                        {doc.state === 'STATE_UPLOADED' && 'Esperando revisión'}
                        {doc.state === 'STATE_REVIEWED' && 'Correcciones pendientes'}
                        {doc.state === 'STATE_MANAGEMENT_PENDING' && 'Pendiente gestión'}
                        {doc.state === 'STATE_CONTROL_PENDING' && 'Pendiente control'}
                        {doc.state === 'STATE_CLASSIFICATION' && 'Clasificación'}
                        {doc.state === 'STATE_DIGITAL_SIGNATURES' && 'Firma digital'}
                        {doc.state === 'STATE_PHYSICAL_SIGNATURES' && 'Firma física'}
                        {doc.state === 'STATE_SIGNATURE_COUNTERPART' && 'Firma contraparte'}
                        {doc.state === 'STATE_PRESENTATION' && 'Presentación'}
                      </span>
                    </div>
                  </Cell>
                </TableRow>
              ))}
            </Table>
          }
        </div>
      </div>
    </PageLayout>
  )
}
