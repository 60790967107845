
import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import diacritic from 'diacritic'

import Icon from 'components/Icon'


const DEFAULT_PAGE_SIZE = 30


export function usePaginatedTable({
  pageSize = DEFAULT_PAGE_SIZE,
  primaryField = 'name',
} = {}) {
  let root = useRef(null)

  let [allRows, setAllRows] = useState([])
  let [filteredRows, setFilteredRows] = useState([])
  let [currentPageRows, setCurrentPageRows] = useState([])
  let [page, setPage] = useState(0)
  let [range, setRange] = useState({
    start: 0,
    end: 0,
    total: 0,
  })
  let [filter, setFilter] = useState('')

  function goTo(newPage) {
    setPage(newPage)
    let start = newPage * pageSize
    let end = Math.min((newPage + 1) * pageSize, filteredRows.length)
    setCurrentPageRows(filteredRows.slice(start, end))

    let totalPages = parseInt(filteredRows.length / pageSize)
    if (filteredRows.length % pageSize !== 0) {
      totalPages++
    }
    setRange({
      start,
      end,
      total: filteredRows.length,
      totalPages,
    })

    let scrollable = root.current?.closest('.overflow-y-scroll')
    if (scrollable) {
      if (scrollable.scrollTop > 0) {
        scrollable.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    } else {
      if (window.scrollY > 0) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    let search = diacritic.clean(filter).toUpperCase()

    setFilteredRows(allRows.filter(row => row.$search.includes(search)))
  }, [allRows, filter])

  // Go to the first page after loading the rows or changing
  // the filters.
  useEffect(() => goTo(0), [filteredRows]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    // Global object with everything grouped to pass it together to the component.
    // Everything inside this object is internal and intended only for use inside
    // the datatable component below.
    table: {
      root,
      currentPageRows,
      page,
      setPage,
      primaryField,
      range,
      filter,
      setFilter,
      filteredRows,

      nextPage() {
        if (page < range.totalPages - 1) {
          goTo(page + 1)
        }
      },

      prevPage() {
        if (page > 0) {
          goTo(page - 1)
        }
      },
    },

    /**
     * Set the rows from the server.
     * @param {Array} rows List of items.
     */
    setRows(rows) {
      setAllRows(rows.map(row => {
        row.$search = diacritic.clean(row.$search.join(' ')).toUpperCase()

        return row
      }))
    },
  }
}


export function CardPaginatedTable({ className, title, subtitle, actions, table, header, row, empty }) {
  return (
    <div className={cx('bg-white shadow sm:rounded-md', className)}>
      <div className="px-4 sm:px-6 py-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
            {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
          </div>
          {actions && <div className="ml-4 mt-2 flex-shrink-0">{actions}</div>}
        </div>
      </div>
      <div className="flex rounded-md w-full">
        <div className="relative flex-grow focus-within:z-10 border-b-3 border-gray-300 pl-6">
          <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
            <Icon solid name="search" className="text-gray-400" />
          </div>
          <input
            type="text"
            autoComplete="off"
            className="form-input block w-full focus:ring-0 py-2 pl-8 border-0"
            placeholder="Filtrar..."
            value={table.filter}
            onChange={event => table.setFilter(event.target.value.trim())}
          />
        </div>
      </div>
      <table className="min-w-full">
        <thead>
          <tr>
            {header}
          </tr>
        </thead>
        <tbody>
          {table.currentPageRows.map((rowData, index) => (
            <tr
              className={cx({
                'bg-white': !(index % 2),
                'bg-gray-50': index % 2,
              })}
              key={rowData[table.primaryField]}
            >
              {row(rowData)}
            </tr>
          ))}
          {!table.filteredRows.length &&
            <tr>
              <td colSpan={header.props.children.length} className="bg-white text-center text-xl italic text-gray-400 py-20">
                {empty}
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div className="w-full bg-white px-6 py-3 flex items-center justify-between border-t border-gray-200">
        <div className="flex-1 flex items-center justify-between">
          <div>
            <p className="text-sm leading-5 text-gray-700">
              <span className="font-medium pr-1">{Math.min(table.range.start + 1, table.range.total)}</span>
              -
              <span className="font-medium px-1">{table.range.end}</span>
              de
              <span className="font-medium px-1">{table.range.total}</span>
              resultados
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex shadow-sm -space-x-px">
              <button
                type="button"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                title="Página anterior"
                onClick={table.prevPage}
              >
                <Icon regular name="chevron-left" className="mx-2" />
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                title="Siguiente página"
                onClick={table.nextPage}
              >
                <Icon regular name="chevron-right" className="mx-2" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
