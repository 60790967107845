
import { webinterna } from 'services/discovery'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'


const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())


export let REF_ELEMENTO_PATRIMONIAL = {
  loader: async names => {
    let { elementosPatrimoniales } = await elementosPatrimonialesClient.BatchGet({ names })
    elementosPatrimoniales = elementosPatrimoniales.map(e => {
      return {
        ...e,
        descriptor: 'Elemento Patrimonial',
        label: e.nombre,
      }
    })
    return elementosPatrimoniales
  },
  link: name => `/webinterna/elementos-patrimoniales/${name}`,
  label: 'Elemento patrimonial',
  fields: {
    codigo: {
      label: 'Código',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigo,
    },
    denominacion: {
      label: 'Denominación',
      type: 'text',
      wide: false,
      extractor: entity => entity.nombre,
    },
    codigoPostal: {
      label: 'Código Postal',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigoPostal,
    },
    tipo: {
      label: 'Tipo',
      type: 'text',
      wide: false,
      extractor: entity => {
        const TIPOS = {
          TIPO_VIVIENDA: 'Vivienda',
          TIPO_VIVIENDA_PP: 'Vivienda PP',
          TIPO_LOCAL: 'Local',
          TIPO_NAVE: 'Nave',
          TIPO_HOTEL: 'Hotel',
          TIPO_OFICINA: 'Oficina',
          TIPO_TRASTERO: 'Trastero',
          TIPO_ALMACEN: 'Almacén',
          TIPO_GARAJE: 'Garaje',
          TIPO_TERRENO: 'Terreno',
          TIPO_INSTALACION_AGRICOLA: 'Instalación',
          TIPO_VALLA: 'Valla',
          TIPO_KIOSCO: 'Kiosco',
          TIPO_STAND: 'Stand',
          TIPO_OTROS: 'Otros',
        }
        return TIPOS[entity.tipo]
      },
    },
    destino: {
      label: 'Destino',
      type: 'text',
      wide: false,
      extractor: entity => {
        const DESTINOS = {
          DESTINO_ARRENDAMIENTO: 'Arrendamiento',
          DESTINO_VENTA: 'Venta',
          DESTINO_USO_PROPIO: 'Uso propio',
        }
        return DESTINOS[entity.destino]
      },
    },
  },
}
