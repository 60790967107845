
import React from 'react'

import ButtonSecondarySm from 'components/buttons/ButtonSecondarySm'
import Icon from 'components/Icon'
import { CustomFieldSelector } from 'components/CustomFieldSelector'
import ButtonSecondaryDangerSm from 'components/buttons/ButtonSecondaryDangerSm'
import Input from 'components/forms/Input'
import Checkbox from 'components/forms/Checkbox'
import Selector from 'components/forms/Selector'
import { nanoid } from 'nanoid'


function Label({ field }) {
  return (
    <legend className="mb-1 block text-sm font-medium text-gray-700">
      {field.displayName}
      {field.required && <span className="text-red-700 align-text-top">&nbsp;*</span>}
    </legend>
  )
}

export function CustomFieldEditor({ form, field, index, onChange }) {
  return (
    <>
      {!field.select && <Label field={field}/>}
      {field.values.map((value, valueIndex) => (
        <div className="flex gap-x-2 mb-2" key={`${field.name}-${value.ghostKey}`}>
          {field.text &&
            <Input
              form={form}
              name={`fields[${index}].values[${valueIndex}].text`}
              validations={field.validations}
              className="w-full"
              value={value.text}
            ></Input>
          }
          {field.numeric &&
            <Input
              form={form}
              name={`fields[${index}].values[${valueIndex}].numeric`}
              type="number"
              validations={field.validations}
              value={value.numeric}
            ></Input>
          }
          {field.email &&
            <Input
              form={form}
              name={`fields[${index}].values[${valueIndex}].email`}
              validations={field.validations}
              className="w-full"
              type="email"
              value={value.email}
            ></Input>
          }
          {field.url &&
            <Input
              form={form}
              name={`fields[${index}].values[${valueIndex}].url`}
              validations={field.validations}
              className="w-full"
              value={value.url}
            ></Input>
          }
          {field.checkbox &&
            <Checkbox
              form={form}
              name={`fields[${index}].values[0].checkbox`}
              label={field.displayName}
            ></Checkbox>
          }
          {field.select && !field.multiple &&
            <Selector
              form={form}
              name={`fields[${index}].values[0].select`}
              validations={field.validations}
              label={field.displayName}
              options={field.select.options}
              value={field.values[0].select}
            ></Selector>
          }
          {field.select && field.multiple &&
            <CustomFieldSelector
              form={form}
              name={`fields[${index}].values`}
              validations={field.validations}
              header={field.displayName}
              selectOptions={field.select.options}
              value={field.values}
            />
          }
          {field.webinternaRef &&
            <CustomFieldSelector
              header={field.displayName}
              form={form}
              name={`fields[${index}].values[${valueIndex}].webinternaRef`}
              type={field.webinternaRef.ref}
              validations={field.validations}
              value={value.webinternaRef}
            />
          }
          {field.webinternaRefDescription &&
            <CustomFieldSelector
              header={field.displayName}
              form={form}
              name={`fields[${index}].values[${valueIndex}].webinternaRefDescription`}
              type={field.webinternaRefDescription.ref}
              validations={field.validations}
              value={value.webinternaRefDescription}
              description
            />
          }
          {field.multiple &&
            <div className="ml-2 flex flex-col mt-1">
              <ButtonSecondaryDangerSm
                onClick={() => {
                  field.values = field.values.filter((_v, i) => i !== valueIndex)
                  onChange(field.values)
                }}
              >
                <Icon solid name="trash" className="my-0.5" />
              </ButtonSecondaryDangerSm>
            </div>
          }
        </div>
      ))}
      {field.multiple && !field.select &&
        <ButtonSecondarySm
          onClick={() => {
            field.values.push({
              ghostKey: nanoid(),
            })
            onChange(field.values)
          }}
        >
          <Icon solid name="plus" className="mr-2" />
          Añadir
        </ButtonSecondarySm>
      }
    </>
  )
}
