
import React from 'react'
import cx from 'classnames'

import { CustomFieldViewer } from 'components/CustomFieldViewer'


export function CustomFieldsViewer({ fields, grid }) {
  return (
    <div
      className={cx(
        {
          'grid grid-cols-1 md:grid-cols-2 gap-y-2': grid,
          'space-y-2': !grid,
        },
      )}
    >
      {fields?.map(field => (
        <CustomFieldViewer key={field.name} field={field} />
      ))}
    </div>
  )
}
