
import { keyBy, uniq } from 'lodash-es'

import { webinterna } from 'services/discovery'
import ProcesosClient from 'services/WebInterna/procesos/procesos'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'

const procesosClient = new ProcesosClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())


export let REF_PROCESO = {
  loader: async names => {
    let reply = await procesosClient.BatchGet({ names })
    let procesos = reply.procesos || []

    let gruposNames = procesos.map(proceso => proceso.grupo)
    let grupos = await gruposProcesosClient.BatchGet({ names: uniq(gruposNames) })
    grupos = keyBy(grupos.grupos, 'name')
    procesos.forEach(proceso => {
      proceso.grupo = grupos[proceso.grupo]?.nombre
    })

    let responsables = []
    let gestores = []
    procesos.forEach(proceso => {
      if (proceso.analista) {
        responsables.push({
          persona: proceso.analista,
        })
      }
      if (proceso.gestores) {
        gestores = proceso.gestores.forEach(gestor => {
          return {
            persona: gestor.contribuidor,
          }
        })
        gestores = gestores || []
      }
      if (proceso.cuidador) {
        responsables.push({
          persona: proceso.cuidador,
        })
      }
      if (proceso.gerentes) {
        proceso.gerentes.forEach(gerente => {
          responsables.push({
            persona: gerente,
          })
        })
      }
    })

    procesos = procesos.map(p => {
      return {
        ...p,
        label: p.denominacion,
        descriptor: 'Proceso',
        gestores,
        responsables,
      }
    })
    return procesos
  },
  link: name => `/webinterna/procesos/${name}`,
  label: 'Proceso',
  fields: {
    grupo: {
      label: 'Grupo',
      type: 'text',
      wide: false,
      extractor: entity => entity.grupo,
    },
    codigo: {
      label: 'Código',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigo,
    },
    nombre: {
      label: 'Nombre proceso',
      type: 'text',
      wide: false,
      extractor: entity => entity.denominacion,
    },
    responsables: {
      label: 'Responsables',
      type: 'persona',
      wide: true,
      multi: true,
      extractor: entity => entity.responsables,
    },
    gestores: {
      label: 'Gestores',
      type: 'persona',
      wide: true,
      multi: true,
      extractor: entity => entity.gestores,
    },
  },
}
