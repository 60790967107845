
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { omitBy, isNil } from 'lodash'
import { webinterna, users } from 'services/discovery'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import UsersClient from 'services/Users/users/users'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import RadioGroupCards from 'components/RadioGroupCards'
import Input from 'components/forms/Input'
import CardHeader from 'components/CardHeader'
import { WideList, WideListInteractiveItem, WideListEmpty } from 'components/WideList'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import { PeopleGrid } from 'components/people'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import PersonasClient from 'services/WebInterna/personas/personas'
import Datepicker from 'components/forms/Datepicker'
import resolver from 'platform/resolver'
import CentrosTrabajoClient from 'services/WebInterna/centros_trabajo/centros_trabajo'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import Select from 'components/forms/Select'
import HtmlEditor from 'components/forms/HtmlEditor'
import Checkbox from 'components/forms/Checkbox'
import SectionTitle from 'components/SectionTitle'
import { parseDate, parseTimestamp, serializeDate } from 'platform/datetime'
import Locality from 'components/forms/Locality'
import Acl from 'components/Acl'
import SingleEntity from 'components/forms/SingleEntity'
import { useNavigate } from 'hooks/navigate'


const personasClient = new PersonasClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const usersClient = new UsersClient(users())
const centrosTrabajoClient = new CentrosTrabajoClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())


function usePersona({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [persona, setPersona] = useState({})
  let [validation, setValidation] = useState(null)
  let [type, setType] = useState('')
  let [contribuidor, setContribuidor] = useState(false)
  let [notario, setNotario] = useState(false)
  let [personaContacto, setPersonaContacto] = useState(false)
  let [tipoIdentidad, setTipoIdentidad] = useState('')

  async function load() {
    if (!isEdit) {
      return
    }
    let reply = await personasClient.Get({ name })
    reply.creacionTime = parseTimestamp(reply.creacionTime)
    reply.bajaTime = parseTimestamp(reply.bajaTime)
    if (reply.fisica && reply.fisica.contribuidor) {
      reply.fisica.contribuidor.nacimientoDate = parseDate(reply.fisica?.contribuidor?.nacimientoDate)
    }

    setPersonaContacto(!!reply.personaContacto)
    setPersona(reply)
    setContribuidor(!!reply.fisica?.contribuidor)
    setType(reply.fisica ? 'FISICA' : 'JURIDICA')
    setTipoIdentidad(reply.tipoIdentidad)
    if (reply.fisica?.notario) {
      setNotario(true)
    }
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name })
    reply.entidad.persona.creacionTime = parseTimestamp(reply.entidad.persona.creacionTime)
    reply.entidad.persona.bajaTime = parseTimestamp(reply.entidad.persona.bajaTime)
    if (reply.entidad.persona.fisica && reply.entidad.persona.fisica.contribuidor) {
      reply.entidad.persona.fisica.contribuidor.nacimientoDate = parseDate(reply.entidad.persona.fisica?.contribuidor?.nacimientoDate)
    }

    setPersonaContacto(reply.entidad.persona.personaContacto)
    setPersona(reply.entidad.persona)
    setContribuidor(!!reply.entidad.persona.fisica?.contribuidor)
    setType(reply.entidad.persona.fisica ? 'FISICA' : 'JURIDICA')
    setTipoIdentidad(reply.entidad.persona.tipoIdentidad)
    if (reply.entidad.persona.fisica?.notario) {
      setNotario(true)
    }
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        persona: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de esta persona para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/personas/${name}`)
    } else {
      history.push(`/webinterna/personas`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await personasClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await personasClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await personasClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        persona,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de esta persona para que lo apruebe un administrador.')
    history.push(`/webinterna/personas`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    persona,
    type,
    setType,
    contribuidor,
    setContribuidor,
    notario,
    setNotario,
    personaContacto,
    setPersonaContacto,
    tipoIdentidad,
    setTipoIdentidad,
    validation,
    remove,
  }
}

function useCargosInstitucionales(persona) {
  let [cargosInstitucionales, setCargosInstitucionales] = useState([])

  function add() {
    setCargosInstitucionales([
      ...cargosInstitucionales,
      {
        ghostKey: nanoid(),
      },
    ])
  }

  function remove(cargo) {
    setCargosInstitucionales(cargosInstitucionales.filter(ci => ci !== cargo))
  }

  useEffect(() => {
    if (persona?.fisica?.contribuidor?.cargosInstitucionales?.length) {
      setCargosInstitucionales(persona.fisica.contribuidor.cargosInstitucionales.map(cargo => {
        return {
          ...cargo,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setCargosInstitucionales, persona])

  return {
    cargosInstitucionales,
    add,
    remove,
  }
}


export default function PersonasEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [users, setUsers] = useState([])
  let [centrosTrabajo, setCentrosTrabajo] = useState([])
  let [sociedades, setSociedades] = useState([])

  let {
    save: savePersona,
    load: loadPersona,
    loadValidation,
    confirmValidation,
    persona,
    type,
    setType,
    contribuidor,
    setContribuidor,
    notario,
    setNotario,
    personaContacto,
    setPersonaContacto,
    tipoIdentidad,
    setTipoIdentidad,
    validation,
    remove: removePersona,
  } = usePersona({ name })

  useNavigate(async () => {
    let { users, centrosTrabajo, sociedades } = await resolver.all({
      users: usersClient.List().then(reply => reply.users || []),
      centrosTrabajo: centrosTrabajoClient.List().then(reply => reply.centros || []),
      sociedades: sociedadesClient.List().then(reply => reply.sociedades || []),
    })
    setUsers(users.map(u => {
      return {
        value: u.name,
        label: u.displayName,
        url: `/manage/users/${u.name}`,
        sublabel: u.email,
      }
    }))
    setCentrosTrabajo(centrosTrabajo.map(c => {
      return {
        value: c.name,
        label: c.nombre,
      }
    }))
    setSociedades(sociedades.map(s => {
      return {
        value: s.name,
        label: s.denominacion,
      }
    }))

    if (isValidation) {
      await loadValidation()
    } else {
      await loadPersona()
    }
  })

  let {
    cargosInstitucionales,
    add: addCargoInstitucional,
    remove: removeCargoInstitucional,
  } = useCargosInstitucionales(persona)

  let form = useForm(async (data) => {
    if (!type) {
      return
    }

    if (notario) {
      data.fisica.notario = true
    }

    if (!Object.values(omitBy(data.personaContacto, isNil)).length || !personaContacto) {
      delete data.personaContacto
    }

    if (type === 'JURIDICA') {
      delete persona.fisica
    } else if (!contribuidor) {
      delete persona.fisica?.contribuidor
    } else {
      delete persona.fisica?.fisica
    }

    let req = {
      ...persona,
      ...data,
    }

    delete req.contacto
    delete req.contribuidor
    delete req.notario

    if (req.fisica && contribuidor) {
      req.fisica.contribuidor.nacimientoDate = serializeDate(req.fisica?.contribuidor?.nacimientoDate)
    }

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await savePersona(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !persona.blocked &&
            <ButtonConfirmDanger onClick={removePersona}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Persona
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/personas">Personas</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/personas/${name}`}>Consultar Persona</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/personas/edit/${name}`}>Editar Persona</Breadcrumb>
          : <Breadcrumb url="/webinterna/personas/edit/new">Crear Nueva Persona</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={persona.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <RadioGroupCards className="mb-6 lg:w-3/4" label="Tipo de persona" value={type} onChange={setType} horizontal>
            <RadioGroupCards.Radio
              value="FISICA"
              description="Persona real."
            >
              Física
            </RadioGroupCards.Radio>
            <RadioGroupCards.Radio
              value="JURIDICA"
              description="Sociedad externa o sociedad unipersonal."
            >
              Jurídica
            </RadioGroupCards.Radio>
          </RadioGroupCards>

          {type === 'FISICA' &&
            <div className="mb-6">
              <Checkbox
                form={form}
                name="contribuidor"
                label="Es contribuidor"
                value={contribuidor}
                onChange={setContribuidor}
                className="mb-2"
              />
              <Checkbox
                form={form}
                name="notario"
                label="Es notario"
                value={notario}
                onChange={setNotario}
                className="mb-2"
              />
              <Checkbox
                form={form}
                name="socio"
                label="Es socio"
                value={persona.socio}
              />
            </div>
          }

          {type === 'FISICA' && contribuidor &&
            <PeopleGrid className="mb-6">
              <SingleEntity
                form={form}
                name="fisica.contribuidor.user"
                header="Asignar usuario"
                label="Usuario"
                validations="required"
                options={users}
                value={persona.fisica?.contribuidor?.user}
              ></SingleEntity>
            </PeopleGrid>
          }
          {type === 'JURIDICA' &&
            <Input
              form={form}
              className="mb-6"
              name="juridica.denominacionSocial"
              label="Denominación Social"
              validations="required"
              value={persona.juridica?.denominacionSocial}
            />
          }
            
          {type === 'FISICA' && !contribuidor &&
            <div className="flex gap-x-4 mb-6">
              <Input form={form} name="fisica.fisica.nombre" label="Nombre" validations="required" value={persona.fisica?.fisica?.nombre} className="flex-grow" />
              <Input form={form} name="fisica.fisica.apellidos" label="Apellidos" value={persona.fisica?.fisica?.apellidos} className="flex-grow"/>
            </div>
          }

          <div className="flex gap-x-4 mb-6">
            {type === 'FISICA' && contribuidor &&
              <Input
                className="w-16"
                form={form}
                name="fisica.contribuidor.acronimo"
                label="Acrónimo"
                value={persona.fisica?.contribuidor?.acronimo}
              />
            }
            <Acl perm="webinterna.personas.personal-data">
              <Select
                form={form}
                name="tipoIdentidad"
                validations="required"
                label="Tipo de identidad"
                options={[
                  { label: 'DNI', value: 'TIPO_IDENTIDAD_DNI' },
                  { label: 'CIF', value: 'TIPO_IDENTIDAD_CIF' },
                  { label: 'NIE', value: 'TIPO_IDENTIDAD_NIE' },
                  { label: 'Pasaporte', value: 'TIPO_IDENTIDAD_PASAPORTE' },
                  { label: 'Otro', value: 'TIPO_IDENTIDAD_OTRO' },
                ]}
                value={persona.tipoIdentidad}
                onChange={setTipoIdentidad}
              />
              {tipoIdentidad === 'TIPO_IDENTIDAD_DNI' &&
                <Input
                  form={form}
                  className="w-72"
                  name="identidad"
                  label="Identidad"
                  validations="required|dni"
                  value={persona.identidad}
                />
              }
              {tipoIdentidad !== 'TIPO_IDENTIDAD_DNI' &&
                <Input
                  form={form}
                  className="w-72"
                  name="identidad"
                  label="Identidad"
                  validations="required"
                  value={persona.identidad}
                />
              }
              {type === 'FISICA' && contribuidor &&
                <Datepicker
                  form={form}
                  name="fisica.contribuidor.nacimientoDate"
                  label="Fecha de nacimiento"
                  value={persona.fisica?.contribuidor?.nacimientoDate}
                />
              }
            </Acl>
          </div>

          <div className="mt-12">
            <SectionTitle fit>Datos de contacto</SectionTitle>
          </div>

          {type === 'JURIDICA' &&
            <Input
              form={form}
              className="mb-6"
              name="juridica.datosRegistrales"
              label="Datos Registrales"
              validations="required"
              value={persona.juridica?.datosRegistrales}
            />
          }
          
          {type === 'FISICA' && contribuidor &&
            <div className="flex gap-x-4 mb-6">
              <Input form={form} name="fisica.contribuidor.telefonoEmpresa" label="Teléfono de empresa" value={persona.fisica?.contribuidor?.telefonoEmpresa} />
              <Input form={form} className="w-32" name="fisica.contribuidor.extensionMovil" label="Extensión móvil" value={persona.fisica?.contribuidor?.extensionMovil} />
              <Input form={form} className="w-32" name="fisica.contribuidor.extensionOficina" label="Extensión oficina" value={persona.fisica?.contribuidor?.extensionOficina} />
            </div>
          }
          {type === 'JURIDICA' &&
            <div className="grid gap-4 grid-cols-2 mb-6">
              <Input form={form} name="juridica.email" label="Email" value={persona.juridica?.email} />
              <Input className="w-72" form={form} name="juridica.telefono" label="Teléfono" value={persona.juridica?.telefono} />
            </div>
          }
          {type === 'FISICA' && !contribuidor &&
            <div className="grid gap-4 grid-cols-2 mb-6">
              <Input form={form} name="fisica.fisica.email" label="Email" validations="required" value={persona.fisica?.fisica?.email} />
              <Input className="w-72" form={form} name="fisica.fisica.telefono" label="Teléfono" validations="required" value={persona.fisica?.fisica?.telefono} />
            </div>
          }
          
          {type === 'FISICA' && contribuidor &&
            <Acl perm="webinterna.personas.personal-data">
              <Input form={form} className="mb-6" name="fisica.contribuidor.telefonoPersonal" label="Teléfono personal" value={persona.fisica?.contribuidor?.telefonoPersonal} />
            </Acl>
          }
          <Acl perm="webinterna.personas.personal-data">
            <div className="flex gap-x-4 mb-6">
              <Input form={form} className="flex-grow" name="calle" label="Calle" value={persona.calle} />
              <Input form={form} className="w-24" name="numero" label="Número" value={persona.numero} />
            </div>
            <div className="flex gap-x-4 mb-6">
              <Locality form={form} name="municipio" label="Municipio" value={persona.municipio} />
              <Input form={form} className="w-24" name="codigoPostal" label="Código Postal" value={persona.codigoPostal} />
              <Input form={form} name="pais" className="flex-grow" label="País" value={persona.pais} />
            </div>
          </Acl>
          {type === 'FISICA' && contribuidor && <>
            <div className="mt-12">
              <SectionTitle fit>Información laboral</SectionTitle>
            </div>
            <div className="flex gap-x-4 mb-6">
              <Select
                form={form}
                name="fisica.contribuidor.centroTrabajo"
                validations="required"
                label="Centro de trabajo"
                options={centrosTrabajo}
                value={persona.fisica?.contribuidor?.centroTrabajo}
              />
              <Select
                form={form}
                name="fisica.contribuidor.sociedad"
                validations="required"
                label="Sociedad"
                options={sociedades}
                value={persona.fisica?.contribuidor?.sociedad}
              />
            </div>
            <HtmlEditor
              form={form}
              name="fisica.contribuidor.objetivosProfesionales"
              label="Objetivos profesionales"
              value={persona.fisica?.contribuidor?.objetivosProfesionales}
              className="mb-6 col-span-2"
            />
          </>
          }

          <div className="mt-12">
            <SectionTitle fit>Vigencia</SectionTitle>
          </div>
          <div className="flex gap-4">
            <Datepicker
              form={form}
              name="creacionTime"
              label="Fecha de alta"
              value={persona.creacionTime}
            />
            <Datepicker
              form={form}
              name="bajaTime"
              label="Fecha de baja"
              value={persona.bajaTime}
            />
          </div>
        </Card>

        <CardHeader
          className="mt-4"
          header="Persona de contacto"
        >
          {(!isEdit || persona.name) &&
            <Checkbox
              form={form}
              name="contacto"
              label="Persona de contacto distinta al titular"
              value={personaContacto}
              onChange={setPersonaContacto}
            />
          }
          {personaContacto &&
            <div className="grid gap-4 grid-cols-2 mt-4">
              <Input form={form} name="personaContacto.nombre" label="Nombre" value={persona.personaContacto?.nombre} />
              <Input form={form} name="personaContacto.apellidos" label="Apellidos" value={persona.personaContacto?.apellidos} />
              <Input form={form} name="personaContacto.email" label="Email" value={persona.personaContacto?.email} />
              <Input form={form} name="personaContacto.telefono" label="Teléfono" value={persona.personaContacto?.telefono} className="w-72" />
            </div>
          }
        </CardHeader>

        {contribuidor &&
          <CardHeader
            fit
            className="mt-4"
            header="Cargos Institucionales"
            subheader="Cargos Institucionales asociados al contribuidor."
          >
            <WideList divider="divide-y-4 divide-teal-400">
              {!cargosInstitucionales.length && <WideListEmpty>No hay cargos institucionales</WideListEmpty>}
              {cargosInstitucionales.map((cargo, index) => (
                <WideListInteractiveItem
                  key={cargo.ghostKey}
                  actions={
                    <div className="self-start">
                      <ButtonHoverDanger onClick={() => removeCargoInstitucional(cargo)}>
                        <Icon solid name="trash" />
                      </ButtonHoverDanger>
                    </div>
                  }
                >
                  <fieldset className="mb-6">
                    <legend className="text-base leading-6 font-medium text-gray-900">Cargo institucional {index + 1}</legend>
                  </fieldset>

                  <div className="flex gap-x-4 mb-4">
                    <Select
                      form={form}
                      name={`fisica.contribuidor.cargosInstitucionales[${index}].uen`}
                      label="UEN"
                      options={sociedades}
                      value={cargo.uen}
                    />
                    <Input
                      form={form}
                      name={`fisica.contribuidor.cargosInstitucionales[${index}].cargo`}
                      label="Cargo"
                      validations="required|min:3"
                      value={cargo.cargo}
                      className="flex-grow"
                    />
                    <Input
                      form={form}
                      name={`fisica.contribuidor.cargosInstitucionales[${index}].email`}
                      label="Email"
                      validations="required|min:3"
                      value={cargo.email}
                      className="flex-grow"
                    />
                  </div>
                </WideListInteractiveItem>
              ))}
              <WideListInteractiveItem>
                <ButtonSecondary onClick={addCargoInstitucional}>
                  <Icon solid name="plus" className="mr-2" />
                  Añadir Cargo Institucional
                </ButtonSecondary>
              </WideListInteractiveItem>
            </WideList>
          </CardHeader>
        }

        {!persona.blocked &&
          <Submit validation={validation} form={form}>Guardar persona</Submit>
        }
      </Form>
    </PageLayout>
  )
}
