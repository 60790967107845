
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import PlanesCuentasClient from 'services/WebInterna/planes_cuentas/planes_cuentas'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import Card from 'components/Card'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import ExternalLink from 'components/ExternalLink'
import CardTable from 'components/CardTable'
import { HeaderCell } from 'components/Datatable'
import { Cell, TableRow } from 'components/Table'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeOrange from 'components/badges/BadgeOrange'
import BadgeBlue from 'components/badges/BadgeBlue'
import BadgePurple from 'components/badges/BadgePurple'
import ExportCSV from 'components/ExportCSV'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import firstBy from 'thenby'
import { datetime } from 'platform/filters'
import { parseTimestamp } from 'platform/datetime'
import BadgeRed from 'components/badges/BadgeRed'


const planesCuentasClient = new PlanesCuentasClient(webinterna())

const PLANES_CUENTAS = {
  'TIPO_UNICA': 'Unica',
  'TIPO_RANGO': 'Rango',
  'TIPO_NORMAL': 'Normal',
}


export default function PlanesCuentasShow() {
  let { name } = useParams()
  let [plan, setPlan] = useState({})

  function formatPAs(pas) {
    if (!pas) {
      return null
    }
  
    return pas.map(pa => {

      return {
        'Plan_Cuentas': plan.name,
        'ID_PA': pa.name,
        'Agrupacion_n1': pa.agrupacion1,
        'Agrupacion_n2': pa.agrupacion2,
        'Agrupacion_n3': pa.agrupacion3,
        'Agrupacion_n4': pa.agrupacion4,
        'Denominacion_PA': pa.denominacion,
        'Descripcion de uso': pa.descripcion,
        'Tipo': PLANES_CUENTAS[pa.tipo],
        'Cuenta_Contable': pa.cuentaContable,
        'Observaciones': pa.observaciones,
        'Activa': pa.activa ? 'Si' : 'No',
        'PyG': pa.pyg ? 'Si' : 'No',
        'Comprobacion longitud': pa.comprobacionLongitud,
      }
    })
  }

  useNavigate(async () => {
    let plan = await planesCuentasClient.Get({ name })

    if (plan.predecesor) {
      plan.predecesor = await planesCuentasClient.Get({ name: plan.predecesor })
    }
    plan.creacionTime = parseTimestamp(plan.creacionTime)
    plan.inhabilitado = parseTimestamp(plan.inhabilitado)

    plan.partidasAnaliticas = plan.partidasAnaliticas?.sort(firstBy('agrupacion1').thenBy('agrupacion2').thenBy('agrupacion3').thenBy('agrupacion4'))

    setPlan(plan)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!plan.blocked &&
            <div className="flex gap-x-4">
              <Acl perm="webinterna.planes_cuentas.propose-edit">
                {!plan.inhabilitado && <ButtonLinkPrimary to={`/webinterna/planes-cuentas/edit/${name}`}>
                  <Icon solid name="pencil" className="mr-2" />
                  Editar Plan de Cuentas
                </ButtonLinkPrimary>}
                <ButtonLinkSecondary to={`/webinterna/planes-cuentas/evolve/${name}`}>
                  <Icon solid name="share" className="mr-2" />
                  Suceder Plan de Cuentas
                </ButtonLinkSecondary>
              </Acl>
              {plan.partidasAnaliticas?.length && <ExportCSV data={formatPAs(plan.partidasAnaliticas)} filename="PartidasAnaliticas"/>}
            </div>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/planes-cuentas">Contabilidad Analítica</Breadcrumb>
        <Breadcrumb url={`/webinterna/planes-cuentas/${name}`}>Consultar Plan de Cuentas</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={plan.blocked}></EntityBlockedAlert>

      <Card>
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ShowPane title="Nombre">{plan.denominacion}</ShowPane>
          <ShowPane title="Fecha de creación">{datetime(plan.creacionTime, 'DATETIME')}</ShowPane>
          <ShowPane title="Predecesor">
            {plan.predecesor && 
              <ExternalLink to={`/webinterna/planes-cuentas/${plan.predecesor.name}`}>
                {plan.predecesor?.denominacion}
              </ExternalLink>
            }
          </ShowPane>
          <ShowPane title="Inhabilitado">
            {plan.inhabilitado ? <BadgeRed>Inhabilitado</BadgeRed> : <BadgeGreen>Vigente</BadgeGreen>}
            {plan.inhabilitado && 
            <>
              &nbsp;&mdash;&nbsp;
              {datetime(plan.inhabilitado, 'DATETIME')}
            </>
            }
          </ShowPane>
          <ShowPane title="Sociedad">
            {plan.sociedad && <div>
              <PeopleViewer value={{ sociedad: plan.sociedad }} />
            </div>}
          </ShowPane>
        </div>
      </Card>

      <CardTable
        className="mt-8"
        title="Partidas Analíticas"
        empty="No hay partidas analíticas"
        header={
          <>
            <HeaderCell>Código</HeaderCell>
            <HeaderCell>Agrupación 1</HeaderCell>
            <HeaderCell>Agrupación 2</HeaderCell>
            <HeaderCell>Agrupación 3</HeaderCell>
            <HeaderCell>Agrupación 4</HeaderCell>
            <HeaderCell>Denominación</HeaderCell>
            <HeaderCell>Descripción</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Cuenta contable</HeaderCell>
            <HeaderCell>Observaciones</HeaderCell>
            <HeaderCell>Activa</HeaderCell>
            <HeaderCell>PyG</HeaderCell>
            <HeaderCell>Comparación longitud</HeaderCell>
          </>
        }
      >
        {plan.partidasAnaliticas && plan.partidasAnaliticas.map(pa => (
          <TableRow key={pa.tipo}>
            <Cell>{pa.name}</Cell>
            <Cell>{pa.agrupacion1}</Cell>
            <Cell>{pa.agrupacion2}</Cell>
            <Cell>{pa.agrupacion3}</Cell>
            <Cell>{pa.agrupacion4}</Cell>
            <Cell>{pa.denominacion}</Cell>
            <Cell>{pa.descripcion}</Cell>
            <Cell>
              {pa.tipo === 'TIPO_RANGO' && 'Rango'}
              {pa.tipo === 'TIPO_UNICA' && 'Única'}
              {pa.tipo === 'TIPO_NORMAL' && 'Normal'}
            </Cell>
            <Cell>{pa.cuentaContable || <BadgeOrange>Sin cuenta</BadgeOrange>}</Cell>
            <Cell>{!pa.cuentaContable ? pa.observaciones : ''}</Cell>
            <Cell>
              {pa.activa && <BadgeGreen>Activada</BadgeGreen>}
              {!pa.activa && <BadgeGray>Desactivada</BadgeGray>}  
            </Cell>
            <Cell>
              {!pa.cuentaContable && 
                  (pa.pyg ? <BadgePurple>Afecta a cuenta de resultados</BadgePurple> : <BadgeBlue>No afecta a cuenta de resultados</BadgeBlue>)
              }
            </Cell>
            <Cell>
              {pa.comprobacionLongitud}
            </Cell>
          </TableRow>
        ))}
          
      </CardTable>
    </PageLayout>
  )
}
