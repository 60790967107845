
import React from 'react'
import { useParams } from 'react-router-dom'

import { Update, useUpdate } from 'views/docs/upload/internal/Update'
import { listEntities } from 'models/docs/entities'
import { useNavigate } from 'hooks/navigate'
import { firstBy } from 'thenby'


export function UploadManagement() {
  let { code } = useParams()
  let { load, ...rest } = useUpdate()

  useNavigate(async () => {
    let revisions = await listEntities('revisions', `documents/${code}`)
    let request = revisions
      .sort(firstBy('createTime'))
      .find(rev => rev.managementReview)

    await load(request, {
      comment: request.managementReview.comment,
    })
  })

  return (
    <>
      <Update
        variant="management"
        description="Petición del responsable de gestión"
        {...rest}
      />
    </>
  )
}
