
import { useEffect, useCallback, useRef } from 'react'


export function useIfMounted() {
  let mounted = useRef(false)

  let checker = useCallback(fn => {
    if (mounted.current) {
      fn()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return checker
}
