
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import cx from 'classnames'
import BarLoader from 'react-spinners/BarLoader'

import Icon from 'components/Icon'
import ClickOutside from 'components/ClickOutside'
import MessagesClient from 'services/Notifications/messages/messages'
import { notifications } from 'services/discovery'
import { useInfinitePagination } from 'hooks/infinite-pagination'
import { useNavigate } from 'hooks/navigate'
import { datetime } from 'platform/filters'
import { parseTimestamp } from 'platform/datetime'


const client = new MessagesClient(notifications())


function NotificationsInternal({ className }) {
  let [showMenu, setShowMenu] = useState(false)
  let [totalUnread, setTotalUnread] = useState(0)
  let [markedAsRead, setMarkedAsRead] = useState({})
  
  let {
    nextPage,
    prevPage,
    hasPrevPage,
    hasNextPage,
    loading,
    reset,
    rows,
    initialLoad,
  } = useInfinitePagination(async function ({ pageToken, pageSize }) {
    let reply = await client.List({
      pageSize,
      pageToken,
    })
    setTotalUnread(reply.totalUnread)
    return {
      ...reply,
      rows: reply.messages,
    }
  }, { pageSize: 6 })
  
  let timeout = useRef(null)
  useNavigate(() => {
    initialLoad()
    timeout.current = setTimeout(reset, 10000)
  })

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  async function markAsRead(e, name) {
    // Stop the click event before reaching the ClickOutside, cause the
    // button no longer exists and it will close the dropdown.
    e.nativeEvent.stopImmediatePropagation()

    setTotalUnread(--totalUnread)
    setMarkedAsRead({
      ...markedAsRead,
      [name]: true,
    })
    await client.MarkAsRead({ name })
  }

  return (
    <ClickOutside onClick={() => setShowMenu(false)}>
      <div className="ml-4 relative flex-shrink-0">
        <button
          type="button"
          className={cx(className, 'p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none')}
          onClick={() => {
            if (!showMenu) {
              reset()
              setMarkedAsRead({})
            }
            setShowMenu(!showMenu)
          }}
        >
          <Icon regular name="bell" className="fa-2x w-6 h-6"></Icon>
          {totalUnread > 0 &&
            <span className="absolute top-0 right-0 block h-5 w-5 transform -translate-y-1 translate-x-1 rounded-full bg-red-400">
              <span className="text-white text-xs font-black relative -top-1">{totalUnread}</span>
            </span>
          }
        </button>
        <Transition
          show={showMenu}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          className="origin-top-right absolute right-0 w-120 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50 overflow-hidden"
        >
          {loading &&
            <div className="absolute top-0 right-0 left-0 h-4">
              <BarLoader color="#047481" width="100%" />
            </div>
          }
          {rows.length === 0 && !loading &&
            <div className="py-6 px-4 border-gray-200 border-b-2 w-full text-center text-xl italic text-gray-400">
              No tienes notificaciones.
            </div>
          }
          {rows.map(notification => (
            <div
              key={notification.name}
              className={cx(
                'flex flex-col border-gray-200 border-b-2',
                {
                  'bg-gray-100': notification.read || markedAsRead[notification.name],
                },
              )}
            >
              <div className="flex">
                <div className="pt-2 px-4 pb-1 w-full">
                  <Link
                    to={notification.link}
                    className={cx(
                      'text-left focus:outline-none flex-1',
                      {
                        'cursor-default': !notification.link,
                        'font-semibold text-gray-900 hover:text-gray-900': !notification.read && !markedAsRead[notification.name],
                        'text-gray-500 hover:text-gray-500 font-normal': notification.read || markedAsRead[notification.name],
                      },
                    )}
                  >
                    {notification.text}
                  </Link>
                </div>
                <div>
                  {!notification.read && !markedAsRead[notification.name] &&
                    <button title="Marcar como leída" className="flex items-center hover:bg-gray-100 p-2 rounded text-sm w-auto m-2" onClick={event => markAsRead(event, notification.name)}>
                      <Icon regular name="eye-slash" className="h-3 w-3 text-gray-700"></Icon>
                    </button>
                  }
                </div>
              </div>
              <div className="flex flex-row-reverse justify-between px-4 py-2 w-full items-end">
                <div className="text-right text-gray-500 text-sm">
                  {datetime(parseTimestamp(notification.createTime), 'DATETIME_SHORT')}
                </div>
                {notification.actionButton &&
                  <a href={notification.actionButton.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cx(
                      'inline-block max-w-min px-2.5 py-1 border text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-nowrap',
                      {
                        'text-gray-700 bg-white hover:bg-gray-100 focus:ring-teal-500 hover:text-gray-700 border-gray-300': notification.read || markedAsRead[notification.name],
                        'text-white bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 hover:text-white border-transparent': !notification.read && !markedAsRead[notification.name],
                      },
                    )}
                  >
                    {notification.actionButton.text}
                  </a>
                }
              </div>
            </div>
          ))}
          <div className="flex justify-end py-2 pr-4">
            <button
              type="button"
              className={cx(
                'relative inline-flex items-center px-2 py-2 rounded-l-md border-l border-t border-b border-gray-300 text-sm font-medium',
                {
                  'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasPrevPage,
                  'bg-gray-100 cursor-default text-gray-300': !hasPrevPage,
                },
              )}
              title="Página anterior"
              onClick={prevPage}
              disabled={!hasPrevPage || loading}
            >
              <Icon regular name="chevron-left" className="mx-2" />
            </button>
            <button
              type="button"
              className={cx(
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium',
                {
                  'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasNextPage,
                  'bg-gray-100 cursor-default text-gray-300': !hasNextPage,
                },
              )}
              title="Siguiente página"
              onClick={nextPage}
              disabled={!hasNextPage || loading}
            >
              <Icon regular name="chevron-right" className="mx-2" />
            </button>
          </div>
        </Transition>
      </div>
    </ClickOutside>
  )
}


export function NotificationsInvert() {
  return (
    <NotificationsInternal className="border-2 border-transparent focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out" />
  )
}


export function Notifications() {
  return (
    <NotificationsInternal className="ml-auto flex-shrink-0 bg-white focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" />
  )
}
