
import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { cloneDeep } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import BadgeGreen from 'components/badges/BadgeGreen'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import CardTable from 'components/CardTable'
import { getEntity, putEntity } from 'models/docs/entities'
import { getFieldTypeText } from 'models/docs/document-types'


export default function RecordFamiliesEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [recordFamily, setRecordFamily] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await getEntity(`record-families/${name}`)
      setForm(reply)
      setRecordFamily(reply)
    }
  })

  async function save() {
    let req = await putEntity('record-families', { recordFamily: cloneDeep(form) })
    if (!name) {
      history.push(`/manage/${req.name}`)
      return
    }
    setRecordFamily(req)
    setForm(req)
  }

  function editField(idx) {
    history.push(`/manage/record-families/${name}/fields/${idx}`)
  }

  let changed = recordFamily.displayName !== form.displayName

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/record-families">Familias de expedientes</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/record-families/${name}`}>{recordFamily.displayName}</Breadcrumb>
            :
            <Breadcrumb url="/manage/record-families/create">Crear familia de expedientes</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos generales"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <Input
          label="Nombre"
          value={form.displayName}
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        ></Input>
      </CardHeader>

      {name &&
        <CardTable
          className="mt-6"
          title="Campos de clasificación"
          subtitle="Campos que se usan para clasificar un expediente."
          empty="No hay campos asociados a la familia"
          header={
            <>
              <HeaderCell>Nombre</HeaderCell>
              <HeaderCell>Tipo</HeaderCell>
              <HeaderCell></HeaderCell>
            </>
          }
          actions={
            <ButtonLinkPrimary to={`/manage/record-families/${name}/fields/add`}>
              <Icon solid name="plus" className="mr-2" />
              Añadir campo
            </ButtonLinkPrimary>
          }
        >
          {form.fields?.map((field, idx) => (
            <TableRow key={idx}>
              <Cell className="w-1/2">
                <Link to="#" onClick={() => editField(idx)} className="text-gray-900">{field.displayName}</Link>
                {field.required && <BadgeGreen className="ml-2">Obligatorio</BadgeGreen>}
              </Cell>
              <Cell className="w-1/2">
                {getFieldTypeText(field)}
              </Cell>
              <Cell>
                <ButtonSecondary onClick={() => editField(idx)}>
                  <Icon solid name="pencil" />
                </ButtonSecondary>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
      }
    </PageLayout>
  )
}
