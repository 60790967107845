
import React from 'react'
import firstBy from 'thenby'
import { Link, useLocation } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import BadgeGray from 'components/badges/BadgeGray'
import BadgePurple from 'components/badges/BadgePurple'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeOrange from 'components/badges/BadgeOrange'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import ExportCSV from 'components/ExportCSV'
import { parseDate } from 'platform/datetime'
import { datetime, percentage } from 'platform/filters'
import PeopleViewer from 'components/people/PeopleViewer'
import { prepareSearchPersonasSociedades, searchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())

const STATUS = {
  ESTADO_PROYECTO: 'Proyecto',
  ESTADO_ACTIVO: 'Activo',
  ESTADO_TERMINADO: 'Terminado',
  ESTADO_ENAJENADO: 'Enajenado',
}

const CLASES = {
  CLASE_RUSTICO: 'Rústico',
  CLASE_URBANIZABLE: 'Urbanizable',
  CLASE_URBANO: 'Urbano',
  CLASE_EDIFICACION: 'Edificación',
}

const USOS_URBANISTICOS = {
  USO_URBANISTICO_RESIDENCIAL: 'Residencial',
  USO_URBANISTICO_COMERCIAL: 'Comercial',
  USO_URBANISTICO_RESIDENCIAL_COMERCIAL: 'Residencial y Comercial',
  USO_URBANISTICO_INDUSTRIAL: 'Industrial',
  USO_URBANISTICO_LOGISTICO: 'Logístico',
  USO_URBANISTICO_HOTELERO: 'Hotelero',
  USO_URBANISTICO_OFICINAS: 'Oficinas',
  USO_URBANISTICO_INSTALACIONES_AGRICOLAS: 'Instalaciones agrícolas',
  USO_URBANISTICO_OTROS_USOS: 'Otros usos',
}


export default function InmueblesList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  let location = useLocation()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('nombre'),
      },
      {
        name: 'Por código',
        sort: firstBy('codigo').thenBy('nombre'),
      },
    ])

    let { inmuebles } = await inmueblesClient.List()
    if (!inmuebles) {
      setRows([])
      return
    }

    if (location.search) {
      let params = new URLSearchParams(location.search)
      if (params.get('finca-registral')) {
        let fincaRegistral = await fincasRegistralesClient.Get({ name: params.get('finca-registral') })
        inmuebles = inmuebles.filter(inmueble => fincaRegistral.inmueble === inmueble.name)
      }
      if (params.get('elemento-patrimonial')) {
        let elementoPatrimonial = await elementosPatrimonialesClient.Get({ name: params.get('elemento-patrimonial') })
        let fincaRegistral = await fincasRegistralesClient.Get({ name: elementoPatrimonial.fincaRegistral })
        inmuebles = inmuebles.filter(inmueble => fincaRegistral.inmueble === inmueble.name)
      }
      if (params.get('finca-catastral')) {
        let fincaCatastral = await fincasCatastralesClient.Get({ name: params.get('finca-catastral') })
        let fincaRegistral = await fincasRegistralesClient.Get({ name: fincaCatastral.fincaRegistral })
        inmuebles = inmuebles.filter(inmueble => fincaRegistral.inmueble === inmueble.name)
      }
    }

    let names = inmuebles
      .filter(inmueble => inmueble.municipio)
      .map(inmueble => inmueble.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(names) })

    names = municipios.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(names) })

    names = inmuebles
      .filter(inmueble => inmueble.grupo)
      .map(inmueble => inmueble.grupo)
    let { grupos } = await gruposInmobiliariosClient.BatchGet({ names: uniq(names) })

    names = inmuebles
      .filter(inmueble => inmueble.titular)
      .map(inmueble => inmueble.titular)
    let titularesSearch = await prepareSearchPersonasSociedades(names)

    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    grupos = keyBy(grupos, 'name')

    inmuebles.forEach(inmueble => {
      inmueble.municipio = municipios[inmueble.municipio]
      inmueble.provincia = provincias[inmueble.municipio?.provincia]
      inmueble.grupo = grupos[inmueble.grupo]
      inmueble.$search = [
        inmueble.codigo,
        inmueble.nombre,
        inmueble.municipio?.nombre,
        inmueble.provincia?.nombre,
        STATUS[inmueble.estado],
        ...searchPersonasSociedades(titularesSearch, inmueble.titular),
        CLASES[inmueble.clase],
        USOS_URBANISTICOS[inmueble.usoUrbanistico],
        inmueble.grupo?.nombre,
      ]
    })
    setRows(inmuebles)
  })

  function getInmueblesCSV(rows) {
    return rows.map(p => {
      return {
        'Código': p.codigo,
        'Denominación': p.nombre,
        'Provincia': p.provincia?.nombre,
        'Municipio': p.municipio?.nombre,
        'Grupo inmobiliario': p.grupo?.nombre,
        'Titular': p.titular?.nombre,
        'Identidad titular': p.titular?.identidad,
        'Clase': CLASES[p.clase],
        'Uso urbanístico': USOS_URBANISTICOS[p.usoUrbanistico],
        'Estado obras': STATUS[p.estado],
        'Alta jurídica': datetime(parseDate(p.altaJuridicaDate), 'DATE'),
        'Baja jurídica': datetime(parseDate(p.bajaJuridicaDate), 'DATE'),
        'Fecha de alta': datetime(parseDate(p.altaDate), 'DATE'),
        'Fecha de baja': datetime(parseDate(p.bajaDate), 'DATE'),
        'Coordenadas': p.coordenadas,
        'Estado junta compensación': p.estadoJuntaCompensacion === 'ESTADO_JUNTA_COMPENSACION_NO_CONSTITUIDA' ? 'No constituida' :  'Constituida y liquidada',
        'Voto': percentage(p.derechoVotoPercentage),
        'Participación': percentage(p.participacionPercentage),
        'Costes': percentage(p.participacionCostesPercentage),
        'Cuadro superficies': p.cuadroSuperficies,
        'Planimetría': p.planimetria,
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.inmuebles.propose-edit">
            <ButtonLinkPrimary to="/webinterna/inmuebles/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Unidad Inmobiliaria
            </ButtonLinkPrimary>
          </Acl>
          <ExportCSV
            className="ml-3"
            data={getInmueblesCSV(table.filteredRows)}
            filename="Inmuebles"
          />
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/inmuebles">Unidades Inmobiliarias</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell fit="right">Código</HeaderCell>
            <HeaderCell>Denominación</HeaderCell>
            <HeaderCell>Grupo Inmobiliario</HeaderCell>
            <HeaderCell>Titular</HeaderCell>
            <HeaderCell>Clase</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/inmuebles/${row.name}`}>
            <div>
              {row.codigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.nombre}</span>
              <span className="ml-2">
                {row.estado === 'ESTADO_PROYECTO' && <BadgePurple>{STATUS[row.estado]}</BadgePurple>}
                {row.estado === 'ESTADO_ACTIVO' && <BadgeGreen>{STATUS[row.estado]}</BadgeGreen>}
                {row.estado === 'ESTADO_TERMINADO' && <BadgeGray>{STATUS[row.estado]}</BadgeGray>}
                {row.estado === 'ESTADO_ENAJENADO' && <BadgeOrange>{STATUS[row.estado]}</BadgeOrange>}
              </span>
            </div>
            <div className="mt-1">
              <Icon regular name="building" className="mr-3 text-gray-400" />
              {row.municipio?.nombre} &mdash; {row.provincia?.nombre}
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell className="font-medium">
              {row.codigo}
            </Cell>
            <Cell>
              <Link
                to={`/webinterna/inmuebles/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.nombre}
              </Link>
              <div>
                {row.provincia?.nombre}
                &nbsp;&mdash;&nbsp;
                {row.municipio?.nombre}
              </div>
            </Cell>
            <Cell>
              {row.grupo?.nombre}
            </Cell>
            <Cell>
              {row.titular && <PeopleViewer value={row.titular} className="w-56"/>}
            </Cell>
            <Cell>
              <div>
                {CLASES[row.clase]}
                &nbsp;&mdash;&nbsp;
                {row.codigo.slice(0, 1)}
              </div>
              <div>
                {USOS_URBANISTICOS[row.usoUrbanistico]}
              </div>
            </Cell>
            <Cell>
              {row.estado === 'ESTADO_PROYECTO' && <BadgePurple>{STATUS[row.estado]}</BadgePurple>}
              {row.estado === 'ESTADO_ACTIVO' && <BadgeGreen>{STATUS[row.estado]}</BadgeGreen>}
              {row.estado === 'ESTADO_TERMINADO' && <BadgeGray>{STATUS[row.estado]}</BadgeGray>}
              {row.estado === 'ESTADO_ENAJENADO' && <BadgeOrange>{STATUS[row.estado]}</BadgeOrange>}
            </Cell>
            <Cell>
              <Acl perm="webinterna.inmuebles.propose-edit">
                <Link to={`/webinterna/inmuebles/edit/${row.name}`}>Editar</Link>
              </Acl>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
