
import React, { useState, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { keyBy, debounce } from 'lodash-es'
import BarLoader from 'react-spinners/BarLoader'

import { listEntities, runQueryPagination } from 'models/docs/entities'
import { Table, HeaderCell, TableRow, Cell } from 'components/Table'
import Icon from 'components/Icon'
import { Dropdown, DropdownMenuItem } from 'components/Dropdown'
import { docs } from 'services/discovery'
import AccessClient from 'services/Docs/access/access'
import * as query from 'models/docs/query'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import { useInfinitePagination } from 'hooks/infinite-pagination'
import { useNavigate } from 'hooks/navigate'
import Selector from 'components/simple-forms/Selector'
import { FullscreenLayout } from 'layout/expedientes/FullscreenLayout'
import Datepicker from 'components/Datepicker'
import Throbber from 'components/Throbber'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'


const accessClient = new AccessClient(docs())
const SORT_OPTIONS = [
  { label: 'Fecha', name: 'createTime', direction: 'desc' },
  { label: 'Título', name: 'title', direction: 'asc' },
]


export default function Dashboard() {
  let [recordFamilies, setRecordFamilies] = useState([])
  let [recordFamiliesOptions, setRecordFamiliesOptions] = useState([])
  let [loading, setLoading] = useState(true)

  let [filterFree, setFilterFree] = useState('')
  let [filterRecordFamily, setFilterRecordFamily] = useState(null)
  let [filterDate, setFilterDate] = useState(null)

  let [sort, setSort] = useState(SORT_OPTIONS[0])

  let rootElem = useRef(null)
  let {
    prevPage,
    nextPage,
    rows,
    initialLoad,
    reset,
    hasPrevPage,
    hasNextPage,
  } = useInfinitePagination(async function ({ pageToken, pageSize }) {
    let access = await accessClient.ListRecords()

    if (!access.records) {
      setLoading(false)
      return {
        rows: [],
      }
    } else {
      let filters = [query.andIn('name', access.records)]
      if (filterRecordFamily) {
        filters.push(query.text('payload.record.recordFamily', filterRecordFamily))
      }
      
      if (filterFree) {
        filters.push(query.text('payload.record.title', filterFree))
      }
  
      if (filterDate) {
        let max = new Date(filterDate)
        max.setDate(filterDate.getDate() + 1)
        filters.push(query.range('payload.record.createTime', filterDate, max))
      }
      
      let reply = await runQueryPagination('records', {
        pageToken,
        pageSize,
        query: query.build(...filters),
        sort: query.sort(`payload.record.${sort.name}`, sort.direction),
      })
  
      let indexRecordFamilies = keyBy(recordFamilies, 'name')
      setLoading(false)
  
      return {
        ...reply,
        rows: reply.entities.map(rec => {
          return {
            ...rec,
            createTime: parseTimestamp(rec.createTime),
            recordFamily: indexRecordFamilies[rec.recordFamily],
          }
        }),
      }
    }
  }, {
    rootElem,
  })

  useNavigate(async () => {
    let families = await listEntities('record-families')
    setRecordFamilies(families)
    setRecordFamiliesOptions(families.map(family => {
      return {
        value: family.name,
        label: family.displayName,
      }
    }))
    initialLoad()
  })

  let debouncedReset = useCallback(debounce(reset, 300), [])
  return (
    <FullscreenLayout>
      <div className="mt-6 flex" ref={rootElem}>
        <div className="w-full">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Icon solid name="search" className="text-gray-400" />
            </div>
            <input
              placeholder="Filtrar expedientes..."
              className="pl-10 form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={e => {
                setFilterFree(e.target.value.trim())
                debouncedReset()
                setLoading(true)
              }}
            />
          </div>
        </div>
        <Selector
          className="w-140 ml-4 -mt-1"
          options={recordFamiliesOptions}
          value={filterRecordFamily}
          placeholder="Filtrar por familia"
          onChange={value => {
            setFilterRecordFamily(value)
            debouncedReset()
            setLoading(true)
          }}
        />
        <Datepicker
          className="w-120 ml-4"
          placeholder="Filtrar por fecha"
          value={filterDate}
          onChange={value => {
            setFilterDate(value[0])
            debouncedReset()
            setLoading(true)
          }}
        ></Datepicker>
        <Dropdown
          className="w-80 ml-32 text-right flex flex-col justify-end"
          trigger={
            <button
              type="button"
              className="inline-block rounded-md px-4 py-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-500 -mb-1 whitespace-nowrap"
            >
              <Icon regular name="sort-alpha-down" className="mr-2 text-gray-500" />
              <span className="hidden md:inline">
                {sort.label}
              </span>
              <Icon solid name="chevron-down" className="md:ml-2 -mr-1" />
            </button>
          }
        >
          {SORT_OPTIONS.map(option => (
            <DropdownMenuItem
              key={option.name}
              onClick={() => {
                setSort(option)
                debouncedReset()
                setLoading(true)
              }}
              className={cx(
                {
                  'font-bold': option.name === sort.name,
                },
              )}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </Dropdown>
      </div>

      <Table
        className="mt-4"
        empty="No hay expedientes que cumplan con los filtros."
        header={
          <>
            <HeaderCell className="w-full">Título</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell>Familia</HeaderCell>
            <HeaderCell>Fecha de creación</HeaderCell>
          </>
        }
      >
        {loading &&
          <tr className="bg-gray-50">
            <td
              colSpan="7"
              className={cx(
                'p-0 align-top relative',
                { 'h-64': !rows.length },
              )}
            >
              <div className="absolute t-0 w-full">
                <BarLoader color="#047481" width="100%" />
              </div>
            </td>
          </tr>
        }
        {rows.map(rec => (
          <TableRow key={rec.name}>
            <Cell>
              <Link to={`/${rec.name}`} className="font-medium text-gray-900">{rec.title}</Link>
            </Cell>
            <Cell>
              {rec.status === 'STATUS_OPEN' && <BadgeGreen>Abierto</BadgeGreen>}
              {rec.status === 'STATUS_FINISHED' && <BadgeGray>Finalizado</BadgeGray>}
            </Cell>
            <Cell>{rec.recordFamily?.displayName}</Cell>
            <Cell>{datetime(rec.createTime, 'DATETIME')}</Cell>
          </TableRow>
        ))}
      </Table>
      <div className="mt-3 flex justify-end">
        <nav className="relative z-0 inline-flex shadow-sm -space-x-px">
          <button
            type="button"
            className={cx(
              'relative inline-flex items-center px-2 py-2 rounded-l-md border-l border-t border-b border-gray-300 text-sm font-medium',
              {
                'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasPrevPage,
                'bg-gray-100 cursor-default text-gray-300': !hasPrevPage,
              },
            )}
            title="Página anterior"
            onClick={prevPage}
            disabled={!hasPrevPage}
          >
            <Icon regular name="chevron-left" className="mx-2" />
          </button>
          {loading &&
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-xs font-medium bg-gray-100 cursor-default"
              disabled
            >
              <Throbber />
            </button>
          }
          {!loading &&
            <button
              type="button"
              className={cx(
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium',
                {
                  'hover:bg-gray-50 bg-white focus:outline-none text-gray-500': hasNextPage,
                  'bg-gray-100 cursor-default text-gray-300': !hasNextPage,
                },
              )}
              title="Siguiente página"
              onClick={nextPage}
              disabled={!hasNextPage}
            >
              <Icon regular name="chevron-right" className="mx-2" />
            </button>
          }
        </nav>
      </div>
    </FullscreenLayout>
  )
}
