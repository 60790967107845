
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import ContratosClient from 'services/WebInterna/contratos/contratos'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import { parseDate, serializeDate } from 'platform/datetime'
import resolver from 'platform/resolver'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Icon from 'components/Icon'
import Input from 'components/forms/Input'
import Price from 'components/forms/Price'
import Datepicker from 'components/forms/Datepicker'
import Select from 'components/forms/Select'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import HtmlEditor from 'components/forms/HtmlEditor'
import Duration from 'components/forms/Duration'
import SingleEntity from 'components/forms/SingleEntity'
import { PeopleGrid } from 'components/people'
import PageLayout from 'layout/webinterna/PageLayout'
import People from 'components/forms/People'
import { useNavigate } from 'hooks/navigate'


const contratosClient = new ContratosClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())

const TIPO_IMPORTE_OPTIONS = [
  { value: 'TIPO_IMPORTE_FIJO', label: 'Importe Fijo' },
  { value: 'TIPO_IMPORTE_FIJO_VARIABLE', label: 'Importe Fijo + Variable' },
  { value: 'TIPO_IMPORTE_VARIABLE', label: 'Importe Variable' },
  { value: 'TIPO_IMPORTE_FIJO_REVISABLE', label: 'Importe Fijo Revisable' },
]

const TIPO_PAGO_OPTIONS = [
  { value: 'TIPO_PAGO_ANTICIPADO', label: 'Pago Anticipado' },
  { value: 'TIPO_PAGO_A_TERMINO', label: 'Pago A Termino' },
  { value: 'TIPO_PAGO_CUOTA_REGULAR', label: 'Cuota Regular' },
  { value: 'TIPO_PAGO_CALENDARIO_ESPECIFICO', label: 'Calendario Específico' },
  { value: 'TIPO_PAGO_CERTIFICACION', label: 'Certificación' },
]

const PERIODO_PAGO_OPTIONS = [
  { value: 'PERIODO_PAGO_MENSUAL', label: 'Mensual' },
  { value: 'PERIODO_PAGO_TRIMESTRAL', label: 'Trimestral' },
  { value: 'PERIODO_PAGO_SEMESTRAL', label: 'Semestral' },
  { value: 'PERIODO_PAGO_ANUAL', label: 'Anual' },
]

const PROGRAMACION_OPTIONS = [
  { value: 'PROGRAMACION_PUNTUAL', label: 'Puntual' },
  { value: 'PROGRAMACION_PRORROGABLE', label: 'Prorrogable' },
  { value: 'PROGRAMACION_TERMINO_CIERTO', label: 'Término Cierto' },
]


function useContrato({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [contrato, setContrato] = useState({})
  let [validation, setValidation] = useState(null)

  async function parse(contrato) {
    contrato.objetivoFinalizacionDate = parseDate(contrato.objetivoFinalizacionDate)
  }

  async function load() {
    if (!isEdit) {
      return
    }

    let contrato = await contratosClient.Get({ name })
    await parse(contrato)
    setContrato(contrato)
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    await parse(reply.entidad.contrato)
    setContrato(reply.entidad.contrato)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        contrato: req,
      },
    })
    notifySuccess('Guardado en contrato', 'Se ha pedido el guardado de este contrato para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/contratos/${name}`)
    } else {
      history.push(`/webinterna/contratos`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await contratosClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await contratosClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await contratosClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        contrato,
      },
    })

    notifySuccess('Eliminación en contrato', 'Se ha pedido la eliminación completa de este contrato para que lo apruebe un administrador.')
    history.push(`/webinterna/contratos`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    contrato,
    validation,
    remove,
  }
}


export default function ContratosEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [tipoPago, setTipoPago] = useState('')
  let [proyectos, setProyectos] = useState(null)
  let [inmuebles, setInmuebles] = useState(null)
  let [sociedades, setSociedades] = useState(null)

  let {
    save: saveContrato,
    load: loadContrato,
    loadValidation,
    confirmValidation,
    contrato,
    validation,
    remove: removeContrato,
  } = useContrato({ name })

  useNavigate(async () => {
    let { proyectos, inmuebles, sociedades } = await resolver.all({
      proyectos: proyectosClient.List().then(reply => reply.proyectos || []),
      inmuebles: inmueblesClient.List().then(reply => reply.inmuebles || []),
      sociedades: sociedadesClient.List().then(reply => reply.sociedades || []),
    })

    proyectos = proyectos.map(proyecto => {
      return {
        value: proyecto.name,
        url: `/webinterna/proyectos/${proyecto.name}`,
        label: proyecto.denominacion,
        sublabel: proyecto.codigo,
      }
    })
    setProyectos(proyectos)

    inmuebles = inmuebles.map(inmueble => {
      return {
        value: inmueble.name,
        url: `/webinterna/inmuebles/${inmueble.name}`,
        label: inmueble.denominacion,
        sublabel: inmueble.codigo,
      }
    })
    setInmuebles(inmuebles)

    sociedades = sociedades.map(sociedad => {
      return {
        value: sociedad.name,
        url: `/webinterna/sociedades/${sociedad.name}`,
        label: sociedad.denominacion,
        sublabel: sociedad.acronimo,
      }
    })
    setSociedades(sociedades)

    if (isValidation) {
      await loadValidation()
    } else {
      await loadContrato()
    }
    setTipoPago(contrato.tipoPago)
  })

  let form = useForm(async (data) => {
    let req = {
      ...contrato,
      ...data,
      objetivoFinalizacionDate: serializeDate(data.objetivoFinalizacionDate),
    }
    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveContrato(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !contrato.blocked &&
            <ButtonConfirmDanger onClick={removeContrato}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Contrato
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/contratos">Contratos</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/contratos/${name}`}>Consultar Contrato</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/contratos/edit/${name}`}>Editar Contrato</Breadcrumb>
          : <Breadcrumb url="/webinterna/contratos/edit/new">Crear Nuevo Contrato</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={contrato.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <Input form={form} name="codigo" label="Código" validations="required" value={contrato.codigo} className="w-24 mb-6" />

            <PeopleGrid className="mb-6">
              <People
                form={form}
                name="cliente"
                label="Cliente"
                value={contrato.cliente}
              />
            </PeopleGrid>

            <PeopleGrid className="mb-6">
              <SingleEntity
                form={form}
                name="proveedor"
                label="Proveedor"
                header="Seleccionar proveedor"
                options={sociedades}
                value={contrato.proveedor}
              />

              <SingleEntity
                form={form}
                name="proyecto"
                label="Proyecto"
                header="Seleccionar proyecto"
                subheader="Proyecto asociado a la entidad que estás editando."
                options={proyectos}
                value={contrato.proyecto}
              />

              <SingleEntity
                form={form}
                name="inmueble"
                label="Unidad Inmobiliaria"
                header="Seleccionar unidad inmobiliaria"
                subheader="Unidad inmobiliaria asociada a la entidad que estás editando."
                options={inmuebles}
                value={contrato.inmueble}
              />
            </PeopleGrid>

            <HtmlEditor form={form} name="descripcion" label="Descripción" value={contrato.descripcion} className="mb-6" />

            <div className="md:flex md:space-x-4">
              <Price form={form} name="precio" label="Precio" value={contrato.precio} className="mb-6" />
              <Select
                form={form}
                className="mb-6"
                name="tipoImporte"
                label="Tipo Importe"
                options={TIPO_IMPORTE_OPTIONS}
                value={contrato.tipoImporte}
              />
              <Select
                form={form}
                name="tipoPago"
                className="mb-6"
                label="Tipo Pago"
                options={TIPO_PAGO_OPTIONS}
                value={contrato.tipoPago}
                onChange={event => setTipoPago(event)}
              />
              {tipoPago === 'TIPO_PAGO_CUOTA_REGULAR' &&
                <Select
                  form={form}
                  className="mb-6"
                  name="periodoPago"
                  label="Periodo Pago"
                  options={PERIODO_PAGO_OPTIONS}
                  value={contrato.periodoPago}
                />
              }
            </div>

            <div className="md:flex md:space-x-4">
              <Select
                form={form}
                className="mb-6"
                name="programacion"
                label="Programación"
                options={PROGRAMACION_OPTIONS}
                value={contrato.programacion}
              />
              <Datepicker form={form} name="objetivoFinalizacionDate" label="Fecha Objetivo Finalización" value={contrato.objetivoFinalizacionDate} className="mb-6" />
              <Duration form={form} name="duracion" label="Duración" value={contrato.duracion} className="mb-6" />
            </div>
          </fieldset>
        </Card>
        {!contrato.blocked &&
          <Submit validation={validation} form={form}>Guardar Contrato</Submit>
        }
      </Form>
    </PageLayout>
  )
}
