
import React from 'react'

import { ReactComponent as Icon } from './icons/SharePointIcon.svg'

export function SharePointIcon({ className }) {
  return (
    <Icon className={className}></Icon>
  )
}
