
import React from 'react'
import Icon from './Icon'


export default function Throbber() {
  return (
    <div className="flex flex-col space-y-6 items-center justify-center">
      <Icon duotone spin name="spinner-third" className="fa-2x text-teal-300"></Icon>
    </div>
  )
}
