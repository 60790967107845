
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { cloneDeep } from 'lodash-es'

import Icon from 'components/Icon'
import PageLayout from 'layout/docs/PageLayout'
import { useNavigate } from 'hooks/navigate'
import { getEntity, batchGetEntities, putEntity } from 'models/docs/entities'
import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { LEVELS } from 'models/docs/access-groups'
import Face from 'components/people/Face'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import { serializeTimestamp } from 'platform/datetime'
import PeopleViewer from 'components/people/PeopleViewer'


const client = new UsersClient(users())


function InfoPane({ className, children, title }) {
  return (
    <dl className={className}>
      <dt className="font-medium text-gray-400 text-sm">{title}</dt>
      <dd className="mt-1 text-gray-900">{children}</dd>
    </dl>
  )
}


export default function ManageAccessInvitation() {
  let { code } = useParams()

  let [invitation, setInvitation] = useState(null)

  let history = useHistory()

  useNavigate(async () => {
    let invitation = await getEntity(`access-invitations/${code}`)
    
    // TODO(alberto): Comprobar que el usuario que accede aqui es el gestor de LOPD del propietario.

    let entities = await batchGetEntities([invitation.accessGroup, invitation.owner])
    invitation.accessGroup = entities[0]
    invitation.owner = entities[1]
    invitation.user = await client.Get({ name: invitation.user })
    invitation.accessGroup.level = LEVELS.find(level => level.value === invitation.accessGroup.level)
    setInvitation(invitation)
  })

  async function saveInvitation(authorization) {
    let accessInvitation = cloneDeep(invitation)
    accessInvitation.accessGroup = accessInvitation.accessGroup.name
    accessInvitation.owner = accessInvitation.owner.name
    accessInvitation.user = accessInvitation.user.name
    accessInvitation.authorization = authorization
    accessInvitation.authorizationTime = serializeTimestamp(new Date())
    await putEntity('access-invitations', { accessInvitation })
    
    if (authorization === 'AUTHORIZATION_DENY') {
      let accessGroup = await getEntity(accessInvitation.accessGroup)
      accessGroup.pendingUsers.splice(accessGroup.pendingUsers.indexOf(accessInvitation.user))
      await putEntity('access-groups', { accessGroup })
    } else {
      let accessGroup = await getEntity(accessInvitation.accessGroup)
      if (!accessGroup.users) {
        accessGroup.users = []
      }
      accessGroup.users.push(accessInvitation.user)
      accessGroup.pendingUsers.splice(accessGroup.pendingUsers.indexOf(accessInvitation.user))
      await putEntity('access-groups', { accessGroup })  
    }

    history.push('/docs/home')
  }

  return (
    <PageLayout>
      <div className="w-full flex justify-center">
        <div className="max-w-xl bg-gray-50 p-8 mt-20 rounded-md w-full">
          {invitation &&
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Gestión de acceso a documentos</h3>
            
            <div className="mt-8">
              Se ha solicitado el acceso del usuario:
              <div className="flex items-center space-x-3 my-4">
                <div className="flex-shrink-0">
                  <Face displayName={invitation.user.displayName} pictureUrl={invitation.user.pictureUrl}></Face>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{invitation.user.displayName}</p>
                  <p className="text-sm font-normal text-gray-500 truncate">{invitation.user.email}</p>
                </div>
              </div>
            </div>
            <div className="mt-8">
              Para los documentos con este criterio:
              <div className="flex w-full flex-col gap-y-4 mt-2">
                <InfoPane title="Propietario">
                  <PeopleViewer value={invitation.owner?.owner} />
                </InfoPane>
                <InfoPane title="Nivel de acceso">{invitation.accessGroup.level.label}</InfoPane>
              </div>
            </div>

            <div className="w-full flex justify-between mt-12">
              <ButtonAsyncDanger onClick={() => saveInvitation('AUTHORIZATION_DENY')}>
                <Icon solid name="times" className="mr-2" />
                Denegar
              </ButtonAsyncDanger>
              <ButtonAsyncPrimary onClick={() => saveInvitation('AUTHORIZATION_ALLOW')}>
                <Icon solid name="check" className="mr-2" />
                Conceder acceso
              </ButtonAsyncPrimary>
            </div>
          </>
          }
        </div>
      </div>
    </PageLayout>
  )
}
