
import React from 'react'
import cx from 'classnames'

import { useField } from 'platform/forms'
import Icon from 'components/Icon'


export default function InputAddon({ form, className, help, type = 'text', name, label, validations, value, onChange, addonText, disabled }) {
  let field = useField({ form, name, validations, value, onChange })

  function parseValue(val) {
    if (type === 'text') {
      return val ? val.trim() : ''
    }

    return val
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{addonText}</span>
        </div>
        <input
          type={type}
          name={name}
          id={name}
          className={cx(
            'form-input block w-full pl-8 sm:text-sm rounded-md',
            {
              'bg-gray-100 cursor-not-allowed text-gray-400': disabled,
              'focus:ring-teal-500 focus:border-teal-500 border-gray-300': !field.hasError,
              'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': field.hasError,
            },
          )}
          disabled={disabled}
          defaultValue={parseValue(value)}
          onChange={e => {
            field.setValue(parseValue(e.target.value))
          }}
        />
        {field.hasError &&
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
            <Icon solid name="exclamation-circle" />
          </div>
        }
      </div>
      {help && <p className="mt-2 text-gray-500">{help}</p>}
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
    </div>
  )
}
