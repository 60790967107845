
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { cloneDeep, some, isEqual } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonSecondaryDangerSm from 'components/buttons/ButtonSecondaryDangerSm'
import Input from 'components/simple-forms/Input'
import MultiSelector from 'components/simple-forms/MultiSelector'
import Selector from 'components/simple-forms/Selector'
import Face from 'components/people/Face'
import Icon from 'components/Icon'
import UserSelector from 'components/UserSelector'
import CardHeader from 'components/CardHeader'
import CardTable from 'components/CardTable'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import BadgeOrange from 'components/badges/BadgeOrange'
import { listEntities, putEntity, getEntity } from 'models/docs/entities'
import { LEVELS } from 'models/docs/access-groups'
import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { serializeTimestamp } from 'platform/datetime'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { sendNotificationButton } from 'hooks/notifications'


const client = new UsersClient(users())


export default function GruposAccesoEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [open, setOpen] = useState(false)
  let [group, setGroup] = useState({ users: [], pendingUsers: [], owners: [] })
  let [form, setForm] = useState({ users: [], pendingUsers: [], owners: [] })

  let [documentTypes, setDocumentTypes] = useState([])
  let [owners, setOwners] = useState([])
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { users } = await client.List()
    setUsers(users)

    let reply = await listEntities('document-types')
    setDocumentTypes(reply?.map(documentType => {
      return {
        value: documentType.name,
        label: documentType.displayName,
      }
    }))

    reply = await listEntities('owners')
    let ownerList = await fetchPersonasSociedades(reply.map(o => o.owner))
    setOwners(reply?.map(owner => {
      return {
        value: owner.name,
        label: ownerList.resolve(owner.owner).nombre,
        privacyManager: owner.privacyManager,
      }
    }))

    if (name) {
      let reply = await getEntity(`access-groups/${name}`)

      reply.users = reply.users?.map(user => users.find(u => u.name === user)) ?? []
      reply.pendingUsers = reply.pendingUsers?.map(user => {
        let reqUser = users.find(u => u.name === user)
        reqUser.invitation = true
        return reqUser
      })
      ?? []

      setForm(cloneDeep(reply))
      setGroup(cloneDeep(reply))
    }
  })

  async function save() {
    let req = cloneDeep(form)
    req.users = req.users.map(u => u.name)
    req.pendingUsers = req.pendingUsers.map(u => u.name)
    
    let added = req.owners.some(owner => !group.owners.includes(owner))
    if (added) {
      req.pendingUsers = req.users.concat(req.pendingUsers)
      req.users = []
    }
    let reply = await putEntity('access-groups', { accessGroup: req })

    req.pendingUsers.forEach(user => {
      req.owners.forEach(async owner => {
        let accessInvitation = {
          createTime: serializeTimestamp(new Date()),
          authorization: 'AUTHORIZATION_PENDING',
          accessGroup: reply.name,
          user,
          owner,
        }
        let accessReply = await putEntity('access-invitations', { accessInvitation })

        await sendNotificationButton(
          owners.find(obj => obj.value === owner).privacyManager,
          'Un administrador intenta añadir un nuevo usuario para poder acceder a los documentos que gestiona. Necesita conceder autorización para ello.',
          {
            text: 'Revisar autorización',
            link: `/docs/${accessReply.name}`,
          },
        )
      })
    })
    
    history.push('/manage/grupos-acceso')
  }

  async function addUser(user) {
    let selected = form.pendingUsers
    selected.push(user)
    setForm({
      ...form,
      pendingUsers: selected,
    })
  }

  function removeUser (user) {
    setForm({
      ...form,
      pendingUsers: form.pendingUsers.filter(u => u.name !== user.name),
      users: form.users.filter(u => u.name !== user.name),
    })
  }

  let changed = some([
    group.displayName !== form.displayName,
    !isEqual(group.documentTypes, form.documentTypes),
    !isEqual(group.owners, form.owners),
    !isEqual(group.level, form.level),
    !isEqual(group.pendingUsers, form.pendingUsers),
    !isEqual(group.users, form.users),
  ]) && form.displayName
    && form.documentTypes
    && form.owners
    && form.level

  let selected = form.pendingUsers?.map(u => u.name)
  let userOptions = users.filter(u => !selected.includes(u.name))

  let allUsers = form.users.concat(form.pendingUsers.map(user => {
    user.pending = true
    return user
  }))

  return (
    <>
      <PageLayout
        breadcrumbs={
          <Breadcrumbs>
            <Breadcrumb url="/manage">Administración</Breadcrumb>
            <Breadcrumb url="/manage/grupos-acceso">Grupos de acceso</Breadcrumb>
            {name ?
              <Breadcrumb url={`/manage/grupos-acceso/${name}`}>{group.displayName}</Breadcrumb>
              :
              <Breadcrumb url="/manage/grupos-acceso/create">Crear grupo</Breadcrumb>
            }
          </Breadcrumbs>
        }
      >
        <CardHeader
          header="Datos generales"
          footer={
            <ButtonAsyncPrimary onClick={save} disabled={!changed}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          }
        >
          <Input
            className="mb-6"
            label="Nombre"
            value={form.displayName}
            onChange={value => {
              setForm({
                ...form,
                displayName: value,
              })
            }}
          ></Input>

          <MultiSelector
            label="Tipos de documentos"
            name="document-types"
            className="mb-6"
            value={form.documentTypes}
            options={documentTypes}
            onChange={value => {
              setForm({
                ...form,
                documentTypes: value,
              })
            }}
          ></MultiSelector>

          <MultiSelector
            label="Propietarios"
            name="owners"
            className="mb-6"
            value={form.owners}
            options={owners}
            onChange={value => {
              setForm({
                ...form,
                owners: value,
              })
            }}
          ></MultiSelector>

          <Selector
            label="Niveles acceso"
            name="niveles"
            value={form.level}
            options={LEVELS}
            onChange={value => {
              setForm({
                ...form,
                level: value,
              })
            }}
          ></Selector>
        </CardHeader>

        <CardTable
          title="Usuarios"
          empty="No hay usuarios asignados"
          header={
            <>
              <HeaderCell className="w-full">Nombre</HeaderCell>
              <HeaderCell fit="right"></HeaderCell>
            </>
          }
          actions={
            <ButtonPrimary onClick={() => setOpen(true)}>
              <Icon solid name="plus" className="mr-2" />
              Añadir usuario
            </ButtonPrimary>
          }
        >
          {allUsers.map(user => (
            <TableRow key={user.name}>
              <Cell>
                <div className="flex-1 flex items-center min-w-0 relative">
                  <Face displayName={user.displayName} pictureUrl={user.pictureUrl} />
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900 truncate">{user.displayName}</div>
                    <div className="text-sm text-gray-500 truncate">{user.email}</div>
                  </div>
                  {user.pending &&
                    <div className="ml-4">
                      <BadgeOrange>PENDIENTE</BadgeOrange>
                    </div>
                  }
                </div>
              </Cell>
              <Cell fit="right">
                <ButtonSecondaryDangerSm className="mr-2" onClick={() => removeUser(user)}>
                  <Icon solid name="trash" />
                </ButtonSecondaryDangerSm>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
      </PageLayout>
      <UserSelector
        open={open}
        onClose={() => setOpen(false)}
        onSelect={$event => {
          addUser($event)
        }}
        users={userOptions}
        header="Añadir usuario"
      ></UserSelector>
    </>
  )
}
