
import React from 'react'

import { priceNoSymbol } from 'platform/filters'


export default function PriceViewer({ value }) {
  return (
    <div>
      {value
        ?
        <>
          <span className="text-gray-800 font-medium">
            {priceNoSymbol(value)}
          </span>
          <span className="ml-1">EUR</span>
        </>
        : <>&mdash;</>
      }
    </div>
  )
}

