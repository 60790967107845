
import { useRef, useLayoutEffect } from 'react'


function getScrollPosition(element) {
  let target = element ? element.current : document.body

  return {
    left: target.scrollLeft,
    top: target.scrollTop,
  }
}


export function useScrollPosition(effect, element = document.body) {
  let track = useRef(getScrollPosition())

  useLayoutEffect(() => {
    if (!element.current) {
      return
    }
    
    let handleScroll = () => {
      requestAnimationFrame(() => {
        let position = getScrollPosition(element)
        effect({
          position,
          prevPos: track.current,
        })
        track.current = position
      })
    }

    let target = element.current || window
    target.addEventListener('scroll', handleScroll)

    return () => {
      target.removeEventListener('scroll', handleScroll)
    }
  }, [effect, element])
}
