export const USOS = [
    { label: 'Vivienda Venta', key: 'VIVIENDA_VENTA', vivienda: true },
    { label: 'Garajes Venta', key: 'GARAJES_VENTA', vivienda: true },
    { label: 'Trasteros Venta', key: 'TRASTEROS_VENTA', vivienda: true },
    { label: 'Viviendas Arrendamiento', key: 'VIVIENDAS_ARRENDAMIENTO', vivienda: true },
    { label: 'Garajes Arrendamiento', key: 'GARAJES_ARRENDAMIENTO', vivienda: true },
    { label: 'Trasteros Arrendamiento', key: 'TRASTEROS_ARRENDAMIENTO', vivienda: true },
    { label: 'Comercial', key: 'COMERCIAL' },
    { label: 'Garajes Comercial', key: 'GARAJES_COMERCIAL' },
    { label: 'Industrial', key: 'INDUSTRIAL' },
    { label: 'Logístico', key: 'LOGISTICO' },
    { label: 'Hotelero', key: 'HOTELERO' },
    { label: 'Oficinas', key: 'OFICINAS' },
    { label: 'Inst.Agrícolas', key: 'INSTALACIONES_AGRÍCOLAS' },
    { label: 'Otros Usos', key: 'OTROS_USOS' },
    { label: 'Libre', key: 'LIBRE', freeNombre: true },
];
export function getUso(key) {
    return USOS.find(uso => uso.key === key);
}
export function serializePercentage(n) {
    if (n) {
        return n.times(10000).toInteger().toNumber();
    }
}
export function serializeInteger(n) {
    if (n) {
        return n.toInteger().toNumber();
    }
}
