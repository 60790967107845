
import React from 'react'
import cx from 'classnames'


export default function ButtonSecondary({ children, onClick, className, disabled = false }) {
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={cx(
          'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none ',
          className,
          {
            'text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500': !disabled,
            'text-gray-400 cursor-default': disabled,
          },
        )}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  )
}
