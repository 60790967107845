// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/grupos_inmobiliarios/grupos_inmobiliarios.proto

import { Caller } from '@altipla/grpc-browser';


export default class GruposInmobiliariosClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/grupos-inmobiliarios`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/grupos-inmobiliarios/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/grupos-inmobiliarios:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/grupos-inmobiliarios`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/grupos-inmobiliarios/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/grupos-inmobiliarios/${req.name}`, req, false, ['name']);
  }
};

