
import React, { useState } from 'react'

import SideSheetHeader from 'components/SideSheetHeader'
import Form from 'components/forms/Form'
import { Submit } from 'components/forms/Submit'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import Icon from 'components/Icon'
import AlertDanger from 'components/alerts/AlertDanger'
import Input from 'components/forms/Input'
import { useForm } from 'platform/forms'


export class InvalidIncludeError extends Error {
  constructor(code) {
    super(`invalid include: ${code}`)
  }
}


export function AddInclude({ open, onClose, onSaveRecord, onSaveDocument }) {
  let [unknownFormat, setUnknownFormat] = useState(false)

  let form = useForm(async (data) => {
    setUnknownFormat(false)

    let url = new URL(data.url)
    try {
      if (url.pathname.startsWith('/records/')) {
        await onSaveRecord(url.pathname.substring('/records/'.length))
      } else if (url.pathname.startsWith('/archive/documents/')) {
        await onSaveDocument(url.pathname.substring('/archive/documents/'.length))
      } else {
        setUnknownFormat(true)
        return
      }
    } catch (err) {
      if (err instanceof InvalidIncludeError) {
        setUnknownFormat(true)
        return
      }

      throw err
    }
    form.reset()
  })

  return (
    <Form form={form}>
      <SideSheetHeader
        fit
        wide
        open={open}
        onClose={onClose}
        header="Añadir entidad"
        subheader="Añade un nuevo expediente o documento al expediente actual."
        footer={
          <div className="flex justify-between w-full">
            <ButtonSecondary onClick={onClose}>
              Cancelar
            </ButtonSecondary>

            <Submit form={form}>
              <Icon solid name="plus" className="mr-2" />
              Añadir entidad
            </Submit>
          </div>
        }
      >
        <div className="px-6 space-y-6">
          <Input
            type="url"
            form={form}
            name="url"
            validations="required|url"
            className="w-full"
            label="Pega la URL del elemento"
          ></Input>

          {unknownFormat && <AlertDanger>No se puede reconocer un documento o expediente en la dirección URL que ha introducido. Revise que apunte correctamente a un documento o expediente y no a la página de edición u otros apartados.</AlertDanger>}
        </div>
      </SideSheetHeader>
    </Form>
  )
}
