// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Users/users/users.proto

import { Caller } from '@altipla/grpc-browser';


export default class UsersClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/users`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/users/${req.name}`, req, false, ['name']);
  }

  Login(req) {
    return this._caller.send('POST', `/users:login`, req, true, []);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/users:batchGet`, req, true, []);
  }

  UpdateGroups(req) {
    return this._caller.send('POST', `/users/${req.name}:updateGroups`, req, true, ['name']);
  }

  Create(req) {
    return this._caller.send('POST', `/users`, req, true, []);
  }

  Profile(req) {
    return this._caller.send('GET', `/profile`, req, false, []);
  }

  ListPending(req) {
    return this._caller.send('GET', `/users:pending`, req, false, []);
  }

  DeletePending(req) {
    return this._caller.send('DELETE', `/users/${req.name}:pending`, req, false, ['name']);
  }
};

