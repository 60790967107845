
import React from 'react'

import SideSheetHeader from 'components/SideSheetHeader'
import Form from 'components/forms/Form'
import { Submit } from 'components/forms/Submit'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import Icon from 'components/Icon'
import Input from 'components/forms/Input'
import Datepicker from 'components/forms/Datepicker'
import { useForm } from 'platform/forms'


export function EditDeadline({ open, onClose, onSave, deadline }) {
  let form = useForm(async (data) => {
    await onSave({
      ...deadline,
      ...data,
    })
    form.reset()
  })

  return (
    <Form form={form}>
      <SideSheetHeader
        fit
        wide
        open={open}
        onClose={onClose}
        header="Editar vencimiento"
        footer={
          <div className="flex justify-between w-full">
            <ButtonSecondary onClick={onClose}>
              Cancelar
            </ButtonSecondary>

            <Submit form={form}>
              <Icon solid name="save" className="mr-2" />
              Guardar vencimiento
            </Submit>
          </div>
        }
      >
        <div className="px-6 space-y-6">
          <Input
            form={form}
            name="displayName"
            validations="required|min:3"
            className="w-full"
            label="Nombre"
            value={deadline.displayName}
          ></Input>
          <Input
            form={form}
            name="owner"
            validations="required|min:3"
            className="w-full"
            label="Responsable"
            value={deadline.owner}
          ></Input>
          <Datepicker
            form={form}
            name="deadlineTime"
            validations="required"
            label="Fecha límite"
            min={new Date()}
            value={deadline.deadlineTime}
          ></Datepicker>
        </div>
      </SideSheetHeader>
    </Form>
  )
}
