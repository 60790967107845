
import React from 'react'

import PageLayout from 'layout/webinterna/PageLayout'


export default function Home() {
  return (
    <PageLayout>
      <div className="w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center mt-12">
        <span className="text-2xl font-medium text-gray-900">WebInterna</span>
      </div>
    </PageLayout>
  )
}
