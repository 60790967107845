
import React from 'react'

import { HeaderMenu } from 'layout/HeaderMenu'
import NavigateThrobber from 'components/NavigateThrobber'


export default function FullscreenLayout({ breadcrumbs, children }) {
  return (
    <HeaderMenu breadcrumbs={breadcrumbs}>
      <div className="flex flex-1 justify-around relative">
        <NavigateThrobber></NavigateThrobber>
          
        <div className="max-w-7xl pt-8 pb-5 w-full flex flex-col">
          {children}
        </div>
      </div>
    </HeaderMenu>
  )
}
