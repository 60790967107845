
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep, some } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { webinterna } from 'services/discovery'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'


const client = new GruposProcesosClient(webinterna())


export default function GruposProcesosEdit() {
  let history = useHistory()
  let { name } = useParams()
  
  let [grupo, setGrupo] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await client.Get({ name })
      setGrupo(cloneDeep(reply))
      setForm(reply)
    }
  })

  async function create() {
    let req = cloneDeep(form)
    if (name) {
      client.Update(req)
      setGrupo({
        ...req,
        procesos: grupo.procesos,
      },
      )
    } else {
      let newGrupo = await client.Create(req)
      history.push('/manage/grupos-procesos/' + newGrupo.name)
    }
  }

  async function deleteGrupoProceso() {
    await client.Delete(grupo)
    history.push('/manage/grupos-procesos')
  }
  
  let hasChanged = some([
    grupo.nombre !== form.nombre,
    grupo.codigo !== form.codigo,
  ]) && form.nombre && form.codigo

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/grupos-procesos">Grupos de procesos</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/grupos-procesos/${name}`}>{grupo.denominacion}</Breadcrumb>
            :
            <Breadcrumb url="/manage/grupos-procesos/create">Crear grupo</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header={name ? 'Editar grupo de procesos' : 'Crear grupo de procesos'}
        subheader={name ? 'Edita un grupo de procesos.' : 'Registra un nuevo grupo de procesos.'}
        footer={
          <div className={`flex ${name ? 'justify-between' : 'justify-end'} w-full`}>
            {name &&
              <ButtonAsyncDanger
                onClick={deleteGrupoProceso}
                disabled={grupo.procesos?.length}
              >
                <Icon solid name="trash" className="mr-2" />
                <span title={grupo.procesos?.length ? 'No se pueden eliminar grupos de procesos con procesos asignados' : ''}>
                  Eliminar grupo
                </span>
              </ButtonAsyncDanger>
            }
            <ButtonAsyncPrimary onClick={create} disabled={!hasChanged}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Input
          className="mb-6 w-36"
          label="Código"
          type="text"
          value={form.codigo}
          onChange={value => {
            setForm({
              ...form,
              codigo: value,
            })
          }}
        ></Input>

        <Input
          className="mb-6"
          label="Nombre"
          type="text"
          value={form.nombre}
          onChange={value => {
            setForm({
              ...form,
              nombre: value,
            })
          }}
        ></Input>
      </CardHeader>
    </PageLayout>
  )
}
