import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { keyBy } from 'lodash'

import UsersClient from 'services/Users/users/users'
import GroupsClient from 'services/Users/groups/groups'
import { useNavigate } from 'hooks/navigate'
import { users } from 'services/discovery'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import resolver from 'platform/resolver'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import Face from 'components/people/Face'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'


const usersClient = new UsersClient(users())
const groupsClient = new GroupsClient(users())


export default function UsersList() {
  let [users, setUsers] = useState([])
  let [usersPending, setUsersPending] = useState([])

  useNavigate(async () => {
    let { usersReply, groups, usersPending } = await resolver.all({
      usersReply: usersClient.List().then(reply => reply.users || []),
      groups: groupsClient.List().then(reply => reply.groups || []),
      usersPending: usersClient.ListPending().then(reply => reply.pendings || []),
    })

    groups = keyBy(groups, 'name')

    usersReply.forEach(user => {
      user.lastLoginTime = parseTimestamp(user.lastLoginTime)
      user.groups = user.groups || []
      user.groups = user.groups.map(group => groups[group])
    })

    usersPending.forEach(user => {
      user.groups = user.groups || []
      user.groups = user.groups.map(group => groups[group])
    })

    setUsers(usersReply)
    setUsersPending(usersPending)
  })

  async function removePendingUser(user) {
    await usersClient.DeletePending({ name: user.name })
    let index = usersPending.indexOf(user)
    usersPending.splice(index, 1)
    setUsersPending([...usersPending])
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/users">Usuarios</Breadcrumb>
        </Breadcrumbs>
      }
    >
      {!!usersPending.length && 
        <CardTable
          title="Usuarios pendientes de activar"
          subtitle="No han iniciado sesión en el panel administrativo todavía. Los detalles de la cuenta de Azure se rellenarán en el primer login."
          empty="No hay usuarios pendientes"
          actions={
            <ButtonLinkPrimary to="/manage/users/create">
              <Icon solid name="plus" className="mr-2" />
              Crear usuario
            </ButtonLinkPrimary>
          }
          header={
            <>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell>Grupos</HeaderCell>
              <HeaderCell></HeaderCell>
            </>
          }
        >
          {usersPending.map(user => (
            <TableRow key={user.name}>
              <Cell verticalFit>
                {user.email}
              </Cell>
              <Cell verticalFit>
                <span>
                  {user.groups.map((group) => group.displayName).join(', ')}
                </span>
              </Cell>
              <Cell verticalFit className="text-right" fit="left">
                <ButtonHoverDanger onClick={() => removePendingUser(user)}>
                  <Icon solid name="trash" />
                </ButtonHoverDanger>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
      }

      <CardTable
        title="Usuarios"
        subtitle="Usuarios con acceso al panel administrativo."
        empty="No hay usuarios configurados."
        actions={
          <ButtonLinkPrimary to="/manage/users/create">
            <Icon solid name="plus" className="mr-2" />
            Crear usuario
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell></HeaderCell>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell>Grupos</HeaderCell>
            <HeaderCell>Últ. Acceso</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {users.map(user => (
          <TableRow key={user.name}>
            <Cell fit="right">
              <Face displayName={user.displayName} pictureUrl={user.pictureUrl}></Face>
            </Cell>
            <Cell>
              <div>
                <Link to={`/manage/users/${user.name}`} className="text-gray-700">{user.displayName}</Link>
              </div>
              <div className="text-gray-500">
                {user.email}
              </div>
            </Cell>
            <Cell>
              <span title={user.groups.map((group) => group.displayName).join(', ')}>
                {user.groups.length === 1 ? '1 grupo' : `${user.groups.length} grupos`}
              </span>
            </Cell>
            <Cell>
              {datetime(user.lastLoginTime, 'DATETIME')}
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/users/${user.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
