
import React from 'react'

import Icon from 'components/Icon'
import { useNotifications } from 'hooks/notifications'


export default function Notifier({ notifications }) {
  let { removeNotification } = useNotifications()

  return (
    <div className="fixed inset-0 flex flex-col justify-end items-center sm:justify-start sm:items-end px-4 py-6 pointer-events-none sm:p-6">
      {notifications.map(notification => (
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mb-4" key={notification.id}>
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <Icon regular name="check-circle" className="mr-2 text-green-400 text-xl mt-1 ml-2" />
                </div>
                <div className="ml-2 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {notification.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {notification.text}
                  </p>
                </div>
                <div className="ml-6 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    onClick={() => removeNotification(notification)}
                  >
                    <Icon solid name="times" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
