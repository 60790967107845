
import React from 'react'

import ProcesosList from 'views/webinterna/procesos/ProcesosList'
import ProcesosEdit from 'views/webinterna/procesos/ProcesosEdit'
import ProcesosShow from 'views/webinterna/procesos/ProcesosShow'
import SociedadesList from 'views/webinterna/sociedades/SociedadesList'
import SociedadesShow from 'views/webinterna/sociedades/SociedadesShow'
import SociedadesEdit from 'views/webinterna/sociedades/SociedadesEdit'
import PersonasList from 'views/webinterna/personas/PersonasList'
import PersonasShow from 'views/webinterna/personas/PersonasShow'
import ContribuidoresList from 'views/webinterna/personas/ContribuidoresList'
import PersonasEdit from 'views/webinterna/personas/PersonasEdit'
import ProyectosList from 'views/webinterna/proyectos/ProyectosList'
import ProyectosShow from 'views/webinterna/proyectos/ProyectosShow'
import ProyectosEdit from 'views/webinterna/proyectos/ProyectosEdit'
import PresupuestosEdit from 'views/webinterna/proyectos/PresupuestosEdit'
import InmueblesList from 'views/webinterna/inmuebles/InmueblesList'
import InmueblesShow from 'views/webinterna/inmuebles/InmueblesShow'
import InmueblesEdit from 'views/webinterna/inmuebles/InmueblesEdit'
import PlanesCuentasList from 'views/webinterna/planes-cuentas/PlanesCuentasList'
import PlanesCuentasShow from 'views/webinterna/planes-cuentas/PlanesCuentasShow'
import PlanesCuentasEdit from 'views/webinterna/planes-cuentas/PlanesCuentasEdit'
import ContratosList from 'views/webinterna/contratos/ContratosList'
import ContratosEdit from 'views/webinterna/contratos/ContratosEdit'
import ContratosShow from 'views/webinterna/contratos/ContratosShow'
import ValidacionesList from 'views/webinterna/validaciones/ValidacionesList'
import Dashboard from 'views/dashboard/Dashboard'
import ManageHome from 'views/manage/Home'
import ProvinciasList from 'views/manage/ProvinciasList'
import GroupsCreate from 'views/manage/groups/GroupsCreate'
import InboxesGroupsList from 'views/manage/inboxes-groups/InboxesGroupsList'
import InboxesGroupsEdit from 'views/manage/inboxes-groups/InboxesGroupsEdit'
import UsersCreate from 'views/manage/users/UsersCreate'
import DocsHome from 'views/docs/Home'
import { UploadMain } from 'views/docs/upload/UploadMain'
import { UploadControl } from 'views/docs/upload/UploadControl'
import { UploadManagement } from 'views/docs/upload/UploadManagement'
import { UploadSignature } from 'views/docs/upload/UploadSignature'
import { UploadCounterpart } from 'views/docs/upload/UploadCounterpart'
import { UploadPresentation } from 'views/docs/UploadPresentation'
import { DocumentShow } from 'views/archive/DocumentShow'
import OwnersList from 'views/manage/owners/OwnersList'
import OwnersEdit from 'views/manage/owners/OwnersEdit'
import ExpedientesHome from 'views/expedientes/Home'
import Inbox from 'views/docs/Inbox'
import ClassificationInbox from 'views/docs/ClassificationInbox'
import UsersList from 'views/manage/users/UsersList'
import UsersEdit from 'views/manage/users/UsersEdit'
import GroupsList from 'views/manage/groups/GroupsList'
import GroupsEdit from 'views/manage/groups/GroupsEdit'
import MunicipiosList from 'views/manage/MunicipiosList'
import DocumentTypesList from 'views/manage/documentTypes/DocumentTypesList'
import DocumentTypesEdit from 'views/manage/documentTypes/DocumentTypesEdit'
import DocumentTypesFieldEdit from 'views/manage/documentTypes/DocumentTypesFieldEdit'
import GruposProcesosList from 'views/manage/grupos-procesos/GruposProcesosList'
import GruposProcesosEdit from 'views/manage/grupos-procesos/GruposProcesosEdit'
import CentrosTrabajoList from 'views/manage/centros-trabajo/CentrosTrabajoList'
import CentrosTrabajoEdit from 'views/manage/centros-trabajo/CentrosTrabajoEdit'
import FincasRegistralesList from 'views/webinterna/fincas-registrales/FincasRegistralesList'
import ArchiveHome from 'views/archive/Home'
import GruposInmueblesList from 'views/manage/grupos-inmuebles/GruposInmueblesList'
import GruposInmueblesEdit from 'views/manage/grupos-inmuebles/GruposInmueblesEdit'
import RegistrosPropiedadList from 'views/manage/registros-propiedad/RegistrosPropiedadList'
import RegistrosPropiedadEdit from 'views/manage/registros-propiedad/RegistrosPropiedadEdit'
import FincasCatastralesList from 'views/webinterna/fincas-catastrales/FincasCatastralesList'
import ElementosPatrimonialesList from 'views/webinterna/elementos-patrimoniales/ElementosPatrimonialesList'
import DocumentSubtypesAdd from 'views/manage/documentTypes/DocumentSubtypesAdd'
import FincasRegistralesShow from 'views/webinterna/fincas-registrales/FincasRegistralesShow'
import FincasRegistralesEdit from 'views/webinterna/fincas-registrales/FincasRegistralesEdit'
import ElementosPatrimonialesShow from 'views/webinterna/elementos-patrimoniales/ElementosPatrimonialesShow'
import FincasCatastralesShow from 'views/webinterna/fincas-catastrales/FincasCatastralesShow'
import ElementosPatrimonialesEdit from 'views/webinterna/elementos-patrimoniales/ElementosPatrimonialesEdit'
import FincasCatastralesEdit from 'views/webinterna/fincas-catastrales/FincasCatastralesEdit'
import GruposAccesoList from 'views/manage/grupos-acceso/GruposAccesoList'
import GruposAccesoEdit from 'views/manage/grupos-acceso/GruposAccesoEdit'
import ArchiveRoomsList from 'views/manage/archive-rooms/ArchiveRoomsList'
import ArchiveRoomsEdit from 'views/manage/archive-rooms/ArchiveRoomsEdit'
import ArchiveRoomsLockerEdit from 'views/manage/archive-rooms/ArchiveRoomsLockerEdit'
import ImpuestosActividadList from 'views/manage/impuestos-actividad/ImpuestosActividadList'
import RegistrosMercantilesList from 'views/manage/registros-mercantiles/RegistrosMercantilesList'
import ImpuestosActividadEdit from 'views/manage/impuestos-actividad/ImpuestosActividadEdit'
import RegistrosMercantilesEdit from 'views/manage/registros-mercantiles/RegistrosMercantilesEdit'
import ManageAccessInvitation from 'views/docs/ManageAccessInvitation'
import ArchiveDocument from 'views/docs/ArchiveDocument'
import RecordFamiliesList from 'views/manage/record-families/RecordFamiliesList'
import RecordFamiliesEdit from 'views/manage/record-families/RecordFamiliesEdit'
import RecordFamiliesFieldEdit from 'views/manage/record-families/RecordFamiliesFieldEdit'
import { ExpedientesShow } from 'views/expedientes/ExpedientesShow'
import RolesTransfer from 'views/manage/RolesTransfer'
import WebinternaHome from 'views/webinterna/Home'
import LibraryManagersList from 'views/manage/library-managers/LibraryManagersList'
import LibraryManagersEdit from 'views/manage/library-managers/LibraryManagersEdit'
import { ExpedientesEdit } from 'views/expedientes/ExpedientesEdit'
import WithdrawDocument from 'views/archive/ValidateWithdrawDocument'
import RequestWithdrawDocument from 'views/archive/RequestWithdrawDocument'
import ReturnDocument from 'views/archive/ReturnDocument'
import ValidateReturnDocument from 'views/archive/ValidateReturnDocument'


export default [
  { path: '/', component: <Dashboard /> },

  { path: '/webinterna', redirect: '/webinterna/home' },
  { path: '/webinterna/home', component: <WebinternaHome /> },

  { path: '/webinterna/procesos', component: <ProcesosList /> },
  { path: '/webinterna/procesos/:name', component: <ProcesosShow /> },
  { path: '/webinterna/procesos/edit/:name', component: <ProcesosEdit /> },

  { path: '/webinterna/personas', component: <PersonasList /> },
  { path: '/webinterna/personas/:name', component: <PersonasShow /> },
  { path: '/webinterna/contribuidores', component: <ContribuidoresList /> },
  { path: '/webinterna/personas/edit/:name', component: <PersonasEdit /> },

  { path: '/webinterna/sociedades', component: <SociedadesList /> },
  { path: '/webinterna/sociedades/:name', component: <SociedadesShow /> },
  { path: '/webinterna/sociedades/edit/:name', component: <SociedadesEdit /> },

  { path: '/webinterna/proyectos', component: <ProyectosList /> },
  { path: '/webinterna/proyectos/:name', component: <ProyectosShow /> },
  { path: '/webinterna/proyectos/edit/:name', component: <ProyectosEdit /> },
  { path: '/webinterna/proyectos/presupuestos/edit/:name', component: <PresupuestosEdit edit /> },
  { path: '/webinterna/proyectos/presupuestos/new/:name', component: <PresupuestosEdit /> },

  { path: '/webinterna/fincas-catastrales', component: <FincasCatastralesList /> },
  { path: '/webinterna/fincas-catastrales/:name', component: <FincasCatastralesShow /> },
  { path: '/webinterna/fincas-catastrales/edit/:name', component: <FincasCatastralesEdit /> },

  { path: '/webinterna/inmuebles', component: <InmueblesList /> },
  { path: '/webinterna/inmuebles/:name', component: <InmueblesShow /> },
  { path: '/webinterna/inmuebles/edit/:name', component: <InmueblesEdit /> },

  { path: '/webinterna/fincas-registrales', component: <FincasRegistralesList /> },
  { path: '/webinterna/fincas-registrales/:name', component: <FincasRegistralesShow /> },
  { path: '/webinterna/fincas-registrales/edit/:name', component: <FincasRegistralesEdit /> },

  { path: '/webinterna/elementos-patrimoniales', component: <ElementosPatrimonialesList /> },
  { path: '/webinterna/elementos-patrimoniales/:name', component: <ElementosPatrimonialesShow /> },
  { path: '/webinterna/elementos-patrimoniales/edit/:name', component: <ElementosPatrimonialesEdit /> },

  { path: '/webinterna/planes-cuentas', component: <PlanesCuentasList /> },
  { path: '/webinterna/planes-cuentas/edit/:name', component: <PlanesCuentasEdit /> },
  { path: '/webinterna/planes-cuentas/evolve/:name', component: <PlanesCuentasEdit predecesor /> },
  { path: '/webinterna/planes-cuentas/:name', component: <PlanesCuentasShow /> },

  { path: '/webinterna/contratos', component: <ContratosList /> },
  { path: '/webinterna/contratos/edit/:name', component: <ContratosEdit /> },
  { path: '/webinterna/contratos/:name', component: <ContratosShow /> },

  { path: '/webinterna/validaciones', component: <ValidacionesList /> },
  { path: '/webinterna/validaciones/procesos/:name', component: <ProcesosEdit validation /> },
  { path: '/webinterna/validaciones/sociedades/:name', component: <SociedadesEdit validation /> },
  { path: '/webinterna/validaciones/proyectos/:name', component: <ProyectosEdit validation /> },
  { path: '/webinterna/validaciones/proyectos/presupuestos/:name', component: <PresupuestosEdit validation /> },
  { path: '/webinterna/validaciones/planes-cuentas/:name', component: <PlanesCuentasEdit validation /> },
  { path: '/webinterna/validaciones/inmuebles/:name', component: <InmueblesEdit validation /> },
  { path: '/webinterna/validaciones/contratos/:name', component: <ContratosEdit validation /> },
  { path: '/webinterna/validaciones/personas/:name', component: <PersonasEdit validation /> },
  { path: '/webinterna/validaciones/fincas-registrales/:name', component: <FincasRegistralesEdit validation /> },
  { path: '/webinterna/validaciones/elementos-patrimoniales/:name', component: <ElementosPatrimonialesEdit validation /> },
  { path: '/webinterna/validaciones/fincas-catastrales/:name', component: <FincasCatastralesEdit validation /> },

  { path: '/manage', redirect: '/manage/home' },
  { path: '/manage/home', component: <ManageHome /> },

  { path: '/manage/provincias', component: <ProvinciasList /> },
  { path: '/manage/municipios', component: <MunicipiosList /> },

  { path: '/manage/owners', component: <OwnersList /> },
  { path: '/manage/owners/create', component: <OwnersEdit /> },
  { path: '/manage/owners/:name', component: <OwnersEdit /> },
  { path: '/manage/grupos-procesos', component: <GruposProcesosList /> },
  { path: '/manage/grupos-procesos/create', component: <GruposProcesosEdit /> },
  { path: '/manage/grupos-procesos/:name', component: <GruposProcesosEdit /> },
  { path: '/manage/users/create', component: <UsersCreate /> },
  { path: '/manage/users', component: <UsersList /> },
  { path: '/manage/users/:name', component: <UsersEdit /> },
  { path: '/manage/groups/create', component: <GroupsCreate /> },
  { path: '/manage/groups', component: <GroupsList /> },
  { path: '/manage/groups/:name', component: <GroupsEdit /> },
  { path: '/manage/inboxes-groups', component: <InboxesGroupsList /> },
  { path: '/manage/inboxes-groups/create', component: <InboxesGroupsEdit /> },
  { path: '/manage/inboxes-groups/:name', component: <InboxesGroupsEdit /> },
  { path: '/manage/document-types', component: <DocumentTypesList/> },
  { path: '/manage/document-types/create', component: <DocumentTypesEdit/> },
  { path: '/manage/document-types/:name', component: <DocumentTypesEdit/> },
  { path: '/manage/document-types/:name/fields/add', component: <DocumentTypesFieldEdit/> },
  { path: '/manage/document-types/:name/fields/:idx', component: <DocumentTypesFieldEdit/> },
  { path: '/manage/document-types/:name/subtypes/add', component: <DocumentSubtypesAdd /> },
  { path: '/manage/record-families', component: <RecordFamiliesList/> },
  { path: '/manage/record-families/create', component: <RecordFamiliesEdit/> },
  { path: '/manage/record-families/:name', component: <RecordFamiliesEdit/> },
  { path: '/manage/record-families/:name/fields/add', component: <RecordFamiliesFieldEdit/> },
  { path: '/manage/record-families/:name/fields/:idx', component: <RecordFamiliesFieldEdit/> },
  { path: '/manage/centros-trabajo', component: <CentrosTrabajoList/> },
  { path: '/manage/centros-trabajo/create', component: <CentrosTrabajoEdit /> },
  { path: '/manage/centros-trabajo/:name', component: <CentrosTrabajoEdit /> },
  { path: '/manage/grupos-inmobiliarios', component: <GruposInmueblesList /> },
  { path: '/manage/grupos-inmobiliarios/create', component: <GruposInmueblesEdit /> },
  { path: '/manage/grupos-inmobiliarios/:name', component: <GruposInmueblesEdit /> },
  { path: '/manage/registros-propiedad', component: <RegistrosPropiedadList/> },
  { path: '/manage/registros-propiedad/create', component: <RegistrosPropiedadEdit /> },
  { path: '/manage/registros-propiedad/:name', component: <RegistrosPropiedadEdit /> },
  { path: '/manage/grupos-acceso', component: <GruposAccesoList/> },
  { path: '/manage/grupos-acceso/create', component: <GruposAccesoEdit /> },
  { path: '/manage/grupos-acceso/:name', component: <GruposAccesoEdit /> },
  { path: '/manage/archive-rooms', component: <ArchiveRoomsList /> },
  { path: '/manage/archive-rooms/create', component: <ArchiveRoomsEdit /> },
  { path: '/manage/archive-rooms/:name', component: <ArchiveRoomsEdit /> },
  { path: '/manage/archive-rooms/:parent/archive-rooms-lockers/add', component: <ArchiveRoomsLockerEdit /> },
  { path: '/manage/archive-rooms/:parent/archive-rooms-lockers/:name', component: <ArchiveRoomsLockerEdit /> },
  { path: '/manage/impuestos-actividad', component: <ImpuestosActividadList /> },
  { path: '/manage/registros-mercantiles', component: <RegistrosMercantilesList /> },
  { path: '/manage/impuestos-actividad/create', component: <ImpuestosActividadEdit /> },
  { path: '/manage/impuestos-actividad/:name', component: <ImpuestosActividadEdit /> },
  { path: '/manage/registros-mercantiles/create', component: <RegistrosMercantilesEdit /> },
  { path: '/manage/registros-mercantiles/:name', component: <RegistrosMercantilesEdit /> },
  { path: '/manage/library-managers', component: <LibraryManagersList /> },
  { path: '/manage/library-managers/create', component: <LibraryManagersEdit /> },
  { path: '/manage/library-managers/:name', component: <LibraryManagersEdit /> },
  { path: '/manage/roles-transfer', component: <RolesTransfer /> },

  { path: '/docs', redirect: '/docs/home' },
  { path: '/docs/home', component: <DocsHome /> },

  { path: '/docs/upload/main', component: <UploadMain /> },
  { path: '/docs/upload/control/:code', component: <UploadControl /> },
  { path: '/docs/upload/management/:code', component: <UploadManagement /> },
  { path: '/docs/upload/signature/:code', component: <UploadSignature /> },
  { path: '/docs/upload/counterpart/:code', component: <UploadCounterpart /> },
  { path: '/docs/upload/presentation/:code', component: <UploadPresentation /> },

  { path: '/docs/inboxes-groups/:code', component: <Inbox /> },
  { path: '/docs/access-invitations/:code', component: <ManageAccessInvitation /> },
  { path: '/docs/inboxes-groups/:parent/classification/documents/:code', component: <ClassificationInbox /> },
  { path: '/docs/archive', component: <ArchiveDocument /> },

  { path: '/records', redirect: '/records/home' },
  { path: '/records/home', component: <ExpedientesHome /> },
  { path: '/records/:code', component: <ExpedientesShow /> },
  { path: '/records/:code/edit', component: <ExpedientesEdit /> },

  { path: '/archive', redirect: '/archive/home' },
  { path: '/archive/home', component: <ArchiveHome /> },
  { path: '/archive/documents/:code', component: <DocumentShow /> },
  { path: '/archive/withdraw/:code/:userid', component: <WithdrawDocument /> },
  { path: '/archive/withdraw/request/:code/:userid', component: <RequestWithdrawDocument /> },
  { path: '/archive/return/:code', component: <ReturnDocument /> },
  { path: '/archive/return/:code/:userid', component: <ValidateReturnDocument /> },
]
