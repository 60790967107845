
import React, { useEffect } from 'react'
import cx from 'classnames'

import { parseShortValidations, validationMessage, hasError } from 'platform/forms'
import Icon from 'components/Icon'


export default function Textarea({ form, className, help, name, rows = 3, label, validations, value, onChange }) {
  let checks = parseShortValidations(validations)

  useEffect(() => {
    form.register({ name }, checks)

    return () => form.unregister(name)
  }, [name, validations]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setValue(name, value ? value.trim() : '')
  }, [name, value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': hasError(form, name),
          },
        )}
      >
        {label}
        {checks && checks.required && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className={cx(
            'form-textarea shadow-sm block w-full focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 rounded-md',
            {
              'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red': hasError(form, name),
            },
          )}
          defaultValue={value ? value.trim() : ''}
          onChange={e => {
            form.setValue(name, e.target.value.trim(), true)
            if (onChange) {
              onChange(e.target.value.trim())
            }
          }}
        ></textarea>
        {hasError(form, name) &&
          <div className="absolute inset-y-0 right-0 pr-3 flex mt-2 pointer-events-none text-red-500">
            <Icon solid name="exclamation-circle" />
          </div>
        }
      </div>
      {hasError(form, name) && <p className="mt-2 text-red-700">{validationMessage(checks, form.errors[name])}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
