// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/validaciones/validaciones.proto

import { Caller } from '@altipla/grpc-browser';


export default class ValidacionesClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/validaciones`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/validaciones/${req.name}`, req, false, ['name']);
  }

  Create(req) {
    return this._caller.send('POST', `/validaciones`, req, true, []);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/validaciones/${req.name}`, req, false, ['name']);
  }
};

