
import React from 'react'
import cx from 'classnames'
import { Table } from 'components/Table'


export default function CardTable({ title, subtitle, header, className, children, empty, actions, footer }) {
  return (
    <div className={
      cx(
        'bg-white pt-6 shadow sm:rounded-md',
        className,
      )
    }>
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
            {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
          </div>
          {actions && <div className="ml-4 mt-2 flex-shrink-0">{actions}</div>}
        </div>
      </div>
      <Table className="mt-6 border-t border-gray-200" header={header} empty={empty}>{children}</Table>
      {footer && <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">{footer}</div>}
    </div>
  )
}
