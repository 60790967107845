
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { Cell, HeaderCell, TableRow } from 'components/Table'
import CardTable from 'components/CardTable'
import Icon from 'components/Icon'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { useNavigate } from 'hooks/navigate'
import { webinterna } from 'services/discovery'
import ImpuestosActividadClient from 'services/WebInterna/impuestos_actividad/impuestos_actividad'


const client = new ImpuestosActividadClient(webinterna())


export default function ImpuestosActividadList() {
  let [impuestos, setImpuestos] = useState([])

  useNavigate(async () => {
    let reply = await client.List()
    setImpuestos(reply.impuestos || [])
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/impuestos-actividad">Impuestos sobre la actividad económica</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="IAE - Impuestos Actividad Económica"
        subtitle="Administración de las clases de IAE que existen en el sistema."
        empty="No hay clases IAE configuradas."
        actions={
          <ButtonLinkPrimary to="/manage/impuestos-actividad/create">
            <Icon solid name="plus" className="mr-2" />
            Crear Impuesto
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {impuestos.map(impuesto => (
          <TableRow key={impuesto.name}>
            <Cell>
              <Link to={`/manage/impuestos-actividad/${impuesto.name}`} className="text-gray-700">{impuesto.nombre}</Link>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/impuestos-actividad/${impuesto.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
