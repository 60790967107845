
import React, { useRef, useEffect, useState } from 'react'
import diacritic from 'diacritic'
import firstBy from 'thenby'

import SideSheetHeader from 'components/SideSheetHeader'
import Face from 'components/people/Face'
import Icon from 'components/Icon'


export default function UserSelector({ onSelect, open, onClose, users, header }) {
  if (!users) {
    users = []
  }

  let filterElement = useRef()

  let [filter, setFilter] = useState('')

  let filtered = users
  filtered.sort(firstBy(item => item.displayName).thenBy('email'))
  if (filter) {
    let search = diacritic.clean(filter).toUpperCase()

    filtered = users.filter(item => {
      let $search = diacritic.clean(`${item.displayName} ${item.email}`).toUpperCase()
      return $search.includes(search)
    })
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => filterElement.current.focus())
    }
  }, [open])

  function selectUser(user) {
    onSelect(user)
    onClose()
  }

  return (
    <SideSheetHeader
      wide
      fit
      header={header}
      open={open}
      onClose={onClose}
    >
      <div className="-mt-6 flex rounded-md w-full">
        <div className="relative flex-grow focus-within:z-10 border-b-3 border-gray-300 pl-6">
          <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
            <Icon solid name="search" className="text-gray-400" />
          </div>
          <input
            ref={filterElement}
            type="text"
            id="search"
            autoComplete="off"
            className="form-input block w-full focus:ring-0 py-2 pl-8 border-0"
            placeholder="Filtrar..."
            value={filter}
            onChange={event => setFilter(event.target.value.trim())}
          />
        </div>
      </div>
      <div className="relative flex-1 overflow-y-auto">
        <ul>
          {filtered.map((item) => (
            <li
              key={item.name}
              className="px-6 py-3 relative border-t border-b -mt-px border-gray-300"
            >
              <div className="group flex justify-between items-center">
                <div onClick={() => selectUser(item)} className="-m-1 p-1 block cursor-pointer">
                  <div className="absolute inset-0 group-hover:bg-gray-200" />
                  <div className="flex-1 flex items-center min-w-0 relative">
                    <Face displayName={item.displayName || item.email} pictureUrl={item.pictureUrl} />
                    <div className="truncate cursor-pointer ml-4">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {item.displayName
                          ? item.displayName
                          : <span className="italic">(sin nombre)</span>
                        }
                      </p>
                      {item.email &&
                        <p className="text-sm text-gray-500 truncate">{item.email}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="ml-2 relative inline-block text-left">
                  <a
                    href={item.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="group relative w-10 h-10 text-gray-500 rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 bg-white"
                  >
                    <span className="flex items-center justify-center h-full w-full rounded-full">
                      <Icon regular name="external-link" className="fa-lg" />
                    </span>
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </SideSheetHeader>
  )
}
