
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import HtmlViewer from 'components/HtmlViewer'
import Card from 'components/Card'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import { parseDate } from 'platform/datetime'
import { area, datetime, percentage } from 'platform/filters'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import ExternalLink from 'components/ExternalLink'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())


export default function ElementosPatrimonialesShow() {
  let { name } = useParams()
  let [elemento, setElemento] = useState({})

  useNavigate(async () => {
    let elemento = await elementosPatrimonialesClient.Get({ name })

    if (elemento.fincaRegistral) {
      elemento.fincaRegistral = await fincasRegistralesClient.Get({ name: elemento.fincaRegistral })
      elemento.fincaRegistral.altaDate = parseDate(elemento.fincaRegistral.altaDate)
      elemento.fincaRegistral.altaJuridicaDate = parseDate(elemento.fincaRegistral.altaJuridicaDate)
      elemento.fincaRegistral.bajaJuridicaDate = parseDate(elemento.fincaRegistral.bajaJuridicaDate)
      elemento.fincaRegistral.bajaDate = parseDate(elemento.fincaRegistral.bajaDate) 
    }

    if (elemento.fincaRegistral?.inmueble) {
      elemento.fincaRegistral.inmueble = await inmueblesClient.Get({ name: elemento.fincaRegistral.inmueble })
      elemento.fincaRegistral.inmueble.titular = elemento.fincaRegistral?.inmueble?.titular
    }

    if (elemento.fincaRegistral.inmueble.municipio) {
      elemento.fincaRegistral.inmueble.municipio = await municipiosClient.Get({ name: elemento.fincaRegistral.inmueble.municipio })
    }

    if (elemento.fincaRegistral.inmueble.provincia) {
      elemento.fincaRegistral.inmueble.provincia = await provinciasClient.Get({ name: elemento.fincaRegistral.inmueble.provincia })
    }

    if (elemento.fincaRegistral.inmueble.grupo) {
      elemento.fincaRegistral.inmueble.grupo = await gruposInmobiliariosClient.Get({ name: elemento.fincaRegistral.inmueble.grupo })
    }

    let { fincas } = await fincasCatastralesClient.List()
    fincas = fincas || []
    let fincasCatastrales = fincas.filter(
      fincaCatastral => elemento.fincaRegistral === fincaCatastral.fincaRegistral,
    )
    elemento.numeroFincasCatastrales = fincasCatastrales.length
    elemento.sumaSupCatastrales = fincasCatastrales.reduce((acc, curr) => acc + curr.superficieTotal, 0)

    setElemento(elemento)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!elemento.blocked &&
            <Acl perm="webinterna.elementos_patrimoniales.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/elementos-patrimoniales/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Elemento Patrimonial
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/elementos-patrimoniales">Elementos Patrimoniales</Breadcrumb>
        <Breadcrumb url={`/webinterna/elementos-patrimoniales/${name}`}>Consultar Elementos Patrimoniales</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={elemento.blocked}></EntityBlockedAlert>

      <div className="flex mb-6">
        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Denominación GIU">{elemento.fincaRegistral?.inmueble?.grupo?.nombre}</ShowPane>
              <ShowPane title="Código UINM">{elemento.fincaRegistral?.inmueble?.codigo}</ShowPane>
              <ShowPane title="Denominación UINM">{elemento.fincaRegistral?.inmueble?.nombre}</ShowPane>
              <ShowPane title="Código Elemento Patrimonial">{elemento.codigo}</ShowPane>
              <ShowPane title="Denominación Elemento Patrimonial">{elemento.nombre}</ShowPane>
              <ShowPane title="Nº Finca Registral">{elemento.fincaRegistral?.numeroFinca}</ShowPane>
              <ShowPane title="Titular">
                {elemento.fincaRegistral?.inmueble?.titular && <PeopleViewer value={elemento.fincaRegistral.inmueble.titular} />}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Tipo">
                {elemento.tipo === 'TIPO_VIVIENDA' && 'Vivienda'}
                {elemento.tipo === 'TIPO_VIVIENDA_PP' && 'Vivienda PP'}
                {elemento.tipo === 'TIPO_LOCAL' && 'Local'}
                {elemento.tipo === 'TIPO_NAVE' && 'Nave'}
                {elemento.tipo === 'TIPO_HOTEL' && 'Hotel'}
                {elemento.tipo === 'TIPO_OFICINA' && 'Oficina'}
                {elemento.tipo === 'TIPO_TRASTERO' && 'Trastero'}
                {elemento.tipo === 'TIPO_ALMACEN' && 'Almacén'}
                {elemento.tipo === 'TIPO_GARAJE' && 'Garaje'}
                {elemento.tipo === 'TIPO_TERRENO' && 'Terreno'}
                {elemento.tipo === 'TIPO_INSTALACION_AGRICOLA' && 'Instalación Agrícola'}
                {elemento.tipo === 'TIPO_VALLA' && 'Valla'}
                {elemento.tipo === 'TIPO_KIOSCO' && 'Kiosco'}
                {elemento.tipo === 'TIPO_STAND' && 'Stand'}
                {elemento.tipo === 'TIPO_OTROS' && 'Otros'}
              </ShowPane>
              <ShowPane title="Destino">
                {elemento.destino === 'DESTINO_ARRENDAMIENTO' && 'Arrendamiento'}
                {elemento.destino === 'DESTINO_VENTA' && 'Venta'}
                {elemento.destino === 'DESTINO_USO_PROPIO' && 'Uso propio'}
              </ShowPane>
              <ShowPane title="Estado desarrollo">
                {elemento.estadoDesarrollo === 'ESTADO_DESARROLLO_PROYECTO' && 'Proyecto'}
                {elemento.estadoDesarrollo === 'ESTADO_DESARROLLO_OBRAS_EN_CURSO' && 'Obras en curso'}
                {elemento.estadoDesarrollo === 'ESTADO_DESARROLLO_EN_SERVICIO' && 'En servicio'}
              </ShowPane>
              <ShowPane title="Estado comercial">
                {elemento.estadoComercial === 'ESTADO_COMERCIAL_DISPONIBLE' && <BadgeGreen>Disponible</BadgeGreen>}
                {elemento.estadoComercial === 'ESTADO_COMERCIAL_NO_DISPONIBLE' && <BadgeGray>No disponible</BadgeGray>}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Metros lineales fachada">{area(elemento.metrosFachada)}</ShowPane>
              <ShowPane title="Metros construidos sin terrazas">{area(elemento.construidoSinTerrazas)}</ShowPane>
              <ShowPane title="Metros construidos terrazas">{area(elemento.construidoTerrazas)}</ShowPane>
              <ShowPane title="Metros construidos Totales">{area((elemento.construidoSinTerrazas ?? 0) + (elemento.construidoTerrazas ?? 0))}</ShowPane>
              <ShowPane title="Metros construidos pp.zz.cc">{area(elemento.construidoPpzzcc)}</ShowPane>
              <ShowPane title="Metros útiles interior">{area(elemento.utilesInterior)}</ShowPane>
              <ShowPane title="Metros útiles terrazas">{area(elemento.utilesTerrazas)}</ShowPane>
              <ShowPane title="Metros útiles totales">{area((elemento.utilesInterior ?? 0) + (elemento.utilesTerrazas ?? 0))}</ShowPane>
              <ShowPane title="Superficie suelo ocupado" className="col-span-2">{area(elemento.sueloOcupado)}</ShowPane>
              <ShowPane title="Superficie suelo computable">{area(elemento.superficieComputable)}</ShowPane>
            </div>
          </Card>
        </div>
        <div className="space-y-4 w-1/2">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Fecha Alta Jurídica">{datetime(elemento.fincaRegistral?.altaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja Jurídica">{datetime(elemento.fincaRegistral?.bajaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Alta">{datetime(elemento.fincaRegistral?.altaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja">{datetime(elemento.fincaRegistral?.bajaDate, 'DATE')}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Municipio">{elemento.fincaRegistral?.inmueble?.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">
                {elemento.fincaRegistral?.inmueble?.provincia?.nombre}
              </ShowPane>
              <ShowPane title="Cód. Postal">{elemento.codigoPostal}</ShowPane>
              <ShowPane title="Bloque/Edificio/Fase">{elemento.bloque}</ShowPane>
              <ShowPane title="Portal">{elemento.portal}</ShowPane>
              <ShowPane title="Escalera">{elemento.escalera}</ShowPane>
              <ShowPane title="Planta">{elemento.planta}</ShowPane>
              <ShowPane title="Puerta">{elemento.puerta}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Nº dormitorios">{elemento.dormitorios}</ShowPane>
              <ShowPane title="Nº baños">{elemento.banos}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="% Participación Gastos Comunes">{percentage(elemento.gastosComunesPercentage)}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Nº Fincas catastrales">{elemento.numeroFincasCatastrales}</ShowPane>
              <ShowPane title="Suma sup. catastrales">{area(elemento.sumaSupCatastrales)}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Observaciones">
                <HtmlViewer html={elemento.observaciones}/>
              </ShowPane>
            </div>
          </Card>
        </div>
      </div>

      <Card className="mb-6">
        <div className="flex flex-col gap-4">
          <ShowPane title="Cuadro superficies">
            <ExternalLink to={elemento.cuadroSuperficies}>
              {elemento.cuadroSuperficies}
            </ExternalLink>
          </ShowPane>
          <ShowPane title="Planimetría de referencia">
            <ExternalLink to={elemento.fincaRegistral?.planimetria}>
              {elemento.fincaRegistral?.planimetria}
            </ExternalLink>
          </ShowPane>
        </div>
      </Card>

      <Card className="mb-4">
        <div className="flex items-center w-full gap-4">
          <ButtonLinkSecondary to={`/webinterna/inmuebles?elemento-patrimonial=${elemento.name}`}>Ver inmuebles asociados</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/fincas-registrales?elemento-patrimonial=${elemento.name}`}>Ver fincas registrales asociadas</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/fincas-catastrales?elemento-patrimonial=${elemento.name}`}>Ver fincas catastrales asociadas</ButtonLinkSecondary>
        </div>
      </Card>

    </PageLayout>
  )
}
