import Decimal from 'decimal.js-light';
import currency from 'currency.js';
export function formatNumber(value, excludeSymbol = false, noDecimals = false) {
    if (value !== 0 && !value) {
        return '';
    }
    let opts = {
        symbol: '€',
        name: 'euros',
        decimal: ',',
        separator: '.',
        formatWithSymbol: true,
        pattern: '# !',
        precision: (noDecimals || value % 100 === 0) ? 0 : 2,
    };
    if (excludeSymbol) {
        opts.symbol = '';
    }
    return (value < 0 ? '-' : '') + currency(Math.abs(value), opts).divide(100).format().trim();
}
export function sumAll(args) {
    return args
        .filter(n => !!n)
        .reduce((prev, n) => prev.add(n), new Decimal(0));
}
export function printRatio(n) {
    if (n) {
        return formatNumber(n.toDecimalPlaces(2).toNumber(), true);
    }
    return '';
}
export function printInteger(n) {
    if (n) {
        return formatNumber(n.times(100).toDecimalPlaces(2).toNumber(), true);
    }
    return '';
}
export function printPercentage(n) {
    if (n) {
        return `${formatNumber(n.times(10000).toDecimalPlaces(2).toNumber(), true)}%`;
    }
    return '';
}
export function printMoney(n) {
    if (n) {
        return formatNumber(n.toNumber(), false, true);
    }
    return '';
}
export function printArea(n) {
    if (n) {
        return `${formatNumber(n.times(100).toDecimalPlaces(2).toNumber(), true)} m²`;
    }
    return '';
}
export function parseInteger(n) {
    if (n || n === 0) {
        return new Decimal(`${n}`);
    }
}
export function parsePercentage(n) {
    if (n || n === 0) {
        return new Decimal(`${n}`).dividedBy(10000);
    }
}
