
import React, { useRef } from 'react'

import ButtonPrimary from './ButtonPrimary'


export default function ButtonFile({ className, children, onSelect, accept }) {
  let fileInputRef = useRef()

  function onFileSelected() {
    if (fileInputRef.current.files.length) {
      onSelect(fileInputRef.current.files[0])
    }
  }

  return (
    <>
      <ButtonPrimary
        className={className}
        onClick={() => fileInputRef.current.click()}
      >{children}</ButtonPrimary>
      <input
        hidden
        ref={fileInputRef}
        type="file"
        onChange={onFileSelected}
        accept={accept}
      />
    </>
  )
}
