
import React from 'react'
import firstBy from 'thenby'
import { Link, useLocation } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeGreen from 'components/badges/BadgeGreen'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'
import ExportCSV from 'components/ExportCSV'
import { parseDate } from 'platform/datetime'
import { datetime, decimal, percentage } from 'platform/filters'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())

const TIPO_DERECHO = {
  TIPO_DERECHO_PLENO_DOMINIO: 'Pleno dominio',
  TIPO_DERECHO_NUDA_PROPIEDAD: 'Nuda propiedad',
  TIPO_DERECHO_USUFRUCTO: 'Usufructo',
}


export default function FincasRegistralesList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  let location = useLocation()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('nombre'),
      },
      {
        name: 'Por código',
        sort: firstBy('codigo').thenBy('nombre'),
      },
    ])

    let { fincas } = await fincasRegistralesClient.List()
    if (!fincas) {
      fincas = []
    }

    let names = fincas
      .filter(finca => finca.inmueble)
      .map(finca => finca.inmueble)
    let { inmuebles } = await inmueblesClient.BatchGet({ names: uniq(names) })
    if (!inmuebles) {
      inmuebles = []
    }
    

    if (location.search) {
      let params = new URLSearchParams(location.search)
      if (params.get('inmueble')) {
        fincas = fincas.filter(fincaRegistral => fincaRegistral.inmueble === params.get('inmueble'))
      }
      if (params.get('elemento-patrimonial')) {
        let elementoPatrimonial = await elementosPatrimonialesClient.Get({ name: params.get('elemento-patrimonial') })
        fincas = fincas.filter(fincaRegistral => fincaRegistral.name === elementoPatrimonial.fincaRegistral)
      }
      if (params.get('finca-catastral')) {
        let fincaCatastral = await fincasCatastralesClient.Get({ name: params.get('finca-catastral') })
        fincas = fincas.filter(fincaRegistral => fincaRegistral.name === fincaCatastral.fincaRegistral)
      }
    }

    names = inmuebles
      .filter(inmueble => inmueble.municipio)
      .map(inmueble => inmueble.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(names) })
    if (!municipios) {
      municipios = []
    }

    names = municipios.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(names) })

    names = inmuebles.filter(inmueble => inmueble.grupo).map(inmueble => inmueble.grupo)
    let { grupos } = await gruposInmobiliariosClient.BatchGet({ names: uniq(names) })

    names = fincas.map(finca => finca.registroPropiedad)
    let { registros } = await registrosPropiedadClient.BatchGet({ names: uniq(names) })

    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    grupos = keyBy(grupos, 'name')
    registros = keyBy(registros, 'name')

    inmuebles.forEach(inmueble => {
      inmueble.municipio = municipios[inmueble.municipio]
      inmueble.provincia = provincias[inmueble.municipio?.provincia]
      inmueble.grupo = grupos[inmueble.grupo]
    })
    inmuebles = keyBy(inmuebles, 'name')

    fincas.forEach(finca => {
      finca.inmueble = inmuebles[finca.inmueble]
      finca.registroPropiedad = registros[finca.registroPropiedad]
      finca.$search = [
        finca.codigo,
        finca.nombre,
      ]
    })
    setRows(fincas)
  })

  function getFincasRegistralesCSV(rows) {
    return rows.map(p => {
      return {
        'Código': p.codigo,
        'Nº finca': p.numeroFinca,
        'Reg. propiedad': p.registroPropiedad.nombre,
        'Denominación GUI': p.inmueble.grupo?.nombre,
        'Código inmueble': p.inmueble.codigo,
        'Denominación inmueble': p.inmueble.nombre,
        'Titular': p.inmueble.titular?.nombre,
        'Identidad titular': p.inmueble.titular?.identidad,
        'Protocolo': p.protocolo,
        'Tipo derecho': TIPO_DERECHO[p.tipoDerecho],
        'Propiedad': percentage(p.propiedadPercentage),
        'Usufructo': percentage(p.usufructoPercentage),
        'Valor EP': percentage(p.valorEp),
        'Superficie registral suelo': `${decimal(p.superficieRegistralSuelo)} m²`,
        'Superficie registral edificación': `${decimal(p.superficieRegistralEdificacion)} m²`,
        'Planimetría': p.planimetria,
        'Estado': p.estado === 'ESTADO_PROPIEDAD' ? 'Propiedad' : 'Baja',
        'Estado inscripción': p.estadoInscripcion === 'ESTADO_INSCRIPCION_INSCRITA' ? 'Inscrita' : 'No inscrita',
        'IDUFIR': p.idufir,
        'Alta contable': datetime(parseDate(p.altaContableDate), 'DATE'),
        'Fecha inicio registro contable': datetime(parseDate(p.inicioContableDate), 'DATE'),
        'Fecha fin registro contable': datetime(parseDate(p.finContableDate), 'DATE'),
        'Alta jurídica': datetime(parseDate(p.altaJuridicaDate), 'DATE'),
        'Baja jurídica': datetime(parseDate(p.bajaJuridicaDate), 'DATE'),
        'Fecha de alta': datetime(parseDate(p.altaDate), 'DATE'),
        'Fecha de baja': datetime(parseDate(p.bajaDate), 'DATE'),
        'Observaciones': p.observaciones,
        'Notas simples': p.notasSimples,
        'Acitvo fijo': p.activoFijo ? 'Sí' : 'No',
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.fincas_registrales.propose-edit">
            <ButtonLinkPrimary to="/webinterna/fincas-registrales/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Finca Registral
            </ButtonLinkPrimary>
          </Acl>
          <ExportCSV
            className="ml-3"
            data={getFincasRegistralesCSV(table.filteredRows)}
            filename="Fincas-registrales"
          />
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-registrales">Fincas Registrales</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell>Código</HeaderCell>
            <HeaderCell>Nº Finca</HeaderCell>
            <HeaderCell>Reg. Propiedad</HeaderCell>
            <HeaderCell>Denominación GUI</HeaderCell>
            <HeaderCell>Unidad Inmobiliaria</HeaderCell>
            <HeaderCell>Titular</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell>IDUFIR</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/fincas-registrales/${row.name}`}>
            <div>
              {row.codigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.numeroFinca}</span>
              <span className="ml-2">
                {row.estado === 'ESTADO_BAJA' && <BadgeGray>Baja</BadgeGray>}
                {row.estado === 'ESTADO_PROPIEDAD' && <BadgeGreen>Propiedad</BadgeGreen>}
              </span>
            </div>
            <div className="mt-1">
              <Icon regular name="building" className="mr-3 text-gray-400" />
              {row.municipio?.nombre} &mdash; {row.provincia?.nombre}
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell className="font-medium">
              {row.codigo}
            </Cell>
            <Cell>
              <Link
                to={`/webinterna/fincas-registrales/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.numeroFinca}
              </Link>
            </Cell>
            <Cell>
              {row.registroPropiedad?.nombre}
            </Cell>
            <Cell>
              {row.inmueble?.grupo?.nombre}
            </Cell>
            <Cell>
              <div>
                <Link
                  to={`/webinterna/inmuebles/${row.inmueble.name}`}
                  className="font-medium text-gray-900"
                >
                  {row.inmueble?.codigo}
                  &nbsp;&mdash;&nbsp;
                  {row.inmueble?.nombre}
                </Link>
              </div>
              <div>
                {row.inmueble?.municipio?.nombre}
                &nbsp;&mdash;&nbsp;
                {row.inmueble?.provincia?.nombre}
              </div>
            </Cell>
            <Cell>
              {row.inmueble.titular && <PeopleViewer value={row.inmueble.titular} className="w-56"/>}
            </Cell>
            <Cell>
              <div className="space-x-2">
                {row.estado === 'ESTADO_BAJA' && <BadgeGray>Baja</BadgeGray>}
                {row.estado === 'ESTADO_PROPIEDAD' && <BadgeGreen>Propiedad</BadgeGreen>}
                {row.estadoInscripcion === 'ESTADO_INSCRIPCION_NO_INSCRITA' && <BadgeGray>No inscrita</BadgeGray>}
                {row.estadoInscripcion === 'ESTADO_INSCRIPCION_INSCRITA' && <BadgeGreen>Inscrita</BadgeGreen>}
              </div>
            </Cell>
            <Cell>
              {row.idufir}
            </Cell>
            <Cell>
              <Acl perm="webinterna.fincas_registrales.propose-edit">
                <Link to={`/webinterna/fincas-registrales/edit/${row.name}`}>Editar</Link>
              </Acl>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
