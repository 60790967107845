
import React, { useState } from 'react'
import cx from 'classnames'
import { Transition } from '@headlessui/react'

import ClickOutside from 'components/ClickOutside'


export function Dropdown({ trigger, children, className }) {
  let [open, setOpen] = useState(false)

  return (
    <div className={cx('relative inline-block text-left', className)}>
      <ClickOutside onClick={() => setOpen(false)}>
        <div onClick={() => setOpen(!open)}>{trigger}</div>
      </ClickOutside>

      <Transition
        show={open}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="origin-top-right absolute right-0 mt-1 w-72 rounded-md shadow-lg z-30"
      >
        <div className="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {children}
          </div>
        </div>
      </Transition>
    </div>
  )
}


export function DropdownMenuItem({ children, className, onClick }) {
  return (
    <button
      onClick={() => onClick()}
      className={cx(
        'w-full block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 whitespace-nowrap text-left',
        className,
      )}
    >
      {children}
    </button>
  )
}
