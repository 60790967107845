import React from 'react'
import firstBy from 'thenby'
import { Link, useLocation } from 'react-router-dom'
import { uniq, keyBy } from 'lodash-es'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeGreen from 'components/badges/BadgeGreen'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { datetime, decimal, percentage, price } from 'platform/filters'
import PeopleViewer from 'components/people/PeopleViewer'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import ExportCSV from 'components/ExportCSV'
import { parseDate } from 'platform/datetime'
import { prepareSearchPersonasSociedades, searchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())

const TIPO_FINCA = {
  TIPO_FINCA_URBANA: 'Finca urbana',
  TIPO_FINCA_RUSTICA: 'Finca rústica',
  TIPO_FINCA_ESPECIALES: 'Finca especial',
}


export default function FincasCatastralesList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  let location = useLocation()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('nombre'),
      },
      {
        name: 'Por código',
        sort: firstBy('codigo').thenBy('nombre'),
      },
    ])

    let fincasCatastrales = await fincasCatastralesClient.List().then(reply => reply.fincas)
    if (!fincasCatastrales) {
      setRows([])
      return
    }

    let names = fincasCatastrales
      .filter(finca => finca.fincaRegistral)
      .map(finca => finca.fincaRegistral)
    let fincasRegistrales = await fincasRegistralesClient.BatchGet({ names: uniq(names) }).then(reply => reply.fincas)

    names = fincasRegistrales
      .filter(finca => finca.inmueble)
      .map(finca => finca.inmueble)
    let { inmuebles } = await inmueblesClient.BatchGet({ names: uniq(names) })

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()

    fincasCatastrales.forEach(fc => {
      let ep = elementosPatrimoniales.filter(ep => ep.fincaRegistral === fc.fincaRegistral)
      if (ep.length) {
        fc.denominacionEP = ep[0].nombre
      }
    })

    names = inmuebles
      .filter(inmueble => inmueble.municipio)
      .map(inmueble => inmueble.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(names) })

    names = municipios.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(names) })

    names = inmuebles.filter(inmueble => inmueble.grupo).map(inmueble => inmueble.grupo)
    let { grupos } = await gruposInmobiliariosClient.BatchGet({ names: uniq(names) })

    names = inmuebles.filter(i => i.titular).map(i => i.titular)
    let titularesSearch  = await prepareSearchPersonasSociedades(names)

    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    grupos = keyBy(grupos, 'name')

    inmuebles.forEach(inmueble => {
      inmueble.municipio = municipios[inmueble.municipio]
      inmueble.provincia = provincias[inmueble.municipio?.provincia]
      inmueble.grupo = grupos[inmueble.grupo]
    })
    inmuebles = keyBy(inmuebles, 'name')

    fincasRegistrales.forEach(fincaR => {
      fincaR.inmueble = inmuebles[fincaR.inmueble]
    })

    fincasRegistrales = keyBy(fincasRegistrales, 'name')

    fincasCatastrales.forEach(fincaC => {
      fincaC.fincaRegistral = fincasRegistrales[fincaC.fincaRegistral]
    })

    if (location.search) {
      let params = new URLSearchParams(location.search)
      if (params.get('inmueble')) {
        fincasCatastrales = fincasCatastrales.filter(fc => fc.fincaRegistral?.inmueble?.name === params.get('inmueble'))
      }
      if (params.get('finca-registral')) {
        fincasCatastrales = fincasCatastrales.filter(fc => fc.fincaRegistral?.name === params.get('finca-registral'))
      }
      if (params.get('elemento-patrimonial')) {
        let elementoPatrimonial = elementosPatrimoniales[params.get('elemento-patrimonial')]
        fincasCatastrales = fincasCatastrales.filter(fc => fc.fincaRegistral?.name === elementoPatrimonial.fincaRegistral)
      }
    }

    fincasCatastrales.forEach(fincaC => {
      fincaC.$search = [
        fincaC.codigo,
        fincaC.nombre,
        fincaC.fincaRegistral?.inmueble?.grupo?.nombre,
        ...searchPersonasSociedades(titularesSearch, fincaC.fincaRegistral?.inmueble?.titular),
        fincaC.fincaRegistral?.numeroFinca,
      ]
    })
    setRows(fincasCatastrales)
  })

  function getFincasCatastralesCSV(rows) {
    return rows.map(p => {
      return {
        'Finca': p.nombre,
        'Código': p.codigo,
        'Inmueble': p.fincaRegistral.inmueble.nombre,
        'Código inmueble': p.fincaRegistral.inmueble.codigo,
        'Grupo inmobiliario': p.fincaRegistral.inmueble?.grupo?.nombre,
        'Asociaciones F. Reg': p.fincaRegistral.numeroFinca,
        'Asociaciones EP': p.denominacionEP,
        'Titular': p.fincaRegistral.inmueble.titular?.nombre,
        'Identidad titular': p.fincaRegistral.inmueble.titular?.identidad,
        'Tipo finca': TIPO_FINCA[p.tipoFinca],
        'Estado': p.estado === 'ESTADO_BAJA' ? 'Baja' : 'Propiedad',
        'Ref. catastral': p.referenciaCatastral,
        'Superficie catastral total': `${decimal(p.valorCatastral)} m²`,
        '% Propiedad': percentage(p.propiedadPercentage),
        'Superficie parcial': `${decimal(p.superficieParcial)} m²`,
        'Tipo de finca': TIPO_FINCA[p.tipoFinca],
        'Planimetría': p.planimetria,
        'Cuadro de superficies': p.cuadroSuperficies,
        'Alta jurídica': datetime(parseDate(p.fincaRegistral.altaJuridicaDate), 'DATE'),
        'Baja jurídica': datetime(parseDate(p.fincaRegistral.bajaJuridicaDate), 'DATE'),
        'Fecha de alta': datetime(parseDate(p.fincaRegistral.altaDate), 'DATE'),
        'Fecha de baja': datetime(parseDate(p.fincaRegistral.bajaDate), 'DATE'),
        'Valor catastral': price(p.valorCatastral),
        'Fecha valoración': datetime(parseDate(p.valoracionDate), 'DATE'),
        'Observaciones': p.observaciones,
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.fincas_catastrales.propose-edit">
            <ButtonLinkPrimary to="/webinterna/fincas-catastrales/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Finca Catastral
            </ButtonLinkPrimary>
          </Acl>
          <ExportCSV
            className="ml-3"
            data={getFincasCatastralesCSV(table.filteredRows)}
            filename="Fincas-catastrales"
          />
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-catastrales">Fincas Catastrales</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell>Finca</HeaderCell>
            <HeaderCell>Unidad Inmobiliaria</HeaderCell>
            <HeaderCell>Asociaciones</HeaderCell>
            <HeaderCell>Titular</HeaderCell>
            <HeaderCell>Tipo Finca</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell>Ref. Catastral</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/fincas-catastrales/${row.name}`}>
            <div>
              {row.codigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.nombre}</span>
              <span className="ml-2">
                {row.fincaRegistral?.inmueble?.grupo?.nombre}
              </span>
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell>
              <Link
                to={`/webinterna/fincas-catastrales/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.nombre}
              </Link>
              <div className="font-medium">
                {row.codigo}
              </div>
            </Cell>
            <Cell>
              <Link
                to={`/webinterna/inmuebles/${row.fincaRegistral?.inmueble?.name}`}
                className="font-medium text-gray-900"
              >
                {row.fincaRegistral?.inmueble?.codigo}
                &nbsp;&mdash;&nbsp;
                {row.fincaRegistral?.inmueble?.nombre}
              </Link>
              <div>
                {row.fincaRegistral?.inmueble?.grupo?.nombre}
              </div>
            </Cell>
            <Cell>
              <div>
                F. Reg:
                &nbsp;
                {row.fincaRegistral?.numeroFinca}
              </div>
              <div>
                EP:
                &nbsp;
                {row.denominacionEP}
              </div>
            </Cell>
            <Cell>
              {row.fincaRegistral?.inmueble?.titular && <PeopleViewer value={row.fincaRegistral.inmueble.titular} className="w-56"/>}
            </Cell>
            <Cell>
              {row.tipoFinca}
            </Cell>
            <Cell>
              {row.fincaRegistral?.estado === 'ESTADO_BAJA' && <BadgeGray>Baja</BadgeGray>}
              {row.fincaRegistral?.estado === 'ESTADO_PROPIEDAD' && <BadgeGreen>Propiedad</BadgeGreen>}
            </Cell>
            <Cell>
              <div>
                {row.referenciaCatastral}
              </div>
              <div>
                <Icon regular name="chart-area" className="text-gray-400"/>
                &nbsp;{decimal(row.superficieParcial)} m²
              </div>
            </Cell>
            <Cell>
              <Acl perm="webinterna.fincas_catastrales.propose-edit">
                <Link to={`/webinterna/fincas-catastrales/edit/${row.name}`}>Editar</Link>
              </Acl>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
