
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'
import { get, erase } from '@altipla/browser-cookies-module'

import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { setCrossSite } from 'auth/cookies'


let redirectUri = `https://auther.dlege.altipla.consulting/accounts/login`
let state = `${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${window.location.host}/accounts/login`
let instance = new PublicClientApplication({
  auth: {
    clientId: 'c25a8a8b-a30d-4844-989c-995b2d77351a',
    authority: 'https://login.microsoftonline.com/09f2812f-05c8-4de5-bad9-2e5acce3965b',
    redirectUri,
    postLogoutRedirectUri: `${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${window.location.host}`,
  },
})
const usersClient = new UsersClient(users())

let accountInfoData


function parseJWT(token) {
  let base64 = token
    .split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/')
  let payload = atob(base64)
    .split('')
    .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    .join('')

  return JSON.parse(decodeURIComponent(payload))
}


export function bearerToken() {
  return get('token')
}


async function loadProfile() {
  accountInfoData = await usersClient.Profile()
  accountInfoData.permissions = accountInfoData.permissions || []
  let payload = parseJWT(bearerToken())
  accountInfoData.expires = new Date(payload.exp * 1000)
}


export async function login() {
  if (bearerToken()) {
    await loadProfile()
    return
  }

  await instance.handleRedirectPromise()
  
  let scopes = ['openid', 'email', 'User.Read']
  let accounts = instance.getAllAccounts()
  if (!accounts || !accounts.length || !accounts[0].homeAccountId) {
    instance.loginRedirect({ scopes, state })
    return new Promise(() => { })
  }
  
  let token
  try {
    token = await instance.acquireTokenSilent({
      scopes,
      account: accounts[0],
      redirectUri,
      state,
    })
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect({
        scopes,
        loginHint: accounts[0].username,
        redirectUri,
        state,
      })
    } else {
      throw err
    }
  }

  let reply = await usersClient.Login({
    accessToken: token.accessToken,
  })
  
  let payload = parseJWT(reply.token)
  setCrossSite('token', reply.token, {
    expires: new Date(payload.exp * 1000),
  })

  await loadProfile()
}


export async function logout() {
  erase('token')
  instance.logoutRedirect()
  return new Promise(() => { })
}


export function accountInfo() {
  return accountInfoData
}
