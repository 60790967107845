
import React from 'react'
import cx from 'classnames'

import Icon from 'components/Icon'


export default function ExternalLink({ children, className, to, hoverIcon }) {
  return (
    <>
      {!!to &&
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={cx(
            'flex',
            className,
            {
              group: hoverIcon,
            },
          )}
        >
          <span className="overflow-hidden overflow-ellipsis">{children}</span>
          <Icon
            regular
            name="external-link"
            className={cx(
              'ml-2',
              {
                'invisible group-hover:visible': hoverIcon,
              },
            )}
          />
        </a>}
    </>
  )
}
