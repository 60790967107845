// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Docs/entities/entities.proto

import { Caller } from '@altipla/grpc-browser';


export default class EntitiesClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  Get(req) {
    return this._caller.send('GET', `/entities`, req, false, []);
  }

  List(req) {
    return this._caller.send('GET', `/entities/${req.collection}`, req, false, ['collection']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/entities:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/entities`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/entities`, req, true, []);
  }

  RunQuery(req) {
    return this._caller.send('POST', `/entities:runQuery`, req, true, []);
  }
};

