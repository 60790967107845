
import React from 'react'
import cx from 'classnames'

import { isEmpty } from 'platform/react'


export function Table({ header, children, empty, className }) {
  return (
    <div className={cx('flex flex-col', className)}>
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>{header}</tr>
              </thead>
              <tbody>{children}</tbody>
              {isEmpty(children) &&
                <tbody>
                  <tr>
                    <td colSpan={header.props.children.length} className="bg-white text-center text-lg italic text-gray-400 py-20">{empty}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}


export function TableRow({ children, className }) {
  return (
    <tr className={cx('odd:bg-white even:bg-gray-50', className)}>{children}</tr>
  )
}


export function HeaderCell({ children, className, fit = 'none' }) {
  return (
    <th className={cx(
      'py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 uppercase tracking-wider whitespace-nowrap',
      {
        'pl-6': fit === 'right',
        'pr-6': fit === 'left',
        'px-6': fit === 'none',
        '': fit === 'both',
      },
      className,
    )}>{children}</th>
  )
}


export function Cell({ children, className, fit = 'none', actions, verticalFit }) {
  return (
    <td
      className={cx(
        'whitespace-nowrap text-sm text-gray-500',
        {
          'pl-6': fit === 'right',
          'pr-6': fit === 'left',
          'px-6': fit === 'none',
          '': fit === 'both',
          'py-4': !actions && !verticalFit,
          'py-2': verticalFit,
          'align-middle text-right space-x-3': actions,
        },
        className,
      )}
    >
      {children}  
    </td>
  )
}
