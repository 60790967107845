// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/provincias/provincias.proto

import { Caller } from '@altipla/grpc-browser';


export default class ProvinciasClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/provincias`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/provincias/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/provincias:batchGet`, req, true, []);
  }
};

