
import React, { useState, useEffect } from 'react'

import Icon from 'components/Icon'
import Face from 'components/people/Face'
import ButtonAsyncWhite from 'components/buttons/ButtonAsyncWhite'
import UserSelector from 'components/UserSelector'


export default function User({ label, className, value, users, onChange }) {
  if (!users) {
    users = []
  }

  let [open, setOpen] = useState(false)
  let [selected, setSelected] = useState(null)

  useEffect(() => {
    if (value) {
      setSelected(users.find(u => u.name === value))
    }
  }, [users, value])

  return (
    <>
      <div className={className}>
        <label className="block font-medium text-gray-700 text-sm">{label}</label>
        <div className="bg-white my-2 shadow md:rounded-lg">
          <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:items-center">
              {selected && selected.email && <Face displayName={selected.displayName || selected.email} pictureUrl={selected.pictureUrl}></Face>}
              {(!selected || !selected.email) &&
                <span className="fa-stack fa-lg text-gray-200">
                  <Icon solid name="camera" className="fa-stack-1x" />
                  <Icon solid name="ban" className="fa-stack-2x" />
                </span>
              }
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <div className="text-sm font-medium text-gray-900">
                  {selected?.displayName}
                </div>
                <div className="text-sm text-gray-600">
                  {selected?.email}
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-6 md:flex-shrink-0">
              <ButtonAsyncWhite onClick={() => setOpen(true)}>
                <Icon solid name="user" className="mr-2" />
                Editar
              </ButtonAsyncWhite>
            </div>
          </div>
        </div>
      </div>

      <UserSelector
        open={open}
        onClose={() => setOpen(false)}
        onSelect={user => {
          setSelected(user)
          onChange(user.name)
        }}
        users={users}
        header="Asignar Gestor de LOPD"
      ></UserSelector>
    </>
  )
}
