
import React from 'react'


export function InfoPane({ className, children, title }) {
  return (
    <dl className={className}>
      <dt className="text-sm font-medium text-gray-400">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">{children}</dd>
    </dl>
  )
}
