
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

import Throbber from 'components/Throbber'
import Icon from 'components/Icon'


export default function PdfPreview({ url }) {
  let [numPages, setNumPages] = useState(null)
  let [currentPage, setCurrentPage] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setCurrentPage(1)
  }

  return (
    <div className="flex flex-col content-center justify-center h-133.5">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Throbber />}
        error={<div class="pl-6 pt-3">Error al cargar el documento</div>}
        noData={''}
      >
        <Page
          pageNumber={currentPage}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          height={490}
          loading={<Throbber />}
        />
      </Document>
      {numPages > 1 &&
        <div className="flex justify-center mt-5">
          <button
            className="focus:outline-none"
            type="button"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <Icon solid name="long-arrow-left" className="text-gray-400" />
          </button>
          <span className="mx-5 w-32 text-center select-none">Página {currentPage} de {numPages}</span>
          <button
            className="focus:outline-none"
            type="button"
            disabled={currentPage >= numPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <Icon solid name="long-arrow-right" className="text-gray-400" />
          </button>
        </div>
      }
    </div>
  )
}
