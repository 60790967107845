import { shallowReactive, ref, computed } from '@vue/reactivity';
import { shallowRef, watch } from '@vue/runtime-core';
import { nanoid } from 'nanoid';
import Decimal from 'decimal.js-light';
import { parseInteger, parsePercentage, sumAll } from "./operations.js";
import { getUso } from "./storage.js";
export function createPresupuesto(val) {
    var _a, _b, _c, _d;
    let superficiesList = shallowReactive(((_b = (_a = val === null || val === void 0 ? void 0 : val.superficies) === null || _a === void 0 ? void 0 : _a.superficies) === null || _b === void 0 ? void 0 : _b.map(superficie => createSuperficie(superficie))) || []);
    let superficies = {
        superficies: superficiesList,
        suelo: ref(parseInteger((_c = val === null || val === void 0 ? void 0 : val.superficies) === null || _c === void 0 ? void 0 : _c.suelo)),
        edificabilidadMax: ref(parseInteger((_d = val === null || val === void 0 ? void 0 : val.superficies) === null || _d === void 0 ? void 0 : _d.edificabilidadMax)),
        superficieConstruida: computed(() => {
            return sumAll(superficiesList.map(superficie => superficie.superficieConstruida.value));
        }),
        totalUnidades: computed(() => {
            return sumAll(superficiesList.map(superficie => superficie.unidades.value));
        }),
        srSuperficie: computed(() => {
            return sumAll(superficiesList.map(superficie => superficie.srSuperficie.value));
        }),
        brSuperficie: computed(() => {
            return sumAll(superficiesList.map(superficie => superficie.brSuperficie.value));
        }),
    };
    let costes = createCostes(superficies, val);
    let ingresos = createIngresos(superficies, costes, val);
    let otrosGastos = createOtrosGastos(superficies, costes, ingresos, val);
    return {
        superficies,
        costes,
        ingresos,
        otrosGastos,
        resumen: createResumen(superficies, costes, ingresos, otrosGastos, val),
    };
}
export function addSuperficie(superficies, val) {
    superficies.superficies.push(createSuperficie(val));
}
export function removeSuperficie(superficies, index) {
    superficies.superficies.splice(index, 1);
}
function createSuperficie(val) {
    let sr = ref(parsePercentage(val.sr));
    let br = computed(() => sr.value ? new Decimal(1).sub(sr.value) : new Decimal(1));
    let superficieConstruida = ref(parseInteger(val.superficieConstruida));
    return {
        name: nanoid(),
        superficieConstruida,
        sr,
        unidades: ref(parseInteger(val.unidades)),
        s1d: ref(parseInteger(val.s1d)),
        s2d: ref(parseInteger(val.s2d)),
        s3d: ref(parseInteger(val.s3d)),
        s4d: ref(parseInteger(val.s4d)),
        nombre: ref(val.nombre || ''),
        uso: val.uso,
        br,
        srSuperficie: computed(() => {
            if (superficieConstruida.value && sr.value) {
                return superficieConstruida.value.times(sr.value);
            }
        }),
        brSuperficie: computed(() => {
            if (superficieConstruida.value && br.value) {
                return superficieConstruida.value.times(br.value);
            }
        }),
    };
}
function createCosteEdificacion(superficie, row, val) {
    var _a, _b, _c, _d;
    let ratioValue = ref(parseInteger(((_a = val === null || val === void 0 ? void 0 : val.costes) === null || _a === void 0 ? void 0 : _a.edificacion) ? (_b = val === null || val === void 0 ? void 0 : val.costes) === null || _b === void 0 ? void 0 : _b.edificacion[row].ratio : undefined));
    let importeValue = ref(parseInteger(((_c = val === null || val === void 0 ? void 0 : val.costes) === null || _c === void 0 ? void 0 : _c.edificacion) ? (_d = val === null || val === void 0 ? void 0 : val.costes) === null || _d === void 0 ? void 0 : _d.edificacion[row].importe : undefined));
    let base = computed(() => superficie.superficieConstruida.value);
    return {
        superficie: superficie.name,
        nombre: superficie.nombre.value,
        ratioValue,
        importeValue,
        base,
        ratio: computed(() => {
            if (ratioValue.value) {
                return ratioValue.value;
            }
            return importeValue.value && base.value && !base.value.isZero() ? importeValue.value.div(base.value) : undefined;
        }),
        importe: computed(() => {
            if (importeValue.value) {
                return importeValue.value;
            }
            return ratioValue.value && base.value ? base.value.times(ratioValue.value) : undefined;
        }),
    };
}
function createCosteMobiliario(superficie, row, val) {
    var _a, _b, _c, _d;
    let base = computed(() => superficie.unidades.value);
    let ratioValue = ref(parseInteger(((_a = val === null || val === void 0 ? void 0 : val.costes) === null || _a === void 0 ? void 0 : _a.mobiliarioEquipamiento) ? (_b = val === null || val === void 0 ? void 0 : val.costes) === null || _b === void 0 ? void 0 : _b.mobiliarioEquipamiento[row].ratio : undefined));
    let importeValue = ref(parseInteger(((_c = val === null || val === void 0 ? void 0 : val.costes) === null || _c === void 0 ? void 0 : _c.mobiliarioEquipamiento) ? (_d = val === null || val === void 0 ? void 0 : val.costes) === null || _d === void 0 ? void 0 : _d.mobiliarioEquipamiento[row].importe : undefined));
    return {
        superficie: superficie.name,
        nombre: superficie.nombre.value,
        base,
        ratioValue,
        importeValue,
        ratio: computed(() => {
            if (ratioValue.value) {
                return ratioValue.value;
            }
            return importeValue.value && base.value && !base.value.isZero() ? importeValue.value.div(base.value) : undefined;
        }),
        importe: computed(() => {
            if (importeValue.value) {
                return importeValue.value;
            }
            return ratioValue.value && base.value ? base.value.times(ratioValue.value) : undefined;
        }),
    };
}
function createCosteFitOuts(superficie, row, val) {
    var _a, _b, _c, _d;
    let base = computed(() => superficie.superficieConstruida.value);
    let ratioValue = ref(parseInteger(((_a = val === null || val === void 0 ? void 0 : val.costes) === null || _a === void 0 ? void 0 : _a.fitOuts) ? (_b = val === null || val === void 0 ? void 0 : val.costes) === null || _b === void 0 ? void 0 : _b.fitOuts[row].ratio : undefined));
    let importeValue = ref(parseInteger(((_c = val === null || val === void 0 ? void 0 : val.costes) === null || _c === void 0 ? void 0 : _c.fitOuts) ? (_d = val === null || val === void 0 ? void 0 : val.costes) === null || _d === void 0 ? void 0 : _d.fitOuts[row].importe : undefined));
    return {
        superficie: superficie.name,
        nombre: superficie.nombre.value,
        base,
        ratioValue,
        importeValue,
        ratio: computed(() => {
            if (ratioValue.value) {
                return ratioValue.value;
            }
            return importeValue.value && base.value && !base.value.isZero() ? importeValue.value.div(base.value) : undefined;
        }),
        importe: computed(() => {
            if (importeValue.value) {
                return importeValue.value;
            }
            return ratioValue.value && base.value ? base.value.times(ratioValue.value) : undefined;
        }),
    };
}
function importeCostesRef(costes) {
    return sumAll(costes.map(coste => {
        if (coste.importeValue.value) {
            return coste.importeValue.value;
        }
        if (coste.ratioValue.value && coste.base.value) {
            return coste.base.value.times(coste.ratioValue.value);
        }
    }));
}
function createCostes(superficies, val) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    let importeSuelo = ref(parseInteger((_a = val === null || val === void 0 ? void 0 : val.costes) === null || _a === void 0 ? void 0 : _a.suelo));
    let importeUrbanizacion = ref(parseInteger((_b = val === null || val === void 0 ? void 0 : val.costes) === null || _b === void 0 ? void 0 : _b.urbanizacion));
    let costesEdificacion = shallowRef(superficies.superficies.map((superficie, row) => createCosteEdificacion(superficie, row, val)));
    let importeEdificacion = computed(() => importeCostesRef(costesEdificacion.value));
    let edificacion = {
        nombre: 'Edificación',
        base: computed(() => superficies.superficieConstruida.value),
        costes: costesEdificacion,
        importe: importeEdificacion,
        ratio: computed(() => {
            if (importeEdificacion.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
                return importeEdificacion.value.div(superficies.superficieConstruida.value);
            }
        }),
    };
    let costesMobiliario = shallowRef(superficies.superficies.map((superficie, row) => createCosteMobiliario(superficie, row, val)));
    let importeMobiliario = computed(() => importeCostesRef(costesMobiliario.value));
    let mobiliarioEquipamiento = {
        nombre: 'Mobiliario y equipamiento',
        costes: costesMobiliario,
        importe: importeMobiliario,
    };
    let costesFitOuts = shallowRef(superficies.superficies.map((superficie, row) => createCosteFitOuts(superficie, row, val)));
    let importeFitOuts = computed(() => importeCostesRef(costesFitOuts.value));
    let fitOuts = {
        nombre: 'Fit-outs',
        costes: costesFitOuts,
        importe: importeFitOuts,
    };
    let ratioTasasCIO = ref(parsePercentage((_d = (_c = val === null || val === void 0 ? void 0 : val.costes) === null || _c === void 0 ? void 0 : _c.legales) === null || _d === void 0 ? void 0 : _d.tasasCIO));
    let ratioObraNueva = ref(parsePercentage((_f = (_e = val === null || val === void 0 ? void 0 : val.costes) === null || _e === void 0 ? void 0 : _e.legales) === null || _f === void 0 ? void 0 : _f.obraNueva));
    let ratioDivisionHorizontal = ref(parsePercentage((_h = (_g = val === null || val === void 0 ? void 0 : val.costes) === null || _g === void 0 ? void 0 : _g.legales) === null || _h === void 0 ? void 0 : _h.divisionHorizontal));
    let ratioOtrosGastos = ref(parsePercentage((_k = (_j = val === null || val === void 0 ? void 0 : val.costes) === null || _j === void 0 ? void 0 : _j.legales) === null || _k === void 0 ? void 0 : _k.otrosGastos));
    let importeTasasCIO = computed(() => {
        if (importeEdificacion.value && ratioTasasCIO.value) {
            return importeEdificacion.value.times(ratioTasasCIO.value);
        }
    });
    let importeObraNueva = computed(() => {
        if (importeEdificacion.value && ratioObraNueva.value) {
            return importeEdificacion.value.times(ratioObraNueva.value);
        }
    });
    let baseDivisionHorizontal = computed(() => {
        if (importeEdificacion.value && importeSuelo.value) {
            return importeEdificacion.value.add(importeSuelo.value);
        }
    });
    let importeDivisionHorizontal = computed(() => {
        if (baseDivisionHorizontal.value && ratioDivisionHorizontal.value) {
            return baseDivisionHorizontal.value.times(ratioDivisionHorizontal.value);
        }
    });
    let importeOtrosGastos = computed(() => {
        if (importeEdificacion.value && ratioOtrosGastos.value) {
            return importeEdificacion.value.times(ratioOtrosGastos.value);
        }
    });
    let importeLegales = computed(() => {
        return sumAll([
            importeTasasCIO.value,
            importeObraNueva.value,
            importeDivisionHorizontal.value,
            importeOtrosGastos.value,
        ]);
    });
    let importeProyectoSSTT = computed(() => {
        if (importeEdificacion.value && ratioProyectoSSTT.value) {
            return importeEdificacion.value.times(ratioProyectoSSTT.value);
        }
    });
    let ratioProyectoSSTT = ref(parsePercentage((_l = val === null || val === void 0 ? void 0 : val.costes) === null || _l === void 0 ? void 0 : _l.proyectoSSTT));
    watch(() => superficies.superficies.length, () => {
        edificacion.costes.value = superficies.superficies.map((superficie, row) => {
            let coste = edificacion.costes.value.find(c => c.superficie === superficie.name);
            return coste || createCosteEdificacion(superficie, row, val);
        });
        mobiliarioEquipamiento.costes.value = superficies.superficies.map((superficie, row) => {
            let coste = mobiliarioEquipamiento.costes.value.find(c => c.superficie === superficie.name);
            return coste || createCosteMobiliario(superficie, row, val);
        });
        fitOuts.costes.value = superficies.superficies.map((superficie, row) => {
            let coste = fitOuts.costes.value.find(c => c.superficie === superficie.name);
            return coste || createCosteFitOuts(superficie, row, val);
        });
    }, { immediate: true, flush: 'sync' });
    let importeTotal = computed(() => {
        return sumAll([
            importeSuelo.value,
            importeUrbanizacion.value,
            importeEdificacion.value,
            importeMobiliario.value,
            importeFitOuts.value,
            importeLegales.value,
            importeProyectoSSTT.value,
        ]);
    });
    return {
        suelo: {
            nombre: 'Suelo (m² techo)',
            importe: importeSuelo,
            base: computed(() => superficies.edificabilidadMax.value),
            ratio: computed(() => {
                if (importeSuelo.value && superficies.edificabilidadMax.value && !superficies.edificabilidadMax.value.isZero()) {
                    return importeSuelo.value.div(superficies.edificabilidadMax.value);
                }
            }),
        },
        urbanizacion: {
            nombre: 'Urbanización',
            importe: importeUrbanizacion,
            base: computed(() => superficies.suelo.value),
            ratio: computed(() => {
                if (importeUrbanizacion.value && superficies.suelo.value && !superficies.suelo.value.isZero()) {
                    return importeUrbanizacion.value.div(superficies.suelo.value);
                }
            }),
        },
        edificacion,
        mobiliarioEquipamiento,
        fitOuts,
        legales: {
            nombre: 'Legales',
            importe: importeLegales,
            tasasCIO: shallowReactive({
                nombre: 'Tasas + ICIO',
                base: importeEdificacion,
                ratio: ratioTasasCIO,
                importe: importeTasasCIO,
            }),
            obraNueva: shallowReactive({
                nombre: 'AJD Obra nueva',
                base: importeEdificacion,
                ratio: ratioObraNueva,
                importe: importeObraNueva,
            }),
            divisionHorizontal: shallowReactive({
                nombre: 'AJD Divisón horizontal',
                base: baseDivisionHorizontal,
                ratio: ratioDivisionHorizontal,
                importe: importeDivisionHorizontal,
            }),
            otrosGastos: shallowReactive({
                nombre: 'Otros gastos legales',
                base: importeEdificacion,
                ratio: ratioOtrosGastos,
                importe: importeOtrosGastos,
            }),
        },
        proyectoSSTT: {
            nombre: 'Proy. y SSTT',
            base: importeEdificacion,
            ratio: ratioProyectoSSTT,
            importe: importeProyectoSSTT,
        },
        total: {
            nombre: 'TOTAL COSTES EJECUCIÓN',
            base: superficies.superficieConstruida,
            importe: importeTotal,
            ratio: computed(() => superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero() ? importeTotal.value.div(superficies.superficieConstruida.value) : undefined),
        }
    };
}
function createIngresos(superficies, costes, val) {
    let totalValorVenta = computed(() => {
        let valores = ventasList.value.map(venta => venta.valorVenta.value);
        valores.push(edit === null || edit === void 0 ? void 0 : edit.valorVenta.value);
        return sumAll(valores);
    });
    let arrendamientosList = shallowRef([]);
    let ventasList = shallowRef(superficies.superficies.slice(1).map(superficie => createVentaStatic(superficie, totalValorVenta, costes, arrendamientosList)));
    let edit = createVentaEditable(superficies.superficies[0], totalValorVenta, costes, val);
    let margenBrutoExplotacion = computed(() => {
        let margen = ventasList.value.map(venta => venta.margenBrutoExplotacion.value);
        margen.push(edit === null || edit === void 0 ? void 0 : edit.margenBrutoExplotacion.value);
        return sumAll(margen);
    });
    let costeEjecucion = computed(() => {
        let costes = ventasList.value.map(venta => venta.costeEjecucion.value);
        costes.push(edit === null || edit === void 0 ? void 0 : edit.costeEjecucion.value);
        return sumAll(costes);
    });
    let ventas = {
        items: ventasList,
        edit,
        length: computed(() => superficies.superficies.length),
        totalValorVenta,
        nombre: 'TOTAL INGRESOS',
        ventasPercentage: new Decimal(1),
        ventasUnidades: computed(() => {
            if (totalValorVenta.value && superficies.totalUnidades.value && !superficies.totalUnidades.value.isZero()) {
                return totalValorVenta.value.div(superficies.totalUnidades.value.toInteger());
            }
        }),
        ventasMetros: computed(() => {
            if (margenBrutoExplotacion.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
                return margenBrutoExplotacion.value.div(superficies.superficieConstruida.value.toInteger());
            }
        }),
        margenBrutoExplotacion,
        costeEjecucion,
        mbce: computed(() => {
            if (margenBrutoExplotacion.value && costeEjecucion.value && !costeEjecucion.value.isZero()) {
                return margenBrutoExplotacion.value.div(costeEjecucion.value.negated());
            }
        }),
    };
    arrendamientosList.value = superficies.superficies.slice(1).map((superficie, row) => createArrendamiento(superficie, ventasList.value, row, val));
    let arrStatic = ref({ superficie: '', nombre: '' });
    if (superficies.superficies.length) {
        arrStatic.value = {
            superficie: superficies.superficies[0].name,
            nombre: superficies.superficies[0].nombre.value,
        };
    }
    let renta = computed(() => {
        return sumAll(arrendamientosList.value.map(arrendamiento => arrendamiento.renta.value));
    });
    let desocImporte = computed(() => {
        return sumAll(arrendamientosList.value.map(arrendamiento => arrendamiento.desocImporte.value));
    });
    let noi = computed(() => {
        return sumAll(arrendamientosList.value.map(arrendamiento => arrendamiento.noi.value));
    });
    let arrendamientos = {
        items: arrendamientosList,
        static: arrStatic,
        length: computed(() => superficies.superficies.length),
        nombre: 'TOTAL INGRESOS',
        renta,
        desocImporte,
        desoc: computed(() => {
            if (renta.value && !renta.value.isZero()) {
                return desocImporte.value.div(renta.value.negated());
            }
        }),
        gExpl: computed(() => {
            return sumAll(arrendamientosList.value.map(arrendamiento => arrendamiento.gExpl.value));
        }),
        noi,
        ree: computed(() => {
            let margen = sumAll(ventasList.value.map(venta => venta.costeEjecucion.value));
            if (!margen.isZero()) {
                return noi.value.div(margen.negated());
            }
        }),
        yield: computed(() => {
            let valorVenta = sumAll(ventasList.value.map(venta => venta.valorVenta.value));
            if (!valorVenta.isZero()) {
                return noi.value.div(valorVenta);
            }
        }),
    };
    watch(() => superficies.superficies.length, (length) => {
        var _a;
        if (!length) {
            ventas.items.value = [];
            ventas.edit = undefined;
            arrendamientos.items.value = [];
            arrendamientos.static.value = { superficie: '', nombre: '' };
            return;
        }
        ventas.items.value = superficies.superficies.slice(1).map(superficie => {
            let venta = ventas.items.value.find(v => v.superficie === superficie.name);
            return venta || createVentaStatic(superficie, totalValorVenta, costes, arrendamientos.items);
        });
        if (((_a = ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) !== superficies.superficies[0].name) {
            ventas.edit = createVentaEditable(superficies.superficies[0], totalValorVenta, costes, val);
        }
        arrendamientos.items.value = superficies.superficies.slice(1).map((superficie, row) => {
            let arrendamiento = arrendamientos.items.value.find(a => a.superficie === superficie.name);
            return arrendamiento || createArrendamiento(superficie, ventas.items.value, row, val);
        });
        if (arrendamientos.static.value.superficie !== superficies.superficies[0].name) {
            arrendamientos.static.value = {
                superficie: superficies.superficies[0].name,
                nombre: superficies.superficies[0].nombre.value,
            };
        }
    }, { immediate: true, flush: 'sync' });
    return {
        ventas,
        arrendamientos,
    };
}
function createVentaStatic(superficie, totalValorVenta, costes, arrendamientos) {
    let valorVenta = computed(() => {
        let arrendamiento = arrendamientos.value.find(a => a.superficie === superficie.name);
        if (arrendamiento && arrendamiento.noi.value && arrendamiento.yield.value && !arrendamiento.yield.value.isZero()) {
            return arrendamiento.noi.value.div(arrendamiento.yield.value);
        }
    });
    let ventasPercentage = computed(() => {
        if (valorVenta.value && totalValorVenta.value && !totalValorVenta.value.isZero()) {
            return valorVenta.value.div(totalValorVenta.value);
        }
    });
    let costeEjecucion = computed(() => {
        var _a, _b, _c, _d;
        let costeEdificacion = costes.edificacion.costes.value.find(c => c.superficie === superficie.name);
        let costeMobiliario = costes.mobiliarioEquipamiento.costes.value.find(c => c.superficie === superficie.name);
        let costeFitOuts = costes.fitOuts.costes.value.find(c => c.superficie === superficie.name);
        return sumAll([
            (_a = costeEdificacion === null || costeEdificacion === void 0 ? void 0 : costeEdificacion.importe.value) === null || _a === void 0 ? void 0 : _a.negated(),
            (_b = costeMobiliario === null || costeMobiliario === void 0 ? void 0 : costeMobiliario.importe.value) === null || _b === void 0 ? void 0 : _b.negated(),
            (_c = costeFitOuts === null || costeFitOuts === void 0 ? void 0 : costeFitOuts.importe.value) === null || _c === void 0 ? void 0 : _c.negated(),
            (_d = ventasPercentage.value) === null || _d === void 0 ? void 0 : _d.negated().times(sumAll([
                costes.suelo.importe.value,
                costes.urbanizacion.importe.value,
                costes.legales.importe.value,
                costes.proyectoSSTT.importe.value,
            ])),
        ]);
    });
    let margenBrutoExplotacion = computed(() => {
        if (valorVenta.value && costeEjecucion.value) {
            return valorVenta.value.add(costeEjecucion.value);
        }
    });
    return {
        superficie: superficie.name,
        valorVenta,
        nombre: superficie.nombre.value,
        ventasPercentage,
        ventasUnidades: computed(() => {
            if (valorVenta.value && superficie.unidades.value && !superficie.unidades.value.isZero()) {
                return valorVenta.value.div(superficie.unidades.value);
            }
        }),
        ventasMetros: computed(() => {
            if (valorVenta.value && superficie.superficieConstruida.value && !superficie.superficieConstruida.value.isZero()) {
                return valorVenta.value.div(superficie.superficieConstruida.value);
            }
        }),
        costeEjecucion,
        margenBrutoExplotacion,
        mbece: computed(() => {
            if (margenBrutoExplotacion.value && costeEjecucion.value && !costeEjecucion.value.isZero()) {
                return margenBrutoExplotacion.value.div(costeEjecucion.value.negated());
            }
        }),
    };
}
function createVentaEditable(superficie, totalValorVenta, costes, val) {
    var _a;
    if (!superficie) {
        return undefined;
    }
    let valorVenta = ref(parseInteger((_a = val === null || val === void 0 ? void 0 : val.ingresos) === null || _a === void 0 ? void 0 : _a.venta));
    let ventasPercentage = computed(() => {
        if (valorVenta.value && totalValorVenta.value && !totalValorVenta.value.isZero()) {
            return valorVenta.value.div(totalValorVenta.value);
        }
    });
    let costeEjecucion = computed(() => {
        var _a, _b, _c, _d;
        let costeEdificacion = costes.edificacion.costes.value.find(c => c.superficie === superficie.name);
        let costeMobiliario = costes.mobiliarioEquipamiento.costes.value.find(c => c.superficie === superficie.name);
        let costeFitOuts = costes.fitOuts.costes.value.find(c => c.superficie === superficie.name);
        return sumAll([
            (_a = costeEdificacion === null || costeEdificacion === void 0 ? void 0 : costeEdificacion.importe.value) === null || _a === void 0 ? void 0 : _a.negated(),
            (_b = costeMobiliario === null || costeMobiliario === void 0 ? void 0 : costeMobiliario.importe.value) === null || _b === void 0 ? void 0 : _b.negated(),
            (_c = costeFitOuts === null || costeFitOuts === void 0 ? void 0 : costeFitOuts.importe.value) === null || _c === void 0 ? void 0 : _c.negated(),
            (_d = ventasPercentage.value) === null || _d === void 0 ? void 0 : _d.negated().times(sumAll([
                costes.suelo.importe.value,
                costes.urbanizacion.importe.value,
                costes.legales.importe.value,
                costes.proyectoSSTT.importe.value,
            ])),
        ]);
    });
    let margenBrutoExplotacion = computed(() => {
        if (valorVenta.value && costeEjecucion.value) {
            return valorVenta.value.add(costeEjecucion.value);
        }
    });
    return {
        superficie: superficie.name,
        valorVenta,
        nombre: superficie.nombre.value,
        ventasPercentage,
        ventasUnidades: computed(() => {
            if (valorVenta.value && superficie.unidades.value && !superficie.unidades.value.isZero()) {
                return valorVenta.value.div(superficie.unidades.value);
            }
        }),
        ventasMetros: computed(() => {
            if (valorVenta.value && superficie.superficieConstruida.value && !superficie.superficieConstruida.value.isZero()) {
                return valorVenta.value.div(superficie.superficieConstruida.value);
            }
        }),
        costeEjecucion,
        margenBrutoExplotacion,
        mbece: computed(() => {
            if (margenBrutoExplotacion.value && costeEjecucion.value && !costeEjecucion.value.isZero()) {
                return margenBrutoExplotacion.value.div(costeEjecucion.value.negated());
            }
        }),
    };
}
function createArrendamiento(superficie, ventas, row, val) {
    var _a, _b, _c, _d, _e;
    let renta = ref();
    let desoc = ref();
    let gExpl = ref();
    let yieldVal = ref();
    if ((_a = val === null || val === void 0 ? void 0 : val.ingresos) === null || _a === void 0 ? void 0 : _a.arrendamientos) {
        renta = ref(parseInteger((_b = val === null || val === void 0 ? void 0 : val.ingresos) === null || _b === void 0 ? void 0 : _b.arrendamientos[row + 1].renta));
        desoc = ref(parsePercentage((_c = val === null || val === void 0 ? void 0 : val.ingresos) === null || _c === void 0 ? void 0 : _c.arrendamientos[row + 1].desoc));
        gExpl = ref(parseInteger((_d = val === null || val === void 0 ? void 0 : val.ingresos) === null || _d === void 0 ? void 0 : _d.arrendamientos[row + 1].gExpl));
        yieldVal = ref(parsePercentage((_e = val === null || val === void 0 ? void 0 : val.ingresos) === null || _e === void 0 ? void 0 : _e.arrendamientos[row + 1].yield));
    }
    let desocImporte = computed(() => {
        if (renta.value && desoc.value) {
            return renta.value.times(-desoc.value);
        }
    });
    let noi = computed(() => {
        if (renta.value || desocImporte.value || gExpl.value) {
            return sumAll([renta.value, desocImporte.value, gExpl.value]);
        }
    });
    return {
        superficie: superficie.name,
        nombre: superficie.nombre.value,
        renta,
        desoc,
        gExpl,
        yield: yieldVal,
        desocImporte,
        noi,
        ree: computed(() => {
            var _a;
            let costeEjecucion = (_a = ventas.find(v => v.superficie === superficie.name)) === null || _a === void 0 ? void 0 : _a.costeEjecucion.value;
            if (noi.value && costeEjecucion && !costeEjecucion.isZero()) {
                return noi.value.div(costeEjecucion.negated());
            }
        }),
    };
}
function createOtrosGastos(superficies, costes, ingresos, val) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    let ratioDireccion = ref(parsePercentage((_a = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _a === void 0 ? void 0 : _a.ratioDireccion));
    let baseDireccion = costes.edificacion.importe;
    let ratioActivacion = ref(parsePercentage((_b = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _b === void 0 ? void 0 : _b.ratioActivacion));
    let baseActivacion = computed(() => costes.suelo.importe.value);
    let ratioComercializacion = ref(parsePercentage((_c = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _c === void 0 ? void 0 : _c.ratioComercializacion));
    let baseComercializacion = costes.edificacion.importe;
    let ratioGestionIntegral = ref(parsePercentage((_d = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _d === void 0 ? void 0 : _d.ratioGestionIntegral));
    let baseGestionIntegral = costes.edificacion.importe;
    let ratioVentaViviendas = ref(parsePercentage((_e = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _e === void 0 ? void 0 : _e.ratioVentaViviendas));
    let baseVentaViviendas = computed(() => {
        var _a, _b;
        let ventas = ingresos.ventas.items.value
            .filter(venta => {
            var _a;
            let sup = superficies.superficies.find(s => s.name === venta.superficie);
            return (_a = getUso((sup === null || sup === void 0 ? void 0 : sup.uso) || '')) === null || _a === void 0 ? void 0 : _a.vivienda;
        })
            .map(venta => venta.valorVenta.value);
        if ((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) {
            let sup = superficies.superficies.find(s => { var _a; return s.name === ((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie); });
            if ((_b = getUso((sup === null || sup === void 0 ? void 0 : sup.uso) || '')) === null || _b === void 0 ? void 0 : _b.vivienda) {
                ventas.push(ingresos.ventas.edit.valorVenta.value);
            }
        }
        return sumAll(ventas);
    });
    let ratioVentaOtros = ref(parsePercentage((_f = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _f === void 0 ? void 0 : _f.ratioVentaOtros));
    let baseVentaOtros = computed(() => {
        var _a, _b;
        let ventas = ingresos.ventas.items.value
            .filter(venta => {
            var _a;
            let sup = superficies.superficies.find(s => s.name === venta.superficie);
            return !((_a = getUso((sup === null || sup === void 0 ? void 0 : sup.uso) || '')) === null || _a === void 0 ? void 0 : _a.vivienda);
        })
            .map(venta => venta.valorVenta.value);
        if ((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) {
            let sup = superficies.superficies.find(s => { var _a; return s.name === ((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie); });
            if (!((_b = getUso((sup === null || sup === void 0 ? void 0 : sup.uso) || '')) === null || _b === void 0 ? void 0 : _b.vivienda)) {
                ventas.push(ingresos.ventas.edit.valorVenta.value);
            }
        }
        return sumAll(ventas);
    });
    let ratioGestionInmuebles = ref(parsePercentage((_g = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _g === void 0 ? void 0 : _g.ratioGestionInmuebles));
    let baseGestionInmuebles = costes.edificacion.importe;
    let ratioMarketing = ref(parsePercentage((_h = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _h === void 0 ? void 0 : _h.ratioMarketing));
    let baseMarketing = ingresos.ventas.totalValorVenta;
    let ratioContabilidad = ref(parsePercentage((_j = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _j === void 0 ? void 0 : _j.ratioContabilidad));
    let baseContabilidad = costes.edificacion.importe;
    let ratioOtros = ref(parsePercentage((_k = val === null || val === void 0 ? void 0 : val.otrosGastos) === null || _k === void 0 ? void 0 : _k.ratioOtros));
    let baseOtros = costes.edificacion.importe;
    let baseTotal = superficies.superficieConstruida;
    let importeDireccion = computed(() => baseDireccion.value && ratioDireccion.value ? baseDireccion.value.times(ratioDireccion.value) : undefined);
    let importeActivacion = computed(() => baseActivacion.value && ratioActivacion.value ? baseActivacion.value.times(ratioActivacion.value) : undefined);
    let importeComercializacion = computed(() => baseComercializacion.value && ratioComercializacion.value ? baseComercializacion.value.times(ratioComercializacion.value) : undefined);
    let importeGestionIntegral = computed(() => baseGestionIntegral.value && ratioGestionIntegral.value ? baseGestionIntegral.value.times(ratioGestionIntegral.value) : undefined);
    let importeVentaViviendas = computed(() => baseVentaViviendas.value && ratioVentaViviendas.value ? baseVentaViviendas.value.times(ratioVentaViviendas.value) : undefined);
    let importeVentaOtros = computed(() => baseVentaOtros.value && ratioVentaOtros.value ? baseVentaOtros.value.times(ratioVentaOtros.value) : undefined);
    let importeGestionInmuebles = computed(() => baseGestionInmuebles.value && ratioGestionInmuebles.value ? baseGestionInmuebles.value.times(ratioGestionInmuebles.value) : undefined);
    let importeMarketing = computed(() => baseMarketing.value && ratioMarketing.value ? baseMarketing.value.times(ratioMarketing.value) : undefined);
    let importeContabilidad = computed(() => baseContabilidad.value && ratioContabilidad.value ? baseContabilidad.value.times(ratioContabilidad.value) : undefined);
    let importeOtros = computed(() => baseOtros.value && ratioOtros.value ? baseOtros.value.times(ratioOtros.value) : undefined);
    let importeTotal = computed(() => {
        return sumAll([
            importeDireccion.value,
            importeActivacion.value,
            importeComercializacion.value,
            importeGestionIntegral.value,
            importeVentaViviendas.value,
            importeVentaOtros.value,
            importeGestionInmuebles.value,
            importeMarketing.value,
            importeContabilidad.value,
            importeOtros.value,
        ]);
    });
    return {
        direccion: {
            nombre: 'D.Estr.y Adm.Legal',
            ratio: ratioDireccion,
            base: baseDireccion,
            importe: importeDireccion,
        },
        activacion: {
            nombre: 'Activación',
            ratio: ratioActivacion,
            base: baseActivacion,
            importe: importeActivacion,
        },
        comercializacion: {
            nombre: 'Comercialización B2B',
            ratio: ratioComercializacion,
            base: baseComercializacion,
            importe: importeComercializacion,
        },
        gestionIntegral: {
            nombre: 'Gestión integral de proyectos',
            ratio: ratioGestionIntegral,
            base: baseGestionIntegral,
            importe: importeGestionIntegral,
        },
        ventaViviendas: {
            nombre: 'Venta viviendas y anejos',
            ratio: ratioVentaViviendas,
            base: baseVentaViviendas,
            importe: importeVentaViviendas,
        },
        ventaOtros: {
            nombre: 'Venta otros usos',
            ratio: ratioVentaOtros,
            base: baseVentaOtros,
            importe: importeVentaOtros,
        },
        gestionInmuebles: {
            nombre: 'Gestión de inmuebles',
            ratio: ratioGestionInmuebles,
            base: baseGestionInmuebles,
            importe: importeGestionInmuebles,
        },
        marketing: {
            nombre: 'Marketing, IT y SSCC',
            ratio: ratioMarketing,
            base: baseMarketing,
            importe: importeMarketing,
        },
        contabilidad: {
            nombre: 'Contabilidad y Administración',
            ratio: ratioContabilidad,
            base: baseContabilidad,
            importe: importeContabilidad,
        },
        otros: {
            nombre: 'Otros gastos (EXTERNOS)',
            ratio: ratioOtros,
            base: baseOtros,
            importe: importeOtros,
        },
        total: {
            nombre: 'TOTAL COSTES ESTRUCTURA Y OTROS GASTOS',
            base: baseTotal,
            importe: importeTotal,
            ratio: computed(() => {
                return importeTotal.value && baseTotal.value && !baseTotal.value.isZero() ? importeTotal.value.div(baseTotal.value) : undefined;
            })
        },
    };
}
function createResumen(superficies, costes, ingresos, otrosGastos, val) {
    var _a, _b, _c;
    let ratioLtvBancario = ref(parsePercentage((_a = val === null || val === void 0 ? void 0 : val.resumen) === null || _a === void 0 ? void 0 : _a.ltvBancario));
    let ratioLtcBancario = ref(parsePercentage((_b = val === null || val === void 0 ? void 0 : val.resumen) === null || _b === void 0 ? void 0 : _b.ltcBancario));
    let costesFinanciacion = ref(parsePercentage((_c = val === null || val === void 0 ? void 0 : val.resumen) === null || _c === void 0 ? void 0 : _c.costesFinanciacion));
    let financiacionObjetivo = computed(() => {
        if (ratioLtvBancario.value) {
            if (ingresos.ventas.totalValorVenta.value) {
                return ingresos.ventas.totalValorVenta.value.times(ratioLtvBancario.value);
            }
        }
        if (ratioLtcBancario.value) {
            costes.total.importe.value;
            if (costes.total.importe.value) {
                return ratioLtcBancario.value.times(costes.total.importe.value);
            }
        }
    });
    let ltvBancario = computed(() => {
        if (ratioLtvBancario.value) {
            return ratioLtvBancario.value;
        }
        if (financiacionObjetivo.value && ingresos.ventas.totalValorVenta.value && !ingresos.ventas.totalValorVenta.value.isZero()) {
            return financiacionObjetivo.value.div(ingresos.ventas.totalValorVenta.value);
        }
    });
    let ltcBancario = computed(() => {
        if (ratioLtcBancario.value) {
            return ratioLtcBancario.value;
        }
        if (financiacionObjetivo.value && costes.total.importe.value && !costes.total.importe.value.isZero()) {
            return financiacionObjetivo.value.div(costes.total.importe.value);
        }
    });
    let totalIngresosTotales = computed(() => ingresos.ventas.totalValorVenta.value);
    let viviendaLibreIngresosTotales = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
        if (superficie) {
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                return ingresos.ventas.edit.ventasMetros.value;
            }
            return (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasMetros.value;
        }
    });
    let vppIngresosTotales = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
        if (superficie) {
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                return ingresos.ventas.edit.ventasMetros.value;
            }
            return (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasMetros.value;
        }
    });
    let totalCostesTotales = computed(() => { var _a; return (_a = costes.total.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalMetrosCostesTotales = computed(() => { var _a; return (_a = costes.total.ratio.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let viviendaLibreCostesTotales = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
        if (superficie) {
            let metros = superficie.superficieConstruida.value;
            let costeEjecucion;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                costeEjecucion = ingresos.ventas.edit.costeEjecucion.value;
            }
            else {
                costeEjecucion = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.costeEjecucion.value;
            }
            if (metros && costeEjecucion && !metros.isZero()) {
                return costeEjecucion.div(metros);
            }
        }
    });
    let vppCostesTotales = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
        if (superficie) {
            let metros = superficie.superficieConstruida.value;
            let margenBrutoExplotacion;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                margenBrutoExplotacion = ingresos.ventas.edit.margenBrutoExplotacion.value;
            }
            else {
                margenBrutoExplotacion = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.margenBrutoExplotacion.value;
            }
            if (margenBrutoExplotacion && metros && !metros.isZero()) {
                return margenBrutoExplotacion.div(metros).negated();
            }
        }
    });
    let totalSuelo = computed(() => { var _a; return (_a = costes.suelo.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalUrbanizacion = computed(() => { var _a; return (_a = costes.urbanizacion.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalEdificacion = computed(() => {
        var _a, _b, _c;
        return sumAll([
            (_a = costes.edificacion.importe.value) === null || _a === void 0 ? void 0 : _a.negated(),
            (_b = costes.mobiliarioEquipamiento.importe.value) === null || _b === void 0 ? void 0 : _b.negated(),
            (_c = costes.fitOuts.importe.value) === null || _c === void 0 ? void 0 : _c.negated(),
        ]);
    });
    let totalLegales = computed(() => { var _a; return (_a = costes.legales.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalProyectosSSTT = computed(() => { var _a; return (_a = costes.proyectoSSTT.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalMargenBrutoDesarrollo = computed(() => {
        if (totalIngresosTotales.value && totalCostesTotales.value) {
            return totalIngresosTotales.value.add(totalCostesTotales.value);
        }
    });
    let viviendaLibreMargenBrutoDesarrollo = computed(() => {
        if (viviendaLibreIngresosTotales.value && viviendaLibreCostesTotales.value) {
            return viviendaLibreIngresosTotales.value.add(viviendaLibreCostesTotales.value);
        }
    });
    let totalMetrosMargenBrutoDesarrollo = computed(() => {
        if (totalMargenBrutoDesarrollo.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
            return totalMargenBrutoDesarrollo.value.div(superficies.superficieConstruida.value.toInteger());
        }
    });
    let vppMargenBrutoDesarrollo = computed(() => {
        if (vppIngresosTotales.value && vppCostesTotales.value) {
            return vppIngresosTotales.value.add(vppCostesTotales.value);
        }
    });
    let totalOtrosGastosOverview = computed(() => { var _a; return (_a = otrosGastos.total.importe.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let totalMetrosOtrosGastosOverview = computed(() => { var _a; return (_a = otrosGastos.total.ratio.value) === null || _a === void 0 ? void 0 : _a.negated(); });
    let viviendaLibreOtrosGastosOverview = computed(() => {
        var _a, _b, _c;
        let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
        if (superficie && totalOtrosGastosOverview.value) {
            let superficieConstruida = superficie.superficieConstruida.value;
            let ventasPercentage;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
            }
            else {
                ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
            }
            if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                return (_c = totalOtrosGastosOverview.value) === null || _c === void 0 ? void 0 : _c.times(ventasPercentage).div(superficieConstruida);
            }
        }
    });
    let vppOtrosGastosOverview = computed(() => {
        var _a, _b, _c;
        let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
        if (superficie && totalOtrosGastosOverview.value) {
            let superficieConstruida = superficie.superficieConstruida.value;
            let ventasPercentage;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
            }
            else {
                ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
            }
            if (superficieConstruida && ventasPercentage && !superficieConstruida.isZero()) {
                return (_c = totalOtrosGastosOverview.value) === null || _c === void 0 ? void 0 : _c.times(ventasPercentage).div(superficieConstruida);
            }
        }
    });
    let totalCostesFinanciacionOverview = computed(() => {
        if (costesFinanciacion.value && financiacionObjetivo.value) {
            return costesFinanciacion.value.negated().times(financiacionObjetivo.value);
        }
    });
    let totalMetrosCostesFinanciacionOverview = computed(() => {
        if (totalCostesFinanciacionOverview.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
            return totalCostesFinanciacionOverview.value.div(superficies.superficieConstruida.value.toInteger());
        }
    });
    let viviendaLibreCostesFinanciacionOverview = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
        if (superficie && totalCostesFinanciacionOverview.value) {
            let superficieConstruida = superficie.superficieConstruida.value;
            let ventasPercentage;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
            }
            else {
                ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
            }
            if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                return totalCostesFinanciacionOverview.value.times(ventasPercentage).div(superficieConstruida);
            }
        }
    });
    let vppCostesFinanciacionOverview = computed(() => {
        var _a, _b;
        let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
        if (superficie && totalCostesFinanciacionOverview.value) {
            let superficieConstruida = superficie.superficieConstruida.value;
            let ventasPercentage;
            if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
            }
            else {
                ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
            }
            if (superficieConstruida && ventasPercentage && !superficieConstruida.isZero()) {
                return totalCostesFinanciacionOverview.value.times(ventasPercentage).div(superficieConstruida);
            }
        }
    });
    let totalMargenBrutoTotal = computed(() => {
        return sumAll([
            totalMargenBrutoDesarrollo.value,
            totalOtrosGastosOverview.value,
            totalCostesFinanciacionOverview.value,
        ]);
    });
    let totalMetrosMargenBrutoTotal = computed(() => {
        if (totalMargenBrutoTotal.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
            return totalMargenBrutoTotal.value.div(superficies.superficieConstruida.value.toInteger());
        }
    });
    let viviendaLibreMargenBrutoTotal = computed(() => {
        return sumAll([
            viviendaLibreMargenBrutoDesarrollo.value,
            viviendaLibreOtrosGastosOverview.value,
            viviendaLibreCostesFinanciacionOverview.value,
        ]);
    });
    let vppMargenBrutoTotal = computed(() => {
        return sumAll([
            vppMargenBrutoDesarrollo.value,
            vppOtrosGastosOverview.value,
            vppCostesFinanciacionOverview.value,
        ]);
    });
    let totalNoi = computed(() => ingresos.arrendamientos.noi.value);
    let totalRbe = computed(() => ingresos.arrendamientos.ree.value);
    let totalYield = computed(() => ingresos.arrendamientos.yield.value);
    return {
        ratioLtvBancario,
        ratioLtcBancario,
        costesFinanciacion,
        financiacionObjetivo,
        ltvBancario,
        ltcBancario,
        ingresosTotales: {
            nombre: 'Ingresos totales',
            total: totalIngresosTotales,
            totalMetros: computed(() => ingresos.ventas.ventasMetros.value),
            viviendaLibre: viviendaLibreIngresosTotales,
            vpp: vppIngresosTotales,
        },
        costesTotales: {
            nombre: 'Costes totales (Ejecución, Estructura y Otros gastos)',
            total: totalCostesTotales,
            totalMetros: totalMetrosCostesTotales,
            viviendaLibre: viviendaLibreCostesTotales,
            vpp: vppCostesTotales,
        },
        suelo: {
            nombre: 'Suelo',
            subOverview: true,
            total: totalSuelo,
            totalMetros: computed(() => { var _a; return (_a = costes.suelo.ratio.value) === null || _a === void 0 ? void 0 : _a.negated(); }),
            viviendaLibre: computed(() => {
                var _a, _b, _c;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie && totalSuelo.value) {
                    let superficieConstruida = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                        return (_c = totalSuelo.value) === null || _c === void 0 ? void 0 : _c.times(ventasPercentage).div(superficieConstruida);
                    }
                }
            }),
            vpp: computed(() => {
                var _a, _b;
                let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
                if (superficie && totalSuelo.value) {
                    let superficieConstruida = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                        return totalSuelo.value.times(ventasPercentage).div(superficieConstruida);
                    }
                }
            }),
        },
        urbanizacion: {
            nombre: 'Urbanización',
            subOverview: true,
            total: totalUrbanizacion,
            totalMetros: computed(() => { var _a; return (_a = costes.urbanizacion.ratio.value) === null || _a === void 0 ? void 0 : _a.negated(); }),
            viviendaLibre: computed(() => {
                var _a, _b, _c;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie && totalUrbanizacion.value) {
                    let superficieConstruida = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                        return (_c = totalUrbanizacion.value) === null || _c === void 0 ? void 0 : _c.times(ventasPercentage).div(superficieConstruida);
                    }
                }
            }),
            vpp: computed(() => {
                var _a, _b, _c;
                let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
                if (superficie) {
                    let superficieConstruida = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && superficieConstruida && !superficieConstruida.isZero()) {
                        return (_c = totalUrbanizacion.value) === null || _c === void 0 ? void 0 : _c.times(ventasPercentage).div(superficieConstruida);
                    }
                }
            }),
        },
        edificacion: {
            nombre: 'Edificación',
            subOverview: true,
            total: totalEdificacion,
            totalMetros: computed(() => {
                if (totalEdificacion.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
                    return totalEdificacion.value.div(superficies.superficieConstruida.value);
                }
            }),
            viviendaLibre: computed(() => {
                var _a, _b, _c, _d, _e, _f;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                let metros = superficie === null || superficie === void 0 ? void 0 : superficie.superficieConstruida.value;
                if (superficie && metros && !metros.isZero()) {
                    return sumAll([
                        (_b = (_a = costes.edificacion.costes.value.find(c => c.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.importe.value) === null || _b === void 0 ? void 0 : _b.negated(),
                        (_d = (_c = costes.mobiliarioEquipamiento.costes.value.find(c => c.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _c === void 0 ? void 0 : _c.importe.value) === null || _d === void 0 ? void 0 : _d.negated(),
                        (_f = (_e = costes.fitOuts.costes.value.find(c => c.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _e === void 0 ? void 0 : _e.importe.value) === null || _f === void 0 ? void 0 : _f.negated(),
                    ]).div(metros);
                }
            }),
            vpp: computed(() => {
                var _a, _b, _c, _d, _e, _f;
                let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
                let otherSuperficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                let metros = superficie === null || superficie === void 0 ? void 0 : superficie.superficieConstruida.value;
                if (superficie && otherSuperficie && metros && !metros.isZero()) {
                    return sumAll([
                        (_b = (_a = costes.edificacion.costes.value.find(c => c.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.importe.value) === null || _b === void 0 ? void 0 : _b.negated(),
                        (_d = (_c = costes.mobiliarioEquipamiento.costes.value.find(c => c.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _c === void 0 ? void 0 : _c.importe.value) === null || _d === void 0 ? void 0 : _d.negated(),
                        (_f = (_e = 
                        // TODO(ernesto): Es un error este índice discordante?
                        costes.fitOuts.costes.value.find(c => c.superficie === (otherSuperficie === null || otherSuperficie === void 0 ? void 0 : otherSuperficie.name))) === null || _e === void 0 ? void 0 : _e.importe.value) === null || _f === void 0 ? void 0 : _f.negated(),
                    ]).div(metros);
                }
            }),
        },
        legales: {
            nombre: 'Legales',
            subOverview: true,
            total: totalLegales,
            totalMetros: computed(() => {
                if (totalLegales.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
                    return totalLegales.value.div(superficies.superficieConstruida.value.toInteger());
                }
            }),
            viviendaLibre: computed(() => {
                var _a, _b;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie && totalLegales.value) {
                    let metros = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && metros && !metros.isZero()) {
                        return totalLegales.value.times(ventasPercentage).div(metros);
                    }
                }
            }),
            vpp: computed(() => {
                var _a, _b;
                let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
                if (superficie && totalLegales.value) {
                    let metros = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && metros && !metros.isZero()) {
                        return totalLegales.value.times(ventasPercentage).div(metros);
                    }
                }
            }),
        },
        proyectosSSTT: {
            nombre: 'Proyectos y SS.TT.',
            subOverview: true,
            total: totalProyectosSSTT,
            totalMetros: computed(() => {
                if (totalProyectosSSTT.value && superficies.superficieConstruida.value && !superficies.superficieConstruida.value.isZero()) {
                    return totalProyectosSSTT.value.div(superficies.superficieConstruida.value.toInteger());
                }
            }),
            viviendaLibre: computed(() => {
                var _a, _b;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie && totalProyectosSSTT.value) {
                    let metros = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && metros && !metros.isZero()) {
                        return totalProyectosSSTT.value.times(ventasPercentage).div(metros);
                    }
                }
            }),
            vpp: computed(() => {
                var _a, _b;
                let superficie = findSuperficieByUso(superficies.superficies, 'VIVIENDA_VENTA');
                if (superficie && totalProyectosSSTT.value) {
                    let metros = superficie.superficieConstruida.value;
                    let ventasPercentage;
                    if (((_a = ingresos.ventas.edit) === null || _a === void 0 ? void 0 : _a.superficie) === superficie.name) {
                        ventasPercentage = ingresos.ventas.edit.ventasPercentage.value;
                    }
                    else {
                        ventasPercentage = (_b = ingresos.ventas.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _b === void 0 ? void 0 : _b.ventasPercentage.value;
                    }
                    if (ventasPercentage && metros && !metros.isZero()) {
                        return totalProyectosSSTT.value.times(ventasPercentage).div(metros);
                    }
                }
            }),
        },
        margenBrutoDesarrollo: {
            nombre: 'Margen Bruto desarrollo',
            total: totalMargenBrutoDesarrollo,
            totalMetros: totalMetrosMargenBrutoDesarrollo,
            viviendaLibre: viviendaLibreMargenBrutoDesarrollo,
            vpp: vppMargenBrutoDesarrollo,
        },
        margenBrutoDesarrolloPercentage: {
            nombre: 'Margen Bruto % s/ C. Desarrollo',
            total: computed(() => {
                if (totalMargenBrutoDesarrollo.value && totalCostesTotales.value && !totalCostesTotales.value.isZero()) {
                    return totalMargenBrutoDesarrollo.value.div(totalCostesTotales.value.negated());
                }
            }),
            totalMetros: computed(() => {
                if (totalMetrosMargenBrutoDesarrollo.value && totalMetrosCostesTotales.value && !totalMetrosCostesTotales.value.isZero()) {
                    return totalMetrosMargenBrutoDesarrollo.value.div(totalMetrosCostesTotales.value.negated());
                }
            }),
            viviendaLibre: computed(() => {
                if (viviendaLibreMargenBrutoDesarrollo.value && viviendaLibreCostesTotales.value && !viviendaLibreCostesTotales.value.isZero()) {
                    return viviendaLibreMargenBrutoDesarrollo.value.div(viviendaLibreCostesTotales.value.negated());
                }
            }),
            vpp: computed(() => {
                if (vppMargenBrutoDesarrollo.value && vppCostesTotales.value && !vppCostesTotales.value.isZero()) {
                    return vppMargenBrutoDesarrollo.value.div(vppCostesTotales.value.negated());
                }
            }),
        },
        otrosGastosOverview: {
            nombre: 'Estructura y otros gastos',
            total: totalOtrosGastosOverview,
            totalMetros: totalMetrosOtrosGastosOverview,
            viviendaLibre: viviendaLibreOtrosGastosOverview,
            vpp: vppOtrosGastosOverview,
        },
        costesFinanciacionPercentage: {
            nombre: '% Costes Financiación',
            total: computed(() => costesFinanciacion.value),
            totalMetros: computed(() => undefined),
            viviendaLibre: computed(() => undefined),
            vpp: computed(() => undefined),
        },
        costesFinanciacionOverview: {
            nombre: 'Costes Financiación',
            total: totalCostesFinanciacionOverview,
            totalMetros: totalMetrosCostesFinanciacionOverview,
            viviendaLibre: viviendaLibreCostesFinanciacionOverview,
            vpp: vppCostesFinanciacionOverview,
        },
        margenBrutoTotal: {
            nombre: 'Margen Bruto Total',
            total: totalMargenBrutoTotal,
            totalMetros: totalMetrosMargenBrutoTotal,
            viviendaLibre: viviendaLibreMargenBrutoTotal,
            vpp: vppMargenBrutoTotal,
        },
        margenBrutoPercentage: {
            nombre: 'Margen Bruto s/ C. Totales',
            total: computed(() => {
                let total = sumAll([
                    totalCostesTotales.value,
                    totalOtrosGastosOverview.value,
                    totalCostesFinanciacionOverview.value,
                ]);
                if (totalMargenBrutoTotal.value && !total.isZero()) {
                    return totalMargenBrutoTotal.value.negated().div(total);
                }
            }),
            totalMetros: computed(() => {
                let total = sumAll([
                    totalMetrosCostesTotales.value,
                    totalMetrosOtrosGastosOverview.value,
                    totalMetrosCostesFinanciacionOverview.value,
                ]);
                if (totalMetrosMargenBrutoTotal.value && !total.isZero()) {
                    return totalMetrosMargenBrutoTotal.value.negated().div(total);
                }
            }),
            viviendaLibre: computed(() => {
                let total = sumAll([
                    viviendaLibreCostesTotales.value,
                    viviendaLibreOtrosGastosOverview.value,
                    viviendaLibreCostesFinanciacionOverview.value,
                ]);
                if (viviendaLibreMargenBrutoTotal.value && !total.isZero()) {
                    return viviendaLibreMargenBrutoTotal.value.negated().div(total);
                }
            }),
            vpp: computed(() => {
                let total = sumAll([
                    vppCostesTotales.value,
                    vppOtrosGastosOverview.value,
                    vppCostesFinanciacionOverview.value,
                ]);
                if (vppMargenBrutoTotal.value && !total.isZero()) {
                    return vppMargenBrutoTotal.value.negated().div(total);
                }
            }),
        },
        noi: {
            nombre: 'NOI total',
            total: totalNoi,
            totalMetros: computed(() => {
                let sup = sumAll(superficies.superficies.map((superficie, row) => row ? superficie.superficieConstruida.value : undefined));
                if (totalNoi.value && !sup.isZero()) {
                    return totalNoi.value.div(sup);
                }
            }),
            viviendaLibre: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie) {
                    let metros = superficie.superficieConstruida.value;
                    let noi = (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.noi.value;
                    if (noi && metros && !metros.isZero()) {
                        return noi.div(metros);
                    }
                }
            }),
            comercial: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_ARRENDAMIENTO');
                if (superficie) {
                    let metros = superficie.superficieConstruida.value;
                    let noi = (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.noi.value;
                    if (noi && metros && !metros.isZero()) {
                        return noi.div(metros);
                    }
                }
            }),
        },
        rbe: {
            nombre: 'R.B.E. % global',
            total: totalRbe,
            totalMetros: computed(() => {
                let sup = sumAll(superficies.superficies.map((superficie, row) => row ? superficie.superficieConstruida.value : undefined));
                if (totalRbe.value && !sup.isZero()) {
                    return totalRbe.value.div(sup);
                }
            }),
            viviendaLibre: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie) {
                    return (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.ree.value;
                }
            }),
            comercial: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_ARRENDAMIENTO');
                if (superficie) {
                    return (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.ree.value;
                }
            }),
        },
        yield: {
            nombre: 'Yield global',
            total: totalYield,
            totalMetros: computed(() => {
                let sup = sumAll(superficies.superficies.map((superficie, row) => row ? superficie.superficieConstruida.value : undefined));
                if (!sup.isZero() && totalYield.value) {
                    return totalYield.value.div(sup);
                }
            }),
            viviendaLibre: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_VENTA');
                if (superficie) {
                    return (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.yield.value;
                }
            }),
            comercial: computed(() => {
                var _a;
                let superficie = findSuperficieByUso(superficies.superficies, 'GARAJES_ARRENDAMIENTO');
                if (superficie) {
                    return (_a = ingresos.arrendamientos.items.value.find(v => v.superficie === (superficie === null || superficie === void 0 ? void 0 : superficie.name))) === null || _a === void 0 ? void 0 : _a.yield.value;
                }
            }),
        },
    };
}
function findSuperficieByUso(superficies, uso) {
    return superficies.find(s => s.uso === uso);
}
