
import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { useHotkeys } from 'react-hotkeys-hook'
import { Transition } from '@headlessui/react'

import { useScrollPosition } from 'platform/dom'
import Icon from 'components/Icon'


export default function SideSheet({ children, open, onClose, header, wide, footer }) {
  let body = useRef(null)
  let [styles, setStyles] = useState('')

  useHotkeys('esc', () => {
    if (open) {
      onClose()
    }
  }, {}, [open, onClose])

  useScrollPosition(({ position }) => {
    if (position.top) {
      if (!styles) {
        setStyles('shadow')
      }
    } else {
      if (styles) {
        setStyles('')
      }
    }
  }, body)

  return (
    <>
      <Transition
        show={open}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 overflow-hidden z-20"
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => onClose()}></div>

          <section
            className={cx(
              'absolute inset-y-0 right-0 pl-10 max-w-full flex',
              styles,
            )}
          >
            <Transition
              show={open}
              enter="delay-500 transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              className={cx(
                'w-screen',
                {
                  'max-w-md': !wide,
                  'max-w-2xl': wide,
                },
              )}
            >
              <div className="h-full flex flex-col pt-6 bg-white shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                      {header}
                    </h2>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-xl"
                        onClick={() => onClose()}
                      >
                        <Icon light name="times" />
                      </button>
                    </div>
                  </div>
                </div>
                {children}
                {footer &&
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end border-t-3 border-gray-200">
                    {footer}
                  </div>
                }
              </div>
            </Transition>
          </section>
        </div>
      </Transition>
    </>
  )
}


SideSheet.Content = function({ children, fit }) {
  return (<div className={cx(
    'mt-5 relative flex-1 overflow-y-scroll',
    {
      'px-4 sm:px-6': !fit,
    },
  )}>
    {children}
  </div>)
}
