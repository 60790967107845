
import React from 'react'
import DatepickerInternal from 'components/Datepicker'


export function Datepicker({ label, name, help, value, onChange, className, disabled, min }) {
  return (
    <>
      <div className={className}>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <DatepickerInternal
          className="mt-1 w-56"
          value={value}
          name={name}
          onChange={values => {
            if (onChange) {
              onChange(values[0])
            }
          }}
          disabled={disabled}
          min={min}
        ></DatepickerInternal>
        {help && <p className="mt-2 text-sm text-gray-500">{help}</p>}
      </div>
    </>
  )
}
