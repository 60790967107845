
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { firstBy } from 'thenby'
import { nanoid } from 'nanoid'
import { GrpcError, StatusCode } from '@altipla/grpc-browser'

import { datetime } from 'platform/filters'
import PageLayout from 'layout/expedientes/PageLayout'
import { useNavigate } from 'hooks/navigate'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeRed from 'components/badges/BadgeRed'
import Card from 'components/Card'
import ShowPane from 'components/ShowPane'
import { Breadcrumb, Breadcrumbs } from 'layout/HeaderMenu'
import EntityLink from 'components/EntityLink'
import { getEntity, putEntity, batchGetEntities } from 'models/docs/entities'
import { parseTimestamp } from 'platform/datetime'
import { keyBy } from 'lodash-es'
import { CustomFieldsViewer } from 'components/CustomFieldsViewer'
import Icon from 'components/Icon'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonSecondarySm from 'components/buttons/ButtonSecondarySm'
import { ButtonAsyncSecondarySm } from 'components/buttons/ButtonAsyncSecondarySm'
import CardTable from 'components/CardTable'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import { EditDeadline } from 'views/expedientes/internal/EditDeadline'
import { EditTask } from 'views/expedientes/internal/EditTask'
import { AddInclude, InvalidIncludeError } from 'views/expedientes/internal/AddInclude'
import { docs } from 'services/discovery'
import AccessClient from 'services/Docs/access/access'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const accessClient = new AccessClient(docs())


function Deadlines({ record, onChange, recordCode }) {
  let [edit, setEdit] = useState({})
  let [open, setOpen] = useState(false)

  async function save(data) {
    let record = await getEntity(`records/${recordCode}`)
    record.deadlines = record.deadlines || []
    let prev = record.deadlines.findIndex(d => d.name === data.name)
    if (prev !== -1) {
      record.deadlines[prev] = data
    } else {
      record.deadlines.push(data)
    }
    await putEntity('records', { record })

    await onChange()
    setOpen(false)
  }

  function openAdd() {
    setEdit({
      name: `deadlines/${nanoid()}`,
    })
    setOpen(true)
  }

  return (
    <>
      <CardTable
        title="Vencimientos"
        empty="No hay vencimientos registrados en este expediente."
        header={
          <>
            <HeaderCell>Nombre del vencimiento</HeaderCell>
            <HeaderCell>Responsable</HeaderCell>
            <HeaderCell>Fecha límite</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        actions={
          <ButtonPrimary onClick={openAdd}>
            <Icon solid name="plus" className="mr-2" />
            Añadir
          </ButtonPrimary>
        }
      >
        {record.deadlines?.map(deadline => (
          <TableRow key={deadline.name}>
            <Cell>{deadline.displayName}</Cell>
            <Cell>{deadline.owner}</Cell>
            <Cell>{datetime(deadline.deadlineTime, 'DATE')}</Cell>
            <Cell actions>
              <ButtonSecondarySm
                onClick={() => {
                  setEdit(deadline)
                  setOpen(true)
                }}
              >
                <Icon solid name="pencil" className="mr-2" />
                Editar
              </ButtonSecondarySm>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
      
      <EditDeadline
        open={open}
        deadline={edit}
        onClose={() => setOpen(false)}
        onSave={save}
      ></EditDeadline>
    </>
  )
}

function Tasks({ record, onChange, recordCode }) {
  let [edit, setEdit] = useState({})
  let [open, setOpen] = useState(false)

  async function save(data) {
    let record = await getEntity(`records/${recordCode}`)
    record.tasks = record.tasks || []
    let prev = record.tasks.findIndex(d => d.name === data.name)
    if (prev !== -1) {
      record.tasks[prev] = data
    } else {
      record.tasks.push(data)
    }
    await putEntity('records', { record })

    await onChange()
    setOpen(false)
  }

  function openAdd() {
    setEdit({
      name: `tasks/${nanoid()}`,
      status: 'STATUS_OPEN',
    })
    setOpen(true)
  }

  async function changeStatus(task, status) {
    let record = await getEntity(`records/${recordCode}`)
    record.tasks
      .find(t => t.name === task.name)
      .status = status
    await putEntity('records', { record })
    await onChange()
  }

  return (
    <>
      <CardTable
        title="Actuaciones"
        empty="No hay actuaciones registradas en este expediente."
        header={
          <>
            <HeaderCell>Gestor</HeaderCell>
            <HeaderCell>Nombre de la actuación</HeaderCell>
            <HeaderCell>Fecha límite</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        actions={
          <ButtonPrimary onClick={openAdd}>
            <Icon solid name="plus" className="mr-2" />
            Añadir
          </ButtonPrimary>
        }
      >
        {record.tasks?.map(task => (
          <TableRow key={task.name}>
            <Cell>{task.owner}</Cell>
            <Cell>{task.displayName}</Cell>
            <Cell>{datetime(task.deadlineTime, 'DATETIME')}</Cell>
            <Cell>{task.status === 'STATUS_OPEN' ? <BadgeRed>Pendiente</BadgeRed> : <BadgeGreen>Hecha</BadgeGreen>}</Cell>
            <Cell actions>
              {task.status === 'STATUS_OPEN' &&
                <ButtonAsyncSecondarySm
                  onClick={() => changeStatus(task, 'STATUS_CLOSED')}
                >
                  <Icon solid name="check-square" className="mr-2" />
                  Marcar como hecha
                </ButtonAsyncSecondarySm>
              }
              {task.status === 'STATUS_CLOSED' &&
                <ButtonAsyncSecondarySm
                  onClick={() => changeStatus(task, 'STATUS_OPEN')}
                >
                  <Icon solid name="times-square" className="mr-2" />
                  Marcar como pendiente
                </ButtonAsyncSecondarySm>
              }
              <ButtonSecondarySm
                onClick={() => {
                  setEdit(task)
                  setOpen(true)
                }}
              >
                <Icon solid name="pencil" className="mr-2" />
                Editar
              </ButtonSecondarySm>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
      
      <EditTask
        open={open}
        task={edit}
        onClose={() => setOpen(false)}
        onSave={save}
      ></EditTask>
    </>
  )
}

function Includes({ includes, recordCode, onChange }) {
  let [open, setOpen] = useState(false)

  async function save(modifier) {
    let record = await getEntity(`records/${recordCode}`)
    record.includedDocuments = record.includedDocuments || []
    record.includedRecords = record.includedRecords || []
    modifier(record)
    await putEntity('records', { record })
  
    await onChange()
    setOpen(false)
  }

  async function saveRecord(record) {
    try {
      await getEntity(`records/${record}`)
    } catch (err) {
      if (err instanceof GrpcError) {
        if (err.code === StatusCode.NotFound) {
          throw new InvalidIncludeError(record)
        }
      }
      throw err
    }

    let { access } = await accessClient.AccessRecord({
      name: `records/${record}`,
      level: 'LEVEL_VIEW',
    })
    if (!access) {
      throw new InvalidIncludeError(record)
    }

    await save(r => r.includedRecords.push(record))
  }
  
  async function saveDocument(document) {
    try {
      await getEntity(`documents/${document}`)
    } catch (err) {
      if (err instanceof GrpcError) {
        if (err.code === StatusCode.NotFound) {
          throw new InvalidIncludeError(document)
        }
      }
      throw err
    }

    let { access } = await accessClient.AccessDocument({
      name: `documents/${document}`,
      level: 'LEVEL_VIEW',
    })
    if (!access) {
      throw InvalidIncludeError(document)
    }

    await save(r => r.includedDocuments.push(document))
  }

  return (
    <>  
      <ul className="grid grid-cols-1 gap-2">
        {includes.map((include, index) => (
          <li key={index} className="col-span-1 shadow-sm rounded-md border border-gray-200 bg-white truncate flex items-center">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <a href={include.link} className="text-gray-900 font-medium hover:text-gray-600">{include.title}</a>
              <p className="text-gray-500">{include.subtitle}</p>
            </div>
          </li>
        ))}

        <li>
          <ButtonPrimary onClick={() => setOpen(true)} className="w-full justify-center">
            <Icon solid name="plus" className="mr-2" />
            Añadir referencia
          </ButtonPrimary>
        </li>
      </ul>

      <AddInclude
        open={open}
        onClose={() => setOpen(false)}
        onSaveRecord={saveRecord}
        onSaveDocument={saveDocument}
      ></AddInclude>
    </>
  )
}

async function fetchDocuments(codes) {
  if  (!codes) {
    return []
  }

  let includes = []
  for (let code of codes) {
    let { access } = await accessClient.AccessDocument({
      name: `documents/${code}`,
      level: 'LEVEL_VIEW',
    })
    if (!access) {
      continue
    }

    let document = await getEntity(`documents/${code}`)
    let [ownerRef, type] = await batchGetEntities([document.owners[0].name, document.owners[0].documentType])
    let refs = await fetchPersonasSociedades([ownerRef.owner])
    let owner = refs.resolve(ownerRef.owner)

    includes.push({
      link: `/archive/documents/${code}`,
      title: document.title,
      subtitle: `${type.displayName} - ${owner.nombre}`,
    })
  }

  return includes
}

async function fetchRecords(codes) {
  if (!codes) {
    return []
  }

  let includes = []
  for (let code of codes) {
    let { access } = await accessClient.AccessRecord({
      name: `records/${code}`,
      level: 'LEVEL_VIEW',
    })
    if (!access) {
      continue
    }

    let record = await getEntity(`records/${code}`)
    let family = await getEntity(record.recordFamily)

    includes.push({
      link: `/records/${code}`,
      title: record.title,
      subtitle: family.displayName,
    })
  }

  return includes
}

export function ExpedientesShow() {
  let history = useHistory()
  let { code: recordCode } = useParams()
  let [record, setRecord] = useState({})
  let [includes, setIncludes] = useState([])
  let [canEdit, setCanEdit] = useState(false)

  async function fetchRecord() {
    let { access } = await accessClient.AccessRecord({
      name: `records/${recordCode}`,
      level: 'LEVEL_VIEW',
    })
    if (!access) {
      history.push('/records')
      return
    }

    let record = await getEntity(`records/${recordCode}`)

    record.recordFamily = await getEntity(record.recordFamily)
    record.originDocument = await getEntity(record.originDocument)
    record.createTime = parseTimestamp(record.createTime)
    
    record.deadlines = record.deadlines || []
    record.deadlines.forEach(deadline => {
      deadline.deadlineTime = parseTimestamp(deadline.deadlineTime)
    })
    record.deadlines.sort(firstBy('deadlineTime', 'asc'))
   
    record.tasks = record.tasks || []
    record.tasks.forEach(task => {
      task.deadlineTime = parseTimestamp(task.deadlineTime)
    })
    record.tasks.sort(firstBy('deadlineTime', 'asc'))

    if (record.recordFamily?.fields) {
      let fieldsInfo = keyBy(record.recordFamily.fields, 'name')
      record.fields = record.fields?.map(field => {
        field.info = fieldsInfo[field.name]
        field.info.options = keyBy(field.info.select?.options, 'name')
        return field
      })
    }

    includes = []
    includes = includes.concat(await fetchDocuments(record.includedDocuments))
    includes = includes.concat(await fetchRecords(record.includedRecords))

    if (record.status === 'STATUS_OPEN') {
      let { access } = await accessClient.AccessRecord({
        name: `records/${recordCode}`,
        level: 'LEVEL_EDIT',
      })
      setCanEdit(access)
    }
    
    setRecord(record)
    setIncludes(includes)
  }

  useNavigate(fetchRecord)

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/records">Expedientes</Breadcrumb>
          <Breadcrumb url={`/${record.name}`}>{record.title}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="flex space-x-16 items-center mb-5">
        <div className="flex-grow gap-x-4 items-baseline flex">
          <h3 className="text-2xl font-semibold font-display text-gray-900 sm:text-3xl">{record.title}</h3>
          {record.status === 'STATUS_OPEN' ? <BadgeGreen>Abierto</BadgeGreen> : <BadgeRed>Finalizado</BadgeRed>}
        </div>
        <div>
          {canEdit &&
            <ButtonLinkSecondary to={`/${record.name}/edit`}>
              <Icon solid name="pencil" className="mr-2" />
              Editar
            </ButtonLinkSecondary>
          }
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-4 gap-y-5">
        <div className="col-span-2 space-y-5">
          <Card
            title="Información general"
            subtitle="Información general del expediente."
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ShowPane title="Fecha y hora de alta">{datetime(record.createTime, 'DATETIME')}</ShowPane>
              <ShowPane title="Documento origen" className="col-span-2">
                {record.originDocument &&
                  <EntityLink to={`/archive/${record.originDocument.name}`}>
                    {record.originDocument.title}
                  </EntityLink>
                }
              </ShowPane>
              <ShowPane title="Familia de expedientes" className="col-span-2">
                {record.recordFamily?.displayName}
              </ShowPane>
            </div>
          </Card>

          {record.fields &&
            <Card
              title="Campos personalizados"
              subtitle="Información personalizada para esta familia concreta de expedientes."
              actions={
                <>
                  {canEdit &&
                    <ButtonLinkSecondary to={`/${record.name}/edit`}>
                      <Icon solid name="pencil" className="mr-2" />
                      Editar
                    </ButtonLinkSecondary>
                  }
                </>
              }
            >
              <CustomFieldsViewer fields={record.fields} grid />
            </Card>
          }
        </div>

        <div>
          <Includes includes={includes} onChange={fetchRecord} recordCode={recordCode} />
        </div>

        <div className="col-span-3 space-y-5">
          <Deadlines record={record} onChange={fetchRecord} recordCode={recordCode} />
          <Tasks record={record} onChange={fetchRecord} recordCode={recordCode} />
        </div>
      </div>
    </PageLayout>
  )
}
