
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import UsersClient from 'services/Users/users/users'
import GroupsClient from 'services/Users/groups/groups'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import Selector from 'components/simple-forms/Selector'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'


const usersClient = new UsersClient(users())
const groupsClient = new GroupsClient(users())


export default function UsersCreate() {
  let history = useHistory()

  let [form, setForm] = useState({})
  let [groups, setGroups] = useState({})

  useNavigate(async () => {
    let { groups } = await groupsClient.List()
    setGroups(groups?.map(group => {
      return {
        value: group.name,
        label: group.displayName,
      }
    }))
  })

  async function create() {
    await usersClient.Create(form)
    history.push('/manage/users')
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/users">Usuarios</Breadcrumb>
          <Breadcrumb url="/manage/users/create">Crear usuario</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Crear usuario"
        subheader="Crea un usuario con acceso al panel administrativo."
        footer={
          <ButtonPrimary onClick={create}>
            <Icon solid name="plus" className="mr-2" />
            Crear usuario
          </ButtonPrimary>
        }
      >
        <Input
          className="mb-6"
          label="Email"
          type="email"
          onChange={value => {
            setForm({
              ...form,
              email: value,
            })
          }}
        ></Input>

        <Selector
          label="Grupos"
          name="groups"
          multiple
          options={groups}
          onChange={value => {
            setForm({
              ...form,
              groups: value,
            })
          }}
        ></Selector>
      </CardHeader>
    </PageLayout>
  )
}
