
import { webinterna } from 'services/discovery'
import PlanesCuentasClient from 'services/WebInterna/planes_cuentas/planes_cuentas'


const planesCuentasClient = new PlanesCuentasClient(webinterna())


export let REF_PLAN_CUENTAS = {
  loader: async names => {
    let { planesCuentas } = await planesCuentasClient.List()
    planesCuentas = planesCuentas.filter(planCuentas => names.includes(planCuentas.name))
    planesCuentas = planesCuentas || []
    planesCuentas = planesCuentas.map(p => {
      let agrupaciones = []
      let descripciones = []
      let denominaciones = []
      let cuentasContables = []
      p.partidasAnaliticas.forEach(pa => {
        agrupaciones.push(pa.agrupacion1)
        descripciones.push(pa.descripcion)
        denominaciones.push(pa.denominacion)
        cuentasContables.push(pa.cuentaContable)
      })
      return {
        ...p,
        label: p.denominacion,
        descriptor: 'Plan de cuentas',
        agrupaciones,
        descripciones,
        denominaciones,
        cuentasContables,
      }
    })
    return planesCuentas
  },
  link: name => `/webinterna/planes-cuentas/${name}`,
  label: 'Plan de cuentas',
  fields: {
    planCuentas: {
      label: 'Plan Cuentas',
      type: 'text',
      wide: false,
      extractor: entity => entity.denominacion,
    },
    agrupacion: {
      label: 'Agrupación',
      type: 'text',
      wide: false,
      multi: true,
      extractor: entity => entity.agrupaciones,
    },
    denominacion: {
      label: 'Denominación',
      type: 'text',
      wide: false,
      multi: true,
      extractor: entity => entity.denominaciones,
    },
    descripcion: {
      label: 'Descripción',
      type: 'text',
      wide: false,
      multi: true,
      extractor: entity => entity.descripciones,
    },
    cuentaContable: {
      label: 'Cuenta contable',
      type: 'text',
      wide: false,
      multi: true,
      extractor: entity => entity.cuentasContables,
    },
  },
}
