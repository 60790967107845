
import { parseDate } from 'platform/datetime'
import Presupuesto, { printMoney, printRatio, printPercentage, printArea } from '@azpool/presupuesto'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import PresupuestosClient from 'services/WebInterna/presupuestos/presupuestos'
import { webinterna } from 'services/discovery'

const proyectosClient = new ProyectosClient(webinterna())
const presupuestosClient = new PresupuestosClient(webinterna())


export let REF_PROYECTO = {
  loader: async (names) => {
    let reply = await proyectosClient.BatchGet({ names })
    let presupuestosReply = await presupuestosClient.List()
    let presupuestos = presupuestosReply.presupuestos || []
    let proyectos = await Promise.all(reply.proyectos.map(async p => {
      let presupuesto = presupuestos
        .filter(presupuesto => presupuesto.proyecto === p.name)
        .find(presupuesto => presupuesto.vigente)

      if (presupuesto) {
        if (presupuesto) {
          presupuesto.elaboracionDate = parseDate(presupuesto.elaboracionDate)
          presupuesto.aprobacionDate = parseDate(presupuesto.aprobacionDate)
          presupuesto.valores = new Presupuesto(JSON.parse(presupuesto.valores))
        }
      }

      return {
        ...p,
        label: p.denominacion,
        descriptor: 'Proyecto',
        presupuesto,
      }
    }))
    return proyectos
  },
  link: name => `/webinterna/proyectos/${name}`,
  label: 'Proyecto',
  fields: {
    nombre: {
      label: 'Nombre',
      type: 'text',
      wide: false,
      extractor: entity => entity.denominacion,
    },
    tipo: {
      label: 'Tipo',
      type: 'text',
      wide: false,
      extractor: entity => {
        const TIPOS = {
          TIPO_A: 'Tipo A',
          TIPO_B: 'Tipo B',
          TIPO_C: 'Tipo C',
          TIPO_D: 'Tipo D',
        }
        return TIPOS[entity.tipo]
      },
    },
    estado: {
      label: 'Estado',
      type: 'badge',
      wide: false,
      extractor: entity => {
        const ESTADO = {
          ESTADO_EN_CURSO: 'En curso',
          ESTADO_FINALIZADO: 'Finalizado',
        }
        return {
          active: entity.estado === 'ESTADO_EN_CURSO',
          text: ESTADO[entity.estado],
        }
      },
    },
    promotor: {
      label: 'Promotor',
      type: 'persona',
      wide: true,
      extractor: entity => {
        return {
          sociedad: entity.promotor,
        }
      },
    },
    ordenanzaGeneral: {
      label: 'Ordenanza General',
      type: 'text',
      wide: false,
      extractor: entity => entity.ordenanzaGeneral,
    },
    ambitoDesarrollo: {
      label: 'Ámbito desarrollo',
      type: 'text',
      wide: false,
      extractor: entity => entity.ambitoDesarrollo,
    },
    tipoContable: {
      label: 'Tipo contable',
      type: 'text',
      wide: false,
      extractor: entity => {
        const TIPOS = {
          TIPO_CONTABLE_EXISTENCIAS_EN_CURSO: 'Existencias en curso',
          TIPO_CONTABLE_INMOBILIZADO_EN_CURSO: 'Inmobilizado en curso',
          TIPO_CONTABLE_EXISTENCIAS_FINALIZADO: 'Existencias finalizado',
          TIPO_CONTABLE_INMOBILIZADO_FINALIZADO: 'Inmobilizado finalizado',
        }
        return TIPOS[entity.tipoContable]
      },
    },
    cuentaContable: {
      label: 'Cuenta contable',
      type: 'text',
      wide: false,
      extractor: entity => entity.cuentaContable,
    },
    garantia1yDate: {
      label: 'Periodo garantía 1 año',
      type: 'date',
      wide: false,
      extractor: entity => parseDate(entity.garantia1yDate),
    },
    garantia3yDate: {
      label: 'Periodo garantía 3 años',
      type: 'date',
      wide: false,
      extractor: entity => parseDate(entity.garantia3yDate),
    },
    garantia10yDate: {
      label: 'Periodo garantía 10 años',
      type: 'date',
      wide: false,
      extractor: entity => parseDate(entity.garantia10yDate),
    },
    cfoDate: {
      label: 'Fecha CFO',
      type: 'date',
      wide: false,
      extractor: entity => parseDate(entity.cfoDate),
    },
    uso: {
      label: 'Uso',
      type: 'text',
      wide: false,
      extractor: entity => {
        const USOS = {
          USO_VIVIENDA_VENTA: 'Vivienda Venta',
          USO_GARAJES_VENTA: 'Garajes Venta',
          USO_TRASTEROS_VENTA: 'Trasteros Venta',
          USO_VIVIENDAS_ARRENDAMIENTO: 'Viviendas Arrendamiento',
          USO_GARAJES_ARRENDAMIENTO: 'Garajes Arrendamiento',
          USO_TRASTEROS_ARRENDAMIENTO: 'Trasteros Arrendamiento',
          USO_COMERCIAL: 'Comercial',
          USO_RESIDENCIAL_COMERCIAL: 'Residencial Comercial',
          USO_INDUSTRIAL: 'Industrial',
          USO_LOGISTICO: 'Logístico',
          USO_HOTELERO: 'Hotelero',
          USO_OFICINAS: 'Oficinas',
          USO_INSTALACIONES_AGRICOLAS: 'Instalaciones Agrícolas',
          USO_LIBRE: 'Libre',
        }
        return USOS[entity.presupuesto.usoPrincipal]
      },
    },
    ingresosTotales: {
      label: 'Ingresos totales',
      type: 'text',
      wide: false,
      extractor: entity => printMoney(entity.presupuesto.valores.resumen.vppOverviews[0].total),
    },
    costesTotales: {
      label: 'Costes totales',
      type: 'text',
      wide: false,
      extractor: entity => printMoney(entity.presupuesto.valores.resumen.vppOverviews[1].total),
    },
    noi: {
      label: 'NOI',
      type: 'text',
      wide: false,
      extractor: entity => printRatio(entity.presupuesto.valores.resumen.comercialOverviews[0].total),
    },
    rbe: {
      label: 'R.B.E.',
      type: 'text',
      wide: false,
      extractor: entity => printPercentage(entity.presupuesto.valores.resumen.comercialOverviews[1].total),
    },
    yield: {
      label: 'Yield',
      type: 'text',
      wide: false,
      extractor: entity => printPercentage(entity.presupuesto.valores.resumen.comercialOverviews[2].total),
    },
    suelo: {
      label: 'Suelo m²',
      type: 'text',
      wide: false,
      extractor: entity => printArea(entity.presupuesto.valores.superficies.suelo),
    },
    edificabilidad: {
      label: 'Edificabilidad máx. m²',
      type: 'text',
      wide: false,
      extractor: entity => printArea(entity.presupuesto.valores.superficies.edificabilidadMax),
    },
  },
}
