// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Notifications/messages/messages.proto

import { Caller } from '@altipla/grpc-browser';


export default class MessagesClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/messages`, req, false, []);
  }

  MarkAsRead(req) {
    return this._caller.send('PUT', `/messages/${req.name}:markAsRead`, req, false, ['name']);
  }

  Create(req) {
    return this._caller.send('POST', `/messages`, req, true, []);
  }
};

