
import React, { useEffect, useState } from 'react'

import ButtonExternalLinkPrimary from 'components/buttons/ButtonExternalLinkPrimary'
import Icon from 'components/Icon'
import { accountInfo } from 'auth/active-directory'
import * as query from 'models/docs/query'
import { runQuery } from 'models/docs/entities'


export function DocumentDownload({ doc }) {
  let [groups, setGroups] = useState([])

  useEffect(() => {
    async function getData() {
      let q = query.build(
        query.and('payload.accessGroup.owners', doc.owners[0].name),
        query.and('payload.accessGroup.documentTypes', doc.owners[0].documentType),
        query.and('payload.accessGroup.users', accountInfo().name),
      )
      setGroups(await runQuery('access-groups', q))
    }
    if (doc && doc.owners) {
      getData()
    }
  }, [doc, doc.owners])

  let downloadEnabled = groups.some(document => document.level === 'LEVEL_PHYSICAL_ACCESS' || document.level === 'LEVEL_DOWNLOAD')
  if (!downloadEnabled) {
    return null
  }

  return (
    <ButtonExternalLinkPrimary to={doc.fileUrl}>
      <Icon solid name="download" className="mr-2" />
      Descargar copia
    </ButtonExternalLinkPrimary>
  )
}
