
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import firstBy from 'thenby'

import RolesClient from 'services/Users/roles/roles'
import GroupsClient from 'services/Users/groups/groups'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import Selector from 'components/simple-forms/Selector'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'


const rolesClient = new RolesClient(users())
const groupsClient = new GroupsClient(users())


export default function GroupsCreate() {
  let history = useHistory()

  let [form, setForm] = useState({})
  let [roles, setRoles] = useState({})

  useNavigate(async () => {
    let { roles } = await rolesClient.List()
    roles = roles?.sort(firstBy('displayName'))
    setRoles(roles?.map(role => {
      return {
        value: role.name,
        label: role.displayName,
      }
    }))
  })

  async function create() {
    let { name } = await groupsClient.Create(form)
    history.push(`/manage/groups/${name}`)
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/groups">Grupos</Breadcrumb>
          <Breadcrumb url="/manage/groups/create">Crear grupo</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Crear grupo"
        footer={
          <ButtonPrimary onClick={create}>
            <Icon solid name="plus" className="mr-2" />
            Crear grupo
          </ButtonPrimary>
        }
      >
        <Input
          className="mb-6"
          label="Nombre"
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        ></Input>

        <Selector
          label="Roles"
          name="roles"
          multiple
          options={roles}
          onChange={value => {
            setForm({
              ...form,
              roles: value,
            })
          }}
        ></Selector>
      </CardHeader>
    </PageLayout>
  )
}
