
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { keyBy } from 'lodash-es'
import { nanoid } from 'nanoid'

import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import Icon from 'components/Icon'
import FullscreenLayout from 'layout/docs/FullscreenLayout'
import { getEntity, batchGetEntities, putEntity, listEntities } from 'models/docs/entities'
import { waitDocumentChanged } from 'models/docs/documents'
import UsersClient from 'services/Users/users/users'
import FilesClient from 'services/Docs/files/files'
import { users, docs } from 'services/discovery'
import { accountInfo } from 'auth/active-directory'
import { getRole } from 'models/docs/inboxes-groups'
import { getFieldValidations } from 'models/docs/document-types'
import PdfPreview from 'components/PdfPreview'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Selector from 'components/forms/Selector'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import { parseTimestamp, serializeTimestamp } from 'platform/datetime'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import AlertWarning from 'components/alerts/AlertWarning'
import { useNavigate } from 'hooks/navigate'
import { CustomFieldsEditor } from 'components/CustomFieldsEditor'


const usersClient = new UsersClient(users())
const filesClient = new FilesClient(docs())


function Submit({ form, children }) {
  return (
    <div>
      {form.formState.isSubmitting ?
        <button type="button" disabled className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-300 cursor-default">
          <Icon solid spin name="spinner" className="mr-2" />
          Enviando datos...
        </button>
        :
        <ButtonPrimary type="submit">
          <Icon solid name="save" className="mr-2" />
          {children}
        </ButtonPrimary>
      }
    </div>
  )
}

export default function ClassificationInbox() {
  let history = useHistory()
  let { parent, code } = useParams()
  let [inbox, setInbox] = useState({})
  let [doc, setDoc] = useState({})
  let [revision, setRevision] = useState(null)
  let [fields, setFields] = useState([])

  useNavigate(async () => {
    let group = await getEntity(`inboxes-groups/${parent}`)

    let u = new URL(window.location.href)

    let role = u.searchParams.get('role')
    let ownerName = u.searchParams.get('owner')
    let docType = u.searchParams.get('docType')
    if (!role || !ownerName || !docType) {
      history.push('/docs')
    }
    if (!group.users.includes(accountInfo().name) || !group.owners.includes(ownerName) || !group.documentTypes.includes(docType)) {
      history.push('/docs')
    }
    
    let name = `documents/${code}`
    let entities = await batchGetEntities([ownerName, docType, name])
    entities = keyBy(entities, 'name')
    role = getRole(role)
    
    let doc = entities[name]
    if (doc.state !== 'STATE_CLASSIFICATION') {
      history.push('/docs')
    }

    let revisions = await listEntities('revisions', doc.name)
    revisions = revisions
      .filter(r => r.validationReview)
      .map(r => {
        r.createTime = parseTimestamp(r.createTime)
        return r
      })
      .sort((a, b) => b.createTime - a.createTime)
    if (revisions.length) {
      let revision = revisions[0]
      revision.user = await usersClient.Get({ name: revision.user })
      setRevision(revision)
    }

    let querystring = new URLSearchParams({
      role: role.value,
      owner: ownerName,
      docType,
    })
    let owners = await fetchPersonasSociedades([ entities[ownerName].owner ])
    let owner = owners.resolve(entities[ownerName].owner)
    let inbox = {
      group,
      displayName: `${role.label}: ${owner.nombre}`,
      role,
      owner: {
        ...owner,
        name: entities[ownerName].name,
      },
      documentType: entities[docType],
      inboxUrl: `/docs/${group.name}?${querystring.toString()}`,
      url: `/docs/${group.name}/classification/${doc.name}?${querystring.toString()}`,
    }
    inbox.documentType.subtypeOptions = inbox.documentType.subtypes?.map(subtype => {
      return {
        value: subtype,
        label: subtype,
      }
    })
    setInbox(inbox)

    let docFields = keyBy(doc.fields, 'name')
    setFields(inbox.documentType.fields?.map(field => {
      let docField = docFields[field.name]
      if (docField) {
        field.values = docField.values?.map(v => {
          v.ghostKey = nanoid()
          return v
        })
      } else {
        field.values = [
          { ghostKey: nanoid() },
        ]
      }

      if (field.select) {
        field.select.options = field.select.options?.map(opt => {
          return {
            value: opt.name,
            label: opt.displayName,
          }
        })

        if (field.multiple) {
          if (docField) {
            field.values = docField.values?.map(val => val.select)
          } else {
            field.values = []
          }
        }
      }

      field.validations = getFieldValidations(field)
      return field
    }))
    
    doc.uploader = await usersClient.Get({ name: doc.uploader })
    doc.file = await filesClient.Get({ name: doc.file })
    doc.subtype = doc.owners[0].subtype
    setDoc(doc)
  })

  let form = useForm(async (data) => {
    let docFields = fields?.map((field, idx) => {
      let values = data.fields[idx].values.map(val => {
        if (field.select && field.multiple) {
          return { select: val }
        }
        return val
      })
      return {
        name: field.name,
        values,
      }
    })

    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      createTime: serializeTimestamp(new Date()),
      classification: {
        title: data.title,
        subtype: data.subtype,
        fields: docFields,
      },
    }
    await putEntity('revisions', { revision })

    await waitDocumentChanged(doc)
    history.push(inbox.inboxUrl)
  })

  return (
    <FullscreenLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url={inbox.inboxUrl}>Bandeja de Clasificación</Breadcrumb>
          <Breadcrumb url={inbox.url}>{inbox.displayName}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{doc.suggestedTitle || doc.title}</h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <Icon regular name="user-crown" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {inbox.owner?.nombre}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <Icon regular name="file-contract" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {inbox.documentType?.displayName}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <Icon solid name="upload" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {doc.uploader?.displayName}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 max-w-3xl grid lg:grid-cols-5 gap-6 lg:max-w-7xl lg:grid-flow-col-dense">
        <Form form={form} className="space-y-6 col-start-1 lg:col-span-3 mb-4">
          {revision &&
            <AlertWarning>
              <div>
                <span className="font-medium">{revision.user.displayName}</span>
                &nbsp;ha requerido cambios en la clasificación del documento:
              </div>
              <div className="mt-1 italic">{revision.validationReview.comment}</div>
            </AlertWarning>
          }

          <Card
            title="Información general"
            subtitle="Información requerida para este tipo de documento."
            footer={(!fields?.length) && <Submit form={form}>Clasificar documento</Submit>}
          >
            <Input
              form={form}
              className="mb-4"
              label="Título"
              name="title"
              validations="required"
              value={doc.title || doc.suggestedTitle}
            ></Input>

            {inbox.documentType?.subtypeOptions &&
              <Selector
                form={form}
                label="Subtipo de documento"
                name="subtype"
                options={inbox.documentType?.subtypeOptions}
                validations="required"
                value={doc.subtype}
              ></Selector>
            }
          </Card>

          {fields?.length &&
            <Card
              title="Campos personalizados"
              subtitle="Rellena los siguientes datos acorde a lo que requiere el tipo de documento."
              actions={
                <div className="text-red-700 text-sm">
                  <span className="align-text-top">*</span>
                  &nbsp;Campo requerido
                </div>
              }
              footer={<Submit form={form}>Clasificar documento</Submit>}
            >
              <CustomFieldsEditor form={form} fields={fields} onChange={setFields} />
            </Card>
          }
        </Form>

        <div className="hidden lg:block lg:col-start-4 lg:col-span-2">
          <div className="bg-white py-6 shadow sm:rounded-lg sticky top-6">
            <h2 id="timeline-title" className="pl-6 text-lg font-medium text-gray-900">Previsualización</h2>
            <PdfPreview url={doc.file?.previewUrl} />
          </div>
        </div>
      </div>
    </FullscreenLayout>
  )
}
