
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { isEqual, clone, some } from 'lodash'
import firstBy from 'thenby'

import RolesClient from 'services/Users/roles/roles'
import GroupsClient from 'services/Users/groups/groups'
import { useNavigate } from 'hooks/navigate'
import { users } from 'services/discovery'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import resolver from 'platform/resolver'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import CardHeader from 'components/CardHeader'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import Input from 'components/simple-forms/Input'
import Selector from 'components/simple-forms/Selector'
import UsersClient from 'services/Users/users/users'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'

const groupsClient = new GroupsClient(users())
const rolesClient = new RolesClient(users())
const usersClient = new UsersClient(users())

export default function GroupsEdit() {
  let history = useHistory()
  let { name } = useParams()
  let [input, setInput] = useState({})
  let [roles, setRoles] = useState({})
  let [group, setGroup] = useState({})
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { group, roles, users } = await resolver.all({
      group: groupsClient.Get({ name }),
      roles: rolesClient.List().then(reply => reply.roles || []),
      users: usersClient.List().then(reply => reply.users || []),
    })

    setUsers(users)

    roles = roles?.sort(firstBy('displayName'))
    setRoles(roles?.map(role => {
      return {
        value: role.name,
        label: role.displayName,
      }
    }))

    setInput(clone(group))

    group.lastRolesChangeTime = parseTimestamp(group.lastRolesChangeTime)
    setGroup(group)
  })

  async function save() {
    await groupsClient.Update(input)
    setGroup({
      ...group,
      displayName: input.displayName,
      roles: input.roles,
    })
  }

  async function deleteGroup() {
    await groupsClient.Delete(group)
    history.push('/manage/groups')
  }

  let hasChanges = some([
    input.displayName !== group.displayName,
    !isEqual(input.roles, group.roles),
  ])

  let groupHasUsers = !!users.find(user => user.groups?.includes(group.name))
  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/groups">Grupos</Breadcrumb>
          <Breadcrumb url={`/manage/groups/${group.name}`}>{group.displayName}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Información del grupo"
        subheader="Gestión de grupos de permisos."
        footer={
          <div className="flex justify-between w-full">
            <ButtonAsyncDanger
              onClick={deleteGroup}
              disabled={groupHasUsers}
            >
              <Icon solid name="trash" className="mr-2" />
              <span title={groupHasUsers ? 'No se pueden eliminar grupos con usuarios asignados' : ''}>
                Eliminar grupo
              </span>
            </ButtonAsyncDanger>
            <ButtonAsyncPrimary
              onClick={save}
              disabled={!hasChanges}
            >
              <Icon regular name="save" className="mr-2" />
              Actualizar grupo
            </ButtonAsyncPrimary>
          </div>
          
        }
        actions={
          <span className="italic text-gray-500 text-sm">Última actualización: {datetime(group.lastRolesChangeTime, 'DATETIME')}</span>
        }
      >
        <Input
          className="mb-6"
          label="Nombre"
          value={input.displayName}
          onChange={value => {
            setInput({
              ...input,
              displayName: value,
            })
          }}
        />
        
        <Selector
          label="Roles"
          name="roles"
          multiple
          value={input.roles}
          options={roles}
          onChange={value => {
            setInput({
              ...input,
              roles: value,
            })
          }}
        ></Selector>
      </CardHeader>
    </PageLayout>
  )
}
