
import React from 'react'

import { HeaderMenu } from 'layout/HeaderMenu'
import NavigateThrobber from 'components/NavigateThrobber'


export default function PageLayout({ breadcrumbs, children }) {
  return (
    <HeaderMenu breadcrumbs={breadcrumbs}>
      <div className="flex-1 overflow-y-scroll relative">
        <NavigateThrobber></NavigateThrobber>

        <div className="max-w-7xl mx-auto mb-12 py-10">
          {children}
        </div>
      </div>
    </HeaderMenu>
  )
}
