
import React from 'react'
import ReactDOM from 'react-dom'
import { pdfjs } from 'react-pdf'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import './styles/_main-autogenerated.css'
import './icons'

import AppLayout from 'layout/AppLayout'


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2f847f1981f44c6a96eb9795acebb14b@o50500.ingest.sentry.io/6225812',
    integrations: [
      new BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  })
}
ReactDOM.render(<React.StrictMode><AppLayout /></React.StrictMode>, document.getElementById('root'))
