
import React from 'react'
import cx from 'classnames'


export default function Textarea({ label, name, help, value, rows, onChange, className, disabled, placeholder }) {
  return (
    <div className={className}>
      <label htmlFor={name} className="block font-medium text-gray-700 text-sm">{label}</label>
      {help && <p className="text-gray-500 text-sm">{help}</p>}
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          id={name}
          name={name}
          rows={rows}
          placeholder={placeholder}
          className={cx(
            'form-textarea shadow-sm block w-full focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 rounded-md',
            {
              'bg-gray-100 cursor-not-allowed text-gray-400': disabled,
            },
          )}
          defaultValue={value || ''}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          disabled={disabled}
        ></textarea>
      </div>
    </div>
  )
}
