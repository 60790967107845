
import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { findLast } from 'lodash-es'

import { validationDescriptor } from 'models/webinterna/validations'
import { datetime } from 'platform/filters'
import { parseTimestamp } from 'platform/datetime'
import Icon from 'components/Icon'


export function PageHeader({ children, buttons, validation }) {
  let currentPath = useLocation().pathname
  let [validationLabel, setValidationLabel] = useState('')
  let [creacionTime, setCreacionTime] = useState(new Date())

  let back = findLast(children, child => child.props, children.length - 2).props.url
  
  useEffect(() => {
    if (validation) {
      setCreacionTime(parseTimestamp(validation.creacionTime))
      setValidationLabel(validationDescriptor(validation).label)
    }
  }, [validation])

  return (
    <div className="mb-8 mt-6">
      <div>
        <nav className="sm:hidden">
          <Link
            to={validation ? '/webinterna/validaciones' : back}
            className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
          >
            <Icon regular name="chevron-left" className="mr-2" />
            Atrás
          </Link>
        </nav>
        <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
          {validation &&
            <>
              <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
              <Breadcrumb url="/webinterna/validaciones">Validaciones</Breadcrumb>
              <Breadcrumb url={currentPath}>Validación: {validationLabel}</Breadcrumb>
            </>
          }
          {!validation && children}
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
            {validation && `Validación: ${validationLabel}`}
            {!validation && children[children.length - 1].props.children}
          </h2>
        </div>
        {!validation && buttons && buttons.props.children &&
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            {buttons}
          </div>
        }
      </div>

      {validation &&
        <div
          className={cx(
            'mt-6 p-4 border-l-4',
            {
              'bg-green-100 text-green-800 border-green-400': validation.accion === 'ACCION_CREAR',
              'bg-blue-100 text-blue-800 border-blue-400': validation.accion === 'ACCION_EDITAR',
              'bg-orange-100 text-orange-800 border-orange-400': validation.accion === 'ACCION_ELIMINAR',
            },
          )}
        >
          El usuario ha solicitado&nbsp;
          <span className="font-bold">
            {validation.accion === 'ACCION_CREAR' && 'CREAR'}
            {validation.accion === 'ACCION_EDITAR' && 'EDITAR'}
            {validation.accion === 'ACCION_ELIMINAR' && 'ELIMINAR'}
          </span>
          &nbsp;la entidad el&nbsp;
          {datetime(creacionTime, 'DATETIME')}
        </div>
      }
    </div>
  )
}


export function Breadcrumb({ children, url }) {
  return (<>
    <Link to={url} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">{children}</Link>
    <Icon regular name="chevron-right" className="mx-3 text-gray-400 text-xs last:invisible" />
  </>)
}
