
import React from 'react'
import cx from 'classnames'


export default function SquareTable({ header, children, empty, className }) {
  return (
    <div className={cx('flex flex-col', className)}>
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>{header}</tr>
              </thead>
              <tbody>{children}</tbody>
              {!children?.length &&
                <tbody>
                  <tr>
                    <td colSpan={header.props.children.length} className="bg-white text-center text-lg italic text-gray-400 py-20">{empty}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
