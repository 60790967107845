// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Docs/access/access.proto

import { Caller } from '@altipla/grpc-browser';


export default class AccessClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  AccessDocument(req) {
    return this._caller.send('GET', `/access/document`, req, false, []);
  }

  AccessRecord(req) {
    return this._caller.send('GET', `/access/record`, req, false, []);
  }

  ListDocuments(req) {
    return this._caller.send('GET', `/access/documents`, req, false, []);
  }

  ListRecords(req) {
    return this._caller.send('GET', `/access/records`, req, false, []);
  }
};

