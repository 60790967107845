
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'
import { uniq, keyBy } from 'lodash-es'

import ContratosClient from 'services/WebInterna/contratos/contratos'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import { webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import PriceViewer from 'components/PriceViewer'
import { Datatable, Cell, HeaderCell, RowMobile, useDatatable } from 'components/Datatable'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import resolver from 'platform/resolver'
import { priceNoSymbol } from 'platform/filters'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'


const contratosClient = new ContratosClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())


export default function ContratosList() {
  let {
    table,
    setRows,
    useAsyncLoad,
    setSortOrders,
  } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Por código',
        sort: firstBy('codigo'),
      },
      {
        name: 'De mayor precio a menor',
        sort: firstBy('precio', 'desc'),
      },
    ])

    let { contratos } = await contratosClient.List()
    if (!contratos) {
      contratos = []
    }

    let clientesNames = contratos.filter(c => c.cliente).map(c => c.cliente)
    let sociedadesNames = contratos.filter(c => c.proveedor).map(c => c.proveedor)
    let proyectosNames = contratos.filter(c => c.proyecto).map(c => c.proyecto)
    let inmueblesNames = contratos.filter(c => c.inmueble).map(c => c.inmueble)

    let { sociedades, proyectos, inmuebles, clientes } = await resolver.all({
      sociedades: sociedadesClient.BatchGet({ names: uniq(sociedadesNames) }).then(reply => reply.sociedades || []),
      proyectos: proyectosClient.BatchGet({ names: uniq(proyectosNames) }).then(reply => reply.proyectos || []),
      inmuebles: inmueblesClient.BatchGet({ names: uniq(inmueblesNames) }).then(reply => reply.inmuebles || []),
      clientes: fetchPersonasSociedades(clientesNames),
    })
    sociedades = keyBy(sociedades, 'name')
    proyectos = keyBy(proyectos, 'name')
    inmuebles = keyBy(inmuebles, 'name')

    contratos.forEach(contrato => {
      contrato.proveedor = sociedades[contrato.proveedor]
      contrato.proyecto = proyectos[contrato.proyecto]
      contrato.inmueble = inmuebles[contrato.inmueble]
      contrato.cliente = clientes.resolve(contrato.cliente)

      contrato.$search = [
        contrato.codigo,
        contrato.cliente?.nombre,
        contrato.proveedor?.denominacion,
        contrato.proyecto?.denominacion,
        contrato.inmueble?.denominacion,
        priceNoSymbol(contrato.precio),
      ]
    })
    setRows(contratos)
  })

  return (
    <PageLayout wide>
      <PageHeader
        buttons={<>
          <Acl perm="webinterna.validaciones.edit">
            <ButtonLinkPrimary to="/webinterna/contratos/edit/new">
              <Icon solid name="plus" className="mr-2" />
              Crear Contrato
            </ButtonLinkPrimary>
          </Acl>
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/contratos">Contratos</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell fit="right">Código</HeaderCell>
            <HeaderCell>Cliente</HeaderCell>
            <HeaderCell>Proveedor</HeaderCell>
            <HeaderCell>Proyecto</HeaderCell>
            <HeaderCell>Unidad Inmobiliaria</HeaderCell>
            <HeaderCell className="text-right">Precio</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/contratos/${row.name}`}>
            <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
              <div className="truncate space-y-1">
                <div>
                  {row.codigo}&nbsp;
                  {row.cliente &&
                    <>
                      &mdash;
                      <span className="font-medium text-gray-900">&nbsp;{row.cliente.nombre}</span>
                    </>
                  }
                </div>
                <PriceViewer value={row.precio}></PriceViewer>
              </div>
              <div className="space-y-1 mt-1 md:mt-0">
                <div>{row.proveedor?.denominacion}</div>
                <div>
                  {row.proyecto && row.inmueble
                    ? <>{row.proyecto.denominacion} &mdash; {row.inmueble.denominacion}</>
                    : <>{row.proyecto?.denominacion} {row.inmueble?.denominacion}</>
                  }
                </div>
              </div>
            </div>
          </RowMobile>
        }
        row={row =>
          <>
            <Cell fit="right" className="font-medium text-right">
              <Link
                to={`/webinterna/contratos/${row.name}`}
                className="text-gray-500 font-normal"
              >
                {row.codigo}
              </Link>
            </Cell>
            <Cell wrap className="w-full">
              <Link
                to={`/webinterna/contratos/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.cliente?.nombre}
              </Link>
            </Cell>
            <Cell>
              {row.proveedor?.denominacion}
            </Cell>
            <Cell>
              {row.proyecto?.denominacion}
            </Cell>
            <Cell>
              {row.inmueble?.denominacion}
            </Cell>
            <Cell className="text-right">
              <PriceViewer value={row.precio}></PriceViewer>
            </Cell>
            <Cell>
              <Acl perm="webinterna.validaciones.edit">
                <Link to={`/webinterna/contratos/edit/${row.name}`}>Editar</Link>
              </Acl>
            </Cell>
          </>
        }
      ></Datatable>
    </PageLayout>
  )
}
