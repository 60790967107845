
import React from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { getEntityOrDefault } from 'models/docs/entities'
import { users } from 'services/discovery'
import UsersClient from 'services/Users/users/users'
import { keyBy } from 'lodash-es'
import { LIBRARY_MANAGERS_NAME } from 'models/docs/library-managers'


const usersClient = new UsersClient(users())


export default function LibraryManagersList() {
  let { table, setRows } = usePaginatedTable()

  useNavigate(async () => {
    let reply = await getEntityOrDefault(LIBRARY_MANAGERS_NAME)
    reply.users = reply.users || []
    let users = await usersClient.List()
    users = users?.users || []
    users = keyBy(users, 'name')
    setRows(reply.users.map(user => {
      return {
        $search: [ users[user].displayName ],
        user: users[user],
      }
    }))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/library-managers">Gestores de biblioteca</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardPaginatedTable
        title="Gestores de biblioteca"
        subtitle="Administración de los gestores de biblioteca."
        empty="No hay gestores de biblioteca que mostrar."
        table={table}
        actions={
          <ButtonLinkPrimary to="/manage/library-managers/create">
            <Icon solid name="plus" className="mr-2" />
            Crear gestor de biblioteca
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        row={row => (
          <>
            <Cell>
              <Link to={`/manage/library-managers/${row.user.name}`} className="text-gray-700">{row.user.displayName}</Link>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/library-managers/${row.user.name}`}>Editar</Link>
            </Cell>
          </>
        )}
      >
      </CardPaginatedTable>
    </PageLayout>
  )
}
