// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Users/groups/groups.proto

import { Caller } from '@altipla/grpc-browser';


export default class GroupsClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/groups`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/groups/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/groups:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/groups`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/groups/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/groups/${req.name}`, req, false, ['name']);
  }
};

