
import React, { useState } from 'react'
import { some } from 'lodash-es'

import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonSecondaryDanger from 'components/buttons/ButtonSecondaryDanger'
import Card from 'components/Card'
import Icon from 'components/Icon'
import ShowPane from 'components/ShowPane'
import { useNavigate } from 'hooks/navigate'
import PageLayout from 'layout/archive/PageLayout'
import { Breadcrumb, Breadcrumbs } from 'layout/HeaderMenu'
import { getEntity, getEntityOrDefault } from 'models/docs/entities'
import { Link, useHistory, useParams } from 'react-router-dom'
import { users } from 'services/discovery'
import UsersClient from 'services/Users/users/users'
import { accountInfo } from 'auth/active-directory'
import * as query from 'models/docs/query'
import { runQuery } from 'models/docs/entities'
import { sendNotification, sendNotificationButton } from 'hooks/notifications'
import { LIBRARY_MANAGERS_NAME } from 'models/docs/library-managers'


const usersClient = new UsersClient(users())


export default function RequestWithdrawDocument() {
  let history = useHistory()
  let { code, userid } = useParams()
  let [doc, setDoc] = useState({})
  let [user, setUser] = useState({})

  useNavigate(async () => {
    let name = `documents/${code}`

    let doc = await getEntity(name)
    let user = await usersClient.Get({ name: userid })

    let q = query.build(
      query.and('payload.accessGroup.owners', doc.owners[0].name),
      query.and('payload.accessGroup.documentTypes', doc.owners[0].documentType),
      query.and('payload.accessGroup.users', accountInfo().name),
    )
    let groups = await runQuery('access-groups', q)

    let cannotAccess = some(
      [
        doc.format !== 'FORMAT_PHYSICAL',
        doc.state !== 'STATE_ARCHIVED',
        !groups.some(document => document.level === 'LEVEL_PHYSICAL_ACCESS'),
      ],
    )
    if (cannotAccess) {
      history.push('/archive')
    }

    setDoc(doc)
    setUser(user)
  })

  async function approveWithdraw() {
    let reply = await getEntityOrDefault(LIBRARY_MANAGERS_NAME)
    reply.users = reply.users || []
    sendNotificationButton(
      reply.users,
      'Un usuario solicita permiso para retirar un documento.',
      {
        text: 'Revisar solicitud',
        link: `/archive/withdraw/${doc.name.substr(10)}/${userid}`,
      },
    )
    history.push('/archive')
  }

  async function rejectWithdraw() {
    sendNotification(
      userid,
      'Tu solicitud para retirar un documento fue rechazada.',
    )
    history.push('/archive')
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url={`/archive/withdraw/request/${code}/${userid}`}>Retirar documento</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{doc.title}</h1>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <ButtonPrimary onClick={approveWithdraw}>
            <Icon solid name="books" className="mr-2" />
            Aprobar retirada
          </ButtonPrimary>
          <ButtonSecondaryDanger onClick={rejectWithdraw}>
            <Icon solid name="times" className="mr-2" />
            Rechazar retirada
          </ButtonSecondaryDanger>
        </div>
      </div>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl">
        <Card className="mt-8" title="Información general" subtitle="Información general de la petición.">
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <ShowPane title="Usuario solicitante">
              <Link to={`/manage/users/${user.name}`} className="text-gray-700">{user.displayName}</Link>
            </ShowPane>
            <ShowPane title="Documento" className="col-span-2">
              <Link to={`/archive/${doc.name}`} className="font-medium text-gray-900">{doc.title}</Link>
            </ShowPane>
          </div>
        </Card>
      </div>
    </PageLayout>
  )
}
