
import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { cloneDeep, some } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import BadgeGreen from 'components/badges/BadgeGreen'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import Textarea from 'components/simple-forms/Textarea'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import CardTable from 'components/CardTable'
import { getEntity, putEntity, listEntities } from 'models/docs/entities'
import { getFieldTypeText } from 'models/docs/document-types'
import Selector from 'components/simple-forms/Selector'


export default function DocumentTypesEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [docType, setDocType] = useState({})
  let [families, setFamilies] = useState([])
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await getEntity(`document-types/${name}`)
      setForm(reply)
      setDocType(reply)
    }

    let families = await listEntities('record-families')
    setFamilies(families.map(family => {
      return {
        label: family.displayName,
        value: family.name,
      }
    }))
  })

  async function save() {
    let req = await putEntity('document-types', { documentType: cloneDeep(form) })
    if (!name) {
      history.push(`/manage/${req.name}`)
      return
    }
    setDocType(req)
    setForm(req)
  }

  function editField(idx) {
    history.push(`/manage/document-types/${name}/fields/${idx}`)
  }

  let changed = some([
    docType.displayName !== form.displayName,
    docType.description !== form.description,
    docType.recordFamily !== form.recordFamily,
  ])

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/document-types">Tipos de documentos</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/document-types/${name}`}>{docType.displayName}</Breadcrumb>
            :
            <Breadcrumb url="/manage/document-types/create">Crear tipo de documento</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos generales"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <Input
          className="mb-6"
          label="Nombre"
          value={form.displayName}
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        ></Input>

        <Textarea
          className="mb-6"
          label="Descripción"
          rows="3"
          value={form.description}
          onChange={value => {
            setForm({
              ...form,
              description: value,
            })
          }}
        />

        <Selector
          label="Familia de expedientes"
          value={form.recordFamily}
          options={families}
          onChange={value => {
            setForm({
              ...form,
              recordFamily: value,
            })
          }}
        />
      </CardHeader>

      {name &&
        <>
          <CardTable
            title="Campos de clasificación"
            subtitle="Campos que se usan para clasificar un documento."
            empty="No hay campos asociados al tipo"
            header={
              <>
                <HeaderCell>Nombre</HeaderCell>
                <HeaderCell>Tipo</HeaderCell>
                <HeaderCell></HeaderCell>
              </>
            }
            actions={
              <ButtonLinkPrimary to={`/manage/document-types/${name}/fields/add`}>
                <Icon solid name="plus" className="mr-2" />
                Añadir campo
              </ButtonLinkPrimary>
            }
          >
            {form.fields?.map((field, idx) => (
              <TableRow key={idx}>
                <Cell className="w-1/2">
                  <Link to="#" onClick={() => editField(idx)} className="text-gray-900">{field.displayName}</Link>
                  {field.required && <BadgeGreen className="ml-2">Obligatorio</BadgeGreen>}
                </Cell>
                <Cell className="w-1/2">
                  {getFieldTypeText(field)}
                </Cell>
                <Cell>
                  <ButtonSecondary onClick={() => editField(idx)}>
                    <Icon solid name="pencil" />
                  </ButtonSecondary>
                </Cell>
              </TableRow>
            ))}
          </CardTable>
          <CardTable
            title="Subtipos"
            subtitle="Lista de subtipos."
            empty="No hay subtipos asociados al tipo"
            header={
              <>
                <HeaderCell>Nombre</HeaderCell>
              </>
            }
            actions={
              <ButtonLinkPrimary to={`/manage/document-types/${name}/subtypes/add`}>
                <Icon solid name="plus" className="mr-2" />
                Añadir subtipo
              </ButtonLinkPrimary>
            }
          >
            {form.subtypes?.map((subtype, idx) => (
              <TableRow key={idx}>
                <Cell>
                  {subtype}
                </Cell>
              </TableRow>
            ))}
          </CardTable>
        </>
      }
    </PageLayout>
  )
}
