
import React from 'react'
import cx from 'classnames'


export default function Input({ label, name, help, value, onChange, className, disabled, type = 'text' }) {
  function parseValue(val) {
    if (type === 'text') {
      return val ? val.trim() : ''
    } else if (type === 'number') {
      return val ? parseInt(val, 10) : undefined
    }

    return val
  }

  return (
    <>
      <div className={className}>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <div className="mt-1">
          <input
            type={type}
            name={name}
            id={name}
            className={cx(
              'form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md',
              {
                'bg-gray-100 cursor-not-allowed text-gray-400': disabled,
              },
            )}
            defaultValue={parseValue(value)}
            onChange={e => {
              if (onChange) {
                onChange(parseValue(e.target.value))
              }
            }}
            disabled={disabled}
          />
        </div>
        {help && <p className="mt-2 text-sm text-gray-500">{help}</p>}
      </div>
    </>
  )
}
