
import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import firstBy from 'thenby'
import diacritic from 'diacritic'

import { docs, webinterna } from 'services/discovery'
import FilesClient from 'services/Docs/files/files'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import Icon from 'components/Icon'
import PageLayout from 'layout/docs/PageLayout'
import FileUpload from 'components/FileUpload'
import AlertInfo from 'components/alerts/AlertInfo'
import AlertSuccess from 'components/alerts/AlertSuccess'
import AlertDanger from 'components/alerts/AlertDanger'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import Card from 'components/Card'
import RadioGroupCards from 'components/RadioGroupCards'
import { bytesSize } from 'platform/filters'
import Textarea from 'components/simple-forms/Textarea'
import Checkbox from 'components/simple-forms/Checkbox'
import { serializeTimestamp } from 'platform/datetime'
import Input from 'components/simple-forms/Input'
import { accountInfo } from 'auth/active-directory'
import ButtonSecondarySm from 'components/buttons/ButtonSecondarySm'
import ButtonSecondaryDangerSm from 'components/buttons/ButtonSecondaryDangerSm'
import EditableSelection from 'components/EditableSelection'
import SideSheetHeader from 'components/SideSheetHeader'
import EmptyText from 'components/EmptyText'
import { InfoPane } from 'views/docs/upload/internal/InfoPane'
import { listEntities, putEntity } from 'models/docs/entities'
import PdfPreview from 'components/PdfPreview'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { useMounted } from 'hooks/mounted'
import { useNavigate } from 'hooks/navigate'
import { keyBy } from 'lodash-es'


const UPLOAD_PREPARING = 'preparing'
const UPLOAD_PENDING = 'pending'
const UPLOAD_SENDING = 'sending'
const UPLOAD_FAILED = 'failed'
const UPLOAD_SUCCESS = 'success'


let initialPendingUpload
const filesClient = new FilesClient(docs())
const sociedadesClient = new SociedadesClient(webinterna())


export function setPendingUpload(file) {
  initialPendingUpload = file
}


function UploadStatusCard({ status, failureReason }) {
  return (
    <div className="h-44 mb-8">
      {status === UPLOAD_SENDING &&
        <Card title="Progreso del fichero">
          <div className="flex items-center space-x-4 text-indigo-600">
            <Icon duotone name="spinner-third" spin className="text-4xl" />
            <span className="text-lg font-medium">Subiendo fichero...</span>
          </div>
        </Card>
      }
      {status === UPLOAD_PENDING &&
        <Card title="Progreso del fichero">
          <AlertInfo>Selecciona un fichero para comenzar con la subida del documento</AlertInfo>
        </Card>
      }
      {status === UPLOAD_SUCCESS &&
        <Card title="Documento subido correctamente">
          <AlertSuccess>¡Todavía no hemos terminado! Ya está el fichero pero no te olvides de rellenar la información de abajo para que el sistema termine de recibir tu documento.</AlertSuccess>
        </Card>
      }
      {status === UPLOAD_FAILED &&
        <Card title="Error durante la subida del fichero">
          <AlertDanger>
            <p>Ha ocurrido un problema durante la subida del fichero.</p>
            <p className="mt-2">
              Los detalles técnicos del error son los siguientes:<br />
              <code className="text-sm">{failureReason}</code>
            </p>
          </AlertDanger>
        </Card>
      }
    </div>
  )
}


function DocInfoForm({ status, fileName, onCreate }) {
  let [suggestedTitle, setSuggestedTitle] = useState('')
  let [format, setFormat] = useState('FORMAT_DIGITAL')
  let [signatureFormat, setSignatureFormat] = useState('SIGNATURE_FORMAT_UNKNOWN')
  let [actionAfterSignature, setActionAfterSignature] = useState('ACTION_AFTER_SIGNATURE_ARCHIVE')
  let [afterSignature, setAfterSignature] = useState('')
  let [signaturePriority, setSignaturePriority] = useState('SIGNATURE_PRIORITY_DEFAULT')
  let [requiresCounterpartSignature, setRequiresCounterpartSignature] = useState(false)
  let [presentation, setPresentation] = useState(false)

  async function save() {
    let doc = {
      createTime: serializeTimestamp(new Date()),
      uploader: accountInfo().name,
      file: fileName,
      suggestedTitle,
      format,
      requiresSignature: signatureFormat !== 'SIGNATURE_FORMAT_UNKNOWN',
      signatureFormat,
    }
    if (doc.requiresSignature) {
      doc.signaturePriority = signaturePriority
      doc.requiresCounterpartSignature = requiresCounterpartSignature
      doc.presentation = { enabled: presentation }
    }
    if (doc.requiresSignature && doc.format === 'FORMAT_PHYSICAL') {
      doc.actionAfterSignature = actionAfterSignature
      doc.afterSignature = afterSignature
    }

    onCreate(doc)
  }

  return (
    <Card
      title="Datos sobre el documento"
      subtitle="Información que es obligatorio rellenar para poder aceptar el fichero en el sistema."
      footer={
        <ButtonAsyncPrimary disabled={status !== UPLOAD_SUCCESS || !suggestedTitle} onClick={save}>
          <Icon regular name="save" className="mr-2" />
          Guardar documento
        </ButtonAsyncPrimary>
      }
    >
      <Input label="Descriptor" className="mb-6" onChange={setSuggestedTitle} />

      <div className="space-y-8 mb-6">
        <RadioGroupCards label="Formato del documento" value={format} onChange={setFormat} horizontal>
          <RadioGroupCards.Radio
            value="FORMAT_DIGITAL"
            description="Directamente en formato PDF"
            icon={<Icon regular name="file-pdf" className="text-3xl text-gray-300 mr-4" />}
          >
            Documento Digital
          </RadioGroupCards.Radio>
          <RadioGroupCards.Radio
            value="FORMAT_PHYSICAL"
            description="Escaneado en formato PDF"
            icon={<Icon regular name="file-search" className="text-3xl text-gray-300 mr-3" />}
          >
            Documento Físico
          </RadioGroupCards.Radio>
        </RadioGroupCards>

        <RadioGroupCards label="Tipo de firma" value={signatureFormat} onChange={setSignatureFormat} horizontal>
          <RadioGroupCards.Radio
            value="SIGNATURE_FORMAT_UNKNOWN"
            description="No requiere firma ni presentación"
            icon={
              <Icon.Stack className="text-xl -ml-2 mr-2">
                <Icon fixedWidth regular name="file-signature" className="text-gray-400 text-2xl pl-1" />
                <Icon fixedWidth light name="ban" className="text-red-300" />
              </Icon.Stack>
            }
          >
            Sin firma
          </RadioGroupCards.Radio>
          <RadioGroupCards.Radio
            value="SIGNATURE_FORMAT_DIGITAL"
            description="Utilizando el programa externo de PortaSigma"
            icon={<Icon regular fixedWidth name="cloud-upload" className="text-3xl text-gray-300 mr-3" />}
          >
            Firma digital
          </RadioGroupCards.Radio>
          <RadioGroupCards.Radio
            value="SIGNATURE_FORMAT_PHYSICAL_INTERNAL"
            description="Se imprimirá con códigos internos para firma física"
            icon={<Icon regular fixedWidth name="file-signature" className="text-3xl text-gray-300 mr-3" />}
          >
            Firma física interna
          </RadioGroupCards.Radio>
          <RadioGroupCards.Radio
            value="SIGNATURE_FORMAT_PHYSICAL_EXTERNAL"
            description="Se imprime un volante que firman entidades externas"
            icon={<Icon regular fixedWidth name="print" className="text-3xl text-gray-300 mr-3" />}
          >
            Firma física externa
          </RadioGroupCards.Radio>
        </RadioGroupCards>

        {format === 'FORMAT_PHYSICAL' && signatureFormat !== 'SIGNATURE_FORMAT_UNKNOWN' &&
        <>
          <RadioGroupCards label="Destino del documento físico firmado" value={actionAfterSignature} onChange={setActionAfterSignature} horizontal>
            <RadioGroupCards.Radio
              value="ACTION_AFTER_SIGNATURE_ARCHIVE"
              description="El documento firmado será clasificado y archivado al terminar"
            >
              Archivo
            </RadioGroupCards.Radio>
            <RadioGroupCards.Radio
              value="ACTION_AFTER_SIGNATURE_RETURN_TO_UPLOADER"
              description="El documento firmado vuelve a ti para finalizar su gestión"
            >
              Gestión externa
            </RadioGroupCards.Radio>
          </RadioGroupCards>

          {actionAfterSignature === 'ACTION_AFTER_SIGNATURE_RETURN_TO_UPLOADER' &&
            <Textarea
              label="Comentarios sobre la solicitud"
              name="after_signature"
              help="Acciones a realizar una vez esté el documento firmado a modo de referencia para el futuro"
              rows="5"
              value={afterSignature}
              onChange={setAfterSignature}
            />
          }
        </>
        }

        {signatureFormat !== 'SIGNATURE_FORMAT_UNKNOWN' &&
          <>
            <div>
              <Checkbox
                label="Firma de alta prioridad"
                value={signaturePriority === 'SIGNATURE_PRIORITY_HIGH'}
                onChange={value => setSignaturePriority(value ? 'SIGNATURE_PRIORITY_HIGH' : 'SIGNATURE_PRIORITY_DEFAULT')}
                className="mb-2"
              />
              <Checkbox
                label="Recabar firma de contraparte"
                help="La contraparte son los firmantes externos a la organización"
                value={requiresCounterpartSignature}
                onChange={setRequiresCounterpartSignature}
              />
            </div>

            <RadioGroupCards label="Presentación telemática" value={presentation} onChange={setPresentation} horizontal>
              <RadioGroupCards.Radio
                value={false}
                description="El documento no requiere presentación"
                icon={<Icon regular name="ban" className="text-3xl text-gray-300 mr-4" />}
              >
                No se presenta
              </RadioGroupCards.Radio>
              <RadioGroupCards.Radio
                value={true}
                description="El documento firmado requiere presentación telemática"
                icon={<Icon solid name="id-card" className="text-3xl text-gray-300 mr-4" />}
              >
                Precisa presentación
              </RadioGroupCards.Radio>
            </RadioGroupCards>
          </>
        }
      </div>
    </Card>
  )
}


function Sidebar({ status, uploadInfo, uploadFile, doc, file }) {
  return (
    <div className="sticky top-6">
      {[UPLOAD_PENDING, UPLOAD_FAILED].includes(status) &&
        <div className="mb-10">
          <h3 className="mb-4 text-xl font-semibold font-display text-gray-900">Subir otro fichero</h3>
          <FileUpload onSelect={uploadFile} />
        </div>
      }
      {!doc && uploadInfo &&
        <Card title="Información sobre el fichero">
          <div className="grid grid-cols-2 gap-6">
            <InfoPane title="Nombre del fichero">{uploadInfo.filename}</InfoPane>
            <InfoPane title="Tamaño">{bytesSize(uploadInfo.size)}</InfoPane>

            <InfoPane title="Subida del fichero">
              {status === UPLOAD_SUCCESS &&
                <span className="text-green-600 font-medium">
                  <Icon solid name="check-circle" className="mr-2" />
                  Recibido correctamente
                </span>
              }
              {status === UPLOAD_FAILED &&
                <span className="text-red-600 font-medium">
                  <Icon solid name="times-circle" className="mr-2" />
                  Error en la subida
                </span>
              }
              {status === UPLOAD_SENDING &&
                <span className="text-blue-600 font-medium">
                  <Icon name="spinner-third" duotone spin className="mr-2" />
                  En proceso
                </span>
              }
            </InfoPane>
            <InfoPane title="Datos sobre el documento">
              <Icon solid name="info-circle" className="mr-2 text-gray-400" />
              <span className="text-gray-600">Pendientes de rellenar</span>
            </InfoPane>
          </div>
        </Card>
      }
      {doc &&
        <Card title="Información sobre el documento">
          <InfoPane title="Título">{doc.suggestedTitle}</InfoPane>

          <InfoPane title="Estado del documento" className="mt-6">
            <span className="text-green-600 font-medium">
              <Icon solid name="check-circle" className="mr-2" />
              Creado correctamente
            </span>
          </InfoPane>
        </Card>
      }

      {file &&
        <Card title="Previsualización" className="mt-8" >
          <div className="w-96 m-auto">
            <PdfPreview url={file} />
          </div>
        </Card>  
      }
    </div>
  )
}


function useSelector() {
  const PAGE_SIZE = 20

  let [allItems, setAllItems] = useState([])
  let [filteredItems, setFilteredItems] = useState([])
  let [currentPageItems, setCurrentPageItems] = useState([])
  let [page, setPage] = useState(0)
  let [range, setRange] = useState({
    start: 0,
    end: 0,
    total: 0,
  })
  let [filter, setFilter] = useState('')

  function goTo(newPage) {
    setPage(newPage)
    let start = newPage * PAGE_SIZE
    let end = Math.min((newPage + 1) * PAGE_SIZE, filteredItems.length)
    setCurrentPageItems(filteredItems.slice(start, end))

    let totalPages = parseInt(filteredItems.length / PAGE_SIZE)
    if (filteredItems.length % PAGE_SIZE !== 0) {
      totalPages++
    }
    setRange({
      start,
      end,
      total: filteredItems.length,
      totalPages,
    })
  }

  useEffect(() => {
    let search = diacritic.clean(filter).toUpperCase()

    let items = allItems.filter(item => {
      let $search = diacritic.clean(`${item.displayName} ${item.description}`).toUpperCase()
      return $search.includes(search)
    })
    setFilteredItems(items)
  }, [allItems, filter])

  useEffect(() => goTo(0), [filteredItems]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    selector: {
      currentPageItems,
      page,
      setPage,
      range,
      filter,
      setFilter,
      filteredItems,

      nextPage() {
        if (page < range.totalPages - 1) {
          goTo(page + 1)
        }
      },

      prevPage() {
        if (page > 0) {
          goTo(page - 1)
        }
      },
    },

    setItems(items) {
      setAllItems(items)
    },
  }
}


function SelectorPagination({ show, selector }) {
  return (
    <div className={cx(
      'w-full bg-white px-4 flex items-center justify-between',
      {
        'hidden': !show,
        'block': show,
      },
    )}>
      <div className="flex-1 flex items-center justify-between">
        <div>
          <p className="text-sm leading-5 text-gray-700">
            <span className="font-medium pr-1">{Math.min(selector.range.start + 1, selector.range.total)}</span>
            -
            <span className="font-medium px-1">{selector.range.end}</span>
            de
            <span className="font-medium px-1">{selector.range.total}</span>
            resultados
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex shadow-sm">
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              title="Página anterior"
              onClick={selector.prevPage}
            >
              <Icon regular name="chevron-left" className="mx-2" />
            </button>
            <button
              type="button"
              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              title="Siguiente página"
              onClick={selector.nextPage}
            >
              <Icon regular name="chevron-right" className="mx-2" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}


function Selector({ show, selector, isSelected, select }) {
  return (
    <div className={cx({
      'hidden': !show,
      'block': show,
    })}>
      <div className="flex rounded-md w-full">
        <div className="relative flex-grow focus-within:z-10 border-b-3 border-gray-300 pl-6">
          <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
            <Icon solid name="search" className="text-gray-400" />
          </div>
          <input
            type="text"
            autoComplete="off"
            className="form-input block w-full focus:ring-0 py-2 pl-8 border-0"
            placeholder="Filtrar..."
            value={selector.filter}
            onChange={event => selector.setFilter(event.target.value.trim())}
          />
        </div>
      </div>
      <div className="relative flex-1 overflow-y-auto">
        <ul>
          {!selector.filteredItems.length && <EmptyText>No hay elementos que cumplan con el filtro</EmptyText>}
          {selector.currentPageItems.map(item => (
            <li key={item.name} className={cx(
              'px-6 py-2 relative border-t border-b -mt-px',
              {
                'z-10 bg-indigo-100 border-indigo-700': isSelected(item),
                'border-gray-300': !isSelected(item),
              },
            )}>
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{item.displayName}</p>
                  <p className="text-sm text-gray-500 truncate">{item.description}</p>
                </div>
                <div>
                  <button
                    onClick={() => select(item)}
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Seleccionar
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


function Owners({ doc, value }) {
  if (!value) {
    value = []
  }

  let history = useHistory()
  let docTypeSelector = useSelector()
  let ownerSelector = useSelector()
  let signerSelector = useSelector()
  
  let [owner, setOwner] = useState(null)
  let [signer, setSigner] = useState(null)
  let [docType, setDocType] = useState(null)

  let [currentTab, setCurrentTab] = useState('docType')
  let [open, setOpen] = useState(false)
  let [edit, setEdit] = useState(null)
  let [selected, setSelected] = useState(value)

  useMounted(async () => {
    let reply = await listEntities('owners')
    let replyList = await fetchPersonasSociedades(reply.map(o => o.owner))
    let sociedades = await sociedadesClient.List()
    sociedades = keyBy(sociedades, 'name')

    let ownerList = reply.map(owner => {
      let o = replyList.resolve(owner.owner)
      return {
        name: owner.name,
        owner: {
          name: o.name,
          displayName: o.nombre,
        },
        displayName: o.nombre,
        description: o.email,
        isPeople: o.isPeople,
        isCorp: o.isCorp,
      }
    })
    if (doc.requiresSignature) {
      ownerList = ownerList.filter(o => o.isPeople || sociedades[o.owner.name].apoderados?.length || sociedades[o.owner.name].administradores?.length)
    }
    ownerList.sort(firstBy('displayName'))
    ownerSelector.setItems(ownerList)
    docTypeSelector.setItems(await listEntities('document-types'))
  })

  function newSelection() {
    setOwner(null)
    setDocType(null)
    setSigner(null)
    setEdit(null)
    setOpen(true)
    setCurrentTab('docType')
  }

  async function selectOwner(owner) {
    if (doc.requiresSignature && owner.isCorp) {
      let sociedad = await sociedadesClient.Get({ name: owner.owner.name })
      sociedad.apoderados = sociedad.apoderados || []
      sociedad.administradores = sociedad.administrador || []
      
      let peopleNames = []
      sociedad.apoderados
        .filter(apoderado => apoderado.apoderado)
        .forEach(apoderado => {
          peopleNames.push(apoderado.apoderado)
        })
      sociedad.administradores
        .filter(administrador => administrador.apoderado)
        .forEach(administrador => {
          peopleNames.push(administrador.apoderado)
        })

      if (!peopleNames.length) {
        saveOwner(docType, owner)
        return
      }

      let people = await fetchPersonasSociedades(peopleNames)
      let signers = people.map(signer => {
        return {
          name: signer.name,
          displayName: signer.nombre,
          description: signer.email,
        }
      })

      if (peopleNames.length === 1) {
        saveOwner(docType, owner, signers[0])
        return
      }
      
      signers.sort(firstBy('displayName'))
      signerSelector.setItems(signers)
      setCurrentTab('signer')
      
      owner.hasSigners = true
      setOwner(owner)
      return
    }
    
    let s = (doc.requiresSignature && owner.isPeople) ? owner.owner : null
    setOwner(owner)
    saveOwner(docType, owner, s)
  }
  
  function selectDocType(docType) {
    setDocType(docType)
    setCurrentTab('owner')
  }
  
  function selectSigner(signer) {
    saveOwner(docType, owner, signer)
  }

  function saveOwner(docType, owner, signer) {
    if (edit) {
      edit.owner = owner
      edit.docType = docType
      edit.signer = signer
    } else {
      setSelected([...selected, { owner, docType, signer }])
    }
    setOpen(false)
  }

  function editSelection(sel) {
    setOwner(sel.owner)
    setDocType(sel.docType)
    setSigner(sel.signer)
    setEdit(sel)
    setCurrentTab('docType')
    setOpen(true)
  }

  function removeSelection(sel) {
    setSelected(value.filter(item => item !== sel))
  }

  async function save() {
    doc.owners = selected.map(item => {
      return {
        name: item.owner.name,
        documentType: item.docType.name,
        signer: item.signer?.name,
      }
    })
    await putEntity('documents', { document: doc })
    history.push(`/docs/home`)
  }

  function isSelectedDocType(item) {
    return docType?.name === item.name
  }

  function isSelectedOwner(item) {
    return owner?.name === item.name
  }

  function isSelectedSigner(item) {
    return signer === item
  }

  return (
    <>
      <Card
        title="Propietarios"
        className="mb-8"
        actions={
          <ButtonPrimary onClick={newSelection}>
            <Icon solid name="plus" className="mr-2" />
            Añadir propietario
          </ButtonPrimary>
        }
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!selected.length}>
            <Icon regular name="save" className="mr-2" />
            Guardar propietarios
          </ButtonAsyncPrimary>
        }
      >
        {!selected.length && <EmptyText>No hay propietarios seleccionados para este documento</EmptyText>}
        {selected.map(item => (
          <EditableSelection
            key={item.owner.name + item.docType.name}
            icon={<Icon regular name="user-crown" />}
            title={item.owner.displayName}
            subtitle={
              <>
                <div>Tipo de Documento: {item.docType.displayName}</div>
                <div>{item.signer && `Firmante: ${item.signer.displayName}`}</div>
              </>
            }
            action={
              <div className="space-x-3 flex">
                <ButtonSecondarySm onClick={() => editSelection(item)}>
                  <Icon solid name="pencil" className="mr-2" />
                  Editar
                </ButtonSecondarySm>
                <ButtonSecondaryDangerSm onClick={() => removeSelection(item)}>
                  <Icon solid name="trash" />
                </ButtonSecondaryDangerSm>
              </div>
            }
          />
        ))}
      </Card>

      <SideSheetHeader
        header="Propietarios del documento"
        subheader="Selecciona propietarios para el documento."
        wide
        fit
        open={open}
        onClose={() => setOpen(false)}
        footer={<>
          <SelectorPagination
            selector={docTypeSelector.selector}
            show={currentTab === 'docType'}
          ></SelectorPagination>
          <SelectorPagination
            selector={ownerSelector.selector}
            show={currentTab === 'owner'}
          ></SelectorPagination>
          <SelectorPagination
            selector={signerSelector.selector}
            show={currentTab === 'signer'}
          ></SelectorPagination>
        </>}
      >
        <div className="-mt-3 border-b border-gray-200">
          <div className="px-6">
            <nav className="-mb-px flex space-x-6">
              <div
                className={cx(
                  'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
                  {
                    'border-indigo-500 text-indigo-600': currentTab === 'docType',
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': currentTab !== 'docType',
                  },
                )}
                onClick={() => setCurrentTab('docType')}
              >
                Tipo de documento
              </div>
              <div
                className={cx(
                  'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
                  {
                    'border-indigo-500 text-indigo-600': currentTab === 'owner',
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': currentTab !== 'owner',
                    'hidden': !docType?.name,
                  },
                )}
                onClick={() => setCurrentTab('owner')}
              >
                Propietario
              </div>
              <div
                className={cx(
                  'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
                  {
                    'border-indigo-500 text-indigo-600': currentTab === 'signer',
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': currentTab !== 'signer',
                    'hidden': !owner?.name || owner?.isPeople || !owner?.hasSigners,
                  },
                )}
                onClick={() => setCurrentTab('signer')}
              >
                Firmante
              </div>
            </nav>
          </div>
        </div>

        <Selector
          show={currentTab === 'docType'}
          isSelected={isSelectedDocType}
          selector={docTypeSelector.selector}
          select={selectDocType}
        ></Selector>

        <Selector
          show={currentTab === 'owner'}
          isSelected={isSelectedOwner}
          selector={ownerSelector.selector}
          select={selectOwner}
        ></Selector>

        <Selector
          show={currentTab === 'signer'}
          isSelected={isSelectedSigner}
          selector={signerSelector.selector}
          select={selectSigner}
        ></Selector>
      </SideSheetHeader>
    </>
  )
}


export function UploadMain() {
  let [status, setStatus] = useState(initialPendingUpload ? UPLOAD_PREPARING : UPLOAD_PENDING)
  let [uploadInfo, setUploadInfo] = useState(null)
  let [failureReason, setFailureReason] = useState('')
  let [file, setFile] = useState(null)
  let [doc, setDoc] = useState(null)

  useNavigate(() => {
    let file = initialPendingUpload
    initialPendingUpload = undefined
    if (file) {
      uploadFile(file)
    }
  })

  async function uploadFile(file) {
    if (file.type !== 'application/pdf') {
      setStatus(UPLOAD_FAILED)
      setFailureReason('Seleccione por favor un fichero en formato PDF. Es el único formato que soporta el gestor documental.')
      return
    }

    setStatus(UPLOAD_SENDING)
    setUploadInfo({
      filename: file.name,
      size: file.size,
    })

    let upload = await filesClient.Create()

    try {
      let reply = await fetch(upload.uploadUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        body: file,
      })
      if (reply.status !== 201) {
        setStatus(UPLOAD_FAILED)
        setFailureReason(`Bad HTTP status code: ${reply.status}`)
        return
      }

      let finishReply = await filesClient.FinishUpload({
        name: upload.name,
        filename: file.name,
        size: file.size,
      })
      setFile(finishReply)
    } catch (err) {
      setStatus(UPLOAD_FAILED)
      setFailureReason(`${err}`)
      return
    }
    
    setStatus(UPLOAD_SUCCESS)
  }
  
  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url="/docs/upload">Subir Documento</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className={cx('flex', { 'space-x-10': uploadInfo, 'space-x-16': !uploadInfo })}>
        <div className="flex-grow">
          <UploadStatusCard status={status} failureReason={failureReason} />

          {![UPLOAD_PREPARING, UPLOAD_PENDING].includes(status) && !doc &&
            <>
              <DocInfoForm status={status} fileName={file?.name} onCreate={setDoc} />
            </>
          }
          {doc && <Owners doc={doc} />}
        </div>
        <div className="w-128 flex-shrink-0">
          <Sidebar status={status} uploadInfo={uploadInfo} doc={doc} file={file?.previewUrl} uploadFile={uploadFile} />
        </div>
      </div>
    </PageLayout>
  )
}
