
export const FIELD_TYPES = [
  { label: 'Texto', value: 'text' },
  { label: 'Numérico', value: 'numeric' },
  { label: 'Email', value: 'email' },
  { label: 'URL', value: 'url' },
  { label: 'Checkbox', value: 'checkbox' },
  { label: 'Seleccionable', value: 'select' },
  { label: 'Entidad de WebInterna', value: 'webinternaRef' },
  { label: 'Entidad de WebInterna con descripción', value: 'webinternaRefDescription' },
]


export const WEBINTERNA_REF_OPTIONS = [
  {
    label: 'Sociedad',
    value: 'REF_SOCIEDAD',
    fields: [
      { value: 'acronimo', label: 'Acrónimo' },
      { value: 'denominacion', label: 'Título sociedad' },
      { value: 'tipo', label: 'Tipo' },
      { value: 'email', label: 'Email' },
      { value: 'telefono', label: 'Teléfono' },
      { value: 'cif', label: 'CIF' },
      { value: 'grupo', label: 'Grupo' },
      { value: 'subgrupo', label: 'Subgrupo' },
      { value: 'capitalSocial', label: 'Capital social' },
      { value: 'direccion', label: 'Dirección' },
      { value: 'codigoPostal', label: 'CP' },
      { value: 'provincia', label: 'Provincia' },
    ],
  },
  {
    label: 'Persona',
    value: 'REF_PERSONA',
    fields: [
      { value: 'identidad', label: 'Identidad' },
      { value: 'nombre', label: 'Nombre' },
      { value: 'email', label: 'Email' },
      { value: 'telefono', label: 'Teléfono' },
      { value: 'calle', label: 'Calle' },
      { value: 'provincia', label: 'Provincia' },
      { value: 'codigoPostal', label: 'Código postal' },
      { value: 'pais', label: 'País' },
    ],
  },
  {
    label: 'Proceso',
    value: 'REF_PROCESO',
    fields: [
      { value: 'grupo', label: 'Grupo' },
      { value: 'codigo', label: 'Código' },
      { value: 'nombre', label: 'Nombre proceso' },
      { value: 'responsables', label: 'Responsables' },
      { value: 'gestores', label: 'Gestores' },
    ],
  },
  {
    label: 'Contribuidor',
    value: 'REF_CONTRIBUIDOR',
    fields: [
      { value: 'nombre', label: 'Nombre' },
      { value: 'identidad', label: 'Identidad' },
      { value: 'calle', label: 'Calle' },
      { value: 'provincia', label: 'Provincia' },
      { value: 'codigoPostal', label: 'Código postal' },
      { value: 'telefono', label: 'Teléfono' },
      { value: 'email', label: 'Email' },
      { value: 'centroTrabajo', label: 'Centro de trabajo' },
      { value: 'pais', label: 'País' },
    ],
  },
  {
    label: 'Proyecto',
    value: 'REF_PROYECTO',
    fields: [
      { value: 'nombre', label: 'Nombre' },
      { value: 'tipo', label: 'Tipo' },
      { value: 'estado', label: 'Estado' },
      { value: 'promotor', label: 'Promotor' },
      { value: 'ordenanzaGeneral', label: 'Ordenanza general' },
      { value: 'ambitoDesarrollo', label: 'Ámbito desarrollo' },
      { value: 'tipoContable', label: 'Tipo contable' },
      { value: 'cuentaContable', label: 'Cuenta contable' },
      { value: 'garantia1yDate', label: 'Periodo de garantía 1 año' },
      { value: 'garantia3yDate', label: 'Periodo de garantía 3 años' },
      { value: 'garantia10yDate', label: 'Periodo de garantía 10 años' },
      { value: 'cfoDate', label: 'Fecha CFO' },
      { value: 'uso', label: 'Uso' },
      { value: 'ingresosTotales', label: 'Ingresos totales' },
      { value: 'costesTotales', label: 'Costes totales' },
      { value: 'noi', label: 'NOI Total' },
      { value: 'rbe', label: 'R.B.E.' },
      { value: 'yield', label: 'Yield global' },
      { value: 'suelo', label: 'Suelo' },
      { value: 'edificabilidad', label: 'Edificabilidad' },
    ],
  },
  {
    label: 'Unidad Inmobiliaria',
    value: 'REF_INMUEBLE',
    fields: [
      { value: 'codigo', label: 'Código' },
      { value: 'denominacion', label: 'Denominación' },
      { value: 'titular', label: 'Titular' },
      { value: 'clase', label: 'Clase' },
      { value: 'usoUrbanistico', label: 'Uso Urbanístico' },
      { value: 'estado', label: 'Estado' },
      { value: 'provincia', label: 'Provincia' },
    ],
  },
  {
    label: 'Finca Registral',
    value: 'REF_FINCA_REGISTRAL',
    fields: [
      { value: 'codigo', label: 'Código' },
      { value: 'numeroFinca', label: 'Nº Finca' },
      { value: 'idufir', label: 'IDUFIR' },
      { value: 'superficieRegistralSuelo', label: 'S. Suelo' },
      { value: 'superficieRegistralEdificacion', label: 'S. Edific.' },
    ],
  },
  {
    label: 'Finca Catastral',
    value: 'REF_FINCA_CATASTRAL',
    fields: [
      { value: 'codigoPostal', label: 'Código Postal' },
      { value: 'denominacion', label: 'Denominación' },
      { value: 'referenciaCatastral', label: 'Referencia Catastral' },
      { value: 'superficieTotal', label: 'Superficie total' },
      { value: 'superficieParcial', label: 'Superficie parcial' },
      { value: 'tipoFinca', label: 'Tipo de finca' },
      { value: 'propiedadPercentage', label: '% prop.' },
      { value: 'valorCatastral', label: 'Valor catastral' },
    ],
  },
  {
    label: 'Elemento Patrimonial',
    value: 'REF_ELEMENTO_PATRIMONIAL',
    fields: [
      { value: 'codigo', label: 'Código' },
      { value: 'denominacion', label: 'Denominación' },
      { value: 'codigoPostal', label: 'Código postal' },
      { value: 'tipo', label: 'Tipo' },
      { value: 'destino', label: 'Destino' },
    ],
  },
  {
    label: 'Contabilidad analítica',
    value: 'REF_PLAN_CUENTAS',
    fields: [
      { value: 'planCuentas', label: 'Plan de cuentas' },
      { value: 'agrupacion', label: 'Agrupación' },
      { value: 'denominacion', label: 'Denominación' },
      { value: 'descripcion', label: 'Descripción de uso' },
      { value: 'cuentaContable', label: 'Cuenta contable' },
    ],
  },
]


export function getFieldType(obj) {
  let excludes = ['name', 'displayName', 'required']
  for (let key in obj) {
    if (excludes.includes(key)) {
      continue
    }

    return key
  }
}


export function getFieldTypeText(obj) {
  return FIELD_TYPES.find(tp => tp.value === getFieldType(obj))?.label
}


export function getFieldValidations(field) {
  let validations = []
  if (field.required) {
    validations.push('required')
  }
  if (field.text) {
    if (field.text.minLength > 0) {
      validations.push(`min:${field.text.minLength}`)
    }
    if (field.text.maxLength > 0) {
      validations.push(`max:${field.text.maxLength}`)
    }
  }
  if (field.numeric) {
    if (field.numeric.min > 0) {
      validations.push(`minNumber:${field.numeric.min}`)
    }
    if (field.numeric.max > 0) {
      validations.push(`maxNumber:${field.numeric.max}`)
    }
  }
  if (field.email) {
    validations.push('email')
  }
  if (field.url) {
    validations.push('url')
  }
  return validations.join('|')
}
