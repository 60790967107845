
import React from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/Icon'


export default function EntityLink({ children, to }) {
  return (
    <Link
      to={to}
      className="text-indigo-500 font-normal"
    >
      <Icon solid name="link" className="mr-2" />
      {children}
    </Link>
  )
}
