
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import HtmlViewer from 'components/HtmlViewer'
import Card from 'components/Card'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeGreen from 'components/badges/BadgeGreen'
import { parseDate } from 'platform/datetime'
import { datetime, decimal, percentage, price } from 'platform/filters'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import PeopleViewer from 'components/people/PeopleViewer'
import ExternalLink from 'components/ExternalLink'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'


const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())


export default function FincasCatastralesShow() {
  let { name } = useParams()
  let [finca, setFinca] = useState({})

  useNavigate(async () => {
    let reply = await fincasCatastralesClient.Get({ name })

    reply.altaDate = parseDate(reply.altaDate)
    reply.altaJuridicaDate = parseDate(reply.altaJuridicaDate)
    reply.bajaJuridicaDate = parseDate(reply.bajaJuridicaDate)
    reply.bajaDate = parseDate(reply.bajaDate)
    reply.valoracionDate = parseDate(reply.valoracionDate)

    if (reply.fincaRegistral) {
      reply.fincaRegistral = await fincasRegistralesClient.Get({ name: reply.fincaRegistral })
    }

    if (reply.fincaRegistral?.inmueble) {
      reply.fincaRegistral.inmueble = await inmueblesClient.Get({ name: reply.fincaRegistral.inmueble })
    }

    if (reply.fincaRegistral?.registroPropiedad) {
      reply.fincaRegistral.registroPropiedad = await registrosPropiedadClient.Get({ name: reply.fincaRegistral.registroPropiedad })
    }

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales.filter(elementoPatrimonial => elementoPatrimonial.fincaRegistral === reply.fincaRegistral.name)
    reply.elementoPatrimonial = elementosPatrimoniales[0]

    let names = []
    names.push(reply.fincaRegistral.inmueble?.titular)
    names.push(reply.fincaRegistral.inmueble?.comunidad)
    names.push(reply.fincaRegistral.inmueble?.juntaCompensacion)

    if (reply.fincaRegistral.inmueble.municipio) {
      reply.fincaRegistral.inmueble.municipio = await municipiosClient.Get({ name: reply.fincaRegistral.inmueble.municipio })
    }

    if (reply.fincaRegistral.inmueble.provincia) {
      reply.fincaRegistral.inmueble.provincia = await provinciasClient.Get({ name: reply.fincaRegistral.inmueble.provincia })
    }

    if (reply.fincaRegistral.inmueble.grupo) {
      reply.fincaRegistral.inmueble.grupo = await gruposInmobiliariosClient.Get({ name: reply.fincaRegistral.inmueble.grupo })
    }

    if (reply.fincaRegistral.inmueble.origen) {
      reply.fincaRegistral.inmueble.origen = await inmueblesClient.Get({ name: reply.fincaRegistral.inmueble.origen })
    }

    setFinca(reply)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!finca.blocked &&
            <Acl perm="webinterna.fincas_catastrales.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/fincas-catastrales/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Finca Catastral
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-catastrales">Fincas Catastrales</Breadcrumb>
        <Breadcrumb url={`/webinterna/fincas-catastrales/${name}`}>Consultar Finca Catastral</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={finca.blocked}></EntityBlockedAlert>

      <div className="flex mb-6">
        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Denominación GIU">{finca.fincaRegistral?.inmueble?.grupo?.nombre}</ShowPane>
              <ShowPane title="Código UINM">{finca.fincaRegistral?.inmueble?.codigo}</ShowPane>
              <ShowPane title="Denominación UINM">{finca.fincaRegistral?.inmueble?.nombre}</ShowPane>
              <ShowPane title="Denominación Elemento Patrimonial">{finca.elementoPatrimonial?.nombre}</ShowPane>
              <ShowPane title="Nº Finca Registral">{finca.fincaRegistral?.numeroFinca}</ShowPane>
              <ShowPane title="Código Finca Catastral">{finca.codigo}</ShowPane>
              <ShowPane title="Titular">
                {finca.fincaRegistral?.inmueble?.titular && <PeopleViewer value={finca.fincaRegistral.inmueble.titular} />}
              </ShowPane>
              <ShowPane title="Estado">
                {finca.fincaRegistral?.estado === 'ESTADO_BAJA' && <BadgeGray>Baja</BadgeGray>}
                {finca.fincaRegistral?.estado === 'ESTADO_PROPIEDAD' && <BadgeGreen>Propiedad</BadgeGreen>}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Referencia Catastral">{finca.referenciaCatastral}</ShowPane>
              <ShowPane title="Superficie Catastral Total">{decimal(finca.superficieTotal)} m²</ShowPane>
              <ShowPane title="% Propiedad">{percentage(finca.propiedadPercentage)}</ShowPane>
              <ShowPane title="Superficie Catastral Parcial">{decimal(finca.superficieParcial)} m²</ShowPane>
              <ShowPane title="Tipo de Finca" className="col-span-2">
                {finca.tipoFinca === 'TIPO_FINCA_URBANA' && 'Finca urbana'}
                {finca.tipoFinca === 'TIPO_FINCA_RUSTICA' && 'Finca rústica'}
                {finca.tipoFinca === 'TIPO_FINCA_ESPECIALES' && 'Finca especial'}
              </ShowPane>
              <ShowPane title="Planimetría">
                <ExternalLink to={finca.planimetria}>{finca.planimetria}</ExternalLink>
              </ShowPane>
            </div>
          </Card>
        </div>

        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Fecha Alta Jurídica">{datetime(finca.altaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja Jurídica">{datetime(finca.bajaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Alta">{datetime(finca.altaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja">{datetime(finca.bajaDate, 'DATE')}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Registro de la Propiedad">{finca.fincaRegistral?.registroPropiedad?.nombre}</ShowPane>
              <ShowPane title="Municipio">{finca.fincaRegistral?.inmueble?.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">
                {finca.fincaRegistral?.inmueble?.provincia?.nombre}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Valor Catastral">{price(finca.valorCatastral)}</ShowPane>
              <ShowPane title="Fecha Valoración">{datetime(finca.valoracionDate, 'DATE')}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Observaciones">
                <HtmlViewer html={finca.observaciones}/>
              </ShowPane>
            </div>
          </Card>
        </div>
      </div>

      <Card className="mb-6">
        <ShowPane title="Cuadro de superficies">
          <ExternalLink to={finca.cuadroSuperficies}>
            {finca.cuadroSuperficies}
          </ExternalLink>
        </ShowPane>
      </Card>

      <Card className="mb-4">
        <div className="flex items-center w-full gap-4">
          <ButtonLinkSecondary to={`/webinterna/inmuebles?finca-catastral=${finca.name}`}>Ver inmuebles asociados</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/fincas-registrales?finca-catastral=${finca.name}`}>Ver fincas registrales asociadas</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/elementos-patrimoniales?finca-catastral=${finca.name}`}>Ver elementos patrimoniales asociados</ButtonLinkSecondary>
        </div>
      </Card>

    </PageLayout>
  )
}
