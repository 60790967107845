
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import { parseDate, serializeDate } from 'platform/datetime'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import HtmlEditor from 'components/forms/HtmlEditor'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Datepicker from 'components/forms/Datepicker'
import SingleEntity from 'components/forms/SingleEntity'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import People from 'components/forms/People'
import Percent from 'components/forms/Percent'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import Price from 'components/forms/Price'
import Checkbox from 'components/forms/Checkbox'
import Area from 'components/forms/Area'
import InputUrl from 'components/forms/InputUrl'
import CardHeader from 'components/CardHeader'
import { WideList, WideListEmpty, WideListInteractiveItem } from 'components/WideList'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import { nanoid } from 'nanoid'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'
import InputAddon from 'components/forms/InputAddon'
import { useNavigate } from 'hooks/navigate'


const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())


function useFincaRegistral({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [fincaRegistral, setFincaRegistral] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (!isEdit) {
      return
    }

    let reply = await fincasRegistralesClient.Get({ name })
    reply.altaDate = parseDate(reply.altaDate)
    reply.bajaDate = parseDate(reply.bajaDate)
    reply.altaJuridicaDate = parseDate(reply.altaJuridicaDate)
    reply.bajaJuridicaDate = parseDate(reply.bajaJuridicaDate)
    reply.altaContableDate = parseDate(reply.altaContableDate)
    reply.bajaContableDate = parseDate(reply.bajaContableDate)
    reply.inicioContableDate = parseDate(reply.inicioContableDate)
    reply.finContableDate = parseDate(reply.finContableDate)
    setFincaRegistral(reply)
  }

  async function loadValidation(fincas) {
    let reply = await validacionesClient.Get({ name: name })
    reply.entidad.fincaRegistral.altaDate = parseDate(reply.entidad.fincaRegistral.altaDate)
    reply.entidad.fincaRegistral.bajaDate = parseDate(reply.entidad.fincaRegistral.bajaDate)
    reply.entidad.fincaRegistral.altaJuridicaDate = parseDate(reply.entidad.fincaRegistral.altaJuridicaDate)
    reply.entidad.fincaRegistral.bajaJuridicaDate = parseDate(reply.entidad.fincaRegistral.bajaJuridicaDate)
    reply.entidad.fincaRegistral.altaContableDate = parseDate(reply.entidad.fincaRegistral.altaContableDate)
    reply.entidad.fincaRegistral.bajaContableDate = parseDate(reply.entidad.fincaRegistral.bajaContableDate)
    reply.entidad.fincaRegistral.inicioContableDate = parseDate(reply.entidad.fincaRegistral.inicioContableDate)
    reply.entidad.fincaRegistral.finContableDate = parseDate(reply.entidad.fincaRegistral.finContableDate)

    if (reply.accion === 'ACCION_CREAR') {
      if (!reply.entidad.fincaRegistral.codigo) {
        let year = reply.entidad.fincaRegistral.altaJuridicaDate.getFullYear().toString().slice(2, 4)
        let codigos = fincas
          .filter(f => f.codigo.slice(2, 4) === year)
          .map(f => f.codigo.slice(4))
          .sort()
        let maxCodigo = codigos.length ? codigos[codigos.length - 1] : '0'
        let newCodigo = parseInt(maxCodigo) + 1
        newCodigo = newCodigo.toString().padStart(5, '0')
        reply.entidad.fincaRegistral.codigo = year + newCodigo
      } else {
        reply.entidad.fincaRegistral.codigo = reply.entidad.fincaRegistral.codigo.substring(2)
      }
    }

    setFincaRegistral(reply.entidad.fincaRegistral)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        fincaRegistral: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de esta finca registral para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/fincas-registrales/${name}`)
    } else {
      history.push(`/webinterna/fincas-registrales`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await fincasRegistralesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await fincasRegistralesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await fincasRegistralesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        fincaRegistral: {
          ...fincaRegistral,
          altaDate: serializeDate(fincaRegistral.altaDate),
          bajaDate: serializeDate(fincaRegistral.bajaDate),
          altaJuridicaDate: serializeDate(fincaRegistral.altaJuridicaDate),
          bajaJuridicaDate: serializeDate(fincaRegistral.bajaJuridicaDate),
          altaContableDate: serializeDate(fincaRegistral.altaContableDate),
          bajaContableDate: serializeDate(fincaRegistral.bajaContableDate),
          inicioContableDate: serializeDate(fincaRegistral.inicioContableDate),
          finContableDate: serializeDate(fincaRegistral.finContableDate),
        },
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de esta finca registral para que lo apruebe un administrador.')
    history.push(`/webinterna/fincas-registrales`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    fincaRegistral,
    validation,
    remove,
  }
}

function useCargas({ fincaRegistral }) {
  let [cargas, setCargas] = useState([])

  function add() {
    setCargas([
      ...cargas,
      {
        ghostKey: nanoid(),
        contribuidor: '',
        rol: '',
      },
    ])
  }

  function remove(carga) {
    setCargas(cargas.filter(c => c !== carga))
  }

  useEffect(() => {
    if (fincaRegistral?.cargas?.length) {
      setCargas(fincaRegistral.cargas.map(carga => {
        return {
          ...carga,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setCargas, fincaRegistral])

  return {
    cargas,
    add,
    remove,
  }
}

export default function FincasRegistralesEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [inmuebles, setInmuebles] = useState(null)
  let [registros, setRegistros] = useState(null)

  let {
    save: saveFincaRegistral,
    load: loadFincaRegistral,
    loadValidation,
    confirmValidation,
    fincaRegistral,
    validation,
    remove: removeFincaRegistral,
  } = useFincaRegistral({ name })

  let {
    cargas,
    add: addCarga,
    remove: removeCarga,
  } = useCargas({ fincaRegistral })

  useNavigate(async () => {
    let { inmuebles } = await inmueblesClient.List()
    inmuebles = inmuebles || []
    setInmuebles(inmuebles.map(i => {
      return {
        value: i.name,
        label: i.nombre,
        url: `/webinterna/inmuebles/${i.name}`,
      }
    }))

    let { registros } = await registrosPropiedadClient.List()
    registros = registros || []
    setRegistros(registros.map(r => {
      return {
        value: r.name,
        label: r.nombre,
        url: `/manage/registros-propiedad/${r.name}`,
      }
    }))

    let { fincas } = await fincasRegistralesClient.List()
    fincas = fincas || []

    if (isValidation) {
      await loadValidation(fincas)
    } else {
      await loadFincaRegistral()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...fincaRegistral,
      ...data,
      cargas: data.cargas?.map(carga => {
        carga.clase = carga.clase ? carga.clase : 'CLASE_UNKNOWN'
        return carga
      }),
      altaJuridicaDate: serializeDate(data.altaJuridicaDate),
      bajaJuridicaDate: serializeDate(data.bajaJuridicaDate),
      bajaDate: serializeDate(data.bajaDate),
      altaDate: serializeDate(data.altaDate),
      altaContableDate: serializeDate(data.altaContableDate),
      bajaContableDate: serializeDate(data.bajaContableDate),
      inicioContableDate: serializeDate(data.inicioContableDate),
      finContableDate: serializeDate(data.finContableDate),
    }

    if (data.codigo && (!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR'))) {
      req.codigo = 'FR' + data.codigo
    }

    req.estadoInscripcion = req.estadoInscripcion || 'ESTADO_INSCRIPCION_UNKNOWN'
    req.tipoDerecho = req.tipoDerecho || 'TIPO_DERECHO_UNKNOWN'

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveFincaRegistral(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !fincaRegistral.blocked &&
            <ButtonConfirmDanger onClick={removeFincaRegistral}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Finca Registral
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-registrales">Fincas Registrales</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/fincas-registrales/${name}`}>Consultar Fincas Registrales</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/fincas-registrales/edit/${name}`}>Editar Finca Registral</Breadcrumb>
          : <Breadcrumb url="/webinterna/fincas-registrales/edit/new">Crear Nueva Finca Registral</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={fincaRegistral.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:gap-x-4 mb-6">
              {(!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR')) &&
                  <InputAddon
                    form={form}
                    name="codigo"
                    type="number"
                    label="Código"
                    validations="minNumber:1"
                    value={fincaRegistral.codigo}
                    disabled={isValidation}
                    className="w-32"
                    addonText="FR"
                  />
              }
              <Input form={form} type="number" name="numeroFinca" label="Nº Finca" validations="required" value={fincaRegistral.numeroFinca} className="flex-grow" />
              <SingleEntity
                className="flex-grow"
                form={form}
                validations="required"
                name="inmueble"
                label="Unidad Inmobiliaria"
                header="Seleccionar unidad inmobiliaria"
                options={inmuebles}
                value={fincaRegistral.inmueble}
              />
            </div>
            <div className="lg:flex lg:space-x-4 mt-4 md:grid md:grid-cols-2 mb-6">
              <Datepicker form={form} name="altaJuridicaDate" label="Fecha Alta Jurídica" value={fincaRegistral.altaJuridicaDate} validations="required" />
              <Datepicker form={form} name="bajaJuridicaDate" label="Fecha Baja Jurídica" value={fincaRegistral.bajaJuridicaDate} />
              <Datepicker form={form} name="altaDate" label="Fecha Alta" value={fincaRegistral.altaDate} />
              <Datepicker form={form} name="bajaDate" label="Fecha Baja" value={fincaRegistral.bajaDate} />
            </div>

            <div className="flex mb-6 space-x-4">
              <SingleEntity
                className="flex-grow"
                form={form}
                validations="required"
                name="registroPropiedad"
                label="Registro de la propiedad"
                header="Seleccionar Registro de la Propiedad"
                options={registros}
                value={fincaRegistral.registroPropiedad}
              />
              <Input form={form} name="idufir" label="IDUFIR" validations="required|min:3" value={fincaRegistral.idufir} />
              <Select
                form={form}
                name="estado"
                label="Estado"
                options={[
                  { value: 'ESTADO_BAJA', label: 'Baja' },
                  { value: 'ESTADO_PROPIEDAD', label: 'Propiedad' },
                ]}
                validations="required"
                value={fincaRegistral.estado ? fincaRegistral.estado : ''}
              />
              <Select
                form={form}
                name="estadoInscripcion"
                label="Estado de la inscripción"
                options={[
                  { value: 'ESTADO_INSCRIPCION_NO_INSCRITA', label: 'No inscrita' },
                  { value: 'ESTADO_INSCRIPCION_INSCRITA', label: 'Inscrita' },
                ]}
                value={fincaRegistral.estadoInscripcion ? fincaRegistral.estadoInscripcion : ''}
              />
            </div>

            <div className="flex mb-6 gap-4">
              <Input form={form} type="number" name="protocolo" label="Protocolo" validations="required" value={fincaRegistral.protocolo} />
              <Price form={form} name="valorEp" label="Valor EP" value={fincaRegistral.valorEp} />
              <Select
                form={form}
                name="tipoDerecho"
                label="Tipo derecho"
                options={[
                  { value: 'TIPO_DERECHO_PLENO_DOMINIO', label: 'Pleno dominio' },
                  { value: 'TIPO_DERECHO_NUDA_PROPIEDAD', label: 'Nuda propiedad' },
                  { value: 'TIPO_DERECHO_USUFRUCTO', label: 'Usufructo' },
                ]}
                value={fincaRegistral.tipoDerecho ? fincaRegistral.tipoDerecho : ''}
              />
              <Percent form={form} name="propiedadPercentage" label="% Prop" value={fincaRegistral.propiedadPercentage}></Percent>
              <Percent form={form} name="usufructoPercentage" label="% usufructo" value={fincaRegistral.usufructoPercentage}></Percent>
            </div>

            <div className="lg:flex lg:space-x-4 mt-4 md:grid md:grid-cols-2 mb-6">
              <Datepicker form={form} name="altaContableDate" label="Fecha Alta Contable" value={fincaRegistral.altaContableDate} />
              <Datepicker form={form} name="bajaContableDate" label="Fecha Baja Contable" value={fincaRegistral.bajaContableDate} />
              <Datepicker form={form} name="inicioContableDate" label="Fecha Inicio Contable" value={fincaRegistral.inicioContableDate} />
              <Datepicker form={form} name="finContableDate" label="Fecha Fin Contable" value={fincaRegistral.finContableDate} />
            </div>

            <div className="flex mb-6 gap-4 items-end">
              <Input form={form} name="tipoContable" label="Tipo contable" validations="required" value={fincaRegistral.tipoContable} />
              <SingleEntity
                className="flex-grow"
                form={form}
                name="cuentaContable"
                label="Cuenta contable"
                header="Seleccionar cuenta contable"
                options={[]}
                value={fincaRegistral.cuentaContable}
              />
              <Checkbox form={form} name="activoFijo" label="Activo Fijo" className="align-end" value={fincaRegistral.activoFijo} />
            </div>

            <div className="flex mb-6 gap-4">
              <Area
                form={form}
                name="superficieRegistralSuelo"
                label="S. Suelo"
                validations="required"
                value={fincaRegistral.superficieRegistralSuelo}
                fit
              />
              <Area
                form={form}
                name="superficieRegistralEdificacion"
                label="S. Edific."
                validations="required"
                value={fincaRegistral.superficieRegistralEdificacion}
                fit
              />
              <InputUrl form={form} className="flex-grow" name="planimetria" label="Planimetría" value={fincaRegistral.planimetria} />
            </div>

            <HtmlEditor form={form} name="observaciones" label="Observaciones" value={fincaRegistral.observaciones} className="mb-6" />
          </fieldset>
        </Card>

        <Card className="mt-4">
          <InputUrl form={form} className="w-1/2" name="notasSimples" label="Notas Simples" value={fincaRegistral.notasSimples} />
        </Card>

        <CardHeader
          fit
          className="mt-4"
          header="Derechos/Cargas"
          subheader="Derechos y cargas asociadas a la finca registral."
        >
          <WideList divider="divide-y-4 divide-teal-400">
            {!cargas.length && <WideListEmpty>No hay derechos ni cargas en la finca registral</WideListEmpty>}
            {cargas.map((carga, index) => (
              <WideListInteractiveItem
                key={carga.ghostKey}
                actions={
                  <div className="self-start">
                    <ButtonHoverDanger onClick={() => removeCarga(carga)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                  </div>
                }
              >
                <fieldset className="mb-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Elemento {index + 1}</legend>
                </fieldset>

                <div className="flex gap-4 mb-6">
                  <Select
                    form={form}
                    name={`cargas[${index}].clase`}
                    label="Clase"
                    options={[
                      { value: 'CLASE_DERECHO', label: 'Derecho' },
                      { value: 'CLASE_CARGA', label: 'Carga' },
                    ]}
                    value={carga.clase ? carga.clase : ''}
                  />
                  <Select
                    form={form}
                    name={`cargas[${index}].tipo`}
                    label="Tipo"
                    options={[
                      { value: 'TIPO_HIPOTECA', label: 'Hipoteca' },
                      { value: 'TIPO_SERVIDUMBRE', label: 'Servidumbre' },
                      { value: 'TIPO_ANOTACION_PREVIA_EMBARGO', label: 'Anotación previa embargo' },
                      { value: 'TIPO_OPCION_COMPRA', label: 'Opción de compra' },
                      { value: 'TIPO_PROCEDIMIENTO_EXPROPIACION', label: 'Procedimiento de expropiación' },
                      { value: 'TIPO_ARRENDAMIENTO', label: 'Arrendamiento' },
                      { value: 'TIPO_AGUAS', label: 'Aguas' },
                      { value: 'TIPO_AFECCIONES_FISCALES', label: 'Afecciones fiscales' },
                      { value: 'TIPO_OTROS', label: 'Otros' },
                    ]}
                    value={carga.tipo ? carga.tipo : ''}
                  />
                  <div className="w-1/2">
                    <People
                      form={form}
                      name={`cargas[${index}].rango`}
                      label="Rango"
                      restrict="FISICA, JURIDICA, CONTRIBUIDOR"
                      value={carga.rango}
                    ></People>
                  </div>
                  <Input form={form} name={`cargas[${index}].beneficiario`} label="Beneficiario" value={carga.beneficiario} />
                </div>
                <div className="flex gap-4 mb-6">
                  <Price form={form} name={`cargas[${index}].importePrincipal`} label="Imp. Principal" value={carga.importePrincipal} />
                  <Price form={form} name={`cargas[${index}].interesOrdinarios`} label="Int. Ordinarios" value={carga.interesOrdinarios} />
                  <Price form={form} name={`cargas[${index}].interesDemora`} label="Int. Demora" value={carga.interesDemora} />
                  <Price form={form} name={`cargas[${index}].costasGastos`} label="Costas y Gastos" value={carga.costasGastos} />
                  <Price form={form} name={`cargas[${index}].otrosConceptos`} label="Otros Conceptos" value={carga.otrosConceptos} />
                </div>
                <div className="flex gap-4">
                  <Price form={form} name={`cargas[${index}].valorSubasta`} label="Valor subasta" value={carga.valorSubasta} />
                  <InputUrl form={form} className="flex-grow" name={`cargas[${index}].titulo`} label="Título" value={carga.titulo} />
                </div>
              </WideListInteractiveItem>
            ))}
            <WideListInteractiveItem>
              <ButtonSecondary onClick={addCarga}>
                <Icon solid name="plus" className="mr-2" />
                Añadir derecho/carga
              </ButtonSecondary>
            </WideListInteractiveItem>
          </WideList>
        </CardHeader>

        {!fincaRegistral.blocked &&
          <Submit form={form} validation={validation}>Guardar Finca Registral</Submit>
        }
      </Form>
    </PageLayout>
  )
}
