
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'

import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Face from 'components/people/Face'
import PageLayout from 'layout/webinterna/PageLayout'
import { webinterna } from 'services/discovery'
import { keyBy } from 'lodash-es'
import ProcesosClient from 'services/WebInterna/procesos/procesos'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import resolver from 'platform/resolver'
import { listPersonas } from 'models/webinterna/personas'


const procesosClient = new ProcesosClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())


export default function ContribuidoresList() {
  let { table, setRows, useAsyncLoad, setSortOrders } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Alfabéticamente Nombres',
        sort: firstBy('nombre'),
      },
    ])

    let personas = await listPersonas()
    personas = personas.filter(persona => persona.fisica?.contribuidor)

    let { procesos, grupos } = await resolver.all({
      procesos: procesosClient.List().then(reply => reply.procesos || []),
      grupos: gruposProcesosClient.List().then(reply => reply.grupos || []),
    })

    grupos = keyBy(grupos, 'name')
    procesos.forEach(proceso => {
      proceso.grupo = grupos[proceso.grupo]
    })

    personas = personas.map(persona => {
      persona.extensionOficina = persona.fisica.contribuidor.extensionOficina
      persona.extensionMovil = persona.fisica.contribuidor.extensionMovil
      persona.telefonoEmpresa = persona.fisica.contribuidor.telefonoEmpresa
      persona.acronimo = persona.fisica.contribuidor.acronimo

      persona.procesosPropietario = procesos.filter(proceso => proceso.cuidador === persona.name)
      persona.procesosGerente = procesos.filter(proceso => proceso.gerentes?.includes(persona.name))
      persona.procesosAnalista = procesos.filter(proceso => proceso.analista === persona.name)
      persona.procesosGestor = procesos.filter(proceso => proceso.gestores?.map(gestor => gestor.contribuidor).includes(persona.name))

      persona.$search = [
        persona.email,
        persona.nombre,
        persona.extensionOficina,
      ]
      
      return persona
    })
    setRows(personas)
  })

  return (
    <PageLayout wide>
      <PageHeader>
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/contribuidores">Contribuidores</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell></HeaderCell>
            <HeaderCell>Ext. Oficina</HeaderCell>
            <HeaderCell>Ext. Móvil</HeaderCell>
            <HeaderCell>Móvil empresa</HeaderCell>
            <HeaderCell>Gestor</HeaderCell>
            <HeaderCell>Gerente</HeaderCell>
            <HeaderCell>Analista</HeaderCell>
            <HeaderCell>Cuidador</HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/personas/${row.name}`}>
            <div className="flex items-center">
              <div className="w-10">
                <Face displayName={row.nombre || row.email} pictureUrl={row.pictureUrl} />
              </div>
              <div className="ml-3">
                <span className="font-medium text-gray-900">
                  {row.nombre}
                </span>
                {row.acronimo && <span className="ml-1 font-medium text-gray-400">{`[${row.acronimo}]`}</span>}
                <div className="text-sm text-gray-400">{row.identidad}</div>
              </div>
            </div>
          </RowMobile>
        }
        row={row => (
          <>
            <Cell>
              <div className="flex items-center">
                <Face displayName={row.nombre || row.email} pictureUrl={row.pictureUrl} />
                <div className="ml-3">
                  <Link
                    to={`/webinterna/personas/${row.name}`}
                    className="font-medium text-gray-900"
                  >
                    {row.nombre
                      ? row.nombre
                      : <span className="italic">(sin nombre)</span>
                    }
                  </Link>
                  {row.acronimo && <span className="ml-1 font-medium text-gray-400">{`[${row.acronimo}]`}</span>}
                  <div className="text-sm text-gray-400">{row.identidad}</div>
                </div>
              </div>
            </Cell>
            <Cell>
              {row.extensionOficina
                ? <div>{row.extensionOficina}</div>
                : <span>&mdash;</span>
              }
            </Cell>
            <Cell>
              <div>
                {row.extensionMovil}
              </div>
            </Cell>
            <Cell>
              <div>
                {row.telefonoEmpresa}
              </div>
            </Cell>
            <Cell wrap>
              <div className="flex flex-wrap gap-1">
                {row.procesosGestor.map(proceso => (
                  <Link to={`/webinterna/procesos/${proceso.name}`} key={proceso.name}>
                    {proceso.grupo?.codigo}{proceso.codigo}
                  </Link>
                ))}
              </div>
            </Cell>
            <Cell wrap>
              <div className="flex flex-wrap gap-1">
                {row.procesosGerente.map(proceso => (
                  <Link to={`/webinterna/procesos/${proceso.name}`} key={proceso.name}>
                    {proceso.grupo?.codigo}{proceso.codigo}
                  </Link>
                ))}
              </div>
            </Cell>
            <Cell wrap>
              <div className="flex flex-wrap gap-1">
                {row.procesosAnalista.map(proceso => (
                  <Link to={`/webinterna/procesos/${proceso.name}`} key={proceso.name}>
                    {proceso.grupo?.codigo}{proceso.codigo}
                  </Link>
                ))}
              </div>
            </Cell>
            <Cell wrap>
              <div className="flex flex-wrap gap-1">
                {row.procesosPropietario.map(proceso => (
                  <Link to={`/webinterna/procesos/${proceso.name}`} key={proceso.name}>
                    {proceso.grupo?.codigo}{proceso.codigo}
                  </Link>
                ))}
              </div>
            </Cell>
          </>
        )}
      />
    </PageLayout>
  )
}
