
import React from 'react'

import Icon from 'components/Icon'
import { HeaderMenu } from 'layout/HeaderMenu'
import NavigateThrobber from 'components/NavigateThrobber'


export function FullscreenLayout({ breadcrumbs, children }) {
  return (
    <HeaderMenu
      search={
        <div className="max-w-lg w-full lg:max-w-xs">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Icon solid name="search" className="text-gray-400" />
            </div>
            <input id="search" name="search" className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-teal-600 focus:border-teal-600 sm:text-sm" placeholder="Search" type="search" />
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
    >
      <div className="flex-1 overflow-y-scroll relative">
        <NavigateThrobber></NavigateThrobber>

        <div className="max-w-full mx-12 mb-12 py-10">
          {children}
        </div>
      </div>
    </HeaderMenu>
  )
}
