
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Face from 'components/people/Face'
import { batchGetPersonas } from 'models/webinterna/personas'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { webinterna } from 'services/discovery'
import Throbber from 'components/Throbber'
import Icon from 'components/Icon'


const sociedadesClient = new SociedadesClient(webinterna())


export default function PeopleViewer({ value }) {
  let [personaSociedad, setPersonaSociedad] = useState(null)

  useEffect(() => {
    async function fetchData() {
      if (value.persona) {
        let reply = await batchGetPersonas([value.persona])
        setPersonaSociedad({
          ...reply[value.persona],
          isPeople: true,
        })
      } else {
        let reply = await sociedadesClient.Get({ name: value.sociedad })
        setPersonaSociedad({
          ...reply,
          nombre: reply.denominacion,
          identidad: reply.cif,
          isCorp: true,
        })
      }
    }
    if (value) {
      fetchData()
    }
  }, [value])

  return personaSociedad ?
    <Link
      to={`/webinterna/${personaSociedad.isPeople ? 'personas' : 'sociedades'}/${personaSociedad.name}`}
      className="relative rounded-lg border border-gray-300 bg-white p-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 cursor-pointer"
    >
      <div className="flex-shrink-0">
        {personaSociedad.isPeople &&
          <Face displayName={personaSociedad.nombre} pictureUrl={personaSociedad.fotoUrl}></Face>
        }
        {personaSociedad.isCorp &&
          <div className="inline-block h-10 w-10 rounded-full text-center bg-indigo-400">
            <Icon solid name="handshake-alt" className="text-lg text-white mt-3"></Icon>
          </div>
        }
      </div>
      <div className="flex-1 min-w-0">
        <div className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">{personaSociedad.nombre}</p>
          <p className="text-sm font-normal text-gray-500 truncate">{personaSociedad.identidad}</p>
        </div>
      </div>
    </Link>
    :
    <div className="flex">
      <Throbber/>
    </div>
}
