
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { isNumber } from 'lodash-es'

import { useField } from 'platform/forms'
import { formatMoney } from 'platform/money'


let mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  requireDecimal: true,
  decimalSymbol: ',',
  allowNegative: true,
})


export default function Price({ form, className, help, name, label, validations, value, onChange }) {
  let field = useField({ form, name, validations, value, onChange })
  let [internalValue, setInternalValue] = useState(null)

  if (value && !isNumber(value)) {
    throw new Error(`Price should receive a number always`)
  }

  useEffect(() => {
    if (!value) {
      return
    }
    setInternalValue(formatMoney(value, true))
  }, [value])

  function maskedInputChanged(e) {
    // Evita reasignar el valor si solo introduce el simbolo negativo (-)
    if (e.target.value === '-_') {
      return
    }
    let changed
    if (e.target.value.includes('.') || e.target.value.includes(',')) {
      changed = e.target.value
        .replace(/\./g, '')
        .replace(/,/g, '')
      if (e.target.value.endsWith(',') || e.target.value.endsWith('.')) {
        changed *= 100
      } else if (e.target.value.charAt(e.target.value.length - 2) === ',' || e.target.value.charAt(e.target.value.length - 2) === '.') {
        changed *= 10
      }
    } else {
      changed = 100 * e.target.value
    }
    changed = parseInt(changed, 10)
    field.setValue(changed)
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1">
        <div className="mt-1 relative rounded-md shadow-sm sm:w-48">
          <MaskedInput
            type="text"
            name={name}
            id={name}
            mask={mask}
            className={cx(
              'form-input block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 pr-18 md:pr-16 sm:text-sm border-gray-300 rounded-md',
              {
                'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red': field.hasError,
              },
            )}
            onChange={maskedInputChanged}
            value={internalValue}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm sm:leading-5" id="price-currency">
              EUROS
            </span>
          </div>
        </div>
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
