
const ENTITY_TYPES = {
  persona: {
    label: 'Persona',
    slug: 'personas',
  },
  contrato: {
    label: 'Contrato',
    slug: 'contratos',
  },
  inmueble: {
    label: 'Unidad Inmobiliaria',
    slug: 'inmuebles',
  },
  planCuentas: {
    label: 'Plan de Cuentas',
    slug: 'planes-cuentas',
  },
  presupuesto: {
    label: 'Presupuesto',
    slug: 'proyectos/presupuestos',
  },
  proceso: {
    label: 'Proceso',
    slug: 'procesos',
  },
  proyecto: {
    label: 'Proyecto',
    slug: 'proyectos',
  },
  sociedad: {
    label: 'Sociedad',
    slug: 'sociedades',
  },
  fincaRegistral: {
    label: 'Finca Registral',
    slug: 'fincas-registrales',
  },
  elementoPatrimonial: {
    label: 'Elemento patrimonial',
    slug: 'elementos-patrimoniales',
  },
  fincaCatastral: {
    label: 'Finca Catastral',
    slug: 'fincas-catastrales',
  },
}

export function validationType(validacion) {
  return Object.keys(validacion.entidad)[0]
}

export function validationDescriptor(validacion) {
  return ENTITY_TYPES[validationType(validacion)]
}

