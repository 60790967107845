
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { some, cloneDeep, isEqual } from 'lodash-es'

import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { putEntity, getEntity } from 'models/docs/entities'
import User from 'components/simple-forms/User'
import PeopleSelector from 'components/people/PeopleSelector'


const client = new UsersClient(users())


export default function OwnersEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [form, setForm] = useState({})
  let [owner, setOwner] = useState({})
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { users } = await client.List()
    setUsers(users)

    if (name) {
      let owner = await getEntity(`owners/${name}`)

      setOwner(owner)
      setForm(owner)
    }
  })

  async function save() {
    let reply = await putEntity('owners', { owner: cloneDeep(form) })

    if (!name) {
      history.push(`/manage/${reply.name}`)
    } else {
      setOwner(form)
    }
  }

  let changed = some([
    !isEqual(owner.owner, form.owner),
    !isEqual(owner.privacyManager, form.privacyManager),
  ]) && form.owner && form.privacyManager

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/owners">Propietarios</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/owners/${name}`}>{owner.displayName}</Breadcrumb>
            :
            <Breadcrumb url="/manage/owners/create">Crear propietario</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos generales"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <PeopleSelector
          label="Propietario"
          value={form.owner}
          className="mb-6"
          onChange={value => {
            setForm({
              ...form,
              owner: value,
            })
          }}
        />
        <User
          label="Gestor de LOPD"
          users={users}
          value={form.privacyManager}
          onChange={value => {
            setForm({
              ...form,
              privacyManager: value,
            })
          }}
        ></User>
      </CardHeader>
    </PageLayout>
  )
}
