
import { keyBy, uniq, merge } from 'lodash-es'

import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { webinterna } from 'services/discovery'
import resolver from 'platform/resolver'
import { batchGetPersonas, listPersonas } from './personas'


const sociedadesClient = new SociedadesClient(webinterna())


export async function fetchPersonasSociedades(items) {
  if (!items || !items.length) {
    return {}
  }

  let personasNames = []
  let sociedadesNames = []
  items.forEach(item => {
    if (item.persona) {
      personasNames.push(item.persona)
    } else {
      sociedadesNames.push(item.sociedad)
    }
  })

  let { personas, sociedades } = await resolver.all({
    personas: batchGetPersonas(personasNames),
    sociedades: sociedadesClient.BatchGet({ names: uniq(sociedadesNames) }).then(reply => reply.sociedades || []),
  })

  personas = Object.values(personas).map(c => serializePersona(c))
  sociedades = sociedades.map(s => serializeSociedad(s))

  personas = keyBy(personas, 'name')
  sociedades = keyBy(sociedades, 'name')

  let results = merge(personas, sociedades)
  results.resolve = obj => obj && results[obj.persona || obj.sociedad]
  return results
}


export async function listPersonasSociedades() {
  let { personas, sociedades } = await resolver.all({
    personas: listPersonas(),
    sociedades: sociedadesClient.List().then(reply => reply.sociedades || []),
  })

  personas = Object.values(personas).map(c => serializePersona(c))
  sociedades = sociedades.map(s => serializeSociedad(s))

  return personas.concat(sociedades)
}


function serializePersona(c) {
  return {
    isPeople: true,
    name: c.name,
    nombre: c.nombre,
    email: c.email,
    pictureUrl: c.fotoUrl,
  }
}


function serializeSociedad(s) {
  return {
    isCorp: true,
    name: s.name,
    acronimo: s.acronimo,
    nombre: s.denominacion,
    email: s.email,
  }
}

export async function prepareSearchPersonasSociedades(items) {
  return await fetchPersonasSociedades(uniq(items))
}

export function searchPersonasSociedades(personasSociedades, item) {
  if (!item || Object.values(item).length === 0) {
    return []
  }

  let reply = personasSociedades[Object.values(item)[0]]
  return [
    reply.nombre,
    reply.denominacion,
    reply.email,
  ]
}
