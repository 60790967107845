
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'

import { webinterna } from 'services/discovery'
import PlanesCuentasClient from 'services/WebInterna/planes_cuentas/planes_cuentas'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import ExportCSV from 'components/ExportCSV'
import ParseCSV from 'components/ParseCSV'
import { isEqual } from 'lodash'
import { Cell, HeaderCell, TableRow } from 'components/Table'
import CardTable from 'components/CardTable'
import Select from 'components/forms/Select'
import BadgeOrange from 'components/badges/BadgeOrange'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeBlue from 'components/badges/BadgeBlue'
import BadgePurple from 'components/badges/BadgePurple'
import People from 'components/forms/People'
import { useNavigate } from 'hooks/navigate'

const planesClient = new PlanesCuentasClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())

const TEMPLATE = [
  {
    'Plan_Cuentas': '',
    'ID_PA': 'ID',
    'Agrupacion_n1': 'Agrupación 1',
    'Agrupacion_n2': 'Agrupación 2',
    'Agrupacion_n3': 'Agrupación 3',
    'Agrupacion_n4': 'Agrupación 4',
    'Denominacion_PA': 'Denominación',
    'Descripcion de uso': 'Descripción',
    'Tipo': 'Normal',
    'Cuenta_Contable': 'Cuenta contable',
    'Observaciones': 'Observaciones',
    'Activa': 'Si',
    'PyG': 'No',
    'Comprobacion longitud': '13',
  },
]
const PLANES_CUENTAS_TIPO = {
  'Rango': 'TIPO_RANGO',
  'Unica': 'TIPO_UNICA',
  'Normal': 'TIPO_NORMAL',
}


function usePlanCuentas({ name, hasPredecesor }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [plan, setPlan] = useState({})
  let [predecesor, setPredecesor] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (isEdit) {
      if (hasPredecesor) {
        let reply = await planesClient.Get({ name: name })
        setPredecesor(reply)
      } else {
        let reply = await planesClient.Get({ name: name })
        setPlan(reply)
      }
    }
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    if (reply.entidad.planCuentas.predecesor) {
      setPredecesor(await planesClient.Get({ name: reply.entidad.planCuentas.predecesor }))
    }
    setPlan(reply.entidad.planCuentas)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit && !hasPredecesor ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        planCuentas: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este plan de cuentas para que lo apruebe un administrador.')

    if (isEdit && !hasPredecesor) {
      history.push(`/webinterna/planes-cuentas/${name}`)
    } else {
      history.push(`/webinterna/planes-cuentas`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await planesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await planesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await planesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        planCuentas: plan,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este plan de cuentas para que lo apruebe un administrador.')
    history.push(`/webinterna/planes-cuentas`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    plan,
    predecesor,
    validation,
    remove,
  }
}

export default function PlanesCuentasEdit({ validation: isValidation, predecesor: hasPredecesor }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')

  let [error, setError] = useState(null)
  let [partidasAnaliticas, setPartidasAnaliticas] = useState(null)
  let [invalidated, setInvalidated] = useState([])
  let [newSucesores, setNewSucesores] = useState({})

  let {
    save: savePlan,
    load: loadPlan,
    loadValidation,
    confirmValidation,
    plan,
    predecesor,
    validation,
    remove: removePlan,
  } = usePlanCuentas({ name, hasPredecesor })

  
  function parseFile(file) {
    let res = []

    if (!isEqual(file[0].data, [
      'Plan_Cuentas',
      'ID_PA',
      'Agrupacion_n1',
      'Agrupacion_n2',
      'Agrupacion_n3',
      'Agrupacion_n4',
      'Denominacion_PA',
      'Descripcion de uso',
      'Tipo',
      'Cuenta_Contable',
      'Observaciones',
      'Activa',
      'PyG',
      'Comprobacion longitud',
    ])) {
      setError('La cabecera del fichero está corrompida. Descarga una nueva plantilla.')
      return
    }
    for (let i = 1; i < file.length; i++) {
      if (file[i].data.length === 0) {
        continue
      } else if (file[i].data.length === 1) {
        if (file[i].data[0] && file[i].data !== ' ') {
          setError('El fichero tiene un formato incorrecto. Cada fila del fichero debe tener 14 campos.')
          return
        } else {
          continue
        }
      }
      else if (file[i].data.length !== 14) {
        setError('El fichero tiene un formato incorrecto. Cada fila del fichero debe tener 14 campos.')
        return
      }

      let pa = {
        name: file[i].data[1].toString(),
        denominacion: file[i].data[6],
        agrupacion1: file[i].data[2],
        agrupacion2: file[i].data[3],
        agrupacion3: file[i].data[4],
        agrupacion4: file[i].data[5],
        descripcion: file[i].data[7],
        cuentaContable: file[i].data[9],
        observaciones: file[i].data[10],
        activa: file[i].data[11] === 'Si' ? true : false,
        pyg: file[i].data[12] === 'Si' ? true : false,
        tipo: file[i].data[8],
        comprobacionLongitud: file[i].data[13],
      }

      if (!pa.cuentaContable && !pa.observaciones) {
        setError('El campo observaciones es obligatorio cuando no hay cuenta contable.')
        return
      }
      if (!pa.cuentaContable && pa.pyg) {
        setError('El campo PyG no puede estar asignado si no hay cuenta contable.')
        return
      }
      if (pa.tipo !== 'Rango' && pa.tipo !== 'Unica' && pa.tipo !== 'Normal') {
        setError('El campo tipo no es correcto. Debe ser Rango, Unica o Normal')
        return
      }
      pa.tipo = PLANES_CUENTAS_TIPO[pa.tipo]

      if (isEdit && !predecesor.name) {
        if (!plan.partidasAnaliticas) {
          setError('El plan anterior no tiene partidas analíticas que suceder.')
          return
        }
        if (plan.partidasAnaliticas.length < i - 1) {
          setError(`El plan anterior parece tener menos partidas analíticas que el actual. Se ha encontrado una nueva partida analítica en la línea ${i}.`)
          return
        }
        if (!plan.partidasAnaliticas.find(prevPA => prevPA.name === pa.name)) {
          setError(`La PA en la línea ${i} no tiene el mismo nombre que ninguna del plan anterior.`)
          return
        }
        if (file[i].data[0] !== plan.name) {
          setError(`La PA de la línea ${i} hace referencia a un plan de cuentas distinto.`)
          return
        }

        pa.predecesor = plan.partidasAnaliticas[i - 1].predecesor
      }

      res.push(pa)
    }

    if (isEdit && !predecesor.name && res.length !== plan.partidasAnaliticas.length) {
      setError('Al plan nuevo le faltan partidas analíticas que había en el anterior.')
      return
    }

    setError('')
    setPartidasAnaliticas(res)
  }

  useEffect(() => {
    if (partidasAnaliticas && partidasAnaliticas.length && predecesor.name) {
      partidasAnaliticas.forEach(pa => {
        let paPred = predecesor.partidasAnaliticas.find(paPred => paPred.name === pa.name)
        if (paPred) {
          pa.predecesor = paPred.name
        }
      })
    
      let pasWithoutSucesor = []
      predecesor.partidasAnaliticas.forEach(paPred => {
        if (!partidasAnaliticas.find(newPA => newPA.name === paPred.name)) {
          pasWithoutSucesor.push(paPred)
        }
      })
        
      setInvalidated(pasWithoutSucesor)
    }

  }, [partidasAnaliticas, predecesor, predecesor.partidasAnaliticas])

  useNavigate(async () => {
    if (isValidation) {
      await loadValidation()
    } else {
      await loadPlan()
    }
  })

  let form = useForm(async (data) => {
    let sociedad
    if (data.sociedad?.sociedad) {
      sociedad = data.sociedad.sociedad
    } else if (data.sociedad) {
      sociedad = data.sociedad
    } else {
      sociedad = plan.sociedad
    }
    let req = {
      ...plan,
      ...data,
      sociedad,
      predecesor: predecesor.name,
    }

    if (partidasAnaliticas) {
      delete req.sucesores
      if (Object.values(newSucesores).length) {
        for (const predPAName in newSucesores) {
          let paName = newSucesores[predPAName]
          if (paName !== 'INUTILIZAR') {
            partidasAnaliticas.find(pa => pa.name === paName).predecesor = predPAName
          }
        }
      }
      req.partidasAnaliticas = partidasAnaliticas
    }

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await savePlan(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<div className="flex space-x-4">
          {isEdit && !plan.blocked && !predecesor &&
            <ButtonConfirmDanger onClick={removePlan}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Plan de Cuentas
            </ButtonConfirmDanger>
          }
          {!isEdit && <ExportCSV data={TEMPLATE} filename="ca" label="Descargar plantilla"/>}
        </div>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/planes-cuentas">Planes de Cuentas</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/planes-cuentas/${name}`}>Consultar Plan de Cuentas</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/planes-cuentas/${predecesor.name ? 'evolve' : 'edit'}/${name}`}>{`${predecesor.name ? 'Suceder' : 'Editar'}`} Plan de Cuentas</Breadcrumb>
          : <Breadcrumb url="/webinterna/planes-cuentas/edit/new">Crear Nuevo Plan de Cuentas</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={plan.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div
              className={cx(
                'flex gap-x-4',
                { 'mb-6': !isValidation },
              )}
            >
              <Input form={form} name="denominacion" label="Denominación" validations="required|min:3" value={plan.denominacion} className="flex-grow" />
              <div className="w-1/2">
                <People form={form} name="sociedad" label="Sociedad" value={plan.sociedad && { sociedad: plan.sociedad }} restrict="SOCIEDAD" />
              </div>
            </div>

            {!isValidation && <ParseCSV onError={() => setError('El archivo tiene un formato incorrecto.')} onFileLoad={parseFile} error={error} />}
          </fieldset>
        </Card>

        {invalidated.length > 0 && 
          <CardTable
            title="Partidas Analíticas"
            subtitle="Elige los sucesores de las partidas inutilizadas."
            header={
              <>
                <HeaderCell>Nombre Partida</HeaderCell>
                <HeaderCell>Elegir Partida Sucesora</HeaderCell>
              </>
            }
            className="mt-4"
          >
            {invalidated.map(paWithoutSucesor => (
              <TableRow key={paWithoutSucesor.name}>
                <Cell>{paWithoutSucesor.name}</Cell>
                <Cell>
                  <Select
                    form={form}
                    validations="required"
                    name={`sucesores[${paWithoutSucesor.name}]`}
                    options={[{ label: 'Inutilizar partida analítica', value: 'INUTILIZAR' }].concat(partidasAnaliticas
                      .map(pa => { 
                        return {
                          label: pa.name,
                          value: pa.name,
                        }
                      }))}
                    onChange={value => {
                      let newName = paWithoutSucesor.name
                      setNewSucesores({
                        ...newSucesores,
                        [newName]: value,
                      })
                    }}
                  />
                </Cell>
              </TableRow>
            ))}
          </CardTable>
        }

        {isValidation &&
          <CardTable
            className="mt-4"
            title="Partidas Analíticas"
            empty="No hay partidas analíticas"
            header={
              <>
                <HeaderCell>Código</HeaderCell>
                <HeaderCell>Agrupación 1</HeaderCell>
                <HeaderCell>Agrupación 2</HeaderCell>
                <HeaderCell>Agrupación 3</HeaderCell>
                <HeaderCell>Agrupación 4</HeaderCell>
                <HeaderCell>Denominación</HeaderCell>
                <HeaderCell>Descripción</HeaderCell>
                <HeaderCell>Tipo</HeaderCell>
                <HeaderCell>Cuenta contable</HeaderCell>
                <HeaderCell>Observaciones</HeaderCell>
                <HeaderCell>Activa</HeaderCell>
                <HeaderCell>PyG</HeaderCell>
                <HeaderCell>Comparación longitud</HeaderCell>
              </>
            }
          >
            {plan.partidasAnaliticas && plan.partidasAnaliticas.map(pa => (
              <TableRow key={pa.tipo}>
                <Cell>{pa.name}</Cell>
                <Cell>{pa.agrupacion1}</Cell>
                <Cell>{pa.agrupacion2}</Cell>
                <Cell>{pa.agrupacion3}</Cell>
                <Cell>{pa.agrupacion4}</Cell>
                <Cell>{pa.denominacion}</Cell>
                <Cell>{pa.descripcion}</Cell>
                <Cell>
                  {pa.tipo === 'TIPO_RANGO' && 'Rango'}
                  {pa.tipo === 'TIPO_UNICA' && 'Única'}
                  {pa.tipo === 'TIPO_NORMAL' && 'Normal'}
                </Cell>
                <Cell>{pa.cuentaContable || <BadgeOrange>Sin cuenta</BadgeOrange>}</Cell>
                <Cell>{!pa.cuentaContable ? pa.observaciones : ''}</Cell>
                <Cell>
                  {pa.activa && <BadgeGreen>Activada</BadgeGreen>}
                  {!pa.activa && <BadgeGray>Desactivada</BadgeGray>}
                </Cell>
                <Cell>
                  {!pa.cuentaContable && pa.pyg && <BadgePurple>Afecta a cuenta de resultados</BadgePurple>}
                  {!pa.cuentaContable && !pa.pyg && <BadgeBlue>No afecta a cuenta de resultados</BadgeBlue>}
                </Cell>
                <Cell>
                  {pa.comprobacionLongitud}
                </Cell>
              </TableRow>
            ))}
          </CardTable>
        }

        {!plan.blocked && !error &&
          <Submit form={form} validation={validation}>Guardar plan de cuentas</Submit>
        }
      </Form>
    </PageLayout>
  )
}
