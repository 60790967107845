
import React, { useState } from 'react'
import { some } from 'lodash-es'

import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import PageLayout from 'layout/docs/PageLayout'
import { useNavigate } from 'hooks/navigate'
import Icon from 'components/Icon'
import Card from 'components/Card'
import ShowPane from 'components/ShowPane'
import PdfPreview from 'components/PdfPreview'
import Face from 'components/people/Face'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import { runQuery, batchGetEntities, listEntities, putEntity } from 'models/docs/entities'
import * as query from 'models/docs/query'
import UsersClient from 'services/Users/users/users'
import FilesClient from 'services/Docs/files/files'
import { users, docs } from 'services/discovery'
import { datetime } from 'platform/filters'
import { parseTimestamp } from 'platform/datetime'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import Selector from 'components/simple-forms/Selector'
import { accountInfo } from 'auth/active-directory'


const usersClient = new UsersClient(users())
const filesClient = new FilesClient(docs())


export default function ArchiveDocument() {
  let [code, setCode] = useState('')
  let [notFound, setNotFound] = useState(false)
  let [doc, setDoc] = useState(null)
  let [room, setRoom] = useState(null)
  let [locker, setLocker] = useState(null)
  let [rooms, setRooms] = useState([])
  let [lockers, setLockers] = useState([])
  
  useNavigate(async () => {
    let rooms = await listEntities('archive-rooms')
    setRooms(rooms.map(room => {
      return {
        value: room.name,
        label: room.displayName,
      }
    }))
  })

  async function find() {
    let q = query.build(
      query.and('payload.document.archive.code', code),
    )
    let documents = await runQuery('documents', q)
    setNotFound(!documents.length)

    let doc = documents[0]
    doc.file = await filesClient.Get({ name: doc.file })
    doc.uploader = await usersClient.Get({ name: doc.uploader })
    doc.createTime = parseTimestamp(doc.createTime)

    let entities = await batchGetEntities([doc.owners[0].name, doc.owners[0].documentType])
    doc.documentType = entities[1]

    let owners = await fetchPersonasSociedades([entities[0].owner])
    doc.owner = owners.resolve(entities[0].owner)
    setDoc(doc)
  }

  async function updateRoom(room) {
    setRoom(null)
    let lockers = await listEntities('archive-rooms-lockers', room)
    setLockers(lockers.map(locker => {
      return {
        value: locker.name,
        label: locker.displayName,
      }
    }))

    setLocker(null)
    setRoom(room)
  }

  async function archive() {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      archive: {
        room,
        locker,
      },
    }
    await putEntity('revisions', { revision })

    setCode('')
    setDoc(null)
    setRoom(null)
    setLocker(null)
  }
  
  let disabled = some([
    !room,
    !locker && lockers.length,
  ])

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url="/docs/archive">Archivar documento físico</Breadcrumb>
        </Breadcrumbs>
      }
    >
      {!doc &&
        <div className="w-full flex justify-center">
          <div className="max-w-xl bg-gray-50 p-8 mt-20 rounded-md w-full">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Archivar un documento físico</h3>

            <div className="mt-8">
              <Input
                className="w-56"
                label="Código de archivación"
                value={code}
                onChange={setCode}
              ></Input>

              {notFound && <div className="mt-4 text-red-500 font-medium">No se ha encontrado ningún documento con el código introducido.</div> }
            </div>

            <div className="w-full mt-12">
              <ButtonAsyncPrimary onClick={() => find()}>
                <Icon solid name="search" className="mr-2" />
                Buscar
              </ButtonAsyncPrimary>
            </div>
          </div>
        </div>
      }
      {doc &&
        <>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <ButtonSecondary onClick={() => setDoc(null)}>
                <Icon solid name="arrow-left" />
              </ButtonSecondary>
              <div>
                <h1 className="text-3xl font-bold text-gray-900">{doc.title}</h1>
              </div>
            </div>
          </div>

          <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <Card
                title="Información general"
                subtitle="Información general del documento."
              >
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                  <ShowPane title="Propietario">
                    <div>{doc.owner.nombre}</div>
                    <div className="text-gray-500">{doc.documentType.displayName}</div>
                  </ShowPane>

                  <ShowPane title="Formato">
                    {doc.format === 'FORMAT_DIGITAL' && 'Digital'}
                    {doc.format === 'FORMAT_PHYSICAL' && 'Físico'}
                  </ShowPane>

                  <ShowPane title="Solicitante">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <Face displayName={doc.uploader?.displayName || ''} pictureUrl={doc.uploader?.pictureUrl} large></Face>
                      </div>
                      <div className="ml-3">
                        <div className="text-base text-gray-800">{doc.uploader?.displayName}</div>
                        <div className="text-sm text-gray-500">{doc.uploader?.email}</div>
                      </div>
                    </div>
                  </ShowPane>

                  <ShowPane title="Fecha de creación">
                    {datetime(doc.createTime, 'DATETIME')}
                  </ShowPane>
                </div>
              </Card>

              <Card
                className="mt-8"
                title="Archivo"
                subtitle="Indica la habitación y el armario donde se ubica el documento."
                actions={
                  <ButtonAsyncPrimary disabled={disabled} onClick={() => archive()}>
                    <Icon solid name="archive" className="mr-2" />
                    Archivar
                  </ButtonAsyncPrimary>
                }
              >
                <Selector
                  name="room"
                  label="Habitación"
                  className="w-88"
                  options={rooms}
                  onChange={value => updateRoom(value)}
                ></Selector>

                {room && !!lockers.length &&
                  <Selector
                    name="locker"
                    label="Armario"
                    className="mt-4 w-88"
                    options={lockers}
                    value={locker}
                    onChange={value => {
                      setLocker(value)
                    }}
                  ></Selector>
                }
              </Card>
            </div>

            <div className="hidden lg:block lg:col-start-3 lg:col-span-1">
              <div className="bg-white py-6 shadow sm:rounded-lg sticky top-6">
                <h2 id="timeline-title" className="pl-6 text-lg font-medium text-gray-900">Previsualización</h2>
                <PdfPreview url={doc.file.previewUrl} />
              </div>
            </div>
          </div>
        </>
      }
    </PageLayout>
  )
}
