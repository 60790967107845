
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import HtmlViewer from 'components/HtmlViewer'
import Card from 'components/Card'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeGreen from 'components/badges/BadgeGreen'
import { parseDate } from 'platform/datetime'
import { datetime, decimal, percentage, price } from 'platform/filters'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import ExternalLink from 'components/ExternalLink'
import { Cell, HeaderCell } from 'components/Datatable'
import CardTable from 'components/CardTable'
import PeopleViewer from 'components/people/PeopleViewer'
import { TableRow } from 'components/Table'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'


const inmueblesClient = new InmueblesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())

export default function FincasRegistralesShow() {
  let { name } = useParams()
  let [fincaRegistral, setFincaRegistral] = useState({})

  useNavigate(async () => {
    let fincaRegistral = await fincasRegistralesClient.Get({ name })
    fincaRegistral.cargas = fincaRegistral.cargas || []
    fincaRegistral.altaDate = parseDate(fincaRegistral.altaDate)
    fincaRegistral.altaJuridicaDate = parseDate(fincaRegistral.altaJuridicaDate)
    fincaRegistral.bajaJuridicaDate = parseDate(fincaRegistral.bajaJuridicaDate)
    fincaRegistral.bajaDate = parseDate(fincaRegistral.bajaDate)
    fincaRegistral.altaContableDate = parseDate(fincaRegistral.altaContableDate)
    fincaRegistral.bajaContableDate = parseDate(fincaRegistral.bajaContableDate)
    fincaRegistral.inicioContableDate = parseDate(fincaRegistral.inicioContableDate)
    fincaRegistral.finContableDate = parseDate(fincaRegistral.finContableDate)

    if (fincaRegistral.inmueble) {
      fincaRegistral.inmueble = await inmueblesClient.Get({ name: fincaRegistral.inmueble })
    }
    if (fincaRegistral.registroPropiedad) {
      fincaRegistral.registroPropiedad = await registrosPropiedadClient.Get({ name: fincaRegistral.registroPropiedad })
    }

    if (fincaRegistral.inmueble.municipio) {
      fincaRegistral.inmueble.municipio = await municipiosClient.Get({ name: fincaRegistral.inmueble.municipio })
    }

    if (fincaRegistral.inmueble.provincia) {
      fincaRegistral.inmueble.provincia = await provinciasClient.Get({ name: fincaRegistral.inmueble.provincia })
    }

    if (fincaRegistral.inmueble.grupo) {
      fincaRegistral.inmueble.grupo = await gruposInmobiliariosClient.Get({ name: fincaRegistral.inmueble.grupo })
    }

    if (fincaRegistral.inmueble.proyecto) {
      fincaRegistral.inmueble.proyecto = await proyectosClient.Get({ name: fincaRegistral.inmueble.proyecto })
    }

    if (fincaRegistral.inmueble.origen) {
      fincaRegistral.inmueble.origen = await inmueblesClient.Get({ name: fincaRegistral.inmueble.origen })
    }

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales || []
    elementosPatrimoniales = elementosPatrimoniales.filter(elementoPatrimonial => fincaRegistral.name === elementoPatrimonial.fincaRegistral)
    fincaRegistral.numeroElementosPatrimoniales = elementosPatrimoniales.length
    fincaRegistral.sumaSupPatrimoniales = elementosPatrimoniales.reduce((acc, curr) => acc + curr.superficieComputable, 0)

    let { fincas } = await fincasCatastralesClient.List()
    fincas = fincas || []
    let fincasCatastrales = fincas.filter(finca => fincaRegistral.name === finca.fincaRegistral)
    fincaRegistral.numeroFincasCatastrales = fincasCatastrales.length
    fincaRegistral.sumaSupCatastrales = fincasCatastrales.reduce((acc, curr) => acc + curr.superficieTotal, 0)
    setFincaRegistral(fincaRegistral)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!fincaRegistral.blocked &&
            <Acl perm="webinterna.fincas_registrales.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/fincas-registrales/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Finca Registral
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-registrales">Fincas Registrales</Breadcrumb>
        <Breadcrumb url={`/webinterna/fincas-registrales/${name}`}>Consultar Fincas Registrales</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={fincaRegistral.blocked}></EntityBlockedAlert>

      <div className="flex mb-6">
        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Denominación GIU">{fincaRegistral.inmueble?.grupo?.nombre}</ShowPane>
              <ShowPane title="Código UINM">{fincaRegistral.inmueble?.codigo}</ShowPane>
              <ShowPane title="Denominación UINM">{fincaRegistral.inmueble?.nombre}</ShowPane>
              <ShowPane title="Código FREG">{fincaRegistral.codigo}</ShowPane>
              <ShowPane title="Nº Finca Registral">{fincaRegistral.numeroFinca}</ShowPane>
              <ShowPane title="IDUFIR">{fincaRegistral.idufir}</ShowPane>
              <ShowPane title="Estado">
                {fincaRegistral.estado === 'ESTADO_BAJA' && <BadgeGray>Baja</BadgeGray>}
                {fincaRegistral.estado === 'ESTADO_PROPIEDAD' && <BadgeGreen>Propiedad</BadgeGreen>}
              </ShowPane>
              <ShowPane title="Estado Inscripción">
                {fincaRegistral.estadoInscripcion === 'ESTADO_INSCRIPCION_NO_INSCRITA' && <span>No inscrita</span>}
                {fincaRegistral.estadoInscripcion === 'ESTADO_INSCRIPCION_INSCRITA' && <span>Inscrita</span>}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Titular">
                {fincaRegistral.inmueble?.titular && <PeopleViewer value={fincaRegistral.inmueble.titular} />}
              </ShowPane>
              <ShowPane title="Protocolo">{fincaRegistral.protocolo}</ShowPane>
              <ShowPane title="Tipo de Derecho">
                {fincaRegistral.tipoDerecho === 'TIPO_DERECHO_PLENO_DOMINIO' && 'Pleno dominio'}
                {fincaRegistral.tipoDerecho === 'TIPO_DERECHO_NUDA_PROPIEDAD' && 'Nuda propiedad'}
                {fincaRegistral.tipoDerecho === 'TIPO_DERECHO_USUFRUCTO' && 'Usufructo'}
              </ShowPane>
              <ShowPane title="% Propiedad">{percentage(fincaRegistral.propiedadPercentage)}</ShowPane>
              <ShowPane title="% Usufructo">{percentage(fincaRegistral.usufructoPercentage)}</ShowPane>
              <ShowPane title="Valor EP">{price(fincaRegistral.valorEp)}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Superficie Registral Suelo" className="col-span-2">{decimal(fincaRegistral.superficieRegistralSuelo)} m²</ShowPane>
              <ShowPane title="Superficie Registral Edificación">{decimal(fincaRegistral.superficieRegistralEdificacion)} m²</ShowPane>
              <ShowPane title="Planimetria">
                <ExternalLink to={fincaRegistral.planimetria}>
                  {fincaRegistral.planimetria}
                </ExternalLink>
              </ShowPane>
            </div>
          </Card>
          <Card>
            <ShowPane title="Observaciones">
              <HtmlViewer html={fincaRegistral.observaciones}/>
            </ShowPane>
          </Card>
        </div>
        <div className="space-y-4 w-1/2">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Fecha Alta Jurídica">{datetime(fincaRegistral.altaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja Jurídica">{datetime(fincaRegistral.bajaJuridicaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Alta">{datetime(fincaRegistral.altaDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Baja">{datetime(fincaRegistral.bajaDate, 'DATE')}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Registro de la Propiedad">{fincaRegistral.registroPropiedad?.nombre}</ShowPane>
              <ShowPane title="Municipio">{fincaRegistral.inmueble?.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">
                {fincaRegistral.inmueble?.provincia?.nombre}
              </ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Fecha Alta Contable">{datetime(fincaRegistral.altaContableDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Inicio de Registro Contable">{datetime(fincaRegistral.inicioContableDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Fin de Registro Contable">{datetime(fincaRegistral.finContableDate, 'DATE')}</ShowPane>
              <ShowPane title="Fecha Finalización Contable">{datetime(fincaRegistral.bajaContableDate, 'DATE')}</ShowPane>
              <ShowPane title="Tipo Contable">{fincaRegistral.tipoContable}</ShowPane>
              <ShowPane title="Cuenta Contable">{fincaRegistral.cuentaContable}</ShowPane>
              <ShowPane title="Activo Fijo">{fincaRegistral.activoFijo ? 'Sí' : 'No'}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Nº Elementos Patrimoniales">{fincaRegistral.numeroElementosPatrimoniales}</ShowPane>
              <ShowPane title="Nº Fincas catastrales">{fincaRegistral.numeroFincasCatastrales}</ShowPane>
              <ShowPane title="Suma sup. Elementos Patrimoniales">{fincaRegistral.sumaSupPatrimoniales}</ShowPane>
              <ShowPane title="Suma sup. catastrales">{fincaRegistral.sumaSupCatastrales}</ShowPane>
            </div>
          </Card>
        </div>
      </div>

      <Card className="mb-6">
        <ShowPane title="Notas simples">
          <ExternalLink to={fincaRegistral.notasSimples}>
            {fincaRegistral.notasSimples}
          </ExternalLink>
        </ShowPane>
      </Card>

      <CardTable
        title="Derechos/Cargas"
        header={
          <>
            <HeaderCell>Derecho/Carga</HeaderCell>
            <HeaderCell>Clase</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Rango</HeaderCell>
            <HeaderCell>Beneficiario</HeaderCell>
            <HeaderCell className="text-right" fit="right">Imp. Principal</HeaderCell>
            <HeaderCell className="text-right" fit="right">Int. Ordinarios</HeaderCell>
            <HeaderCell className="text-right" fit="right">Int. Demora</HeaderCell>
            <HeaderCell className="text-right" fit="right">Costas/gastos</HeaderCell>
            <HeaderCell className="text-right" fit="right">Otros conceptos</HeaderCell>
            <HeaderCell className="text-right" fit="right">Valor subasta</HeaderCell>
            <HeaderCell>Título</HeaderCell>
          </>
        }
        empty="No hay derechos ni cargas."
      >
        {fincaRegistral.cargas?.map((carga, index) => (
          <TableRow key={index}>
            <Cell>Carga {index + 1}</Cell>
            <Cell>
              {carga.clase === 'CLASE_DERECHO' && 'Derecho'}
              {carga.clase === 'CLASE_CARGA' && 'Carga'}
            </Cell>
            <Cell>
              {carga.tipo === 'TIPO_HIPOTECA' && 'Hipoteca'}
              {carga.tipo === 'TIPO_SERVIDUMBRE' && 'Servidumbre'}
              {carga.tipo === 'TIPO_ANOTACION_PREVIA_EMBARGO' && 'Anotación previa embargo'}
              {carga.tipo === 'TIPO_OPCION_COMPRA' && 'Opción de compra'}
              {carga.tipo === 'TIPO_PROCEDIMIENTO_EXPROPIACION' && 'Procedimiento Expropiación'}
              {carga.tipo === 'TIPO_ARRENDAMIENTO' && 'Arrendamiento'}
              {carga.tipo === 'TIPO_AGUAS' && 'Aguas'}
              {carga.tipo === 'TIPO_AFECCIONES_FISCALES' && 'Afecciones Fiscales'}
              {carga.tipo === 'TIPO_OTROS' && 'Otros'}
            </Cell>
            <Cell>
              {carga.rango && <PeopleViewer value={{ persona: carga.rango }} />}
            </Cell>
            <Cell>
              {carga.beneficiario}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.importePrincipal)}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.interesesOrdinarios)}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.interesDemora)}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.costasGastos)}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.otrosConceptos)}
            </Cell>
            <Cell className="text-right" fit="right">
              {price(carga.valorSubasta)}
            </Cell>
            <Cell>
              <ExternalLink to={carga.titulo}>{carga.titulo}</ExternalLink>
            </Cell>
          </TableRow>
        ))}
      </CardTable>

      <Card className="mt-4">
        <div className="flex items center w-full gap-4">
          <ButtonLinkSecondary to={`/webinterna/inmuebles?finca-registral=${fincaRegistral.name}`}>Ver inmuebles asociados</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/elementos-patrimoniales?finca-registral=${fincaRegistral.name}`}>Ver elementos patrimoniales asociados</ButtonLinkSecondary>
          <ButtonLinkSecondary to={`/webinterna/fincas-catastrales?finca-registral=${fincaRegistral.name}`}>Ver fincas catastrales asociadas</ButtonLinkSecondary>
        </div>
      </Card>

    </PageLayout>
  )
}
