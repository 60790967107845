
import React, { useEffect, useRef } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import { ReactComponent as UndoIcon } from 'quill/assets/icons/undo.svg'
import { ReactComponent as RedoIcon } from 'quill/assets/icons/redo.svg'

import { useField } from 'platform/forms'


export default function HtmlEditor({ form, name, label, value, className, disabled, onChange }) {
  let field = useField({ form, name, value, onChange })

  let container = useRef(null)
  let toolbar = useRef(null)
  let editor = useRef(null)

  useEffect(() => {
    editor.current = new Quill(container.current, {
      readOnly: disabled,
      modules: {
        history: {
          delay: 500,
          maxStack: 500,
        },
        toolbar: {
          container: toolbar.current,
          handlers: {
            undo() {
              this.quill.history.undo()
            },
            redo() {
              this.quill.history.redo()
            },
          },
        },
      },
      theme: 'snow',
    })

    editor.current.root.classList.add('prose')
    editor.current.root.classList.add('max-w-full')

    editor.current.on('text-change', () => {
      field.setValue(editor.current.root.innerHTML)
    })
  }, [container, toolbar]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editor.current && value) {
      editor.current.root.innerHTML = value
    }
  }, [value])

  return (
    <div className={className}>
      <label htmlFor={name} className="block font-medium text-gray-700 text-sm">{label}</label>
      <div className="h-48 pb-12 mt-1">
        <div className="ql-toolbar ql-snow" ref={toolbar}>
          <span className="ql-formats">
            <button type="button" className="ql-undo">
              <UndoIcon />
            </button>
            <button type="button" className="ql-redo">
              <RedoIcon />
            </button>
          </span>
          <span className="ql-formats">
            <button type="button" className="ql-bold"></button>
            <button type="button" className="ql-italic"></button>
          </span>
          <span className="ql-formats">
            <button type="button" className="ql-header" value="1"></button>
            <button type="button" className="ql-header" value="2"></button>
            <button type="button" className="ql-blockquote"></button>
            <select className="ql-color" />
          </span>
          <span className="ql-formats">
            <button type="button" className="ql-list" value="ordered"></button>
            <button type="button" className="ql-list" value="bullet"></button>
          </span>
          <span className="ql-formats">
            <button type="button" className="ql-link"></button>
          </span>
          <span className="ql-formats">
            <button type="button" className="ql-clean"></button>
          </span>
        </div>
        <div ref={container} />
      </div>
    </div>
  )
}
