
import React from 'react'
import { MultiSelect } from 'react-multi-select-component'


export default function SelectorMultiple({ className, options = [], value = [], onChange, empty, selectedPrefix }) {
  let val = value?.map(v => options.find(o => o.value === v))

  return (
    <div className={className}>
      <MultiSelect
        className="h-full focus:ring-indigo-500 focus:border-indigo-500 shadow-sm rounded-md sm:text-sm text-gray-500"
        overrideStrings={{
          search: 'Filtrar...',
          selectSomeItems: 'Ningún elemento seleccionado',
          allItemsAreSelected: 'Todos seleccionados',
        }}
        ItemRenderer={({ checked, option, onClick, disabled }) => (
          <div className="flex items-center">
            <input
              className="mr-2"
              type="checkbox"
              onChange={onClick}
              checked={checked}
              tabIndex={-1}
              disabled={disabled}
            />
            {option.label}
          </div>
        )}
        hasSelectAll={false}
        options={options}
        value={val}
        onChange={value => {
          if (onChange) {
            onChange(value.map(v => v.value))
          }
        }}
        valueRenderer={selected => <span className="text-gray-500">{selected.length ? `${selectedPrefix}: ${selected.length}` : empty}</span>}
      />
    </div>
  )
}
