
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import getDate from 'date-fns/getDate'
import parseJSON from 'date-fns/parseJSON'


export function serializeDate(dt) {
  if (!dt) {
    return undefined
  }
  return {
    day: getDate(dt),
    month: getMonth(dt) + 1,
    year: getYear(dt),
  }
}


export function parseDate(dt) {
  if (!dt) {
    return undefined
  }
  return new Date(dt.year, dt.month - 1, dt.day)
}


export function parseTimestamp(ts) {
  if (!ts) {
    return null
  }
  return parseJSON(ts)
}


export function serializeTimestamp(ts) {
  if (!ts) {
    return null
  }
  return ts.toISOString()
}


export function parseDayMonth(dm) {
  if (!dm) {
    return null
  }
  return new Date(0, dm.month - 1, dm.day)
}


export function serializeDayMonth(dt) {
  if (!dt) {
    return null
  }
  return { day: dt.getDate(), month: dt.getMonth() + 1 }
}
