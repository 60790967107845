
import React from 'react'
import cx from 'classnames'


/**
 * List of items inside a card. Card should be provided manually around the component.
 */
export function WideList({ children, className, divider = 'divide-y divide-gray-200' }) {
  return (
    <ul className={cx(divider, className)}>{children}</ul>
  )
}


/**
 * Empty text to be included directly as a child of WideList.
 */
export function WideListEmpty({ children }) {
  return (
    <li className="italic text-gray-400 text-center py-20 leading-tight">{children}</li>
  )
}


/**
 * Each of the items inside a WideList.
 */
export function WideListItem({ children, actions, className }) {
  return (
    <li>
      <div className={cx('block hover:bg-gray-50', className)}>
        <div className="px-4 py-4 flex items-stretch sm:px-6">
          <div className="flex-1">
            {children}
          </div>
          <div className="ml-5 flex-shrink-0 flex items-center">
            {actions}
          </div>
        </div>
      </div>
    </li>
  )
}


/**
 * WideListItem with interactive elements inside. It won't highlight the item
 * in any way when the mouse is over the item.
 */
export function WideListInteractiveItem({ children, actions, className }) {
  return (
    <li>
      <div className={cx('block', className)}>
        <div className="px-4 py-4 flex items-stretch sm:px-6">
          <div className="flex-1">
            {children}
          </div>
          <div className="ml-5 flex-shrink-0 flex items-center">
            {actions}
          </div>
        </div>
      </div>
    </li>
  )
}
