
import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'


export default function ButtonLinkSecondary({ children, to, className }) {
  return (
    <>
      <Link
        to={to}
        className={cx('inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', className)}
      >
        {children}
      </Link>
    </>
  )
}
