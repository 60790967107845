
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import firstBy from 'thenby'

import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import { webinterna } from 'services/discovery'
import { useField } from 'platform/forms'
import Throbber from 'components/Throbber'
import { useMounted } from 'hooks/mounted'


const provinciasClient = new ProvinciasClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())


export default function Locality({ form, className, help, name, labelLocality = 'Municipio', labelProvince = 'Provincia', validations, value, onChange }) {
  let field = useField({ form, name, validations, value, onChange })

  let [loadingProvinces, setLoadingProvinces] = useState(true)
  let [loadingLocalities, setLoadingLocalities] = useState(false)
  let [provinces, setProvinces] = useState([])
  let [localities, setLocalities] = useState([])
  let [locality, setLocality] = useState(value)
  let [province, setProvince] = useState('')

  useMounted(async () => {
    let { provincias } = await provinciasClient.List()
    if (!provincias) {
      provincias = []
    }
    provincias.sort(firstBy(p => p.nombre).thenBy('nombre'))
    provincias = provincias.map(p => ({ value: p.name, label: p.nombre }))
    setProvinces(provincias)
    setLoadingProvinces(false)
  })
  
  useEffect(() => {
    async function loadLocality() {
      if (!locality && value && !province) {
        setLocality(value)
        let locality = await municipiosClient.Get({ name: value })
        await loadLocalities(locality.provincia)
        setProvince(locality.provincia)
      }
    }
    loadLocality()
  }, [value, locality, province])

  async function changeProvince(p) {
    setProvince(p)
    setLocality('')
    await loadLocalities(p)
  }

  async function loadLocalities(provincia) {
    setLoadingLocalities(true)
    let { municipios } = await municipiosClient.Search({ provincia })
    if (!municipios) {
      municipios = []
    }
    municipios.sort(firstBy(m => m.nombre).thenBy('nombre'))
    municipios = municipios.map(m => {
      return {
        value: m.name,
        label: m.nombre,
      }
    })
    setLocalities(municipios)
    setLoadingLocalities(false)
  }

  return (
    <div className={className}>
      <div className="flex space-x-4">
        {loadingProvinces
          ? <Throbber></Throbber>
          : <div className="relative rounded-md shadow-sm">
            <label
              htmlFor={`${name}-province`}
              className={cx(
                'block font-medium text-gray-700 text-sm mb-1',
                {
                  'text-red-700': field.hasError,
                },
              )}
            >
              {labelProvince}
              {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
            </label>
            <select
              name={`${name}-province`}
              id={`${name}-province`}
              className="form-select max-w-lg block w-full focus:ring-teal-500 focus:border-teal-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              value={province}
              onChange={e => changeProvince(e.target.value)}
            >
              <option value="" hidden></option>
              {provinces.map(province => (
                <option key={province.value.trim()} value={province.value}>{province.label}</option>
              ))}
            </select>
          </div>
        }
        {province &&
          <>
            {loadingLocalities
              ? <Throbber></Throbber>
              : <div className="relative rounded-md shadow-sm">
                <label
                  htmlFor={name}
                  className={cx(
                    'block font-medium text-gray-700 text-sm mb-1',
                    {
                      'text-red-700': field.hasError,
                    },
                  )}
                >
                  {labelLocality}
                  {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
                </label>
                <select
                  name={name}
                  id={name}
                  className="form-select max-w-lg block w-full focus:ring-teal-500 focus:border-teal-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  value={locality}
                  onChange={e => {
                    field.setValue(e.target.value)
                    setLocality(e.target.value)
                    if (onChange) {
                      onChange(e.target.value)
                    }
                  }}
                >
                  <option value="" hidden></option>
                  {localities.map(locality => (
                    <option key={locality.value.trim()} value={locality.value}>{locality.label}</option>
                  ))}
                </select>
              </div>
            }
          </>
        }
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
