
import React, { useState } from 'react'
import cx from 'classnames'

import { useField } from 'platform/forms'
import Icon from 'components/Icon'


export default function InputUrl({ form, className, name, label, value, validations, onChange }) {
  let field = useField({ form, name, validations: validations ? `url|${validations}` : 'url', value, onChange })

  let [url, setUrl] = useState('')
  
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          type="url"
          name={name}
          id={name}
          className={cx(
            'form-input  block w-full rounded-none rounded-l-md sm:text-sm',
            {
              'focus:ring-teal-500 focus:border-teal-500 border-gray-300': !field.hasError,
              'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': field.hasError,
            },
          )}
          defaultValue={value ? value.trim() : ''}
          onChange={e => {
            field.setValue(e.target.value.trim())
            setUrl(e.target.value.trim())
          }}
        />
        {field.hasError &&
          <div className="relative">
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
              <Icon solid name="exclamation-circle" />
            </div>
          </div>
        }
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
        >
          <Icon regular name="external-link" />
          <span>Visitar</span>
        </a>
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
    </div>
  )
}
