
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

import { HeaderMenu } from 'layout/HeaderMenu'
import NavigateThrobber from 'components/NavigateThrobber'


export const MENU = [
  { label: 'WebInterna' },
  { label: 'Grupos de procesos', url: '/manage/grupos-procesos' },
  { label: 'Centros de trabajo', url: '/manage/centros-trabajo' },
  { label: 'Grupos inmobiliarios', url: '/manage/grupos-inmobiliarios' },
  { label: 'Registros de la propiedad', url: '/manage/registros-propiedad' },
  { label: 'Registros mercantiles', url: '/manage/registros-mercantiles' },
  { label: 'IAE', url: '/manage/impuestos-actividad' },
  { label: 'Provincias', url: '/manage/provincias' },
  { label: 'Municipios', url: '/manage/municipios' },
  { label: 'Documentos' },
  { label: 'Propietarios', url: '/manage/owners' },
  { label: 'Tipos de documento', url: '/manage/document-types' },
  { label: 'Grupos de gestión', url: '/manage/inboxes-groups' },
  { label: 'Grupos de acceso', url: '/manage/grupos-acceso' },
  { label: 'Archivo físico', url: '/manage/archive-rooms' },
  { label: 'Gestores de biblioteca', url: '/manage/library-managers' },
  { label: 'Expedientes' },
  { label: 'Familias de expedientes', url: '/manage/record-families' },
  { label: 'Usuarios' },
  { label: 'Usuarios', url: '/manage/users' },
  { label: 'Grupos de usuarios', url: '/manage/groups' },
  { label: 'Transferencia de roles', url: '/manage/roles-transfer' },
]


export default function PageLayout({ children, breadcrumbs }) {
  let location = useLocation()
  MENU.forEach(item => item.active = item.url && location.pathname.startsWith(item.url))

  return (
    <HeaderMenu breadcrumbs={breadcrumbs}>
      <div className="flex-1 overflow-y-scroll">
        <div className="max-w-5xl mx-auto mb-12 py-10">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
              <nav>
                {MENU.map(item => (
                  !item.url ? (
                    <h3
                      key={item.label}
                      className={cx(
                        'text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2',
                        {
                          'mt-8': item.label !== MENU[0].label,
                        },
                      )}
                    >
                      {item.label}
                    </h3>
                  ) : (
                    <Link
                      key={item.url}
                      to={item.url}
                      className={cx(
                        'text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                        {
                          'bg-gray-200 text-indigo-600 hover:text-indigo-700 hover:bg-white': item.active,
                        },
                      )}
                    >{item.label}</Link>
                  )
                ))}
              </nav>
            </div>

            <div className="relative lg:col-span-9">
              <NavigateThrobber className="-m-1"></NavigateThrobber>
              <div className="space-y-6 sm:px-6 lg:px-0">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderMenu>
  )
}
