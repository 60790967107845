
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { webinterna } from 'services/discovery'
import GruposInmueblesClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'


const client = new GruposInmueblesClient(webinterna())


export default function GruposInmueblesList() {
  let [grupos, setGrupos] = useState([])

  useNavigate(async () => {
    let reply = await client.List()
    setGrupos(reply.grupos || [])
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/grupos-inmobiliarios">Grupos inmobiliarios</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Grupos inmobiliarios"
        subtitle="Administración de los grupos inmobiliarios."
        empty="No hay grupos inmobiliarios definidos."
        actions={
          <ButtonLinkPrimary to="/manage/grupos-inmobiliarios/create">
            <Icon solid name="plus" className="mr-2" />
            Crear grupo
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {grupos.map(grupo => (
          <TableRow key={grupo.name}>
            <Cell>
              <Link to={`/manage/grupos-inmobiliarios/${grupo.name}`} className="text-gray-700">{grupo.nombre}</Link>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/grupos-inmobiliarios/${grupo.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
