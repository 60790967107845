
import React from 'react'

import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'


export default function Home() {
  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
        <span className="text-2xl font-medium text-gray-900">Administración</span>
      </div>
    </PageLayout>
  )
}
