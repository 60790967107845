
import { area, percentage, price } from 'platform/filters'
import { webinterna } from 'services/discovery'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'


const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())


export let REF_FINCA_CATASTRAL = {
  loader: async (names) => {
    let { fincas } = await fincasCatastralesClient.BatchGet({ names })
    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales || []
    fincas = fincas.map(f => {
      let ep = elementosPatrimoniales.filter(ep => ep.fincaRegistral === f.fincaRegistral)
      return {
        ...f,
        descriptor: 'Finca catastral',
        label: f.nombre,
        elementoPatrimonial: ep.length ? ep[0] : null,
      }
    })
    return fincas
  },
  link: name => `/webinterna/fincas-catastrales/${name}`,
  label: 'Finca catastral',
  fields: {
    codigoPostal: {
      label: 'Código postal',
      type: 'text',
      wide: false,
      extractor: entity => entity.elementoPatrimonial?.codigoPostal,
    },
    denominacion: {
      label: 'Denominación',
      type: 'text',
      wide: false,
      extractor: entity => entity.nombre,
    },
    referenciaCatastral: {
      label: 'Referencia catastral',
      type: 'text',
      wide: false,
      extractor: entity => entity.referenciaCatastral,
    },
    superficieTotal: {
      label: 'Superficie Total',
      type: 'text',
      wide: false,
      extractor: entity => area(entity.superficieTotal),
    },
    superficieParcial: {
      label: 'Superficie Parcial',
      type: 'text',
      wide: false,
      extractor: entity => area(entity.superficieParcial),
    },
    tipoFinca: {
      label: 'Superficie Parcial',
      type: 'text',
      wide: false,
      extractor: entity => {
        const TIPOS = {
          TIPO_FINCA_URBANA: 'Urbana',
          TIPO_FINCA_RUSTICA: 'Rústica',
          TIPO_FINCA_ESPECIALES: 'Especiales',
        }
        return TIPOS[entity.tipoFinca]
      },
    },
    propiedadPercentage: {
      label: '% prop',
      type: 'text',
      wide: false,
      extractor: entity => percentage(entity.propiedadPercentage),
    },
    valorCatastral: {
      label: 'Valor catastral',
      type: 'text',
      wide: false,
      extractor: entity => price(entity.valorCatastral),
    },
  },
}
