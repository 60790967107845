// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: Docs/files/files.proto

import { Caller } from '@altipla/grpc-browser';


export default class FilesClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  Create(req) {
    return this._caller.send('POST', `/files`, req, true, []);
  }

  FinishUpload(req) {
    return this._caller.send('POST', `/files:finishUpload`, req, true, []);
  }

  Get(req) {
    return this._caller.send('GET', `/files`, req, false, []);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/files:batchGet`, req, true, []);
  }
};

