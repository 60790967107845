
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep, some, isEqual } from 'lodash-es'

import UsersClient from 'services/Users/users/users'
import { users } from 'services/discovery'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Face from 'components/people/Face'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonSecondaryDangerSm from 'components/buttons/ButtonSecondaryDangerSm'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import MultiSelector from 'components/simple-forms/MultiSelector'
import { TableRow, Cell, HeaderCell } from 'components/Table'
import CardTable from 'components/CardTable'
import { getEntity, putEntity, listEntities } from 'models/docs/entities'
import UserSelector from 'components/UserSelector'
import Checkbox from 'components/simple-forms/Checkbox'
import { filterActiveRoles, extractStatesFromRoles, ROLES } from 'models/docs/inboxes-groups'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'


const client = new UsersClient(users())


export default function InboxesGroupsEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [open, setOpen] = useState(false)
  let [inboxesGroup, setInboxesGroup] = useState({ users: [], states: [], roles: [] })
  let [form, setForm] = useState({ users: [], states: [], roles: [] })

  let [documentTypes, setDocumentTypes] = useState([])
  let [owners, setOwners] = useState([])
  let [users, setUsers] = useState([])

  useNavigate(async () => {
    let { users } = await client.List()
    setUsers(users)

    let documentTypes = await listEntities('document-types')
    setDocumentTypes(documentTypes?.map(docType => {
      return {
        value: docType.name,
        label: docType.displayName,
      }
    }))
    let owners = await listEntities('owners')
    let ownerList = await fetchPersonasSociedades(owners.map(o => o.owner))
    setOwners(owners?.map(owner => {
      return {
        value: owner.name,
        label: ownerList.resolve(owner.owner).nombre,
      }
    }))

    if (name) {
      let group = await getEntity(`inboxes-groups/${name}`)
      group.roles = filterActiveRoles(group.states).map(r => r.value)
      group.users = group.users?.map(user => users.find(u => u.name === user)) || []
      setForm(cloneDeep(group))
      setInboxesGroup(cloneDeep(group))
    }
  })

  async function save() {
    let group = cloneDeep(form)
    group.states = extractStatesFromRoles(group.roles)
    delete group.roles
    group.users = group.users.map(u => u.name)
    await putEntity('inboxes-groups', { inboxesGroup: group })
    history.push('/manage/inboxes-groups')
  }

  function addUser(user) {
    let selected = form.users
    selected.push(user)
    setForm({
      ...form,
      users: selected,
    })
  }

  function removeUser(user) {
    setForm({
      ...form,
      users: form.users.filter(u => u.name !== user.name),
    })
  }

  function updateRole(add, role) {
    let roles = form.roles
    if (add) {
      roles.push(role)
    } else {
      roles.splice(roles.indexOf(role), 1)
    }

    setForm({
      ...form,
      roles,
    })
  }

  function hasRole(role) {
    return form.roles?.includes(role)
  }

  let changed = some([
    form.displayName !== inboxesGroup.displayName,
    !isEqual(form.documentTypes, inboxesGroup.documentTypes),
    !isEqual(form.owners, inboxesGroup.owners),
    !isEqual(form.roles, inboxesGroup.roles),
    !isEqual(form.users, inboxesGroup.users),
  ]) && form.displayName
    && form.documentTypes?.length
    && form.owners?.length
    && form.roles?.length

  let selected = form.users?.map(u => u.name)
  let userOptions = users.filter(u => !selected.includes(u.name))

  return (
    <>
      <PageLayout
        breadcrumbs={
          <Breadcrumbs>
            <Breadcrumb url="/manage">Administración</Breadcrumb>
            <Breadcrumb url="/manage/inboxes-groups">Grupos de gestión</Breadcrumb>
            {name ?
              <Breadcrumb url={`/manage/inboxes-groups/${name}`}>{inboxesGroup.displayName}</Breadcrumb>
              :
              <Breadcrumb url="/manage/inboxes-groups/create">Crear grupo</Breadcrumb>
            }
          </Breadcrumbs>
        }
      >
        <CardHeader
          header="Datos generales"
          footer={
            <ButtonAsyncPrimary onClick={save} disabled={!changed}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          }
        >
          <Input
            className="mb-6"
            label="Nombre"
            value={form.displayName}
            onChange={value => {
              setForm({
                ...form,
                displayName: value,
              })
            }}
          ></Input>

          <MultiSelector
            label="Tipos de documentos"
            name="document-types"
            className="mb-6"
            value={form.documentTypes}
            options={documentTypes}
            onChange={value => {
              setForm({
                ...form,
                documentTypes: value,
              })
            }}
          ></MultiSelector>

          <MultiSelector
            label="Propietarios"
            name="owners"
            className="mb-6"
            value={form.owners}
            options={owners}
            onChange={value => {
              setForm({
                ...form,
                owners: value,
              })
            }}
          ></MultiSelector>

          <label className="block text-sm font-medium text-gray-700 mb-2">Roles</label>
          {ROLES.map(role => (
            <Checkbox
              key={role.value}
              name={role.value}
              label={role.label}
              value={hasRole(role.value)}
              onChange={checked => updateRole(checked, role.value)}
            ></Checkbox>
          ))}
        </CardHeader>

        <CardTable
          title="Usuarios"
          empty="No hay usuarios asignados"
          header={
            <>
              <HeaderCell className="w-full">Nombre</HeaderCell>
              <HeaderCell fit="right"></HeaderCell>
            </>
          }
          actions={
            <ButtonPrimary onClick={() => setOpen(true)}>
              <Icon solid name="plus" className="mr-2" />
              Añadir usuario
            </ButtonPrimary>
          }
        >
          {form.users?.map(user => (
            <TableRow key={user.name}>
              <Cell>
                <div className="flex-1 flex items-center min-w-0 relative">
                  <Face displayName={user.displayName} pictureUrl={user.pictureUrl} />
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900 truncate">{user.displayName}</div>
                    <div className="text-sm text-gray-500 truncate">{user.email}</div>
                  </div>
                </div>
              </Cell>
              <Cell fit="right">
                <ButtonSecondaryDangerSm className="mr-2" onClick={() => removeUser(user)}>
                  <Icon solid name="trash" />
                </ButtonSecondaryDangerSm>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
      </PageLayout>
      
      <UserSelector
        open={open}
        onClose={() => setOpen(false)}
        onSelect={$event => {
          addUser($event)
        }}
        users={userOptions}
        header="Añadir usuario"
      ></UserSelector>
    </>
  )
}
