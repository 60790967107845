
import React, { useEffect, useState } from 'react'

import SingleEntity from 'components/forms/SingleEntity'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import PlanesCuentasClient from 'services/WebInterna/planes_cuentas/planes_cuentas'
import ProcesosClient from 'services/WebInterna/procesos/procesos'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import People from 'components/forms/People'
import { webinterna } from 'services/discovery'
import Input from 'components/forms/Input'


const procesosClient = new ProcesosClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const planesCuentasClient = new PlanesCuentasClient(webinterna())


const listEntities = {
  REF_PROCESO: async () => {
    let reply = await procesosClient.List()
    return reply.procesos.map(e => {
      return {
        value: e.name,
        label: e.denominacion,
      }
    })
  },
  REF_PROYECTO: async () => {
    let reply = await proyectosClient.List()
    return reply.proyectos.map(e => {
      return {
        value: e.name,
        label: e.denominacion,
      }
    })
  },
  REF_INMUEBLE: async () => {
    let reply = await inmueblesClient.List()
    return reply.inmuebles.map(e => {
      return {
        value: e.name,
        label: e.nombre,
      }
    })
  },
  REF_FINCA_REGISTRAL: async () => {
    let reply = await fincasRegistralesClient.List()
    return reply.fincas.map(e => {
      return {
        value: e.name,
        label: e.numeroFinca,
      }
    })
  },
  REF_FINCA_CATASTRAL: async () => {
    let reply = await fincasCatastralesClient.List()
    return reply.fincas.map(e => {
      return {
        value: e.name,
        label: e.nombre,
      }
    })
  },
  REF_ELEMENTO_PATRIMONIAL: async () => {
    let reply = await elementosPatrimonialesClient.List()
    return reply.elementosPatrimoniales.map(e => {
      return {
        value: e.name,
        label: e.nombre,
      }
    })
  },
  REF_PLAN_CUENTAS: async () => {
    let reply = await planesCuentasClient.List()
    return reply.planesCuentas.map(e => {
      return {
        value: e.name,
        label: e.denominacion,
      }
    })
  },
}


function hasPersonSelector(ref) {
  return ref === 'REF_PERSONA' || ref === 'REF_SOCIEDAD' || ref === 'REF_CONTRIBUIDOR'
}


export function CustomFieldSelector({ form, name, validations, value, type, header, description }) {
  let [options, setOptions] = useState([])

  let entityName = name
  let descriptionName = ''
  let entityValue = value
  let descriptionValue
  if (description) {
    entityName = name + '.text'
    descriptionName = name + '.description'
    entityValue = value?.text
    descriptionValue = value?.description
  }

  let restrict
  if (hasPersonSelector(type)) {
    switch (type) {
    case 'REF_SOCIEDAD':
      restrict = 'SOCIEDAD'
      break
    case 'REF_CONTRIBUIDOR':
      restrict = 'CONTRIBUIDOR'
      break
    default:
      restrict = 'FISICA, JURIDICA, CONTRIBUIDOR'
    }
  }

  useEffect(() => {
    if (hasPersonSelector(type)) {
      return
    }
    async function getData() {
      setOptions(await listEntities[type]())
    }
    getData()
  }, [type])

  return (
    <div className="w-full flex gap-x-2">
      {hasPersonSelector(type) ?
        <People
          header={header}
          form={form}
          name={entityName}
          className="flex-grow"
          validations={validations}
          restrict={restrict}
          value={entityValue}
        />
        :
        <SingleEntity
          header={header}
          form={form}
          name={entityName}
          validations={validations}
          className="flex-grow"
          options={options}
          value={entityValue}
        />
      }
      {descriptionName &&
        <Input
          form={form}
          name={descriptionName}
          className="flex-grow"
          value={descriptionValue}
        ></Input>
      }
    </div>
  )
}
