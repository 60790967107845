
import React, { useEffect, useState } from 'react'

import ButtonPrimary from 'components/buttons/ButtonPrimary'
import Icon from 'components/Icon'
import { accountInfo } from 'auth/active-directory'
import { differenceInSeconds } from 'date-fns'


export function LogoutAlert() {
  let [show, setShow] = useState(false)

  useEffect(() => {
    let remaining = differenceInSeconds(accountInfo().expires, new Date())

    // Some margin to avoid redirects before the logout is applied to the cookies.
    remaining++

    setTimeout(() => setShow(true), remaining * 1000)
  }, [])

  if (!show) {
    return (<></>)
  }
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        {/* Center modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div>
            <div className="sm:mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Sesión finalizada</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Ha finalizado la sesión con su cuenta de usuario de Microsoft.
                  Por favor, inicie sesión de nuevo para continuar navegando.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-8">
            <ButtonPrimary onClick={() => window.location.reload()} className="w-full justify-center">
              <Icon regular className="mr-2" name="user-unlock" />
              Iniciar sesión
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  )
}
