
import React from 'react'
import Icon from './Icon'


export default function PageThrobber({ children }) {
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex justify-center mt-56">
        <div className="flex flex-col space-y-6 items-center">
          <Icon duotone spin name="spinner-third" className="fa-7x text-teal-300"></Icon>
          {children && <span className="text-2xl italic text-teal-500">{children}</span> }
        </div>
      </div>
    </div>
  )
}
