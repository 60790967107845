
import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'


export default function ButtonLinkPrimary({ children, className, to }) {
  return (
    <Link
      to={to}
      className={cx(
        'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 hover:text-white',
        className,
      )}
    >
      {children}
    </Link>
  )
}
