
import React from 'react'
import cx from 'classnames'

export default function BadgeBlue({ children, className }) {
  return (
    <span
      className={cx(
        'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-300 text-purple-900',
        className,
      )}
    >
      {children}
    </span>
  )
}
