
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'

import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import ExportCSV from 'components/ExportCSV'
import { price } from 'platform/filters'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { keyBy, uniq, cloneDeep } from 'lodash'


const sociedadesClient = new SociedadesClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())


export default function Sociedades() {
  let { table, setRows, useAsyncLoad, setSortOrders } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('denominacion'),
      },
    ])

    let { sociedades } = await sociedadesClient.List()
    sociedades = sociedades || []

    let names = sociedades
      .filter(s => s.municipio)
      .map(s => s.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(names) })
    if (!municipios) {
      municipios = []
    }

    names = municipios.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(names) })

    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')

    sociedades.forEach(sociedad => {
      sociedad.$search = [
        sociedad.denominacion,
      ]
      if (sociedad.municipio) {
        sociedad.municipio = cloneDeep(municipios[sociedad.municipio])
        sociedad.municipio.provincia = provincias[sociedad.municipio.provincia]
      }
    })

    setRows(sociedades)
  })

  function getSociedadesCSV(rows) {
    return rows.map(p => {
      return {
        'Tipo': p.tipo,
        'Denominación': p.denominacion,
        'Cif': p.cif,
        'Duración': p.duracion,
        'Gestión': p.gestion === 'GESTION_DIRECTA'
          ? 'Gestión directa' : p.gestion === 'GESTION_CONTROL'
            ? 'Control de gestión' : 'Gestión ajena', 
        'Grupo': p.grupo,
        'Subgrupo': p.subgrupo,
        'Email': p.email,
        'Teléfono': p.telefono,
        'Registro mercantil inscripción': p.registro?.inscripcion ? p.registro.inscripcion : '',
        'Registro tomo': p.registro?.tomo ? p.registro.tomo : '',
        'Registro libro': p.registro?.libro ? p.registro.libro : '',
        'Registro folio': p.registro?.folio ? p.registro.folio : '',
        'Registro hoja': p.registro?.hoja ? p.registro.hoja : '',
        'Registro sección': p.registro?.seccion ? p.registro.seccion : '',
        'Escritura título': p.escritura?.titulo ? p.escritura.titulo : '',
        'Número de socios': p.socios?.length ? p.socios.length : 0,
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={
          <>
            <Acl perm="webinterna.sociedades.propose-edit">
              <ButtonLinkPrimary to="/webinterna/sociedades/edit/new">
                <Icon solid name="plus" className="mr-2" />
                Crear Nueva Sociedad
              </ButtonLinkPrimary>
            </Acl>
            <ExportCSV
              className="ml-3"
              data={getSociedadesCSV(table.filteredRows)}
              filename="Sociedades"
            />
          </>
        }
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/sociedades">Sociedades</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell fit="right">Acrónimo</HeaderCell>
            <HeaderCell>CIF</HeaderCell>
            <HeaderCell expand>Denominación</HeaderCell>
            <HeaderCell>Tipo sociedad</HeaderCell>
            <HeaderCell>Capital social</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/sociedades/${row.name}`}>
            <div>
              <span className="font-medium text-gray-900">{row.denominacion}</span>
            </div>
          </RowMobile>
        }
        row={row => (
          <>
            <Cell fit="right" className="text-right">{row.acronimo}</Cell>
            <Cell>
              {row.cif}
            </Cell>
            <Cell>
              <div>
                <Link
                  to={`/webinterna/sociedades/${row.name}`}
                  className="font-medium text-gray-900"
                >
                  {row.denominacion}
                </Link>
              </div>
              {row.direccion && row.municipio && row.codigoPostal && row.municipio.provincia &&
                <div>
                  {`${row.direccion}, ${row.municipio?.nombre} (${row.municipio?.provincia?.nombre}) ${row.codigoPostal}`}
                </div>
              }
            </Cell>
            <Cell className="font-medium">
              {row.tipo}
            </Cell>
            <Cell>
              {price(row.capitalSocial)}
            </Cell>
            <Cell className="text-right">
              <Acl perm="webinterna.sociedades.propose-edit">
                <Link
                  to={`/webinterna/sociedades/edit/${row.name}`}
                >
                  Editar
                </Link>
              </Acl>
            </Cell>
          </>
        )}
      />
    </PageLayout>
  )
}
