
import { useEffect } from 'react'
import { useForm as useReactForm } from 'react-hook-form'
import { get } from 'lodash-es'

const EMAIL_RE = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
const URL_RE = /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i
const DNI_RE = /^\d{8}[A-Z]$/i
const DNI_TABLE = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']

const MESSAGES = {
  required: () => `Este campo es un valor requerido`,
  minLength: checks => `Este campo necesita al menos ${checks.minLength} caracteres`,
  maxLength: checks => `Este campo puede tener como máximo ${checks.maxLength} caracteres`,
  url: () => `Este campo debe contener un link válido a una página o URL de Internet`,
  email: () => `Este campo debe ser una dirección de email válida`,
  dni: () => 'Este campo debe contener un DNI completo con letra mayúscula',
  cif: () => 'Este campo debe contener un CIF completo con letra mayúscula',
  pattern: () => `Este campo debe cumplir con las indicaciones recomendadas en la ayuda`,
  min: checks => `Este campo debe ser un número mayor que ${checks.min}`,
  max: checks => `Este campo debe ser un número menor que ${checks.max}`,
}


export function useForm(submit) {
  return {
    ...useReactForm(),
    submit,
  }
}


export function useField({ form, name, validations, value, onChange }) {
  let { register, unregister, setValue, formState, errors } = form

  useEffect(() => {
    let checks = parseShortValidations(validations)
    register({ name }, checks)
    
    return () => unregister(name)
  }, [name, validations, register, unregister])
  
  useEffect(() => {
    setValue(name, value)
  }, [name, value, setValue])

  return {
    get errorMessage() {
      let checks = parseShortValidations(validations)
      return validationMessage(checks, get(errors, name))
    },

    get hasError() {
      return formState.isSubmitted && get(errors, name)
    },

    setValue(value) {
      setValue(name, value, { shouldValidate: true, shouldDirty: true })
      if (onChange) {
        onChange(value)
      }
    },

    get isRequired() {
      let checks = parseShortValidations(validations)
      return checks && checks.required
    },
  }
}


export function parseShortValidations(validations) {
  if (!validations) {
    return undefined
  }

  let checks = {}
  validations
    .split('|')
    .forEach(validation => {
      if (validation.startsWith('min:')) {
        checks.minLength = parseInt(validation.substring('min:'.length), 10)
      } else if (validation.startsWith('max:')) {
        checks.maxLength = parseInt(validation.substring('max:'.length), 10)
      } else if (validation.startsWith('minNumber:')) {
        checks.min = parseInt(validation.substring('minNumber:'.length, 15))
      } else if (validation.startsWith('maxNumber:')) {
        checks.max = parseInt(validation.substring('maxNumber:'.length, 15))
      } else {
        switch (validation) {
        case 'required':
          checks.required = true
          break
        case 'url':
          checks.pattern = URL_RE
          break
        case 'email':
          checks.pattern = EMAIL_RE
          break
        case 'dni':
          checks.validate = {
            ...checks.validate,
            dni: dni => {
              if (!DNI_RE.test(dni)) {
                return false
              }
              return DNI_TABLE[parseInt(dni.slice(0, 8), 10) % 23] === dni.slice(8, 9)
            },
          }
          break
        case 'cif':
          checks.validate = {
            ...checks.validate,
            cif: cif => {
              if (!cif || cif.length !== 9) {
                return false
              }
            
              let letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
              let digits = cif.substr(1, cif.length - 2)
              let letter = cif.substr(0, 1)
              let control = cif.substr(cif.length - 1)
              let sum = 0
              let i
              let digit
            
              if (!letter.match(/[A-Z]/)) {
                return false
              }
            
              for (i = 0; i < digits.length; ++i) {
                digit = parseInt(digits[i])
                if (isNaN(digit)) {
                  return false
                }
                if (i % 2 === 0) {
                  digit *= 2
                  if (digit > 9) {
                    digit = parseInt(digit / 10) + (digit % 10)
                  }
                  sum += digit
                } else {
                  sum += digit
                }
              }
            
              sum %= 10
              if (sum !== 0) {
                digit = 10 - sum
              } else {
                digit = sum
              }
            
              if (letter.match(/[ABEH]/)) {
                return String(digit) === control
              }
              if (letter.match(/[NPQRSW]/)) {
                return letters[digit] === control
              }
            
              return String(digit) === control || letters[digit] === control
            },
          }
          break
        default:
          throw new Error(`unknown input validation: ${validation}`)
        }
      }
    })
  return checks
}


export function validationMessage(checks, error) {
  if (!error) {
    return undefined
  }

  if (error.type === 'pattern') {
    switch (checks.pattern) {
    case EMAIL_RE:
      error.type = 'email'
      break
    case URL_RE:
      error.type = 'url'
      break
    default:
    }
  }

  if (error.type === 'validate') {
    if (checks.validate.dni) {
      error.type = 'dni'
    }
  }

  if (!MESSAGES[error.type]) {
    throw new Error(`validation ${error.type} does not have the error message configured correctly`)
  }
  return MESSAGES[error.type](checks)
}


// TODO(ernesto): Esta función debe desaparecer a cambio de form.hasError
export function hasError(form, name) {
  return form.formState.isSubmitted && form.errors[name]
}
