
import React from 'react'
import cx from 'classnames'


export default function ButtonSecondaryDangerSm({ children, onClick, className }) {
  return (
    <>
      <button
        type="button"
        className={cx('inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', className)}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  )
}
