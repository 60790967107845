
import { keyBy, uniq } from 'lodash-es'

import { batchGetPersonas } from 'models/webinterna/personas'
import { webinterna } from 'services/discovery'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import CentrosTrabajoClient from 'services/WebInterna/centros_trabajo/centros_trabajo'


const provinciasClient = new ProvinciasClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const centrosTrabajoClient = new CentrosTrabajoClient(webinterna())


export let REF_CONTRIBUIDOR = {
  loader: async (names) => {
    let contribuidores = await batchGetPersonas(names)
    contribuidores = Object.values(contribuidores)
    let municipiosNames = contribuidores
      .filter(p => p.municipio)
      .map(p => p.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(municipiosNames) })
    let provinciasNames = municipios?.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(provinciasNames) })
    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    let centrosTrabajoNames = contribuidores
      .filter(c => c.fisica.contribuidor.centroTrabajo)
      .map(c => c.fisica.contribuidor.centroTrabajo)
    let { centros } = await centrosTrabajoClient.BatchGet({ names: uniq(centrosTrabajoNames) })
    centros = keyBy(centros, 'name')

    contribuidores = contribuidores.map(c => {
      return {
        ...c,
        municipio: municipios[c.municipio],
        provincia: provincias[municipios[c.municipio].provincia],
        centroTrabajo: centros[c.fisica.contribuidor.centroTrabajo],
        label: c.nombre,
        descriptor: 'Contribuidor',
      }
    })
    return contribuidores
  },
  link: name => `/webinterna/personas/${name}`,
  label: 'Contribuidor',
  fields: {
    identidad: {
      label: 'Identidad',
      type: 'text',
      wide: false,
      extractor: entity => entity.identidad,
    },
    nombre: {
      label: 'Nombre',
      type: 'text',
      wide: false,
      extractor: entity => entity.nombre,
    },
    email: {
      label: 'Email',
      type: 'text',
      wide: false,
      extractor: entity => entity.email,
    },
    telefono: {
      label: 'Teléfono',
      type: 'text',
      wide: false,
      extractor: entity => entity.fisica.contribuidor.telefonoEmpresa,
    },
    calle: {
      label: 'Calle',
      type: 'text',
      wide: false,
      extractor: entity => entity.calle,
    },
    provincia: {
      label: 'Provincia',
      type: 'text',
      wide: false,
      extractor: entity => entity.provincia.nombre,
    },
    codigoPostal: {
      label: 'Código Postal',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigoPostal,
    },
    pais: {
      label: 'País',
      type: 'text',
      wide: false,
      extractor: entity => entity.pais,
    },
    centroTrabajo: {
      label: 'Centro de trabajo',
      type: 'text',
      wide: false,
      extractor: entity => entity.centroTrabajo.nombre,
    },
  },
}
