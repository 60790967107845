
import { keyBy, uniq } from 'lodash-es'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'


const provinciasClient = new ProvinciasClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())


export let REF_INMUEBLE = {
  loader: async names => {
    let { inmuebles } = await inmueblesClient.BatchGet({ names })
    inmuebles = inmuebles || []
    let municipiosNames = inmuebles
      .filter(i => i.municipio)
      .map(i => i.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(municipiosNames) })
    let provinciasNames = municipios?.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(provinciasNames) })
    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')
    inmuebles = inmuebles.map(i => {
      return {
        ...i,
        label: i.nombre,
        municipio: municipios[i.municipio],
        provincia: provincias[municipios[i.municipio].provincia],
        descriptor: 'Inmueble',
      }
    })
    return inmuebles
  },
  link: name => `/webinterna/inmuebles/${name}`,
  label: 'Inmueble',
  fields: {
    codigo: {
      label: 'Código',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigo,
    },
    denominacion: {
      label: 'Denominacion',
      type: 'text',
      wide: false,
      extractor: entity => entity.nombre,
    },
    titular: {
      label: 'Código',
      type: 'persona',
      wide: true,
      extractor: entity => entity.titular,
    },
    clase: {
      label: 'Clase',
      type: 'text',
      wide: false,
      extractor: entity => {
        const CLASES = {
          CLASE_RUSTICO: 'Rústico',
          CLASE_URBANIZABLE: 'Urbanizable',
          CLASE_URBANO: 'Urbano',
          CLASE_EDIFICACION: 'Edificación',
        }
        return CLASES[entity.clase]
      },
    },
    usoUrbanistico: {
      label: 'Uso urbanístico',
      type: 'text',
      wide: false,
      extractor: entity => {
        const USOS = {
          USO_URBANISTICO_RESIDENCIAL: 'Residencial',
          USO_URBANISTICO_COMERCIAL: 'Comercial',
          USO_URBANISTICO_RESIDENCIAL_COMERCIAL: 'Residencial Comercial',
          USO_URBANISTICO_INDUSTRIAL: 'Industrial',
          USO_URBANISTICO_LOGISTICO: 'Logístico',
          USO_URBANISTICO_HOTELERO: 'Hotelero',
          USO_URBANISTICO_OFICINAS: 'Oficinas',
          USO_URBANISTICO_INSTALACIONES_AGRICOLAS: 'Instalaciones agrícolas',
          USO_URBANISTICO_OTROS_USOS: 'Otros usos',
        }
        return USOS[entity.usoUrbanistico]
      },
    },
    estado: {
      label: 'Estado',
      type: 'text',
      wide: false,
      extractor: entity => {
        const ESTADOS = {
          ESTADO_PROYECTO: 'Proyecto',
          ESTADO_ACTIVO: 'Activo',
          ESTADO_TERMINADO: 'Terminado',
          ESTADO_ENAJENADO: 'Enajenado',
        }
        return ESTADOS[entity.estado]
      },
    },
    provincia: {
      label: 'Provincia',
      type: 'text',
      wide: false,
      extractor: entity => entity.provincia.nombre,
    },
  },
}
