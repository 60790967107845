
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep, some } from 'lodash-es'
import cx from 'classnames'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { webinterna } from 'services/discovery'
import GruposInmueblesClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'


const client = new GruposInmueblesClient(webinterna())


export default function GruposInmueblesEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [grupo, setGrupo] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await client.Get({ name })
      setGrupo(cloneDeep(reply))
      setForm(reply)
    }
  })

  async function save() {
    let req = cloneDeep(form)
    if (name) {
      let reply = await client.Update(req)
      setGrupo(reply)
      setForm(reply)
    } else {
      let reply = await client.Create(req)
      history.push('/manage/grupos-inmobiliarios/' + reply.name)
    }
  }
    
  async function deleteGrupoInmueble() {
    await client.Delete(grupo)
    history.push('/manage/grupos-inmobiliarios')
  }
        
  let hasChanged = some([
    grupo.nombre !== form.nombre,
  ]) && form.nombre
  
  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/grupos-inmobiliarios">Grupos inmobiliarios</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/grupos-inmobiliarios/${name}`}>{grupo.nombre}</Breadcrumb>
            :
            <Breadcrumb url="/manage/grupos-inmobiliarios/create">Crear grupo</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header={name ? 'Editar grupo' : 'Crear grupo'}
        subheader={name ? 'Edita un grupo inmobiliario.' : 'Registra un nuevo grupo inmobiliario.'}
        footer={
          <div
            className={cx(
              'flex justify-end w-full',
              {
                'justify-between': name,
              },
            )}
          >
            {name &&
              <ButtonAsyncDanger
                onClick={deleteGrupoInmueble}
                disabled={grupo.inmuebles?.length}
              >
                <Icon solid name="trash" className="mr-2" />
                Eliminar grupo
              </ButtonAsyncDanger>
            }
            <ButtonAsyncPrimary onClick={save} disabled={!hasChanged}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Input
          label="Nombre"
          type="text"
          value={form.nombre}
          onChange={value => {
            setForm({
              ...form,
              nombre: value,
            })
          }}
        ></Input>
      </CardHeader>
    </PageLayout>
  )
}
