
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'
import { keyBy } from 'lodash-es'
import resolver from 'platform/resolver'

import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { webinterna } from 'services/discovery'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import { formatMoney } from 'platform/money'
import EntityLink from 'components/EntityLink'
import ExportCSV from 'components/ExportCSV'

const proyectosClient = new ProyectosClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())

const TYPES = {
  TIPO_A: 'Tipo A',
  TIPO_B: 'Tipo B',
  TIPO_C: 'Tipo C',
  TIPO_D: 'Tipo D',
}

const TIPOS_CONTABLES = {
  TIPO_CONTABLE_EXISTENCIAS_EN_CURSO: 'Existencias en curso',
  TIPO_CONTABLE_INMOBILIZADO_EN_CURSO: 'Inmobilizado en curso',
  TIPO_CONTABLE_EXISTENCIAS_FINALIZADO: 'Existencias finalizado',
  TIPO_CONTABLE_INMOBILIZADO_FINALIZADO: 'Inmobilizado finalizado',
}

const TIPOS_GESTION = {
  GESTION_NO_PRESENTADO: 'No presentada',
  GESTION_EN_TRAMITACION: 'En tramitación',
  GESTION_CONCEDIDA: 'Concedida',
  GESTION_NO_INICIADA: 'No iniciada',
  GESTION_EN_CURSO: 'En curso',
  GESTION_TERMINADA_EN_GARANTIA: 'Terminada en garantía',
  GESTION_CERRADA: 'Cerrada',
  GESTION_FINALIZADO: 'Finalizado',
}


export default function ProyectosList() {
  let { table, setRows, useAsyncLoad, setSortOrders } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('denominacion'),
      },
    ])

    let { proyectos, sociedades } = await resolver.all({
      proyectos: proyectosClient.List().then(reply => reply.proyectos || []),
      sociedades: sociedadesClient.List().then(reply => reply.sociedades || []),
    })

    sociedades = keyBy(sociedades, 'name')

    proyectos.forEach(proyecto => {
      proyecto.promotor = sociedades[proyecto.promotor]

      proyecto.$search = [
        proyecto.denominacion,
        proyecto.codigo,
        proyecto.promotor?.denominacion,
        TYPES[proyecto.tipo],
      ]
    })

    setRows(proyectos)
  })

  function getProyectosCSV(rows) {
    return rows.map(p => {
      return {
        'Código': p.codigo,
        'Denominación': p.denominacion,
        'Tipo': TYPES[p.tipo],
        'Estado': p.estado === 'ESTADO_EN_CURSO' ? 'En curso' : 'Finalizado',
        'Cuenta contable': p.cuentaContable,
        'Ordenanza general': p.ordenanzaGeneral,
        'Tipo contable': TIPOS_CONTABLES[p.tipoContable],
        'Licencia': TIPOS_GESTION[p.licencia?.gestion],
        'Licitación': TIPOS_GESTION[p.licitacion?.gestion],
        'Obras': TIPOS_GESTION[p.obras?.gestion],
        'Legalización entrega': TIPOS_GESTION[p.legalizacionEntrega?.gestion],
        'Proyecto básico': TIPOS_GESTION[p.proyectoBasico?.gestion],
        'Proyecto edificación': TIPOS_GESTION[p.proyectoEdificacion?.gestion],
        'Promotor denominación': p.promotor?.denominacion,
        'Promotor CIF': p.promotor?.cif,
        'Promotor email': p.promotor?.email,
        'Promotor duracion': p.promotor?.duracion,
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={
          <>
            <Acl perm="webinterna.proyectos.propose-edit">
              <ButtonLinkPrimary to="/webinterna/proyectos/edit/new">
                <Icon solid name="plus" className="mr-2" />
                Crear Nuevo Proyecto
              </ButtonLinkPrimary>
            </Acl>
            <ExportCSV
              className="ml-3"
              data={getProyectosCSV(table.filteredRows)}
              filename="Proyectos"
            />
          </>
        }
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/proyectos">Proyectos</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell fit="right">Código</HeaderCell>
            <HeaderCell expand>Denominación</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Promotor</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell>Costes totales</HeaderCell>
            <HeaderCell>Margen bruto % s/C</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/proyectos/${row.name}`}>
            <div>
              {row.codigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.denominacion}</span>
            </div>
            <div className="mt-1">
              {TYPES[row.tipo]}
            </div>
            <div className="mt-1">
              <Icon solid name="user-hard-hat" className="mr-3 text-gray-400" />
              {row.promotor?.denominacion}
            </div>
          </RowMobile>
        }
        row={row => (
          <>
            <Cell fit="right" className="font-medium text-right">
              {row.codigo}
            </Cell>
            <Cell wrap>
              <Link
                to={`/webinterna/proyectos/${row.name}`}
                className="font-medium text-gray-900"
              >
                {row.denominacion}
              </Link>
            </Cell>
            <Cell>
              {TYPES[row.tipo]}
            </Cell>
            <Cell>
              {row.promotor &&
                <EntityLink to={`/webinterna/sociedades/${row.promotor.name}`}>
                  {row.promotor.denominacion}
                </EntityLink>
              }
            </Cell>
            <Cell>
              {row.estado === 'ESTADO_EN_CURSO' && <BadgeGreen>En curso</BadgeGreen>}
              {row.estado === 'ESTADO_FINALIZADO' && <BadgeGray>Finalizado</BadgeGray>}
            </Cell>
            <Cell className="text-right">
              {formatMoney(null)}
            </Cell>
            <Cell className="text-right">
              {formatMoney(null)}
            </Cell>
            <Cell className="text-right">
              <Acl perm="webinterna.proyectos.propose-edit">
                <Link
                  to={`/webinterna/proyectos/edit/${row.name}`}
                >
                  Editar
                </Link>
              </Acl>
            </Cell>
          </>
        )}
      />
    </PageLayout>
  )
}
