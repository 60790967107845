
import React from 'react'

import { useField } from 'platform/forms'
import PeopleSelector from 'components/people/PeopleSelector'


export default function People({ form, name, label, header, value, onChange, className, validations, restrict }) {
  let field = useField({ form, name, validations, value, onChange })

  return (
    <PeopleSelector
      label={label}
      header={header}
      required={field.isRequired}
      value={value}
      className={className}
      onChange={value => {
        field.setValue(value)
        if (onChange) {
          onChange(value)
        }
      }}
      restrict={restrict}
    />
  )
}
