
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import { parseDate, serializeDate } from 'platform/datetime'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Locality from 'components/forms/Locality'
import HtmlEditor from 'components/forms/HtmlEditor'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Datepicker from 'components/forms/Datepicker'
import SingleEntity from 'components/forms/SingleEntity'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import GruposInmobiliariosClient from 'services/WebInterna/grupos_inmobiliarios/grupos_inmobiliarios'
import Percent from 'components/forms/Percent'
import InputUrl from 'components/forms/InputUrl'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'
import InputAddon from 'components/forms/InputAddon'
import People from 'components/forms/People'
import { useNavigate } from 'hooks/navigate'


const inmueblesClient = new InmueblesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const gruposInmobiliariosClient = new GruposInmobiliariosClient(webinterna())
const registrosPropiedadClient = new RegistrosPropiedadClient(webinterna())

const TIPOS_CLASES = {
  CLASE_RUSTICO: 'A',
  CLASE_URBANIZABLE: 'B',
  CLASE_URBANO: 'C',
  CLASE_EDIFICACION: 'D',
}


function useInmueble({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [inmueble, setInmueble] = useState({})
  let [estadoJunta, setEstadoJunta] = useState('')
  let [validation, setValidation] = useState(null)
  let [prefix, setPrefix] = useState(null)

  async function load() {
    if (!isEdit) {
      return
    }

    let reply = await inmueblesClient.Get({ name })
    reply.altaJuridicaDate = parseDate(reply.altaJuridicaDate)
    reply.bajaJuridicaDate = parseDate(reply.bajaJuridicaDate)
    reply.bajaDate = parseDate(reply.bajaDate)
    reply.altaDate = parseDate(reply.altaDate)
    setEstadoJunta(reply.estadoJuntaCompensacion)
    setInmueble(reply)
  }

  async function loadValidation(inmuebles) {
    let reply = await validacionesClient.Get({ name: name })

    reply.entidad.inmueble.altaJuridicaDate = parseDate(reply.entidad.inmueble.altaJuridicaDate)
    reply.entidad.inmueble.bajaJuridicaDate = parseDate(reply.entidad.inmueble.bajaJuridicaDate)
    reply.entidad.inmueble.bajaDate = parseDate(reply.entidad.inmueble.bajaDate)
    reply.entidad.inmueble.altaDate = parseDate(reply.entidad.inmueble.altaDate)

    if (reply.accion === 'ACCION_CREAR') {
      if (!reply.entidad.inmueble.codigo) {
        let prefix = TIPOS_CLASES[reply.entidad.inmueble.clase]
        setPrefix(prefix)
        let year = reply.entidad.inmueble.altaJuridicaDate.getFullYear().toString().slice(2, 4)
        let codigos = inmuebles
          .filter(uin => uin.codigo.slice(0, 1) === prefix && uin.codigo.slice(1, 3) === year)
          .map(uin => uin.codigo?.slice(3))
          .sort()
        let maxCodigo = codigos.length ? codigos[codigos.length - 1] : '0'
        let newCodigo = parseInt(maxCodigo) + 1
        newCodigo = newCodigo.toString().padStart(2, '0')
        reply.entidad.inmueble.codigo = year + newCodigo
      } else {
        setPrefix(reply.entidad.inmueble.codigo.substring(0, 1))
        reply.entidad.inmueble.codigo = reply.entidad.inmueble.codigo.substring(1)
      }
    }
    
    setInmueble(reply.entidad.inmueble)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        inmueble: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este inmueble para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/inmuebles/${name}`)
    } else {
      history.push(`/webinterna/inmuebles`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await inmueblesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await inmueblesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await inmueblesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        inmueble: {
          ...inmueble,
          altaJuridicaDate: serializeDate(inmueble.altaJuridicaDate),
          bajaJuridicaDate: serializeDate(inmueble.bajaJuridicaDate),
          bajaDate: serializeDate(inmueble.bajaDate),
          altaDate: serializeDate(inmueble.altaDate),
        },
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este inmueble para que lo apruebe un administrador.')
    history.push(`/webinterna/inmuebles`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    inmueble,
    setInmueble,
    validation,
    remove,
    prefix,
    setPrefix,
    estadoJunta,
    setEstadoJunta,
  }
}

export default function InmueblesEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [proyectos, setProyectos] = useState(null)
  let [grupos, setGrupos] = useState(null)
  let [inmuebles, setInmuebles] = useState(null)
  let [registros, setRegistros] = useState(null)

  let {
    save: saveInmueble,
    load: loadInmueble,
    setInmueble,
    loadValidation,
    confirmValidation,
    inmueble,
    validation,
    remove: removeInmueble,
    prefix,
    setPrefix,
    estadoJunta,
    setEstadoJunta,
  } = useInmueble({ name })

  useNavigate(async () => {
    let { proyectos } = await proyectosClient.List()
    proyectos = proyectos || []
    proyectos = proyectos.map(proyecto => {
      return {
        value: proyecto.name,
        url: `/webinterna/proyectos/${proyecto.name}`,
        label: proyecto.denominacion,
        sublabel: proyecto.codigo,
      }
    })
    setProyectos(proyectos)

    let { registros } = await registrosPropiedadClient.List()
    registros = registros || []
    registros = registros.map(registro => {
      return {
        value: registro.name,
        url: `/manage/registros-propiedad/${registro.name}`,
        label: registro.nombre,
      }
    })
    setRegistros(registros)

    let { grupos } = await gruposInmobiliariosClient.List()
    grupos = grupos || []
    grupos = grupos.map(grupoInmobiliario => {
      return {
        value: grupoInmobiliario.name,
        url: `/manage/grupos-inmuebles/${grupoInmobiliario.name}`,
        label: grupoInmobiliario.nombre,
      }
    })
    setGrupos(grupos)

    let { inmuebles } = await inmueblesClient.List()
    inmuebles = inmuebles || []
    setInmuebles(inmuebles.map(i => {
      return {
        codigo: i.codigo,
        value: i.name,
        label: i.nombre,
        url: `/webinterna/inmuebles/${i.name}`,
      }
    }))
    
    if (isValidation) {
      await loadValidation(inmuebles)
    } else {
      await loadInmueble()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...inmueble,
      ...data,
      altaJuridicaDate: serializeDate(data.altaJuridicaDate),
      bajaJuridicaDate: serializeDate(data.bajaJuridicaDate),
      bajaDate: serializeDate(data.bajaDate),
      altaDate: serializeDate(data.altaDate),
    }

    let prefix = TIPOS_CLASES[req.clase]

    if (data.codigo && (!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR'))) {
      req.codigo = prefix + data.codigo
    }

    req.estado = req.estado || 'ESTADO_UNKNOWN'
    req.estadoJuntaCompensacion = req.estadoJuntaCompensacion || 'ESTADO_JUNTA_COMPENSACION_UNKNOWN'
    req.estadoObra = req.estadoObra || 'ESTADO_OBRA_UNKNOWN'
    req.usoUrbanistico = req.usoUrbanistico || 'USO_URBANISTICO_UNKNOWN'
    
    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveInmueble(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !inmueble.blocked &&
            <ButtonConfirmDanger onClick={removeInmueble}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Unidad Inmobiliaria
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/inmuebles">Unidades Inmobiliarias</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/inmuebles/${name}`}>Consultar Unidad Inmobiliaria</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/inmuebles/edit/${name}`}>Editar Unidad Inmobiliaria</Breadcrumb>
          : <Breadcrumb url="/webinterna/inmuebles/edit/new">Crear Nueva Unidad Inmobiliaria</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={inmueble.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:gap-x-4 mb-6">
              {(!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR')) &&
                <InputAddon
                  form={form}
                  name="codigo"
                  type="number"
                  label="Código"
                  validations="minNumber:1"
                  value={inmueble.codigo}
                  disabled={isValidation}
                  className="w-32"
                  addonText={prefix}
                />
              }
              <Input form={form} name="nombre" label="Denominación" validations="required|min:3" value={inmueble.nombre} className="flex-grow" />
            </div>
            <div className="md:flex md:space-x-4 mb-6">
              <div className="w-1/2">
                <People
                  className="flex-grow"
                  form={form}
                  name="titular"
                  label="Titular"
                  validations="required"
                  restrict="FISICA, JURIDICA, CONTRIBUIDOR, SOCIEDAD"
                  value={inmueble.titular}
                />
              </div>
                
              <SingleEntity
                className="flex-grow"
                form={form}
                name="grupo"
                label="Grupo Inmobiliario"
                header="Seleccionar grupo inmobiliario"
                options={grupos}
                value={inmueble.grupo}
              />
            </div>
            <div className="lg:flex lg:space-x-4 mt-4 md:grid md:grid-cols-2 mb-6">
              <Datepicker
                form={form}
                name="altaJuridicaDate"
                label="Fecha Alta Jurídica"
                value={inmueble.altaJuridicaDate}
                validations="required"
              />
              <Datepicker form={form} name="bajaJuridicaDate" label="Fecha Baja Jurídica" value={inmueble.bajaJuridicaDate} />
              <Datepicker form={form} name="altaDate" label="Fecha Alta" value={inmueble.altaDate} />
              <Datepicker form={form} name="bajaDate" label="Fecha Baja" value={inmueble.bajaDate} />
            </div>

            <div className="flex mb-6 gap-x-4">
              {!isEdit &&
                <Select
                  form={form}
                  name="clase"
                  label="Clase"
                  validations="required"
                  options={[
                    { value: 'CLASE_RUSTICO', label: 'Rústico' },
                    { value: 'CLASE_URBANIZABLE', label: 'Urbanizable' },
                    { value: 'CLASE_URBANO', label: 'Urbano' },
                    { value: 'CLASE_EDIFICACION', label: 'Edificación' },
                  ]}
                  onChange={value => {
                    setInmueble(
                      {
                        ...inmueble,
                        clase: value,
                      },
                    )
                    setPrefix(TIPOS_CLASES[value])
                  }}
                  value={inmueble.clase ? inmueble.clase : ''}
                />
              }
              {inmueble.clase !== 'CLASE_RUSTICO' &&
                <Select
                  form={form}
                  name="usoUrbanistico"
                  label="Uso urbanístico"
                  options={[
                    { label: 'Residencial', value: 'USO_URBANISTICO_RESIDENCIAL' },
                    { label: 'Comercial', value: 'USO_URBANISTICO_COMERCIAL' },
                    { label: 'Residencial y Comercial', value: 'USO_URBANISTICO_RESIDENCIAL_COMERCIAL' },
                    { label: 'Industrial', value: 'USO_URBANISTICO_INDUSTRIAL' },
                    { label: 'Logístico', value: 'USO_URBANISTICO_LOGISTICO' },
                    { label: 'Hotelero', value: 'USO_URBANISTICO_HOTELERO' },
                    { label: 'Oficinas', value: 'USO_URBANISTICO_OFICINAS' },
                    { label: 'Instalaciones Agrícolas', value: 'USO_URBANISTICO_INSTALACIONES_AGRICOLAS' },
                    { label: 'Otros usos', value: 'USO_URBANISTICO_OTROS_USOS' },
                  ]}
                  value={inmueble.usoUrbanistico ? inmueble.usoUrbanistico : ''}
                />
              }
              <SingleEntity
                className="flex-grow"
                form={form}
                name="origen"
                label="Origen"
                header="Seleccionar origen"
                options={inmuebles}
                value={inmueble.origen}
              />
              <Select
                form={form}
                name="estado"
                label="Estado"
                options={[
                  { value: 'ESTADO_ACTIVO', label: 'Activo' },
                  { value: 'ESTADO_PROYECTO', label: 'Proyecto' },
                  { value: 'ESTADO_TERMINADO', label: 'Terminado' },
                  { value: 'ESTADO_ENAJENADO', label: 'Enajenado' },
                ]}
                value={inmueble.estado ? inmueble.estado : ''}
              />
              <Select
                form={form}
                name="estadoObra"
                label="Estado obra"
                options={[
                  { value: 'ESTADO_OBRA_NO_INICIADA', label: 'No iniciada' },
                  { value: 'ESTADO_OBRA_EN_CURSO', label: 'En curso' },
                  { value: 'ESTADO_OBRA_COMPLETADA', label: 'Completada' },
                ]}
                value={inmueble.estadoObra ? inmueble.estadoObra : ''}
              />
            </div>

            <div className="flex mb-6 space-x-4">
              <SingleEntity
                className="flex-grow"
                form={form}
                name="proyecto"
                label="Proyecto"
                header="Seleccionar proyecto"
                options={proyectos}
                value={inmueble.proyecto}
              />
              <SingleEntity
                className="flex-grow"
                form={form}
                name="registroPropiedad"
                label="Registro de la Propiedad"
                header="Seleccionar registro de la propiedad"
                options={registros}
                value={inmueble.registroPropiedad}
              />
              <Locality
                form={form}
                name="municipio"
                label="Municipio"
                value={inmueble.municipio}
              ></Locality>
            </div>

            <div className="flex mb-6 space-x-4">
              <div className="flex-grow">
                <People
                  form={form}
                  name="juntaCompensacion"
                  label="Junta de compensación"
                  restrict="FISICA, JURIDICA, CONTRIBUIDOR"
                  value={inmueble.juntaCompensacion}
                />
              </div>
              <div className="flex-grow">
                <People
                  form={form}
                  name="comunidad"
                  label="Comunidad"
                  restrict="FISICA, JURIDICA, CONTRIBUIDOR"
                  value={inmueble.comunidad}
                />
              </div>
            </div>
            <div className="flex mb-6 space-x-4">
              <Input form={form} name="coordenadas" label="Coordenadas" value={inmueble.coordenadas} className="flex-grow" />
              <Select
                form={form}
                name="estadoJuntaCompensacion"
                label="Estado Junta Compensación"
                options={[
                  { value: 'ESTADO_JUNTA_COMPENSACION_NO_CONSTITUIDA', label: 'No constituida' },
                  { value: 'ESTADO_JUNTA_COMPENSACION_CONSTITUIDA_LIQUIDADA', label: 'Constituida y liquidada' },
                ]}
                value={inmueble.estadoJuntaCompensacion ? inmueble.estadoJuntaCompensacion : ''}
                onChange={setEstadoJunta}
              />
              {estadoJunta === 'ESTADO_JUNTA_COMPENSACION_CONSTITUIDA_LIQUIDADA' && 
                <>
                  <Percent form={form} name="derechoVotoPercentage" label="% voto" validations="required" value={inmueble.derechoVotoPercentage}/>
                  <Percent form={form} name="participacionPercentage" label="% particip" validations="required" value={inmueble.participacionPercentage}/>
                  <Percent form={form} name="participacionCostesPercentage" label="% costes" validations="required" value={inmueble.participacionCostesPercentage}/>
                </>
              }
            </div>

            <div className="flex mb-6 space-x-4">
              <InputUrl className="flex-grow" form={form} name="cuadroSuperficies" label="Cuadro Superficies" value={inmueble.cuadroSuperficies}/>
              <InputUrl className="flex-grow" form={form} name="planimetria" label="Planimetría" value={inmueble.planimetria}/>
            </div>

            <HtmlEditor form={form} name="observaciones" label="Observaciones" value={inmueble.observaciones}/>
          </fieldset>
        </Card>

        {!inmueble.blocked &&
          <Submit form={form} validation={validation}>Guardar Unidad Inmobiliaria</Submit>
        }
      </Form>
    </PageLayout>
  )
}
