
import React, { useEffect, useState } from 'react'
import cx from 'classnames'


export default function Select({ className, name, label, value, onChange, options, disabled }) {
  if (!options) {
    options = []
  }

  let [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [setInternalValue, value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <label className="block font-medium text-gray-700 text-sm">
        {label}
      </label>
      <div className="mt-1">
        <select
          disabled={disabled}
          name={name}
          id={name}
          className={cx(
            'form-select block w-full focus:ring-teal-500 focus:border-teal-500 shadow-sm max-w-xs text-sm border-gray-300 rounded-md',
            {
              'bg-gray-100 cursor-not-allowed text-gray-400': disabled,
            },
          )}
          value={internalValue}
          onChange={e => {
            setInternalValue(e.target.value)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        >
          <option value="" hidden></option>
          {options.map(option => {
            return <option key={option.value.trim()} value={option.value}>{option.label}</option>
          })}
        </select>
      </div>
    </div>
  )
}
