
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import { parseDate, serializeDate } from 'platform/datetime'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import HtmlEditor from 'components/forms/HtmlEditor'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Datepicker from 'components/forms/Datepicker'
import SingleEntity from 'components/forms/SingleEntity'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import Percent from 'components/forms/Percent'
import InputUrl from 'components/forms/InputUrl'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import Area from 'components/forms/Area'
import FincasCatastralesClient from 'services/WebInterna/fincas_catastrales/fincas_catastrales'
import Select from 'components/forms/Select'
import InputAddon from 'components/forms/InputAddon'
import Price from 'components/forms/Price'
import { useNavigate } from 'hooks/navigate'


const fincasCatastralesClient = new FincasCatastralesClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())


function useFincaCatastral({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [finca, setFinca] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (!isEdit) {
      return
    }

    let reply = await fincasCatastralesClient.Get({ name })

    reply.altaDate = parseDate(reply.altaDate)
    reply.bajaDate = parseDate(reply.bajaDate)
    reply.altaJuridicaDate = parseDate(reply.altaJuridicaDate)
    reply.bajaJuridicaDate = parseDate(reply.bajaJuridicaDate)
    reply.valoracionDate = parseDate(reply.valoracionDate)
    setFinca(reply)
  }

  async function loadValidation(fincasCatastrales) {
    let reply = await validacionesClient.Get({ name: name })
    reply.entidad.fincaCatastral.altaDate = parseDate(reply.entidad.fincaCatastral.altaDate)
    reply.entidad.fincaCatastral.bajaDate = parseDate(reply.entidad.fincaCatastral.bajaDate)
    reply.entidad.fincaCatastral.altaJuridicaDate = parseDate(reply.entidad.fincaCatastral.altaJuridicaDate)
    reply.entidad.fincaCatastral.bajaJuridicaDate = parseDate(reply.entidad.fincaCatastral.bajaJuridicaDate)
    reply.entidad.fincaCatastral.valoracionDate = parseDate(reply.entidad.fincaCatastral.valoracionDate)

    if (reply.accion === 'ACCION_CREAR') {
      if (!reply.entidad.fincaCatastral.codigo) {
        let year = reply.entidad.fincaCatastral.altaJuridicaDate.getFullYear().toString().slice(2, 4)
        let codigos = fincasCatastrales
          .filter(f => f.codigo.slice(2, 4) === year)
          .map(f => f.codigo.slice(4))
          .sort()
        let maxCodigo = codigos.length ? codigos[codigos.length - 1] : '0'
        let newCodigo = parseInt(maxCodigo) + 1
        newCodigo = newCodigo.toString().padStart(5, '0')
        reply.entidad.fincaCatastral.codigo = year + newCodigo
      } else {
        reply.entidad.fincaCatastral.codigo = reply.entidad.fincaCatastral.codigo.substring(2)
      }
    }

    setFinca(reply.entidad.fincaCatastral)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        fincaCatastral: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de esta finca catastral para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/fincas-catastrales/${name}`)
    } else {
      history.push(`/webinterna/fincas-catastrales`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await fincasCatastralesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await fincasCatastralesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await fincasCatastralesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        fincaCatastral: finca,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de esta finca catastral para que lo apruebe un administrador.')
    history.push(`/webinterna/fincas-catastrales`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    finca,
    setFinca,
    validation,
    remove,
  }
}

export default function FincasCatastralesEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [fincasRegistrales, setFincasRegistrales] = useState(null)

  let {
    save: saveFinca,
    load: loadFinca,
    loadValidation,
    confirmValidation,
    finca,
    validation,
    remove: removeFinca,
  } = useFincaCatastral({ name })

  useNavigate(async () => {
    let { fincas: fincasRegistrales } = await fincasRegistralesClient.List()
    fincasRegistrales = fincasRegistrales || []
    fincasRegistrales = fincasRegistrales.map(f => {
      return {
        value: f.name,
        url: `/webinterna/fincas-registrales/${f.name}`,
        label: f.numeroFinca,
      }
    })
    setFincasRegistrales(fincasRegistrales)

    let { fincas: fincasCatastrales } = await fincasCatastralesClient.List()
    fincasCatastrales = fincasCatastrales || []

    if (isValidation) {
      await loadValidation(fincasCatastrales)
    } else {
      await loadFinca()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...finca,
      ...data,
    }

    if (data.codigo && (!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR'))) {
      req.codigo = 'CT' + data.codigo
    }

    req.altaDate = serializeDate(req.altaDate)
    req.bajaDate = serializeDate(req.bajaDate)
    req.altaJuridicaDate = serializeDate(req.altaJuridicaDate)
    req.bajaJuridicaDate = serializeDate(req.bajaJuridicaDate)
    req.valoracionDate = serializeDate(req.valoracionDate)
    
    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveFinca(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !finca.blocked &&
            <ButtonConfirmDanger onClick={removeFinca}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Finca Catastral
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/fincas-catastrales">Fincas Catastrales</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/fincas-catastrales/${name}`}>Consultar Finca Catastral</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/fincas-catastrales/edit/${name}`}>Editar Finca Catastral</Breadcrumb>
          : <Breadcrumb url="/webinterna/fincas-catastrales/edit/new">Crear Nueva Finca Catastral</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={finca.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:gap-x-4 mb-6">
              {(!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR')) &&
                <InputAddon
                  form={form}
                  name="codigo"
                  type="number"
                  label="Código"
                  validations="minNumber:1"
                  disabled={isValidation}
                  value={finca.codigo}
                  className="w-32"
                  addonText="CT"
                />
              }
              <Input form={form} name="nombre" label="Denominación" validations="required|min:3" value={finca.nombre} className="flex-grow" />
              <SingleEntity
                className="flex-grow"
                form={form}
                name="fincaRegistral"
                validations="required"
                label="Finca Registral"
                header="Seleccionar finca registral"
                options={fincasRegistrales}
                value={finca.fincaRegistral}
              />
            </div>

            <div className="lg:flex lg:space-x-4 mt-4 md:grid md:grid-cols-2 mb-6">
              <Datepicker form={form} name="altaJuridicaDate" label="Fecha Alta Jurídica" value={finca.altaJuridicaDate} validations="required" />
              <Datepicker form={form} name="bajaJuridicaDate" label="Fecha Baja Jurídica" value={finca.bajaJuridicaDate} />
              <Datepicker form={form} name="altaDate" label="Fecha Alta" value={finca.altaDate} />
              <Datepicker form={form} name="bajaDate" label="Fecha Baja" value={finca.bajaDate} />
            </div>

            <div className="flex mb-6 gap-x-4">
              <Input form={form} name="referenciaCatastral" label="Referencia Catastral" value={finca.referenciaCatastral} className="flex-grow" />
              <Area form={form} name="superficieTotal" label="Superficie total" value={finca.superficieTotal} />
              <Area form={form} name="superficieParcial" label="Superficie parcial" value={finca.superficieParcial} />
              <Select
                form={form}
                name="tipoFinca"
                label="Tipo de finca"
                validations="required"
                options={[
                  { value: 'TIPO_FINCA_URBANA', label: 'Finca urbana' },
                  { value: 'TIPO_FINCA_RUSTICA', label: 'Finca rústica' },
                  { value: 'TIPO_FINCA_ESPECIALES', label: 'Finca especial' },
                ]}
                value={finca.tipoFinca ? finca.tipoFinca : ''}
                className="w-42"
              />
              <Percent form={form} name="propiedadPercentage" label="% prop." value={finca.propiedadPercentage} />
            </div>

            <div className="flex mb-6 gap-x-4">
              <Datepicker form={form} name="valoracionDate" label="Fecha de Valoración" value={finca.valoracionDate} />
              <InputUrl form={form} name="planimetria" label="Planimetría" value={finca.planimetria} className="flex-grow" />
              <Price form={form} name="valorCatastral" label="Valor Catastral" value={finca.valorCatastral}/>
            </div>

            <InputUrl form={form} name="cuadroSuperficies" label="Cuadro de superficies" value={finca.cuadroSuperficies} className="w-1/2 mb-6" />

            <HtmlEditor form={form} name="observaciones" label="Observaciones" value={finca.observaciones} />
          </fieldset>
        </Card>

        {!finca.blocked &&
          <Submit form={form} validation={validation}>Guardar Finca Catastral</Submit>
        }
      </Form>
    </PageLayout>
  )
}
