
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { keyBy } from 'lodash-es'
import { nanoid } from 'nanoid'

import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import Icon from 'components/Icon'
import PageLayout from 'layout/expedientes/PageLayout'
import { getEntity, putEntity } from 'models/docs/entities'
import { getFieldValidations } from 'models/docs/document-types'
import Card from 'components/Card'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import { useNavigate } from 'hooks/navigate'
import { CustomFieldsEditor } from 'components/CustomFieldsEditor'
import AccessClient from 'services/Docs/access/access'
import { docs } from 'services/discovery'


const accessClient = new AccessClient(docs())


function Submit({ form, children }) {
  return (
    <div>
      {form.formState.isSubmitting ?
        <button type="button" disabled className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-300 cursor-default">
          <Icon solid spin name="spinner" className="mr-2" />
          Enviando datos...
        </button>
        :
        <ButtonPrimary type="submit">
          <Icon solid name="save" className="mr-2" />
          {children}
        </ButtonPrimary>
      }
    </div>
  )
}

export function ExpedientesEdit() {
  let history = useHistory()
  let [record, setRecord] = useState({})
  let [fields, setFields] = useState([])

  let { code } = useParams()
  useNavigate(async () => {
    let { access } = await accessClient.AccessRecord({
      name: `records/${code}`,
      level: 'LEVEL_EDIT',
    })
    if (!access) {
      history.push(`/records/${code}`)
      return
    }

    let record = await getEntity(`records/${code}`)
    let recordFamily = await getEntity(record.recordFamily)

    let recordFields = keyBy(record.fields, 'name')
    setFields(recordFamily.fields?.map(field => {
      let recField = recordFields[field.name]
      if (recField) {
        field.values = recField.values?.map(v => {
          v.ghostKey = nanoid()
          return v
        })
      } else {
        field.values = [
          { ghostKey: nanoid() },
        ]
      }

      if (field.select) {
        field.select.options = field.select.options?.map(opt => {
          return {
            value: opt.name,
            label: opt.displayName,
          }
        })

        if (field.multiple) {
          if (recField) {
            field.values = recField.values?.map(val => val.select)
          } else {
            field.values = []
          }
        }
      }

      field.validations = getFieldValidations(field)
      return field
    }))
    
    setRecord(record)
  })

  let form = useForm(async (data) => {
    let recFields = fields?.map((field, idx) => {
      let values = data.fields[idx].values.map(val => {
        if (field.select && field.multiple) {
          return { select: val }
        }
        return val
      })
      return {
        name: field.name,
        values,
      }
    })

    await putEntity('records', {
      record: {
        ...record,
        fields: recFields,
      },
    })

    history.push(`/${record.name}`)
  })

  async function closeRecord() {
    let record = await getEntity(`records/${code}`)
    record.status = 'STATUS_FINISHED'
    await putEntity(`records/${code}`, { record })

    history.push(`/${record.name}`)
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/records">Expedientes</Breadcrumb>
          <Breadcrumb url={`/${record.name}`}>{record.title}</Breadcrumb>
          <Breadcrumb url={`/${record.name}/edit`}>Editar</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="grid grid-cols-3 gap-4">
        <Form form={form} className="space-y-6 col-span-2">
          {fields?.length &&
            <Card
              title="Campos personalizados"
              subtitle="Rellena los siguientes datos acorde a lo que requiere la familia de expedientes."
              actions={
                <div className="text-red-700 text-sm">
                  <span className="align-text-top">*</span>
                  &nbsp;Campo requerido
                </div>
              }
              footer={<Submit form={form}>Guardar expediente</Submit>}
            >
              <CustomFieldsEditor form={form} fields={fields} onChange={setFields} />
            </Card>
          }
        </Form>

        <div>
          <Card
            title="Cerrar expediente"
            subtitle="Archivar definitivamente los datos y guardarlos en modo solo lectura."
            bodyBackground="bg-gray-50"
          >
            <ButtonConfirmDanger onClick={closeRecord} sendingLabel="Cerrando...">
              <Icon solid name="briefcase" className="mr-2" />
              Cerrar Expediente
            </ButtonConfirmDanger>
          </Card>
        </div>
      </div>
    </PageLayout>
  )
}
