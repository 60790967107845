
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import cx from 'classnames'

import PageThrobber from 'components/PageThrobber'
import { GlobalContext } from 'hooks/navigate'


let viewLoaded = false


export default function NavigateThrobber({ className }) {
  let { isLoading, setPending } = useContext(GlobalContext)

  let [inhibe, setInhibe] = useState(true)

  if (!viewLoaded && inhibe) {
    viewLoaded = true
    setPending(v => v + 1)
  }

  let location = useLocation()
  useEffect(() => {
    viewLoaded = false
    setInhibe(false)

    setTimeout(() => setPending(v => v - 1), 100)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading() &&
        <div className={cx('absolute inset-0 z-50', className)}>
          <div className="absolute bg-gray-100 inset-0"></div>
          <PageThrobber />
        </div>
      }
    </>
  )
}
