
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { keyBy, uniq } from 'lodash-es'
import { webinterna, users } from 'services/discovery'
import ProcesosClient from 'services/WebInterna/procesos/procesos'
import UsersClient from 'services/Users/users/users'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import { WideList, WideListEmpty, WideListItem } from 'components/WideList'
import BadgeYellow from 'components/badges/BadgeYellow'
import BadgePurple from 'components/badges/BadgePurple'
import BadgeGreen from 'components/badges/BadgeGreen'
import BadgeGray from 'components/badges/BadgeGray'
import BadgeBlue from 'components/badges/BadgeBlue'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import PageLayout from 'layout/webinterna/PageLayout'
import resolver from 'platform/resolver'
import { parseDate, parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import PeopleViewer from 'components/people/PeopleViewer'
import HtmlViewer from 'components/HtmlViewer'
import CentrosTrabajoClient from 'services/WebInterna/centros_trabajo/centros_trabajo'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import PersonasClient from 'services/WebInterna/personas/personas'
import SectionTitle from 'components/SectionTitle'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'


const personasClient = new PersonasClient(webinterna())
const procesosClient = new ProcesosClient(webinterna())
const usersClient = new UsersClient(users())
const centrosTrabajoClient = new CentrosTrabajoClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())


export default function PersonasShow() {
  let { name } = useParams()
  let [persona, setPersona] = useState({})

  useNavigate(async () => {
    let persona = await personasClient.Get({ name })

    persona.bajaTime = parseTimestamp(persona.bajaTime)
    persona.creacionTime = parseTimestamp(persona.creacionTime)

    if (persona.municipio) {
      persona.municipio = await municipiosClient.Get({ name: persona.municipio })
      persona.municipio.provincia = await provinciasClient.Get({ name: persona.municipio.provincia })
    }

    persona.isFisica = !!persona.fisica
    persona.isJuridica = !!persona.juridica
    persona.isNotario = !!persona.fisica && persona.fisica.notario
    persona.isContribuidor = !!persona.fisica && !!persona.fisica.contribuidor
    persona.isSocio = persona.socio

    if (persona.isContribuidor) {
      persona.fisica.contribuidor.nacimientoDate = parseDate(persona.fisica.contribuidor.nacimientoDate)

      let sociedadesNames = [persona.fisica.contribuidor.sociedad]
      if (persona.fisica.contribuidor.cargosInstitucionales) {
        sociedadesNames.push(...persona.fisica.contribuidor.cargosInstitucionales.map(cargo => cargo.uen))
      }

      let { procesos, grupos, user, centroTrabajo, sociedades } = await resolver.all({
        procesos: procesosClient.List().then(reply => reply.procesos || []),
        grupos: gruposProcesosClient.List().then(reply => reply.grupos || []),
        user: usersClient.Get({ name: persona.fisica.contribuidor.user }),
        centroTrabajo: centrosTrabajoClient.Get({ name: persona.fisica.contribuidor.centroTrabajo }),
        sociedades: sociedadesClient.BatchGet({ names: uniq(sociedadesNames) }).then(reply => reply.sociedades || []),
      })

      grupos = keyBy(grupos, 'name')
      procesos.forEach(proceso => {
        proceso.grupo = grupos[proceso.grupo]
      })

      persona.user = user
      persona.centroTrabajo = centroTrabajo

      sociedades = keyBy(sociedades, 'name')
      persona.sociedad = sociedades[persona.fisica.contribuidor.sociedad]
      if (persona.fisica.contribuidor.cargosInstitucionales) {
        persona.fisica.contribuidor.cargosInstitucionales = persona.fisica.contribuidor.cargosInstitucionales.map(cargo => {
          return {
            ...cargo,
            uen: sociedades[cargo.uen],
          }
        })
      }

      persona.procesos = []
      procesos.forEach(proceso => {
        let relaciones = []
        if (proceso.cuidador === persona.name) {
          relaciones.push('Cuidador')
        }
        if (proceso.gerentes?.includes(persona.name)) {
          relaciones.push('Gerente')
        }
        if (proceso.analista === persona.name) {
          relaciones.push('Analista')
        }
        let gestores = proceso.gestores?.filter(gestor => gestor.contribuidor === persona.name)
        if (gestores?.length) {
          relaciones.push(`Gestor (${gestores[0].rol})`)
        }
        if (relaciones.length) {
          persona.procesos.push({
            ...proceso,
            relaciones: relaciones,
          })
        }
      })
    }

    setPersona(persona)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!persona.blocked &&
            <Acl perm="webinterna.personas.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/personas/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Persona
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/personas">Personas</Breadcrumb>
        <Breadcrumb url={`/webinterna/personas/${name}`}>Consultar Persona</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={persona.blocked}></EntityBlockedAlert>

      <Card>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {persona.isContribuidor &&
            <ShowPane title="Usuario">
              <PeopleViewer value={{ persona: persona.name }} />
            </ShowPane>
          }
          {!persona.isContribuidor && persona.isFisica &&
            <ShowPane title="Nombre">
              {persona.fisica.fisica.nombre}
            </ShowPane>
          }
          {persona.isJuridica &&
            <ShowPane title="Denominación Social">
              {persona.juridica.denominacionSocial}
            </ShowPane>
          }
          
          <ShowPane title="Tipo">
            <span className="space-x-1">
              {persona.isFisica && <BadgePurple>Física</BadgePurple>}
              {persona.isJuridica && <BadgeYellow>Jurídica</BadgeYellow>}
              {persona.isNotario && <BadgeBlue>Notario</BadgeBlue>}
              {persona.isContribuidor && <BadgeBlue>Contribuidor</BadgeBlue>}
              {persona.isSocio && <BadgeBlue>Socio</BadgeBlue>}
            </span>
          </ShowPane>

          {persona.isContribuidor &&
            <ShowPane title="Acrónimo">
              {persona.fisica.contribuidor.acronimo}
            </ShowPane>
          }
          {!persona.isContribuidor && persona.isFisica &&
            <ShowPane title="Apellidos">
              {persona.fisica.fisica.apellidos}
            </ShowPane>
          }
          {persona.isJuridica &&
            <ShowPane title="Datos Registrales">
              {persona.juridica.datosRegistrales}
            </ShowPane>
          }
          <Acl perm="webinterna.personas.personal-data">
            <ShowPane title="Identidad">
              {persona.tipoIdentidad === 'TIPO_IDENTIDAD_DNI' && 'DNI'}
              {persona.tipoIdentidad === 'TIPO_IDENTIDAD_CIF' && 'CIF'}
              {persona.tipoIdentidad === 'TIPO_IDENTIDAD_NIE' && 'NIE'}
              {persona.tipoIdentidad === 'TIPO_IDENTIDAD_PASAPORTE' && 'Pasaporte'}
              {persona.tipoIdentidad === 'TIPO_IDENTIDAD_OTRO' && 'Otro'}
              &nbsp;&mdash;&nbsp;
              {persona.identidad}
            </ShowPane>
          </Acl>
          {persona.isContribuidor &&
            <Acl perm="webinterna.personas.personal-data">
              <ShowPane title="Fecha de nacimiento">
                {datetime(persona.fisica.contribuidor.nacimientoDate, 'DATE')}
              </ShowPane>
            </Acl>
          }
        </div>
        
        <SectionTitle>Datos de contacto</SectionTitle>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {!persona.isContribuidor && persona.isFisica &&
            <ShowPane title="Email">
              {persona.fisica.fisica.email}
            </ShowPane>
          }
          {persona.juridica &&
            <ShowPane title="Email">
              {persona.juridica.email}
            </ShowPane>
          }
          <Acl perm="webinterna.personas.personal-data">
            <ShowPane title="Calle">{persona.calle}</ShowPane>
            <ShowPane title="Número">{persona.numero}</ShowPane>
            <ShowPane title="Municipio">{persona.municipio?.nombre}</ShowPane>
            <ShowPane title="Provincia">{persona.municipio?.provincia?.nombre}</ShowPane>
            <ShowPane title="Código Postal">{persona.codigoPostal}</ShowPane>
            <ShowPane title="País">{persona.pais}</ShowPane>
          </Acl>

          {!persona.isContribuidor && persona.isFisica &&
            <ShowPane title="Teléfono">
              {persona.fisica.fisica.telefono}
            </ShowPane>
          }

          {persona.isContribuidor &&
            <>
              <ShowPane title="Teléfono de empresa">
                {persona.fisica.contribuidor.telefonoEmpresa}
                {persona.fisica.contribuidor.extensionMovil &&
                  <>
                    &nbsp;(Extensión: {persona.fisica.contribuidor.extensionMovil})
                  </>
                }
              </ShowPane>
              <Acl perm="webinterna.personas.personal-data">
                <ShowPane title="Teléfono personal">
                  {persona.fisica.contribuidor.telefonoPersonal}
                </ShowPane>
              </Acl>
              <ShowPane title="Extensión de oficina">
                {persona.fisica.contribuidor.extensionOficina}
              </ShowPane>
            </>
          }

          {persona.isJuridica &&
            <ShowPane title="Teléfono">
              {persona.juridica.telefono}
            </ShowPane>
          }
        </div>

        {persona.isContribuidor &&
          <>
            <SectionTitle>Información laboral</SectionTitle>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <ShowPane title="Centro de trabajo">
                {persona.centroTrabajo.nombre}
              </ShowPane>
              <ShowPane title="Sociedad">
                {persona.sociedad.denominacion}
              </ShowPane>
              <ShowPane title="Objetivos profesionales" className="col-span-2">
                <HtmlViewer html={persona.fisica.contribuidor.objetivosProfesionales}/>
              </ShowPane>
            </div>
          </>
        }

        <SectionTitle>Vigencia</SectionTitle>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <ShowPane title="Fecha de alta">{datetime(persona.creacionTime, 'DATETIME')}</ShowPane>
          <ShowPane title="Fecha de baja">{datetime(persona.bajaTime, 'DATETIME')}</ShowPane>
          <ShowPane title="Vigencia">
            {persona.bajaTime ?
              <BadgeGray>No vigente</BadgeGray>
              :
              <BadgeGreen>Vigente</BadgeGreen>
            }
          </ShowPane>
        </div>
      </Card>

      {persona.personaContacto &&
        <CardHeader
          className="mt-4"
          header="Persona de contacto"
        >
          <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <ShowPane title="Nombre">
              {persona.personaContacto.nombre}
            </ShowPane>
            <ShowPane title="Email">
              {persona.personaContacto.email}
            </ShowPane>
            <ShowPane title="Apellidos">
              {persona.personaContacto.apellidos}
            </ShowPane>
            <ShowPane title="Teléfono">
              {persona.personaContacto.telefono}
            </ShowPane>
          </div>
        </CardHeader>
      }

      {persona.isContribuidor &&
        <>
          <CardHeader
            fit
            className="mt-4"
            header="Cargos institucionales"
            subheader="Cargos que desempeña el contribuidor en las diversas empresas de la matriz."
          >
            <WideList divider="divide-y-4 divide-teal-400">
              {!persona.fisica.contribuidor.cargosInstitucionales?.length && <WideListEmpty>No hay cargos institucionales</WideListEmpty>}
              {persona.fisica.contribuidor.cargosInstitucionales?.map(cargo => (
                <WideListItem key={cargo.uen}>
                  <SectionTitle fit>{cargo.uen.denominacion}</SectionTitle>
                  <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <ShowPane title="Cargo">{cargo.cargo}</ShowPane>
                    <ShowPane title="Email">{cargo.email}</ShowPane>
                  </div>
                </WideListItem>
              ))}
            </WideList>
          </CardHeader>

          <CardHeader
            fit
            className="mt-4"
            header="Procesos"
            subheader="Procesos asociados a la persona."
          >
            <WideList divider="divide-y-4 divide-teal-400">
              {!persona.procesos?.length && <WideListEmpty>No hay procesos asociados</WideListEmpty>}
              {persona.procesos?.map(proceso => (
                <WideListItem key={proceso.name}>
                  <SectionTitle>{proceso.denominacion}</SectionTitle>

                  <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <ShowPane title="Código">{proceso.grupo?.codigo}{proceso.codigo}</ShowPane>
                    <ShowPane title="Relaciones">
                      {proceso.relaciones.map(relacion => (
                        <div>{relacion}</div>
                      ))}
                    </ShowPane>
                  </div>
                </WideListItem>
              ))}
            </WideList>
          </CardHeader>
        </>
      }

    </PageLayout>
  )
}
