
import { keyBy, uniq } from 'lodash-es'

import { batchGetPersonas } from 'models/webinterna/personas'
import { webinterna } from 'services/discovery'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'

const provinciasClient = new ProvinciasClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())


export let REF_PERSONA = {
  loader: async (names) => {
    let personas = await batchGetPersonas(names)
    let municipiosNames = Object.values(personas)
      .filter(p => p.municipio)
      .map(p => p.municipio)
    let { municipios } = await municipiosClient.BatchGet({ names: uniq(municipiosNames) })
    let provinciasNames = municipios?.map(municipio => municipio.provincia)
    let { provincias } = await provinciasClient.BatchGet({ names: uniq(provinciasNames) })
    municipios = keyBy(municipios, 'name')
    provincias = keyBy(provincias, 'name')

    personas = Object.values(personas).map(persona => {
      return {
        ...persona,
        municipio: municipios[persona.municipio],
        provincia: provincias[municipios[persona.municipio].provincia],
        label: persona.nombre,
        descriptor: 'Persona',
      }
    })
    return personas
  },
  link: name => `/webinterna/personas/${name}`,
  label: 'Persona',
  fields: {
    identidad: {
      label: 'Identidad',
      type: 'text',
      wide: false,
      extractor: entity => entity.identidad,
    },
    nombre: {
      label: 'Nombre',
      type: 'text',
      wide: false,
      extractor: entity => entity.nombre,
    },
    email: {
      label: 'Email',
      type: 'text',
      wide: false,
      extractor: entity => entity.email,
    },
    telefono: {
      label: 'Teléfono',
      type: 'text',
      wide: false,
      extractor: entity => {
        if (entity.fisica?.fisica) {
          return entity.fisica.fisica.telefono
        }
        if (entity.fisica?.contribuidor) {
          return entity.fisica.contribuidor.telefonoEmpresa
        }
        if (entity.juridica) {
          return entity.juridica.telefono
        }
      },
    },
    calle: {
      label: 'Calle',
      type: 'text',
      wide: false,
      extractor: entity => entity.calle,
    },
    provincia: {
      label: 'Provincia',
      type: 'text',
      wide: false,
      extractor: entity => entity.provincia.nombre,
    },
    codigoPostal: {
      label: 'Código Postal',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigoPostal,
    },
    pais: {
      label: 'País',
      type: 'text',
      wide: false,
      extractor: entity => entity.pais,
    },
  },
}
