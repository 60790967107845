
import React, { useState } from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'

import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import BadgeYellow from 'components/badges/BadgeYellow'
import BadgePurple from 'components/badges/BadgePurple'
import BadgeBlue from 'components/badges/BadgeBlue'
import Face from 'components/people/Face'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { users, webinterna } from 'services/discovery'
import UsersClient from 'services/Users/users/users'
import { keyBy } from 'lodash-es'
import PersonasClient from 'services/WebInterna/personas/personas'
import SelectorMultiple from 'components/SelectorMultiple'
import ExportCSV from 'components/ExportCSV'
import { parseTimestamp } from 'platform/datetime'


const personasClient = new PersonasClient(webinterna())
const usersClient = new UsersClient(users())


export default function PersonasList() {
  let { table, setRows, useAsyncLoad, setSortOrders } = useDatatable()
  let [personas, setPersonas] = useState([])
  let [filters, setFilters] = useState([])

  function getPersonasCSV(rows) {
    return rows.map(p => {
      let tipo = [
        p.isJuridica ? 'Jurídica' : null,
        p.isFisica ? 'Física' : null,
      ]
      let atributos = [
        p.isContribuidor  ? 'Contribuidor' : null,
        p.isSocio ? 'Socio' : null,
        p.isNotario ? 'Notario' : null,
      ]
      return {
        'Identificador': p.name,
        'Nombre': p.nombre,
        'Email': p.email,
        'Teléfono': p.telefono,
        'Tipo Identidad': p.tipoIdentidad,
        'Identidad': p.identidad,
        'Tipo': tipo.filter(o => o),
        'Atributos': atributos.filter(o => o),
        'Calle': p.calle,
        'Número': p.numero,
        'Código Postal': p.codigoPostal,
        'País': p.pais,
        'Fecha creación': parseTimestamp(p.creacionTime),
      }
    })
  }

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Alfabéticamente Nombres',
        sort: firstBy('nombre'),
      },
      {
        name: 'Identidad',
        sort: firstBy('identidad'),
      },
    ])

    let personas = await personasClient.List().then(reply => reply.personas || [])

    let names = personas
      .filter(persona => persona.fisica?.contribuidor)
      .map(persona => persona.fisica.contribuidor.user)
    let users = await usersClient.BatchGet({ names }).then(users => users.users)
    users = keyBy(users, 'name')

    personas = personas.map(persona => {
      persona.isFisica = !!persona.fisica
      persona.isJuridica = !!persona.juridica
      persona.isNotario = !!persona.fisica && persona.fisica.notario
      persona.isContribuidor = !!persona.fisica && !!persona.fisica.contribuidor
      persona.isSocio = persona.socio

      if (persona.isFisica) {
        if (persona.fisica.fisica) {
          persona.email = persona.fisica.fisica.email
          persona.nombre = persona.fisica.fisica.nombre + ' ' + persona.fisica.fisica.apellidos
          persona.telefono = persona.fisica.fisica.telefono
        } else {
          let user = users[persona.fisica.contribuidor.user]
          persona.email = user.email
          persona.nombre = user.displayName
          persona.telefono = persona.fisica.contribuidor.telefonoEmpresa
          persona.extensionOficina = persona.fisica.contribuidor.extensionOficina
          persona.pictureUrl = user.pictureUrl
          persona.acronimo = persona.fisica.contribuidor.acronimo
        }
      } else {
        persona.email = persona.juridica.email
        persona.nombre = persona.juridica.denominacionSocial
        persona.telefono = persona.juridica.telefono
      }

      persona.$search = [
        persona.email,
        persona.nombre,
        persona.telefono,
        persona.identidad,
        persona.extensionOficina,
        persona.isFisica && 'Física',
        persona.isJuridica && 'Jurídica',
        persona.isNotario && 'Notario',
        persona.isContribuidor && 'Contribuidor',
        persona.isSocio && 'Socio',
      ].filter(v => v)
      return persona
    })
    setPersonas(personas)
    setRows(personas)
  })

  function filterRows(rows, filters) {
    if (filters?.length) {
      return rows.filter(persona => filters.every(filter => {
        switch (filter) {
        case 'FISICA':
          return persona.isFisica
        case 'JURIDICA':
          return persona.isJuridica
        case 'NOTARIO':
          return persona.isNotario
        case 'CONTRIBUIDOR':
          return persona.isContribuidor
        case 'SOCIO':
          return persona.isSocio
        default:
          return true
        }
      }))
    } else {
      return rows
    }
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={
          <>
            <Acl perm="webinterna.personas.propose-edit">
              <ButtonLinkPrimary to="/webinterna/personas/edit/new">
                <Icon solid name="plus" className="mr-2" />
                Crear nueva persona
              </ButtonLinkPrimary>
            </Acl>
            <ExportCSV
              className="ml-3"
              data={getPersonasCSV(table.filteredRows)}
              filename="Personas"
            />
          </>
        }
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/personas">Personas</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell></HeaderCell>
            <HeaderCell>Teléfono</HeaderCell>
            <HeaderCell>Identidad</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Atributos</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        filters={
          <>
            <div className="ml-4 h-full">
              <SelectorMultiple
                className="h-full w-56 height shadow-sm rounded-md sm:text-sm text-gray-500"
                options={[
                  { label: 'Física', value: 'FISICA' },
                  { label: 'Jurídica', value: 'JURIDICA' },
                  { label: 'Contribuidor', value: 'CONTRIBUIDOR' },
                  { label: 'Notario', value: 'NOTARIO' },
                  { label: 'Socio', value: 'SOCIO' },
                ]}
                value={filters}
                onChange={filters => {
                  setFilters(filters)
                  setRows(filterRows(personas, filters))
                }}
                selectedPrefix="Atributos"
                empty="Filtrar por atributo..."
              /> 
            </div>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/personas/${row.name}`}>
            <div className="flex items-center">
              <Face displayName={row.nombre || row.email} pictureUrl={row.pictureUrl} />
              <div className="ml-3">
                <span className="font-medium text-gray-900">
                  {row.nombre}
                </span>
                {row.acronimo && <span className="ml-1 font-medium text-gray-400">{`[${row.acronimo}]`}</span>}
                <span className="ml-2 space-x-1">
                  {row.isFisica && <BadgePurple>Física</BadgePurple>}
                  {row.isJuridica && <BadgeYellow>Jurídica</BadgeYellow>}
                </span>
                <span className="ml-2 space-x-1">
                  {row.isNotario && <BadgeBlue>Notario</BadgeBlue>}
                  {row.isContribuidor && <BadgeBlue>Contribuidor</BadgeBlue>}
                </span>
                <div className="text-sm text-gray-400">{row.identidad}</div>
              </div>
            </div>
          </RowMobile>
        }
        row={row => (
          <>
            <Cell>
              <div className="flex items-center">
                <Face displayName={row.nombre || row.email} pictureUrl={row.pictureUrl} />
                <div className="ml-3">
                  <Link
                    to={`/webinterna/personas/${row.name}`}
                    className="font-medium text-gray-900"
                  >
                    {row.nombre
                      ? row.nombre
                      : <span className="italic">(sin nombre)</span>
                    }
                  </Link>
                  {row.acronimo && <span className="ml-1 font-medium text-gray-400">{`[${row.acronimo}]`}</span>}
                  <div className="text-sm text-gray-400">{row.identidad}</div>
                </div>
              </div>
            </Cell>
            <Cell>
              {row.telefono
                ?
                <div>
                  <Icon solid name="phone-rotary" className="mr-2 text-gray-400 align-baseline"/>
                  <span className="tracking-tight">
                    {`${row.telefono}`}&nbsp;
                    {row.extensionOficina && `(${row.extensionOficina})`}
                  </span>
                </div>
                :
                <span>&mdash;</span>
              }
            </Cell>
            <Cell>
              <div className="text-sm text-gray-400">{row.identidad}</div>
            </Cell>
            <Cell className="space-x-2">
              {row.isFisica && <BadgePurple>Física</BadgePurple>}
              {row.isJuridica && <BadgeYellow>Jurídica</BadgeYellow>}
            </Cell>
            <Cell className="space-x-2">
              {row.isNotario && <BadgeBlue>Notario</BadgeBlue>}
              {row.isContribuidor && <BadgeBlue>Contribuidor</BadgeBlue>}
              {row.isSocio && <BadgeBlue>Socio</BadgeBlue>}
            </Cell>
            <Cell className="text-right">
              <Acl perm="webinterna.personas.propose-edit">
                <Link to={`/webinterna/personas/edit/${row.name}`}>
                  Editar
                </Link>
              </Acl>
            </Cell>
          </>
        )}
      />
    </PageLayout>
  )
}
