
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Transition } from '@headlessui/react'

import { logout, accountInfo } from 'auth/active-directory'
import Icon from 'components/Icon'
import Face from 'components/people/Face'
import logo from 'images/logo.png'
import { Notifications } from 'components/Notifications'
import { Profile } from 'components/Profile'
import Acl from 'components/Acl'


export const MENU_ITEMS = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'WebInterna',
    url: '/webinterna',
    perm: 'menu.webinterna',
  },
  {
    label: 'Documentos',
    url: '/docs',
    perm: 'menu.docs',
  },
  {
    label: 'Biblioteca',
    url: '/archive',
    perm: 'menu.archive',
  },
  {
    label: 'Expedientes',
    url: '/records',
    perm: 'menu.records',
  },
  {
    label: 'Administración',
    url: '/manage',
    perm: 'menu.admin',
  },
]


export function HeaderMenu({ breadcrumbs, children }) {
  let [showMobile, setShowMobile] = useState(false)

  function isActive(item) {
    if (item.url === '/') {
      return window.location.pathname === '/'
    }
    return window.location.pathname.startsWith(item.url)
  }

  return (
    <div className="h-full bg-gray-100 flex flex-col">
      <header className="bg-white shadow pr-4">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex px-2 lg:px-0">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img className="block" width="96" height="32" src={process.env.REACT_APP_HOST + logo} alt="Alvores"></img>
                </Link>
              </div>
              <nav className="hidden lg:ml-12 lg:flex lg:space-x-8">
                {MENU_ITEMS.map(item => (
                  <React.Fragment key={item.label}>
                    {item.perm &&
                      <Acl perm={item.perm}>
                        <Link to={item.url}>
                          <div
                            className={cx(
                              'inline-flex items-center pt-1 border-b-2 text-sm font-medium h-full',
                              {
                                'border-teal-500 focus:border-teal-700 font-bold': isActive(item),
                                'border-transparent hover:border-gray-300 focus:border-gray-300': !isActive(item),
                              },
                            )}
                          >
                            <span
                              className={cx(
                                'px-1',
                                {
                                  'text-black': isActive(item),
                                  'text-gray-500 hover:text-gray-700 focus:text-gray-700': !isActive(item),
                                },
                              )}
                            >{item.label}</span>
                          </div>
                        </Link>
                      </Acl>
                    }
                    {!item.perm &&
                    <Link to={item.url}>
                      <div
                        className={cx(
                          'inline-flex items-center pt-1 border-b-2 text-sm font-medium h-full',
                          {
                            'border-teal-500 focus:border-teal-700 font-bold': isActive(item),
                            'border-transparent hover:border-gray-300 focus:border-gray-300': !isActive(item),
                          },
                        )}
                      >
                        <span
                          to={item.url}
                          className={cx(
                            'px-1',
                            {
                              'text-black': isActive(item),
                              'text-gray-500 hover:text-gray-700 focus:text-gray-700': !isActive(item),
                            },
                          )}
                        >{item.label}</span>
                      </div>
                    </Link>
                    }
                  </React.Fragment>
                ))}
              </nav>
            </div>
            <div className="flex items-center lg:hidden">
              {!showMobile &&
                <button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500"
                  onClick={() => setShowMobile(!showMobile)}
                >
                  <Icon regular name="bars" className="h-5 w-5 fa-2x"></Icon>
                </button>
              }
            </div>
            <div className="hidden lg:ml-4 lg:flex lg:items-center">
              <Notifications />
              <Profile />
            </div>
          </div>
        </div>

        {breadcrumbs}

        <Transition
          show={showMobile}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="z-20 fixed inset-0 bg-black bg-opacity-25 lg:hidden"></div>
        </Transition>

        <Transition
          show={showMobile}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top lg:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
              <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="Alvores"></img>
                  </div>
                  <div className="-mr-2">
                    <button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500"
                      onClick={() => setShowMobile(false)}
                    >
                      <Icon light name="times" className="h-6 w-6 fa-2x"></Icon>
                    </button>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {MENU_ITEMS.map(item => (
                    <div key={item.label}>
                      {item.perm &&
                        <Acl perm={item.perm}>
                          <Link
                            to={item.url}
                            key={item.url}
                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                          >{item.label}</Link>
                        </Acl>
                      }
                      {!item.perm &&
                        <Link
                          to={item.url}
                          className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                        >{item.label}</Link>
                      }
                    </div>
                  ))}
                </div>
              </div>

              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <Notifications />
                <Profile />
              </div>
              <div className="pt-4 pb-2">
                <div className="block lg:hidden">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <Face displayName={accountInfo().displayName} pictureUrl={accountInfo().pictureUrl} large></Face>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{accountInfo().displayName}</div>
                      <div className="text-sm font-medium text-gray-500">{accountInfo().email}</div>
                    </div>
                    <Notifications></Notifications>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <button
                      className="block w-full text-left rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                      onClick={() => logout()}
                    >Cerrar sesión</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </header>

      <main className="flex-grow flex h-0">
        {children}
      </main>
    </div>
  )
}


export function Breadcrumbs({ children }) {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="border-t border-gray-200 py-3">
          <nav className="flex">
            <div className="flex sm:hidden">
              <Link
                to={children.length > 1 ? children[children.length - 2].props.url : '/'}
                className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <Icon solid name="long-arrow-left" className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600 fa-2x"></Icon>
                <span>Atras</span>
              </Link>
            </div>
            <div className="hidden sm:block">
              <ol className="flex items-center space-x-4">
                <li>
                  <Link to="/" className="text-gray-400 hover:text-gray-500">
                    <Icon solid name="home" className="flex-shrink-0 h-4 w-4"></Icon>
                  </Link>
                </li>
                {children}
              </ol>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}


export function Breadcrumb({ children, url }) {
  return (
    <>
      <li>
        <div className="flex items-center">
          <Icon regular name="chevron-right" className="text-gray-400 text-xs last:invisible" />
          <Link to={url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{children}</Link>
        </div>
      </li>
    </>
  )
}
