
import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { isNumber } from 'lodash-es'

import { useField } from 'platform/forms'
import { formatDecimal } from 'platform/decimal'


let mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
})


export default function Area({ form, className, help, name, label, validations, value, onChange }) {
  let field = useField({ form, name, validations, value, onChange })
  let [internalValue, setInternalValue] = useState(null)

  if (value && !isNumber(value)) {
    throw new Error(`Area should receive a number always`)
  }
  useEffect(() => {
    if (!value) {
      return
    }
    setInternalValue(formatDecimal(value))
  }, [value])

  function maskedInputChanged(e) {
    let changed = e.target.value
      .replace(/\./g, '')
      .replace(/,/g, '')
    changed = parseInt(changed, 10)
    field.setValue(changed)
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm w-36">
        <MaskedInput
          type="text"
          name={name}
          id={name}
          mask={mask}
          className={cx(
            'form-input focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md pr-8',
            {
              'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': field.hasError,
            },
          )}
          onChange={maskedInputChanged}
          value={internalValue}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">m<sup>2</sup></span>
        </div>
      </div>
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
      {help && <p className="mt-2 text-gray-500">{help}</p>}
    </div>
  )
}
