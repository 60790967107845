
import React from 'react'
import { MultiSelect } from 'react-multi-select-component'


export default function MultiSelector({ label, name, className, value = [], options = [], onChange }) {
  let val = value?.map(v => options.find(o => o.value === v))

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block font-medium text-gray-700 text-sm"
      >{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <MultiSelect
          className="shadow-sm rounded-md sm:text-sm"
          overrideStrings={{
            search: 'Filtrar...',
            selectSomeItems: 'Ningún elemento seleccionado',
            allItemsAreSelected: 'Todos seleccionados',
          }}
          ItemRenderer={({ checked, option, onClick, disabled }) => (
            <div className="flex items-center">
              <input
                className="mr-2"
                type="checkbox"
                onChange={onClick}
                checked={checked}
                tabIndex={-1}
                disabled={disabled}
              />
              {option.label}
            </div>
          )}
          hasSelectAll={false}
          options={options}
          value={val}
          onChange={value => {
            if (onChange) {
              onChange(value.map(v => v.value))
            }
          }}
        ></MultiSelect>
      </div>
    </div>
  )
}
