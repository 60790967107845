
import React, { useState } from 'react'

import InputButton from 'components/forms/InputButton'
import SingleSelector from 'components/selectors/SingleSelector'
import { useField } from 'platform/forms'
import Icon from 'components/Icon'


export default function SingleEntity({ form, name, label, onChange, validations, header, subheader, value, options, className }) {
  if (!options) {
    options = []
  }

  let field = useField({ form, name, validations, value, onChange })

  let [open, setOpen] = useState(false)

  let [selected, setSelected] = useState(null)
  if (!selected && value && options.length) {
    setSelected(options.find(p => p.value === value))
  }

  return (
    <>
      <InputButton
        label={label}
        required={field.isRequired}
        className={className}
        name={name}
        value={selected?.label}
        error={field.errorMessage}
        onButtonClick={() => setOpen(true)}
        button={<>
          <Icon solid name="link" className="mr-2" />
          Vincular
        </>}
      ></InputButton>
      <SingleSelector
        open={open}
        onClose={() => setOpen(false)}
        header={header}
        subheader={subheader}
        options={options}
        value={selected?.value}
        onChange={s => {
          field.setValue(s?.value)
          setSelected(s || {})
          if (onChange) {
            onChange(s?.value)
          }
        }}
      ></SingleSelector>
    </>
  )
}
