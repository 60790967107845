
import { useContext } from 'react'
import { isArray } from 'lodash-es'
import { GlobalContext } from 'hooks/navigate'
import MessagesClient from 'services/Notifications/messages/messages'
import { notifications } from 'services/discovery'


const client = new MessagesClient(notifications())
let notificationID = 0


export function useNotifications() {
  let { addNotification, removeNotification } = useContext(GlobalContext)

  return {
    removeNotification,
    
    notifySuccess(title, text) {
      let notification = {
        id: notificationID++,
        type: 'success',
        title,
        text,
      }
      addNotification(notification)

      setTimeout(() => removeNotification(notification), 7000)
    },
  }
}

export async function sendNotification(users, text, link = '') {
  if (!isArray(users)) {
    users = [users]
  }

  let req = {
    users,
    message: {
      text,
      link,
    },
  }
  await client.Create(req)
}

export async function sendNotificationButton(users, text, actionButton) {
  if (!isArray(users)) {
    users = [users]
  }
  
  let req = {
    users,
    message: {
      text,
      actionButton,
    },
  }
  await client.Create(req)
}
