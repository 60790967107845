
import React from 'react'


function onErrors(errors) {
  if (Object.keys(errors).length) {
    setTimeout(() => {
      let element = document.querySelector('.text-red-700')
      let parent = element.closest('.overflow-y-scroll')
      if (!parent) {
        parent = document.body
      }
      let target = Math.max(element.offsetTop - 30, 0)
      if (target > parent.scrollTop) {
        return
      }
      parent.scroll({
        top: target,
        behavior: 'smooth',
      })
    })
  }
}

export default function Form({ form, className, children }) {
  return (
    <form className={className} onSubmit={form.handleSubmit(form.submit, onErrors)} noValidate>
      {children}
    </form>
  )
}
