
import { isArray, isString } from 'lodash-es'


export function isEmpty(children) {
  if (!children) {
    return true
  }
  if (isString(children)) {
    return false
  }
  if (isArray(children)) {
    if (!children.length) {
      return true
    }
    return children.every(isEmpty)
  }
  return false
}
