
import React from 'react'
import { Link } from 'react-router-dom'
import firstBy from 'thenby'

import { useNavigate } from 'hooks/navigate'
import { Breadcrumb, Breadcrumbs } from 'layout/HeaderMenu'
import PageLayout from 'layout/manage/PageLayout'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import { listEntities } from 'models/docs/entities'


export default function ArchiveRoomsList() {
  let { table, setRows } = usePaginatedTable()

  useNavigate(async () => {
    let rows = await listEntities('archive-rooms')
    rows = rows.sort(firstBy('displayName'))
    setRows(rows.map(row => {
      row.$search = [
        row.displayName,
      ]
      return row
    }))
  })


  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/archive-rooms">Habitaciones</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardPaginatedTable
        title="Habitaciones"
        subtitle="Registro de las localizaciones físicas donde se pueden guardar documentos."
        empty="No hay habitaciones que mostrar"
        table={table}
        actions={
          <ButtonLinkPrimary to="/manage/archive-rooms/create">
            <Icon solid name="plus" className="mr-2" />
            Registrar Habitación
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        row={row => (
          <>
            <Cell>
              <Link to={`/manage/${row.name}`} className="text-gray-700">{row.displayName}</Link>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/${row.name}`}>Editar</Link>
            </Cell>
          </>
        )}
      >
      </CardPaginatedTable>
    </PageLayout>
  )
}
