
import format from 'date-fns/format'
import prettyBytes from 'pretty-bytes'

import { es } from 'date-fns/locale'

import { formatMoney } from 'platform/money'
import { formatDecimal } from 'platform/decimal'


export function price(value) {
  return formatMoney(value)
}


export function priceNoSymbol(value) {
  return formatMoney(value, true)
}


export function datetime(value, formatName) {
  if (!value) {
    return ''
  }

  const FORMATS = {
    DATE: 'dd MMM yyyy',
    DATETIME: `dd MMM yyyy 'a las' HH:mm`,
    DAY_MONTH: 'dd MMM',
    DAY_MONTH_TIME: `dd MMM',' HH:mm`,
    DATETIME_SHORT: `HH:MM dd MMM yyyy`,
  }
  return format(value, FORMATS[formatName], { locale: es })
}


export function duracion(duracion) {
  if (!duracion) {
    return ''
  }

  let unit
  switch (duracion.unit) {
  case 'UNIT_HOURS':
    unit = (duracion.value === 1) ? 'hora' : 'horas'
    break

  case 'UNIT_DAYS':
    unit = (duracion.value === 1) ? 'día' : 'días'
    break

  case 'UNIT_WEEKS':
    unit = (duracion.value === 1) ? 'semana' : 'semanas'
    break
    
  case 'UNIT_MONTHS':
    unit = (duracion.value === 1) ? 'mes' : 'meses'
    break

  default:
    throw Error(`unknown duration unit: ${duracion.unit}`)
  }
  return `${duracion.value} ${unit}`
}


export function decimal(value) {
  return formatDecimal(value)
}

export function area(value) {
  if (!value) {
    return ''
  }
  return formatDecimal(value) + 'm²'
}

export function percentage(value) {
  if (!value) {
    return ''
  }
  return formatDecimal(value) + '%'
}

export function bytesSize(value) {
  if (!value) {
    return ''
  }
  return prettyBytes(value, { locale: 'es' })
}
