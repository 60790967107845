
import { webinterna, users } from 'services/discovery'
import PersonasClient from 'services/WebInterna/personas/personas'
import UsersClient from 'services/Users/users/users'
import { keyBy, uniq } from 'lodash-es'


const usersClient = new UsersClient(users())
const personasClient = new PersonasClient(webinterna())


async function fillRelated(personas) {
  let names = uniq(personas
    .filter(persona => persona.fisica?.contribuidor)
    .map(persona => persona.fisica.contribuidor.user))

  let users
  if (names.length) {
    ({ users } = await usersClient.BatchGet({ names }))
    users = keyBy(users || [], 'name')
  }
  
  personas = personas.map(persona => {
    return persona.fisica?.contribuidor ?
      {
        ...persona,
        nombre: users[persona.fisica.contribuidor.user].displayName,
        email: users[persona.fisica.contribuidor.user].email,
        fotoUrl: users[persona.fisica.contribuidor.user].pictureUrl,
      }
      :
      persona.fisica?.fisica ?
        {
          ...persona,
          nombre: persona.fisica.fisica.nombre,
          apellidos: persona.fisica.fisica.apellidos,
          email: persona.fisica.fisica.email,
        }
        :
        {
          ...persona,
          nombre: persona.juridica.denominacionSocial,
          email: persona.juridica.email,
        }
  })
  return personas
}

export async function listPersonas() {
  let { personas } = await personasClient.List()

  return await fillRelated(personas || [])
}

export async function batchGetPersonas(names = []) {
  if (!names.length) {
    return {}
  }

  let { personas } = await personasClient.BatchGet({ names })
  return keyBy(await fillRelated(personas || []), 'name')
}

export async function prepareSearchPersonas(names) {
  return await batchGetPersonas(uniq(names))
}

export function searchPersonas(personas, name) {
  if (!name) {
    return []
  }

  let reply = personas[name]
  return [reply.nombre, reply.email]
}
