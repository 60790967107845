
import React from 'react'
import cx from 'classnames'


export default function Card({ children, className, footer, title, subtitle, actions, fit, bodyBackground }) {
  return (
    <div
      className={cx(
        className,
        'bg-white shadow sm:rounded-md',
        { 
          'sm:rounded-md': !footer,
          'sm:rounded-t-md': footer,
        },
      )}
    >
      {(title || subtitle) &&
        <div
          className={cx(
            'flex items-center justify-between flex-wrap sm:flex-nowrap',
            {
              'pr-4 sm:px-6 pt-3 sm:pt-4': !fit,
            },
          )}
        >
          <div className="mt-2">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
            {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
          </div>
          {actions && <div className="ml-4 mt-2 flex-shrink-0 self-start">{actions}</div>}
        </div>
      }
      <div
        className={cx(
          bodyBackground,
          {
            'px-4 py-5 sm:p-6': !fit,
            'mt-5 sm:mt-6': bodyBackground,
          },
        )}
      >
        {children}
      </div>

      {footer && <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">{footer}</div>}
    </div>
  )
}
