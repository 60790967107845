
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import cx from 'classnames'

import { cloneDeep, some } from 'lodash-es'
import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonAsyncDanger from 'components/buttons/ButtonAsyncDanger'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { webinterna } from 'services/discovery'
import RegistrosPropiedadClient from 'services/WebInterna/registros_propiedad/registros_propiedad'


const client = new RegistrosPropiedadClient(webinterna())


export default function RegistrosPropiedadEdit() {
  let history = useHistory()
  let { name } = useParams()

  let [registro, setRegistro] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    if (name) {
      let reply = await client.Get({ name })
      setRegistro(cloneDeep(reply))
      setForm(reply)
    }
  })

  async function save() {
    let req = cloneDeep(form)
    if (name) {
      let reply = await client.Update(req)
      setRegistro(reply)
      setForm(reply)
    } else {
      let reply = await client.Create(req)
      history.push('/manage/registros-propiedad/' + reply.name)
    }
  }

  async function deleteRegistroPropiedad() {
    await client.Delete(registro)
    history.push('/manage/registros-propiedad')
  }

  let hasChanged = some([
    registro.nombre !== form.nombre,
  ]) && form.nombre

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/registros-propiedad">Registros de la propiedad</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/registros-propiedad/${name}`}>{registro.nombre}</Breadcrumb>
            :
            <Breadcrumb url="/manage/registros-propiedad/create">Crear registro</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header={name ? 'Editar registro' : 'Crear registro'}
        subheader={name ? 'Edita un registro de la propiedad.' : 'Crea un registro de la propiedad.'}
        footer={
          <div
            className={cx(
              'flex justify-end w-full',
              {
                'justify-between': name,
              },
            )}
          >
            {name &&
              <ButtonAsyncDanger
                onClick={deleteRegistroPropiedad}
              >
                <Icon solid name="trash" className="mr-2" />
                Eliminar registro
              </ButtonAsyncDanger>
            }
            <ButtonAsyncPrimary onClick={save} disabled={!hasChanged}>
              <Icon solid name="save" className="mr-2" />
              Guardar cambios
            </ButtonAsyncPrimary>
          </div>
        }
      >
        <Input
          label="Nombre"
          type="text"
          value={form.nombre}
          onChange={value => {
            setForm({
              ...form,
              nombre: value,
            })
          }}
        ></Input>
      </CardHeader>
    </PageLayout>
  )
}
