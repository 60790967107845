// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/municipios/municipios.proto

import { Caller } from '@altipla/grpc-browser';


export default class MunicipiosClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  Search(req) {
    return this._caller.send('POST', `/municipios:search`, req, true, []);
  }

  Get(req) {
    return this._caller.send('GET', `/municipios/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/municipios:batchGet`, req, true, []);
  }
};

