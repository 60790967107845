
import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'

import { ReactComponent as DiagonalBars } from 'images/DiagonalBars.svg'


export default function FileUpload({ onSelect }) {
  let fileInputRef = useRef()
  let [hover, setHover] = useState(false)
  let [file, setFile] = useState(null)

  useEffect(() => {
    if (file) {
      onSelect(file)
    }
  }, [file, onSelect])

  function onFileDrop(e) {
    e.preventDefault()
    setHover(false)
    let files = e.dataTransfer.files
    if (files.length) {
      setFile(files[0])
    }
  }

  function onFileSelected() {
    if (fileInputRef.current.files.length) {
      setFile(fileInputRef.current.files[0])
    }
  }

  return (
    <>
      <div className="w-12/12 mx-auto h-72">
        <div
          className={cx(
            'relative px-4 sm:px-0 cursor-pointer border-8 border-dashed rounded-lg h-full border-gray-300 hover:border-gray-400 text-gray-300 hover:text-gray-400',
            {
              'border-gray-400 text-gray-400': hover,
            },
          )}
        >
          <div className="stroke-current stroke-8">
            <DiagonalBars />
          </div>
          <div className={cx('w-full h-full flex justify-center items-center bg-white', { 'bg-gray-200': hover })}>
            <div className={cx('absolute bg-white bg-opacity-75 w-80 text-center py-4', { 'bg-gray-200': hover })}>
              <span className="text-2xl text-gray-400 font-medium">
                Arrastra aquí tu documento o haz click para subirlo
              </span>
            </div>
          </div>
          <div
            className="w-full h-full absolute top-0"
            onDragOver={e => {
              e.preventDefault()
            }}
            onDragEnter={e => {
              e.preventDefault()
              setHover(true)
            }}
            onDragLeave={e => {
              e.preventDefault()
              setHover(false)
            }}
            onDrop={onFileDrop}
            onClick={() => fileInputRef.current.click()}
          ></div>
          <input
            hidden
            ref={fileInputRef}
            type="file"
            onChange={onFileSelected}
            accept="application/pdf"
          />
        </div>
      </div>
    </>
  )
}
