
import React, { useRef, useEffect } from 'react'


export default function ClickOutside({ children, onClick }) {
  let ref = useRef(null)

  useEffect(() => {
    function internalOnClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick()
      }
    }

    document.addEventListener('click', internalOnClick)
    return () => {
      document.removeEventListener('click', internalOnClick)
    }
  }, [ref, onClick])

  return (
    <div ref={ref}>{children}</div>
  )
}
