
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Card from 'components/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import HtmlEditor from 'components/forms/HtmlEditor'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import SingleEntity from 'components/forms/SingleEntity'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import Area from 'components/forms/Area'
import InputUrl from 'components/forms/InputUrl'
import InputAddon from 'components/forms/InputAddon'
import { useNavigate } from 'hooks/navigate'


const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())


function useElementoPatrimonial({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [elementoPatrimonial, setElementoPatrimonial] = useState({})
  let [validation, setValidation] = useState(null)
  let [destino, setDestino] = useState(null)

  async function load() {
    if (!isEdit) {
      return
    }
    let reply = await elementosPatrimonialesClient.Get({ name })
    setElementoPatrimonial(reply)
    setDestino(reply.destino)
  }

  async function loadValidation(elementosPatrimoniales) {
    let reply = await validacionesClient.Get({ name: name })

    if (reply.accion === 'ACCION_CREAR') {
      if (!reply.entidad.elementoPatrimonial.codigo) {
        let year = new Date().getFullYear().toString().slice(2, 4)
        let codigos = elementosPatrimoniales
          .filter(f => f.codigo.slice(2, 4) === year)
          .map(f => f.codigo.slice(4))
          .sort()
        let maxCodigo = codigos.length ? codigos[codigos.length - 1] : '0'
        let newCodigo = parseInt(maxCodigo) + 1
        newCodigo = newCodigo.toString().padStart(5, '0')
        reply.entidad.elementoPatrimonial.codigo = year + newCodigo
      } else {
        reply.entidad.elementoPatrimonial.codigo = reply.entidad.elementoPatrimonial.codigo.substring(2)
      }
    }

    setElementoPatrimonial(reply.entidad.elementoPatrimonial)
    setDestino(reply.entidad.elementoPatrimonial.destino)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        elementoPatrimonial: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este elemento patrimonial para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/elementos-patrimoniales/${name}`)
    } else {
      history.push(`/webinterna/elementos-patrimoniales`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await elementosPatrimonialesClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await elementosPatrimonialesClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await elementosPatrimonialesClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        elementoPatrimonial,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este elemento patrimonial para que lo apruebe un administrador.')
    history.push(`/webinterna/elementos-patrimoniales`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    elementoPatrimonial,
    setElementoPatrimonial,
    destino,
    setDestino,
    validation,
    remove,
  }
}

export default function ElementosPatrimonialesEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')
  let [fincasRegistrales, setFincasRegistrales] = useState(null)

  let {
    save: saveElementoPatrimonial,
    load: loadElementoPatrimonial,
    loadValidation,
    confirmValidation,
    elementoPatrimonial,
    destino,
    setDestino,
    validation,
    remove: removeElementoPatrimonial,
  } = useElementoPatrimonial({ name })

  useNavigate(async () => {
    let { fincas } = await fincasRegistralesClient.List()
    fincas = fincas || []
    fincas = fincas.map(f => {
      return {
        value: f.name,
        url: `/webinterna/fincas-registrales/${f.name}`,
        label: f.numeroFinca,
      }
    })
    setFincasRegistrales(fincas)

    let { elementosPatrimoniales } = await elementosPatrimonialesClient.List()
    elementosPatrimoniales = elementosPatrimoniales || []

    if (isValidation) {
      await loadValidation(elementosPatrimoniales)
    } else {
      await loadElementoPatrimonial()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...elementoPatrimonial,
      ...data,
    }

    if (data.codigo && (!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR'))) {
      req.codigo = 'EP' + data.codigo
    }

    req.tipo = req.tipo || 'TIPO_UNKNOWN'
    req.destino = req.destino || 'DESTINO_UNKNOWN'
    req.estadoDesarrollo = req.estadoDesarrollo || 'ESTADO_DESARROLLO_UNKNOWN'
    req.estadoComercial = req.estadoComercial || 'ESTADO_COMERCIAL_UNKNOWN'
    
    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveElementoPatrimonial(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !elementoPatrimonial.blocked &&
            <ButtonConfirmDanger onClick={removeElementoPatrimonial}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Elemento Patrimonial
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/elementos-patrimoniales">Elementos Patrimoniales</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/elementos-patrimoniales/${name}`}>Consultar Elementos Patrimoniales</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/elementos-patrimoniales/edit/${name}`}>Editar Elemento Patrimonial</Breadcrumb>
          : <Breadcrumb url="/webinterna/elementos-patrimoniales/edit/new">Crear Nuevo Elemento Patrimonial</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={elementoPatrimonial.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:gap-x-4 mb-6">
              {(!isEdit || (isValidation && validation?.accion === 'ACCION_CREAR')) &&
                <InputAddon
                  form={form}
                  name="codigo"
                  type="number"
                  label="Código"
                  validations="minNumber:1"
                  disabled={isValidation}
                  value={elementoPatrimonial.codigo}
                  className="w-32"
                  addonText="EP"
                />
              }
              <Input form={form} name="nombre" label="Denominación" validations="required|min:3" value={elementoPatrimonial.nombre} className="flex-grow" />
              <SingleEntity
                className="flex-grow"
                form={form}
                validations="required"
                name="fincaRegistral"
                label="Finca Registral"
                header="Seleccionar finca registral"
                options={fincasRegistrales}
                value={elementoPatrimonial.fincaRegistral}
              />
              <Input form={form} name="codigoPostal" label="Código Postal" validations="required|min:3" value={elementoPatrimonial.codigoPostal} className="w-24"/>
            </div>

            <div className="flex mb-6 gap-x-4">
              <Select
                form={form}
                className="w-64"
                name="tipo"
                label="Tipo"
                options={[
                  { value: 'TIPO_VIVIENDA', label: 'Vivienda' },
                  { value: 'TIPO_VIVIENDA_PP', label: 'Vivienda PP' },
                  { value: 'TIPO_LOCAL', label: 'Local' },
                  { value: 'TIPO_NAVE', label: 'Nave' },
                  { value: 'TIPO_HOTEL', label: 'Hotel' },
                  { value: 'TIPO_OFICINA', label: 'Oficina' },
                  { value: 'TIPO_TRASTERO', label: 'Trastero' },
                  { value: 'TIPO_ALMACEN', label: 'Almacén' },
                  { value: 'TIPO_GARAJE', label: 'Garaje' },
                  { value: 'TIPO_TERRENO', label: 'Terreno' },
                  { value: 'TIPO_INSTALACION_AGRICOLA', label: 'Instalación agrícola' },
                  { value: 'TIPO_VALLA', label: 'Valla' },
                  { value: 'TIPO_KIOSCO', label: 'Kiosco' },
                  { value: 'TIPO_STAND', label: 'Stand' },
                  { value: 'TIPO_OTROS', label: 'Otros' },
                ]}
                value={elementoPatrimonial.tipo ? elementoPatrimonial.tipo : ''}
              />
              <Select
                form={form}
                className="w-64"
                name="destino"
                label="Destino"
                options={[
                  { value: 'DESTINO_ARRENDAMIENTO', label: 'Arrendamiento' },
                  { value: 'DESTINO_VENTA', label: 'Venta' },
                  { value: 'DESTINO_USO_PROPIO', label: 'Uso propio' },
                ]}
                value={elementoPatrimonial.destino ? elementoPatrimonial.destino : ''}
                onChange={setDestino}
              />
              <Select
                form={form}
                className="w-64"
                name="estadoDesarrollo"
                label="Estado de desarrollo"
                options={[
                  { value: 'ESTADO_DESARROLLO_PROYECTO', label: 'Proyecto' },
                  { value: 'ESTADO_DESARROLLO_OBRAS_EN_CURSO', label: 'Obras en curso' },
                  { value: 'ESTADO_DESARROLLO_EN_SERVICIO', label: 'En servicio' },
                ]}
                value={elementoPatrimonial.estadoDesarrollo ? elementoPatrimonial.estadoDesarrollo : ''}
              />

              {destino !== 'DESTINO_USO_PROPIO' && <Select
                form={form}
                className="w-64"
                name="estadoComercial"
                label="Estado comercial"
                options={[
                  { value: 'ESTADO_COMERCIAL_DISPONIBLE', label: 'Disponible' },
                  { value: 'ESTADO_COMERCIAL_NO_DISPONIBLE', label: 'No disponible' },
                ]}
                value={elementoPatrimonial.estadoComercial ? elementoPatrimonial.estadoComercial : ''}
              />}
            </div>

            <div className="flex mb-6 gap-x-4 gap-y-6 flex-wrap">
              <Area form={form} name="metrosFachada" label="Fachada" value={elementoPatrimonial.metrosFachada} />
              <Input form={form} type="number" name="bloque" label="Bloque" value={elementoPatrimonial.bloque} className="w-24"/>
              <Input form={form} type="number" name="portal" label="Portal" value={elementoPatrimonial.portal} className="w-24"/>
              <Input form={form} type="number" name="escalera" label="Escalera" value={elementoPatrimonial.escalera} className="w-24"/>
              <Input form={form} type="number" name="planta" label="Planta" value={elementoPatrimonial.planta} className="w-24"/>
              <Input form={form} name="puerta" label="Puerta" value={elementoPatrimonial.puerta} className="w-24"/>
              <Area form={form} name="sueloOcupado" label="Suelo ocupado" value={elementoPatrimonial.sueloOcupado}/>
              <Area form={form} name="superficieComputable" label="Computable" value={elementoPatrimonial.superficieComputable}/>
              <Area form={form} name="construidoSinTerrazas" label="Construida sin terrazas" value={elementoPatrimonial.construidoSinTerrazas} />
              <Area form={form} name="construidoTerrazas" label="Construida terrazas" value={elementoPatrimonial.construidoTerrazas} />
              <Area form={form} name="construidoPpzzcc" label="Construida pp.zz.cc." value={elementoPatrimonial.construidoPpzzcc} />
              <Area form={form} name="utilesTerrazas" label="Útil terrazas" value={elementoPatrimonial.utilesTerrazas} />
              <Area form={form} name="utilesInterior" label="Útil interior" value={elementoPatrimonial.utilesInterior} />
              <Input form={form} type="number" name="dormitorios" label="Dormitorios" value={elementoPatrimonial.dormitorios} className="w-24"/>
              <Input form={form} type="number" name="banos" label="Baños" value={elementoPatrimonial.banos} className="w-24"/>
              <InputUrl form={form} name="cuadroSuperficies" label="Cuadro de superficies" value={elementoPatrimonial.cuadroSuperficies} className="w-1/2" />
            </div>

            <HtmlEditor form={form} name="observaciones" label="Observaciones" value={elementoPatrimonial.observaciones} className="mb-6" />
          </fieldset>
        </Card>

        {!elementoPatrimonial.blocked &&
          <Submit form={form} validation={validation}>Guardar Elemento Patrimonial</Submit>
        }
      </Form>
    </PageLayout>
  )
}
