// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: WebInterna/contratos/contratos.proto

import { Caller } from '@altipla/grpc-browser';


export default class ContratosClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/contratos`, req, false, []);
  }

  Get(req) {
    return this._caller.send('GET', `/contratos/${req.name}`, req, false, ['name']);
  }

  Create(req) {
    return this._caller.send('POST', `/contratos`, req, true, []);
  }

  Update(req) {
    return this._caller.send('PUT', `/contratos/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    return this._caller.send('DELETE', `/contratos/${req.name}`, req, false, ['name']);
  }
};

