
import React from 'react'
import { Link } from 'react-router-dom'

import Face from 'components/people/Face'


export default function PeopleSingleViewer({ value }) {
  if (!value) {
    return ''
  }

  return (
    <Link
      to={`/webinterna/personas/${value.name}`}
      className="relative rounded-lg border border-gray-300 bg-white p-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 cursor-pointer"
    >
      <div className="flex-shrink-0">
        <Face displayName={value.nombre} pictureUrl={value.pictureUrl}></Face>
      </div>
      <div className="flex-1 min-w-0">
        <div className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{value.nombre}</p>
          <p className="text-sm font-normal text-gray-500 truncate">{value.identidad}</p>
        </div>
      </div>
    </Link>
  )
}
