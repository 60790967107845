
import React from 'react'
import Select from 'react-select'


let customStyles = {
  multiValueRemove: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: ' 0.875rem',
  }),
}


export default function Selector({ label, name, className, value, multiple, options, onChange, placeholder }) {
  if (!options) {
    options = []
  }

  let val
  if (multiple) {
    val = value?.map(v => options.find(o => o.value === v))
  } else {
    val = options.find(o => o.value === value)
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block font-medium text-gray-700 text-sm"
      >{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Select
          name={name}
          id={name}
          value={val}
          isMulti={multiple}
          isClearable={false}
          options={options}
          styles={customStyles}
          noOptionsMessage={() => 'No hay opciones que cumplan con el filtro'}
          placeholder={placeholder}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral30: 'neutral20',
            },
          })}
          onChange={(selected) => {
            if (multiple) {
              onChange(selected?.map(s => s.value))
            } else {
              onChange(selected?.value)
            }
          }}
        ></Select>
      </div>
    </div>
  )
}
