
import React from 'react'
import cx from 'classnames'


export default function EditableSelection({ className, icon, title, subtitle, action }) {
  return (
    <>
      <div className={cx('mt-4', className)}>
        <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-shrink-0 text-gray-400 text-3xl">
              {icon}
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-5">
              <div className="text-sm font-medium text-gray-900">{title}</div>
              <div className="mt-1 text-sm text-gray-600">{subtitle}</div>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">{action}</div>
        </div>
      </div>
    </>
  )
}
