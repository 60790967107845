
import React, { useState, useEffect } from 'react'

import GeneratedAvatar from 'components/people/GeneratedAvatar'
import { nanoid } from 'nanoid'


const MAX_FACES = 3


export default function Faces({ people }) {
  let [remaining, setRemaining] = useState(0)
  let [faces, setFaces] = useState([])

  useEffect(() => {
    if (!people) {
      setFaces([])
      return
    }

    if (people.length > MAX_FACES) {
      setRemaining(people.length - MAX_FACES)
    }

    setFaces(people
      .map(person => {
        return {
          ...person,
          ghostKey: nanoid(),
        }
      })
      .slice(0, MAX_FACES),
    )
  }, [people, setFaces, setRemaining])

  return (
    <>
      {!!faces.length &&
        <div className="xl:flex xl:items-center xl:mt-0 w-28">
          <div className="flex overflow-hidden -space-x-2">
            {faces.map(item => (
              <div key={item.ghostKey}>
                {item.pictureUrl
                  ?
                  <img
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={item.pictureUrl}
                    alt={`${item.nombre}`}
                  />
                  :
                  <GeneratedAvatar displayName={item.nombre}></GeneratedAvatar>
                }
              </div>
            ))}
          </div>
          {!!remaining &&
            <span className="ml-1">+{remaining}</span>
          }
        </div>
      }
    </>
  )
}
