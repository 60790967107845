
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { getEntity, putEntity } from 'models/docs/entities'
import { users } from 'services/discovery'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { InfoPane } from 'views/docs/upload/internal/InfoPane'
import { Sidebar, UPLOAD } from 'views/docs/upload/internal/Sidebar'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import Icon from 'components/Icon'
import Card from 'components/Card'
import { parseTimestamp } from 'platform/datetime'
import PageLayout from 'layout/docs/PageLayout'
import { datetime } from 'platform/filters'
import { accountInfo } from 'auth/active-directory'
import Face from 'components/people/Face'
import UsersClient from 'services/Users/users/users'


const usersClient = new UsersClient(users())


export function useUpdate() {
  let { code } = useParams()

  let [doc, setDoc] = useState(null)
  let [requester, setRequester] = useState(null)
  let [revision, setRevision] = useState(null)
  let [status, setStatus] = useState(UPLOAD.LOADING)

  async function load(request, { comment } = {}) {
    let doc = await getEntity(`documents/${code}`)
    // TODO(ernesto): Check current user against doc.upload for permission.
    // TODO(ernesto): Check doc state against STATE_REVIEWED

    setDoc({
      ...doc,
      createTime: parseTimestamp(doc.createTime),
    })
    setRevision({
      ...request,
      comment,
      createTime: parseTimestamp(request.createTime),
    })
    setRequester(await usersClient.Get({ name: request.user }))

    setStatus(UPLOAD.PENDING)
  }

  return {
    load,
    code,
    requester,
    revision,
    doc,
    status,
    setStatus,
  }
}


export function Update({ variant, requester, revision, doc, code, status, setStatus, description }) {
  let history = useHistory()
  let [file, setFile] = useState(null)

  async function submit() {
    let revision = {
      parent: doc.name,
      user: accountInfo().name,
      reviewUpload: {
        file: file.name,
      },
    }
    await putEntity('revisions', { revision })
    history.push('/docs')
  }

  if (status === UPLOAD.LOADING) {
    return (<></>)
  }
  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url={`/docs/upload/${variant}/${code}`}>Actualizar documento</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="flex space-x-16">
        <div className="flex-grow">
          {doc &&
            <>
              <div className="mx-auto md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex items-center space-x-5">
                  <div>
                    <h1 className="text-3xl font-bold text-gray-900">{doc.title || doc.suggestedTitle}</h1>
                  </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                  <ButtonAsyncPrimary disabled={!file} onClick={() => submit()}>
                    <Icon solid name="file-upload" className="mr-2" />
                    Enviar cambio
                  </ButtonAsyncPrimary>
                </div>
              </div>

              <Card
                title="Información general"
                subtitle="Información general del documento."
                className="mt-8"
              >
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                  <InfoPane title="Formato">
                    {doc.format === 'FORMAT_DIGITAL' && 'Digital'}
                    {doc.format === 'FORMAT_PHYSICAL' && 'Físico'}
                  </InfoPane>

                  <InfoPane title="Fecha de creación">
                    {datetime(doc.createTime, 'DATETIME')}
                  </InfoPane>
                </div>
              </Card>

              <Card
                title="Petición de cambio"
                subtitle="Información sobre la petición del cambio requerida."
                className="mt-8"
              >
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                  <InfoPane title="Responsable">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <Face displayName={requester.displayName || ''} pictureUrl={requester.pictureUrl} large></Face>
                      </div>
                      <div className="ml-3">
                        <div className="text-base text-gray-800">{requester.displayName}</div>
                        <div className="text-sm text-gray-500">{requester.email}</div>
                      </div>
                    </div>
                  </InfoPane>

                  <InfoPane title="Fecha de la petición">
                    {datetime(revision.createTime, 'DATETIME')}
                  </InfoPane>

                  <InfoPane title="Razón">
                    {description}
                  </InfoPane>

                  {revision.comment &&
                    <InfoPane title="Comentario" className="col-span-2">
                      {revision.comment}
                    </InfoPane>
                  }
                </div>
              </Card>
            </>
          }
        </div>
        <div className="w-128 flex-shrink-0">
          <Sidebar status={status} setStatus={setStatus} onUploadFile={setFile} />
        </div>
      </div>
    </PageLayout>
  )
}
