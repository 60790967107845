
export default {
  all(requests) {
    let keys = []
    let promises = []
    for (let key in requests) {
      keys.push(key)
      promises.push(requests[key])
    }
    return Promise.all(promises).then(reply => {
      let result = {}
      keys.forEach((key, index) => {
        result[key] = reply[index]
      })
      return result
    })
  },
}
