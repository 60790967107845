
import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import cx from 'classnames'

import { logout, accountInfo } from 'auth/active-directory'
import Face from 'components/people/Face'
import ClickOutside from 'components/ClickOutside'


function Menu({ buttonClassName }) {
  let [showMenu, setShowMenu] = useState(false)

  return (
    <ClickOutside onClick={() => setShowMenu(false)}>
      <div className="ml-4 relative flex-shrink-0">
        <div>
          <button
            className={cx(
              'rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500',
              buttonClassName,
            )}
            onClick={() => setShowMenu(!showMenu)}
          >
            <Face displayName={accountInfo().displayName} pictureUrl={accountInfo().pictureUrl}></Face>
          </button>
        </div>
        <Transition
          show={showMenu}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          className="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-10"
        >
          <div className="block mt-2 px-4 pb-3 border-b border-gray-300">
            <div className="font-medium text-gray-800 truncate">{accountInfo().displayName}</div>
            <div className="text-sm text-gray-400 truncate">{accountInfo().email}</div>
          </div>
          <button
            className="block w-full text-left px-4 py-2 font-normal text-sm text-gray-700 hover:text-gray-700 hover:bg-gray-100"
            onClick={() => logout()}
          >Cerrar sesión</button>
        </Transition>
      </div>
    </ClickOutside>
  )
}


export function ProfileInvert() {
  return (
    <Menu buttonClassName="focus:ring-offset-gray-800" />
  )
}


export function Profile() {
  return (
    <Menu />
  )
}
