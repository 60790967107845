
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { webinterna } from 'services/discovery'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import ProcesosClient from 'services/WebInterna/procesos/procesos'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import CardHeader from 'components/CardHeader'
import ExternalLink from 'components/ExternalLink'
import HtmlViewer from 'components/HtmlViewer'
import { PeopleGrid } from 'components/people'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { WideList, WideListEmpty, WideListItem } from 'components/WideList'
import PeopleViewer from 'components/people/PeopleViewer'


const procesosClient = new ProcesosClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())


export default function ProcesosShow() {
  let { name } = useParams()
  let [proceso, setProceso] = useState({
    interacciones: [],
    etiquetas: [],
    gestiones: [],
  })

  useNavigate(async () => {
    let proceso = await procesosClient.Get({ name })

    if (proceso.grupo) {
      proceso.grupo = await gruposProcesosClient.Get({ name: proceso.grupo })
    }
    
    proceso.interacciones = proceso.interacciones || []
    proceso.etiquetas = proceso.etiquetas || []

    if (proceso.interacciones.length) {
      let { procesos } = await procesosClient.BatchGet({ names: proceso.interacciones })
      proceso.interacciones = procesos
    }

    setProceso(proceso)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!proceso.blocked &&
            <Acl perm="webinterna.procesos.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/procesos/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Proceso
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/procesos">Procesos</Breadcrumb>
        <Breadcrumb url={`/webinterna/procesos/${name}`}>Consultar Proceso</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={proceso.blocked}></EntityBlockedAlert>

      <CardHeader header="Información general del proceso">
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          <ShowPane title="Código">
            <span className="font-mono">{proceso.grupo?.codigo}{proceso.codigo}</span>
          </ShowPane>
          <ShowPane title="Nombre">{proceso.denominacion}</ShowPane>
          <ShowPane title="Grupo">{proceso.grupo?.nombre}</ShowPane>
          <ShowPane title="Enlace Sharepoint">
            <ExternalLink to={proceso.sitioSharepoint} className="w-80">{proceso.sitioSharepoint}</ExternalLink>
          </ShowPane>
          <ShowPane title="Propósito" className="sm:col-span-2">
            <HtmlViewer html={proceso.proposito}/>
          </ShowPane>
        </div>
      </CardHeader>

      <CardHeader header="Responsables" className="mt-4">
        <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 mb-4">
          <ShowPane title="Cuidador">
            {proceso.cuidador && <PeopleViewer value={{ persona: proceso.cuidador }}></PeopleViewer>}
          </ShowPane>
          <ShowPane title="Analista">
            {proceso.analista && <PeopleViewer value={{ persona: proceso.analista }}></PeopleViewer>}
          </ShowPane>
        </div>

        <ShowPane title="Gerentes" className="mb-4">
          <PeopleGrid>
            {proceso.gerentes?.length && <PeopleViewer value={{ persona: proceso.gerentes[0] }}/>}
            {proceso.gerentes?.length > 1 && <PeopleViewer value={{ persona: proceso.gerentes[1] }}/>}
          </PeopleGrid>
        </ShowPane>
      </CardHeader>

      <CardHeader fit header="Gestores" className="mt-4">
        <WideList divider="divide-y-4 divide-teal-400">
          {proceso.gestores?.length ?
            proceso.gestores.map((gestor, index) => (
              <WideListItem key={index}>
                <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                  <ShowPane title="Gestor">
                    {gestor.contribuidor && 
                      <PeopleViewer value={{ persona: gestor.contribuidor }}></PeopleViewer>
                    }
                  </ShowPane>
                  <ShowPane title="Rol">
                    {gestor.rol}
                  </ShowPane>
                </div>
              </WideListItem>
            ))
            :
            <WideListEmpty>No hay gestores</WideListEmpty>
          }
        </WideList>
      </CardHeader>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
        <CardHeader fit header="Actividades" className="mt-4">
          <WideList divider="divide-y-4 divide-teal-400">
            {proceso.actividades?.length ?
              proceso.actividades.map((actividad, index) => (
                <WideListItem key={index}>
                  <HtmlViewer html={actividad}/>
                </WideListItem>
              ))
              :
              <WideListEmpty>No hay actividades</WideListEmpty>
            }
          </WideList>
        </CardHeader>
        <CardHeader fit header="KPIs" className="mt-4">
          <WideList divider="divide-y-4 divide-teal-400">
            {proceso.kpis?.length ?
              proceso.kpis.map((kpi, index) => (
                <WideListItem key={index}>
                  <HtmlViewer html={kpi}/>
                </WideListItem>
              ))
              :
              <WideListEmpty>No hay KPIs</WideListEmpty>
            }
          </WideList>
        </CardHeader>
      </div>
    </PageLayout>
  )
}
