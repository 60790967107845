import React from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import { CardPaginatedTable, usePaginatedTable } from 'components/CardPaginatedTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { listEntities } from 'models/docs/entities'


export default function DocumentTypesList() {
  let { table, setRows } = usePaginatedTable()

  useNavigate(async () => {
    let rows = await listEntities('document-types')
    setRows(rows.map(row => {
      row.$search = [
        row.displayName,
        row.description,
        row.subtypes,
      ]
      return row
    }))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/document-types">Tipos de documentos</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardPaginatedTable
        title="Tipos de documentos"
        subtitle="Clasificación de los documentos de la plataforma."
        empty="No hay tipos de documentos que mostrar"
        table={table}
        actions={
          <ButtonLinkPrimary to="/manage/document-types/create">
            <Icon solid name="plus" className="mr-2" />
            Crear tipo de documento
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell>Subtipos</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        row={row => (
          <>
            <Cell>
              <Link to={`/manage/document-types/${row.name.split('/')[1]}`} className="text-gray-700">{row.displayName}</Link>
            </Cell>
            <Cell>
              <span title={row.subtypes?.join(', ')}>
                {`${row.subtypes ? row.subtypes.length : 'Sin'} ${row.subtypes?.length === 1 ? 'subtipo' : 'subtipos'}`}
              </span>
            </Cell>
            <Cell className="text-right">
              <Link to={`/manage/document-types/${row.name.split('/')[1]}`}>Editar</Link>
            </Cell>
          </>
        )}
      >
      </CardPaginatedTable>
    </PageLayout>
  )
}
