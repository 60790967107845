
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import ProcesosClient from 'services/WebInterna/procesos/procesos'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import { webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import InputUrl from 'components/forms/InputUrl'
import HtmlEditor from 'components/forms/HtmlEditor'
import CardHeader from 'components/CardHeader'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import { WideList, WideListInteractiveItem, WideListEmpty } from 'components/WideList'
import PeopleGrid from 'components/people/PeopleGrid'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import Card from 'components/Card'
import People from 'components/forms/People'
import { useNavigate } from 'hooks/navigate'


const procesosClient = new ProcesosClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())


function useProceso({ name }) {
  let isEdit = (name !== 'new')
  let history = useHistory()
  let { notifySuccess } = useNotifications()

  let [proceso, setProceso] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (!isEdit) {
      return
    }

    let proceso = await procesosClient.Get({ name })
    setProceso(proceso)
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    setProceso(reply.entidad.proceso)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        proceso: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este proceso para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/procesos/${name}`)
    } else {
      history.push(`/webinterna/procesos`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await procesosClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await procesosClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await procesosClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        proceso,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este proceso para que lo apruebe un administrador.')
    history.push(`/webinterna/procesos`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    proceso,
    validation,
    remove,
  }
}

function useGestores({ proceso }) {
  let [gestores, setGestores] = useState([])

  function add() {
    setGestores([
      ...gestores,
      {
        ghostKey: nanoid(),
        contribuidor: '',
        rol: '',
      },
    ])
  }

  function remove(gestion) {
    setGestores(gestores.filter(g => g !== gestion))
  }

  useEffect(() => {
    if (proceso?.gestores?.length) {
      setGestores(proceso.gestores.map(gestion => {
        return {
          ...gestion,
          ghostKey: nanoid(),
        }
      }))
    }
  }, [setGestores, proceso])

  return {
    gestores,
    add,
    remove,
  }
}

function useActividades({ proceso }) {
  let [actividades, setActividades] = useState([])

  function add() {
    setActividades([
      ...actividades,
      '',
    ])
  }

  function remove(actividad) {
    setActividades(actividades.filter(a => a !== actividad))
  }

  useEffect(() => {
    if (proceso?.actividades?.length) {
      setActividades(proceso.actividades)
    }
  }, [setActividades, proceso])

  return {
    actividades,
    add,
    remove,
  }
}

function useKPIs({ proceso }) {
  let [kpis, setKPIs] = useState([])

  function add() {
    setKPIs([
      ...kpis,
      '',
    ])
  }

  function remove(kpi) {
    setKPIs(kpi.filter(k => k !== kpi))
  }

  useEffect(() => {
    if (proceso?.kpis?.length) {
      setKPIs(proceso.kpis)
    }
  }, [setKPIs, proceso])

  return {
    kpis,
    add,
    remove,
  }
}

function useGerentes({ proceso }) {
  let [gerentes, setGerentes] = useState([])

  function set(gerente, index) {
    if (gerentes.length > index) {
      gerentes[index] = gerente
      setGerentes(gerentes)
    } else {
      setGerentes([
        ...gerentes,
        gerente,
      ])
    }
  }

  useEffect(() => {
    if (proceso?.gerentes?.length) {
      setGerentes(proceso.gerentes)
    }
  }, [setGerentes, proceso])

  return {
    gerentes,
    set,
  }
}

export default function ProcesosEdit({ validation: isValidation }) {
  let { name } = useParams()
  let isEdit = (name !== 'new')

  let [gruposProcesos, setGruposProcesos] = useState([])

  let {
    save: saveProceso,
    load: loadProceso,
    loadValidation,
    confirmValidation,
    proceso,
    validation,
    remove: removeProceso,
  } = useProceso({ name })

  let {
    gestores,
    add: addGestor,
    remove: removeGestor,
  } = useGestores({ proceso })

  let {
    actividades,
    add: addActividad,
    remove: removeActividad,
  } = useActividades({ proceso })

  let {
    kpis,
    add: addKPI,
    remove: removeKPI,
  } = useKPIs({ proceso })

  let {
    gerentes,
    set: setGerentes,
  } = useGerentes({ proceso })

  useNavigate(async () => {

    let gruposProcesos = await gruposProcesosClient.List()
    setGruposProcesos(Object.values(gruposProcesos.grupos || []).map(g => {
      return {
        value: g.name,
        label: g.nombre,
      }
    }))

    if (isValidation) {
      await loadValidation()
    } else {
      await loadProceso()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...proceso,
      ...data,
      gerentes: gerentes.filter(gerente => gerente),
      analista: data.analista,
      cuidador: data.cuidador,
      gestores: data.gestores,
    }

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await saveProceso(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !proceso.blocked &&
            <ButtonConfirmDanger onClick={removeProceso}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Proceso
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/procesos">Procesos</Breadcrumb>
        {isEdit && <Breadcrumb url={`/webinterna/procesos/${name}`}>Consultar Proceso</Breadcrumb>}
        {isEdit
          ? <Breadcrumb url={`/webinterna/procesos/edit/${name}`}>Editar Proceso</Breadcrumb>
          : <Breadcrumb url="/webinterna/procesos/edit/new">Crear Nuevo Proceso</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={proceso.blocked}></EntityBlockedAlert>

      <Form form={form}>
        <Card>
          <fieldset>
            <div className="md:flex md:space-x-4">
              <Select
                form={form}
                className="mb-6"
                name="grupo"
                label="Grupo"
                validations="required"
                options={gruposProcesos}
                value={proceso.grupo}
              />
              <Input form={form} name="codigo" label="Código" validations="required" value={proceso.codigo} className="w-24 mb-6" />
              <Input form={form} name="denominacion" label="Nombre Proceso" validations="required|min:3" value={proceso.denominacion} className="flex-1 mb-6 md:ml-2" />
            </div>
            
            <InputUrl form={form} name="sitioSharepoint" label="Enlace SharePoint" value={proceso.sitioSharepoint} className="mb-6" />
            <HtmlEditor form={form} name="proposito" label="Propósito" value={proceso.proposito} className="mb-6" />
          </fieldset>

          <fieldset>
            <legend className="text-base leading-6 font-medium text-gray-900">Responsables</legend>
            <PeopleGrid className="mt-2">
              <People
                form={form}
                name="cuidador"
                label="Cuidador"
                restrict="CONTRIBUIDOR, FISICA, JURIDICA"
                value={proceso.cuidador}
              ></People>
              <People
                form={form}
                name="analista"
                label="Analista"
                restrict="CONTRIBUIDOR, FISICA, JURIDICA"
                value={proceso.analista}
              ></People>
              <People
                form={form}
                name="gerentes[0]"
                label="Gerente 1"
                restrict="CONTRIBUIDOR, FISICA, JURIDICA"
                value={gerentes[0]}
                onChange={(value) => setGerentes(value, 0)}
              ></People>
              {gerentes[0] &&
                <People
                  form={form}
                  name="gerentes[1]"
                  label="Gerente 2"
                  restrict="CONTRIBUIDOR, FISICA, JURIDICA"
                  value={gerentes[1]}
                  onChange={(value) => setGerentes(value, 1)}
                ></People>
              }
            </PeopleGrid>
          </fieldset>

        </Card>

        <CardHeader
          fit
          className="mt-4"
          header="Gestores"
          subheader="Personas encargadas del proceso y su grado de implicación en él."
        >
          <WideList divider="divide-y-4 divide-teal-400">
            {!gestores.length && <WideListEmpty>No hay gestores encargados del proceso actualmente</WideListEmpty>}
            {gestores.map((gestor, index) => (
              <WideListInteractiveItem
                key={gestor.ghostKey}
                actions={
                  <div className="self-start">
                    <ButtonHoverDanger onClick={() => removeGestor(gestor)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                  </div>
                }
              >
                <fieldset className="mb-6">
                  <legend className="text-base leading-6 font-medium text-gray-900">Gestor {index + 1}</legend>
                </fieldset>

                <div className="grid grid-cols-2 space-x-4">
                  <div>
                    <People
                      form={form}
                      name={`gestores[${index}].contribuidor`}
                      label="Contribuidor"
                      restrict="CONTRIBUIDOR"
                      value={gestor.contribuidor}
                    ></People>
                  </div>
                  <Input form={form} name={`gestores[${index}].rol`} label="Rol" value={gestor.rol} className="mt-6" />
                </div>
              </WideListInteractiveItem>
            ))}
            <WideListInteractiveItem>
              <ButtonSecondary onClick={addGestor}>
                <Icon solid name="plus" className="mr-2" />
                Añadir gestor
              </ButtonSecondary>
            </WideListInteractiveItem>
          </WideList>
        </CardHeader>

        <div className="grid grid-cols-2 gap-x-4 mt-4">
          <CardHeader
            fit
            header="Actividades"
            subheader="Actividades del proceso."
          >
            <WideList divider="divide-y-4 divide-teal-400">
              {!actividades.length && <WideListEmpty>Este proceso no tiene actividades actualmente</WideListEmpty>}
              {actividades.map((actividad, index) => (
                <WideListInteractiveItem
                  key={index}
                  actions={
                    <div className="self-start">
                      <ButtonHoverDanger onClick={() => removeActividad(actividad)}>
                        <Icon solid name="trash" />
                      </ButtonHoverDanger>
                    </div>
                  }
                >
                  <fieldset>
                    <legend className="text-base leading-6 font-medium text-gray-900">Actividad {index + 1}</legend>
                  </fieldset>
                  <HtmlEditor form={form} name={`actividades[${index}]`} value={actividad}/>
                </WideListInteractiveItem>
              ))}
              <WideListInteractiveItem>
                <ButtonSecondary onClick={addActividad}>
                  <Icon solid name="plus" className="mr-2" />
                  Añadir actividad
                </ButtonSecondary>
              </WideListInteractiveItem>
            </WideList>
          </CardHeader>

          <CardHeader
            fit
            header="KPIs"
            subheader="Indicadores clave de rendimiento."
          >
            <WideList divider="divide-y-4 divide-teal-400">
              {!kpis.length && <WideListEmpty>Este proceso no tiene KPIs actualmente</WideListEmpty>}
              {kpis.map((kpi, index) => (
                <WideListInteractiveItem
                  key={index}
                  actions={
                    <div className="self-start">
                      <ButtonHoverDanger onClick={() => removeKPI(kpi)}>
                        <Icon solid name="trash" />
                      </ButtonHoverDanger>
                    </div>
                  }
                >
                  <fieldset>
                    <legend className="text-base leading-6 font-medium text-gray-900">KPI {index + 1}</legend>
                  </fieldset>
                  <HtmlEditor form={form} name={`kpis[${index}]`} value={kpi}/>
                </WideListInteractiveItem>
              ))}
              <WideListInteractiveItem>
                <ButtonSecondary onClick={addKPI}>
                  <Icon solid name="plus" className="mr-2" />
                  Añadir KPI
                </ButtonSecondary>
              </WideListInteractiveItem>
            </WideList>
          </CardHeader>
        </div>

        {!proceso.blocked &&
          <Submit validation={validation} form={form}>Guardar Proceso</Submit>
        }
      </Form>
    </PageLayout>
  )
}
