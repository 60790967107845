
import React from 'react'
import firstBy from 'thenby'
import { Link } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'

import ProcesosClient from 'services/WebInterna/procesos/procesos'
import { webinterna } from 'services/discovery'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { Datatable, Cell, HeaderCell, useDatatable, RowMobile } from 'components/Datatable'
import Faces from 'components/people/Faces'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { SharePointIcon } from 'components/SharePointIcon'
import PeopleViewer from 'components/people/PeopleViewer'
import GruposProcesosClient from 'services/WebInterna/grupos_procesos/grupos_procesos'
import resolver from 'platform/resolver'
import ExportCSV from 'components/ExportCSV'
import { batchGetPersonas } from 'models/webinterna/personas'


const procesosClient = new ProcesosClient(webinterna())
const gruposProcesosClient = new GruposProcesosClient(webinterna())


export default function Procesos() {
  let { table, setRows, useAsyncLoad, setSortOrders } = useDatatable()

  useAsyncLoad(async () => {
    setSortOrders([
      {
        name: 'Orden alfabético',
        sort: firstBy('denominacion'),
      },
      {
        name: 'Por código',
        sort: firstBy('fullCodigo').thenBy('denominacion'),
      },
    ])

    let { procesos, grupos } = await resolver.all({
      procesos: procesosClient.List().then(reply => reply.procesos || []),
      grupos: gruposProcesosClient.List().then(reply => reply.grupos || []),
    })

    grupos = keyBy(grupos, 'name')
    procesos.forEach(proceso => {
      proceso.grupo = grupos[proceso.grupo]
    })

    let names = []
    procesos.forEach(proceso => {
      if (proceso.analista) {
        names.push(proceso.analista)
      }
      if (proceso.gestores) {
        names.push(...proceso.gestores.map(gestor => gestor.contribuidor).filter(v => v))
      }
      if (proceso.cuidador) {
        names.push(proceso.cuidador)
      }
      if (proceso.gerentes) {
        names.push(...proceso.gerentes)
      }
    })

    let personas = await batchGetPersonas(uniq(names))
    personas = keyBy(personas, 'name')
    procesos.forEach(proceso => {
      if (proceso.analista) {
        proceso.analista = personas[proceso.analista]
      }
      if (proceso.gestores) {
        proceso.gestores = proceso.gestores.map(gestor => personas[gestor.contribuidor]).filter(g => g)
      }
      if (proceso.cuidador) {
        proceso.cuidador = personas[proceso.cuidador]
      }
      if (proceso.gerentes) {
        proceso.gerentes = proceso.gerentes.map(gerente => personas[gerente])
      }

      proceso.fullCodigo = (proceso.grupo?.codigo ?? '') + proceso.codigo
    })

    procesos.forEach(proceso => {
      proceso.$search = [
        proceso.denominacion,
        proceso.fullCodigo,
      ]
    })

    setRows(procesos)
  })

  function getProcesosCSV(rows) {
    return rows.map(p => {
      return {
        'Código': `${p.grupo?.codigo ? p.grupo?.codigo : ''}${p.codigo}`,
        'Denominación': p.denominacion,
        'Analista': p.analista?.nombre,
        'Analista tipo identidad': p.analista?.tipoIdentidad,
        'Analista identidad': p.analista?.identidad,
        'Cuidador': p.cuidador?.nombre,
        'Cuidador tipo identidad': p.cuidador?.tipoIdentidad,
        'Cuidador identidad': p.cuidador?.identidad,
        'Gerentes': p.gerentes?.map(g => g.nombre),
        'Gestores': p.gestores?.filter(g => g).map(g => g.nombre),
      }
    })
  }

  return (
    <PageLayout wide>
      <PageHeader
        buttons={
          <>
            <Acl perm="webinterna.procesos.propose-edit">
              <ButtonLinkPrimary to="/webinterna/procesos/edit/new">
                <Icon solid name="plus" className="mr-2" />
                Crear Nuevo Proceso
              </ButtonLinkPrimary>
            </Acl>
            <ExportCSV
              className="ml-3"
              data={getProcesosCSV(table.filteredRows)}
              filename="Procesos"
            />
          </>
        }
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/procesos">Procesos</Breadcrumb>
      </PageHeader>

      <Datatable
        table={table}
        header={
          <>
            <HeaderCell fit="right">Código</HeaderCell>
            <HeaderCell>Denominación</HeaderCell>
            <HeaderCell>Gerentes</HeaderCell>
            <HeaderCell>Analista</HeaderCell>
            <HeaderCell>Cuidador</HeaderCell>
            <HeaderCell>Gestores</HeaderCell>
            <HeaderCell>Sitio SPS</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
        rowMobile={row =>
          <RowMobile to={`/webinterna/procesos/${row.name}`}>
            <div>
              {row.fullCodigo}
              &nbsp;&mdash;&nbsp;
              <span className="font-medium text-gray-900">{row.denominacion}</span>
            </div>
          </RowMobile>
        }
        row={row => (
          <>
            <Cell fit="right" className="font-medium text-right">
              {row.fullCodigo}
            </Cell>
            <Cell wrap expand>
              <div>
                <Link
                  to={`/webinterna/procesos/${row.name}`}
                  className="font-medium text-gray-900"
                >
                  {row.denominacion}
                </Link>
              </div>
            </Cell>
            <Cell>
              <div className="flex space-x-2">
                {row.gerentes && <PeopleViewer value={{ persona: row.gerentes[0].name }} className="w-56"/>}
                {row.gerentes?.length > 1 && <PeopleViewer value={{ persona: row.gerentes[1].name }} className="w-56"/>}
              </div>
            </Cell>
            <Cell>
              {row.analista && <PeopleViewer value={{ persona: row.analista.name }} className="w-56"/>}
            </Cell>
            <Cell>
              {row.cuidador && <PeopleViewer value={{ persona: row.cuidador.name }} className="w-56"/>}
            </Cell>
            <Cell>
              <div className="flex">
                <Faces people={row.gestores} />
              </div>
            </Cell>
            <Cell className="md:text-xs">
              {row.sitioSharepoint &&
                <a href={row.sitioSharepoint}>
                  <SharePointIcon className="mx-auto"/>
                </a>
              }
            </Cell>
            <Cell className="text-right">
              <Acl perm="webinterna.procesos.propose-edit">
                <Link
                  to={`/webinterna/procesos/edit/${row.name}`}
                >
                  Editar
                </Link>
              </Acl>
            </Cell>
          </>
        )}
      />
    </PageLayout>
  )
}
