
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'hooks/navigate'
import { webinterna } from 'services/discovery'
import CardTable from 'components/CardTable'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { TableRow, HeaderCell, Cell } from 'components/Table'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import CentrosTrabajoClient from 'services/WebInterna/centros_trabajo/centros_trabajo'


const client = new CentrosTrabajoClient(webinterna())


export default function CentrosTrabajoList() {
  let [centros, setCentros] = useState([])

  useNavigate(async () => {
    setCentros(await client.List().then(reply => reply.centros || []))
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/centros-trabajo">Centros de trabajo</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <CardTable
        title="Centros de trabajo"
        subtitle="Las diferentes sedes de trabajo que posee el grupo."
        empty="No hay centros de trabajo registrados."
        actions={
          <ButtonLinkPrimary to="/manage/centros-trabajo/create">
            <Icon solid name="plus" className="mr-2" />
            Crear centro
          </ButtonLinkPrimary>
        }
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell></HeaderCell>
          </>
        }
      >
        {centros.map(centro => (
          <TableRow key={centro.name}>
            <Cell>
              <Link to={`/manage/centros-trabajo/${centro.name}`} className="text-gray-700">{centro.nombre}</Link>
            </Cell>
            <Cell className="text-right" fit="left">
              <Link to={`/manage/centros-trabajo/${centro.name}`}>Editar</Link>
            </Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
