
import React from 'react'


export default function EmptyText({ children }) {
  return (
    <>
      <div className="text-center text-lg italic text-gray-400 py-20">{children}</div>
    </>
  )
}
