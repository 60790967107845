
import React from 'react'
import Select from 'react-select'
import cx from 'classnames'

import { useField } from 'platform/forms'


let customStyles = {
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
}


export default function Selector({ form, label, name, className, value, multiple, options, onChange, validations, help }) {
  let field = useField({ form, name, validations, value, onChange })

  if (!options) {
    options = []
  }

  let val
  if (multiple) {
    val = value?.map(v => options.find(o => o.value === v))
  } else {
    val = options.find(o => o.value === value)
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cx(
          'block font-medium text-gray-700 text-sm',
          {
            'text-red-700': field.hasError,
          },
        )}
      >
        {label}
        {field.isRequired && label && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Select
          name={name}
          id={name}
          value={val}
          isMulti={multiple}
          isClearable={false}
          options={options}
          styles={customStyles}
          noOptionsMessage={() => 'No hay opciones que cumplan con el filtro'}
          placeholder=""
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral30: 'neutral20',
            },
          })}
          onChange={(selected) => {
            // field.setValue(parseValue(e.target.value))
            if (multiple) {
              field.setValue(selected?.map(s => s.value))
            } else {
              field.setValue(selected?.value)
            }
          }}
        ></Select>
      </div>
      {help && <p className="mt-2 text-gray-500">{help}</p>}
      {field.hasError && <p className="mt-2 text-red-700">{field.errorMessage}</p>}
    </div>
  )
}
