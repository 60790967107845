
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'

import { webinterna } from 'services/discovery'
import PresupuestosClient from 'services/WebInterna/presupuestos/presupuestos'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import ValidacionesClient from 'services/WebInterna/validaciones/validaciones'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ButtonConfirmDanger from 'components/buttons/ButtonConfirmDanger'
import { useNotifications } from 'hooks/notifications'
import { useForm } from 'platform/forms'
import Form from 'components/forms/Form'
import Submit from 'views/webinterna/internal/Submit'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import PageLayout from 'layout/webinterna/PageLayout'
import Presupuesto, { parsePercentage, parseInteger, printInteger, printPercentage, printMoney, printArea, printRatio } from '@azpool/presupuesto'
import CardTable from 'components/CardTable'
import { Table, Cell, TableRow, HeaderCell } from 'components/Table'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import { decimal } from 'platform/filters'
import Select from 'components/forms/Select'
import Datepicker from 'components/forms/Datepicker'
import InputUrl from 'components/forms/InputUrl'
import Textarea from 'components/forms/Textarea'
import Percent from 'components/forms/Percent'
import Price from 'components/forms/Price'
import { parseDate, serializeDate } from 'platform/datetime'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import InputRatio from 'components/forms/InputRatio'
import Area from 'components/forms/Area'
import { useNavigate } from 'hooks/navigate'


const USOS = {
  VIVIENDA_VENTA: 'Vivienda Venta',
  GARAJES_VENTA: 'Garajes Venta',
  TRASTEROS_VENTA: 'Trasteros Venta',
  VIVIENDAS_ARRENDAMIENTO: 'Viviendas Arrendamiento',
  GARAJES_ARRENDAMIENTO: 'Garajes Arrendamiento',
  TRASTEROS_ARRENDAMIENTO: 'Trasteros Arrendamiento',
  COMERCIAL: 'Comercial',
  GARAJES_COMERCIAL: 'Garajes Comercial',
  INDUSTRIAL: 'Industrial',
  LOGISTICO: 'Logístico',
  HOTELERO: 'Hotelero',
  OFICINAS: 'Oficinas',
  INSTALACIONES_AGRÍCOLAS: 'Instalaciones Agrícolas',
  USO_LIBRE: 'Otros usos',
}
const PRESUPUESTO_OPTIONS = [
  { label: 'Vivienda Venta', value: 'USO_VIVIENDA_VENTA' },
  { label: 'Garajes Venta', value: 'USO_GARAJES_VENTA' },
  { label: 'Trasteros Venta', value: 'USO_TRASTEROS_VENTA' },
  { label: 'Viviendas Arrendamiento', value: 'USO_VIVIENDAS_ARRENDAMIENTO' },
  { label: 'Garajes Arrendamiento', value: 'USO_GARAJES_ARRENDAMIENTO' },
  { label: 'Trasteros Arrendamiento', value: 'USO_TRASTEROS_ARRENDAMIENTO' },
  { label: 'Comercial', value: 'USO_COMERCIAL' },
  { label: 'Residencial y Comercial', value: 'USO_RESIDENCIAL_COMERCIAL' },
  { label: 'Industrial', value: 'USO_INDUSTRIAL' },
  { label: 'Logístico', value: 'USO_LOGISTICO' },
  { label: 'Hotelero', value: 'USO_HOTELERO' },
  { label: 'Oficinas', value: 'USO_OFICINAS' },
  { label: 'Instalaciones Agrícolas', value: 'USO_INSTALACIONES_AGRICOLAS' },
  { label: 'Otros usos', value: 'USO_LIBRE' },
]

const presupuestosClient = new PresupuestosClient(webinterna())
const validacionesClient = new ValidacionesClient(webinterna())
const proyectosClient = new ProyectosClient(webinterna())

function parsePlanTemporalPresupuesto(planTemporal) {
  if (!planTemporal) {
    return null
  }
  planTemporal.inicioFecha = parseDate(planTemporal.inicioFecha)
  planTemporal.finFecha = parseDate(planTemporal.finFecha)

  return planTemporal
}
function serializePlanTemporalPresupuesto(planTemporal) {
  planTemporal.inicioFecha = serializeDate(planTemporal.inicioFecha)
  planTemporal.finFecha = serializeDate(planTemporal.finFecha)

  return planTemporal
}


function usePresupuestos({ name, isEdit }) {
  let history = useHistory()
  let { notifySuccess } = useNotifications()
  
  let [, setVersion] = useState(0)
  let [presupuesto, setPresupuesto] = useState({})
  let [validation, setValidation] = useState(null)

  async function load() {
    if (isEdit) {
      let reply = await presupuestosClient.Get({ name })
      reply.valores = new Presupuesto(JSON.parse(reply.valores), setVersion)
      reply.elaboracionDate = parseDate(reply.elaboracionDate)
      reply.aprobacionDate = parseDate(reply.aprobacionDate)
      reply.planesTemporales.planeamiento = parsePlanTemporalPresupuesto(reply.planesTemporales.planeamiento)
      reply.planesTemporales.urbanizacion = parsePlanTemporalPresupuesto(reply.planesTemporales.urbanizacion)
      reply.planesTemporales.proyectoBasico = parsePlanTemporalPresupuesto(reply.planesTemporales.proyectoBasico)
      reply.planesTemporales.licencia = parsePlanTemporalPresupuesto(reply.planesTemporales.licencia)
      reply.planesTemporales.proyectoEjecucion = parsePlanTemporalPresupuesto(reply.planesTemporales.proyectoEjecucion)
      reply.planesTemporales.licitacion = parsePlanTemporalPresupuesto(reply.planesTemporales.licitacion)
      reply.planesTemporales.obras = parsePlanTemporalPresupuesto(reply.planesTemporales.obras)
      reply.planesTemporales.legalizacionEntrega = parsePlanTemporalPresupuesto(reply.planesTemporales.legalizacionEntrega)
      if (reply.valores.superficies.superficies.length === 0) {
        reply.valores.superficies.add('VIVIENDA_VENTA')
      }
      setPresupuesto(reply)
    } else {
      let proyecto = await proyectosClient.Get({ name })
      let presupuesto = {
        proyecto: proyecto.name,
        valores: new Presupuesto(null, setVersion),
        planesTemporales: {},
      }
      presupuesto.valores.superficies.add('VIVIENDA_VENTA')
      setPresupuesto(presupuesto)
    }
  }

  function setInicioFecha(value, field) {
    presupuesto.planesTemporales[field] = {
      ...presupuesto.planesTemporales[field],
      inicioFecha: value,
    }
    setPresupuesto({
      ...presupuesto,
    })
  }

  function setFinFecha(value, field) {
    presupuesto.planesTemporales[field] = {
      ...presupuesto.planesTemporales[field],
      finFecha: value,
    }
    setPresupuesto({
      ...presupuesto,
    })
  }

  async function loadValidation() {
    let reply = await validacionesClient.Get({ name: name })
    reply.entidad.presupuesto.valores = new Presupuesto(JSON.parse(reply.entidad.presupuesto.valores))
    reply.entidad.presupuesto.elaboracionDate = parseDate(reply.entidad.presupuesto.elaboracionDate)
    reply.entidad.presupuesto.aprobacionDate = parseDate(reply.entidad.presupuesto.aprobacionDate)
    reply.entidad.presupuesto.planesTemporales.planeamiento = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.planeamiento)
    reply.entidad.presupuesto.planesTemporales.urbanizacion = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.urbanizacion)
    reply.entidad.presupuesto.planesTemporales.proyectoBasico = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.proyectoBasico)
    reply.entidad.presupuesto.planesTemporales.licencia = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.licencia)
    reply.entidad.presupuesto.planesTemporales.proyectoEjecucion = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.proyectoEjecucion)
    reply.entidad.presupuesto.planesTemporales.licitacion = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.licitacion)
    reply.entidad.presupuesto.planesTemporales.obras = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.obras)
    reply.entidad.presupuesto.planesTemporales.legalizacionEntrega = parsePlanTemporalPresupuesto(reply.entidad.presupuesto.planesTemporales.legalizacionEntrega)
    setPresupuesto(reply.entidad.presupuesto)
    setValidation(reply)
  }

  async function save(req) {
    await validacionesClient.Create({
      accion: isEdit ? 'ACCION_EDITAR' : 'ACCION_CREAR',
      entidad: {
        presupuesto: req,
      },
    })
    notifySuccess('Guardado en proceso', 'Se ha pedido el guardado de este presupuesto para que lo apruebe un administrador.')

    if (isEdit) {
      history.push(`/webinterna/proyectos/${presupuesto.proyecto}`)
    } else {
      history.push(`/webinterna/proyectos`)
    }
  }

  async function confirmValidation(req) {
    if (validation.accion === 'ACCION_CREAR') {
      await presupuestosClient.Create(req)
    }
    if (validation.accion === 'ACCION_EDITAR') {
      await presupuestosClient.Update(req)
    }
    if (validation.accion === 'ACCION_ELIMINAR') {
      await presupuestosClient.Delete({ name: req.name })
    }

    await validacionesClient.Delete({ name })
    history.push('/webinterna/validaciones')
  }

  async function remove() {
    await validacionesClient.Create({
      accion: 'ACCION_ELIMINAR',
      entidad: {
        presupuesto,
      },
    })

    notifySuccess('Eliminación en proceso', 'Se ha pedido la eliminación completa de este presupuesto para que lo apruebe un administrador.')
    history.push(`/webinterna/proyectos`)
  }

  return {
    save,
    load,
    loadValidation,
    confirmValidation,
    presupuesto,
    setInicioFecha,
    setFinFecha,
    validation,
    remove,
  }
}


export default function PresupuestosEdit({ validation: isValidation, edit: isEdit }) {
  let { name } = useParams()
  let [superficieUso, setSuperficieUso] = useState(null)

  let {
    save: savePresupuesto,
    load: loadPresupuesto,
    loadValidation,
    confirmValidation,
    presupuesto,
    validation,
    remove: removePresupuesto,
    setInicioFecha,
    setFinFecha,
  } = usePresupuestos({ name, isEdit })

  useNavigate(async () => {
    if (isValidation) {
      await loadValidation()
    } else {
      await loadPresupuesto()
    }
  })

  let form = useForm(async (data) => {
    let req = {
      ...presupuesto,
      ...data,
      valores: JSON.stringify(presupuesto.valores.toJSON()),
      proyecto: presupuesto.proyecto.name ? presupuesto.proyecto.name : presupuesto.proyecto,
    }

    delete req.superficieUso

    req.aprobacionDate = serializeDate(req.aprobacionDate)
    req.elaboracionDate = serializeDate(req.elaboracionDate)
    req.planesTemporales.planeamiento = serializePlanTemporalPresupuesto(req.planesTemporales.planeamiento)
    req.planesTemporales.urbanizacion = serializePlanTemporalPresupuesto(req.planesTemporales.urbanizacion)
    req.planesTemporales.proyectoBasico = serializePlanTemporalPresupuesto(req.planesTemporales.proyectoBasico)
    req.planesTemporales.licencia = serializePlanTemporalPresupuesto(req.planesTemporales.licencia)
    req.planesTemporales.proyectoEjecucion = serializePlanTemporalPresupuesto(req.planesTemporales.proyectoEjecucion)
    req.planesTemporales.licitacion = serializePlanTemporalPresupuesto(req.planesTemporales.licitacion)
    req.planesTemporales.obras = serializePlanTemporalPresupuesto(req.planesTemporales.obras)
    req.planesTemporales.legalizacionEntrega = serializePlanTemporalPresupuesto(req.planesTemporales.legalizacionEntrega)

    if (isValidation) {
      await confirmValidation(req)
    } else {
      await savePresupuesto(req)
    }
  })

  return (
    <PageLayout>
      <PageHeader
        validation={validation}
        buttons={<>
          {isEdit && !presupuesto.blocked &&
            <ButtonConfirmDanger onClick={removePresupuesto}>
              <Icon solid name="trash" className="mr-2" />
              Eliminar Presupuesto
            </ButtonConfirmDanger>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/proyectos">Proyectos</Breadcrumb>
        <Breadcrumb url={`/webinterna/proyectos/${presupuesto.proyecto}`}>Consultar Proyecto</Breadcrumb>
        {isEdit
          ? <Breadcrumb url={`/webinterna/proyectos/presupuestos/edit/${name}`}>Editar Presupuesto</Breadcrumb>
          : <Breadcrumb url={`/webinterna/proyectos/presupuestos/new/${name}`}>Crear Nuevo Presupuesto</Breadcrumb>}
      </PageHeader>

      <EntityBlockedAlert blocked={presupuesto.blocked}></EntityBlockedAlert>

      <Form form={form}>

        <Card>
          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <Select
              form={form}
              className="w-48"
              name="usoPrincipal"
              label="Uso"
              options={PRESUPUESTO_OPTIONS}
              value={presupuesto.usoPrincipal}
            />
            
            <Datepicker form={form} name="elaboracionDate" label="Fecha elaboración" value={presupuesto.elaboracionDate} />
            <Datepicker form={form} name="aprobacionDate" label="Fecha aprobación" value={presupuesto.aprobacionDate} />
            <InputUrl form={form} name="adjuntoUrl" label="URL SharePoint" value={presupuesto.adjuntoURL} />
            <Textarea form={form} name="comentarios" label="Comentarios/Observaciones" value={presupuesto.comentarios} rows="5" />
          </div>
        </Card>

        {presupuesto.valores &&
        <>
          <CardTable
            title="Resumen y ratios"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold"></HeaderCell>
                <HeaderCell className="font-bold text-right">Total</HeaderCell>
                <HeaderCell className="font-bold text-right">€/m² Total</HeaderCell>
                <HeaderCell className="font-bold text-right">Viv. Libre</HeaderCell>
                <HeaderCell className="font-bold text-right">VPP</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.resumen.vppOverviews.map((vppOverview, index) => (
              <TableRow key={vppOverview.nombre}>
                <Cell
                  verticalFit
                  className={cx(
                    { 'italic': vppOverview.subOverview },
                    { 'font-bold': !vppOverview.subOverview },
                  )}
                >
                  {vppOverview.nombre}
                </Cell>
                <Cell
                  verticalFit
                  className={cx(
                    'text-right flex flex-row-reverse',
                    { 'text-red-500': printMoney(vppOverview.total) < 0 },
                    { 'font-bold': !vppOverview.subOverview },
                  )}
                >
                  {(index === 8 || index === 13) && printPercentage(vppOverview.total)}
                  {index !== 8 && index !== 13 && index !== 10 && printMoney(vppOverview.total)}
                  {index === 10 && 
                    <Percent
                      form={form}
                      name={`valores.resumen.vppOverviews[${index}].total`}
                      value={vppOverview.total?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.resumen.costesFinanciacion = parsePercentage(value)
                      }}
                    />
                  }
                </Cell>
                <Cell
                  verticalFit
                  className={cx(
                    'text-right',
                    { 'text-red-500': printRatio(vppOverview.totalMetros) < 0 },
                    { 'font-bold': !vppOverview.subOverview },
                  )}
                >
                  {(index === 8 || index === 10 || index === 13) ? 
                    printPercentage(vppOverview.totalMetros)
                    : 
                    printRatio(vppOverview.totalMetros)}
                </Cell>
                <Cell
                  verticalFit
                  className={cx(
                    'text-right',
                    { 'text-red-500': printInteger(vppOverview.viviendaLibre) < 0 },
                    { 'font-bold': !vppOverview.subOverview },
                  )}
                >
                  {(index === 8 || index === 10 || index === 13) ? 
                    printPercentage(vppOverview.viviendaLibre)
                    : 
                    printRatio(vppOverview.viviendaLibre)}
                </Cell>
                <Cell
                  verticalFit
                  className={cx(
                    'text-right',
                    { 'text-red-500': printInteger(vppOverview.vpp) < 0 },
                    { 'font-bold': !vppOverview.subOverview },
                  )}
                >
                  {(index === 8 || index === 10 || index === 13) ? 
                    printPercentage(vppOverview.vpp)
                    : 
                    printRatio(vppOverview.vpp)}
                </Cell>
              </TableRow>
            ))}
          </CardTable>

          <div className="space-x-4 mt-4 flex">
            <div className="w-1/2 lg:w-2/3">
              <CardTable
                title="Resumen Comercial"
                className="mt-4"
                header={
                  <>
                    <HeaderCell className="font-bold"></HeaderCell>
                    <HeaderCell className="font-bold text-right">Total</HeaderCell>
                    <HeaderCell className="font-bold text-right">€/m² Total</HeaderCell>
                    <HeaderCell className="font-bold text-right">Viv. Libre</HeaderCell>
                    <HeaderCell className="font-bold text-right">Comercial</HeaderCell>
                  </>
                }
              >
                {presupuesto.valores.resumen.comercialOverviews.map((comercialOverview, index) => (
                  <TableRow key={comercialOverview.nombre}>
                    <Cell verticalFit className="font-bold">{comercialOverview.nombre}</Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.total?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.total)}
                      {index > 0 && printPercentage(comercialOverview.total)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.totalMetros?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.totalMetros)}
                      {index > 0 && printPercentage(comercialOverview.totalMetros)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.viviendaLibre?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.viviendaLibre)}
                      {index > 0 && printPercentage(comercialOverview.viviendaLibre)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.comercial?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.comercial)}
                      {index > 0 && printPercentage(comercialOverview.comercial)}
                    </Cell>
                  </TableRow>
                ))}
              </CardTable>
            </div>
            <div className="w-1/2 lg:w-2/3">
              <CardTable
                title="Datos Bancarios"
                className="mt-4"
                header={
                  <>
                    <HeaderCell></HeaderCell>
                    <HeaderCell></HeaderCell>
                  </>
                }
              >
                <TableRow>
                  <Cell verticalFit>LTV bancario objetivo</Cell>
                  <Cell verticalFit className="flex flex-row-reverse">
                    <Percent
                      form={form}
                      name={`valores.resumen.ltvBancario`}
                      value={presupuesto.valores.resumen.ltvBancario?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.resumen.ltvBancario = parsePercentage(value)
                      }}
                    />
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell verticalFit>LTC bancario objetivo</Cell>
                  <Cell verticalFit className="flex flex-row-reverse">
                    <Percent
                      form={form}
                      name={`valores.resumen.ltcBancario`}
                      value={presupuesto.valores.resumen.ltcBancario?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.resumen.ltcBancario = parsePercentage(value)
                      }}
                    />
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell verticalFit>Financiación objetivo</Cell>
                  <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.resumen.financiacionObjetivo)}</Cell>
                </TableRow>
              </CardTable>
            </div>
          </div>

          <CardTable
            title="Plan temporal"
            className="mt-4"
            header={
              <>
                <HeaderCell></HeaderCell>
                <HeaderCell className="text-right">F_inicio</HeaderCell>
                <HeaderCell className="text-right">F_fin</HeaderCell>
                <HeaderCell className="text-right">Duración</HeaderCell>
              </>
            }
          >
            <TableRow>
              <Cell verticalFit>Planeamiento</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.planeamiento.inicioFecha"
                    value={presupuesto.planesTemporales.planeamiento?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'planeamiento')}
                  />
                </div>
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.planeamiento.finFecha"
                    value={presupuesto.planesTemporales.planeamiento?.finFecha}
                    onChange={value => setFinFecha(value, 'planeamiento')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.planeamiento?.inicioFecha && presupuesto.planesTemporales.planeamiento?.finFecha &&
                decimal((presupuesto.planesTemporales.planeamiento.finFecha.getTime() - presupuesto.planesTemporales.planeamiento.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Urbanización</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.urbanizacion.inicioFecha"
                    value={presupuesto.planesTemporales.urbanizacion?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'urbanizacion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.urbanizacion.finFecha"
                    value={presupuesto.planesTemporales.urbanizacion?.finFecha}
                    onChange={value => setFinFecha(value, 'urbanizacion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.urbanizacion?.inicioFecha && presupuesto.planesTemporales.urbanizacion?.finFecha &&
                decimal((presupuesto.planesTemporales.urbanizacion.finFecha.getTime() - presupuesto.planesTemporales.urbanizacion.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Proyecto básico</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.proyectoBasico.inicioFecha"
                    value={presupuesto.planesTemporales.proyectoBasico?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'proyectoBasico')}
                  />
                </div>
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.proyectoBasico.finFecha"
                    value={presupuesto.planesTemporales.proyectoBasico?.finFecha}
                    onChange={value => setFinFecha(value, 'proyectoBasico')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.proyectoBasico?.inicioFecha && presupuesto.planesTemporales.proyectoBasico?.finFecha &&
                decimal((presupuesto.planesTemporales.proyectoBasico.finFecha.getTime() - presupuesto.planesTemporales.proyectoBasico.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Licencia</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.licencia.inicioFecha"
                    value={presupuesto.planesTemporales.licencia?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'licencia')}
                  />
                </div>
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.licencia.finFecha"
                    value={presupuesto.planesTemporales.licencia?.finFecha}
                    onChange={value => setFinFecha(value, 'licencia')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.licencia?.inicioFecha && presupuesto.planesTemporales.licencia?.finFecha &&
                decimal((presupuesto.planesTemporales.licencia.finFecha.getTime() - presupuesto.planesTemporales.licencia.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Proyecto ejecución</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.proyectoEjecucion.inicioFecha"
                    value={presupuesto.planesTemporales.proyectoEjecucion?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'proyectoEjecucion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.proyectoEjecucion.finFecha"
                    value={presupuesto.planesTemporales.proyectoEjecucion?.finFecha}
                    onChange={value => setFinFecha(value, 'proyectoEjecucion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.proyectoEjecucion?.inicioFecha && presupuesto.planesTemporales.proyectoEjecucion?.finFecha &&
                decimal((presupuesto.planesTemporales.proyectoEjecucion.finFecha.getTime() - presupuesto.planesTemporales.proyectoEjecucion.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Licitación</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.licitacion.inicioFecha"
                    value={presupuesto.planesTemporales.licitacion?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'licitacion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.licitacion.finFecha"
                    value={presupuesto.planesTemporales.licitacion?.finFecha}
                    onChange={value => setFinFecha(value, 'licitacion')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.licitacion?.inicioFecha && presupuesto.planesTemporales.licitacion?.finFecha &&
                decimal((presupuesto.planesTemporales.licitacion.finFecha.getTime() - presupuesto.planesTemporales.licitacion.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Obras</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.obras.inicioFecha"
                    value={presupuesto.planesTemporales.obras?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'obras')}
                  />
                </div>
                
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.obras.finFecha"
                    value={presupuesto.planesTemporales.obras?.finFecha}
                    onChange={value => setFinFecha(value, 'obras')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.obras?.inicioFecha && presupuesto.planesTemporales.obras?.finFecha &&
                decimal((presupuesto.planesTemporales.obras.finFecha.getTime() - presupuesto.planesTemporales.obras.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>Legalización entrega</Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.legalizacionEntrega.inicioFecha"
                    value={presupuesto.planesTemporales.legalizacionEntrega?.inicioFecha}
                    onChange={value => setInicioFecha(value, 'legalizacionEntrega')}
                  />
                </div>
                
              </Cell>
              <Cell verticalFit>
                <div className="flex flex-row-reverse">
                  <Datepicker
                    form={form}
                    name="planesTemporales.legalizacionEntrega.finFecha"
                    value={presupuesto.planesTemporales.legalizacionEntrega?.finFecha}
                    onChange={value => setFinFecha(value, 'legalizacionEntrega')}
                  />
                </div>
              </Cell>
              <Cell verticalFit className="text-right">
                {presupuesto.planesTemporales.legalizacionEntrega?.inicioFecha && presupuesto.planesTemporales.legalizacionEntrega?.finFecha &&
                decimal((presupuesto.planesTemporales.legalizacionEntrega.finFecha.getTime() - presupuesto.planesTemporales.legalizacionEntrega.inicioFecha.getTime()) / 25920000)} meses
              </Cell>
            </TableRow>
          </CardTable>

          <CardHeader
            className="mt-4"
            header="Superficies"
            fit
            footer={
              Object.keys(USOS).filter(uso => !presupuesto.valores.superficies.superficies.find(superficie => superficie.uso === uso)).length && <div className="flex gap-x-4">
                <Select 
                  form={form}
                  name="superficieUso"
                  className="-mt-1"
                  options={Object.keys(USOS).filter(uso => !presupuesto.valores.superficies.superficies.find(superficie => superficie.uso === uso))
                    .map(uso => {
                      return {
                        label: USOS[uso],
                        value: uso,
                      }
                    })}
                  onChange={setSuperficieUso}
                />
                <ButtonSecondary disabled={!superficieUso} onClick={() => {
                  if (superficieUso) {
                    presupuesto.valores.superficies.add(superficieUso)
                    setSuperficieUso(null)
                  }
                }}>
                  {superficieUso ?
                    <>
                      <Icon name="plus" className="mr-4" solid />
                      Añadir superficie
                    </>
                    :
                    'Selecciona el tipo...'
                  }
                </ButtonSecondary>
              </div>
            }
          >
            <div className="m-6 flex space-x-4">
              <Area
                form={form}
                name="valores.superficies.suelo"
                label="Suelo m²"
                value={presupuesto.valores.superficies.suelo?.toNumber() * 100}
                onChange={(value) => {
                  presupuesto.valores.superficies.suelo = parseInteger(value)
                }}
              />
              <Area
                form={form}
                name="valores.superficies.edificabilidadMax"
                label="Edificabilidad máx. m²"
                value={presupuesto.valores.superficies.edificabilidadMax?.toNumber() * 100}
                onChange={(value) => {
                  presupuesto.valores.superficies.edificabilidadMax = parseInteger(value)
                }}
              />
            </div>

            <Table
              header={
                <>
                  <HeaderCell className="font-bold text-right">Sup. Const. m² (con pp.zz.cc.)*</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. Const. m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">% Sup. SR</HeaderCell>
                  <HeaderCell className="font-bold text-right">% Sup. BR</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. SR m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. BR m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">Nº uds.</HeaderCell>
                  <HeaderCell className="font-bold text-right">1D</HeaderCell>
                  <HeaderCell className="font-bold text-right">2D</HeaderCell>
                  <HeaderCell className="font-bold text-right">3D</HeaderCell>
                  <HeaderCell className="font-bold text-right">4D</HeaderCell>
                  <HeaderCell></HeaderCell>
                </>
              }
            >
              {presupuesto.valores.superficies.superficies.map((superficie, index) => (
                <TableRow key={superficie.nombre ? superficie.nombre : USOS[superficie.uso]}>
                  <Cell verticalFit>{superficie.nombre ? superficie.nombre : USOS[superficie.uso]}</Cell>
                  <Cell verticalFit>
                    <Area
                      form={form}
                      name={`valores.superficies.superficies[${index}].superficieConstruida`}
                      className="w-full"
                      value={superficie.superficieConstruida?.toNumber() * 100}
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].superficieConstruida = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit>
                    <Percent
                      form={form}
                      name={`valores.superficies.superficies[${index}].sr`}
                      className="w-full"
                      value={superficie.sr?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].sr = parsePercentage(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit className="text-right">{printPercentage(superficie.br)}</Cell>
                  <Cell verticalFit className="text-right">{printInteger(superficie.srSuperficie)}</Cell>
                  <Cell verticalFit className="text-right">{printInteger(superficie.brSuperficie)}</Cell>
                  <Cell verticalFit>
                    <InputRatio
                      form={form}
                      type="number"
                      name={`valores.superficies.superficies[${index}].unidades`}
                      className="w-full"
                      value={superficie.unidades?.toNumber()}
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].unidades = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit>
                    <InputRatio
                      form={form}
                      type="number"
                      name={`valores.superficies.superficies[${index}].s1d`}
                      value={superficie.s1d?.toNumber()}
                      className="w-12"
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].s1d = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit>
                    <InputRatio
                      form={form}
                      type="number"
                      name={`valores.superficies.superficies[${index}].s2d`}
                      value={superficie.s2d?.toNumber()}
                      className="w-12"
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].s2d = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit>
                    <InputRatio
                      form={form}
                      type="number"
                      name={`valores.superficies[${index}].s3d`}
                      value={superficie.s3d?.toNumber()}
                      className="w-12"
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].s3d = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell verticalFit>
                    <InputRatio
                      form={form}
                      type="number"
                      name={`valores.superficies[${index}].s4d`}
                      value={superficie.s4d?.toNumber()}
                      className="w-12"
                      onChange={(value) => {
                        presupuesto.valores.superficies.superficies[index].s4d = parseInteger(value)
                      }}
                    />
                  </Cell>
                  <Cell>
                    {index !== 0 && 
                    <ButtonHoverDanger onClick={() => presupuesto.valores.superficies.remove(index)}>
                      <Icon solid name="trash" />
                    </ButtonHoverDanger>
                    }
                  </Cell>
                </TableRow>
              ))}
              <TableRow>
                <Cell verticalFit className="font-bold">TOTAL</Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.superficieConstruida)}</Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.srSuperficie)}</Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.brSuperficie)}</Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.unidades)}</Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
              </TableRow>
            </Table>
          </CardHeader>

          <CardTable
            title="Costes ejecución"
            className="mt-4"
            header={
              <>
                <HeaderCell></HeaderCell>
                <HeaderCell className="font-bold text-right">Base</HeaderCell>
                <HeaderCell className="font-bold text-right">Ratio</HeaderCell>
                <HeaderCell className="font-bold text-right">Importe</HeaderCell>
              </>
            }
          >
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.suelo.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.suelo.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.suelo.ratio)}</Cell>
              <Cell verticalFit className="font-bold">
                <Price
                  form={form}
                  name={`valores.costes.suelo.importe`}
                  value={presupuesto.valores.costes.suelo.importe?.toNumber()}
                  className="flex flex-row-reverse"
                  onChange={(value) => {
                    presupuesto.valores.costes.suelo.importe = parseInteger(value * 100)
                  }}
                />  
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.urbanizacion.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.urbanizacion.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.urbanizacion.ratio)}</Cell>
              <Cell verticalFit className="font-bold">
                <Price
                  form={form}
                  name={`valores.costes.urbanizacion.importe`}
                  value={presupuesto.valores.costes.urbanizacion.importe?.toNumber()}
                  className="flex flex-row-reverse"
                  onChange={(value) => {
                    presupuesto.valores.costes.urbanizacion.importe = parseInteger(value * 100)
                  }}
                />  
              </Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.edificacion.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.edificacion.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.edificacion.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.edificacion.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.edificacion.costes.map((coste, index) => (
              <TableRow key={USOS[presupuesto.valores.superficies.superficies[index].uso]}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell verticalFit className="text-right">{printArea(coste.base)}</Cell>
                <Cell verticalFit className="text-right">
                  <InputRatio
                    form={form}
                    type="number"
                    name={`valores.costes.edificacion.costes[${index}].ratio`}
                    className="flex flex-row-reverse"
                    value={coste.ratio?.toNumber() / 100}
                    onChange={(value) => {
                      presupuesto.valores.costes.edificacion.costes[index].ratio = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
                <Cell verticalFit>
                  <Price
                    form={form}
                    name={`valores.costes.edificacion.costes[${index}].importe`}
                    className="flex flex-row-reverse"
                    value={coste.importe?.toNumber()}
                    onChange={(value) => {
                      presupuesto.valores.costes.edificacion.costes[index].importe = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.mobiliarioEquipamiento.nombre}</Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.mobiliarioEquipamiento.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.mobiliarioEquipamiento.costes.map((coste, index) => (
              <TableRow key={USOS[presupuesto.valores.superficies.superficies[index].uso]}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}  
                </Cell>
                <Cell verticalFit className="text-right">{coste.base && (printInteger(coste.base) + 'uds')}</Cell>
                <Cell verticalFit>
                  <InputRatio
                    form={form}
                    type="number"
                    name={`valores.costes.mobiliarioEquipamiento.costes[${index}].ratio`}
                    className="flex flex-row-reverse"
                    value={coste.ratio?.toNumber() / 100}
                    onChange={(value) => {
                      presupuesto.valores.costes.mobiliarioEquipamiento.costes[index].ratio = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
                <Cell verticalFit>
                  <Price
                    form={form}
                    type="number"
                    name={`valores.costes.mobiliarioEquipamiento.costes[${index}].importe`}
                    className="flex flex-row-reverse"
                    value={coste.importe?.toNumber()}
                    onChange={(value) => {
                      presupuesto.valores.costes.mobiliarioEquipamiento.costes[index].importe = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.fitOuts.nombre}</Cell>
              <Cell verticalFit className="font-bold"></Cell>
              <Cell verticalFit className="font-bold"></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.fitOuts.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.fitOuts.costes.map((coste, index) => (
              <TableRow key={USOS[presupuesto.valores.superficies.superficies[index].uso]}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}  
                </Cell>
                <Cell verticalFit className="text-right">{printArea(coste.base)}</Cell>
                <Cell verticalFit>
                  <InputRatio
                    form={form}
                    type="number"
                    name={`valores.costes.fitOuts.costes[${index}].ratio`}
                    className="flex flex-row-reverse"
                    value={coste.ratio?.toNumber() / 100}
                    onChange={(value) => {
                      presupuesto.valores.costes.fitOuts.costes[index].ratio = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
                <Cell verticalFit>
                  <Price
                    form={form}
                    type="number"
                    name={`valores.costes.fitOuts.costes[${index}].importe`}
                    className="flex flex-row-reverse"
                    value={coste.importe?.toNumber()}
                    onChange={(value) => {
                      presupuesto.valores.costes.fitOuts.costes[index].importe = parseInteger(value * 100)
                    }}
                  /> 
                </Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.legales.nombre}</Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.legales.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>{presupuesto.valores.costes.legales.tasasCIO.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.tasasCIO.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  type="number"
                  name={`valores.costes.legales.tasasCIO.ratio`}
                  value={presupuesto.valores.costes.legales.ratioTasasCIO?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.costes.legales.ratioTasasCIO = parsePercentage(value)
                  }}
                /> 
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.tasasCIO.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>{presupuesto.valores.costes.legales.obraNueva.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.obraNueva.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  type="number"
                  name={`valores.costes.legales.obraNueva.ratio`}
                  value={presupuesto.valores.costes.legales.ratioObraNueva?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.costes.legales.ratioObraNueva = parsePercentage(value)
                  }}
                /> 
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.obraNueva.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>{presupuesto.valores.costes.legales.divisionHorizontal.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.divisionHorizontal.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  type="number"
                  name={`valores.costes.legales.divisionHorizontal.ratio`}
                  value={presupuesto.valores.costes.legales.ratioDivisionHorizontal?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.costes.legales.ratioDivisionHorizontal = parsePercentage(value)
                  }}
                /> 
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.divisionHorizontal.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit>{presupuesto.valores.costes.legales.otrosGastos.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.otrosGastos.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  type="number"
                  name={`valores.costes.legales.otrosGastos.ratio`}
                  value={presupuesto.valores.costes.legales.ratioOtrosGastos?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.costes.legales.ratioOtrosGastos = parsePercentage(value)
                  }}
                /> 
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.costes.legales.otrosGastos.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.proyectoSSTT.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.proyectoSSTT.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  type="number"
                  name={`valores.costes.proyectoSSTT.ratio`}
                  value={presupuesto.valores.costes.proyectoSSTT.ratio * 10000}
                  onChange={(value) => {
                    presupuesto.valores.costes.proyectoSSTT.ratio = parsePercentage(value)
                  }}
                /> 
              </Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.proyectoSSTT.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.costes.total.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.total.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.total.importe)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Ingresos Ventas"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold">VENTA</HeaderCell>
                <HeaderCell className="font-bold">V_VENTA</HeaderCell>
                <HeaderCell className="font-bold">% VENTAS</HeaderCell>
                <HeaderCell className="font-bold">€/uds VENTAS</HeaderCell>
                <HeaderCell className="font-bold">€/m² VENTA</HeaderCell>
                <HeaderCell className="font-bold">COSTE EJECUCIÓN</HeaderCell>
                <HeaderCell className="font-bold">MARGEN BRUTO EXPLOTACIÓN</HeaderCell>
                <HeaderCell className="font-bold">% M.B.EXPL. s/COSTES EJECUCIÓN</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.ingresos.ventas.items.map((venta, index) => (
              <TableRow key={USOS[presupuesto.valores.superficies.superficies[index].uso]}>
                <Cell verticalFit className="font-bold">
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}  
                </Cell>
                <Cell verticalFit className="font-bold text-right">
                  {index === 0 ?
                    <Price
                      form={form}
                      type="number"
                      name={`valores.ingresos.ventas.items[${index}].valorVenta`}
                      className="w-full"
                      value={venta.valorVenta?.toNumber()}
                      onChange={(value) => {
                        presupuesto.valores.ingresos.ventas.items[index].valorVenta = parseInteger(value * 100)
                      }}
                    /> 
                    :
                    printRatio(venta.valorVenta)
                  }
                  {}
                </Cell>
                <Cell verticalFit className="text-right">{printPercentage(venta.ventasPercentage)}</Cell>
                <Cell verticalFit className="text-right">{printRatio(venta.ventasUnidades)}</Cell>
                <Cell verticalFit className="text-right">{printRatio(venta.ventasMetros)}</Cell>
                <Cell verticalFit className="text-red-500 text-right">({printMoney(venta.costeEjecucion)})</Cell>
                <Cell verticalFit className={cx('text-right', { 'text-red-500': printMoney(venta.margenBrutoExplotacion) < 0 })}>{printMoney(venta.margenBrutoExplotacion)}</Cell>
                <Cell verticalFit className="text-right">{printPercentage(venta.mbece)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.ingresos.ventas.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.ventas.total.valorVenta)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.ventas.total.ventasPercentage)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.ingresos.ventas.total.ventasUnidades)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.ingresos.ventas.total.ventasMetros)}</Cell>
              <Cell verticalFit className="font-bold text-red-500 text-right">({printMoney(presupuesto.valores.ingresos.ventas.total.costeEjecucion)})</Cell>
              <Cell verticalFit className={cx('font-bold text-right', { 'text-red-500': printMoney(presupuesto.valores.ingresos.ventas.total.margenBrutoExplotacion) < 0 })}>
                {printInteger(presupuesto.valores.ingresos.ventas.total.margenBrutoExplotacion)}
              </Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.ventas.total.mbce)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Arrendamiento"
            className="mt-4"
            header={
              <>
                <HeaderCell></HeaderCell>
                <HeaderCell>RENTA</HeaderCell>
                <HeaderCell>DESOC%</HeaderCell>
                <HeaderCell>Desoc. Imp.</HeaderCell>
                <HeaderCell>G_EXPL</HeaderCell>
                <HeaderCell>NOI</HeaderCell>
                <HeaderCell>R.E.E. %</HeaderCell>
                <HeaderCell>YIELD</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.ingresos.arrendamientos.items.map((arrendamiento, index) => (
              <TableRow key={USOS[presupuesto.valores.superficies.superficies[index].uso]}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell verticalFit>
                  {!!index &&
                    <Price
                      form={form}
                      type="number"
                      name={`valores.ingresos.arrendamientos.items[${index}].renta`}
                      className="w-full"
                      value={arrendamiento.renta?.toNumber()}
                      onChange={(value) => {
                        presupuesto.valores.ingresos.arrendamientos.items[index].renta = parseInteger(value * 100)
                      }}
                    /> 
                  }
                </Cell>
                <Cell verticalFit>
                  {!!index &&
                    <Percent
                      form={form}
                      name={`valores.ingresos.arrendamientos.items[${index}].desoc`}
                      className="w-full"
                      value={arrendamiento.desoc?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.ingresos.arrendamientos.items[index].desoc = parsePercentage(value)
                      }}
                    />
                  }
                </Cell>
                <Cell verticalFit className="text-red-500 text-right">{!!index && arrendamiento.desocImporte && <>({printRatio(arrendamiento.desocImporte)})</>}</Cell>
                <Cell verticalFit className="text-red-500">
                  {!!index &&
                    <Price
                      form={form}
                      type="number"
                      name={`valores.ingresos.arrendamientos.items[${index}].gExpl`}
                      className="w-full"
                      value={arrendamiento.gExpl?.toNumber()}
                      onChange={(value) => {
                        presupuesto.valores.ingresos.arrendamientos.items[index].gExpl = parseInteger(value * 100)
                      }}
                    /> 
                  }
                </Cell>
                <Cell verticalFit className="text-right">{!!index && printRatio(arrendamiento.noi)}</Cell>
                <Cell verticalFit className="text-right">{!!index && printPercentage(arrendamiento.ree)}</Cell>
                <Cell verticalFit>
                  {!!index &&
                    <Percent
                      form={form}
                      name={`valores.ingresos.arrendamientos.items[${index}].yield`}
                      value={arrendamiento.yield?.toNumber() * 10000}
                      onChange={(value) => {
                        presupuesto.valores.ingresos.arrendamientos.items[index].yield = parsePercentage(value)
                      }}
                    />
                  }
                </Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.ingresos.arrendamientos.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.ingresos.arrendamientos.total.renta)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.desoc)}</Cell>
              <Cell verticalFit className="text-red-500 font-bold text-right">({printMoney(presupuesto.valores.ingresos.arrendamientos.total.desocImporte)})</Cell>
              <Cell verticalFit className="text-red-500 font-bold text-right">{printMoney(presupuesto.valores.ingresos.arrendamientos.total.gExpl)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.ingresos.arrendamientos.total.noi)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.ree)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.yield)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Costes estructura y otros gastos (hasta puesta en servicio)"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold"></HeaderCell>
                <HeaderCell className="font-bold text-right">Base</HeaderCell>
                <HeaderCell className="font-bold text-right">Ratio</HeaderCell>
                <HeaderCell className="font-bold text-right">Importe</HeaderCell>
              </>
            }
          >
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.direccion.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.direccion.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioDireccion`}
                  value={presupuesto.valores.otrosGastos.direccion.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioDireccion = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.direccion.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.activacion.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.activacion.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioActivacion`}
                  value={presupuesto.valores.otrosGastos.activacion.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioActivacion = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.activacion.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.comercializacion.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.comercializacion.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioComercializacion`}
                  value={presupuesto.valores.otrosGastos.comercializacion.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioComercializacion = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.comercializacion.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.gestionIntegral.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.gestionIntegral.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioGestionIntegral`}
                  value={presupuesto.valores.otrosGastos.gestionIntegral.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioGestionIntegral = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.gestionIntegral.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.ventaViviendas.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.ventaViviendas.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioVentaViviendas`}
                  value={presupuesto.valores.otrosGastos.ventaViviendas.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioVentaViviendas = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.ventaViviendas.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.ventaOtros.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.ventaOtros.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioVentaOtros`}
                  value={presupuesto.valores.otrosGastos.ventaOtros.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioVentaOtros = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.ventaOtros.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.gestionInmuebles.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.gestionInmuebles.base)} </Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioGestionInmuebles`}
                  value={presupuesto.valores.otrosGastos.gestionInmuebles.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioGestionInmuebles = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.gestionInmuebles.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.marketing.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.marketing.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioMarketing`}
                  value={presupuesto.valores.otrosGastos.marketing.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioMarketing = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.marketing.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.contabilidad.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.contabilidad.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioContabilidad`}
                  value={presupuesto.valores.otrosGastos.contabilidad.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioContabilidad = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.contabilidad.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.otros.nombre}</Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.otros.base)}</Cell>
              <Cell verticalFit className="flex flex-row-reverse">
                <Percent
                  form={form}
                  name={`valores.otrosGastos.ratioOtros`}
                  value={presupuesto.valores.otrosGastos.otros.ratio?.toNumber() * 10000}
                  onChange={(value) => {
                    presupuesto.valores.otrosGastos.ratioOtros = parsePercentage(value)
                  }}
                />
              </Cell>
              <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.otrosGastos.otros.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.otrosGastos.total.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.otrosGastos.total.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.otrosGastos.total.importe)}</Cell>
            </TableRow>
          </CardTable>
        </>
        }

        {!presupuesto.blocked &&
          <Submit validation={validation} form={form}>Guardar presupuesto</Submit>
        }
      </Form>
    </PageLayout>
  )
}
