
import { runQuery } from './entities'
import * as query from './query'


export async function waitDocumentChanged(doc) {
  for (let i = 0; i < 10; i++) {
    if (await checkDocumentState(doc)) {
      return
    }
  
    await sleep(1000)
  }
}

async function checkDocumentState(doc) {
  let docs = await runQuery('documents', query.build(
    query.and('name', doc.name),
  ))
  return docs[0].lastUpdateTime !== doc.lastUpdateTime
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
