
import React, { useState } from 'react'

import Card from 'components/Card'
import FileUpload from 'components/FileUpload'
import { InfoPane } from 'views/docs/upload/internal/InfoPane'
import AlertDanger from 'components/alerts/AlertDanger'
import Icon from 'components/Icon'
import { bytesSize } from 'platform/filters'
import PdfPreview from 'components/PdfPreview'
import FilesClient from 'services/Docs/files/files'
import { docs } from 'services/discovery'


const filesClient = new FilesClient(docs())

export const UPLOAD = {
  LOADING: 'LOADING',
  PENDING: 'PENDING',
  SENDING: 'SENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}


export function Sidebar({ status, setStatus, onUploadFile, children }) {
  let [uploadInfo, setUploadInfo] = useState(null)
  let [failureReason, setFailureReason] = useState('')
  let [preview, setPreview] = useState('')

  async function uploadFile(file) {
    if (file.type !== 'application/pdf') {
      setStatus(UPLOAD.FAILED)
      setFailureReason('Seleccione por favor un fichero en formato PDF. Es el único formato que soporta el gestor documental.')
      return
    }

    setStatus(UPLOAD.SENDING)
    setUploadInfo({
      filename: file.name,
      size: file.size,
    })

    let upload = await filesClient.Create()

    try {
      let uploadReply = await fetch(upload.uploadUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        body: file,
      })
      if (uploadReply.status !== 201) {
        setStatus(UPLOAD.FAILED)
        setFailureReason(`Bad HTTP status code: ${uploadReply.status}`)
        return
      }

      let finishReply = await filesClient.FinishUpload({
        name: upload.name,
        filename: file.name,
        size: file.size,
      })
      setPreview(finishReply.previewUrl)
      onUploadFile(finishReply)
    } catch (err) {
      setStatus(UPLOAD.FAILED)
      setFailureReason(`${err}`)
      return
    }

    setStatus(UPLOAD.SUCCESS)
  }

  return (
    <div className="sticky top-6">
      {status === UPLOAD.FAILED &&
        <Card className="mb-10" title="Error durante la subida del fichero">
          <AlertDanger>
            <p>Ha ocurrido un problema durante la subida del fichero.</p>
            <p className="mt-2">
              Los detalles técnicos del error son los siguientes:<br />
              <code className="text-sm">{failureReason}</code>
            </p>
          </AlertDanger>
        </Card>
      }
      {[UPLOAD.PENDING, UPLOAD.FAILED].includes(status) &&
        <div className="mb-10">
          <h3 className="mb-4 text-xl font-semibold font-display text-gray-900">Subir fichero</h3>
          <FileUpload onSelect={uploadFile} />
        </div>
      }
      {uploadInfo &&
        <Card title="Información sobre el fichero">
          <div className="grid grid-cols-2 gap-6">
            <InfoPane title="Nombre del fichero">{uploadInfo.filename}</InfoPane>
            <InfoPane title="Tamaño">{bytesSize(uploadInfo.size)}</InfoPane>

            <InfoPane title="Subida del fichero" className="col-span-2">
              {status === UPLOAD.SUCCESS &&
                <span className="text-green-600 font-medium">
                  <Icon solid name="check-circle" className="mr-2" />
                  Recibido correctamente
                </span>
              }
              {status === UPLOAD.FAILED &&
                <>
                  <div className="text-red-600 font-medium">
                    <Icon solid name="times-circle" className="mr-2" />
                    Error en la subida
                  </div>
                  <div>{failureReason}</div>
                </>
              }
              {status === UPLOAD.SENDING &&
                <span className="text-blue-600 font-medium">
                  <Icon name="spinner-third" duotone spin className="mr-2" />
                  En proceso
                </span>
              }
            </InfoPane>
          </div>
        </Card>
      }

      {preview &&
        <Card title="Previsualización" className="mt-8" >
          <div className="w-96 m-auto">
            <PdfPreview url={preview} />
          </div>
        </Card>
      }
      {!preview && children}
    </div>
  )
}
