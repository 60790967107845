
import React, { useRef, useEffect } from 'react'
import flatpickr from 'flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import startOfDay from 'date-fns/startOfDay'
import isAfter from 'date-fns/isAfter'
import cx from 'classnames'
import { isDate } from 'lodash-es'


import Icon from 'components/Icon'
import { useMounted } from 'hooks/mounted'


export default function Datepicker({ className, value, onChange, min, disabled, placeholder }) {
  if (value && !isDate(value)) {
    throw new Error(`Datepicker should receive a Date object. Use parseDate to read it: ${value}`)
  }
  if (min) {
    min = startOfDay(min)
  }
  if (value) {
    value = startOfDay(value)
  }

  let inputElem = useRef(null)
  let picker = useRef(null)

  useMounted(() => {
    picker.current = flatpickr(inputElem.current, {
      dateFormat: 'd M Y',
      minDate: min ? startOfDay(min) : undefined,
      locale: Spanish,
      onChange: onChange,
      defaultDate: value,
    })

    return () => {
      picker.current.destroy()
    }
  })

  useEffect(() => {
    if (picker.current && value) {
      picker.current.setDate(value)
    }
  }, [picker, value])

  useEffect(() => {
    if (picker.current && min) {
      if (isAfter(min, picker.current.selectedDates[0])) {
        picker.current.setDate(min)
      }
      picker.current.set('minDate', min)
    }
  }, [picker, min])

  return (
    <div className={cx(className, 'flex relative rounded-md shadow-sm')}>
      <input
        className="form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md pr-10"
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        readOnly
        ref={inputElem}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <Icon regular name="calendar-alt" className="text-gray-500" />
      </div>
    </div>
  )
}
