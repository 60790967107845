import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'

import UsersClient from 'services/Users/users/users'
import GroupsClient from 'services/Users/groups/groups'
import { useNavigate } from 'hooks/navigate'
import { users } from 'services/discovery'
import Icon from 'components/Icon'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import resolver from 'platform/resolver'
import { parseTimestamp } from 'platform/datetime'
import { datetime } from 'platform/filters'
import CardHeader from 'components/CardHeader'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ShowPane from 'components/ShowPane'
import Selector from 'components/simple-forms/Selector'
import Face from 'components/people/Face'


const usersClient = new UsersClient(users())
const groupsClient = new GroupsClient(users())


export default function UsersEdit() {
  let { name } = useParams()
  let [input, setInput] = useState({})
  let [groups, setGroups] = useState({})
  let [user, setUser] = useState({})

  useNavigate(async () => {
    let { user, groups } = await resolver.all({
      user: usersClient.Get({ name: name }),
      groups: groupsClient.List().then(reply => reply.groups || []),
    })

    setGroups(groups?.map(group => {
      return {
        value: group.name,
        label: group.displayName,
      }
    }))

    setInput({
      name,
      groups: user.groups || [],
    })

    user.lastLoginTime = parseTimestamp(user.lastLoginTime)
    user.lastGroupsUpdateTime = parseTimestamp(user.lastGroupsUpdateTime)
    setUser(user)
  })

  async function save() {
    await usersClient.UpdateGroups(input)
    setUser({
      ...user,
      groups: input.groups,
    })
  }

  let hasChanges = !isEqual(user.groups, input.groups)

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/users">Usuarios</Breadcrumb>
          <Breadcrumb url={`/manage/users/${user.name}`}>{user.displayName}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <>
        <CardHeader
          header="Información personal"
          subheader="Datos extraídos desde la cuenta corporativa de Active Directory."
        >
          <div className="grid grid-cols-2 gap-x-4">
            <div className="space-y-4">
              <ShowPane title="Nombre">{user.displayName}</ShowPane>
              <ShowPane title="Email" className="mb-4"><span className="font-mono">{user.email}</span></ShowPane>
              <ShowPane title="Último acceso">{datetime(user.lastLoginTime, 'DATETIME')}</ShowPane>
            </div>
            <ShowPane title="Imagen">
              {user.displayName && <Face displayName={user.displayName} pictureUrl={user.pictureUrl} size="huge"/>}
            </ShowPane>
          </div>

        </CardHeader>

        <CardHeader
          header="Grupos"
          subheader="Gestión de los permisos del usuario."
          actions={
            <span className="italic text-gray-500 text-sm">Última actualización: {datetime(user.lastGroupsUpdateTime, 'DATETIME')}</span>
          }
          footer={
            <ButtonAsyncPrimary onClick={save} disabled={!hasChanges}>
              <Icon regular name="save" className="mr-2" />
              Actualizar grupos
            </ButtonAsyncPrimary>
          }
        >
          <Selector
            label="Grupos"
            name="groups"
            multiple
            value={input.groups}
            options={groups}
            onChange={value => {
              setInput({
                ...input,
                groups: value,
              })
            }}
          ></Selector>
        </CardHeader>
      </>
    </PageLayout>
  )
}
