
import { area } from 'platform/filters'
import { webinterna } from 'services/discovery'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'


const fincasRegistralesClient = new FincasRegistralesClient(webinterna())


export let REF_FINCA_REGISTRAL = {
  loader: async (names) => {
    let { fincas } = await fincasRegistralesClient.BatchGet({ names })
    fincas = fincas.map(f => {
      return {
        ...f,
        descriptor: 'Finca registral',
        label: f.numeroFinca,
      }
    })
    return fincas
  },
  link: name => `/webinterna/fincas-registrales/${name}`,
  label: 'Finca registral',
  fields: {
    codigo: {
      label: 'Código',
      type: 'text',
      wide: false,
      extractor: entity => entity.codigo,
    },
    numeroFinca: {
      label: 'Número finca',
      type: 'text',
      wide: false,
      extractor: entity => entity.numeroFinca,
    },
    idufir: {
      label: 'IDUFIR',
      type: 'text',
      wide: false,
      extractor: entity => entity.idufir,
    },
    superficieRegistralSuelo: {
      label: 'Superficie Registral Suelo',
      type: 'text',
      wide: false,
      extractor: entity => area(entity.superficieRegistralSuelo),
    },
    superficieRegistralEdificacion: {
      label: 'Superficie Registral Edificación',
      type: 'text',
      wide: false,
      extractor: entity => area(entity.superficieRegistralEdificacion),
    },
  },
}
