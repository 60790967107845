
import React, { useState } from 'react'
import cx from 'classnames'
import diacritic from 'diacritic'

import SideSheet from 'components/SideSheet'
import ButtonPrimary from 'components/buttons/ButtonPrimary'
import ButtonHoverDanger from 'components/buttons/ButtonHoverDanger'
import Icon from 'components/Icon'


const PAGE_SIZE = 50


export default function SingleSelector({ onChange, header, subheader, value, options, open, onClose }) {
  if (!options) {
    options = []
  }
  
  let [filter, setFilter] = useState('')
  
  let [selected, setSelected] = useState(null)
  if (!selected && value) {
    setSelected(options.find(p => p.value === value))
  }
  
  let filtered = options
  if (filter) {
    let search = diacritic.clean(filter).toUpperCase()
    
    filtered = options.filter(item => {
      let $search = diacritic.clean(`${item.label} ${item.sublabel}`).toUpperCase()
      return $search.includes(search)
    })
    let end = Math.min(PAGE_SIZE, filtered.length)
    filtered = filtered.slice(0, end)
  }

  function isSelected(item) {
    return selected && item.value === selected.value
  }

  return (
    <>
      <SideSheet
        wide
        fit
        open={open}
        onClose={onClose}
        header={header}
        subheader={subheader}
        footer={
          <div className="flex justify-between w-full">
            <ButtonHoverDanger onClick={() => {
              setSelected({})
              if (onChange) {
                onChange(null)
              }
              onClose()
            }}>
              <Icon solid name="unlink" className="mr-2" />
              Desvincular existente
            </ButtonHoverDanger>
            
            <ButtonPrimary onClick={() => {
              if (onChange) {
                onChange(selected)
              }
              onClose()
            }}>
              <Icon solid name="link" className="mr-2" />
              Seleccionar
            </ButtonPrimary>
          </div>
        }
      >
        <div className="mt-5 flex rounded-md w-full">
          <div className="relative flex-grow focus-within:z-10 border-b-3 border-gray-300 pl-6">
            <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
              <Icon solid name="search" className="text-gray-400" />
            </div>
            <input
              type="text"
              id="search"
              autoComplete="off"
              className="form-input block w-full focus:ring-0 py-2 pl-8 border-0"
              placeholder="Filtrar..."
              value={filter}
              onChange={event => setFilter(event.target.value.trim())}
            />
          </div>
        </div>
        <div className="relative flex-1 overflow-y-auto">
          <ul>
            {filtered.map((item) => (
              <li key={item.value} className={cx(
                'px-6 py-3 relative border-t border-b -mt-px',
                {
                  'z-10 bg-indigo-100 border-indigo-700': isSelected(item),
                  'border-gray-300': !isSelected(item),
                },
              )}>
                <div className="group flex justify-between items-center">
                  <div onClick={() => setSelected(item)} className="-m-1 p-1 block cursor-pointer">
                    <div className={cx(
                      'absolute inset-0',
                      {
                        'group-hover:bg-indigo-100': isSelected(item),
                        'group-hover:bg-gray-200': !isSelected(item),
                      },
                    )}/>
                    <div className="flex-1 flex items-center min-w-0 relative">
                      <div className="truncate cursor-pointer">
                        <p className="text-sm font-medium text-gray-900 truncate">{item.label ? item.label : <>&nbsp;</>}</p>
                        <p className="text-sm text-gray-500 truncate">{item.sublabel ? item.sublabel : <>&nbsp;</>}</p>
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 relative inline-block text-left">
                    <a
                      href={item.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={cx(
                        'group relative w-10 h-10 text-gray-500 rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
                        {
                          'bg-indigo-100': isSelected(item),
                          'bg-white': !isSelected(item),
                        },
                      )}
                    >
                      <span className="flex items-center justify-center h-full w-full rounded-full">
                        <Icon regular name="external-link" className="fa-lg" />
                      </span>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </SideSheet>
    </>
  )
}
