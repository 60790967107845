
import currency from 'currency.js'


export function formatMoney(value, excludeSymbol = false) {
  if (value !== 0 && !value) {
    return ''
  }

  let opts = {
    symbol: '€',
    name: 'euros',
    decimal: ',',
    separator: '.',
    formatWithSymbol: true,
    pattern: '# !',
  }
  if (excludeSymbol) {
    opts.symbol = ''
  }
  if (value % 100 === 0) {
    // Do not show decimals in exact prices
    opts.precision = 0
  }

  return currency(value, opts).divide(100).format().trim()
}


export function parseMoney(value) {
  return currency(value.replace(/,/, '.')).intValue
}
