
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { nanoid } from 'nanoid'
import cx from 'classnames'

import { useNavigate } from 'hooks/navigate'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import Input from 'components/simple-forms/Input'
import Checkbox from 'components/simple-forms/Checkbox'
import Select from 'components/simple-forms/Select'
import Selector from 'components/simple-forms/Selector'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import ButtonDanger from 'components/buttons/ButtonDanger'
import { HeaderCell, TableRow, Cell } from 'components/Table'
import SquareTable from 'components/SquareTable'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import { getEntity, putEntity } from 'models/docs/entities'
import { FIELD_TYPES, WEBINTERNA_REF_OPTIONS, getFieldType } from 'models/docs/document-types'


export default function RecordFamiliesFieldEdit() {
  let history = useHistory()
  let { name, idx } = useParams()

  let [recordFamily, setRecordFamily] = useState({})
  let [form, setForm] = useState({})
  let [fieldType, setFieldType] = useState('')
  let [spec, setSpec] = useState({})

  useNavigate(async () => {
    let reply = await getEntity(`record-families/${name}`)
    reply.fields = reply.fields || []
    setRecordFamily(reply)
    if (idx) {
      setForm(reply.fields[idx])
      let req = getFieldType(reply.fields[idx])
      setFieldType(req)
      let spec = reply.fields[idx][req]
      spec.options = spec.options?.map(opt => {
        opt.id = nanoid()
        opt.old = true
        return opt
      })
      setSpec(spec)
    }
  })

  async function save() {
    spec.options = spec.options?.map(opt => {
      delete opt.id
      delete opt.old
      return opt
    })
    let field = {
      ...form,
      [fieldType]: spec,
    }
    if (idx) {
      recordFamily.fields[idx] = field
    } else {
      field.name = (Math.random() + 1).toString(36)
      recordFamily.fields.push(field)
    }
    await putEntity('record-families', { recordFamily })
    history.push(`/manage/record-families/${name}`)
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/record-families">Familias de expedientes</Breadcrumb>
          <Breadcrumb url={`/manage/record-families/${name}`}>{recordFamily.displayName}</Breadcrumb>
          {idx ?
            <Breadcrumb url={`/manage/record-families/${name}/fields/${idx}`}>Editar campo</Breadcrumb>
            :
            <Breadcrumb url={`/manage/record-families/${name}/fields/add`}>Añadir campo</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos del campo"
        footer={
          <ButtonAsyncPrimary onClick={save}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <Input
          label="Nombre del campo"
          value={form.displayName}
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        />

        <label className="mt-6 block font-medium text-gray-700 text-sm">Validaciones</label>
        <Checkbox
          disabled={idx ? 'disabled' : ''}
          className="w-min"
          name="required"
          label="Obligatorio"
          value={form.required}
          onChange={value => {
            setForm({
              ...form,
              required: value,
            })
          }}
        ></Checkbox>

        <Checkbox
          disabled={idx ? 'disabled' : ''}
          className="w-max"
          name="multiple"
          label="Selección Múltiple"
          value={form.multiple}
          onChange={value => {
            setForm({
              ...form,
              multiple: value,
            })
          }}
        ></Checkbox>

        <Select
          disabled={idx ? 'disabled' : ''}
          className="mt-6 w-max"
          name="type"
          label="Tipo de campo"
          options={FIELD_TYPES}
          value={fieldType}
          onChange={value => {
            setFieldType(value)
            setSpec({})
          }}
        ></Select>

        {fieldType === 'text' &&
          <div className="mt-6 flex space-x-8">
            <Input
              disabled={idx ? 'disabled' : ''}
              className="w-36"
              type="number"
              label="Longitud mínima"
              value={spec.minLength}
              onChange={value => {
                setSpec({
                  ...spec,
                  minLength: value,
                })
              }}
            />
            <Input
              disabled={idx ? 'disabled' : ''}
              className="w-36"
              type="number"
              label="Longitud máxima"
              value={spec.maxLength}
              onChange={value => {
                setSpec({
                  ...spec,
                  maxLength: value,
                })
              }}
            />
          </div>
        }

        {fieldType === 'numeric' &&
          <div className="mt-6 flex space-x-8">
            <Input
              disabled={idx ? 'disabled' : ''}
              className="w-36"
              type="number"
              label="Valor mínimo"
              value={spec.min}
              onChange={value => {
                setSpec({
                  ...spec,
                  min: value,
                })
              }}
            />
            <Input
              disabled={idx ? 'disabled' : ''}
              className="w-36"
              type="number"
              label="Valor máximo"
              value={spec.max}
              onChange={value => {
                setSpec({
                  ...spec,
                  max: value,
                })
              }}
            />
          </div>
        }

        {fieldType === 'select' &&
          <>
            <label className="mt-6 block font-medium text-gray-700 text-sm mb-4">Opciones</label>
            <SquareTable
              className="-mx-4 sm:-mx-6 border-t border-gray-200"
              empty="No hay opciones disponibles"
              header={
                <>
                  <HeaderCell fit="right">Valor</HeaderCell>
                  <HeaderCell fit="right">Nombre</HeaderCell>
                  <HeaderCell></HeaderCell>
                </>
              }
            >
              {spec.options?.map((option, idx) => (
                <TableRow key={option.id}>
                  <Cell fit="right">
                    <input
                      disabled={option.old ? 'disabled' : ''}
                      type="text"
                      className={cx(
                        'w-full form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md',
                        {
                          'bg-gray-100 cursor-not-allowed text-gray-400': option.old,
                        },
                      )}
                      defaultValue={option.name}
                      onChange={e => {
                        let opts = spec.options
                        opts[idx].name = e.target.value.trim()
                        setSpec({
                          ...spec,
                          options: opts,
                        })
                      }}
                    />
                  </Cell>
                  <Cell fit="right">
                    <input
                      disabled={option.old ? 'disabled' : ''}
                      type="text"
                      className={cx(
                        'w-full form-input shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md',
                        {
                          'bg-gray-100 cursor-not-allowed text-gray-400': option.old,
                        },
                      )}
                      defaultValue={option.displayName}
                      onChange={e => {
                        let opts = spec.options
                        opts[idx].displayName = e.target.value.trim()
                        setSpec({
                          ...spec,
                          options: opts,
                        })
                      }}
                    />
                  </Cell>
                  {
                    !option.old && 
                      <Cell className="text-right" fit="left">
                        <ButtonDanger
                          onClick={() => {
                            let opts = spec.options
                            opts.splice(idx, 1)
                            setSpec({
                              ...spec,
                              options: opts,
                            })
                          }}
                        >
                          <Icon solid name="trash" />
                        </ButtonDanger>
                      </Cell>
                  }
                </TableRow>
              ))}
            </SquareTable>

            <ButtonSecondary
              className="mt-4"
              onClick={() => {
                let opts = spec.options || []
                opts.push({ id: nanoid() })
                setSpec({
                  ...spec,
                  options: opts,
                })
              }}
            >
              <Icon solid name="plus" className="mr-2" />
              Añadir opción
            </ButtonSecondary>
          </>
        }

        {(fieldType === 'webinternaRef' || fieldType === 'webinternaRefDescription') &&
          <>
            <Select
              disabled={idx ? 'disabled' : ''}
              className="mt-6 w-max"
              name="ref"
              label={fieldType === 'webinternaRef' ? 'Entidad de WebInterna' : 'Entidad de WebInterna con descripción'}
              options={WEBINTERNA_REF_OPTIONS}
              value={spec.ref}
              onChange={value => {
                setSpec({
                  ...spec,
                  ref: value,
                })
              }}
            ></Select>

            {spec.ref &&
              <Selector
                label="Campos visibles"
                className="mt-6"
                multiple
                value={spec.fields}
                options={WEBINTERNA_REF_OPTIONS.find(opt => opt.value === spec.ref).fields}
                onChange={value => {
                  setSpec({
                    ...spec,
                    fields: value,
                  })
                }}
              ></Selector>
            }
          </>
        }
      </CardHeader>
    </PageLayout>
  )
}
