
import React from 'react'
import cx from 'classnames'


export default function ButtonPrimary({ onClick, className, children, type = 'button', disabled = false }) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={cx(
        'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white',
        className,
        {
          'bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 active:bg-teal-500 focus:ring-teal-500 hover:text-white': !disabled,
          'bg-teal-300 cursor-default': disabled,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
