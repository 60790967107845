
import React from 'react'
import cx from 'classnames'


export default function ButtonExternalLinkPrimary({ children, className, to, disabled }) {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(
        'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none ',
        className,
        {
          'focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 hover:text-white hover:bg-teal-700 text-white bg-teal-600': !disabled,
          'text-gray-400 cursor-default pointer-events-none border-gray-300': disabled,
        },
      )}
    >
      {children}
    </a>
  )
}
