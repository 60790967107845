
import React from 'react'
import cx from 'classnames'


export default function InputButton({ name, className, label, value, onButtonClick, button, error, required }) {
  return (
    <div className={className}>
      <label htmlFor={name} className={cx(
        'block text-sm font-medium text-gray-700',
        {
          'text-red-700': error,
        },
      )}>
        {label}
        {required && label && <span className="text-red-700 align-text-top">&nbsp;*</span>}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            type="text"
            name={name}
            id={name}
            title={value}
            className={cx(
              'form-input focus:ring-transparent focus:border-gray-300 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 overflow-ellipsis',
              {
                'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
              },
            )}
            defaultValue={value}
            readOnly
          />
        </div>
        <button
          type="button"
          className={cx(
            '-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none',
            {
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
            },
          )}
          onClick={onButtonClick}
        >
          {button}
        </button>
      </div>
      {error && <p className="mt-2 text-red-700">{error}</p>}
    </div>
  )
}
