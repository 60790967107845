
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { webinterna } from 'services/discovery'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { useNavigate } from 'hooks/navigate'
import resolver from 'platform/resolver'
import { datetime, percentage, price } from 'platform/filters'
import { parseDate } from 'platform/datetime'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import Card from 'components/Card'
import CardHeader from 'components/CardHeader'
import { WideList, WideListEmpty, WideListItem } from 'components/WideList'
import BadgeGreen from 'components/badges/BadgeGreen'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import BadgeGray from 'components/badges/BadgeGray'
import RegistrosMercantilesClient from 'services/WebInterna/registros_mercantiles/registros_mercantiles'
import ImpuestosActividadClient from 'services/WebInterna/impuestos_actividad/impuestos_actividad'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { uniq, keyBy, merge } from 'lodash-es'
import { Cell, HeaderCell, Table, TableRow } from 'components/Table'
import CardTable from 'components/CardTable'
import EntityLink from 'components/EntityLink'
import PeopleViewer from 'components/people/PeopleViewer'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import MunicipiosClient from 'services/WebInterna/municipios/municipios'
import ProvinciasClient from 'services/WebInterna/provincias/provincias'
import { batchGetPersonas } from 'models/webinterna/personas'


const sociedadesClient = new SociedadesClient(webinterna())
const registrosClient = new RegistrosMercantilesClient(webinterna())
const impuestosClient = new ImpuestosActividadClient(webinterna())
const municipiosClient = new MunicipiosClient(webinterna())
const provinciasClient = new ProvinciasClient(webinterna())


async function getSociedadesPersonasWithDetails(items) {
  let personasNames = []
  let sociedadesNames = []
  items.forEach(item => {
    if (item.persona) {
      personasNames.push(item.persona)
    } else {
      sociedadesNames.push(item.sociedad)
    }
  })

  let { personas, sociedades } = await resolver.all({
    personas: batchGetPersonas(personasNames),
    sociedades: sociedadesClient.BatchGet({ names: uniq(sociedadesNames) }).then(reply => reply.sociedades || []),
  })

  let municipiosNames = Object.values(personas)
    .filter(p => p.municipio)
    .map(p => p.municipio)
  let { municipios } = await municipiosClient.BatchGet({ names: uniq(municipiosNames) })
  let provinciasNames = municipios?.map(municipio => municipio.provincia)
  let { provincias } = await provinciasClient.BatchGet({ names: uniq(provinciasNames) })
  municipios = keyBy(municipios, 'name')
  provincias = keyBy(provincias, 'name')

  personas = Object.values(personas).map(p => {
    return {
      ...p,
      isPeople: true,
      name: p.name,
      nombre: p.nombre,
      email: p.email,
      pictureUrl: p.fotoUrl,
      municipio: municipios[p.municipio],
      provincia: provincias[municipios[p.municipio].provincia],
      telefono: p.juridica ? p.juridica.telefono : (p.fisica.fisica ? p.personaContacto?.telefono : p.fisica.contribuidor?.telefonoEmpresa),
    }
  })
  sociedades = sociedades.map(s => {
    return {
      ...s,
      isCorp: true,
      name: s.name,
      acronimo: s.acronimo,
      nombre: s.denominacion,
      email: s.email,
    }
  })

  personas = keyBy(personas, 'name')
  sociedades = keyBy(sociedades, 'name')

  let results = merge(personas, sociedades)
  return results
}

export default function SociedadesShow() {
  let { name } = useParams()
  let [sociedad, setSociedad] = useState({})
  let [currentSocio, setCurrentSocio] = useState(null)

  useNavigate(async () => {
    let sociedad = await sociedadesClient.Get({ name })

    sociedad.cargas = sociedad.cargas || []
    sociedad.socios = sociedad.socios || []
    sociedad.apoderados = sociedad.apoderados || []
    sociedad.administradores = sociedad.administradores || []
    sociedad.titulares = sociedad.titulares || []

    sociedad.constitucionFecha = parseDate(sociedad.constitucionFecha)
    sociedad.extincionFecha = parseDate(sociedad.extincionFecha)
    sociedad.finEjercicioDate = parseDate(sociedad.finEjercicioDate)
    
    if (sociedad.iae) {
      sociedad.iae = await impuestosClient.Get({ name: sociedad.iae })
    }

    if (sociedad.municipio) {
      sociedad.municipio = await municipiosClient.Get({ name: sociedad.municipio })
      sociedad.municipio.provincia = await provinciasClient.Get({ name: sociedad.municipio.provincia })
    }

    let peopleNames = []
    let peopleNamesDetail = []
    let registrosNames = []
    if (sociedad.registro?.registro) {
      registrosNames.push(sociedad.registro.registro)
    }
    if (sociedad.escritura?.notario) {
      peopleNames.push({ persona: sociedad.escritura.notario })
    }
    sociedad.cargas.forEach(carga => {
      peopleNames.push({ persona: carga.notario })
    })
    sociedad.socios.forEach(socio => {
      peopleNamesDetail.push(socio.propietario)
      if (socio.rangos) {
        socio.rangos.forEach(rango => {
          if (rango.notario) {
            peopleNames.push({ persona: rango.notario })
          }
        })
      }
    })
    sociedad.apoderados.forEach(apoderado => {
      peopleNamesDetail.push(apoderado.apoderado)
      peopleNames.push({ persona: apoderado.notario })
      registrosNames.push(apoderado.registroMercantil.registro)
    })
    sociedad.administradores.forEach(administrador => {
      peopleNamesDetail.push(administrador.apoderado)
      peopleNames.push({ persona: administrador.notario })
      registrosNames.push(administrador.registroMercantil.registro)
    })
    sociedad.titulares.forEach(titular => {
      peopleNames.push({ persona: titular.titular })
      peopleNames.push({ persona: titular.notario })
    })

    let { personas, personasDetailed, registros } = await resolver.all({
      personas: fetchPersonasSociedades(peopleNames),
      personasDetailed: getSociedadesPersonasWithDetails(peopleNamesDetail),
      registros: registrosClient.BatchGet({ names: registrosNames }),
    })

    registros = keyBy(registros.registros, 'name')

    sociedad.registro.registro = registros[sociedad.registro.registro]
    if (sociedad.escritura?.notario) {
      sociedad.escritura.notario = personas[sociedad.escritura.notario]
    }

    sociedad.cargas = sociedad.cargas?.map(carga => {
      carga.ghostKey = nanoid()
      carga.expedicionFecha = parseDate(carga.expedicionFecha)
      carga.caducidadFecha = parseDate(carga.caducidadFecha)
      carga.notario = personas[carga.notario]
      return carga
    })
    sociedad.socios = sociedad.socios?.map(socio => {
      socio.ghostKey = nanoid()
      socio.propietario = personasDetailed[Object.values(socio.propietario)[0]]
      socio.rangos = socio.rangos?.map(rango => {
        rango.ghostKey = nanoid()
        rango.fecha = parseDate(rango.fecha)
        rango.altaFecha = parseDate(rango.altaFecha)
        rango.bajaFecha = parseDate(rango.bajaFecha)
        rango.notario = personas[rango.notario]
        return rango
      })
      return socio
    })
    sociedad.apoderados = sociedad.apoderados?.map(apoderado => {
      apoderado.ghostKey = nanoid()
      apoderado.fecha = parseDate(apoderado.fecha)
      apoderado.caducidadFecha = parseDate(apoderado.caducidadFecha)
      apoderado.apoderado = personasDetailed[Object.values(apoderado.apoderado)[0]]
      apoderado.notario = personas[apoderado.notario]
      apoderado.registroMercantil.registro = registros[apoderado.registroMercantil.registro]
      return apoderado
    })
    sociedad.administradores = sociedad.administradores?.map(administrador => {
      administrador.ghostKey = nanoid()
      administrador.fecha = parseDate(administrador.fecha)
      administrador.caducidadFecha = parseDate(administrador.caducidadFecha)
      administrador.apoderado = personasDetailed[Object.values(administrador.apoderado)[0]]
      administrador.notario = personas[administrador.notario]
      administrador.registroMercantil.registro = registros[administrador.registroMercantil.registro]
      return administrador
    })
    sociedad.titulares = sociedad.titulares?.map(titular => {
      titular.ghostKey = nanoid()
      titular.fecha = parseDate(titular.fecha)
      titular.titular = personas[titular.titular]
      titular.notario = personas[titular.notario]
      return titular
    })

    sociedad.participacionesTotales = sociedad.socios
      ?.map(socio => socio.rangos?.reduce((prev, rango) => prev + rango.endParticipaciones - rango.startParticipaciones, 0))
      .reduce((prev, rango) => prev + rango, 0)
    if (!sociedad.participacionesTotales) {
      sociedad.participacionesTotales = 0
    }

    sociedad.numeroSocios = sociedad.socios?.length
    
    sociedad.valorPrimas = sociedad.socios
      ?.map(socio => socio.rangos?.reduce((prev, rango) => prev + (rango.prima ?? 0), 0))
      .reduce((prev, rango) => prev + rango, 0)
    if (!sociedad.valorPrimas) {
      sociedad.valorPrimas = 0
    }

    let clases = sociedad.socios
      ?.map(socio => {
        return { ...socio.rangos }
      })
      .map(rango => rango.clase)
    if (clases) {
      sociedad.numeroClasesParticipaciones = uniq(clases).length
    }

    setSociedad(sociedad)
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!sociedad.blocked &&
            <Acl perm="webinterna.sociedades.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/sociedades/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Sociedad
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/sociedades">Sociedades</Breadcrumb>
        <Breadcrumb url={`/webinterna/sociedades/${name}`}>Consultar Sociedad</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={sociedad.blocked}></EntityBlockedAlert>

      <div className="flex mb-6">
        <div className="space-y-4 w-1/2 mr-4">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Título Sociedad">{sociedad.denominacion}</ShowPane>
              <ShowPane title="Acrónimo">{sociedad.acronimo}</ShowPane>
              <ShowPane title="CIF">{sociedad.cif}</ShowPane>
              <ShowPane title="Registro">{sociedad.registro?.registro?.nombre}</ShowPane>
              <ShowPane title="Tomo">{sociedad.registro?.tomo}</ShowPane>
              <ShowPane title="Libro">{sociedad.registro?.libro}</ShowPane>
              <ShowPane title="Folio">{sociedad.registro?.folio}</ShowPane>
              <ShowPane title="Hoja">{sociedad.registro?.hoja}</ShowPane>
              <ShowPane title="Sección">{sociedad.registro?.seccion}</ShowPane>
              <ShowPane title="Notario">
                {sociedad.escritura && sociedad.escritura.notario && <PeopleViewer value={{ persona: sociedad.escritura?.notario.name }}></PeopleViewer>}
              </ShowPane>
              <ShowPane title="Protocolo">{sociedad.escritura?.protocolo}</ShowPane>
              <ShowPane title="Capital Social">{price(sociedad.capitalSocial)}</ShowPane>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="Nº participaciones totales">{sociedad.participacionesTotales}</ShowPane>
              <ShowPane title="Nº socios">{sociedad.numeroSocios}</ShowPane>
              <ShowPane title="Valor de primas">{sociedad.valorPrimas}</ShowPane>
              <ShowPane title="Nº clases de participaciones">{sociedad.numeroClasesParticipaciones}</ShowPane>
            </div>
          </Card>
        </div>
        <div className="space-y-4 w-1/2">
          <Card>
            <div className="flex flex-col gap-4">
              <ShowPane title="IAE Principal">{sociedad.iae?.nombre}</ShowPane>
              <ShowPane title="Fecha de constitución">{datetime(sociedad.constitucionFecha, 'DATE')}</ShowPane>
              <ShowPane title="Fecha de extinción">{datetime(sociedad.extincionFecha, 'DATE')}</ShowPane>
              <ShowPane title="Fin de ejercicio">{datetime(sociedad.finEjercicioDate, 'DATE')}</ShowPane>
              <ShowPane title="Duración">{sociedad.duracion}</ShowPane>
              <ShowPane title="Grupo">{sociedad.grupo}</ShowPane>
              <ShowPane title="Subgrupo">{sociedad.subgrupo}</ShowPane>
              <ShowPane title="Estado gestión">
                {sociedad.gestion === 'GESTION_DIRECTA' && 'Gestión directa'}
                {sociedad.gestion === 'GESTION_CONTROL' && 'Control de gestión'}
                {sociedad.gestion === 'GESTION_AJENA' && 'Gestión ajena'}
              </ShowPane>
              <ShowPane title="Teléfono">{sociedad.telefono}</ShowPane>
              <ShowPane title="Email">{sociedad.email}</ShowPane>
              <ShowPane title="Municipio">{sociedad.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">{sociedad.municipio?.provincia?.nombre}</ShowPane>
              <ShowPane title="Dirección">{sociedad.direccion}</ShowPane>
              <ShowPane title="Código postal">{sociedad.codigoPostal}</ShowPane>
            </div>
          </Card>
        </div>
      </div>

      {currentSocio === null ? 
        <CardTable
          className="mt-8"
          title="Socios"
          header={
            <>
              <HeaderCell>Socio</HeaderCell>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell>Nº participaciones</HeaderCell>
              <HeaderCell>Valor nominal</HeaderCell>
              <HeaderCell>% participación</HeaderCell>
              <HeaderCell></HeaderCell>
            </>
          }
          empty="No hay socios"
        >
          {sociedad.socios && sociedad.socios.map((socio, index) => (
            <TableRow key={socio.propietario.name}>
              <Cell>
                <EntityLink to={`/webinterna/${socio.propietario.isPeople ? 'personas' : 'sociedades'}/${socio.propietario.name}`}>{socio.propietario.nombre}</EntityLink>
              </Cell>
              <Cell>
                {socio.propietario.email}
              </Cell>
              <Cell>{socio.rangos?.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0)}</Cell>
              <Cell>
                {socio.rangos?.reduce(
                  (total, rango) => total + (rango.endParticipaciones - rango.startParticipaciones) * (rango.valorUnitario ?? 0) / 100, 0,
                ) ?? 0}
              </Cell>
              <Cell>
                {percentage(sociedad.participacionesTotales
                  ? (socio.rangos?.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0) * 100 / sociedad.participacionesTotales) * 100 ?? 0
                  : 0)
                }
              </Cell>
              <Cell className="text-right">
                <Link onClick={() => setCurrentSocio(sociedad.socios[index])}>Consultar</Link>
              </Cell>
            </TableRow>
          ))}
        </CardTable>
        :
        <CardHeader
          className="mt-8"
          header={`Datos del socio ${currentSocio.propietario.nombre}`}
          fit
          actions={
            <ButtonSecondary onClick={() => setCurrentSocio(null)}>
              <Icon regular name="chevron-left" className="mr-2" />
              Volver
            </ButtonSecondary>
          }
          empty="No hay socios"
        >
          <div className="flex mb-6 mt-4">
            <div className="space-y-4 w-1/2 mr-4 flex flex-col">
              <ShowPane title="DNI/CIF">{currentSocio.propietario.isPeople ? currentSocio.propietario.identidad : currentSocio.propietario.cif}</ShowPane>
              <ShowPane title="Representante">
                {currentSocio.propietario &&
                  <PeopleViewer value={currentSocio.propietario.isPeople ?
                    {
                      persona: currentSocio.propietario.name,
                    }
                    :
                    {
                      sociedad: currentSocio.propietario.name,
                    }
                  }/>
                }
              </ShowPane>
              <ShowPane title="Dirección">
                {currentSocio.propietario.calle ? `${currentSocio.propietario.calle}, ${currentSocio.propietario.numero}` : ''}
              </ShowPane>
              <ShowPane title="CP">{currentSocio.propietario.codigoPostal}</ShowPane>
              <ShowPane title="Municipio">{currentSocio.propietario.municipio?.nombre}</ShowPane>
              <ShowPane title="Provincia">{currentSocio.propietario.provincia?.nombre}</ShowPane>
              <ShowPane title="Teléfono de contacto">{currentSocio.propietario.telefono}</ShowPane>
            </div>
            <div className="space-y-4 w-1/2 flex flex-col">
              <ShowPane title="Nº participaciones">
                {currentSocio.rangos.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0)}
              </ShowPane>
              <ShowPane title="% participación">
                {sociedad.participacionesTotales
                  ? (currentSocio.rangos?.reduce((acc, curr) => acc + curr.endParticipaciones - curr.startParticipaciones, 0) * 100 / sociedad.participacionesTotales) ?? 0
                  : 0
                }
                &nbsp;%
              </ShowPane>
              <ShowPane title="Valor nominal">
                {currentSocio.rangos?.reduce(
                  (total, rango) => total + (rango.endParticipaciones - rango.startParticipaciones) * (rango.valorNominal ?? 0), 0,
                ) ?? 0}
              </ShowPane>
              <ShowPane title="Nombre representante">
                {currentSocio.propietario.personaContacto?.nombre} &nbsp; {currentSocio.propietario.personaContacto?.apellidos}
              </ShowPane>
              <ShowPane title="Teléfono representante">
                {currentSocio.propietario.personaContacto?.telefono}
              </ShowPane>
              <ShowPane title="Email representante">
                {currentSocio.propietario.personaContacto?.email}
              </ShowPane>
            </div>
          </div>
          
          <Table
            header={
              <>
                <HeaderCell>Rango</HeaderCell>
                <HeaderCell>Título</HeaderCell>
                <HeaderCell>Notario</HeaderCell>
                <HeaderCell>Protocolo</HeaderCell>
                <HeaderCell>Fecha</HeaderCell>
                <HeaderCell>Relación de propiedad</HeaderCell>
                <HeaderCell>Alta participación</HeaderCell>
                <HeaderCell>Baja participación</HeaderCell>
                <HeaderCell>Prima</HeaderCell>
                <HeaderCell>Clase</HeaderCell>
                <HeaderCell>Valor unitario</HeaderCell>
              </>
            }
          >
            {currentSocio.rangos && currentSocio.rangos.map(rango => (
              <TableRow key={rango.ghostKey}>
                <Cell>
                  {rango.startParticipaciones} - {rango.endParticipaciones}
                </Cell>
                <Cell>
                  {rango.titulo}
                </Cell>
                <Cell>
                  {rango.notario &&
                    <EntityLink to={`/webinterna/personas/${rango.notario.name}`}>{rango.notario.nombre}</EntityLink>
                  }
                </Cell>
                <Cell>
                  {rango.protocolo}
                </Cell>
                <Cell>
                  {datetime(rango.fecha, 'DATE')}
                </Cell>
                <Cell>
                  {rango.relacionPropiedad ? 'Sí' : 'No'}
                </Cell>
                <Cell>
                  {datetime(rango.altaFecha, 'DATE')}
                </Cell>
                <Cell>
                  {datetime(rango.bajaFecha, 'DATE')}
                </Cell>
                <Cell>
                  {price(rango.prima)}
                </Cell>
                <Cell>
                  {rango.clase}
                </Cell>
                <Cell>
                  {price(rango.valorUnitario)}
                </Cell>
              </TableRow>
            ))}
          </Table>
        </CardHeader>
      }

      <CardHeader
        fit
        className="mt-8"
        header="Administradores"
        subheader="Administradores de la sociedad."
      >
        <WideList divider="divide-y-4 divide-teal-400">
          {!sociedad.administradores?.length && <WideListEmpty>No hay administradores</WideListEmpty>}
          {sociedad.administradores?.map((administrador, index) => (
            <WideListItem key={administrador.ghostKey}>
              <h3 className="text-base leading-6 font-medium text-gray-900 mb-6">Administrador {index + 1}</h3>

              <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                <div className="flex flex-col gap-y-6">
                  <ShowPane title="Apoderado">
                    <PeopleViewer value={{ persona: administrador.apoderado.name }}></PeopleViewer>
                  </ShowPane>
                  <ShowPane title="Tipo Relación">{administrador.tipoRelacion}</ShowPane>
                  <ShowPane title="Título">{administrador.titulo}</ShowPane>
                  <ShowPane title="Protocolo">{administrador.protocolo}</ShowPane>
                  <ShowPane title="Fecha">{datetime(administrador.fecha, 'DATE')}</ShowPane>
                  <ShowPane title="Fecha Caducidad">{datetime(administrador.caducidadFecha, 'DATE')}</ShowPane>
                  <ShowPane title="Vigencia">
                    {administrador.vigente
                      ? <BadgeGreen>Vigente</BadgeGreen>
                      : <BadgeGray>No Vigente</BadgeGray>
                    }
                  </ShowPane>
                  <ShowPane title="Nombre representante">
                    {administrador.apoderado.personaContacto?.nombre} &nbsp; {administrador.apoderado.personaContacto?.apellidos}
                  </ShowPane>
                  <ShowPane title="Teléfono representante">
                    {administrador.apoderado.personaContacto?.telefono}
                  </ShowPane>
                  <ShowPane title="Email representante">
                    {administrador.apoderado.personaContacto?.email}
                  </ShowPane>
                </div>

                <div className="flex flex-col gap-y-6">
                  <ShowPane title="Inscripción">{administrador.registroMercantil.inscripcion}</ShowPane>
                  <ShowPane title="Registro">{administrador.registroMercantil.registro?.nombre}</ShowPane>
                  <ShowPane title="Tomo">{administrador.registroMercantil.tomo}</ShowPane>
                  <ShowPane title="Libro">{administrador.registroMercantil.libro}</ShowPane>
                  <ShowPane title="Folio">{administrador.registroMercantil.folio}</ShowPane>
                  <ShowPane title="Hoja">{administrador.registroMercantil.hoja}</ShowPane>
                  <ShowPane title="Sección">{administrador.registroMercantil.seccion}</ShowPane>
                  <ShowPane title="Notario">
                    <PeopleViewer value={{ persona: administrador.notario.name }}></PeopleViewer>
                  </ShowPane>
                </div>
              </div>
            </WideListItem>
          ))}
        </WideList>
      </CardHeader>

      <CardHeader
        fit
        className="my-8"
        header="Apoderados"
        subheader="Apoderados de la sociedad."
      >
        <WideList divider="divide-y-4 divide-teal-400">
          {!sociedad.apoderados?.length && <WideListEmpty>No hay apoderados</WideListEmpty>}
          {sociedad.apoderados?.map((apoderado, index) => (
            <WideListItem key={apoderado.ghostKey}>
              <h3 className="text-base leading-6 font-medium text-gray-900 mb-6">Apoderado {index + 1}</h3>

              <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                <div className="flex flex-col gap-y-6">
                  <ShowPane title="Apoderado">
                    <PeopleViewer value={{ persona: apoderado.apoderado.name }}></PeopleViewer>
                  </ShowPane>
                  <ShowPane title="Tipo Relación">{apoderado.tipoRelacion}</ShowPane>
                  <ShowPane title="Título">{apoderado.titulo}</ShowPane>
                  <ShowPane title="Protocolo">{apoderado.protocolo}</ShowPane>
                  <ShowPane title="Fecha">{datetime(apoderado.fecha, 'DATE')}</ShowPane>
                  <ShowPane title="Fecha Caducidad">{datetime(apoderado.caducidadFecha, 'DATE')}</ShowPane>
                  <ShowPane title="Vigencia">
                    {apoderado.vigente
                      ? <BadgeGreen>Vigente</BadgeGreen>
                      : <BadgeGray>No Vigente</BadgeGray>
                    }
                  </ShowPane>
                  <ShowPane title="Nombre representante">
                    {apoderado.apoderado.personaContacto?.nombre} &nbsp; {apoderado.apoderado.personaContacto?.apellidos}
                  </ShowPane>
                  <ShowPane title="Teléfono representante">
                    {apoderado.apoderado.personaContacto?.telefono}
                  </ShowPane>
                  <ShowPane title="Email representante">
                    {apoderado.apoderado.personaContacto?.email}
                  </ShowPane>
                </div>

                <div className="flex flex-col gap-y-6">
                  <ShowPane title="Inscripción">{apoderado.registroMercantil.inscripcion}</ShowPane>
                  <ShowPane title="Registro">{apoderado.registroMercantil.registro?.nombre}</ShowPane>
                  <ShowPane title="Tomo">{apoderado.registroMercantil.tomo}</ShowPane>
                  <ShowPane title="Libro">{apoderado.registroMercantil.libro}</ShowPane>
                  <ShowPane title="Folio">{apoderado.registroMercantil.folio}</ShowPane>
                  <ShowPane title="Hoja">{apoderado.registroMercantil.hoja}</ShowPane>
                  <ShowPane title="Sección">{apoderado.registroMercantil.seccion}</ShowPane>
                  <ShowPane title="Notario">
                    <PeopleViewer value={{ persona: apoderado.notario.name }}></PeopleViewer>
                  </ShowPane>
                </div>
              </div>
            </WideListItem>
          ))}
        </WideList>
      </CardHeader>

      <CardTable
        title="Cargas"
        empty="No hay cargas"
        header={
          <>
            <HeaderCell>Rango</HeaderCell>
            <HeaderCell>Beneficiario</HeaderCell>
            <HeaderCell>Tipo</HeaderCell>
            <HeaderCell>Título</HeaderCell>
            <HeaderCell>Notario</HeaderCell>
            <HeaderCell>Protocolo</HeaderCell>
            <HeaderCell>Fecha</HeaderCell>
            <HeaderCell>Obl. garantizada</HeaderCell>
            <HeaderCell>Imp. garantizado</HeaderCell>
            <HeaderCell>Fecha cad.</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
          </>
        }
      >
        {sociedad.cargas && sociedad.cargas.map(carga => (
          <TableRow key={carga.ghostKey}>
            <Cell>{carga.startParticipaciones} - {carga.endParticipaciones} ({carga.endParticipaciones - carga.startParticipaciones})</Cell>
            <Cell>{carga.beneficiario}</Cell>
            <Cell>{carga.tipo}</Cell>
            <Cell>{carga.titulo}</Cell>
            <Cell>
              <EntityLink to={`/webinterna/personas/${carga.notario.name}`}>{carga.notario.nombre}</EntityLink>
            </Cell>
            <Cell>{carga.protocolo}</Cell>
            <Cell>{datetime(carga.expedicionFecha, 'DATE')}</Cell>
            <Cell>{carga.obligacionGarantizada}</Cell>
            <Cell>{carga.importeGarantizado}</Cell>
            <Cell>{datetime(carga.caducidadFecha, 'DATE')}</Cell>
            <Cell>{carga.estado}</Cell>
          </TableRow>
        ))}
      </CardTable>

      <CardTable
        title="Titulares"
        empty="No hay titulares"
        className="mt-8"
        header={
          <>
            <HeaderCell>Titular</HeaderCell>
            <HeaderCell>Porcentaje</HeaderCell>
            <HeaderCell>Notario</HeaderCell>
            <HeaderCell>Protocolo</HeaderCell>
            <HeaderCell>Fecha</HeaderCell>
          </>
        }
      >
        {sociedad.titulares && sociedad.titulares.map(titular => (
          <TableRow key={titular.titular.name}>
            <Cell>
              <EntityLink to={`/webinterna/personas/${titular.titular.name}`}>{titular.titular.nombre}</EntityLink>
            </Cell>
            <Cell>{titular.porcentaje}</Cell>
            <Cell>
              <EntityLink to={`/webinterna/personas/${titular.notario.name}`}>{titular.notario.nombre}</EntityLink>
            </Cell>
            <Cell>{titular.protocolo}</Cell>
            <Cell>{datetime(titular.fecha, 'DATE')}</Cell>
          </TableRow>
        ))}
      </CardTable>
    </PageLayout>
  )
}
