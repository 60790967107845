
import React from 'react'
import cx from 'classnames'

import GeneratedAvatar from 'components/people/GeneratedAvatar'


export default function Face({ pictureUrl, displayName, size = 'large' }) {
  return (
    <div>
      {pictureUrl
        ?
        <img
          className={cx(
            'inline-block rounded-full ring-2 ring-white',
            {
              'h-8 w-8': size === 'normal',
              'h-10 w-10': size === 'large',
              'h-36 w-36': size === 'huge',
            },
          )}
          src={pictureUrl}
          alt={displayName}
        />
        :
        <GeneratedAvatar displayName={displayName} size={size}></GeneratedAvatar>
      }
    </div>
  )
}
