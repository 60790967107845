
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { some } from 'lodash-es'

import { useNavigate } from 'hooks/navigate'
import { getEntity, putEntity } from 'models/docs/entities'
import PageLayout from 'layout/manage/PageLayout'
import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import CardHeader from 'components/CardHeader'
import Icon from 'components/Icon'
import ButtonAsyncPrimary from 'components/buttons/ButtonAsyncPrimary'
import Input from 'components/simple-forms/Input'

export default function ArchiveRoomsLockerEdit() {
  let history = useHistory()
  let { parent, name } = useParams()

  let [room, setRoom] = useState({})
  let [locker, setLocker] = useState({})
  let [form, setForm] = useState({})

  useNavigate(async () => {
    let reply =  await getEntity(`archive-rooms/${parent}`)
    setRoom(reply)
    if (name) {
      let reply = await getEntity(`archive-rooms/${parent}/archive-rooms-lockers/${name}`)
      setLocker(reply)
      setForm(reply)
    }
  })

  async function save() {
    await putEntity('archive-rooms-lockers', { archiveRoomLocker: { ...form, parent: room.name } })
    history.push(`/manage/${room.name}`)
  }

  let changed = some([
    locker.displayName !== form.displayName,
  ]) && form.displayName


  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/manage">Administración</Breadcrumb>
          <Breadcrumb url="/manage/archive-rooms">Habitaciones</Breadcrumb>
          <Breadcrumb url={`/manage/archive-rooms/${parent}`}>{room.displayName}</Breadcrumb>
          {name ?
            <Breadcrumb url={`/manage/archive-rooms/${parent}/archive-rooms-lockers/${name}`}>Editar Armario</Breadcrumb>
            :
            <Breadcrumb url={`/manage/archive-rooms/${parent}/archive-rooms-lockers/add`}>Añadir Armario</Breadcrumb>
          }
        </Breadcrumbs>
      }
    >
      <CardHeader
        header="Datos del Armario"
        footer={
          <ButtonAsyncPrimary onClick={save} disabled={!changed}>
            <Icon solid name="save" className="mr-2" />
            Guardar cambios
          </ButtonAsyncPrimary>
        }
      >
        <Input
          label="Nombre del Armario"
          value={form.displayName}
          onChange={value => {
            setForm({
              ...form,
              displayName: value,
            })
          }}
        />
      </CardHeader>
    </PageLayout>
  )
}
