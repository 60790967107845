
import React, { useState, useEffect } from 'react'
import cx from 'classnames'


const COLORS = ['bg-red-300', 'bg-yellow-300', 'bg-green-300', 'bg-blue-300', 'bg-indigo-300', 'bg-purple-300', 'bg-pink-300']


export default function GeneratedAvatar({ displayName, size = 'normal' }) {
  let [initials, setInitials] = useState('')

  useEffect(() => {
    let parts = displayName.split(' ')
    if (parts.length > 1) {
      setInitials(`${parts[0].charAt(0)}${parts[1].charAt(0)}`.toLocaleUpperCase())
      return
    }

    setInitials(`${parts[0].charAt(0)}${parts[0].charAt(1)}`.toLocaleUpperCase())
  }, [displayName, setInitials])

  function calculateAvatarColor() {
    let charCodes = initials
      .split('')
      .map(char => char.charCodeAt(0))
      .join('')

    return COLORS[parseInt(charCodes, 10) % COLORS.length]
  }

  return (
    <>
      <span className={cx(
        `inline-flex items-center justify-center rounded-full ${calculateAvatarColor()}`,
        {
          'h-8 w-8': size === 'normal',
          'h-10 w-10': size === 'large',
          'h-36 w-36': size === 'huge',
        },
      )}>
        <span className="text-sm font-medium leading-none text-white">{initials}</span>
      </span>
    </>
  )
}
