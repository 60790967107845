
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { uniq, keyBy } from 'lodash-es'
import cx from 'classnames'

import { Breadcrumbs, Breadcrumb } from 'layout/HeaderMenu'
import PageLayout from 'layout/docs/PageLayout'
import { useNavigate } from 'hooks/navigate'
import { getEntity, batchGetEntities, listEntities } from 'models/docs/entities'
import Icon from 'components/Icon'
import Card from 'components/Card'
import { datetime } from 'platform/filters'
import ShowPane from 'components/ShowPane'
import { parseTimestamp } from 'platform/datetime'
import UsersClient from 'services/Users/users/users'
import FilesClient from 'services/Docs/files/files'
import { users, docs } from 'services/discovery'
import AccessClient from 'services/Docs/access/access'
import Face from 'components/people/Face'
import PdfPreview from 'components/PdfPreview'
import { fetchPersonasSociedades } from 'models/webinterna/personas-sociedades'
import { CustomFieldsViewer } from 'components/CustomFieldsViewer'
import { DocumentDownload } from 'views/archive/internal/DocumentDownload'
import WithdrawDocument from 'components/WithdrawDocument'


const accessClient = new AccessClient(docs())
const usersClient = new UsersClient(users())
const filesClient = new FilesClient(docs())


function ViewUser({ revision }) {
  return (
    <span className="font-medium text-gray-900">
      {revision.user.displayName}
    </span>
  )
}


export function DocumentShow() {
  let history = useHistory()
  let { code } = useParams()
  let [doc, setDoc] = useState({})
  let [revisions, setRevisions] = useState([])

  useNavigate(async () => {
    let name = `documents/${code}`
    let { access } = await accessClient.AccessDocument({ name, level: 'LEVEL_VIEW' })
    if (!access) {
      history.push('/archive')
      return
    }
    
    let doc = await getEntity(name)
    doc.owners = doc.owners || []
    doc.createTime = parseTimestamp(doc.createTime)

    let revisions = await listEntities('revisions', doc.name)

    let names = revisions.filter(r => r.user).map(r => r.user)
    names.push(doc.uploader)
    let { users } = await usersClient.BatchGet({ names: uniq(names) })
    users = keyBy(users, 'name')

    let fileNames = revisions.filter(r => r.update && r.update.file).map(r => r.update.file)
    fileNames.push(doc.file)
    fileNames = uniq(fileNames)

    let { files } = await filesClient.BatchGet({ names: fileNames })
    let urls = {}
    fileNames.forEach((n, idx) => {
      urls[n] = files[idx].downloadUrl
    })

    revisions = revisions.map(r => {
      r.user = users[r.user]
      r.createTime = parseTimestamp(r.createTime)
      if (r.update && r.update.file) {
        r.update.fileUrl = urls[r.update.file]
      }

      if (r.controlReview?.comment) {
        r.comment = r.controlReview.comment
      }
      if (r.managementReview?.comment) {
        r.comment = r.managementReview.comment
      }
      if (r.validationReview?.comment) {
        r.comment = r.validationReview.comment
      }
      if (r.rejectedSignature?.comment) {
        r.comment = r.rejectedSignature.comment
      }
      
      return r
    })
    setRevisions(revisions.sort((a, b) => b.createTime - a.createTime))

    doc.uploader = users[doc.uploader]
    doc.fileUrl = urls[doc.file]

    let entities = await batchGetEntities([doc.owners[0].name, doc.owners[0].documentType])
    let owners = await fetchPersonasSociedades([entities[0].owner])
    doc.owner = owners.resolve(entities[0].owner)
    doc.documentType = entities[1]

    let fieldsInfo = keyBy(doc.documentType.fields, 'name')
    doc.fields = doc.fields?.map(field => {
      field.values = field.values || []
      field.info = fieldsInfo[field.name]
      field.info.options = keyBy(field.info.select?.options, 'name')
      return field
    })

    setDoc(doc)
  })

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb url="/docs">Gestor documental</Breadcrumb>
          <Breadcrumb url="/docs/foo">{doc.title}</Breadcrumb>
        </Breadcrumbs>
      }
    >
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{doc.title}</h1>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <WithdrawDocument doc={doc}/>
          <DocumentDownload doc={doc} />
        </div>
      </div>

      <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <Card
            title="Información general"
            subtitle="Información general del documento."
          >
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
              <ShowPane title="Propietario">
                <div>{doc.owner?.nombre}</div>
                <div className="text-gray-500">{doc.documentType?.displayName}</div>
              </ShowPane>

              <ShowPane title="Formato">
                {doc.format === 'FORMAT_DIGITAL' && 'Digital'}
                {doc.format === 'FORMAT_PHYSICAL' && 'Físico'}
              </ShowPane>

              <ShowPane title="Solicitante">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Face displayName={doc.uploader?.displayName || ''} pictureUrl={doc.uploader?.pictureUrl} large></Face>
                  </div>
                  <div className="ml-3">
                    <div className="text-base text-gray-800">{doc.uploader?.displayName}</div>
                    <div className="text-sm text-gray-500">{doc.uploader?.email}</div>
                  </div>
                </div>
              </ShowPane>

              <ShowPane title="Fecha de creación">
                {datetime(doc.createTime, 'DATETIME')}
              </ShowPane>
            </div>
          </Card>

          {doc.fields && doc.fields.length &&
            <Card
              className="mt-8"
              title="Campos personalizados"
              subtitle="Datos acordes a lo que requiere el tipo de documento."
            >
              <CustomFieldsViewer fields={doc.fields} />
            </Card>
          }

          <Card
            title="Historial del documento"
            subtitle="Información de los cambios del documento."
          >
            <div className="flow-root mt-2">
              <ul>
                {revisions.map((revision, idx) => (
                  <li key={revision.name}>
                    <div className={cx(
                      'relative',
                      {
                        'pb-8': idx !== revisions.length - 1,
                      },
                    )}>
                      {idx !== revisions.length - 1 &&
                        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"/>
                      }
                      <div className="relative flex items-start space-x-5">
                        <div className="relative">
                          {revision.user &&
                            <Face displayName={revision.user.displayName} pictureUrl={revision.user.pictureUrl} large></Face>
                          }
                          {revision.thirdParty === 'THIRD_PARTY_PORTASIGMA' && 
                            <Face displayName="Portasigma" large></Face>
                          }
                          <span className="absolute -bottom-2 -right-1 bg-white rounded-tl px-0.5 py-px">
                            {revision.update && revision.update.fileUrl &&
                              <Icon solid name="file-upload" className="h-5 w-5 text-blue-400"></Icon>
                            }
                            {revision.controlReview && revision.controlReview.verification === 'VERIFICATION_APPROVED' &&
                              <Icon solid name="check-square" className="h-5 w-5 text-green-400"></Icon>
                            }
                            {revision.controlReview && revision.controlReview.verification === 'VERIFICATION_DENIED' &&
                              <Icon solid name="times-square" className="h-5 w-5 text-red-400"></Icon>
                            }
                            {revision.managementReview && revision.managementReview.verification === 'VERIFICATION_APPROVED' &&
                              <Icon solid name="check-square" className="h-5 w-5 text-green-400"></Icon>
                            }
                            {revision.managementReview && revision.managementReview.verification === 'VERIFICATION_DENIED' &&
                              <Icon solid name="times-square" className="h-5 w-5 text-red-400"></Icon>
                            }
                            {revision.validationReview && revision.validationReview.verification === 'VERIFICATION_APPROVED' &&
                              <Icon solid name="check-square" className="h-5 w-5 text-green-400"></Icon>
                            }
                            {revision.validationReview && revision.validationReview.verification === 'VERIFICATION_DENIED' &&
                              <Icon solid name="times-square" className="h-5 w-5 text-red-400"></Icon>
                            }
                            {revision.rejectedSignature &&
                              <Icon solid name="times-square" className="h-5 w-5 text-red-400"></Icon>
                            }
                            {revision.internalSignature &&
                              <Icon solid name="signature" className="h-5 w-5 text-green-400"></Icon>
                            }
                            {revision.classification &&
                              <Icon regular name="project-diagram" className="h-5 w-5 text-green-400"></Icon>
                            }
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm text-gray-500">
                              {revision.update &&
                                <>
                                  {!revision.update.from &&
                                    <>
                                      <ViewUser revision={revision}></ViewUser>
                                      &nbsp;ha subido un nuevo documento.&nbsp;
                                    </>
                                  }
                                  {revision.update.from && revision.update.to === 'STATE_UPLOADED' &&
                                    <>
                                      <ViewUser revision={revision}></ViewUser>
                                      &nbsp;ha realizado cambios en el documento.
                                    </>
                                  }
                                  {revision.update.to === 'STATE_REVIEWED' && 
                                    'El documento está pendiente de cambios por el solicitante.'
                                  }
                                  {revision.update.to === 'STATE_MANAGEMENT_PENDING' && 
                                    'El documento está pendiente de revisión por el responsable de gestión.'
                                  }
                                  {revision.update.to === 'STATE_CONTROL_PENDING' && 
                                    'El documento está pendiente de revisión por el responsable de control.'
                                  }
                                  {revision.update.to === 'STATE_CLASSIFICATION' && 
                                    'El documento está pendiente de clasificación.'
                                  }
                                  {revision.update.to === 'STATE_DIGITAL_SIGNATURES' && 
                                    'El documento está a la espera de la firma digital de los firmantes.'
                                  }
                                  {revision.update.to === 'STATE_PHYSICAL_SIGNATURES' && 
                                    'El documento está a la espera de la firma física de los firmantes.'
                                  }
                                  {revision.update.to === 'STATE_SIGNATURE_COUNTERPART' && 
                                    'El documento está a la espera de la contraparte.'
                                  }
                                  {revision.update.to === 'STATE_VALIDATION' && 
                                    'El documento está pendiente de la validación final.'
                                  }
                                  {revision.update.to === 'STATE_ARCHIVED' && 
                                    'El documento ha sido archivado.'
                                  }

                                  {revision.update.fileUrl &&
                                    <>
                                      &nbsp;
                                      <a
                                        href={revision.update.fileUrl}
                                        className="text-gray-600 ml-3 whitespace-nowrap"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Ver versión
                                        <Icon regular name="external-link" className="ml-1" />
                                      </a>
                                    </>
                                  }
                                </>
                              }
                              {revision.controlReview &&
                                <>
                                  <ViewUser revision={revision}></ViewUser>
                                  {revision.controlReview.verification === 'VERIFICATION_APPROVED' && ' ha aprobado el documento.'}
                                  {revision.controlReview.verification === 'VERIFICATION_DENIED' && ' ha pedido cambios en el documento.'}
                                </>
                              }
                              {revision.managementReview &&
                                <>
                                  <ViewUser revision={revision}></ViewUser>
                                  {revision.managementReview.verification === 'VERIFICATION_APPROVED' && ' ha aprobado el documento.'}
                                  {revision.managementReview.verification === 'VERIFICATION_DENIED' && ' ha pedido cambios en el documento.'}
                                </>
                              }
                              {revision.validationReview &&
                                <>
                                  <ViewUser revision={revision}></ViewUser>
                                  {revision.validationReview.verification === 'VERIFICATION_APPROVED' && ' ha aprobado el documento.'}
                                  {revision.validationReview.verification === 'VERIFICATION_DENIED' && ' ha pedido cambios en el documento.'}
                                </>
                              }
                              {revision.classification &&
                                <>
                                  <ViewUser revision={revision}></ViewUser>
                                  &nbsp;ha clasificado el documento.
                                </>
                              }
                              {revision.internalSignature && 'El documento ha sido firmado completamente.'}
                              {revision.rejectedSignature && 'Uno de los firmantes ha rechazado firmar el documento.'}
                              {revision.reviewUpload &&
                                <>
                                  Se ha generado un documento por el sistema con características adicionales.
                                  &nbsp;
                                  <a
                                    href={revision.reviewUpload.fileUrl}
                                    className="text-gray-600 ml-3 whitespace-nowrap"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                      Ver versión
                                    <Icon regular name="external-link" className="ml-1" />
                                  </a>
                                </>
                              }
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500 italic">{datetime(revision.createTime, 'DATETIME')}</p>
                          </div>
                          {revision.comment &&
                            <div className="mt-2 text-sm text-gray-700">
                              {revision.comment}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        </div>

        <div className="hidden lg:block lg:col-start-3 lg:col-span-1">
          <div className="bg-white py-6 shadow sm:rounded-lg sticky top-6">
            <h2 id="timeline-title" className="pl-6 text-lg font-medium text-gray-900">Previsualización</h2>
            <PdfPreview url={doc.fileUrl} />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
