
import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { useHotkeys } from 'react-hotkeys-hook'
import { Transition } from '@headlessui/react'

import { useScrollPosition } from 'platform/dom'
import Icon from 'components/Icon'


export default function SideSheetHeader({ children, open, onClose, header, wide, extraWide, subheader, fit, footer }) {
  let body = useRef(null)
  let [styles, setStyles] = useState('')

  useHotkeys('esc', () => {
    if (open) {
      onClose()
    }
  }, {}, [open, onClose])

  useScrollPosition(({ position }) => {
    if (position.top) {
      if (!styles) {
        setStyles('shadow')
      }
    } else {
      if (styles) {
        setStyles('')
      }
    }
  }, body)

  return (
    <>
      <Transition
        show={open}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 overflow-hidden z-20"
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => onClose()}></div>

          <section
            className={cx(
              'absolute inset-y-0 right-0 pl-10 max-w-full flex',
              styles,
            )}
          >
            <Transition
              show={open}
              enter="delay-500 transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              className={cx(
                'w-screen',
                {
                  'max-w-md': !wide,
                  'max-w-2xl': wide,
                  'max-w-5xl': extraWide,
                },
              )}
            >
              <div className="overflow-auto h-full flex flex-col bg-white shadow-xl divide-y divide-gray-200">
                <div className="py-6 px-4 bg-teal-700 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      {header}
                    </h2>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="bg-teal-700 rounded-md text-teal-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white text-xl"
                        onClick={() => onClose()}
                      >
                        <Icon light name="times" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-teal-300">
                      {subheader}
                    </p>
                  </div>
                </div>
                <div
                  className={cx(
                    'py-6 relative flex-1 ',
                    {
                      'px-4 sm:px-6': !fit,
                    },
                  )}
                >
                  {children}
                </div>
                <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                  {footer}
                </div>
              </div>
            </Transition>
          </section>
        </div>
      </Transition>
    </>
  )
}
