
import React from 'react'
import cx from 'classnames'


export default function Checkbox({ className, help, name, label, value, onChange, disabled }) {
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="p-2 flex"
      >
        <input
          disabled={disabled}
          id={name}
          name={name}
          type="checkbox"
          className={cx(
            'form-checkbox focus:ring-teal-400 h-4 w-4 text-teal-600 border-gray-300 rounded',
            {
              'bg-gray-100 cursor-not-allowed text-gray-400': disabled,
            },
          )}
          checked={value}
          onChange={e => {
            if (onChange) {
              onChange(e.target.checked)
            }
          }}
        />
        <div className="ml-3 text-sm">
          <div className="font-medium text-gray-700 -mt-0.5">
            {label}
          </div>
          {help && <p className="mt-0.5 text-gray-500 font-normal">{help}</p>}
        </div>
      </label>
    </div>
  )
}
