
import React from 'react'
import cx from 'classnames'


const Context = React.createContext()


export default function RadioGroupCards({ label, children, className, onChange, value, horizontal }) {
  return (
    <div>
      <Context.Provider value={{ value, onChange }}>
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        <ul
          className={cx(
            'mt-2',
            className,
            {
              'space-y-4': !horizontal,
              'grid grid-cols-2 gap-4': horizontal,
            },
          )}
        >{children}</ul>
      </Context.Provider>
    </div>
  )
}


RadioGroupCards.Radio = function({ value, children, description, icon }) {
  return (
    <Context.Consumer>
      {({ value: currentValue, onChange }) => (
        <li
          tabIndex="0"
          className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 flex"
          onClick={() => onChange(value)}
        >
          <div className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between flex-grow">
            <div className="flex items-center">
              {icon && <div className="flex-shrink-0">{icon}</div>}
              <div className="text-sm">
                <p className="font-medium text-gray-900">{children}</p>
                <div className="text-gray-500">{description}&nbsp;</div>
              </div>
            </div>
            <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
              {/* Space for right aligned items in the future */}
            </div>
          </div>
          <div
            className={cx(
              'absolute inset-0 rounded-lg border-2 pointer-events-none',
              {
                'border-indigo-500': currentValue === value,
                'border-transparent': currentValue !== value,
              },
            )}
          ></div>
        </li>
      )}
    </Context.Consumer>
  )
}
