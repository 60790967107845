
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { keyBy, uniq } from 'lodash-es'
import cx from 'classnames'

import { webinterna } from 'services/discovery'
import ProyectosClient from 'services/WebInterna/proyectos/proyectos'
import SociedadesClient from 'services/WebInterna/sociedades/sociedades'
import { useNavigate } from 'hooks/navigate'
import ButtonLinkPrimary from 'components/buttons/ButtonLinkPrimary'
import { PageHeader, Breadcrumb } from 'layout/webinterna/PageHeader'
import ShowPane from 'components/ShowPane'
import Card from 'components/Card'
import EntityLink from 'components/EntityLink'
import EntityBlockedAlert from 'components/EntityBlockedAlert'
import Icon from 'components/Icon'
import Acl from 'components/Acl'
import PageLayout from 'layout/webinterna/PageLayout'
import BadgeGreen from 'components/badges/BadgeGreen'
import { parseDate } from 'platform/datetime'
import { datetime, decimal, percentage } from 'platform/filters'
import CardHeader from 'components/CardHeader'
import { WideList, WideListEmpty, WideListItem } from 'components/WideList'
import BadgeGray from 'components/badges/BadgeGray'
import resolver from 'platform/resolver'
import FincasRegistralesClient from 'services/WebInterna/fincas_registrales/fincas_registrales'
import InmueblesClient from 'services/WebInterna/inmuebles/inmuebles'
import ElementosPatrimonialesClient from 'services/WebInterna/elementos_patrimoniales/elementos_patrimoniales'
import CardTable from 'components/CardTable'
import { Table, Cell, HeaderCell, TableRow } from 'components/Table'
import ButtonLinkSecondary from 'components/buttons/ButtonLinkSecondary'
import Presupuesto, { printPercentage, printMoney, printInteger, printArea, printRatio } from '@azpool/presupuesto'
import { SharePointIcon } from 'components/SharePointIcon'
import PresupuestosClient from 'services/WebInterna/presupuestos/presupuestos'
import ButtonSecondary from 'components/buttons/ButtonSecondary'
import { firstBy } from 'thenby'


const proyectosClient = new ProyectosClient(webinterna())
const sociedadesClient = new SociedadesClient(webinterna())
const inmueblesClient = new InmueblesClient(webinterna())
const fincasRegistralesClient = new FincasRegistralesClient(webinterna())
const elementosPatrimonialesClient = new ElementosPatrimonialesClient(webinterna())
const presupuestosClient = new PresupuestosClient(webinterna())

const USOS = {
  VIVIENDA_VENTA: 'Vivienda Venta',
  GARAJES_VENTA: 'Garajes Venta',
  TRASTEROS_VENTA: 'Trasteros Venta',
  VIVIENDAS_ARRENDAMIENTO: 'Viviendas Arrendamiento',
  GARAJES_ARRENDAMIENTO: 'Garajes Arrendamiento',
  TRASTEROS_ARRENDAMIENTO: 'Trasteros Arrendamiento',
  COMERCIAL: 'Comercial',
  GARAJES_COMERCIAL: 'Garajes Comercial',
  INDUSTRIAL: 'Industrial',
  LOGISTICO: 'Logístico',
  HOTELERO: 'Hotelero',
  OFICINAS: 'Oficinas',
  INSTALACIONES_AGRÍCOLAS: 'Instalaciones Agrícolas',
  OTROS_USOS: 'Otros usos',
}


function parsePlanTemporal(planTemporal) {
  if (!planTemporal) {
    return null
  }
  planTemporal.inicioDate = parseDate(planTemporal.inicioDate)
  planTemporal.finDate = parseDate(planTemporal.finDate)

  return planTemporal
}

function parsePlanTemporalPresupuesto(planTemporal) {
  if (!planTemporal) {
    return null
  }
  planTemporal.inicioFecha = parseDate(planTemporal.inicioFecha)
  planTemporal.finFecha = parseDate(planTemporal.finFecha)

  return planTemporal
}

function loadPresupuesto(presupuesto) {
  if (presupuesto) {
    if (typeof presupuesto.valores === 'object') {
      return presupuesto
    }
    presupuesto.valores = new Presupuesto(JSON.parse(presupuesto.valores))
    presupuesto.planesTemporales.planeamiento = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.planeamiento)
    presupuesto.planesTemporales.urbanizacion = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.urbanizacion)
    presupuesto.planesTemporales.proyectoBasico = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.proyectoBasico)
    presupuesto.planesTemporales.licencia = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.licencia)
    presupuesto.planesTemporales.proyectoEjecucion = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.proyectoEjecucion)
    presupuesto.planesTemporales.licitacion = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.licitacion)
    presupuesto.planesTemporales.obras = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.obras)
    presupuesto.planesTemporales.legalizacionEntrega = parsePlanTemporalPresupuesto(presupuesto.planesTemporales.legalizacionEntrega)
    return presupuesto
  }
  return null
}

export default function ProyectosShow() {
  let { name } = useParams()
  let [proyecto, setProyecto] = useState({})
  let [presupuestos, setPresupuestos] = useState([])
  let [presupuesto, setPresupuesto] = useState(null)

  useNavigate(async () => {
    let proyecto = await proyectosClient.Get({ name })

    proyecto.garantia1yDate = parseDate(proyecto.garantia1yDate)
    proyecto.garantia3yDate = parseDate(proyecto.garantia3yDate)
    proyecto.garantia10yDate = parseDate(proyecto.garantia10yDate)
    proyecto.cfoDate = parseDate(proyecto.cfoDate)

    if (proyecto.planeamiento) {
      proyecto.planeamiento.planTemporal = parsePlanTemporal(proyecto.planeamiento.planTemporal)
    }
    if (proyecto.urbanizacion) {
      proyecto.urbanizacion.planTemporal = parsePlanTemporal(proyecto.urbanizacion.planTemporal)
    }
    if (proyecto.proyectoBasico) {
      proyecto.proyectoBasico.planTemporal = parsePlanTemporal(proyecto.proyectoBasico.planTemporal)
    }
    if (proyecto.licencia) {
      proyecto.licencia.planTemporal = parsePlanTemporal(proyecto.licencia.planTemporal)
    }
    if (proyecto.proyectoEdificacion) {
      proyecto.proyectoEdificacion.planTemporal = parsePlanTemporal(proyecto.proyectoEdificacion.planTemporal)
    }
    if (proyecto.legalizacionEntrega) {
      proyecto.legalizacionEntrega.planTemporal = parsePlanTemporal(proyecto.legalizacionEntrega.planTemporal)
    }
    if (proyecto.licitacion) {
      proyecto.licitacion.planTemporal = parsePlanTemporal(proyecto.licitacion.planTemporal)
    }
    if (proyecto.obras) {
      proyecto.obras.planTemporal = parsePlanTemporal(proyecto.obras.planTemporal)
    }
    if (proyecto.promotor) {
      proyecto.promotor = await sociedadesClient.Get({ name: proyecto.promotor })
    }

    let inmueblesNames = []
    let fincasRegistralesNames = []
    let elementosPatrimonialesNames = []

    if (proyecto.origen) {
      proyecto.origen.forEach(uin => {
        inmueblesNames.push(uin.inmueble)
        if (uin.fincasRegistrales) {
          fincasRegistralesNames.push(...uin.fincasRegistrales.map(fr => fr.fincaRegistral))
        }
        if (uin.elementosPatrimoniales) {
          elementosPatrimonialesNames.push(...uin.elementosPatrimoniales)
        }
      })
    }
    if (proyecto.resultado) {
      proyecto.resultado.forEach(uin => {
        inmueblesNames.push(uin.inmueble)
        if (uin.fincasRegistrales) {
          fincasRegistralesNames.push(...uin.fincasRegistrales.map(fr => fr.fincaRegistral))
        }
        if (uin.elementosPatrimoniales) {
          elementosPatrimonialesNames.push(...uin.elementosPatrimoniales)
        }
      })
    }

    let { inmuebles, fincasRegistrales, elementosPatrimoniales } = await resolver.all({
      inmuebles: inmueblesClient.BatchGet({ names: uniq(inmueblesNames) }).then(reply => reply.inmuebles),
      fincasRegistrales: fincasRegistralesClient.BatchGet({ names: uniq(fincasRegistralesNames) }).then(reply => reply.fincas),
      elementosPatrimoniales: elementosPatrimonialesClient.BatchGet({ names: uniq(elementosPatrimonialesNames) }).then(reply => reply.elementosPatrimoniales),
    })
    inmuebles = keyBy(inmuebles, 'name')
    fincasRegistrales = keyBy(fincasRegistrales, 'name')
    elementosPatrimoniales = keyBy(elementosPatrimoniales, 'name')

    if (proyecto.origen) {
      proyecto.origen.forEach(uin => {
        uin.inmueble = inmuebles[uin.inmueble]
        uin.fincasRegistrales = uin.fincasRegistrales?.map(fr => {
          return {
            ...fr,
            fincaRegistral: fincasRegistrales[fr.fincaRegistral],
          }
        })
        uin.elementosPatrimoniales = uin.elementosPatrimoniales?.map(ep => elementosPatrimoniales[ep])
      })
    }
    if (proyecto.resultado) {
      proyecto.resultado.forEach(uin => {
        uin.inmueble = inmuebles[uin.inmueble]
        uin.fincasRegistrales = uin.fincasRegistrales?.map(fr => {
          return {
            ...fr,
            fincaRegistral: fincasRegistrales[fr.fincaRegistral],
          }
        })
        uin.elementosPatrimoniales = uin.elementosPatrimoniales?.map(ep => elementosPatrimoniales[ep])
      })
    }

    setProyecto(proyecto)

    let presupuestos = await presupuestosClient.List()
    presupuestos = presupuestos.presupuestos || []
    presupuestos = presupuestos
      .filter(presupuesto => presupuesto.proyecto === name)
      .map(presupuesto => {
        return {
          ...presupuesto,
          elaboracionDate: parseDate(presupuesto.elaboracionDate),
          aprobacionDate: parseDate(presupuesto.aprobacionDate),
        }
      })
      .sort(firstBy('vigente').thenBy('aprobacionDate').thenBy('elaboracionDate'))
    setPresupuestos(presupuestos)
    let presupuesto = presupuestos.filter(presupuesto => presupuesto.vigente)[0]
    setPresupuesto(loadPresupuesto(presupuesto))
  })

  return (
    <PageLayout>
      <PageHeader
        buttons={<>
          {!proyecto.blocked &&
            <Acl perm="webinterna.personas.propose-edit">
              <ButtonLinkPrimary to={`/webinterna/proyectos/edit/${name}`}>
                <Icon solid name="pencil" className="mr-2" />
                Editar Proyecto
              </ButtonLinkPrimary>
            </Acl>
          }
        </>}
      >
        <Breadcrumb url="/webinterna">WebInterna</Breadcrumb>
        <Breadcrumb url="/webinterna/proyectos">Proyectos</Breadcrumb>
        <Breadcrumb url={`/webinterna/proyectos/${name}`}>Consultar Proyecto</Breadcrumb>
      </PageHeader>

      <EntityBlockedAlert blocked={proyecto.blocked}></EntityBlockedAlert>

      <Card className="mb-4">
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          <ShowPane title="Código">{proyecto.codigo}</ShowPane>
          <ShowPane title="Nombre Proyecto">{proyecto.denominacion}</ShowPane>
          <ShowPane title="Tipo">
            {proyecto.tipo === 'TIPO_A' && 'Tipo A'}
            {proyecto.tipo === 'TIPO_B' && 'Tipo B'}
            {proyecto.tipo === 'TIPO_C' && 'Tipo C'}
            {proyecto.tipo === 'TIPO_D' && 'Tipo D'}
          </ShowPane>
          <ShowPane title="Estado">
            {proyecto.estado === 'ESTADO_EN_CURSO' && <BadgeGreen>En curso</BadgeGreen>}
            {proyecto.estado === 'ESTADO_FINALIZADO' && <BadgeGray>Finalizado</BadgeGray>}
          </ShowPane>
          <ShowPane title="Promotor" className="col-span-2">
            {proyecto.promotor &&
              <EntityLink to={`/webinterna/sociedades/${proyecto.promotor.name}`}>{proyecto.promotor.denominacion}</EntityLink>
            }
          </ShowPane>
          <ShowPane title="Ordenanza general">{proyecto.ordenanzaGeneral}</ShowPane>
          <ShowPane title="Ámbito desarrollo">{proyecto.ambitoDesarrollo}</ShowPane>
          <ShowPane title="Tipo contable">
            {proyecto.tipoContable === 'TIPO_CONTABLE_EXISTENCIAS_EN_CURSO' && 'Existencias en curso'}
            {proyecto.tipoContable === 'TIPO_CONTABLE_INMOBILIZADO_EN_CURSO' && 'Inmobilizado en curso'}
            {proyecto.tipoContable === 'TIPO_CONTABLE_EXISTENCIAS_FINALIZADO' && 'Existencias finalizado'}
            {proyecto.tipoContable === 'TIPO_CONTABLE_INMOBILIZADO_FINALIZADO' && 'Inmobilizado finalizado'}
          </ShowPane>
          <ShowPane title="Cuenta contable">{proyecto.cuentaContable}</ShowPane>
          <ShowPane title={`Periodo garantía\n1 año`} className="whitespace-pre-line">
            {datetime(proyecto.garantia1yDate, 'DATE')}
          </ShowPane>
          <ShowPane title={`Periodo garantía\n3 años`} className="whitespace-pre-line">
            {datetime(proyecto.garantia3yDate, 'DATE')}
          </ShowPane>
          <ShowPane title={`Periodo garantía\n10 años`} className="whitespace-pre-line">
            {datetime(proyecto.garantia10yDate, 'DATE')}
          </ShowPane>
          <ShowPane title="Fecha CFO">
            {datetime(proyecto.cfoDate, 'DATE')}
          </ShowPane>
        </div>
      </Card>

      <CardTable
        title="Etapas"
        header={
          <>
            <HeaderCell>Nombre</HeaderCell>
            <HeaderCell>Estado</HeaderCell>
            <HeaderCell>Fecha inicio</HeaderCell>
            <HeaderCell>Fecha fin</HeaderCell>
            <HeaderCell className="text-right">% progreso</HeaderCell>
          </>
        }
      >
        <TableRow>
          <Cell>Planeamiento</Cell>
          <Cell>
            {proyecto.planeamiento?.gestion === 'GESTION_REDACCION' && 'Redacción'}
            {proyecto.planeamiento?.gestion === 'GESTION_APROBACION_INICIAL' && 'Aprobación inicial'}
            {proyecto.planeamiento?.gestion === 'GESTION_APROBACION_DEFINITIVA' && 'Aprobación definitiva'}
          </Cell>
          <Cell>{datetime(proyecto.planeamiento?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.planeamiento?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.planeamiento?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Urbanización</Cell>
          <Cell>
            {proyecto.urbanizacion?.gestion === 'GESTION_PLAN_PARCIAL' && 'Plan parcial'}
            {proyecto.urbanizacion?.gestion === 'GESTION_INNOVACION_PLANEAMIENTO' && 'Planeamiento innovación'}
            {proyecto.urbanizacion?.gestion === 'GESTION_PERI' && 'Peri'}
            {proyecto.urbanizacion?.gestion === 'GESTION_PROYECTO_URBANIZACION' && 'Proyecto urbanización'}
            {proyecto.urbanizacion?.gestion === 'GESTION_PROYECTO_REPARCELIZACION' && 'Proyecto reparcelización'}
            {proyecto.urbanizacion?.gestion === 'GESTION_ESTUDIO_DETALLE' && 'Estudio detalle'}
          </Cell>
          <Cell>{datetime(proyecto.urbanizacion?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.urbanizacion?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.urbanizacion?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Proyecto básico</Cell>
          <Cell>
            {proyecto.proyectoBasico?.gestion === 'GESTION_NO_INICIADA' && 'No iniciado'}
            {proyecto.proyectoBasico?.gestion === 'GESTION_EN_CURSO' && 'En curso'}
            {proyecto.proyectoBasico?.gestion === 'GESTION_FINALIZADO' && 'Finalizado'}
          </Cell>
          <Cell>{datetime(proyecto.proyectoBasico?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.proyectoBasico?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.proyectoBasico?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Licencia</Cell>
          <Cell>
            {proyecto.licencia?.gestion === 'GESTION_NO_PRESENTADO' && 'No presentada'}
            {proyecto.licencia?.gestion === 'GESTION_EN_TRAMITACION' && 'En tramitación'}
            {proyecto.licencia?.gestion === 'GESTION_CONCEDIDA' && 'Concedida'}
          </Cell>
          <Cell>{datetime(proyecto.licencia?.planTemporal.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.licencia?.planTemporal.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.licencia?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Proyecto Edificación</Cell>
          <Cell>
            {proyecto.proyectoEdificacion?.gestion === 'GESTION_NO_INICIADA' && 'No iniciada'}
            {proyecto.proyectoEdificacion?.gestion === 'GESTION_EN_CURSO' && 'En curso'}
            {proyecto.proyectoEdificacion?.gestion === 'GESTION_FINALIZADO' && 'Finalizado'}
          </Cell>
          <Cell>{datetime(proyecto.proyectoEdificacion?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.proyectoEdificacion?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.proyectoEdificacion?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Licitación</Cell>
          <Cell>
            {proyecto.licitacion?.gestion === 'GESTION_NO_INICIADA' && 'No iniciada'}
            {proyecto.licitacion?.gestion === 'GESTION_EN_CURSO' && 'En curso'}
            {proyecto.licitacion?.gestion === 'GESTION_FINALIZADO' && 'Finalizado'}
          </Cell>
          <Cell>{datetime(proyecto.licitacion?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.licitacion?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.licitacion?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Obras</Cell>
          <Cell>
            {proyecto.obras?.gestion === 'GESTION_NO_INICIADA' && 'No iniciada'}
            {proyecto.obras?.gestion === 'GESTION_EN_CURSO' && 'En curso'}
            {proyecto.obras?.gestion === 'GESTION_TERMINADA_EN_GARANTIA' && 'Terminada en garantía'}
            {proyecto.obras?.gestion === 'GESTION_CERRADA' && 'Cerrada'}
          </Cell>
          <Cell>{datetime(proyecto.obras?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.obras?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.obras?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
        <TableRow>
          <Cell>Legalización entrega</Cell>
          <Cell>
            {proyecto.legalizacionEntrega?.gestion === 'GESTION_NO_INICIADA' && 'No iniciada'}
            {proyecto.legalizacionEntrega?.gestion === 'GESTION_EN_CURSO' && 'En curso'}
            {proyecto.legalizacionEntrega?.gestion === 'GESTION_FINALIZADO' && 'Finalizado'}
          </Cell>
          <Cell>{datetime(proyecto.legalizacionEntrega?.planTemporal?.inicioDate, 'DATE')}</Cell>
          <Cell>{datetime(proyecto.legalizacionEntrega?.planTemporal?.finDate, 'DATE')}</Cell>
          <Cell className="text-right">{percentage(proyecto.legalizacionEntrega?.planTemporal?.progresoPercentage)}</Cell>
        </TableRow>
      </CardTable>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
        <div>
          <CardHeader fit header="UINs origen" className="mt-4">
            <WideList divider="divide-y-4 divide-teal-400">
              {proyecto.origen?.length ?
                proyecto.origen.map((uin, index) => (
                  <WideListItem key={index}>
                    <EntityLink to={'/webinterna/inmuebles/' + uin.inmueble.name}>
                      <span className="text-base leading-6 font-medium mb-6">
                        {uin.inmueble.nombre}
                      </span>
                    </EntityLink>
                    <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {uin.fincasRegistrales?.map((fincaRegistral, index) => (
                        <React.Fragment key={fincaRegistral.fincaRegistral.name}>
                          <ShowPane title={index === 0 ? 'Fincas registrales' : ''}>
                            <EntityLink to={'/webinterna/fincas-registrales/' + fincaRegistral.fincaRegistral.name}>
                              {fincaRegistral.fincaRegistral.numeroFinca}
                            </EntityLink>
                          </ShowPane>
                          <ShowPane title="% coste">
                            {percentage(fincaRegistral.costePorcentaje)}
                          </ShowPane>
                        </React.Fragment>
                      ))}
                    </div>
                    {uin.elementosPatrimoniales?.map((elementoPatrimonial, index) => (
                      <ShowPane className="mt-4" title={index === 0 ? 'El. patrimoniales' : ''}>
                        <EntityLink to={'/webinterna/elementos-patrimoniales/' + elementoPatrimonial.name}>
                          {elementoPatrimonial.nombre}
                        </EntityLink>
                      </ShowPane>
                    ))}
                  </WideListItem>
                ))
                :
                <WideListEmpty>No hay UINs</WideListEmpty>
              }
            </WideList>
          </CardHeader>
        </div>
        <div>
          <CardHeader fit header="UINs destino" className="mt-4">
            <WideList divider="divide-y-4 divide-teal-400">
              {proyecto.resultado?.length ?
                proyecto.resultado.map((uin, index) => (
                  <WideListItem key={index}>
                    <EntityLink to={'/webinterna/inmuebles/' + uin.inmueble.name}>
                      <span className="text-base leading-6 font-medium mb-6">
                        {uin.inmueble.nombre}
                      </span>
                    </EntityLink>
                    <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {uin.fincasRegistrales?.map((fincaRegistral, index) => (
                        <React.Fragment key={fincaRegistral.fincaRegistral.name}>
                          <ShowPane title={index === 0 ? 'Fincas registrales' : ''}>
                            <EntityLink to={'/webinterna/fincas-registrales/' + fincaRegistral.fincaRegistral.name}>
                              {fincaRegistral.fincaRegistral.numeroFinca}
                            </EntityLink>
                          </ShowPane>
                          <ShowPane title="% coste">
                            {percentage(fincaRegistral.costePorcentaje)}
                          </ShowPane>
                        </React.Fragment>
                      ))}
                    </div>
                    {uin.elementosPatrimoniales?.map((elementoPatrimonial, index) => (
                      <ShowPane className="mt-4" title={index === 0 ? 'El. patrimoniales' : ''} key={elementoPatrimonial.name}>
                        <EntityLink to={'/webinterna/elementos-patrimoniales' + elementoPatrimonial.name}>
                          {elementoPatrimonial.nombre}
                        </EntityLink>
                      </ShowPane>
                    ))}
                  </WideListItem>
                ))
                :
                <WideListEmpty>No hay UINs</WideListEmpty>
              }
            </WideList>
          </CardHeader>
        </div>
      </div>

      <CardHeader
        header={presupuesto ? `Presupuesto ${presupuesto.codigo}` : 'Presupuesto'}
        className="mt-4"
        actions={!proyecto.blocked &&
          <Acl perm="webinterna.validaciones.edit">
            <div className="flex gap-x-4">
              {presupuestos.indexOf(presupuesto) > 0 &&
                <ButtonSecondary onClick={() => setPresupuesto(loadPresupuesto(presupuestos[presupuestos.indexOf(presupuesto) - 1]))}>
                  <Icon solid name="chevron-left" className="mr-2" />
                  Ver presupuesto anterior
                </ButtonSecondary>
              }
              {presupuesto && presupuesto.vigente && 
                <Acl perm="webinterna.proyectos.propose-edit">
                  <ButtonLinkSecondary to={`/webinterna/proyectos/presupuestos/edit/${presupuesto.name}`}>
                    <Icon solid name="pencil" className="mr-2" />
                    Editar presupuesto
                  </ButtonLinkSecondary>
                </Acl>
              }
              <Acl perm="webinterna.proyectos.propose-edit">
                <ButtonLinkPrimary to={`/webinterna/proyectos/presupuestos/new/${name}`}>
                  <Icon solid name="plus" className="mr-2" />
                  Crear presupuesto
                </ButtonLinkPrimary>
              </Acl>
              {presupuestos.indexOf(presupuesto) < presupuestos.length - 1 &&
                <ButtonSecondary onClick={() => setPresupuesto(loadPresupuesto(presupuestos[presupuestos.indexOf(presupuesto) + 1]))}>
                  <Icon solid name="chevron-right" className="mr-2" />
                  Ver presupuesto siguiente
                </ButtonSecondary>
              }
            </div>
          </Acl>
        }
      >
        {presupuesto ?
          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <ShowPane title="Uso Principal">
              {presupuesto.usoPrincipal === 'USO_VIVIENDA_VENTA' && 'Vivienda Venta'}
              {presupuesto.usoPrincipal === 'USO_GARAJES_VENTA' && 'Garajes Venta'}
              {presupuesto.usoPrincipal === 'USO_TRASTEROS_VENTA' && 'Trasteros Venta'}
              {presupuesto.usoPrincipal === 'USO_VIVIENDAS_ARRENDAMIENTO' && 'Viviendas Arrendamiento'}
              {presupuesto.usoPrincipal === 'USO_GARAJES_ARRENDAMIENTO' && 'Garajes Arrendamiento'}
              {presupuesto.usoPrincipal === 'USO_TRASTEROS_ARRENDAMIENTO' && 'Trasteros Arrendamiento'}
              {presupuesto.usoPrincipal === 'USO_COMERCIAL' && 'Comercial'}
              {presupuesto.usoPrincipal === 'USO_RESIDENCIAL_COMERCIAL' && 'Residencial y Comercial'}
              {presupuesto.usoPrincipal === 'USO_INDUSTRIAL' && 'Industrial'}
              {presupuesto.usoPrincipal === 'USO_LOGISTICO' && 'Logístico'}
              {presupuesto.usoPrincipal === 'USO_HOTELERO' && 'Hotelero'}
              {presupuesto.usoPrincipal === 'USO_OFICINAS' && 'Oficinas'}
              {presupuesto.usoPrincipal === 'USO_INSTALACIONES_AGRICOLAS' && 'Instalaciones agrícolas'}
              {presupuesto.usoPrincipal === 'OTROS_USOS' && 'Otros usos'}
            </ShowPane>
            <ShowPane title="Fecha elaboración">{datetime(presupuesto.elaboracionDate, 'DATE')}</ShowPane>
            <ShowPane title="Fecha aprobación">{datetime(presupuesto.aprobacionDate, 'DATE')}</ShowPane>
            <ShowPane title="Sharepoint">
              {presupuesto.adjuntoURL && <a href={presupuesto.adjuntoURL}>
                <SharePointIcon/>
              </a>}
            </ShowPane>
            <ShowPane title="Comentarios">{presupuesto.comentarios}</ShowPane>
          </div>
          :
          <div className="w-full h-full text-center text-lg italic text-gray-400 py-20">No hay presupuesto</div>
        }
      </CardHeader>

      {presupuesto?.valores &&
        <>
          <CardTable
            title="Resumen y ratios"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold"></HeaderCell>
                <HeaderCell className="font-bold text-right">Total</HeaderCell>
                <HeaderCell className="font-bold text-right">€/m² Total</HeaderCell>
                <HeaderCell className="font-bold text-right">Viv. Libre</HeaderCell>
                <HeaderCell className="font-bold text-right">VPP</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.resumen.vppOverviews.map((vppOverview, index) => {
              let isPercentage = index === 8 || index === 10 || index === 13
              let className = !vppOverview.subOverview && 'font-bold'

              return (
                <TableRow key={vppOverview.nombre}>
                  <Cell
                    verticalFit
                    className={cx(
                      { 'italic': vppOverview.subOverview },
                      { 'font-bold': !vppOverview.subOverview },
                    )}
                  >
                    {vppOverview.nombre}
                  </Cell>
                  <Cell
                    verticalFit
                    className={cx(
                      { 'text-red-500': vppOverview.total?.isNegative() },
                      'text-right',
                      className,
                    )}
                  >
                    {!isPercentage && printMoney(vppOverview.total)}
                    {isPercentage && printPercentage(vppOverview.total)}
                  </Cell>
                  <Cell
                    verticalFit
                    className={cx(
                      { 'text-red-500': vppOverview.totalMetros?.isNegative() },
                      'text-right',
                      className,
                    )}
                  >
                    {!isPercentage && printRatio(vppOverview.totalMetros)}
                    {isPercentage && printPercentage(vppOverview.totalMetros)}
                  </Cell>
                  <Cell
                    verticalFit
                    className={cx(
                      { 'text-red-500': vppOverview.viviendaLibre?.isNegative() },
                      'text-right',
                      className,
                    )}
                  >
                    {!isPercentage && printRatio(vppOverview.viviendaLibre)}
                    {isPercentage && printPercentage(vppOverview.viviendaLibre)}
                  </Cell>
                  <Cell
                    verticalFit
                    className={cx(
                      { 'text-red-500': vppOverview.vpp?.isNegative() },
                      'text-right',
                      className,
                    )}
                  >
                    {!isPercentage && printRatio(vppOverview.vpp)}
                    {isPercentage && printPercentage(vppOverview.vpp)}
                  </Cell>
                </TableRow>
              )
            })}
          </CardTable>

          <div className="space-x-4 mt-4 flex">
            <div className="w-1/2 lg:w-2/3">
              <CardTable
                title="Resumen Comercial"
                header={
                  <>
                    <HeaderCell className="font-bold"></HeaderCell>
                    <HeaderCell className="font-bold text-right">Total</HeaderCell>
                    <HeaderCell className="font-bold text-right">€/m² Total</HeaderCell>
                    <HeaderCell className="font-bold text-right">Viv. Libre</HeaderCell>
                    <HeaderCell className="font-bold text-right">Comercial</HeaderCell>
                  </>
                }
              >
                {presupuesto.valores.resumen.comercialOverviews.map((comercialOverview, index) => (
                  <TableRow key={comercialOverview.nombre}>
                    <Cell verticalFit className="font-bold">{comercialOverview.nombre}</Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.total?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.total)}
                      {index > 0 && printPercentage(comercialOverview.total)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.totalMetros?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.totalMetros)}
                      {index > 0 && printPercentage(comercialOverview.totalMetros)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.viviendaLibre?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.viviendaLibre)}
                      {index > 0 && printPercentage(comercialOverview.viviendaLibre)}
                    </Cell>
                    <Cell verticalFit className={cx('text-right', { 'text-red-500': comercialOverview.comercial?.isNegative() })}>
                      {index === 0 && printRatio(comercialOverview.comercial)}
                      {index > 0 && printPercentage(comercialOverview.comercial)}
                    </Cell>
                  </TableRow>
                ))}
              </CardTable>
            </div>
            <div className="w-1/2 lg:w-1/3 h-full">
              <CardTable
                title="Datos Bancarios"
                className="h-full"
                header={
                  <>
                    <HeaderCell></HeaderCell>
                    <HeaderCell></HeaderCell>
                  </>
                }
              >
                <TableRow>
                  <Cell verticalFit>LTV bancario objetivo</Cell>
                  <Cell verticalFit className="text-right">
                    {printPercentage(presupuesto.valores.resumen.ltvBancario)}
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell verticalFit>LTC bancario objetivo</Cell>
                  <Cell verticalFit className="text-right">
                    {printPercentage(presupuesto.valores.resumen.ltcBancario)}
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell verticalFit>Financiación objetivo</Cell>
                  <Cell verticalFit className="text-right">{printMoney(presupuesto.valores.resumen.financiacionObjetivo)}</Cell>
                </TableRow>
              </CardTable>
            </div>
          </div>


          <CardTable
            title="Plan temporal"
            className="mt-4"
            header={
              <>
                <HeaderCell></HeaderCell>
                <HeaderCell className="text-right">F_inicio</HeaderCell>
                <HeaderCell className="text-right">F_fin</HeaderCell>
                <HeaderCell className="text-right">Duración</HeaderCell>
              </>
            }
          >
            {[presupuesto.planesTemporales.planeamiento, presupuesto.planesTemporales.urbanizacion, presupuesto.planesTemporales.proyectoBasico,
              presupuesto.planesTemporales.licencia, presupuesto.planesTemporales.proyectoEjecucion, presupuesto.planesTemporales.licitacion,
              presupuesto.planesTemporales.obras, presupuesto.planesTemporales.legalizacionEntrega].map((planTemporal, index) => (
              <TableRow key={index}>
                <Cell verticalFit>
                  {index === 0 && 'Planeamiento'}
                  {index === 1 && 'Urbanización'}
                  {index === 2 && 'Proyecto básico'}
                  {index === 3 && 'Licencia'}
                  {index === 4 && 'Proyecto ejecución'}
                  {index === 5 && 'Licitación'}
                  {index === 6 && 'Obras'}
                  {index === 7 && 'Legalización y entrega'}
                </Cell>
                <Cell className="text-right" verticalFit>{planTemporal?.inicioFecha && datetime(planTemporal?.inicioFecha, 'DATE')}</Cell>
                <Cell className="text-right" verticalFit>{planTemporal?.finFecha && datetime(planTemporal?.finFecha, 'DATE')}</Cell>
                <Cell className="text-right" verticalFit>{planTemporal?.inicioFecha && planTemporal?.finFecha && decimal((planTemporal.finFecha.getTime() - planTemporal.inicioFecha.getTime()) / 25920000)} meses</Cell>
              </TableRow>
            ))}

          </CardTable>

          <CardHeader
            className="mt-4"
            header="Superficies"
            fit
          >
            <div className="my-6 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
              <ShowPane title="Suelo m²">{printArea(presupuesto.valores.superficies.suelo)}</ShowPane>
              <ShowPane title="Edificabilidad máx. m²">{printArea(presupuesto.valores.superficies.edificabilidadMax)}</ShowPane>
            </div>

            <Table
              header={
                <>
                  <HeaderCell className="font-bold text-right">Sup. Const. m² (con pp.zz.cc.)*</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. Const. m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">% Sup. SR</HeaderCell>
                  <HeaderCell className="font-bold text-right">% Sup. BR</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. SR m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">Sup. BR m²</HeaderCell>
                  <HeaderCell className="font-bold text-right">Nº uds.</HeaderCell>
                  <HeaderCell className="font-bold text-right">1D</HeaderCell>
                  <HeaderCell className="font-bold text-right">2D</HeaderCell>
                  <HeaderCell className="font-bold text-right">3D</HeaderCell>
                  <HeaderCell className="font-bold text-right">4D</HeaderCell>
                </>
              }
            >
              {presupuesto.valores.superficies.superficies.map(superficie => (
                <TableRow key={superficie.nombre ? superficie.nombre : superficie.uso}>
                  <Cell verticalFit>{superficie.nombre ? superficie.nombre : USOS[superficie.uso]}</Cell>
                  <Cell className="text-right" verticalFit>{printArea(superficie.superficieConstruida)}</Cell>
                  <Cell className="text-right" verticalFit>
                    {printPercentage(superficie.sr)}
                  </Cell>
                  <Cell className="text-right" verticalFit>
                    {printPercentage(superficie.br)}
                  </Cell>
                  <Cell className="text-right" verticalFit>{printArea(superficie.srSuperficie)}</Cell>
                  <Cell className="text-right" verticalFit>{printArea(superficie.brSuperficie)}</Cell>
                  <Cell className="text-right" verticalFit>{printInteger(superficie.unidades)}</Cell>
                  <Cell className="text-right" verticalFit>{printInteger(superficie.s1d)}</Cell>
                  <Cell className="text-right" verticalFit>{printInteger(superficie.s2d)}</Cell>
                  <Cell className="text-right" verticalFit>{printInteger(superficie.s3d)}</Cell>
                  <Cell className="text-right" verticalFit>{printInteger(superficie.s4d)}</Cell>
                </TableRow>
              ))}
              <TableRow>
                <Cell verticalFit className="font-bold">TOTAL</Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.superficieConstruida)}</Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.superficies.total.srSuperficie)}</Cell>
                <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.superficies.total.brSuperficie)}</Cell>
                <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.superficies.total.unidades)}</Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
                <Cell verticalFit></Cell>
              </TableRow>
            </Table>
          </CardHeader>

          <CardTable
            title="Costes ejecución"
            className="mt-4"
            header={
              <>
                <HeaderCell></HeaderCell>
                <HeaderCell className="font-bold text-right">Base</HeaderCell>
                <HeaderCell className="font-bold text-right">Ratio</HeaderCell>
                <HeaderCell className="font-bold text-right">Importe</HeaderCell>
              </>
            }
          >
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.suelo.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.suelo.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.costes.suelo.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.suelo.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.urbanizacion.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.urbanizacion.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.costes.urbanizacion.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.urbanizacion.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.edificacion.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printArea(presupuesto.valores.costes.edificacion.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.edificacion.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.edificacion.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.edificacion.costes.map((coste, index) => (
              <TableRow key={index}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell className="text-right" verticalFit>{printArea(coste.base)}</Cell>
                <Cell className="text-right" verticalFit>{printRatio(coste.ratio)}</Cell>
                <Cell className="text-right" verticalFit>{printMoney(coste.importe)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.mobiliarioEquipamiento.nombre}</Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.mobiliarioEquipamiento.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.mobiliarioEquipamiento.costes.map((coste, index) => (
              <TableRow key={index}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell className="text-right" verticalFit>{printInteger(coste.base)}uds</Cell>
                <Cell className="text-right" verticalFit>{printRatio(coste.ratio)}</Cell>
                <Cell className="text-right" verticalFit>{printMoney(coste.importe)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.fitOuts.nombre}</Cell>
              <Cell verticalFit className="font-bold"></Cell>
              <Cell verticalFit className="font-bold"></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.fitOuts.importe)}</Cell>
            </TableRow>
            {presupuesto.valores.costes.fitOuts.costes.map((coste, index) => (
              <TableRow key={index}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell className="text-right" verticalFit>{printArea(coste.base)}</Cell>
                <Cell className="text-right" verticalFit>{printRatio(coste.ratio)}</Cell>
                <Cell className="text-right" verticalFit>{printMoney(coste.importe)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.legales.nombre}</Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit></Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.legales.importe)}</Cell>
            </TableRow>
            {[presupuesto.valores.costes.legales.tasasCIO, presupuesto.valores.costes.legales.obraNueva,
              presupuesto.valores.costes.legales.divisionHorizontal, presupuesto.valores.costes.legales.otrosGastos].map(coste => (
              <TableRow key={coste.nombre}>
                <Cell verticalFit>{coste.nombre}</Cell>
                <Cell className="text-right" verticalFit>{printMoney(coste.base)}</Cell>
                <Cell className="text-right" verticalFit>{printPercentage(coste.ratio)}</Cell>
                <Cell className="text-right" verticalFit>{printMoney(coste.importe)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell></Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.proyectoSSTT.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.proyectoSSTT.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.costes.proyectoSSTT.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.proyectoSSTT.importe)}</Cell>
            </TableRow>
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.costes.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.costes.total.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.costes.total.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.costes.total.importe)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Ingresos Ventas"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold">VENTA</HeaderCell>
                <HeaderCell className="font-bold text-right">V_VENTA</HeaderCell>
                <HeaderCell className="font-bold text-right">% VENTAS</HeaderCell>
                <HeaderCell className="font-bold text-right">€/uds VENTAS</HeaderCell>
                <HeaderCell className="font-bold text-right">€/m² VENTA</HeaderCell>
                <HeaderCell className="font-bold text-right">COSTE EJECUCIÓN</HeaderCell>
                <HeaderCell className="font-bold text-right">MARGEN BRUTO EXPLOTACIÓN</HeaderCell>
                <HeaderCell className="font-bold text-right">% M.B.EXPL. s/COSTES EJECUCIÓN</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.ingresos.ventas.items.map((venta, index) => (
              <TableRow key={index}>
                <Cell verticalFit className="font-bold">
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell verticalFit className="font-bold text-right">{printRatio(venta.valorVenta)}</Cell>
                <Cell verticalFit className="text-right">{printPercentage(venta.ventasPercentage)}</Cell>
                <Cell verticalFit className="text-right">{printRatio(venta.ventasUnidades)}</Cell>
                <Cell verticalFit className="text-right">{printRatio(venta.ventasMetros)}</Cell>
                <Cell verticalFit className="text-red-500 text-right">({printMoney(venta.costeEjecucion)})</Cell>
                <Cell verticalFit className={cx('text-right', { 'text-red-500': venta.margenBrutoExplotacion?.isNegative() })}>{printMoney(venta.margenBrutoExplotacion)}</Cell>
                <Cell verticalFit className="text-right">{printPercentage(venta.mbece)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.ingresos.ventas.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.ventas.total.valorVenta)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.ventas.total.ventasPercentage)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.ventas.total.ventasUnidades)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.ventas.total.ventasMetros)}</Cell>
              <Cell verticalFit className="font-bold text-red-500 text-right">({printMoney(presupuesto.valores.ingresos.ventas.total.costeEjecucion)})</Cell>
              <Cell verticalFit className={cx('font-bold text-right', { 'text-red-500': presupuesto.valores.ingresos.ventas.total.margenBrutoExplotacion?.isNegative() })}>
                {printMoney(presupuesto.valores.ingresos.ventas.total.margenBrutoExplotacion)}
              </Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.ventas.total.mbce)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Ingresos Arrendamiento"
            className="mt-4"
            header={
              <>
                <HeaderCell>ARRENDAMIENTO</HeaderCell>
                <HeaderCell className="text-right">RENTA</HeaderCell>
                <HeaderCell className="text-right">DESOC%</HeaderCell>
                <HeaderCell className="text-right">Desoc. Imp.</HeaderCell>
                <HeaderCell className="text-right">G_EXPL</HeaderCell>
                <HeaderCell className="text-right">NOI</HeaderCell>
                <HeaderCell className="text-right">R.E.E. %</HeaderCell>
                <HeaderCell className="text-right">YIELD</HeaderCell>
              </>
            }
          >
            {presupuesto.valores.ingresos.arrendamientos.items.map((arrendamiento, index) => (
              <TableRow key={index}>
                <Cell verticalFit>
                  {presupuesto.valores.superficies.superficies[index].nombre ? 
                    presupuesto.valores.superficies.superficies[index].nombre 
                    : USOS[presupuesto.valores.superficies.superficies[index].uso]}
                </Cell>
                <Cell className="text-right" verticalFit>{printRatio(arrendamiento.renta)}</Cell>
                <Cell className="text-right" verticalFit>
                  {printPercentage(arrendamiento.desoc)}
                </Cell>
                <Cell verticalFit className="text-red-500 text-right">{arrendamiento.desocImporte && `(${printMoney(arrendamiento.desocImporte)})`}</Cell>
                <Cell verticalFit className="text-red-500 text-right">{printRatio(arrendamiento.gExpl)}</Cell>
                <Cell verticalFit className="text-right">{printRatio(arrendamiento.noi)}</Cell>
                <Cell verticalFit className="text-right">
                  {printPercentage(arrendamiento.ree)}
                </Cell>
                <Cell verticalFit className="text-right">
                  {printPercentage(arrendamiento.yield)}
                </Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.ingresos.arrendamientos.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.arrendamientos.total.renta)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.desoc)}</Cell>
              <Cell verticalFit className="text-red-500 font-bold text-right">({printMoney(presupuesto.valores.ingresos.arrendamientos.total.desocImporte)})</Cell>
              <Cell verticalFit className="text-red-500 font-bold text-right">{printInteger(presupuesto.valores.ingresos.arrendamientos.total.gExpl)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.ingresos.arrendamientos.total.noi)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.ree)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printPercentage(presupuesto.valores.ingresos.arrendamientos.total.yield)}</Cell>
            </TableRow>
          </CardTable>

          <CardTable
            title="Costes estructura y otros gastos (hasta puesta en servicio)"
            className="mt-4"
            header={
              <>
                <HeaderCell className="font-bold"></HeaderCell>
                <HeaderCell className="font-bold text-right">Base</HeaderCell>
                <HeaderCell className="font-bold text-right">Ratio</HeaderCell>
                <HeaderCell className="font-bold text-right">Importe</HeaderCell>
              </>
            }
          >
            {[presupuesto.valores.otrosGastos.direccion, presupuesto.valores.otrosGastos.activacion, presupuesto.valores.otrosGastos.comercializacion,
              presupuesto.valores.otrosGastos.gestionIntegral, presupuesto.valores.otrosGastos.ventaViviendas, presupuesto.valores.otrosGastos.ventaOtros,
              presupuesto.valores.otrosGastos.gestionInmuebles, presupuesto.valores.otrosGastos.marketing, presupuesto.valores.otrosGastos.contabilidad,
              presupuesto.valores.otrosGastos.otros].map(gasto => (
              <TableRow key={gasto.nombre}>
                <Cell verticalFit className="font-bold">{gasto.nombre}</Cell>
                <Cell verticalFit className="text-right">{printMoney(gasto.base)}</Cell>
                <Cell verticalFit className="text-right">{printPercentage(gasto.ratio)}</Cell>
                <Cell verticalFit className="text-right">{printMoney(gasto.importe)}</Cell>
              </TableRow>
            ))}
            <TableRow>
              <Cell verticalFit className="font-bold">{presupuesto.valores.otrosGastos.total.nombre}</Cell>
              <Cell verticalFit className="font-bold text-right">{printInteger(presupuesto.valores.otrosGastos.total.base)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printRatio(presupuesto.valores.otrosGastos.total.ratio)}</Cell>
              <Cell verticalFit className="font-bold text-right">{printMoney(presupuesto.valores.otrosGastos.total.importe)}</Cell>
            </TableRow>
          </CardTable>
        </>
      }
    </PageLayout>
  )
}
