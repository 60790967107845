
import React, { useEffect, useContext } from 'react'


export const GlobalContext = React.createContext(null)


export function useNavigate(fn) {
  let { setPending } = useContext(GlobalContext)

  useEffect(() => {
    setPending(v => v + 1)
    async function load() {
      await fn()
      setPending(v => v - 1)
    }
    load()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
