
import React from 'react'
import { useParams } from 'react-router-dom'

import { Update, useUpdate } from 'views/docs/upload/internal/Update'
import { listEntities } from 'models/docs/entities'
import { useNavigate } from 'hooks/navigate'
import { firstBy } from 'thenby'


export function UploadControl() {
  let { code } = useParams()
  let { load, ...rest } = useUpdate()

  useNavigate(async () => {
    let revisions = await listEntities('revisions', `documents/${code}`)
    let request = revisions
      .sort(firstBy('createTime'))
      .find(rev => rev.controlReview)

    await load(request, {
      comment: request.controlReview.comment,
    })
  })

  return (
    <>
      <Update
        variant="control"
        description="Petición del responsable de control"
        {...rest}
      />
    </>
  )
}
